import { FC } from "react";
import { Form, Radio } from "antd";
import { FORM_FIELDS } from "../../constants";
import { useAtLeastOneOverrideRule } from "../../useAtLeastOneOverrideRule";
import { useTaxonomyOverrideModeRadio } from "./useTaxonomyOverrideModeRadio";

const { name, label } = FORM_FIELDS.TAXONOMY_OVERRIDE_MODE;

export const TaxonomyOverrideModeRadio: FC = () => {
    const { onChange } = useTaxonomyOverrideModeRadio();
    const atLeastOneOverrideRule = useAtLeastOneOverrideRule();

    return (
        <Form.Item name={name} label={label} rules={[atLeastOneOverrideRule]}>
            <Radio.Group onChange={onChange}>
                <Radio value={1}>Add New Taxonomies</Radio>
                <Radio value={2}>Override Existing Taxonomies</Radio>
                <Radio value={3}>None</Radio>
            </Radio.Group>
        </Form.Item>
    );
};
