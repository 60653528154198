import { Col, Form, Row } from "antd";
import { formatNumber } from "@rubicon/utils";
import { formItemOffset } from "./DealDetailsTerms";
import { DealListItem } from "@app/core/services";
import { DEAL_TYPE_FIXED_PRICE_DEAL, DEAL_TYPE_PROGRAMMATIC_GUARANTEE } from "../constants";

interface DealDetailsPricingProps {
    dealTypeId: number;
    pricingModel?: string;
    cpmRate: number;
    impressions?: number | null;
    currency: DealListItem["currencyType"];
}

export const DealDetailsPricing = ({
    dealTypeId,
    pricingModel,
    cpmRate,
    currency,
    impressions,
}: DealDetailsPricingProps) => {
    const dealCpmRate = cpmRate;
    const currencyCode = currency.code;
    const isPricingModelVisible = [DEAL_TYPE_PROGRAMMATIC_GUARANTEE.id, DEAL_TYPE_FIXED_PRICE_DEAL.id].includes(
        dealTypeId
    );

    return (
        <Row>
            <Col sm={8}>
                {isPricingModelVisible && (
                    <Form.Item className={formItemOffset} label="Pricing Model">
                        {pricingModel}
                    </Form.Item>
                )}
                <Form.Item className={formItemOffset} label="CPM Rate">
                    {formatNumber.asMoney(dealCpmRate, currencyCode)}
                </Form.Item>
            </Col>
            <Col sm={8}>
                {Boolean(impressions) && (
                    <Form.Item className={formItemOffset} label="Impressions">
                        {impressions}
                    </Form.Item>
                )}
                <Form.Item className={formItemOffset} label="Currency">
                    {currencyCode}
                </Form.Item>
            </Col>
        </Row>
    );
};
