import { HelpTooltip } from "@app/core/components";
import { DealListItem } from "@app/core/services";
import { DealsHelpKeys } from "@app/features/deals/constants";
import { css } from "@emotion/css";
import { Button, Radio, Col, Form, Input, Row, Select, Space, Typography } from "antd";
import { FC } from "react";
import { useDealDetailsDrawerLadle } from "./useDealDetailsDrawerLadle";
import { ladleNameValidator } from "@app/core/utils/formValidators";

const CREATIVE_OPTIONS = [
    {
        label: "All",
        value: null,
    },
    {
        label: "Filled",
        value: true,
    },
    {
        label: "Unfilled",
        value: false,
    },
];

interface Props {
    deal: DealListItem;
}
export const DealDetailsDrawerLadle: FC<Props> = ({ deal }) => {
    const {
        initialValues,
        form,
        LADLE_FORM_FIELDS,
        isFetchingAwsRegions,
        isFetchingNetworks,
        networksOptions,
        awsRegionsOptions,
        handleSubmit,
        handleFormChange,
        onClose,
        isSubmitting,
    } = useDealDetailsDrawerLadle(deal);
    return (
        <Form
            layout="vertical"
            form={form}
            initialValues={initialValues}
            onFinish={handleSubmit}
            onValuesChange={handleFormChange}
            data-sdet="deal-details-drawer-ladle"
        >
            <Row gutter={16}>
                <Col>
                    <Typography.Paragraph>
                        Ladle is a troubleshooting tool that allows you to debug bid response in JSON for tag-based and
                        open RTB traffic. Please fill out the necessary information below to generate a ladle. Once the
                        ladle is generated you can find the results in the diagnostics under &quot;Ladle Results&quot;.
                    </Typography.Paragraph>
                </Col>
                <Col span={22}>
                    <Form.Item
                        name={LADLE_FORM_FIELDS.Region}
                        label={
                            <HelpTooltip helpKeyList={DealsHelpKeys} helpKey={DealsHelpKeys.LadleRegion} popover={true}>
                                Region
                            </HelpTooltip>
                        }
                    >
                        <Select
                            allowClear
                            showSearch
                            optionFilterProp="label"
                            options={awsRegionsOptions}
                            loading={isFetchingAwsRegions}
                            placeholder="Select Region"
                        />
                    </Form.Item>
                </Col>
                <Col span={22}>
                    <Form.Item
                        name={LADLE_FORM_FIELDS.LadleName}
                        label={
                            <HelpTooltip helpKeyList={DealsHelpKeys} helpKey={DealsHelpKeys.LadleName} popover={true}>
                                Ladle Name
                            </HelpTooltip>
                        }
                        rules={[{ validator: (_, value) => ladleNameValidator(value) }]}
                    >
                        <Input placeholder="Enter Name" />
                    </Form.Item>
                </Col>
                <Col span={22}>
                    <Form.Item
                        name={LADLE_FORM_FIELDS.SupplyDomain}
                        label={
                            <HelpTooltip
                                helpKeyList={DealsHelpKeys}
                                helpKey={DealsHelpKeys.LadleSupplyDomain}
                                popover={true}
                            >
                                Supply Domain
                            </HelpTooltip>
                        }
                    >
                        <Input placeholder="Enter Domain" />
                    </Form.Item>
                </Col>
                <Col span={22}>
                    <Form.Item
                        name={LADLE_FORM_FIELDS.AdvertiserDomain}
                        label={
                            <HelpTooltip
                                helpKeyList={DealsHelpKeys}
                                helpKey={DealsHelpKeys.LadleAdomain}
                                popover={true}
                            >
                                Advertiser Domain
                            </HelpTooltip>
                        }
                    >
                        <Input placeholder="Enter Advertiser Domain" />
                    </Form.Item>
                </Col>
                <Col span={22}>
                    <Form.Item
                        name={LADLE_FORM_FIELDS.CreativeId}
                        label={
                            <HelpTooltip
                                helpKeyList={DealsHelpKeys}
                                helpKey={DealsHelpKeys.LadleCreativeID}
                                popover={true}
                            >
                                Creative ID
                            </HelpTooltip>
                        }
                    >
                        <Input placeholder="Enter Creative ID" />
                    </Form.Item>
                </Col>
                <Col span={22}>
                    <Form.Item
                        label={
                            <HelpTooltip
                                helpKeyList={DealsHelpKeys}
                                helpKey={DealsHelpKeys.LadleHasCreative}
                                popover={true}
                            >
                                Creative
                            </HelpTooltip>
                        }
                        name={LADLE_FORM_FIELDS.Creative}
                    >
                        <Radio.Group options={CREATIVE_OPTIONS} />
                    </Form.Item>
                </Col>
                <Col span={22}>
                    <Form.Item
                        name={LADLE_FORM_FIELDS.Demand}
                        label={
                            <HelpTooltip helpKeyList={DealsHelpKeys} helpKey={DealsHelpKeys.DealDSP} popover={true}>
                                Demand
                            </HelpTooltip>
                        }
                    >
                        <Select
                            allowClear
                            showSearch
                            optionFilterProp="label"
                            options={networksOptions}
                            loading={isFetchingNetworks}
                            placeholder="Select Demand"
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row
                className={css`
                    border-top: 1px solid #d9d9d9;
                    position: fixed;
                    bottom: 0;
                    width: 100%;
                    background: #fff;
                    height: 52px;
                    line-height: 52px;
                `}
            >
                <Col
                    className={css`
                        position: fixed;
                        right: 16px;
                    `}
                >
                    <Space>
                        <Button onClick={onClose} disabled={isSubmitting}>
                            Cancel
                        </Button>
                        <Button type="primary" htmlType="submit" loading={isSubmitting}>
                            Activate
                        </Button>
                    </Space>
                </Col>
            </Row>
        </Form>
    );
};
