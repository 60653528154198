import { Form, RadioChangeEvent } from "antd";
import { FORM_FIELDS } from "../../constants";
import { OverrideMode } from "../../types";

const { ADD_OVERRIDE_TAXONOMIES, ADVERTISER_DOMAIN_OVERRIDE, INDUSTRY_OVERRIDE_MODE } = FORM_FIELDS;

interface UseTaxonomyOverrideModeRadio {
    onChange: (e: RadioChangeEvent) => void;
}

export const useTaxonomyOverrideModeRadio = (): UseTaxonomyOverrideModeRadio => {
    const form = Form.useFormInstance();

    const onChange = (e: RadioChangeEvent) => {
        const taxonomyOverrideMode = e.target.value;
        if (taxonomyOverrideMode === OverrideMode.NONE) {
            form.setFieldValue(ADD_OVERRIDE_TAXONOMIES.name, null);
            form.validateFields([ADD_OVERRIDE_TAXONOMIES.name]);
        }
        form.validateFields([ADVERTISER_DOMAIN_OVERRIDE.name, INDUSTRY_OVERRIDE_MODE.name]);
    };

    return {
        onChange,
    };
};
