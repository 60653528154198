import { gray10, gray3 } from "@rubicon/antd-components";
import { MenuProps, Menu } from "antd";
import { useHistory, useParams } from "react-router-dom";
import { css } from "@emotion/css";
import { DownOutlined } from "@ant-design/icons";
import { ROUTE_FORMATTERS } from "@app/core/routing";
import { CONTROLS_ROUTES } from "../constants";
import { BRAND_SAFETY_LABELS } from "./constants";

export const ADVERTISER_DOMAIN_LIST = "advertiser-domain-list";
export const SEQUESTERED_ADVERTISER_DOMAINS = "sequestered-advertiser-domains";
export const TRANSPARENCY_DEFAULT = "transparency-default";
export const CONTENT_TRANSPARENCY_RULES = "content-transparency-rules";
export const ADVANCED_BRAND_SAFETY = "advanced-brand-safety";
export const COMPETITIVE_SEPARATION_GROUPS = "competitive-separation-groups";
export const BUYER_SEAT_LIST = "buyer-seat-list";
export const MAPPING_EXCEPTIONS_LIST = "mapping-exceptions";

export const items: MenuProps["items"] = [
    {
        label: (
            <span>
                Advertiser Domains <DownOutlined />
            </span>
        ),
        key: "advertiserDomains",
        children: [
            {
                label: (
                    <span data-sdet={ADVERTISER_DOMAIN_LIST}>
                        {BRAND_SAFETY_LABELS[CONTROLS_ROUTES.ADVERTISER_DOMAINS]}
                    </span>
                ),
                key: CONTROLS_ROUTES.ADVERTISER_DOMAINS,
            },
            {
                label: (
                    <span data-sdet={SEQUESTERED_ADVERTISER_DOMAINS}>
                        {BRAND_SAFETY_LABELS[CONTROLS_ROUTES.SEQUESTERED_DOMAINS]}
                    </span>
                ),
                key: CONTROLS_ROUTES.SEQUESTERED_DOMAINS,
            },
        ],
    },
    {
        label: (
            <span>
                Transparency <DownOutlined />
            </span>
        ),
        key: "transparency",
        children: [
            {
                label: (
                    <span data-sdet={TRANSPARENCY_DEFAULT}>
                        {BRAND_SAFETY_LABELS[CONTROLS_ROUTES.TRANSPARENCY_DEFAULT]}
                    </span>
                ),
                key: CONTROLS_ROUTES.TRANSPARENCY_DEFAULT,
            },
            {
                label: (
                    <span data-sdet={CONTENT_TRANSPARENCY_RULES}>
                        {BRAND_SAFETY_LABELS[CONTROLS_ROUTES.CONTENT_TRANSPARENCY_RULES]}
                    </span>
                ),
                key: CONTROLS_ROUTES.CONTENT_TRANSPARENCY_RULES,
            },
        ],
    },
    {
        label: (
            <span data-sdet={ADVANCED_BRAND_SAFETY}>{BRAND_SAFETY_LABELS[CONTROLS_ROUTES.ADVANCED_BRAND_SAFETY]}</span>
        ),
        key: CONTROLS_ROUTES.ADVANCED_BRAND_SAFETY,
    },
    {
        label: (
            <span data-sdet={COMPETITIVE_SEPARATION_GROUPS}>
                {BRAND_SAFETY_LABELS[CONTROLS_ROUTES.COMPETITIVE_SEPARATION_GROUPS]}
            </span>
        ),
        key: CONTROLS_ROUTES.COMPETITIVE_SEPARATION_GROUPS,
    },
    {
        label: <span data-sdet={BUYER_SEAT_LIST}>{BRAND_SAFETY_LABELS[CONTROLS_ROUTES.BUYER_SEAT_LIST]}</span>,
        key: CONTROLS_ROUTES.BUYER_SEAT_LIST,
    },
    {
        label: (
            <span data-sdet={MAPPING_EXCEPTIONS_LIST}>{BRAND_SAFETY_LABELS[CONTROLS_ROUTES.MAPPING_EXCEPTIONS]}</span>
        ),
        key: CONTROLS_ROUTES.MAPPING_EXCEPTIONS,
    },
];

export const styles = css`
    & {
        .ant-menu-overflow-item.ant-menu-submenu.ant-menu-submenu-horizontal.ant-menu-submenu-selected,
        .ant-menu-overflow-item.ant-menu-submenu.ant-menu-submenu-horizontal.ant-menu-submenu-open {
            color: red;
            background-color: ${gray3};
        }
    }
    .ant-menu-submenu-horizontal:hover,
    .ant-menu-submenu-open {
        .ant-menu-submenu-title {
            color: ${gray10};
        }
    }
`;

export const BrandSafetyMenu = () => {
    const history = useHistory();
    const { seatId, tab = CONTROLS_ROUTES.ADVERTISER_DOMAINS } = useParams<{ seatId: string; tab: string }>();
    const handleClick = ({ key }) => history.push(ROUTE_FORMATTERS.SEAT_CONTROLS_BRAND_SAFETY_TABS(seatId, key));

    return (
        <Menu
            className={styles}
            defaultSelectedKeys={[tab]}
            selectedKeys={[tab]}
            mode="horizontal"
            onClick={handleClick}
            items={items}
        />
    );
};

export default BrandSafetyMenu;
