import { MONTH_DAY_YEAR_HOUR_MINUTE_AMPM, NONE } from "@app/core/components/constants";
import { AdUnit } from "@app/core/services";
import { GUTTER } from "@app/features/inventory/constants";
import { Col, Form, Row, Typography } from "antd";
import { FC } from "react";
import { AD_UNIT_DETAILS_LABELS, COL_SIZES, FORM_ITEM_OFFSET } from "../../../constants";
import moment from "moment-timezone";
import { TextWithCopy } from "@app/features/inventory/components/TextWithCopy";

const { Item } = Form;

interface Props {
    adUnit: AdUnit;
}
export const General: FC<Props> = ({ adUnit }) => {
    return (
        <div data-sdet="ad-unit-general-section">
            <Row gutter={GUTTER}>
                <Col span={8}>
                    <Item label={AD_UNIT_DETAILS_LABELS.name} className={FORM_ITEM_OFFSET}>
                        <TextWithCopy text={adUnit?.name} notificationEntity="Name" />
                    </Item>
                </Col>
            </Row>
            <Row gutter={GUTTER}>
                <Col span={8}>
                    <Item label={AD_UNIT_DETAILS_LABELS.code} className={FORM_ITEM_OFFSET}>
                        <TextWithCopy text={adUnit?.code} notificationEntity="Code" />
                    </Item>
                </Col>
            </Row>
            <Row gutter={GUTTER}>
                <Col span={10}>
                    <Item label={AD_UNIT_DETAILS_LABELS.description} className={FORM_ITEM_OFFSET}>
                        <Typography.Paragraph>{adUnit?.description ? adUnit.description : NONE}</Typography.Paragraph>
                    </Item>
                </Col>
            </Row>
            <Row gutter={GUTTER}>
                <Col {...COL_SIZES}>
                    <Item label={AD_UNIT_DETAILS_LABELS.status} className={FORM_ITEM_OFFSET}>
                        {adUnit?.status?.name}
                    </Item>
                </Col>
            </Row>
            <Row gutter={GUTTER}>
                <Col {...COL_SIZES}>
                    <Item label={AD_UNIT_DETAILS_LABELS.lastUpdated} className={FORM_ITEM_OFFSET}>
                        {adUnit?.updateDate ? moment(adUnit.updateDate).format(MONTH_DAY_YEAR_HOUR_MINUTE_AMPM) : NONE}
                    </Item>
                </Col>
                <Col {...COL_SIZES}>
                    <Item label={AD_UNIT_DETAILS_LABELS.lastIndexed} className={FORM_ITEM_OFFSET}>
                        {NONE}
                    </Item>
                </Col>
                <Col {...COL_SIZES}>
                    <Item label={AD_UNIT_DETAILS_LABELS.created} className={FORM_ITEM_OFFSET}>
                        {adUnit?.creationDate
                            ? moment(adUnit.creationDate).format(MONTH_DAY_YEAR_HOUR_MINUTE_AMPM)
                            : NONE}
                    </Item>
                </Col>
            </Row>
        </div>
    );
};
