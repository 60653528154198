import { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { ROUTE_FORMATTERS } from "@app/core/routing";
import { MappingException, useGetMappingExceptionByIdQuery } from "@app/core/services";

interface UseMappingExceptionsDetailsDrawer {
    open: boolean;
    mappingException: MappingException | undefined;
    detailsPageUrl: string;
    onClose: () => void;
    afterOpenChange: () => void;
}

export const useMappingExceptionsDetailsDrawer = (): UseMappingExceptionsDetailsDrawer => {
    const history = useHistory();
    const { seatId, mappingExceptionId } = useParams<{ seatId: string; mappingExceptionId: string }>();
    const { data: mappingException } = useGetMappingExceptionByIdQuery(Number(mappingExceptionId), {
        skip: !mappingExceptionId,
    });

    const [open, setOpen] = useState(true);

    const detailsPageUrl = ROUTE_FORMATTERS.SEAT_CONTROLS_BRAND_SAFETY_MAPPING_EXCEPTIONS_DETAILS_PAGE(
        seatId,
        mappingExceptionId
    );

    const onClose = () => setOpen(false);

    const afterOpenChange = () => {
        if (open) {
            return;
        }
        history.push(ROUTE_FORMATTERS.SEAT_CONTROLS_BRAND_SAFETY_MAPPING_EXCEPTIONS_LIST_PAGE(seatId));
    };

    return {
        open,
        mappingException,
        detailsPageUrl,
        onClose,
        afterOpenChange,
    };
};
