import { Col, Flex } from "antd";
import { DetailsDrawerLayout } from "../../DetailsDrawerLayout";
import { useInventoryDetailsDrawerUrlId } from "../../useInventoryDetailsDrawerUrlId";
import { ChangeHistoryTable } from "@app/core/components/ChangeHistoryTable";
import { useChangeHistory } from "./useChangeHistory";
import { Loading } from "@app/core/components";
import { css } from "@emotion/css";

export const ChangeHistoryContent = () => {
    const unitId = useInventoryDetailsDrawerUrlId();
    const { entityType, breadcrumbs, isLoading } = useChangeHistory({ unitId });

    if (isLoading) {
        return <Loading />;
    }

    return (
        <Flex vertical data-sdet="inventory-change-history-content">
            <DetailsDrawerLayout title="Change History" breadcrumbs={breadcrumbs} />
            <Col
                className={css`
                    padding: 1rem;
                    width: 100%;
                `}
            >
                <ChangeHistoryTable entityType={entityType} entityId={unitId} />
            </Col>
        </Flex>
    );
};
