import { Store } from "rc-field-form/lib/interface";
import { getSeatLabelValueLabel } from "@app/features/inventory/helpers";
import { getTimeZoneLabel, Seat, UpdateSeatPayload } from "@app/core/services";
import { INVENTORY_FORM_FIELDS, REVSHARE_PERCENTAGE_COST_MODEL } from "@app/features/inventory/InventorySeat/constants";
import { SeatDetailsForm } from "@app/features/inventory/InventorySeat/InventorySeatEditPage/SeatDetailsForm/useSeatDetailsForm";

export const getSeatDetailsFormInitialValues = (seat: Seat | null | undefined): Store | undefined => {
    if (!seat) return undefined;

    return {
        // General Section
        [INVENTORY_FORM_FIELDS.NAME.name]: seat.name,
        [INVENTORY_FORM_FIELDS.CODE.name]: seat.code,
        [INVENTORY_FORM_FIELDS.DESCRIPTION.name]: seat.description,
        [INVENTORY_FORM_FIELDS.BUSINESS_DOMAIN.name]: seat.businessDomain,
        [INVENTORY_FORM_FIELDS.ACCOUNT_TYPE.name]: seat.accountType
            ? { value: seat.accountType.id, label: seat.accountType.name }
            : null,
        [INVENTORY_FORM_FIELDS.CLIENT_TYPE.name]: seat.clientType
            ? { value: seat.clientType.id, label: seat.clientType.name }
            : null,
        [INVENTORY_FORM_FIELDS.REGION.name]: seat.region ? { value: seat.region.id, label: seat.region.name } : null,
        [INVENTORY_FORM_FIELDS.SUPPORT_TIER.name]: seat.supportTier
            ? { value: seat.supportTier.id, label: seat.supportTier.name }
            : null,
        [INVENTORY_FORM_FIELDS.IS_MVPD.name]: seat.isMvpd,
        [INVENTORY_FORM_FIELDS.IS_FILL_GUARANTEED.name]: seat.fillGuaranteed,
        // Financial Section
        [INVENTORY_FORM_FIELDS.FLOOR_CURRENCY.name]: { value: seat.floorCurrency.id, label: seat.floorCurrency.code },
        [INVENTORY_FORM_FIELDS.FALLBACK_FLOOR.name]: seat.fallbackFloor / 1000,
        [INVENTORY_FORM_FIELDS.DEFAULT_BILLING_CODE.name]: seat.defaultBillingCode,
        [INVENTORY_FORM_FIELDS.AFFILIATE_COST_ENABLED.name]: seat.affiliateCostEnabled,
        [INVENTORY_FORM_FIELDS.AFFILIATE_COST_MODEL.name]: seat.affiliateCostModel
            ? {
                  value: seat.affiliateCostModel.id,
                  label: seat.affiliateCostModel.name,
              }
            : REVSHARE_PERCENTAGE_COST_MODEL,
        [INVENTORY_FORM_FIELDS.AFFILIATE_COST_VALUE_PERCENT.name]: seat.affiliateCostValuePercent,
        [INVENTORY_FORM_FIELDS.AFFILIATE_COST_VALUE_FIXED.name]: Number(seat.affiliateCostValueFixed) / 1000,
        // Seat Defaults
        [INVENTORY_FORM_FIELDS.TIME_ZONE.name]: seat.timeZone
            ? { value: seat.timeZone.id, label: getTimeZoneLabel(seat.timeZone) }
            : null,
        [INVENTORY_FORM_FIELDS.AD_SOURCE_MIN_DURATION_DEFAULT.name]: seat.adSourceMinDurationDefault || 0,
        [INVENTORY_FORM_FIELDS.AD_SOURCE_MAX_DURATION_DEFAULT.name]: seat.adSourceMaxDurationDefault || 0,
        [INVENTORY_FORM_FIELDS.AD_SOURCE_AUCTION_TYPE_DEFAULT.name]: seat.adSourceAuctionTypeDefault
            ? {
                  value: seat.adSourceAuctionTypeDefault.id,
                  label: seat.adSourceAuctionTypeDefault.name,
              }
            : null,
        [INVENTORY_FORM_FIELDS.DEFAULT_EXTENDED_IMPRESSION_WAIT_TIME.name]: seat.defaultExtendedImpressionWaitTime
            ? {
                  value: seat.defaultExtendedImpressionWaitTime.id,
                  label: seat.defaultExtendedImpressionWaitTime.name,
              }
            : null,
        // Brand Safety
        [INVENTORY_FORM_FIELDS.BLOCK_ADVERTISER_DOMAIN_LISTS.name]: seat.seatAdvertiserDomainFilterListDefs.map(
            (list) => ({
                value: list.id,
                label: list.name,
            })
        ),
        [INVENTORY_FORM_FIELDS.BLOCKED_ADVERTISER_DOMAINS.name]: seat.advertiserBlockDomains.join("\n"),
        [INVENTORY_FORM_FIELDS.BLOCK_BUYER_SEAT_LIST.name]: seat.buyerSeatList
            ? { value: seat.buyerSeatList.id, label: seat.buyerSeatList.name }
            : null,
        // Custom Pixels
        [INVENTORY_FORM_FIELDS.CUSTOM_PIXELS.name]: seat.thirdPartyPixels.map((pixel) => ({
            value: pixel.id,
            label: pixel.name,
        })),
        // Labels
        [INVENTORY_FORM_FIELDS.LABELS.name]: seat.labelValues.map(({ id, label, value }) => ({
            value: id,
            label: getSeatLabelValueLabel(label, value),
            labelValue: value,
            seatLabel: label,
        })),
        [INVENTORY_FORM_FIELDS.INTERNAL_LABELS.name]: seat.internalLabelValues.map(({ id, label, value }) => ({
            value: id,
            label: getSeatLabelValueLabel(label, value),
            labelValue: value,
            seatLabel: label,
        })),
    };
};
export const parseSeatDetailsFormValuesToApi = (
    seat: Seat,
    values: SeatDetailsForm,
    isInternalOnly: boolean
): UpdateSeatPayload => {
    const {
        name,
        region,
        timeZone,
        clientType,
        supportTier,
        labelValues,
        description,
        accountType,
        fallbackFloor,
        buyerSeatList,
        floorCurrency,
        businessDomain,
        thirdPartyPixels,
        affiliateCostEnabled,
        affiliateCostModel,
        affiliateCostValuePercent,
        affiliateCostValueFixed,
        internalLabelValues,
        advertiserBlockDomains,
        adSourceAuctionTypeDefault,
        adSourceMinDurationDefault,
        adSourceMaxDurationDefault,
        defaultExtendedImpressionWaitTime,
        seatAdvertiserDomainFilterListDefs,
        ...rest
    } = values;

    const payload: UpdateSeatPayload = {
        id: seat.id,
        audienceLockAllowedDomain:
            seat.audienceLockAllowedDomain && !seat.audienceLockAllowedDomain.length
                ? null
                : seat.audienceLockAllowedDomain,
        // General Section
        description,
        // Financial Section
        floorCurrency: {
            id: floorCurrency.value as number,
            code: floorCurrency.label as string,
        },
        fallbackFloor: (fallbackFloor * 1000) as number,
        // Seat Defaults
        adSourceMinDurationDefault,
        adSourceMaxDurationDefault,
        adSourceAuctionTypeDefault: adSourceAuctionTypeDefault
            ? {
                  id: adSourceAuctionTypeDefault.value as number,
                  name: adSourceAuctionTypeDefault.label as string,
              }
            : null,
        // Brand Safety
        seatAdvertiserDomainFilterListDefs: seatAdvertiserDomainFilterListDefs.map((list) => ({
            id: list.value as number,
            name: list.label as string,
        })),
        advertiserBlockDomains: advertiserBlockDomains.trim() ? advertiserBlockDomains.trim().split("\n") : null,
        buyerSeatList: buyerSeatList
            ? { id: buyerSeatList.value as number, name: buyerSeatList.label as string }
            : null,
        // Custom Pixels
        thirdPartyPixels: thirdPartyPixels.map((pixel) => ({
            id: pixel.value as number,
            name: pixel.label as string,
        })),
        //Labels
        labelValues: (labelValues || []).map(({ value, seatLabel, labelValue }) => ({
            id: value,
            value: labelValue,
            label: seatLabel,
        })),
    };

    const internalOnlyPayload: UpdateSeatPayload = {
        ...(seat as UpdateSeatPayload),
        ...rest,
        ...payload,
        // General Section
        name,
        businessDomain,
        accountType: accountType ? { id: accountType.value as number, name: accountType.label as string } : null,
        clientType: clientType ? { id: clientType.value as number, name: clientType.label as string } : null,
        region: region ? { id: region.value as number, name: region.value as string } : null,
        supportTier: supportTier ? { id: supportTier.value as number, name: supportTier.value as string } : null,
        // Seat Defaults
        timeZone: timeZone ? { id: timeZone.value as number, name: timeZone.label as string } : null,
        defaultExtendedImpressionWaitTime: defaultExtendedImpressionWaitTime
            ? {
                  id: defaultExtendedImpressionWaitTime.value as number,
                  name: defaultExtendedImpressionWaitTime.label as string,
              }
            : null,
        //Labels
        internalLabelValues: (internalLabelValues || []).map(({ value, seatLabel, labelValue }) => ({
            id: value,
            value: labelValue,
            label: seatLabel,
        })),
        // Financial Section
        affiliateCostEnabled,
        affiliateCostModel: affiliateCostEnabled
            ? { id: affiliateCostModel.value as number, name: affiliateCostModel.label as string }
            : null,
        affiliateCostValueFixed: affiliateCostValueFixed ? affiliateCostValueFixed * 1000 : null,
        affiliateCostValuePercent,
    };
    return isInternalOnly ? internalOnlyPayload : payload;
};
