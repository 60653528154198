import { FC } from "react";
import { Form } from "antd";
import { LabeledValue } from "antd/es/select";
import { LoadOnScrollSelect } from "@rubicon/antd-components";
import { TagRender } from "@app/core/components/TagRender";
import { SelectAllButtonDropdownRender } from "@app/core/components/SelectAllButtonDropdownRender";
import { BLOCKED_INDUSTRIES_NAME } from "./constants";
import { useBlockedIndustriesSelect } from "./useBlockedIndustriesSelect";

interface Props {
    name?: string;
    label?: string;
    placeholder?: string;
    advertiserDomainModeId?: number | null;
    industriesModeId?: number | null;
    value?: LabeledValue[] | null;
    disabled?: boolean;
    required?: boolean;
    labelCol?: { span: number };
    wrapperCol?: { span: number };
    onChange?: (globalIndustries: LabeledValue[]) => void;
}

export const BlockedIndustriesSelect: FC<Props> = ({
    name = BLOCKED_INDUSTRIES_NAME,
    advertiserDomainModeId,
    industriesModeId,
    value: propValue,
    label: propLabel,
    placeholder: propPlaceholder,
    disabled: propDisabled,
    required: propRequired,
    onChange: propOnChange,
    labelCol,
    wrapperCol,
}) => {
    const {
        value,
        options,
        isFetching,
        disabled,
        label,
        placeholder,
        required,
        rules,
        isSearchCurrent,
        search,
        extra,
        hasMore,
        loadMore,
        onBlur,
        onChange,
        onChangeSelectAll,
        onChangeSearch,
        getPrimaryKey,
    } = useBlockedIndustriesSelect(
        name,
        advertiserDomainModeId,
        industriesModeId,
        propValue,
        propLabel,
        propPlaceholder,
        propDisabled,
        propRequired,
        propOnChange
    );

    return (
        <Form.Item
            data-sdet="blocked-industries-select"
            name={name}
            label={label}
            required={required}
            extra={extra}
            rules={rules}
            labelCol={labelCol}
            wrapperCol={wrapperCol}
        >
            <LoadOnScrollSelect
                allowClear
                labelInValue
                disabled={disabled}
                mode="multiple"
                dropdownRender={(menu) => (
                    <SelectAllButtonDropdownRender<LabeledValue>
                        menu={menu}
                        value={value ?? undefined}
                        search={search}
                        isCurrent={isSearchCurrent}
                        hasMore={hasMore}
                        options={options}
                        getPrimaryKey={getPrimaryKey}
                        onChange={onChangeSelectAll}
                    />
                )}
                filterOption={false}
                loading={isFetching}
                options={options}
                searchValue={search}
                showSearch
                hasMore={hasMore}
                placeholder={placeholder}
                loadMore={loadMore}
                onChange={onChange}
                onBlur={onBlur}
                onSearch={onChangeSearch}
                tagRender={TagRender}
                value={value}
            />
        </Form.Item>
    );
};
