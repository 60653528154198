import { FC } from "react";
import { Link, Route } from "react-router-dom";
import { Button, Table, Tooltip, Typography } from "antd";
import { ColumnsType } from "antd/es/table";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import moment from "moment-timezone";
import { format } from "@rubicon/utils";
import { YEAR_MONTH_DAY_HOUR_MINUTE_AMPM } from "@app/core/components/constants";
import { useUserAccess } from "@app/core/auth";
import { ROUTE_FORMATTERS, ROUTES } from "@app/core/routing";
import { MappingException } from "@app/core/services";
import { BrandSafetyDeleteModal } from "../../BrandSafetyDeleteModal";
import { MappingExceptionsDetailsDrawer } from "../MappingExceptionsDetailsDrawer";
import { SORT_PARAM_MAP, useMappingExceptionsListPage } from "./useMappingExceptionsListPage";

const COLUMN_KEYS = {
    DEMAND: "network",
    ADOMAIN: "adomain",
    CRID: "crid",
    DATE_CREATED: "creationDate",
    LAST_UPDATED: "updateDate",
    ACTIONS: "actions",
} as const;

export const MappingExceptionsListPage: FC = () => {
    const { canEditSeat } = useUserAccess();
    const {
        seatId,
        dataSource,
        loading,
        sortBy,
        sortOrder,
        paginationConfig,
        deleteModalProps,
        onChangeTable,
        onClickEdit,
        onClickDelete,
    } = useMappingExceptionsListPage();

    return (
        <div data-sdet="mapping-exceptions-list-page">
            <Route exact path={ROUTES.SEAT_CONTROLS_BRAND_SAFETY_MAPPING_EXCEPTIONS_LIST_PAGE_DETAILS_DRAWER}>
                <MappingExceptionsDetailsDrawer />
            </Route>
            <BrandSafetyDeleteModal {...deleteModalProps} />
            <Table<MappingException>
                data-sdet="mapping-exceptions-table"
                rowKey="id"
                size="small"
                sortDirections={["ascend", "descend", "ascend"]}
                showSorterTooltip={false}
                dataSource={dataSource}
                loading={loading}
                pagination={paginationConfig}
                onChange={onChangeTable}
                columns={
                    [
                        {
                            title: "Demand",
                            dataIndex: COLUMN_KEYS.DEMAND,
                            key: COLUMN_KEYS.DEMAND,
                            sorter: true,
                            sortBy: sortBy === COLUMN_KEYS.DEMAND ? sortOrder : null,
                            render: (network: MappingException["network"], { id }) => (
                                <Link
                                    to={ROUTE_FORMATTERS.SEAT_CONTROLS_BRAND_SAFETY_MAPPING_EXCEPTIONS_LIST_PAGE_DETAILS_DRAWER(
                                        seatId,
                                        id
                                    )}
                                >
                                    {network.name}
                                </Link>
                            ),
                        },
                        {
                            title: "Adomain",
                            dataIndex: COLUMN_KEYS.ADOMAIN,
                            key: COLUMN_KEYS.ADOMAIN,
                            sorter: true,
                            sortBy: sortBy === COLUMN_KEYS.ADOMAIN ? sortOrder : null,
                            render: (adomain: MappingException["adomain"]) => (
                                <Typography.Text>{format.asSelf(adomain)}</Typography.Text>
                            ),
                        },
                        {
                            title: "Crid",
                            dataIndex: COLUMN_KEYS.CRID,
                            key: COLUMN_KEYS.CRID,
                            sorter: true,
                            sortBy: sortBy === COLUMN_KEYS.CRID ? sortOrder : null,
                            render: (crid: MappingException["crid"]) => (
                                <Typography.Text>{format.asSelf(crid)}</Typography.Text>
                            ),
                        },
                        {
                            title: "Date Created",
                            dataIndex: COLUMN_KEYS.DATE_CREATED,
                            key: COLUMN_KEYS.DATE_CREATED,
                            sorter: true,
                            sortBy: sortBy === SORT_PARAM_MAP.creationDate ? sortOrder : null,
                            render: (creationDate: MappingException["creationDate"]) => (
                                <Typography.Text>
                                    {moment.utc(creationDate).format(YEAR_MONTH_DAY_HOUR_MINUTE_AMPM)}
                                </Typography.Text>
                            ),
                        },
                        {
                            title: "Last Updated",
                            dataIndex: COLUMN_KEYS.LAST_UPDATED,
                            key: COLUMN_KEYS.LAST_UPDATED,
                            sorter: true,
                            sortBy: sortBy === SORT_PARAM_MAP.updateDate ? sortOrder : null,
                            render: (updateDate: MappingException["updateDate"]) => (
                                <Typography.Text>
                                    {moment.utc(updateDate).format(YEAR_MONTH_DAY_HOUR_MINUTE_AMPM)}
                                </Typography.Text>
                            ),
                        },
                        canEditSeat && {
                            title: "Actions",
                            dataIndex: COLUMN_KEYS.ACTIONS,
                            width: "114px",
                            render: (_, { id, network }) => {
                                return (
                                    <Button.Group>
                                        <Tooltip title="Edit">
                                            <Button icon={<EditOutlined />} onClick={() => onClickEdit(id)} />
                                        </Tooltip>
                                        <Tooltip title="Delete">
                                            <Button
                                                icon={<DeleteOutlined />}
                                                onClick={() => onClickDelete({ id, name: network.name })}
                                            />
                                        </Tooltip>
                                    </Button.Group>
                                );
                            },
                        },
                    ].filter(Boolean) as ColumnsType<MappingException>
                }
            />
        </div>
    );
};
