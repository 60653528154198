import { Button, Card, Flex, Form, Input, Layout, Radio } from "antd";
import { FC, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { FixedSaveButtons } from "@app/core/components";
import {
    AdvertiserDomainFormPayload,
    AdvertiserDomainRaw,
    useDeleteAdvertiserDomainMutation,
    useGetAdvertiserDomainTypesQuery,
} from "@app/core/services";
import { ROUTE_FORMATTERS } from "@app/core/routing";
import { CONTROLS_ROUTES, URL_REGEXP_PATTERN } from "@app/features/controls/constants";
import {
    ADOMAIN_DETAILS_NAME_INPUT_SDET,
    ADOMAIN_DETAILS_TYPE_SDET,
    ADOMAIN_DETAILS_DOMAINS_SDET,
    ADOMAIN_DELETE_BTN_SDET,
} from "../AdvertiserDomainDrawer/AdvertiserDomainDetailsContent";
import { useNotificationError } from "@app/core/components/hooks";
import { AdvertiserDomainDefinitionsButtonAndDrawer } from "../AdvertiserDomainDrawer/AdvertiserDomainDefinitionDrawer";
import { BrandSafetyDeleteModal } from "../../BrandSafetyDeleteModal";
import { useBrandSafetyDeleteModal } from "../../BrandSafetyDeleteModal/useBrandSafetyDeleteModal";

interface Props {
    handleSubmit: (values: AdvertiserDomainFormPayload) => void;
    isLoading: boolean;
    initialValues?: AdvertiserDomainRaw;
    handlePostDelete?: () => void;
}
export const DEFAULT_DOMAIN_TYPE = { name: "Block", id: 1 };
export const ADVERTISER_DOMAIN_CREATE_FORM_INITIAL_VALUES = {
    name: "",
    advertiserDomains: [""],
    advertiserDomainListType: DEFAULT_DOMAIN_TYPE,
} as AdvertiserDomainRaw;

export const NAME_LEN_ERROR_MESSAGE = (count: string | number) => `Name can not be longer than ${count} characters`;

export const AdvertiserDomainForm: FC<Props> = ({ isLoading, initialValues, handleSubmit, handlePostDelete }) => {
    const [form] = Form.useForm();
    const { seatId } = useParams<{ seatId: string }>();
    const history = useHistory();
    const [deleteTrigger, { isLoading: isDeleting }] = useDeleteAdvertiserDomainMutation();
    const { handleOpen: handleDeleteModal, ...restModalProps } = useBrandSafetyDeleteModal(
        deleteTrigger,
        handlePostDelete
    );
    const { data, error } = useGetAdvertiserDomainTypesQuery();
    useNotificationError(error);

    useEffect(() => {
        form.setFieldsValue({
            advertiserDomains: initialValues?.advertiserDomains?.toString().split(",").join("\r\n"),
        });
    }, [form, initialValues?.advertiserDomains]);
    return (
        <>
            <Form
                form={form}
                name="aDomainForm"
                layout="vertical"
                initialValues={initialValues}
                onFinish={handleSubmit}
                wrapperCol={{ xs: 24, md: 12 }}
                scrollToFirstError={{ behavior: "smooth", block: "center" }}
            >
                <Layout.Content>
                    <Card bordered={false} style={{ minHeight: "77vh" }}>
                        <Flex justify="space-between">
                            <Flex flex={1} vertical>
                                <Form.Item
                                    label="Name"
                                    name="name"
                                    messageVariables={{ name: "Name" }}
                                    required
                                    rules={[
                                        {
                                            whitespace: true,
                                            required: true,
                                            message: "${name} is required",
                                        },
                                        {
                                            max: 70,
                                            message: NAME_LEN_ERROR_MESSAGE(70),
                                        },
                                    ]}
                                >
                                    <Input data-sdet={ADOMAIN_DETAILS_NAME_INPUT_SDET} />
                                </Form.Item>
                                <Form.Item hidden name="id" initialValue={initialValues?.id ?? null} />
                                <Form.Item hidden name="seat" initialValue={{ id: Number(seatId) }} />
                                <Form.Item label="Type" name={["advertiserDomainListType", "id"]}>
                                    <Radio.Group
                                        options={data?.map(({ name, id }) => ({ value: id, label: name }))}
                                        data-sdet={ADOMAIN_DETAILS_TYPE_SDET}
                                    />
                                </Form.Item>
                                <Form.Item
                                    label="Domains"
                                    messageVariables={{ name: "Domain" }}
                                    name="advertiserDomains"
                                    validateTrigger={["onBlur", "onChange"]}
                                    required
                                    rules={[
                                        {
                                            whitespace: true,
                                            required: true,
                                        },
                                        {
                                            validateTrigger: "onBlur",
                                            transform: (advertiserDomains) => advertiserDomains.split(/\r?\n/),
                                            pattern: URL_REGEXP_PATTERN,
                                            validator: ({ pattern }, values) =>
                                                Promise.all(
                                                    values.map((value) => {
                                                        const matched = value.match(pattern);
                                                        return !matched
                                                            ? Promise.reject(
                                                                  new Error(
                                                                      `Domain "${value}" does not match URL pattern`
                                                                  )
                                                              )
                                                            : Promise.resolve();
                                                    })
                                                ).then((v) => v),
                                        },
                                    ]}
                                >
                                    <Input.TextArea rows={6} data-sdet={ADOMAIN_DETAILS_DOMAINS_SDET} />
                                </Form.Item>
                            </Flex>

                            <AdvertiserDomainDefinitionsButtonAndDrawer />
                        </Flex>
                    </Card>
                </Layout.Content>
                <FixedSaveButtons
                    isLoading={isLoading}
                    onCancel={() =>
                        history.push(ROUTE_FORMATTERS.SEAT_CONTROLS_TABS(seatId, CONTROLS_ROUTES.BRAND_SAFETY))
                    }
                    submitLabel={initialValues?.id ? "Save" : "Submit"}
                    suffixAddOn={
                        initialValues?.id ? (
                            <Button
                                data-sdet={ADOMAIN_DELETE_BTN_SDET}
                                type="primary"
                                danger
                                onClick={() =>
                                    initialValues?.id &&
                                    initialValues?.name &&
                                    handleDeleteModal({ id: initialValues.id, name: initialValues.name })
                                }
                            >
                                Delete
                            </Button>
                        ) : null
                    }
                />
            </Form>
            <BrandSafetyDeleteModal isDeleting={isDeleting} {...restModalProps} />
        </>
    );
};
