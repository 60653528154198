import { useState, useMemo, useRef, useEffect, useCallback } from "react";
import { Typography } from "antd";
import debounce from "lodash.debounce";
import { FILTER_INPUT_DEBOUNCE_TIME } from "@app/core/components/constants";
import {
    SearchItem,
    SearchItemAdUnit,
    SearchItemBrand,
    SearchItemPublisher,
    SearchItemSupply,
    useSearchQuery,
} from "@app/core/services/console/search";
import { useSeatAuthContext } from "@app/core/auth";
import { useAppDispatch } from "@app/core/store";
import { setExpandedRowKeys, setSelectedRowAndExpandedRowKeys, setSelectedRowKey } from "../../reducer";
import { useHistory } from "react-router-dom";
import { ROUTE_FORMATTERS } from "@app/core/routing";
import { InventoryDetailsDrawerType } from "@app/features/inventory/DetailsDrawer/reducer";

const generatePublisherChannelOptions = (datum) => {
    return {
        entity: datum,
        value: `${datum.entityType}-${datum.id}`,
        label: `${datum.entityType}: ${datum.seat.name} > ${datum.name}`,
    };
};

const generateBrandChannelOptions = (datum) => {
    return {
        entity: datum,
        value: `${datum.entityType}-${datum.id}`,
        label: `${datum.entityType}: ${datum.publisher.seat.name} > ${datum.publisher.name} > ${datum.name}`,
    };
};

const generateChannelAdUnitOptions = (datum) => {
    return {
        entity: datum,
        value: `${datum.entityType}-${datum.id}`,
        label: `${datum.entityType}: ${datum.supply.brand.publisher.seat.name} >  ${datum.supply.brand.publisher.name} > ${datum.supply.brand.name} > ${datum.supply.name} > ${datum.name}`,
    };
};

const generatePublisherOptions = (datum: SearchItemPublisher) => {
    return {
        entity: datum,
        value: `${datum.entityType}-${datum.id}`,
        label: `${datum.entityType}: ${datum.seat.name} > ${datum.name}`,
    };
};

const generateBrandOptions = (datum: SearchItemBrand) => {
    return {
        entity: datum,
        value: `${datum.entityType}-${datum.id}`,
        label: `${datum.entityType}: ${datum.publisher.seat.name} > ${datum.publisher.name} > ${datum.name}`,
    };
};

const generateSupplyOptions = (datum: SearchItemSupply) => {
    return {
        entity: datum,
        value: `${datum.entityType}-${datum.id}`,
        label: `${datum.entityType}: ${datum.brand.publisher.seat.name} >  ${datum.brand.publisher.name} > ${datum.brand.name} > ${datum.name}`,
    };
};

const generateAdUnitOptions = (datum: SearchItemAdUnit) => {
    return {
        entity: datum,
        value: `${datum.entityType}-${datum.id}`,
        label: `${datum.entityType}: ${datum.supply.brand.publisher.seat.name} >  ${datum.supply.brand.publisher.name} > ${datum.supply.brand.name} > ${datum.supply.name} > ${datum.name}`,
    };
};

export const useInventorySearch = () => {
    const dispatch = useAppDispatch();
    const { context } = useSeatAuthContext();
    const ref = useRef<{ label: React.ReactElement; value: string; entity: SearchItem }[]>([]);
    const [search, setSearch] = useState("");
    const [page, setPage] = useState<number>(1);
    const [keyword, setKeyword] = useState("");
    const history = useHistory();
    const { data, isFetching } = useSearchQuery(
        { keyword, page, max: 25, seatId: context?.id.toString() },
        { skip: !keyword }
    );

    const getOptionConfigurations = (datum: SearchItem) => {
        switch (datum.entityType) {
            case "Channel":
                if (datum.seat) {
                    return generatePublisherChannelOptions(datum);
                }
                if (datum.publisher) {
                    return generateBrandChannelOptions(datum);
                }
            case "Publisher":
                return generatePublisherOptions(datum as SearchItemPublisher);
            case "Brand":
                return generateBrandOptions(datum);
            case "Supply":
                return generateSupplyOptions(datum);
            case "ChannelAdUnit":
                return generateChannelAdUnitOptions(datum);
            case "AdUnit":
                return generateAdUnitOptions(datum);
            default:
                return [];
        }
    };

    const getOptions = useCallback((datum: SearchItem) => {
        const optionConfigurations = getOptionConfigurations(datum);

        return (Array.isArray(optionConfigurations) ? optionConfigurations : [optionConfigurations]).map(
            (configuration) => ({
                label: <Typography.Text>{configuration.label}</Typography.Text>,
                value: configuration.value,
                entity: configuration.entity,
            })
        );
    }, []);

    const options = useMemo(() => {
        return (ref.current || []).concat((data || []).map((datum) => getOptions(datum)).flat());
    }, [data, getOptions]);

    const debouncedSetKeyword = useMemo(
        () => debounce((value: string) => setKeyword(value), FILTER_INPUT_DEBOUNCE_TIME),
        [setKeyword]
    );

    const handleChangeSearch = (value: string) => {
        setSearch(value);
        ref.current = [];
        debouncedSetKeyword(value);
    };

    const handleSearchSelected = (_, option) => {
        if (option.entity) {
            switch (option.entity.entityType) {
                case "Channel":
                    dispatch(
                        setSelectedRowKey({
                            key: `Channel-${option.entity.id}`,
                            entity: option.entity,
                            shouldScrollTo: true,
                            isLoading: false,
                        })
                    );
                    if (option.entity.publisher) {
                        dispatch(
                            setExpandedRowKeys({
                                seatId: `Seat-${option.entity.publisher.seat.id}`,
                                publisherId: `Publisher-${option.entity.publisher.id}`,
                                brandId: null,
                                supplyId: null,
                            })
                        );
                    }
                    break;
                case "Publisher":
                    dispatch(
                        setSelectedRowAndExpandedRowKeys({
                            selectedRow: {
                                key: `Publisher-${option.entity.id}`,
                                entity: option.entity,
                                shouldScrollTo: true,
                                isLoading: true,
                            },
                            expandedRowKeys: {
                                seatId: `Seat-${option.entity.seat.id}`,
                                publisherId: null,
                                brandId: null,
                                supplyId: null,
                            },
                        })
                    );
                    break;
                case "Brand":
                    dispatch(
                        setSelectedRowAndExpandedRowKeys({
                            selectedRow: {
                                key: `Brand-${option.entity.id}`,
                                entity: option.entity,
                                shouldScrollTo: true,
                                isLoading: true,
                            },
                            expandedRowKeys: {
                                seatId: `Seat-${option.entity.publisher.seat.id}`,
                                publisherId: `Publisher-${option.entity.publisher.id}`,
                                brandId: null,
                                supplyId: null,
                            },
                        })
                    );
                    break;
                case "Supply":
                    dispatch(
                        setSelectedRowAndExpandedRowKeys({
                            selectedRow: {
                                key: `Supply-${option.entity.id}`,
                                entity: option.entity,
                                shouldScrollTo: true,
                                isLoading: true,
                            },
                            expandedRowKeys: {
                                seatId: `Seat-${option.entity.brand.publisher.seat.id}`,
                                publisherId: `Publisher-${option.entity.brand.publisher.id}`,
                                brandId: `Brand-${option.entity.brand.id}`,
                                supplyId: null,
                            },
                        })
                    );
                    break;
                case "AdUnit":
                    dispatch(
                        setSelectedRowAndExpandedRowKeys({
                            selectedRow: {
                                key: `AdUnit-${option.entity.id}`,
                                entity: option.entity,
                                shouldScrollTo: true,
                                isLoading: true,
                            },
                            expandedRowKeys: {
                                seatId: `Seat-${option.entity.supply.brand.publisher.seat.id}`,
                                publisherId: `Publisher-${option.entity.supply.brand.publisher.id}`,
                                brandId: `Brand-${option.entity.supply.brand.id}`,
                                supplyId: `Supply-${option.entity.supply.id}`,
                            },
                        })
                    );
                    break;
                case "ChannelAdUnit":
                    dispatch(
                        setSelectedRowAndExpandedRowKeys({
                            selectedRow: {
                                key: `Channel-${option.entity.supply.brand.id}`,
                                entity: option.entity.supply.brand,
                                shouldScrollTo: true,
                                isLoading: true,
                            },
                            expandedRowKeys: {
                                seatId: `Seat-${option.entity.supply.brand.publisher.seat.id}`,
                                publisherId: `Publisher-${option.entity.supply.brand.publisher.id}`,
                                brandId: null,
                                supplyId: null,
                            },
                        })
                    );
                    history.push(
                        ROUTE_FORMATTERS.SEAT_INVENTORY_HEALTH_DETAILS_UNIT(
                            option.entity.supply.brand.publisher.seat.id,
                            InventoryDetailsDrawerType.AD_UNIT,
                            option.entity.supply.brand.publisher.id,
                            option.entity.supply.brand.id,
                            option.entity.supply.id,
                            option.entity.id
                        ) || ""
                    );
                default:
                    return;
            }
        }
    };

    const handleScroll = ({ target }) => {
        const isEndOfList = target.scrollTop + target.offsetHeight === target.scrollHeight;
        if (isEndOfList && data?.length && !isFetching) {
            setPage(page + 1);
        }
    };

    useEffect(() => {
        ref.current = options;
    }, [options]);

    return {
        handleScroll,
        handleChangeSearch,
        handleSearchSelected,
        isLoading: isFetching,
        options,
        search,
    };
};
