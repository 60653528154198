import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import moment from "moment-timezone";
import { DealStatus, Currency, SeatAdSourcesStatus, TimeZone } from "@app/core/services";
import { RootState } from "@app/core/store";
import { SyncedFieldKey, SyncedFields } from "./types";

export interface SyncedFieldsState {
    startDate: moment.Moment | null | undefined;
    endDate: moment.Moment | null | undefined;
    timeZone: TimeZone | null | undefined;
    cpmRate: string | null | undefined;
    status: DealStatus | SeatAdSourcesStatus | null | undefined;
    currency: Currency | null | undefined;
    impressions: number | string | null | undefined;
}

interface SyncedFieldsStoreState {
    fields: SyncedFieldsState;
    isAdSourceOutOfSync: boolean;
    isSyncingAdSourceChangesToDeal: boolean;
}

const initialState: SyncedFieldsStoreState = {
    fields: {
        startDate: undefined,
        endDate: undefined,
        timeZone: undefined,
        cpmRate: undefined,
        status: undefined,
        currency: undefined,
        impressions: undefined,
    } as const,
    isAdSourceOutOfSync: false,
    isSyncingAdSourceChangesToDeal: false,
} as const;

const syncedFieldsSlice = createSlice({
    name: "syncedFields",
    initialState,
    reducers: {
        consumeAllSyncedFieldsState: (state) => {
            Object.keys(state.fields).forEach((fieldKey) => {
                state.fields[fieldKey] = initialState.fields[fieldKey];
            });
        },
        consumeSyncedFieldState: (state, action: PayloadAction<SyncedFieldKey>) => {
            state.fields[action.payload] = undefined;
        },
        updateSyncedFieldsState: (state, action: PayloadAction<SyncedFields>) => {
            state.fields = {
                ...state.fields,
                ...action.payload,
            };
        },
        updateIsAdSourceOutOfSyncState: (state, action: PayloadAction<boolean>) => {
            state.isAdSourceOutOfSync = action.payload;
        },
        updateIsSyncingAdSourceChangesToDeal: (state, action: PayloadAction<boolean>) => {
            state.isSyncingAdSourceChangesToDeal = action.payload;
        },
    },
});

export const selectSyncedFields = (rootState: RootState): SyncedFields => {
    const syncedFieldsState = rootState.syncedFields.fields;
    return Object.keys(syncedFieldsState).reduce(
        (updatedFields, fieldKey) =>
            syncedFieldsState[fieldKey] === undefined
                ? updatedFields
                : { ...updatedFields, [fieldKey]: syncedFieldsState[fieldKey] },
        {}
    );
};
export const selectIsAdSourceOutOfSync = (rootState: RootState): boolean => rootState.syncedFields.isAdSourceOutOfSync;
export const selectIsSyncingAdSourceChangesToDeal = (rootState: RootState): boolean =>
    rootState.syncedFields.isSyncingAdSourceChangesToDeal;

export const {
    consumeAllSyncedFieldsState,
    consumeSyncedFieldState,
    updateSyncedFieldsState,
    updateIsAdSourceOutOfSyncState,
    updateIsSyncingAdSourceChangesToDeal,
} = syncedFieldsSlice.actions;

export const syncedFieldsReducer = syncedFieldsSlice.reducer;
