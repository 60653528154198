import { FC } from "react";
import { BlockedIndustriesSelect } from "@app/core/components";
import { FORM_FIELDS } from "../../constants";
import { useAddOverrideIndustriesSelect } from "./useAddOverrideIndustriesSelect";

export const AddOverrideIndustriesSelect: FC = () => {
    const { isDisabled, isRequired, label } = useAddOverrideIndustriesSelect();

    return (
        <BlockedIndustriesSelect
            name={FORM_FIELDS.ADD_OVERRIDE_INDUSTRIES.name}
            label={label}
            disabled={isDisabled}
            required={isRequired}
            placeholder="Select Industries"
        />
    );
};
