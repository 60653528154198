import { FC } from "react";
import { Collapse } from "antd";
import { AD_SOURCE_SECTIONS, AD_SOURCE_SECTIONS_NAME, mainStepSectionClassList } from "../../../constants";
import { DefinitionSidePanel } from "../../DefinitionSidePanel";
import { useIsPbsDealType } from "../../useIsPbsDealType";
import { DealObjectSection, DemandConfigurationSection } from "./DealFormSections";
import { ItemType } from "rc-collapse/es/interface";

export const DealFormContent: FC<{ readonly?: boolean }> = ({ readonly = false }) => {
    const isPbsDealType = useIsPbsDealType();
    return (
        <Collapse
            defaultActiveKey={["1", "2"]}
            bordered
            ghost={false}
            collapsible="header"
            className={mainStepSectionClassList}
            items={
                [
                    {
                        key: "1",
                        forceRender: true,
                        label: !isPbsDealType && !readonly ? "Step 1 of 2: Deal Object" : "Deal Object",
                        children: <DealObjectSection />,
                    },
                    !isPbsDealType && {
                        key: "2",
                        forceRender: true,
                        label: !readonly ? "Step 2 of 2: Demand Configuration" : "Demand Configuration",
                        extra: (
                            <DefinitionSidePanel
                                panelLabel={AD_SOURCE_SECTIONS_NAME[AD_SOURCE_SECTIONS.DEMAND_CONFIGURATION]}
                                section={AD_SOURCE_SECTIONS.DEMAND_CONFIGURATION}
                                sectionSelector="#demandConfigurationForm"
                                elementType="label"
                            />
                        ),
                        children: <DemandConfigurationSection />,
                    },
                ].filter(Boolean) as ItemType[]
            }
        />
    );
};
