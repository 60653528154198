import { Form } from "antd";
import { useMemo } from "react";
import { useParams } from "react-router-dom";
import { useUserAccess } from "@app/core/auth";
import { LabeledValue } from "antd/lib/select";
import { useGetDmpsQuery } from "@app/core/services";
import { getDmpTypeLabel } from "@app/features/inventory/helpers";
import { INVENTORY_FORM_FIELDS } from "@app/features/inventory/InventorySeat/constants";

const { useWatch } = Form;

interface UseEnableDmpList {
    isFetching: boolean;
    options: LabeledValue[];
    hasInternalAccess: boolean;
}

export const useEnableDmpList = (): UseEnableDmpList => {
    const { seatId } = useParams<{ seatId: string }>();
    const { data, isFetching } = useGetDmpsQuery(seatId);
    const { isSysAdmin, isPubAcctMgr } = useUserAccess();
    const dmps: LabeledValue[] = useMemo(
        () =>
            (data || []).map((dmp) => ({
                label: getDmpTypeLabel(dmp),
                value: dmp.id,
            })),
        [data]
    );
    const dmpList = useWatch(INVENTORY_FORM_FIELDS.DMPS.name) || [];
    const values = dmpList.map((dmp) => dmp?.value);
    const options = dmps.filter((options) => !values.includes(options.value));

    return {
        options,
        isFetching,
        hasInternalAccess: isPubAcctMgr || isSysAdmin,
    };
};
