import { AdSource, FinanceDefault, SeatTransparencyDefault } from "@app/core/services";
import { getDefaultCostModel } from "@app/features/seatAdSources/SeatAdSourcesForm/utils/getDefaultCostModel";
import { CREATE_AD_SOURCE_TYPES_COPY, CREATE_AD_SOURCE_TYPES_NEW, CREATE_DEAL_FORM_ITEMS_NAME } from "../constants";
import {
    getOverrideValue,
    getMaskedSharedHiddenValue,
    getSharedHiddenValue,
    getSpecificValue,
    getTransparenciesValues,
} from "./dealFormReducerUtils";
import { DealFormAdSourceValues, DealFormMode, DealFormValues } from "./types";
import {
    DEFAULT_AD_SOURCE_AUCTION_TYPE,
    DEFAULT_AD_SOURCE_AUTOSCALE,
    DEFAULT_AD_SOURCE_EXTEND_TIMEOUT,
    DEFAULT_AD_SOURCE_FLOOR_TYPE,
    DEFAULT_AD_SOURCE_PRIRITY_ID,
    DEFAULT_AD_SOURCE_RUNNING_STATUS_ID,
    DEFAULT_AD_SOURCE_SEND_FLOOR,
    DEFAULT_AD_SOURCE_STRICT_MODE,
    DEFAULT_USD_CURRENCY_ID,
} from "./useDealForm";
import { formatCategoryLabel } from "@app/features/targeting";
import { TargetingDimensionTypeLabels } from "@app/features/targeting/constants";
import { parseDateStringFromApiAsUtcFreeString } from "@app/core/utils";
import { parsePacingFromApi } from "@app/features/seatAdSources/SeatAdSourcesForm/utils/pacingUtils";
import { parseEnrichmentCostFieldsFromApi } from "@app/features/seatAdSources/SeatAdSourcesForm/utils/useParseEnrichmentCostFields";
import { parseBlockedIndustriesFromApi } from "@app/core/components";

export const getAdSourceCost = (
    costValue:
        | {
              costValuePercent: number | null;
              costValueFixed: number | null;
          }
        | undefined
        | null
) => {
    return costValue?.costValuePercent || (costValue?.costValueFixed || 0) / 1000 || null;
};

export const getValuesFromDealAdSource = (
    adSource: AdSource | undefined,
    seatFinanceDefaults: FinanceDefault[] | undefined,
    isCopyingAdSource,
    mode: DealFormMode
): DealFormAdSourceValues => {
    const defaultCostModel = getDefaultCostModel(adSource?.type.id, seatFinanceDefaults);
    const {
        pacingType,
        redistributePacing,
        dailyCapVolume,
        dailyRequestOpportunityFallback,
        customCurveStartDate,
        customCurveImps,
        dailyCapEnabled,
    } = parsePacingFromApi(adSource);
    const { demandAcquisitionCost, demandAcquisitionCostModel } = parseEnrichmentCostFieldsFromApi(adSource);

    return {
        [CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_NAME]:
            isCopyingAdSource || mode === "copy" ? `Copy of ${adSource?.name ?? ""}` : adSource?.name ?? "",
        [CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_DESCRIPTION]: adSource?.description ?? "",
        [CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_PRIORITY]: adSource?.priority?.id ?? DEFAULT_AD_SOURCE_PRIRITY_ID,
        [CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_STATUS]: adSource?.status?.id ?? DEFAULT_AD_SOURCE_RUNNING_STATUS_ID,
        [CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_EXTEND_TIMEOUT]:
            adSource?.nurlTimeoutOverride ?? DEFAULT_AD_SOURCE_EXTEND_TIMEOUT,
        [CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_ASSIGNEE]: adSource?.assignee?.id ?? null,

        // Ad Source Admin section
        [CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_COST_MODEL]:
            adSource?.costModel?.id ?? defaultCostModel?.costModel.id ?? null,
        [CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_COST]:
            getAdSourceCost(adSource) ?? getAdSourceCost(defaultCostModel) ?? null,
        [CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_AUTOSCALE]: adSource?.allowAutoscale ?? DEFAULT_AD_SOURCE_AUTOSCALE,
        [CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_REGION]: adSource?.region?.id ?? null,
        [CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_SALES_CONTACT]: adSource?.salesContact?.id ?? null,
        [CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_EXTERNAL_CONTACT_NUMBER]: adSource?.externalContractId ?? "",

        // Ad Source Floor Settings section
        [CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_FLOOR_TYPE]:
            adSource?.adSourceFloorType?.id ?? DEFAULT_AD_SOURCE_FLOOR_TYPE,
        [CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_BOOKING_PRICE]: (adSource && adSource?.bookingPrice / 1000) ?? null,
        [CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_AUCTION_TYPE]:
            adSource?.auctionType?.id ?? DEFAULT_AD_SOURCE_AUCTION_TYPE,
        [CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_ALWAYS_SEND_FLOOR]:
            adSource?.alwaysSendFloor ?? DEFAULT_AD_SOURCE_SEND_FLOOR,
        [CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_RUN_UNDER_INBOUND_FLOOR]: adSource?.overrideDynamicFloor ? true : false,
        [CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_CURRENCY]: adSource?.currencyType.id ?? DEFAULT_USD_CURRENCY_ID,
        [CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_AD_RESPONSE_PRICE_OVERRIDE]:
            adSource?.adResponsePriceOverrideType?.id ?? null,

        // Ad Source Pacing section
        [CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_PACING_TYPE]: pacingType?.id ?? null,
        [CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_PACING_DAILY_IMPRESSION_CAP_ENABLED]: dailyCapEnabled,
        [CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_PACING_IMPRESSION_CAP]: dailyCapVolume,
        [CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_PACING_FALLBACK]: dailyRequestOpportunityFallback,
        [CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_PACING_REDISTRIBUTE]: redistributePacing,
        [CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_PACING_CUSTOM_CURVE_IMPS]: customCurveImps,
        [CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_PACING_CUSTOM_CURVE_START_DATE]: customCurveStartDate,

        // Ad Source Delivery Details section
        [CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_BOOKING_START_DATE]: adSource?.bookingStartDate
            ? parseDateStringFromApiAsUtcFreeString(adSource.bookingStartDate, adSource?.timeZone?.code)
            : null,
        [CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_BOOKING_END_DATE]: adSource?.bookingEndDate
            ? parseDateStringFromApiAsUtcFreeString(adSource.bookingEndDate, adSource?.timeZone?.code)
            : null,
        [CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_TIME_ZONE]: adSource?.timeZone?.id ?? null,
        [CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_INVENTORY_DISTRIBUTION_GROUPS]:
            adSource?.inventoryDistributionGroups ?? [],

        //Ad Source Brand Safety section
        [CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_IAB_CATEGORY_BLOCK_MODE]: adSource?.iabCategoryBlockMode?.id ?? null,
        [CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_IAB_CATEGORIES]: (adSource?.blockedIabCategories || []).map(
            (item) => item
        ),
        [CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_BLOCK_EXCEPTION_IAB_CATEGORIES]:
            adSource?.blockExceptionIabCategories?.map((category) => ({
                value: category.id,
                label: formatCategoryLabel(category, TargetingDimensionTypeLabels.ContentCategories),
            })) ?? [],
        [CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_MODE]: adSource?.advertiserBlockingMode?.id ?? null,
        [CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_REUSABLE_ADVERTISER_DOMAIN]: (
            adSource?.seatAdvertiserDomainFilterListDefs || []
        ).map((item) => item?.id),
        [CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_ADDITIONAL_ADVERTISER_DOMAIN]:
            adSource?.advertiserBlockDomains?.join("\n") ?? "",
        [CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_INDUSTRIES_MODE]: adSource?.industryBlockMode?.id || null,
        [CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_BLOCKED_INDUSTRIES]: parseBlockedIndustriesFromApi(
            adSource?.blockedIndustries
        ),

        // Ad Source Custom Pixels section
        [CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_PIXELS]: (adSource?.thirdPartyPixels || []).map((item) => item?.id),

        // Ad Source Transparency section
        [CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_ALLOW_SITE_NAME]: getSharedHiddenValue(adSource?.allowSiteName),
        [CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_ALLOW_DOMAIN_NAME]: getOverrideValue(
            adSource?.allowDomainName,
            !!adSource?.domainNameOverride
        ),
        [CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_DOMAIN_NAME_OVERRIDE]: adSource?.domainNameOverride ?? "",
        [CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_ALLOW_SITE_PAGE]: getSharedHiddenValue(adSource?.allowSitePage),
        [CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_ALLOW_REF]: getSharedHiddenValue(adSource?.allowRef),
        [CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_ALLOW_IP]: getSharedHiddenValue(adSource?.allowIp),
        [CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_ALLOW_BUNDLE_ID]: getOverrideValue(
            adSource?.allowBundleId,
            !!adSource?.bundleIdOverride
        ),
        [CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_BUNDLE_ID_OVERRIDE]: adSource?.bundleIdOverride ?? "",
        [CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_ALLOW_STORE_URL]: getSharedHiddenValue(adSource?.allowStoreUrl),
        [CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_ALLOW_USER_ID]: getSharedHiddenValue(adSource?.allowUserId),
        [CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_ALLOW_CONTENT]: getSharedHiddenValue(adSource?.allowContent),
        [CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_ALLOW_ALL_EXTENDED_ID]: getSpecificValue(
            adSource?.allowAllExtendedId,
            adSource?.allowedExtendedIdTransparencies
        ),
        [CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_ALLOWED_EXTENDED_ID_TRANSPARENCIES]: getTransparenciesValues(
            adSource?.allowedExtendedIdTransparencies || []
        ),
        [CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_CONTENT_TRANSPARENCY_RULE]: adSource?.contentTransparencyRule ?? null,
        [CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_OZTAM_CO_VIEWING]: Boolean(adSource?.allowOztamCoviewing),
        [CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_ALLOW_AUDIENCE_LOCK]: Boolean(adSource?.allowAudienceLock),

        // Ad Source Label section
        [CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_STRICT_MODE]: adSource?.strictMode ?? DEFAULT_AD_SOURCE_STRICT_MODE,
        [CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_LABELS]: adSource?.labelValues ?? [],

        [CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_MODE_CONFIGURATION]:
            isCopyingAdSource || mode === "copy" ? CREATE_AD_SOURCE_TYPES_COPY : CREATE_AD_SOURCE_TYPES_NEW, //TODO if reuse in dealFormReduceUtils add mode and add conditional value
        [CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_COPY_ID]:
            isCopyingAdSource || mode === "copy" ? adSource?.id ?? null : null,

        // Ad Source targeting section
        [CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_TARGETING]: adSource?.targeting ?? null,

        // Ad Source enrichment cost section
        [CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_DEMAND_ACQUISITION_COST]: demandAcquisitionCost,
        [CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_DEMAND_ACQUISITION_COST_MODEL]: demandAcquisitionCostModel?.id || null,
    };
};

export function getTransparencyValuesFromDefaults(
    transparencyDefaults: SeatTransparencyDefault
): Partial<DealFormValues> {
    const defaultSupplyNameAppName = getSharedHiddenValue(transparencyDefaults?.allowSiteName);
    const defaultDomainName = getSharedHiddenValue(transparencyDefaults?.allowDomainName);
    const defaultSupplyPage = getSharedHiddenValue(transparencyDefaults?.allowSitePage);
    const defaultReferUrl = getSharedHiddenValue(transparencyDefaults?.allowRef);
    const defaultFullIpAddress = getSharedHiddenValue(transparencyDefaults?.allowIp);
    const defaultBundleId = getSharedHiddenValue(transparencyDefaults?.allowBundleId);
    const defaultStoreUrl = getSharedHiddenValue(transparencyDefaults?.allowStoreUrl);
    const defaultUserId = getMaskedSharedHiddenValue(
        transparencyDefaults?.maskUserId,
        transparencyDefaults?.allowUserId
    );
    const defaultContent = transparencyDefaults?.contentTransparencyRule?.id
        ? String(transparencyDefaults.contentTransparencyRule.id)
        : getSharedHiddenValue(transparencyDefaults?.allowContent);
    const defaultContentTransparencyRule =
        typeof Number(defaultContent) === "number" ? transparencyDefaults?.contentTransparencyRule : null;
    const defaultExtentedUserId = getSpecificValue(
        transparencyDefaults?.allowAllExtendedId,
        transparencyDefaults?.allowedExtendedIdTransparencies
    );
    return {
        [CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_ALLOW_SITE_NAME]: defaultSupplyNameAppName,
        [CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_ALLOW_DOMAIN_NAME]: defaultDomainName,
        [CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_ALLOW_SITE_PAGE]: defaultSupplyPage,
        [CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_ALLOW_REF]: defaultReferUrl,
        [CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_ALLOW_IP]: defaultFullIpAddress,
        [CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_ALLOW_BUNDLE_ID]: defaultBundleId,
        [CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_ALLOW_STORE_URL]: defaultStoreUrl,
        [CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_ALLOW_USER_ID]: defaultUserId,
        [CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_ALLOW_CONTENT]: defaultContent,
        [CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_CONTENT_TRANSPARENCY_RULE]: defaultContentTransparencyRule,
        [CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_ALLOW_ALL_EXTENDED_ID]: defaultExtentedUserId,
    };
}
