import { GUTTER, COL_SIZES } from "@app/features/deals/constants";
import {
    AdSourceFormsContext,
    DemandConfigurationForm,
} from "@app/features/seatAdSources/SeatAdSourcesForm/SeatAdSourcesForm";
import { Card, Col, Form, Row } from "antd";
import { FC, useContext } from "react";
import { EnforcementSelect, FrequencyCapping, TransactionCurrencySelect } from "./Fields";
import { LegacyDealSubsection } from "./LegacyDealSubsection";
import { TargetingSettings } from "./TargetingSettings";
import { useDemandConfigurationForm } from "./useDemandConfigurationForm";

export const DemandConfigurationSection: FC = () => {
    const { forms } = useContext(AdSourceFormsContext);
    const { demandConfigurationForm } = forms;
    const { isLegacyDeal, initValues, isProgrammaticGuaranteedOrLegacy } = useDemandConfigurationForm();
    return (
        <Form<DemandConfigurationForm>
            initialValues={initValues}
            name="demandConfigurationForm"
            layout="vertical"
            form={demandConfigurationForm}
            scrollToFirstError={{ behavior: "smooth", block: "center" }}
        >
            <Card bordered={false}>
                {isLegacyDeal && (
                    <LegacyDealSubsection isProgrammaticGuaranteedOrLegacy={isProgrammaticGuaranteedOrLegacy} />
                )}

                <Row gutter={GUTTER}>
                    <Col {...COL_SIZES}>
                        <TransactionCurrencySelect />
                    </Col>
                </Row>
                <Row gutter={GUTTER}>
                    <Col {...COL_SIZES}>
                        <EnforcementSelect />
                    </Col>
                </Row>
                <Row gutter={GUTTER}>
                    <Col span={24}>
                        <FrequencyCapping />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <TargetingSettings />
                    </Col>
                </Row>
            </Card>
        </Form>
    );
};
