import { ChangeEvent, FC, useMemo, useState } from "react";
import { Col, Form, Row, Space, Table, Typography } from "antd";
import { css } from "@emotion/css";
import { AdSource } from "@app/core/services/console/adSources";
import { DealDrawer } from "../SeatAdSourcesForm/DealFormDrawer";
import {
    AdSourcesForm,
    DealObjectForm,
    DemandConfigurationForm,
    AdSourceFormsContext,
    MarketplaceInfoContext,
} from "../SeatAdSourcesForm";
import { Mode, useAdSourceForm } from "../SeatAdSourcesForm/useAdSourceForm";
import { MarketplaceInfoDrawerMode, useMarketplaceInfoWidget } from "../SeatAdSourcesForm/useMarketplaceInfoWidget";
import { TableSearchInput } from "@app/core/components";
import { useSeatAdSourcesDealCountTable } from "./useSeatAdSourcesDealCountTable";
import { getTableColumns } from "../SeatAdSourcesForm/AdSourcesSections";

interface Props {
    adSource: AdSource;
}

const DealCountTableContent: FC<Props> = ({ adSource }) => {
    const { deals, onSearchChange, onSortChange, isSingleInstanceOnly, totalPaginationProps } =
        useSeatAdSourcesDealCountTable(adSource);
    useAdSourceForm({ adSourceOrigin: adSource });
    const { setViewMode } = useMarketplaceInfoWidget();

    const columns = useMemo(
        () => getTableColumns({ adSourceTypeId: adSource.type?.id, setViewMode }),
        [adSource.type?.id, setViewMode]
    );

    return (
        <Space
            data-sdet="seat-ad-sources-demand-details-table"
            direction="vertical"
            size="middle"
            style={{ width: "100%" }}
        >
            {!isSingleInstanceOnly && (
                <Row justify="space-between">
                    <Col>
                        <TableSearchInput
                            entityType="Deal"
                            onChange={(e: ChangeEvent<HTMLInputElement>) => onSearchChange(e.target.value)}
                            style={{ width: "300px" }}
                        />
                    </Col>
                </Row>
            )}
            <Row>
                <Table
                    size="small"
                    bordered
                    columns={columns}
                    dataSource={deals}
                    pagination={isSingleInstanceOnly ? false : totalPaginationProps}
                    onChange={onSortChange}
                    showSorterTooltip={false}
                    scroll={{ x: 1750 }}
                />
                <Typography.Text
                    className={css`
                        margin-top: -40px;
                    `}
                >
                    * Inherited from Ad Source
                </Typography.Text>
            </Row>
            <DealDrawer readonly adSourceName={adSource.name} />
        </Space>
    );
};

const AdSourceFormsProvidersWrapper: FC = ({ children }) => {
    const [adSourceForm] = Form.useForm<AdSourcesForm>();
    const [dealObjectForm] = Form.useForm<DealObjectForm>();
    const [demandConfigurationForm] = Form.useForm<DemandConfigurationForm>();
    const marketplaceInfoModeState = useState<MarketplaceInfoDrawerMode>({ name: "closed" });
    const dealFetchingState = useState<boolean>(false);
    const dealObjectModeState = useState<Mode>("view");
    return (
        <AdSourceFormsContext.Provider
            value={{
                forms: {
                    adSourceForm,
                    dealObjectForm,
                    demandConfigurationForm,
                    adSourceFormMode: "edit",
                },
            }}
        >
            <MarketplaceInfoContext.Provider
                value={{ marketplaceInfoModeState, dealObjectModeState, dealFetchingState }}
            >
                {children}
            </MarketplaceInfoContext.Provider>
        </AdSourceFormsContext.Provider>
    );
};

export const SeatAdSourcesDealCountTable: FC<Props> = ({ adSource }) => {
    return (
        <AdSourceFormsProvidersWrapper>
            <DealCountTableContent adSource={adSource} />
        </AdSourceFormsProvidersWrapper>
    );
};
