import { FC } from "react";
import { Tag, Typography } from "antd";
import { Seat } from "@app/core/services";
import { NONE } from "@app/core/components/constants";
import { INVENTORY_FORM_FIELDS } from "@app/features/inventory/InventorySeat/constants";
import {
    getDmpTypeLabel,
    getSeatLabelValueLabel,
    getAuthorizedExportStreamLabel,
} from "@app/features/inventory/helpers";

type Property =
    | typeof INVENTORY_FORM_FIELDS.EXTERNAL_AD_UNIT_CODE_NAMESPACES.name
    | typeof INVENTORY_FORM_FIELDS.BLOCK_ADVERTISER_DOMAIN_LISTS.name
    | typeof INVENTORY_FORM_FIELDS.AUDIENCE_LOCK_ALLOWED_DOMAIN.name
    | typeof INVENTORY_FORM_FIELDS.SEAT_CONTENT_METADATA_KEYS.name
    | typeof INVENTORY_FORM_FIELDS.ALLOWED_FMT_JSON_OPTIONS.name
    | typeof INVENTORY_FORM_FIELDS.AUTHORIZED_MARKETPLACES.name
    | typeof INVENTORY_FORM_FIELDS.VIEWABILITY_MESUREMENT.name
    | typeof INVENTORY_FORM_FIELDS.AUTHORIZED_PBS_SERVERS.name
    | typeof INVENTORY_FORM_FIELDS.EXTERNAL_DATA_STREAMS.name
    | typeof INVENTORY_FORM_FIELDS.SUPPLY_DOMAIN_FILTER.name
    | typeof INVENTORY_FORM_FIELDS.BUNDLE_ID_FILTER.name
    | typeof INVENTORY_FORM_FIELDS.INTERNAL_LABELS.name
    | typeof INVENTORY_FORM_FIELDS.CUSTOM_PIXELS.name
    | typeof INVENTORY_FORM_FIELDS.COUNT_LIMITS.name
    | typeof INVENTORY_FORM_FIELDS.LABELS.name
    | typeof INVENTORY_FORM_FIELDS.QPS_LIMITS.name
    | typeof INVENTORY_FORM_FIELDS.DMPS.name;

interface ConditionalTagRenderProps {
    data: Seat;
    property: Property;
}

export const ConditionalTagRender: FC<ConditionalTagRenderProps> = ({ data, property }) => {
    if (!data[property]?.length || !property) return <>{NONE}</>;

    switch (property) {
        case INVENTORY_FORM_FIELDS.EXTERNAL_AD_UNIT_CODE_NAMESPACES.name:
            return (
                <>
                    {data[property].map(({ id, name }) => {
                        return <Tag key={id}>{name}</Tag>;
                    })}
                </>
            );
        case INVENTORY_FORM_FIELDS.ALLOWED_FMT_JSON_OPTIONS.name:
            return (
                <>
                    {data[property].map(({ id, name }) => {
                        return <Tag key={id}>{name}</Tag>;
                    })}
                </>
            );
        case INVENTORY_FORM_FIELDS.SEAT_CONTENT_METADATA_KEYS.name:
            return (
                <>
                    {data[property].map(({ contentKey }) => {
                        return <Tag key={contentKey}>{contentKey}</Tag>;
                    })}
                </>
            );
        case INVENTORY_FORM_FIELDS.AUTHORIZED_MARKETPLACES.name:
            return (
                <>
                    {data[property].map(({ name, id }) => {
                        return <Tag key={id}>{name}</Tag>;
                    })}
                </>
            );
        case INVENTORY_FORM_FIELDS.EXTERNAL_DATA_STREAMS.name:
            return (
                <>
                    {data[property].map((stream) => {
                        return <Tag key={stream.id}>{getAuthorizedExportStreamLabel(stream)}</Tag>;
                    })}
                </>
            );
        case INVENTORY_FORM_FIELDS.DMPS.name:
            return (
                <>
                    {data[property].map((dmp) => {
                        return <Tag key={dmp.id}>{getDmpTypeLabel(dmp)}</Tag>;
                    })}
                </>
            );
        case INVENTORY_FORM_FIELDS.VIEWABILITY_MESUREMENT.name:
            return (
                <>
                    {data[property].map(({ vendor, sampleRate }, index) => {
                        return (
                            <Tag key={index}>
                                {vendor.name} - {sampleRate}
                            </Tag>
                        );
                    })}
                </>
            );
        case INVENTORY_FORM_FIELDS.COUNT_LIMITS.name:
            return (
                <>
                    {data[property].map(({ adSourceType: { name }, maxAllowed }, index) => {
                        return (
                            <Tag key={index}>
                                {name} - {maxAllowed}
                            </Tag>
                        );
                    })}
                </>
            );
        case INVENTORY_FORM_FIELDS.SUPPLY_DOMAIN_FILTER.name:
            return (
                <>
                    {data[property].map(({ id, name }) => {
                        return (
                            <Tag key={id}>
                                {/* TODO: Make internal link when admin is implemented */}
                                <Typography.Link
                                    target="_blank"
                                    rel="noreferrer"
                                    href={`https://console.telaria.com/ssp/admin/supplyfilters/supplydomains/${id}`}
                                >
                                    {name}
                                </Typography.Link>
                            </Tag>
                        );
                    })}
                </>
            );
        case INVENTORY_FORM_FIELDS.BUNDLE_ID_FILTER.name:
            return (
                <>
                    {data[property].map(({ id, name }) => {
                        return (
                            <Tag key={id}>
                                {/* TODO: Make internal link when admin is implemented */}
                                <Typography.Link
                                    target="_blank"
                                    rel="noreferrer"
                                    href={`https://console.telaria.com/ssp/admin/supplyfilters/bundleids/${id}`}
                                >
                                    {name}
                                </Typography.Link>
                            </Tag>
                        );
                    })}
                </>
            );
        case INVENTORY_FORM_FIELDS.QPS_LIMITS.name:
            return (
                <>
                    {data[property].map(({ qpsLimit, awsRegion: { name } }, i) => {
                        return (
                            <Tag key={i}>
                                {name} - {qpsLimit}
                            </Tag>
                        );
                    })}
                </>
            );
        case INVENTORY_FORM_FIELDS.AUDIENCE_LOCK_ALLOWED_DOMAIN.name:
            return (
                <>
                    {data[property]?.map((item, i) => {
                        return <Tag key={i}>{item}</Tag>;
                    })}
                </>
            );
        case INVENTORY_FORM_FIELDS.BLOCK_ADVERTISER_DOMAIN_LISTS.name:
            return (
                <>
                    {data[property].map(({ id, name }) => {
                        return <Tag key={id}>{name}</Tag>;
                    })}
                </>
            );
        case INVENTORY_FORM_FIELDS.AUTHORIZED_PBS_SERVERS.name:
            return (
                <>
                    {data[property].map(({ id, name }) => {
                        return <Tag key={id}>{name}</Tag>;
                    })}
                </>
            );
        case INVENTORY_FORM_FIELDS.CUSTOM_PIXELS.name:
            return (
                <>
                    {data[property].map(({ id, name }) => {
                        return <Tag key={id}>{name}</Tag>;
                    })}
                </>
            );
        case INVENTORY_FORM_FIELDS.LABELS.name:
            return (
                <>
                    {data[property].map(({ label, value, id }) => {
                        return <Tag key={id}>{getSeatLabelValueLabel(label, value)}</Tag>;
                    })}
                </>
            );
        case INVENTORY_FORM_FIELDS.INTERNAL_LABELS.name:
            return (
                <>
                    {data[property].map(({ label, value, id }) => {
                        return <Tag key={id}>{getSeatLabelValueLabel(label, value)}</Tag>;
                    })}
                </>
            );
        default:
            return <>{NONE}</>;
    }
};
