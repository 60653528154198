import { FC } from "react";
import { Form, Input } from "antd";
import { FORM_FIELDS } from "../../constants";

const { name, label, max } = FORM_FIELDS.CRID;

export const CridInput: FC = () => {
    return (
        <Form.Item
            name={name}
            label={label}
            rules={[
                { required: true, message: `${label} is required` },
                { max: 128, message: `${label} cannot exceed ${max} characters` },
            ]}
        >
            <Input placeholder={`Enter ${label}`} />
        </Form.Item>
    );
};
