import { useGetExtendedIdsQuery } from "@app/core/services";
import { css } from "@emotion/css";
import { Select } from "antd";
import { LabeledValue } from "antd/es/select";
import { FC, useMemo } from "react";
import { useParams } from "react-router-dom";

const classNameSelect = css`
    &.ant-select.ant-select-in-form-item {
        width: 189px;
    }
`;
const DISABLED_SOURCE_ID = 2;

export interface SourceOptions extends LabeledValue {
    code: string;
}

interface Props {
    value: SourceOptions | null;
    disabledSourceId?: number;
    handleChange: (value: SourceOptions | null) => void;
}
export const SelectSource: FC<Props> = ({ value, handleChange, disabledSourceId }) => {
    const { seatId } = useParams<{ seatId: string }>();
    const { isFetching: isLoadingSource, data: sourceData } = useGetExtendedIdsQuery(Number(seatId));
    const sourceOptions = useMemo(
        () =>
            (sourceData || [])
                .filter(({ id }) => id !== DISABLED_SOURCE_ID)
                .map(({ id, name, code }) => ({ label: name, value: id, code, disabled: id === disabledSourceId })),
        [disabledSourceId, sourceData]
    );

    const onChange = (value: SourceOptions) => {
        handleChange(value);
    };

    return (
        <Select
            allowClear
            placeholder="Select Source"
            options={sourceOptions}
            loading={isLoadingSource}
            onChange={(_, options) => onChange(options as SourceOptions)}
            className={classNameSelect}
            data-sdet="select-source"
            labelInValue
            value={value}
        />
    );
};
