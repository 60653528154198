import { Form } from "antd";
import { IndustryBlockMode, useGetIndustryBlockingModesQuery } from "@app/core/services";
import { AD_SOURCE_FIELDS, AdSourceAdvertiserDomainModeIds } from "@app/features/seatAdSources/constants";

interface UseIndustriesModeSelect {
    options: IndustryBlockMode[];
    isLoading: boolean;
    isDisabled: boolean;
}

export const useIndustriesModeSelect = (): UseIndustriesModeSelect => {
    const { data: options = [], isLoading } = useGetIndustryBlockingModesQuery();
    const advertiserDomainModeId = Form.useWatch(AD_SOURCE_FIELDS.MODE.name)?.id;
    const isDisabled = advertiserDomainModeId === AdSourceAdvertiserDomainModeIds.ALLOW_OVERRIDE_INVENTORY;

    return {
        options,
        isLoading,
        isDisabled,
    };
};
