import { FC } from "react";
import { Flex, Typography } from "antd";
import { ResizableDrawer } from "@rubicon/antd-components";
import { GoToLink } from "@app/core/components";
import { drawerRightClosePosition } from "@app/features/controls/constants";
import { MappingExceptionsActions } from "../MappingExceptionsActions";
import { useMappingExceptionsDetailsDrawer } from "./useMappingExceptionsDetailsDrawer";
import { MappingExceptionsReadOnlyForm } from "../MappingExceptionsForm";

export const MappingExceptionsDetailsDrawer: FC = () => {
    const { open, mappingException, detailsPageUrl, onClose, afterOpenChange } = useMappingExceptionsDetailsDrawer();

    if (!mappingException) {
        return null;
    }

    return (
        <ResizableDrawer
            open={open}
            onClose={onClose}
            afterOpenChange={afterOpenChange}
            className={drawerRightClosePosition}
            placement="right"
            width="45%"
            title={
                <Flex vertical gap="small" style={{ marginRight: "1rem" }}>
                    <Flex flex="1 1 0" justify="space-between">
                        <Flex gap="small" align="center">
                            <Typography.Title
                                level={5}
                                style={{
                                    margin: 0,
                                    maxWidth: "15rem",
                                }}
                                ellipsis={{ tooltip: { title: mappingException.network.name, placement: "bottom" } }}
                            >
                                {mappingException.network.name}
                            </Typography.Title>
                            <GoToLink targetPath={detailsPageUrl} goToText="Go To Mapping Exception" />
                        </Flex>
                    </Flex>
                    <MappingExceptionsActions mappingException={mappingException} />
                </Flex>
            }
        >
            <MappingExceptionsReadOnlyForm mappingException={mappingException} />
        </ResizableDrawer>
    );
};
