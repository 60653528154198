import { useHistory, useParams } from "react-router-dom";
import { Form, FormInstance, notification } from "antd";
import { FixedSaveButtonProps } from "@app/core/components";
import { ROUTE_FORMATTERS } from "@app/core/routing";
import {
    MappingException,
    useCreateSeatMappingExceptionMutation,
    useDeleteMappingExceptionByIdMutation,
    useUpdateMappingExceptionMutation,
} from "@app/core/services";
import { BrandSafetyDeleteModalProps } from "../../BrandSafetyDeleteModal";
import { useBrandSafetyDeleteModal } from "../../BrandSafetyDeleteModal/useBrandSafetyDeleteModal";
import { DEFAULT_FORM_VALUES } from "./constants";
import { MappingExceptionsForm } from "./types";
import { parseMappingExceptionFromApi, parseMappingExceptionToApi } from "./utils";

interface UseMappingExceptionsForm {
    form: FormInstance<MappingExceptionsForm>;
    initialValues: MappingExceptionsForm;
    deleteModalProps: BrandSafetyDeleteModalProps;
    fixedSaveButtonProps: FixedSaveButtonProps;
    onFinish: (formValues: MappingExceptionsForm) => void;
    onClickDelete: () => void;
}

export const useMappingExceptionsForm = (mappingException: MappingException | undefined): UseMappingExceptionsForm => {
    const history = useHistory();
    const { seatId } = useParams<{ seatId: string }>();
    const [createMappingExceptionQuery, { isLoading: isCreating }] = useCreateSeatMappingExceptionMutation();
    const [updateMappingExceptionQuery, { isLoading: isUpdating }] = useUpdateMappingExceptionMutation();

    const [form] = Form.useForm();

    const initialValues = mappingException ? parseMappingExceptionFromApi(mappingException) : DEFAULT_FORM_VALUES;

    const [deleteTrigger, { isLoading: isDeleting }] = useDeleteMappingExceptionByIdMutation();
    const postDeleteEvent = () =>
        history.push(ROUTE_FORMATTERS.SEAT_CONTROLS_BRAND_SAFETY_MAPPING_EXCEPTIONS_LIST_PAGE(seatId));
    const { handleOpen: handleOpenDeleteModal, ...restModalProps } = useBrandSafetyDeleteModal(
        deleteTrigger,
        postDeleteEvent
    );

    const deleteModalProps = {
        isDeleting,
        ...restModalProps,
    };

    const fixedSaveButtonProps = {
        isSaving: isCreating || isUpdating,
        submitLabel: mappingException ? "Save" : "Submit",
        onCancel: () => history.push(ROUTE_FORMATTERS.SEAT_CONTROLS_BRAND_SAFETY_MAPPING_EXCEPTIONS_LIST_PAGE(seatId)),
    };

    const onFinish = async (formValues: MappingExceptionsForm) => {
        try {
            const body = parseMappingExceptionToApi(formValues, seatId);
            const { id: mappingExceptionId } = mappingException
                ? await updateMappingExceptionQuery({ mappingExceptionId: mappingException.id, body }).unwrap()
                : await createMappingExceptionQuery({ seatId, body }).unwrap();
            history.push(
                ROUTE_FORMATTERS.SEAT_CONTROLS_BRAND_SAFETY_MAPPING_EXCEPTIONS_DETAILS_PAGE(seatId, mappingExceptionId)
            );
            notification.success({
                message: `Mapping Exception ${mappingException ? "updated" : "created"} successfully`,
            });
        } catch (error) {
            notification.error({
                message: `Failed to ${mappingException ? "update" : "create"} Mapping Exception: ${
                    error?.data?.errorDescription || error?.data?.errors?.map(({ message }) => message).join(", ")
                }`,
            });
        }
    };

    const onClickDelete = () => {
        if (mappingException) {
            handleOpenDeleteModal({ id: mappingException.id, name: mappingException.network.name });
        }
    };

    return {
        form,
        initialValues,
        deleteModalProps,
        fixedSaveButtonProps,
        onFinish,
        onClickDelete,
    };
};
