import { FC } from "react";
import { Button, Space } from "antd";
import { MappingException } from "@app/core/services";
import { BrandSafetyDeleteModal } from "../../BrandSafetyDeleteModal";
import { useMappingExceptionsActions } from "./useMappingExceptionsActions";

interface Props {
    mappingException: MappingException;
}

export const MappingExceptionsActions: FC<Props> = ({ mappingException }) => {
    const { isVisible, deleteModalProps, onClickEdit, onClickDelete } = useMappingExceptionsActions(mappingException);

    if (!isVisible) {
        return null;
    }

    return (
        <>
            <Space data-sdet="mapping-exceptions-actions">
                <Button data-sdet="edit-button" type="primary" onClick={onClickEdit}>
                    Edit
                </Button>
                <Button danger data-sdet="delete-button" type="primary" onClick={onClickDelete}>
                    Delete
                </Button>
            </Space>
            <BrandSafetyDeleteModal {...deleteModalProps} />
        </>
    );
};
