import styled from "@emotion/styled";
import { brandCobalt } from "@rubicon/antd-components";
import { Button, Typography } from "antd";
import { Link } from "react-router-dom";

export const StyledLink = styled.a`
    color: ${brandCobalt};
`;
export const StyledButtonLink = styled(Button)`
    color: ${brandCobalt};
    padding: 0;
`;

export const StyledReactLink = styled(Link)`
    color: ${brandCobalt} !important;
    word-break: break-all;
`;

export const StyledTypographyLink = styled(Typography.Link)`
    color: ${brandCobalt};
`;
