import { FC } from "react";
import { Radio } from "antd";
import { TransparencyFormItem } from "@app/features/deals/DealForm/DealFormCreateAdSource/DealFormCreateAdSourceSections/AdSourceTransparencySection/Fields";
import { useAdSourceAllowAudienceLockField } from "./useAdSourceAllowAudienceLockField";
import { AD_SOURCE_FIELDS } from "@app/features/seatAdSources/constants";

const OPTIONS = [
    { label: "Yes", value: true },
    { label: "No", value: false },
];

export const AdSourceAllowAudienceLockField: FC = () => {
    const { value, onChange } = useAdSourceAllowAudienceLockField();
    return (
        <TransparencyFormItem label={AD_SOURCE_FIELDS.ALLOW_AUDIENCE_LOCK.label} data-sdet="allow-data-lock">
            <Radio.Group onChange={(e) => onChange(e?.target?.value)} value={value} options={OPTIONS} />
        </TransparencyFormItem>
    );
};
