import { FC } from "react";
import { ROUTES } from "@app/core/routing";
import { useUserAccess } from "@app/core/auth";
import { Route, Switch } from "react-router-dom";
import {
    AdUnitCreatePage,
    AdUnitEditPage,
    AdUnitCopyPage,
    PublisherEditPage,
    PublisherCopyPage,
    PublisherCreatePage,
    BrandChannelEditPage,
    BrandChannelCreatePage,
    BrandCopyPage,
    BrandEditPage,
    BrandCreatePage,
    SupplyEditPage,
    SupplyCopyPage,
    SupplyCreatePage,
    PublisherChannelCreatePage,
    PublisherChannelEditPage,
} from "@app/features/inventory/HierarchyFormsPages";
import InventoryHealthPage from "@app/features/inventory/InventoryHealth/InventoryHealthPage";
import { InventoryHealthUnitPageSwitch } from "./InventoryHealthUnitPage/InventoryHealthUnitPageSwitch";
import { Loading } from "@app/core/components";

export const InventoryHealthSwitch: FC = () => {
    const { hasSeatWriteAccess, isLoading } = useUserAccess();

    if (isLoading) {
        return <Loading />;
    }

    return (
        <Switch>
            {hasSeatWriteAccess && (
                <Route path={ROUTES.SEAT_INVENTORY_DETAILS_PUBLISHER_CREATE}>
                    <PublisherCreatePage />
                </Route>
            )}
            {hasSeatWriteAccess && (
                <Route path={ROUTES.SEAT_INVENTORY_DETAILS_PUBLISHER_EDIT}>
                    <PublisherEditPage />
                </Route>
            )}
            {hasSeatWriteAccess && (
                <Route path={ROUTES.SEAT_INVENTORY_DETAILS_PUBLISHER_COPY}>
                    <PublisherCopyPage />
                </Route>
            )}
            {hasSeatWriteAccess && (
                <Route path={ROUTES.SEAT_INVENTORY_DETAILS_PUBLISHER_CHANNEL_CREATE}>
                    <PublisherChannelCreatePage />
                </Route>
            )}
            {hasSeatWriteAccess && (
                <Route path={ROUTES.SEAT_INVENTORY_DETAILS_PUBLISHER_CHANNEL_EDIT}>
                    <PublisherChannelEditPage />
                </Route>
            )}
            {hasSeatWriteAccess && (
                <Route path={ROUTES.SEAT_INVENTORY_DETAILS_PUBLISHER_BRAND_CHANNEL_CREATE}>
                    <BrandChannelCreatePage />
                </Route>
            )}
            {hasSeatWriteAccess && (
                <Route path={ROUTES.SEAT_INVENTORY_DETAILS_PUBLISHER_BRAND_CHANNEL_EDIT}>
                    <BrandChannelEditPage />
                </Route>
            )}
            {hasSeatWriteAccess && (
                <Route path={ROUTES.SEAT_INVENTORY_DETAILS_PUBLISHER_BRAND_CREATE}>
                    <BrandCreatePage />
                </Route>
            )}
            {hasSeatWriteAccess && (
                <Route path={ROUTES.SEAT_INVENTORY_DETAILS_PUBLISHER_BRAND_EDIT}>
                    <BrandEditPage />
                </Route>
            )}
            {hasSeatWriteAccess && (
                <Route path={ROUTES.SEAT_INVENTORY_DETAILS_PUBLISHER_BRAND_COPY}>
                    <BrandCopyPage />
                </Route>
            )}
            {hasSeatWriteAccess && (
                <Route path={ROUTES.SEAT_INVENTORY_DETAILS_PUBLISHER_BRAND_SUPPLY_CREATE}>
                    <SupplyCreatePage />
                </Route>
            )}
            {hasSeatWriteAccess && (
                <Route path={ROUTES.SEAT_INVENTORY_DETAILS_PUBLISHER_BRAND_SUPPLY_EDIT}>
                    <SupplyEditPage />
                </Route>
            )}
            {hasSeatWriteAccess && (
                <Route path={ROUTES.SEAT_INVENTORY_DETAILS_PUBLISHER_BRAND_SUPPLY_COPY}>
                    <SupplyCopyPage />
                </Route>
            )}
            {hasSeatWriteAccess && (
                <Route path={ROUTES.SEAT_INVENTORY_DETAILS_PUBLISHER_BRAND_SUPPLY_AD_UNIT_CREATE}>
                    <AdUnitCreatePage />
                </Route>
            )}
            {hasSeatWriteAccess && (
                <Route path={ROUTES.SEAT_INVENTORY_DETAILS_PUBLISHER_BRAND_SUPPLY_AD_UNIT_EDIT}>
                    <AdUnitEditPage />
                </Route>
            )}
            {hasSeatWriteAccess && (
                <Route path={ROUTES.SEAT_INVENTORY_DETAILS_PUBLISHER_BRAND_SUPPLY_AD_UNIT_COPY}>
                    <AdUnitCopyPage />
                </Route>
            )}

            <Route
                exact
                path={[
                    ROUTES.SEAT_INVENTORY_HEALTH,
                    ROUTES.SEAT_INVENTORY_HEALTH_MODEL,

                    ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_SEAT_DIAGNOSTICS,
                    ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_SEAT_CHANGE_HISTORY,
                    ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_SEAT_FLOOR_ANALYSIS,
                    ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_SEAT_DIAGNOSTICS_DEFINITION,
                    ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_SEAT_TAG_PARAMS,
                    ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_SEAT_CREATIVE_REVIEW,
                    ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_SEAT_CREATIVE_REVIEW_DEFINITION,

                    ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PUBLISHER,
                    ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PUBLISHER_DEFINITION,
                    ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PUBLISHER_CHANGE_HISTORY,
                    ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PUBLISHER_DIAGNOSTICS,
                    ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PUBLISHER_PERMISSIONS,
                    ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PUBLISHER_FLOOR_ANALYSIS,
                    ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PUBLISHER_DIAGNOSTICS_DEFINITION,
                    ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PUBLISHER_TAG_PARAMS,
                    ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PUBLISHER_SNAPSHOT,
                    ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PUBLISHER_CREATIVE_REVIEW,
                    ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PUBLISHER_CREATIVE_REVIEW_DEFINITION,
                    ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PUBLISHER_ADS_TXT,

                    ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_CHANNEL_LADLE,
                    ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_CHANNEL_LADLE_DEFINITION,
                    ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_CHANNEL_ADS_TXT,
                    ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_CHANNEL_CHANGE_HISTORY,

                    ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_BRAND,
                    ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_BRAND_DEFINITION,
                    ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_BRAND_CHANGE_HISTORY,
                    ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_BRAND_DIAGNOSTICS,
                    ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_BRAND_FLOOR_ANALYSIS,
                    ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_BRAND_DIAGNOSTICS_DEFINITION,
                    ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_BRAND_TAG_PARAMS,
                    ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_BRAND_SNAPSHOT,
                    ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_BRAND_CREATIVE_REVIEW,
                    ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_BRAND_CREATIVE_REVIEW_DEFINITION,
                    ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_BRAND_ADS_TXT,

                    ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_BRAND_CHANNEL_LADLE,
                    ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_BRAND_CHANNEL_LADLE_DEFINITION,
                    ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_BRAND_CHANNEL_CHANGE_HISTORY,

                    ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_SUPPLY,
                    ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_SUPPLY_DEFINITION,
                    ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_SUPPLY_CHANGE_HISTORY,
                    ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_SUPPLY_DIAGNOSTICS,
                    ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_SUPPLY_FLOOR_ANALYSIS,
                    ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_SUPPLY_DIAGNOSTICS_DEFINITION,
                    ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_SUPPLY_TAG_PARAMS,
                    ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_SUPPLY_SNAPSHOT,
                    ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_SUPPLY_CREATIVE_REVIEW,
                    ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_SUPPLY_CREATIVE_REVIEW_DEFINITION,

                    ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_AD_UNIT,
                    ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_AD_UNIT_DEFINITION,
                    ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_AD_UNIT_CHANGE_HISTORY,
                    ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_AD_UNIT_DIAGNOSTICS,
                    ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_AD_UNIT_FLOOR_ANALYSIS,
                    ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_AD_UNIT_DIAGNOSTICS_DEFINITION,
                    ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_AD_UNIT_TAG_PARAMS,
                    ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_AD_UNIT_SNAPSHOT,
                    ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_AD_UNIT_CREATIVE_REVIEW,
                    ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_AD_UNIT_CREATIVE_REVIEW_DEFINITION,
                    ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_AD_UNIT_LADLE,
                    ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_AD_UNIT_LADLE_DEFINITION,
                ]}
            >
                <InventoryHealthPage />
            </Route>
            <Route
                exact
                path={[
                    ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_PUBLISHER,
                    ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_PUBLISHER_DEFINITION,
                    ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_PUBLISHER_CHANGE_HISTORY,
                    ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_PUBLISHER_TAG_PARAMS,
                    ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_PUBLISHER_CREATIVE_REVIEW,
                    ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_PUBLISHER_CREATIVE_REVIEW_DEFINITION,
                    ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_PUBLISHER_SNAPSHOT,
                    ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_PUBLISHER_DIAGNOSTICS,
                    ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_PUBLISHER_PERMISSIONS,
                    ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_PUBLISHER_FLOOR_ANALYSIS,
                    ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_PUBLISHER_DIAGNOSTICS_DEFINITION,
                    ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_PUBLISHER_ADS_TXT,

                    ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_CHANNEL,
                    ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_CHANNEL_DEFINITION,
                    ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_CHANNEL_CHANGE_HISTORY,
                    ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_CHANNEL_TAG_PARAMS,
                    ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_CHANNEL_CREATIVE_REVIEW,
                    ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_CHANNEL_CREATIVE_REVIEW_DEFINITION,
                    ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_CHANNEL_SNAPSHOT,
                    ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_CHANNEL_DIAGNOSTICS,
                    ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_CHANNEL_FLOOR_ANALYSIS,
                    ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_CHANNEL_DIAGNOSTICS_DEFINITION,
                    ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_CHANNEL_LADLE,
                    ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_CHANNEL_LADLE_DEFINITION,
                    ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_CHANNEL_ADS_TXT,

                    ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_BRAND_CHANNEL,
                    ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_BRAND_CHANNEL_DEFINITION,
                    ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_BRAND_CHANNEL_CHANGE_HISTORY,
                    ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_BRAND_CHANNEL_TAG_PARAMS,
                    ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_BRAND_CHANNEL_CREATIVE_REVIEW,
                    ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_BRAND_CHANNEL_CREATIVE_REVIEW_DEFINITION,
                    ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_BRAND_CHANNEL_DIAGNOSTICS,
                    ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_BRAND_CHANNEL_FLOOR_ANALYSIS,
                    ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_BRAND_CHANNEL_DIAGNOSTICS_DEFINITION,
                    ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_BRAND_CHANNEL_SNAPSHOT,
                    ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_BRAND_CHANNEL_LADLE,
                    ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_BRAND_CHANNEL_LADLE_DEFINITION,

                    ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_BRAND,
                    ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_BRAND_DEFINITION,
                    ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_BRAND_CHANGE_HISTORY,
                    ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_BRAND_TAG_PARAMS,
                    ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_BRAND_CREATIVE_REVIEW,
                    ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_BRAND_CREATIVE_REVIEW_DEFINITION,
                    ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_BRAND_DIAGNOSTICS,
                    ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_BRAND_FLOOR_ANALYSIS,
                    ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_BRAND_DIAGNOSTICS_DEFINITION,
                    ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_BRAND_SNAPSHOT,
                    ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_BRAND_ADS_TXT,

                    ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_SUPPLY,
                    ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_SUPPLY_DEFINITION,
                    ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_SUPPLY_CHANGE_HISTORY,
                    ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_SUPPLY_TAG_PARAMS,
                    ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_SUPPLY_CREATIVE_REVIEW,
                    ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_SUPPLY_CREATIVE_REVIEW_DEFINITION,
                    ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_SUPPLY_DIAGNOSTICS,
                    ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_SUPPLY_FLOOR_ANALYSIS,
                    ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_SUPPLY_DIAGNOSTICS_DEFINITION,
                    ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_SUPPLY_SNAPSHOT,

                    ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_AD_UNIT,
                    ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_AD_UNIT_DEFINITION,
                    ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_AD_UNIT_CHANGE_HISTORY,
                    ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_AD_UNIT_TAG_PARAMS,
                    ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_AD_UNIT_CREATIVE_REVIEW,
                    ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_AD_UNIT_CREATIVE_REVIEW_DEFINITION,
                    ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_AD_UNIT_DIAGNOSTICS,
                    ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_AD_UNIT_FLOOR_ANALYSIS,
                    ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_AD_UNIT_DIAGNOSTICS_DEFINITION,
                    ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_AD_UNIT_SNAPSHOT,
                    ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_AD_UNIT_LADLE,
                    ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_AD_UNIT_LADLE_DEFINITION,
                ]}
            >
                <InventoryHealthUnitPageSwitch />
            </Route>
        </Switch>
    );
};
