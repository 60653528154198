import { MappingExceptionsForm, OverrideMode } from "./types";

export const FORM_FIELDS = {
    DEMAND: {
        label: "Demand",
        name: "network",
    } as const,
    ADOMAIN: {
        label: "Adomain",
        name: "adomain",
        max: 128,
    } as const,
    CRID: {
        label: "Crid",
        name: "crid",
        max: 128,
    } as const,
    ADVERTISER_DOMAIN_OVERRIDE: {
        label: "Advertiser Domain Override",
        name: "adomainOverride",
        max: 128,
    } as const,
    INDUSTRY_OVERRIDE_MODE: {
        label: "Industry Override Mode",
        name: "industryOverrideMode",
    } as const,
    ADD_OVERRIDE_INDUSTRIES: {
        label: "Add New/Override Existing Industries",
        shortLabel: "Industries",
        name: "industryOverrideList",
    } as const,
    TAXONOMY_OVERRIDE_MODE: {
        label: "Taxonomy Override Mode",
        name: "taxonomyOverrideMode",
    } as const,
    ADD_OVERRIDE_TAXONOMIES: {
        label: "Add New/Override Existing Taxonomies",
        shortLabel: "Taxonomies",
        name: "taxonomyOverrideList",
    } as const,
} as const;

export const DEFAULT_FORM_VALUES: MappingExceptionsForm = {
    adomain: null,
    adomainOverride: null,
    crid: null,
    industryOverrideList: null,
    industryOverrideMode: 3,
    network: null,
    taxonomyOverrideMode: 3,
    taxonomyOverrideList: null,
};

export const OVERRIDE_MODE_TEXT = {
    [OverrideMode.ADD]: "Add New",
    [OverrideMode.OVERRIDE]: "Override Existing",
    [OverrideMode.NONE]: "",
} as const;
