import { FC } from "react";
import { Col, Row, Space, Typography } from "antd";
import { LabeledValue } from "antd/lib/select";
import { TargetingDimensionTypeLabels } from "../../../constants";
import { SupplyTypesTargetsDimension, UsedDimensions } from "../../../types";
import { MoreMenu } from "../../MoreMenu";
import { SupplyTypesSelect } from "./SupplyTypesSelect";

interface Props {
    dimension: SupplyTypesTargetsDimension;
    onChangeDimensionValues: (value: LabeledValue[]) => void;
    onClickDelete: () => void;
    onClickDownload: () => void;
    onClickDuplicate: () => void;
    usedDimensions: UsedDimensions;
}

export const SupplyTypesDimensionRow: FC<Props> = ({
    dimension,
    onChangeDimensionValues,
    onClickDelete,
    onClickDuplicate,
    usedDimensions,
}) => {
    return (
        <Row gutter={8}>
            <Col flex="150px">
                <Typography.Text strong>{TargetingDimensionTypeLabels.SupplyTypes}</Typography.Text>
            </Col>
            <Col flex={1}>
                <SupplyTypesSelect
                    onChange={onChangeDimensionValues}
                    value={dimension.values}
                    usedDimensions={usedDimensions}
                />
            </Col>
            <Col flex="146px" style={{ display: "flex", justifyContent: "flex-end" }}>
                <Space>
                    <MoreMenu
                        onClickDelete={onClickDelete}
                        onClickDuplicate={onClickDuplicate}
                        isDuplicateDisabled={usedDimensions.supplyTypes.includes || usedDimensions.supplyTypes.excludes}
                    />
                </Space>
            </Col>
        </Row>
    );
};
