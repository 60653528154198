import { FC } from "react";
import { Select } from "antd";
import { useAdSourceContentField } from "./useAdSourceContentField";
import { TransparencyFormItem } from "@app/features/deals/DealForm/DealFormCreateAdSource/DealFormCreateAdSourceSections/AdSourceTransparencySection/Fields";
import { AD_SOURCE_FIELDS } from "@app/features/seatAdSources/constants";

export const AdSourceContentField: FC = () => {
    const { value, options, isLoading, onChange } = useAdSourceContentField();

    return (
        <TransparencyFormItem label={AD_SOURCE_FIELDS.CONTENT.label}>
            <Select
                options={options}
                loading={isLoading}
                value={value}
                onChange={onChange}
                style={{ width: "189px" }}
                data-sdet="content"
            />
        </TransparencyFormItem>
    );
};
