import { FC } from "react";
import { Layout } from "antd";
import { Loading } from "@app/core/components";
import { useSeatAuthContext } from "@app/core/auth";
import { DiagnosticsPage } from "./DiagnosticsPage";
import conf from "@app/core/conf";

export const DiagnosticsSupplyPage: FC = () => {
    const { context, session } = useSeatAuthContext();

    if (!context || !session) {
        return (
            <Layout.Content>
                <Loading />
            </Layout.Content>
        );
    }

    return (
        <DiagnosticsPage
            src={(seatId) =>
                `${conf.mctvConsoleRoot}/ssp/seats/${seatId}/${context.isDemandSeat ? "demand" : "supply"}-diagnostics`
            }
        />
    );
};
