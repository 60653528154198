import { FC } from "react";
import { Drawer } from "antd";
import { DrawerLayout } from "@app/core/components";
import { css } from "@emotion/css";
import { ChangeHistoryTable } from "@app/core/components/ChangeHistoryTable";
import { useHistory, useParams } from "react-router-dom";
import { ROUTE_FORMATTERS } from "@app/core/routing";
import { ChangeLogEntityType, useGetSeatSelfQuery } from "@app/core/services";

const drawerClasses = css`
    .ant-drawer-body {
        padding: 0;
    }
`;

const ChangelogDrawer: FC = () => {
    const history = useHistory();
    const { seatId } = useParams<{ seatId: string }>();
    const { data } = useGetSeatSelfQuery(seatId);
    const handleClose = () => {
        history.push(ROUTE_FORMATTERS.SEAT_INVENTORY_SEAT_DETAILS(seatId));
    };

    const seat = data?.seat;

    return (
        <Drawer
            width="60%"
            placement="right"
            open={true}
            closable={false}
            onClose={handleClose}
            className={drawerClasses}
        >
            <DrawerLayout closeHandler={handleClose} title={`Change History: ${seat?.name}`}>
                <ChangeHistoryTable entityId={seat?.id} entityType={ChangeLogEntityType.Seat} />
            </DrawerLayout>
        </Drawer>
    );
};

export default ChangelogDrawer;
