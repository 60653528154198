import { FC } from "react";
import { useHose } from "@app/core/services/useHose";
import conf from "@app/core/conf";
import { SeatIFrame } from "@app/core/components";
import { IFRAMED_APP_ID } from "./constants";

export const IntegrationsStreamingIframe: FC = () => {
    const { init, launchApplication } = useHose(IFRAMED_APP_ID);
    const srcBuilder = (seatId) => `${conf.appsMagniteRoot}/streaming-integrations/#/seats/${seatId}/integrations`;
    const onLoad = () => {
        init();
        launchApplication();
    };
    return <SeatIFrame name="streaming-integrations" src={srcBuilder} title="Integrations" onLoad={onLoad} />;
};
