import { SeatIFrame } from "@app/core/components";
import { useHose } from "@app/core/services/useHose";
import { useLocation } from "react-router-dom";
import { useGetDeepLink } from "@app/core/components/hooks";
import conf from "@app/core/conf";
import React from "react";

const IFRAME_ID = "streaming-diagnostics";
const DIAGNOSTICS_PATH = "diagnostics";

export const DiagnosticsIframe = () => {
    const { init, launchApplication } = useHose(IFRAME_ID);
    const { pathname, search } = useLocation();
    const deepLink = useGetDeepLink(pathname, DIAGNOSTICS_PATH);

    const srcBuilder = (seatId: string) =>
        `${conf.appsMagniteRoot}/${IFRAME_ID}/#/seats/${seatId}/${DIAGNOSTICS_PATH}`.concat(deepLink, search);
    const onLoad = () => {
        init();
        launchApplication();
    };

    return <SeatIFrame name={IFRAME_ID} title="Diagnostics" src={srcBuilder} onLoad={onLoad} />;
};
