import { AdStat } from "@app/features/adStats";
import {
    AD_SOURCE_STATS_SNAPSHOTS_TAG,
    AD_UNIT_STATS_SNAPSHOTS_TAG,
    BRAND_STATS_SNAPSHOTS_TAG,
    PUBLISHER_STATS_SNAPSHOTS_TAG,
    SEAT_STATS_SNAPSHOTS_FOLDERS_TAG,
    SEAT_STATS_SNAPSHOTS_TAG,
    adStatsApi,
    SUPPLY_STATS_SNAPSHOTS_TAG,
} from "./adStats";
import { toQueryString } from "../console/utils";

export enum SnapshotsEntityTypes {
    Seat = "seat",
    Publisher = "publisher",
    Brand = "brand",
    Supply = "supply",
    AdUnit = "adUnit",
    AdSource = "adSource",
}

interface StatsSnapshot {
    emailAddress: string;
    entityId: string;
    entityName: string;
    entityType: SnapshotsEntityTypes;
    filename: string;
    message: string;
    subEntityId: unknown;
    subEntityType: unknown;
}

export type SeatStatsSnapshot = StatsSnapshot & {
    entityType: SnapshotsEntityTypes.Seat;
};

export type PublisherStatsSnapshot = StatsSnapshot & {
    entityType: SnapshotsEntityTypes.Publisher;
};

export type BrandStatsSnapshot = StatsSnapshot & {
    entityType: SnapshotsEntityTypes.Brand;
};

export type SupplyStatsSnapshot = StatsSnapshot & {
    entityType: SnapshotsEntityTypes.Supply;
};

export type AdUnitStatsSnapshot = StatsSnapshot & {
    entityType: SnapshotsEntityTypes.AdUnit;
};

export type AdSourceStatsSnapshot = StatsSnapshot & {
    entityType: SnapshotsEntityTypes.AdSource;
};

interface GetStatsSnapshotsParams {
    email?: string;
}

type GetSeatStatsSnapshotsParams = GetStatsSnapshotsParams & {
    seatId: number;
};

type GetPublisherStatsSnapshotsParams = GetStatsSnapshotsParams & {
    publisherId: number;
};

type GetBrandStatsSnapshotsParams = GetStatsSnapshotsParams & {
    brandId: number;
};

type GetSupplyStatsSnapshotsParams = GetStatsSnapshotsParams & {
    supplyId: number;
};

type GetAdUnitStatsSnapshotsParams = GetStatsSnapshotsParams & {
    adUnitId: number;
};

type GetAdSourceStatsSnapshotsParams = GetStatsSnapshotsParams & {
    adSourceId: number;
};

interface GetStatsSnapshotsFolders {
    seatId: number;
    entityType: SnapshotsEntityTypes;
}

interface CreateStatsSnapshotParams {
    message: string;
}

type CreateSeatStatsSnapshotParams = CreateStatsSnapshotParams & {
    seatId: number;
};

type CreatePublisherStatsSnapshotParams = CreateStatsSnapshotParams & {
    publisherId: number;
};

type CreateBrandStatsSnapshotParams = CreateStatsSnapshotParams & {
    brandId: number;
};

type CreateAdUnitStatsSnapshotParams = CreateStatsSnapshotParams & {
    adUnitId: number;
};

type CreateSupplyStatsSnapshotParams = CreateStatsSnapshotParams & {
    supplyId: number;
};

type CreateAdSourceStatsSnapshotParams = CreateStatsSnapshotParams & {
    adSourceId: number;
};

interface GetStatsSnapshotParams {
    timestamp: string;
}

type GetSeatStatsSnapshotParams = GetStatsSnapshotParams & {
    seatId: number;
};

type GetPublisherStatsSnapshotParams = GetStatsSnapshotParams & {
    publisherId: number;
};

type GetBrandStatsSnapshotParams = GetStatsSnapshotParams & {
    brandId: number;
};

type GetSupplyStatsSnapshotParams = GetStatsSnapshotParams & {
    supplyId: number;
};

type GetAdUnitStatsSnapshotParams = GetStatsSnapshotParams & {
    adUnitId: number;
};

type GetAdSourceStatsSnapshotParams = GetStatsSnapshotParams & {
    adSourceId: number;
};

export const statsSnapshotsApi = adStatsApi.injectEndpoints({
    endpoints: (builder) => ({
        getSeatStatsSnapshotsFolders: builder.query<SeatStatsSnapshot[], GetStatsSnapshotsFolders>({
            query: ({ seatId, entityType }) => {
                const params: Omit<GetStatsSnapshotsFolders, "seatId"> = {
                    entityType,
                };
                return `seats/${seatId}/snapshots/folders;${toQueryString(params)}`;
            },
            providesTags: (_, __, { seatId, entityType }) => [
                { type: SEAT_STATS_SNAPSHOTS_FOLDERS_TAG, seatId, entityType },
            ],
        }),
        getSeatStatsSnapshots: builder.query<SeatStatsSnapshot[], GetSeatStatsSnapshotsParams>({
            query: ({ seatId, email }) => {
                const params: Omit<GetSeatStatsSnapshotsParams, "seatId"> = {
                    email,
                };
                return `seats/${seatId}/snapshots;${toQueryString(params)}`;
            },
            providesTags: (_, __, { seatId }) => [{ type: SEAT_STATS_SNAPSHOTS_TAG, id: seatId }],
        }),
        getPublisherStatsSnapshots: builder.query<PublisherStatsSnapshot[], GetPublisherStatsSnapshotsParams>({
            query: ({ publisherId, email }) => {
                const params: Omit<GetPublisherStatsSnapshotsParams, "publisherId"> = {
                    email,
                };
                return `publishers/${publisherId}/snapshots;${toQueryString(params)}`;
            },
            providesTags: (_, __, { publisherId }) => [{ type: PUBLISHER_STATS_SNAPSHOTS_TAG, id: publisherId }],
        }),
        getBrandStatsSnapshots: builder.query<BrandStatsSnapshot[], GetBrandStatsSnapshotsParams>({
            query: ({ brandId, email }) => {
                const params: Omit<GetBrandStatsSnapshotsParams, "brandId"> = {
                    email,
                };
                return `brands/${brandId}/snapshots;${toQueryString(params)}`;
            },
            providesTags: (_, __, { brandId }) => [{ type: BRAND_STATS_SNAPSHOTS_TAG, id: brandId }],
        }),
        getSupplyStatsSnapshots: builder.query<SupplyStatsSnapshot[], GetSupplyStatsSnapshotsParams>({
            query: ({ supplyId, email }) => {
                const params: Omit<GetAdUnitStatsSnapshotsParams, "adUnitId"> = {
                    email,
                };
                return `supply/${supplyId}/snapshots;${toQueryString(params)}`;
            },
            providesTags: (_, __, { supplyId }) => [{ type: SUPPLY_STATS_SNAPSHOTS_TAG, id: supplyId }],
        }),
        getAdUnitStatsSnapshots: builder.query<AdUnitStatsSnapshot[], GetAdUnitStatsSnapshotsParams>({
            query: ({ adUnitId, email }) => {
                const params: Omit<GetAdUnitStatsSnapshotsParams, "adUnitId"> = {
                    email,
                };
                return `adunits/${adUnitId}/snapshots;${toQueryString(params)}`;
            },
            providesTags: (_, __, { adUnitId }) => [{ type: AD_UNIT_STATS_SNAPSHOTS_TAG, id: adUnitId }],
        }),
        getAdSourceStatsSnapshots: builder.query<AdSourceStatsSnapshot[], GetAdSourceStatsSnapshotsParams>({
            query: ({ adSourceId, email }) => {
                const params: Omit<GetAdSourceStatsSnapshotsParams, "adSourceId"> = {
                    email,
                };
                return `adsources/${adSourceId}/snapshots;${toQueryString(params)}`;
            },
            providesTags: (_, __, { adSourceId }) => [{ type: AD_SOURCE_STATS_SNAPSHOTS_TAG, id: adSourceId }],
        }),
        createSeatStatsSnapshot: builder.mutation<void, CreateSeatStatsSnapshotParams>({
            query: ({ seatId, message }) => {
                return {
                    url: `/seats/${seatId}/snapshots`,
                    method: "POST",
                    body: { message },
                };
            },
            invalidatesTags: (_, err) => (err ? [] : [SEAT_STATS_SNAPSHOTS_TAG]),
        }),
        createPublisherStatsSnapshot: builder.mutation<void, CreatePublisherStatsSnapshotParams>({
            query: ({ publisherId, message }) => {
                return {
                    url: `/publishers/${publisherId}/snapshots`,
                    method: "POST",
                    body: { message },
                };
            },
            invalidatesTags: (_, err, { publisherId }) =>
                err ? [] : [{ type: PUBLISHER_STATS_SNAPSHOTS_TAG, id: publisherId }],
        }),
        createBrandStatsSnapshot: builder.mutation<void, CreateBrandStatsSnapshotParams>({
            query: ({ brandId, message }) => {
                return {
                    url: `/brands/${brandId}/snapshots`,
                    method: "POST",
                    body: { message },
                };
            },
            invalidatesTags: (_, err, { brandId }) => (err ? [] : [{ type: BRAND_STATS_SNAPSHOTS_TAG, id: brandId }]),
        }),
        createSupplyStatsSnapshot: builder.mutation<void, CreateSupplyStatsSnapshotParams>({
            query: ({ supplyId, message }) => {
                return {
                    url: `/supply/${supplyId}/snapshots`,
                    method: "POST",
                    body: { message },
                };
            },
            invalidatesTags: (_, err, { supplyId }) =>
                err ? [] : [{ type: SUPPLY_STATS_SNAPSHOTS_TAG, id: supplyId }],
        }),
        createAdUnitStatsSnapshot: builder.mutation<void, CreateAdUnitStatsSnapshotParams>({
            query: ({ adUnitId, message }) => {
                return {
                    url: `/adunits/${adUnitId}/snapshots`,
                    method: "POST",
                    body: { message },
                };
            },
            invalidatesTags: (_, err, { adUnitId }) =>
                err ? [] : [{ type: AD_UNIT_STATS_SNAPSHOTS_TAG, id: adUnitId }],
        }),
        createAdSourceStatsSnapshot: builder.mutation<void, CreateAdSourceStatsSnapshotParams>({
            query: ({ adSourceId, message }) => {
                return {
                    url: `/adsources/${adSourceId}/snapshots`,
                    method: "POST",
                    body: { message },
                };
            },
            invalidatesTags: (_, err) => (err ? [] : [AD_SOURCE_STATS_SNAPSHOTS_TAG]),
        }),
        getSeatStatsSnapshot: builder.query<AdStat[], GetSeatStatsSnapshotParams>({
            query: ({ seatId, timestamp }) => {
                return `/seats/${seatId}/snapshots/${timestamp}`;
            },
        }),
        getPublisherStatsSnapshot: builder.query<AdStat[], GetPublisherStatsSnapshotParams>({
            query: ({ publisherId, timestamp }) => {
                return `/publishers/${publisherId}/snapshots/${timestamp}`;
            },
        }),
        getBrandStatsSnapshot: builder.query<AdStat[], GetBrandStatsSnapshotParams>({
            query: ({ brandId, timestamp }) => {
                return `/brands/${brandId}/snapshots/${timestamp}`;
            },
        }),
        getSupplyStatsSnapshot: builder.query<AdStat[], GetSupplyStatsSnapshotParams>({
            query: ({ supplyId, timestamp }) => {
                return `/supply/${supplyId}/snapshots/${timestamp}`;
            },
        }),
        getAdUnitStatsSnapshot: builder.query<AdStat[], GetAdUnitStatsSnapshotParams>({
            query: ({ adUnitId, timestamp }) => {
                return `/adunits/${adUnitId}/snapshots/${timestamp}`;
            },
            providesTags: (_, __, { adUnitId }) => [{ type: AD_UNIT_STATS_SNAPSHOTS_TAG, id: adUnitId }],
        }),
        getAdSourceStatsSnapshot: builder.query<AdStat[], GetAdSourceStatsSnapshotParams>({
            query: ({ adSourceId, timestamp }) => {
                return `/adsources/${adSourceId}/snapshots/${timestamp}`;
            },
        }),
    }),
    overrideExisting: false,
});

export const {
    useGetSeatStatsSnapshotsFoldersQuery,
    useGetSeatStatsSnapshotsQuery,
    useGetPublisherStatsSnapshotsQuery,
    useGetBrandStatsSnapshotsQuery,
    useGetSupplyStatsSnapshotsQuery,
    useGetAdUnitStatsSnapshotsQuery,
    useGetAdSourceStatsSnapshotsQuery,
    useCreateSeatStatsSnapshotMutation,
    useCreatePublisherStatsSnapshotMutation,
    useCreateBrandStatsSnapshotMutation,
    useCreateSupplyStatsSnapshotMutation,
    useCreateAdUnitStatsSnapshotMutation,
    useCreateAdSourceStatsSnapshotMutation,
    useGetSeatStatsSnapshotQuery,
    useGetPublisherStatsSnapshotQuery,
    useGetBrandStatsSnapshotQuery,
    useGetSupplyStatsSnapshotQuery,
    useGetAdUnitStatsSnapshotQuery,
    useGetAdSourceStatsSnapshotQuery,
    usePrefetch: useSnapshotsPrefetch,
} = statsSnapshotsApi;
