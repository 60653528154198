import { FC } from "react";
import { Form } from "antd";
import { IndustryBlockMode } from "@app/core/services";
import { SelectWithValue } from "@app/features/seatAdSources/SeatAdSourcesForm/components";
import { AD_SOURCE_FIELDS, VERTICAL_LAYOUT_FIX } from "@app/features/seatAdSources/constants";
import { useIndustriesModeSelect } from "./useIndustriesModeSelect";

const { INDUSTRIES_MODE } = AD_SOURCE_FIELDS;

export const IndustriesModeSelect: FC = () => {
    const { options, isLoading, isDisabled } = useIndustriesModeSelect();

    return (
        <Form.Item label={INDUSTRIES_MODE.label} name={INDUSTRIES_MODE.name} {...VERTICAL_LAYOUT_FIX}>
            <SelectWithValue<IndustryBlockMode>
                disabled={isDisabled}
                fieldAsLabel={["name"]}
                fieldAsValue={["id"]}
                options={options}
                loading={isLoading}
                placeholder="Select..."
            />
        </Form.Item>
    );
};
