export const TYPE_FILTER_ALL = { label: "All", value: "" } as const;

export const TYPE_FILTER_MAP = {
    1: "country",
    2: "state",
    3: "city",
    4: "dma",
    5: "postal",
};

export const TYPE_FILTER_OPTIONS = [
    TYPE_FILTER_ALL,
    { label: "Country", value: TYPE_FILTER_MAP[1] },
    { label: "State/Region", value: TYPE_FILTER_MAP[2] },
    { label: "City", value: TYPE_FILTER_MAP[3] },
    { label: "Postal Code", value: TYPE_FILTER_MAP[5] },
    { label: "Digital Market Area (DMA)", value: TYPE_FILTER_MAP[4] },
];
