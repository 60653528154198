import { Store } from "rc-field-form/lib/interface";
import { Seat, UpdateSeatPayload } from "@app/core/services";
import { getLimitLabel, getQpsLimitLabel } from "@app/features/inventory/helpers";
import { INVENTORY_FORM_FIELDS } from "@app/features/inventory/InventorySeat/constants";
import {
    ExchangeProtectionsForm,
    DEFAULT_REQUEST_GOVERNOR_LIMIT_PER_MIN,
} from "@app/features/inventory/InventorySeat/InventorySeatEditPage/ExchangeProtectionsForm/useExchangeProtectionsForm";

export const getExchangeProtectionsFormInitialValues = (seat: Seat | null | undefined): Store | undefined => {
    if (!seat) return undefined;

    return {
        //Inventory Limits Section
        [INVENTORY_FORM_FIELDS.COUNT_LIMITS.name]: (seat?.adSourceCountLimits || []).map((adSourceCountLimit) => ({
            value: adSourceCountLimit.adSourceType.id,
            label: getLimitLabel(adSourceCountLimit),
            adSourceCountLimit,
        })),
        [INVENTORY_FORM_FIELDS.QPS_LIMITS.name]: (seat?.qpsLimits || []).map((qpsLimit) => ({
            value: qpsLimit.awsRegion.id,
            label: getQpsLimitLabel(qpsLimit),
            qpsLimit,
        })),
        [INVENTORY_FORM_FIELDS.GOVERNOR_REQUEST.name]: seat?.requestGovernorLimitPerMin,
        [INVENTORY_FORM_FIELDS.TWO_QPS_LIMIT.name]: seat?.tierTwoQpsLimitMinPercent,
        [INVENTORY_FORM_FIELDS.THREE_QPS_LIMIT.name]: seat?.tierThreeQpsLimitMinPercent,
        [INVENTORY_FORM_FIELDS.VIEWABILITY_MESUREMENT.name]: (seat?.viewabilityMeasurement || []).map(
            ({ sampleRate, vendor }) => ({
                value: vendor.id,
                label: `${vendor.name} - ${sampleRate}`,
                viewabilityMeasurement: {
                    sampleRate,
                    vendor: {
                        id: vendor.id,
                    },
                },
            })
        ),
        [INVENTORY_FORM_FIELDS.VIEWABILITY_MESUREMENT_SAMPLE_RATE.name]: 0,
        [INVENTORY_FORM_FIELDS.MOKA_FILTERING_MODE.name]: seat?.seatMokaFilteringMode?.id,
        // Quality Control section
        [INVENTORY_FORM_FIELDS.FRAUD_DETECTION.name]: seat?.seatFraudDetection?.id,
        [INVENTORY_FORM_FIELDS.DOMAIN_FILTER_MODE.name]: seat?.supplyDomainFilterIsBlack,
        [INVENTORY_FORM_FIELDS.SUPPLY_DOMAIN_FILTER.name]: (seat?.seatSupplyDomainFilterLists || []).map((domain) => ({
            value: domain.id,
            label: domain.name,
        })),
        [INVENTORY_FORM_FIELDS.BUNDLE_ID_FILTER_MODE.name]: seat?.bundleIdFilterIsBlack,
        [INVENTORY_FORM_FIELDS.BUNDLE_ID_FILTER.name]: (seat?.seatBundleIdFilterLists || []).map((bundle) => ({
            value: bundle.id,
            label: bundle.name,
        })),
    };
};
export const parseExchangeProtectionsFormValuesToApi = (
    seat: Seat,
    values: ExchangeProtectionsForm
): UpdateSeatPayload => {
    const {
        requestGovernorLimitPerMin,
        seatSupplyDomainFilterLists,
        seatBundleIdFilterLists,
        viewabilityMeasurement,
        seatMokaFilteringMode,
        adSourceCountLimits,
        seatFraudDetection,
        qpsLimits,
        ...rest
    } = values;
    return {
        ...(seat as UpdateSeatPayload),
        ...rest,
        audienceLockAllowedDomain:
            seat.audienceLockAllowedDomain && !seat.audienceLockAllowedDomain.length
                ? null
                : seat.audienceLockAllowedDomain,
        seatFraudDetection: { id: seatFraudDetection },
        seatMokaFilteringMode: { id: seatMokaFilteringMode },
        qpsLimits: (qpsLimits || []).map(({ qpsLimit }) => qpsLimit),
        requestGovernorLimitPerMin: requestGovernorLimitPerMin || DEFAULT_REQUEST_GOVERNOR_LIMIT_PER_MIN,
        adSourceCountLimits: (adSourceCountLimits || []).map(({ adSourceCountLimit }) => adSourceCountLimit),
        seatSupplyDomainFilterLists: (seatSupplyDomainFilterLists || []).map(({ label, value }) => ({
            id: value as number,
            name: label as string,
        })),
        seatBundleIdFilterLists: (seatBundleIdFilterLists || []).map(({ value, label }) => ({
            id: value as number,
            name: label as string,
        })),
        viewabilityMeasurement: (viewabilityMeasurement || []).map(
            ({ viewabilityMeasurement }) => viewabilityMeasurement
        ),
    };
};
