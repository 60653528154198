import { Loading, SectionTitle } from "@app/core/components";
import { AdUnit, useGetSeatSelfQuery } from "@app/core/services";
import { SnapshotsEntityTypes } from "@app/core/services/adStats/statsSnapshots";
import { EntityTypes } from "@app/features/adStats";
import { Card, Collapse, Form } from "antd";
import { FC } from "react";
import {
    AD_UNIT_DETAILS_SECTIONS_NAME,
    classNameCardBody,
    PLATFORM_ID,
    PLAYLIST_ID,
    POD_ID,
    PUBLISHER_DETAILS_SECTIONS_NAME,
    SECTIONS_NAMES,
} from "../constants";
import { useParams } from "react-router";
import { HelpKeysButton } from "../HelpKeysButton";
import { Labels, Performance, Pixels, BrandSafety, WaterfallPrefiltering, Affiliate } from "../SharedSections";
import { AdBreakRules, AdPod, DemandRequirements, General, SupplyDetails } from "./sections";
import { PlaylistConfiguration } from "./sections/PlaylistConfiguration/PlaylistConfiguration";
import { FloorsTable } from "@app/features/inventory/components/FloorsTable";
import { useUserAccess } from "@app/core/auth";
import { InternalSection } from "./sections/InternalSection";
import { SupplyTrafficAlertBanner } from "@app/features/inventory/components/SupplyTrafficAlertBanner";
import { ExternalCodesTable } from "../../components/ExternalCodesTable";

interface Props {
    adUnit: AdUnit;
}

export const AdUnitSections: FC<Props> = ({ adUnit }) => {
    const { seatId } = useParams<{ seatId: string }>();
    const { data: seatSelfData } = useGetSeatSelfQuery(seatId);
    const seatData = seatSelfData?.seat || null;
    const { isSysAdmin, isPubAcctMgr, isTremorUser } = useUserAccess();
    const isInternalOnly: boolean = isSysAdmin || isPubAcctMgr;
    const isChannelAdUnit = adUnit?.entityType === "ChannelAdUnit";
    const isAffiliateSectionShown: boolean = isTremorUser && !!seatData?.affiliateCostEnabled;

    if (!adUnit) {
        return <Loading />;
    }

    return (
        <>
            <SupplyTrafficAlertBanner sourceSelf="Ad Unit" />
            <Form layout="vertical" style={{ width: "100%" }} data-sdet="ad-unit-sections">
                <Collapse
                    defaultActiveKey={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15]}
                    bordered
                    ghost={false}
                    collapsible="header"
                >
                    {!isChannelAdUnit && (
                        <Collapse.Panel
                            key={1}
                            header={<SectionTitle title={PUBLISHER_DETAILS_SECTIONS_NAME().performance.label} />}
                            extra={
                                <HelpKeysButton
                                    label={PUBLISHER_DETAILS_SECTIONS_NAME().performance.label}
                                    sectionKey={SECTIONS_NAMES.PERFORMANCE}
                                />
                            }
                        >
                            <Performance
                                id={adUnit?.id}
                                adStatsEntity={EntityTypes.AdUnit}
                                snapShotEntity={SnapshotsEntityTypes.AdUnit}
                            />
                        </Collapse.Panel>
                    )}
                    <Collapse.Panel
                        key={2}
                        header={<SectionTitle title={AD_UNIT_DETAILS_SECTIONS_NAME().general.label} />}
                        extra={
                            <HelpKeysButton
                                label={AD_UNIT_DETAILS_SECTIONS_NAME().general.label}
                                sectionKey={SECTIONS_NAMES.GENERAL}
                            />
                        }
                    >
                        <Card bordered={false} className={classNameCardBody}>
                            <General adUnit={adUnit} />
                        </Card>
                    </Collapse.Panel>
                    {isAffiliateSectionShown && (
                        <Collapse.Panel
                            key={3}
                            header={<SectionTitle title={AD_UNIT_DETAILS_SECTIONS_NAME().affiliate.label} />}
                            extra={
                                <HelpKeysButton
                                    label={AD_UNIT_DETAILS_SECTIONS_NAME().affiliate.helpLabel}
                                    sectionKey={SECTIONS_NAMES.AFFILIATE}
                                />
                            }
                        >
                            <Card bordered={false} className={classNameCardBody}>
                                <Affiliate unit={adUnit} />
                            </Card>
                        </Collapse.Panel>
                    )}
                    <Collapse.Panel
                        key={4}
                        header={<SectionTitle title={AD_UNIT_DETAILS_SECTIONS_NAME().brandSafety.label} />}
                        extra={
                            <HelpKeysButton
                                label={AD_UNIT_DETAILS_SECTIONS_NAME().brandSafety.label}
                                sectionKey={SECTIONS_NAMES.BRAND_SAFETY}
                            />
                        }
                    >
                        <Card bordered={false} className={classNameCardBody}>
                            <BrandSafety unit={adUnit} hasFreqCaps hasFreqCapping />
                        </Card>
                    </Collapse.Panel>
                    {isInternalOnly && (
                        <Collapse.Panel
                            key={5}
                            header={<SectionTitle title={AD_UNIT_DETAILS_SECTIONS_NAME().internal.label} />}
                            extra={<HelpKeysButton label="Internal" sectionKey={SECTIONS_NAMES.INTERNAL} />}
                        >
                            <Card bordered={false} className={classNameCardBody}>
                                <InternalSection adUnit={adUnit} />
                            </Card>
                        </Collapse.Panel>
                    )}
                    {seatData?.externalAdUnitCodeNamespaces?.length && (
                        <Collapse.Panel
                            key={6}
                            header={<SectionTitle title={AD_UNIT_DETAILS_SECTIONS_NAME().sspConnect.label} />}
                        >
                            <Card bordered={false} className={classNameCardBody}>
                                <ExternalCodesTable externalCodes={adUnit.externalCodes} />
                            </Card>
                        </Collapse.Panel>
                    )}
                    <Collapse.Panel
                        key={7}
                        header={<SectionTitle title={AD_UNIT_DETAILS_SECTIONS_NAME().demandRequirements.label} />}
                        extra={
                            <HelpKeysButton
                                label={AD_UNIT_DETAILS_SECTIONS_NAME().demandRequirements.label}
                                sectionKey={SECTIONS_NAMES.DEMAND_REQUIREMENTS}
                            />
                        }
                    >
                        <Card bordered={false} className={classNameCardBody}>
                            <DemandRequirements adUnit={adUnit} />
                        </Card>
                    </Collapse.Panel>
                    <Collapse.Panel
                        key={8}
                        header={<SectionTitle title={AD_UNIT_DETAILS_SECTIONS_NAME().supplyDetails.label} />}
                        extra={
                            <HelpKeysButton
                                label={AD_UNIT_DETAILS_SECTIONS_NAME().supplyDetails.label}
                                sectionKey={SECTIONS_NAMES.SUPPLY_DETAILS}
                            />
                        }
                    >
                        <Card bordered={false} className={classNameCardBody}>
                            <SupplyDetails adUnit={adUnit} isInternalOnly={isInternalOnly} />
                        </Card>
                    </Collapse.Panel>
                    {adUnit?.multiplicity?.id === PLAYLIST_ID && (
                        <Collapse.Panel
                            key={9}
                            header={
                                <SectionTitle title={AD_UNIT_DETAILS_SECTIONS_NAME().playlistConfiguration.label} />
                            }
                            extra={
                                <HelpKeysButton
                                    label={AD_UNIT_DETAILS_SECTIONS_NAME().playlistConfiguration.label}
                                    sectionKey={SECTIONS_NAMES.PLAYLIST_CONFIGURATION}
                                />
                            }
                        >
                            <Card bordered={false} className={classNameCardBody}>
                                <PlaylistConfiguration adUnit={adUnit} />
                            </Card>
                        </Collapse.Panel>
                    )}
                    {adUnit?.multiplicity?.id === POD_ID && (
                        <Collapse.Panel
                            key={10}
                            header={<SectionTitle title={AD_UNIT_DETAILS_SECTIONS_NAME().adPod.label} />}
                            extra={
                                <HelpKeysButton
                                    label={AD_UNIT_DETAILS_SECTIONS_NAME().adPod.label}
                                    sectionKey={SECTIONS_NAMES.AD_POD}
                                />
                            }
                        >
                            <Card bordered={false} className={classNameCardBody}>
                                <AdPod adUnit={adUnit} />
                            </Card>
                        </Collapse.Panel>
                    )}
                    {adUnit?.playlistDefinitionMode?.id === PLATFORM_ID && adUnit?.adBreakRules?.length && (
                        <Collapse.Panel
                            key={11}
                            header={<SectionTitle title={AD_UNIT_DETAILS_SECTIONS_NAME().adBreakRules.label} />}
                        >
                            <Card bordered={false} className={classNameCardBody}>
                                <AdBreakRules adUnit={adUnit} />
                            </Card>
                        </Collapse.Panel>
                    )}
                    <Collapse.Panel
                        key={12}
                        header={<SectionTitle title={AD_UNIT_DETAILS_SECTIONS_NAME().pixels.label} />}
                        extra={
                            <HelpKeysButton
                                label={AD_UNIT_DETAILS_SECTIONS_NAME().pixels.label}
                                sectionKey={SECTIONS_NAMES.PIXELS}
                            />
                        }
                    >
                        <Card bordered={false} className={classNameCardBody}>
                            <Pixels id={adUnit.id} />
                        </Card>
                    </Collapse.Panel>
                    <Collapse.Panel
                        key={13}
                        header={<SectionTitle title={AD_UNIT_DETAILS_SECTIONS_NAME().labels.label} />}
                        extra={
                            <HelpKeysButton
                                label={AD_UNIT_DETAILS_SECTIONS_NAME(isInternalOnly).labels.label}
                                sectionKey={SECTIONS_NAMES.LABELS}
                            />
                        }
                    >
                        <Card bordered={false} className={classNameCardBody}>
                            <Labels
                                labels={adUnit?.labelValues}
                                isInternalOnly={isInternalOnly}
                                internalLabelValues={adUnit?.internalLabelValues}
                            />
                        </Card>
                    </Collapse.Panel>
                    <Collapse.Panel
                        key={14}
                        header={<SectionTitle title={AD_UNIT_DETAILS_SECTIONS_NAME().waterfallPrefiltering.label} />}
                        extra={
                            <HelpKeysButton
                                label={AD_UNIT_DETAILS_SECTIONS_NAME().waterfallPrefiltering.label}
                                sectionKey={SECTIONS_NAMES.WATERFALL_PREFILTERING}
                            />
                        }
                    >
                        <Card bordered={false} className={classNameCardBody}>
                            <WaterfallPrefiltering unit={adUnit} />
                        </Card>
                    </Collapse.Panel>
                    <Collapse.Panel
                        key={15}
                        header={<SectionTitle title={AD_UNIT_DETAILS_SECTIONS_NAME().floors.label} />}
                    >
                        <Card bordered={false} className={classNameCardBody}>
                            <FloorsTable
                                readOnly
                                id={adUnit?.id}
                                sourceSelf="Ad Unit"
                                hiddenColumns={["actualPrice"]}
                            />
                        </Card>
                    </Collapse.Panel>
                </Collapse>
            </Form>
        </>
    );
};
