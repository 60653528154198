import { FC } from "react";
import { Radio } from "antd";
import { TransparencyFormItem } from "@app/features/deals/DealForm/DealFormCreateAdSource/DealFormCreateAdSourceSections/AdSourceTransparencySection/Fields";
import { useAdSourceOztamCoViewingField } from "./useAdSourceOztamCoViewingField";
import { AD_SOURCE_FIELDS } from "@app/features/seatAdSources/constants";

const OPTIONS = [
    { label: "Yes", value: true },
    { label: "No", value: false },
];

export const AdSourceOztamCoViewingField: FC = () => {
    const { value, onChange } = useAdSourceOztamCoViewingField();
    return (
        <TransparencyFormItem label={AD_SOURCE_FIELDS.OZTAM_CO_VIEWING.label} data-sdet="allow-oztam-co-viewing">
            <Radio.Group onChange={(e) => onChange(e?.target?.value)} value={value} options={OPTIONS} />
        </TransparencyFormItem>
    );
};
