import { MarketplaceInfo } from "@app/core/services";
import { ReadonlyControl } from "@app/features/seatAdSources/SeatAdSourcesForm/components";
import { Col, Form, Input } from "antd";
import { FC } from "react";
import { usePublisherEmail } from "./usePublisherEmail";
import { isValidEmail } from "@rubicon/utils";

export const PublisherEmailInput: FC = () => {
    const { isVisible, isReadonly } = usePublisherEmail();
    if (!isVisible) {
        return null;
    }
    return (
        <Col sm={12}>
            <Form.Item
                name={["dealUnderEdit", "publisherEmail"]}
                validateDebounce={500}
                rules={[
                    {
                        validator(_, value) {
                            if (!value || isValidEmail.isValid(value)) {
                                return Promise.resolve();
                            }
                            return Promise.reject(new Error("Invalid email address"));
                        },
                    },
                ]}
                label="Publisher Email"
            >
                {isReadonly ? (
                    <ReadonlyControl<MarketplaceInfo["deal"]["publisherEmail"]> getLabel={(email) => email} />
                ) : (
                    <Input placeholder="Publisher Email" />
                )}
            </Form.Item>
        </Col>
    );
};
