import { FC } from "react";
import { Layout } from "antd";
import { PageHeader } from "@ant-design/pro-layout";
import { Loading } from "@app/core/components";
import { MappingExceptionsActions } from "../MappingExceptionsActions";
import { MappingExceptionsReadOnlyForm } from "../MappingExceptionsForm";
import { useMappingExceptionsDetailsPage } from "./useMappingExceptionsDetailsPage";

export const MappingExceptionsDetailsPage: FC = () => {
    const { mappingException, isFetching, onBack } = useMappingExceptionsDetailsPage();

    if (!mappingException || isFetching) {
        return <Loading />;
    }

    return (
        <Layout>
            <PageHeader title={mappingException.network.name} onBack={onBack}>
                <MappingExceptionsActions mappingException={mappingException} />
            </PageHeader>
            <Layout.Content>
                <MappingExceptionsReadOnlyForm mappingException={mappingException} />
            </Layout.Content>
        </Layout>
    );
};
