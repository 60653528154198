import { RevenueTypes } from "@app/core/clients/console";
import { DashboardSupplyReport, DashboardSupplyReportDatum } from "@app/core/services";
import moment from "moment-timezone";
import { LabeledValue } from "antd/lib/select";
import { GraphConfig } from "./ChartMetricsFilters";

const DEMO_DATE_NOW = "_NOW_";
const DEMO_DATE_UTC_ZERO = "_UTC_ZERO_";

export const getDateLabel = (period: LabeledValue | undefined, timeZone: string) => {
    if (!timeZone) {
        return "";
    }
    switch (period?.value) {
        case "today":
            return `${moment().tz(timeZone).format("ddd MM/DD/YY")}, 00:00 - ${moment()
                .subtract(1, "hours")
                .startOf("hour")
                .tz(timeZone)
                .format("HH:mm")} ${timeZone} (vs. ${moment()
                .subtract(1, "days")
                .tz(timeZone)
                .format("ddd MM/DD/YY")}, 00:00 - ${moment()
                .subtract(1, "hours")
                .startOf("hour")
                .tz(timeZone)
                .format("HH:mm")} ${timeZone})`;
        case "yesterday":
            return `${moment().subtract(1, "days").tz(timeZone).format("dddd, MM/DD/YY")} (vs. ${moment()
                .subtract(1, "days")
                .subtract(1, "weeks")
                .tz(timeZone)
                .format("dddd, MM/DD/YY")})`;
        case "weekly":
            return `${moment().startOf("isoWeek").tz(timeZone).format("MMM Do")} - ${moment()
                .tz(timeZone)
                .format("MMM Do, YYYY")} (vs. ${moment()
                .subtract(1, "weeks")
                .startOf("isoWeek")
                .tz(timeZone)
                .format("MMM Do")} - ${moment().subtract(1, "weeks").tz(timeZone).format("MMM Do, YYYY")})`;
        case "lastSevenDays":
            return `${moment().subtract(1, "weeks").format("MMM Do")} - ${moment()
                .subtract(1, "days")
                .format("MMM Do, YYYY")} (vs. ${moment().subtract(2, "weeks").format("MMM Do")} - ${moment()
                .subtract(1, "weeks")
                .subtract(1, "days")
                .format("MMM Do, YYYY")})`;
        case "monthly":
            return `${moment().startOf("month").format("MMM Do")} - ${moment()
                .subtract(1, "days")
                .format("MMM Do, YYYY")} (vs. ${moment()
                .subtract(1, "month")
                .startOf("month")
                .format("MMM Do")} - ${moment().subtract(1, "month").subtract(1, "days").format("MMM Do, YYYY")})`;
        case "lastThirtyDays":
            return `${moment().subtract(31, "days").format("MMM Do")} - ${moment()
                .subtract(1, "days")
                .format("MMM Do, YYYY")} (vs. ${moment().subtract(61, "days").format("MMM Do")} - ${moment()
                .subtract(32, "days")
                .format("MMM Do, YYYY")})`;
        case "quarterly":
            return `${moment().format("[Q]Q YYYY")} (vs. ${moment().subtract(1, "quarter").format("[Q]Q YYYY")})`;
        case "yearly":
            return `${moment().format("[This Year] YYYY")} (vs. ${moment()
                .subtract(1, "year")
                .format("[Last Year] YYYY")})`;
        default:
            return "";
    }
};

export const getChartTooltipTitle = (period: LabeledValue) => {
    switch (period?.value) {
        case "today":
        case "yesterday":
            return "Hour";
        case "weekly":
        case "lastSevenDays":
            return "Day of the Week";
        case "monthly":
        case "lastThirtyDays":
            return "Day of the Month";
        case "quarterly":
            return "Day of Quarter";
        case "yearly":
            return "Month";
        default:
            return "";
    }
};

const toPercent = (num, decimals = 0) => (num * 100).toFixed(decimals);

export const getChartStatValue = (stat, field, revenueType: RevenueTypes | null) => {
    if (field === "fillRate") {
        return stat.acceptedRequests === 0 ? 0 : toPercent(stat.fills / stat.acceptedRequests, 2);
    }
    if (field === "useRate") {
        return stat.fills === 0 ? 0 : toPercent(stat.impressions / stat.fills, 2);
    }
    if (["acceptedRequestRate", "standardFillRate", "standardUseRate"].includes(field)) {
        return toPercent(stat[field], 2);
    }
    if (field === "podAndPlaylistRevenue") {
        return revenueType === RevenueTypes.NET_REVENUE
            ? stat.podsNetRevenue + stat.playlistNetRevenue
            : stat.podsGrossRevenue + stat.playlistGrossRevenue;
    }
    if (field === "podsAndPlaylistRequests") {
        return stat.podRequests + stat.playlistRequests;
    }
    if (field === "podsAndPlaylistFills") {
        return stat.podFills + stat.playlistFills;
    }
    if (field === "podsAndPlaylistImpressions") {
        return stat.podImpressions + stat.playlistImpressions;
    }
    if (field === "PodAndPlaylistRevenueCpm") {
        return stat.netRevenueCpm;
    }
    if (field === "podsAndPlaylistFillRate") {
        return toPercent((stat.playlistFillRate + stat.podFillRate) / 2);
    }
    if (field === "podsAndPlaylistUseRate") {
        return toPercent((stat.podUseRate + stat.playlistUseRate) / 2);
    }
    if (field === "standardAcceptedRate") {
        return stat.standardRequests === 0 ? 0 : toPercent(stat.standardAcceptedRequests / stat.standardRequests);
    }
    if (field === "netRevenue") {
        return revenueType === RevenueTypes.NET_REVENUE ? stat.netRevenue : stat.grossRevenue;
    }
    if (field === "netRevenueCpm") {
        return revenueType === RevenueTypes.NET_REVENUE ? stat.netRevenueCpm : stat.grossRevenueCpm;
    }

    return stat[field];
};

export const getChartStatDate = (stat, timeProp, period) => {
    if (period?.value === "weekly") {
        return moment(stat[timeProp]).format("dddd");
    }
    if (period?.value === "lastSevenDays") {
        return moment(stat[timeProp]).format("dddd");
    }
    if (period?.value === "monthly") {
        return moment(stat[timeProp]).date();
    }
    if (period?.value === "lastThirtyDays") {
        const diff = moment.utc().diff(moment(stat[timeProp]), "days");
        return diff % 30 === 0 ? 1 : 31 - (diff % 30);
    }
    return stat[timeProp];
};

const getGroupedChartStats = (stats: { value: number; date: string }[]) => {
    return Object.entries(
        stats.reduce((acc, value) => {
            if (!acc[value.date]) {
                acc[value.date] = [];
            }
            acc[value.date].push(value);
            return acc;
        }, {})
    );
};

export const getGroupedChartStatsMetricOne = (
    stats: { value: number; date: string }[],
    metricTitle: string,
    periodTitle: string
) => {
    return getGroupedChartStats(stats).map(([date, value]: [string, { date: string; value: number }[]]) => ({
        date,
        type: `${metricTitle} for ${periodTitle}`,
        value: value.reduce((acc, v) => acc + v.value, 0),
    }));
};

export const getGroupedChartStatsMetricTwo = (
    stats: { value: number; date: string }[],
    metricConfig: GraphConfig,
    periodTitle: string
) => {
    return getGroupedChartStats(stats).map(([date, value]: [string, { date: string; value: number }[]]) => ({
        date,
        name: `${metricConfig.title} for ${periodTitle}`,
        count: value.reduce((acc, v) => acc + v.value, 0) / (metricConfig.averageValue ? value.length : 1),
    }));
};

export const getOtimeNtimeMap = (adStat) => {
    if (!adStat) {
        return {
            otime: 0,
            ntime: 0,
        };
    }
    return (!Array.isArray(adStat) ? [adStat] : adStat).reduce(
        (timeStats, adStat) => {
            if (!adStat.requests) {
                return timeStats;
            }
            timeStats.otime =
                adStat.otime === DEMO_DATE_UTC_ZERO
                    ? moment().startOf("day").valueOf()
                    : Math.max(adStat.otime, timeStats.otime);
            timeStats.ntime =
                adStat.ntime === DEMO_DATE_NOW ? moment().valueOf() : Math.max(adStat.ntime, timeStats.ntime);
            return timeStats;
        },
        {
            otime: 0,
            ntime: 0,
        }
    );
};

export const getLiveStatsDateLabel = (timeZone) => {
    const startOfDay = moment.utc().startOf("day").startOf("hour").tz(timeZone);
    return `Totals from ${startOfDay.calendar()} until ${moment().fromNow()} (${timeZone})`;
};

export const generateDemoSeatData = (period): DashboardSupplyReport => {
    const thisPeriodHourlyData: DashboardSupplyReportDatum[] = [];
    const lastPeriodHourlyData: DashboardSupplyReportDatum[] = [];
    const thisPeriodDailyData: DashboardSupplyReportDatum[] = [];
    const lastPeriodDailyData: DashboardSupplyReportDatum[] = [];
    const thisPeriodMonthlyData: DashboardSupplyReportDatum[] = [];
    const lastPeriodMonthlyData: DashboardSupplyReportDatum[] = [];

    if (period === "today") {
        for (let i = 0; i < moment.utc().hour() + 1; i++) {
            thisPeriodHourlyData.push(generateHourlyData(moment().format("YYYY-MM-DD"), i));
        }
        for (let i = 0; i < 24; i++) {
            lastPeriodHourlyData.push(generateHourlyData(moment().subtract(1, "days").format("YYYY-MM-DD"), i));
        }
    } else if (period === "yesterday") {
        for (let i = 0; i < 24; i++) {
            thisPeriodHourlyData.push(generateHourlyData(moment().subtract(1, "days").format("YYYY-MM-DD"), i));
        }
        for (let i = 0; i < 24; i++) {
            lastPeriodHourlyData.push(generateHourlyData(moment().subtract(8, "days").format("YYYY-MM-DD"), i));
        }
    } else if (period === "weekly") {
        let thisPeriodDate = moment().startOf("isoWeek");
        while (thisPeriodDate.isBefore(moment().startOf("day"))) {
            for (let i = 0; i < 24; i++) {
                thisPeriodHourlyData.push(generateHourlyData(thisPeriodDate.format("YYYY-MM-DD"), i));
            }
            thisPeriodDate = thisPeriodDate.add(1, "days");
        }
        let lastPeriodDate = moment().subtract(1, "week").startOf("isoWeek");
        while (lastPeriodDate.isBefore(moment().startOf("isoWeek"))) {
            for (let i = 0; i < 24; i++) {
                lastPeriodHourlyData.push(generateHourlyData(lastPeriodDate.format("YYYY-MM-DD"), i));
            }
            lastPeriodDate = lastPeriodDate.add(1, "days");
        }
    } else if (period === "lastSevenDays") {
        let thisPeriodDate = moment().subtract(1, "week");
        while (thisPeriodDate.isBefore(moment().startOf("day"))) {
            for (let i = 0; i < 24; i++) {
                thisPeriodHourlyData.push(generateHourlyData(thisPeriodDate.format("YYYY-MM-DD"), i));
            }
            thisPeriodDate = thisPeriodDate.add(1, "days");
        }
        let lastPeriodDate = moment().subtract(2, "weeks");
        while (lastPeriodDate.isBefore(moment().subtract(1, "weeks").startOf("day"))) {
            for (let i = 0; i < 24; i++) {
                lastPeriodHourlyData.push(generateHourlyData(lastPeriodDate.format("YYYY-MM-DD"), i));
            }
            lastPeriodDate = lastPeriodDate.add(1, "days");
        }
    } else if (period === "monthly") {
        let thisPeriodDate = moment().startOf("month");
        while (thisPeriodDate.isBefore(moment())) {
            thisPeriodDailyData.push(generateDailyData(thisPeriodDate.format("YYYY-MM-DD")));
            thisPeriodDate = thisPeriodDate.add(1, "days");
        }
        for (let i = 0; i < 24; i++) {
            thisPeriodHourlyData.push(generateHourlyData(moment().subtract(1, "days").format("YYYY-MM-DD"), i));
        }
        let lastPeriodDate = moment().subtract(1, "month").startOf("month");
        while (lastPeriodDate.isBefore(moment().subtract(1, "month").endOf("month"))) {
            lastPeriodDailyData.push(generateDailyData(lastPeriodDate.format("YYYY-MM-DD")));
            lastPeriodDate = lastPeriodDate.add(1, "days");
        }
    } else if (period === "lastThirtyDays") {
        let thisPeriodDate = moment().subtract(30, "days");
        while (thisPeriodDate.isBefore(moment())) {
            thisPeriodDailyData.push(generateDailyData(thisPeriodDate.format("YYYY-MM-DD")));
            thisPeriodDate = thisPeriodDate.add(1, "days");
        }
        for (let i = 0; i < 24; i++) {
            thisPeriodHourlyData.push(generateHourlyData(moment().subtract(1, "days").format("YYYY-MM-DD"), i));
        }
        let lastPeriodDate = moment().subtract(60, "days");
        while (lastPeriodDate.isBefore(moment().subtract(30, "days"))) {
            lastPeriodDailyData.push(generateDailyData(lastPeriodDate.format("YYYY-MM-DD")));
            lastPeriodDate = lastPeriodDate.add(1, "days");
        }
    } else if (period === "quarterly") {
        let thisPeriodDate = moment().startOf("quarter");
        let thisPeriodDayOfQuarter = 1;
        while (thisPeriodDate.isBefore(moment().subtract(2, "days"))) {
            thisPeriodDailyData.push(generateDailyData(thisPeriodDate.format("YYYY-MM-DD"), thisPeriodDayOfQuarter++));
            thisPeriodDate = thisPeriodDate.add(1, "days");
        }
        for (let i = 0; i < 24; i++) {
            thisPeriodHourlyData.push(generateHourlyData(moment().subtract(1, "days").format("YYYY-MM-DD"), i));
        }
        let lastPeriodDate = moment().subtract(1, "quarter").startOf("quarter");
        let lastPeriodDayOfQuarter = 1;
        while (lastPeriodDate.isBefore(moment().subtract(1, "quarter").endOf("quarter"))) {
            lastPeriodDailyData.push(generateDailyData(lastPeriodDate.format("YYYY-MM-DD"), lastPeriodDayOfQuarter++));
            lastPeriodDate = lastPeriodDate.add(1, "days");
        }
    } else if (period === "yearly") {
        let thisPeriodDate = moment().startOf("year");
        while (thisPeriodDate.isBefore(moment())) {
            thisPeriodMonthlyData.push(generateMonthlyData(thisPeriodDate.format("MMMM")));
            thisPeriodDate = thisPeriodDate.add(1, "month");
        }
        for (let i = 0; i < 24; i++) {
            thisPeriodHourlyData.push(generateHourlyData(moment().subtract(1, "days").format("YYYY-MM-DD"), i));
        }
        let lastPeriodDate = moment().subtract(1, "year").startOf("year");
        while (lastPeriodDate.isBefore(moment().subtract(1, "year"))) {
            lastPeriodMonthlyData.push(generateMonthlyData(lastPeriodDate.format("MMMM")));
            lastPeriodDate = lastPeriodDate.add(1, "month");
        }
    }
    return {
        thisPeriod: {
            hourlyData: { fields: [], data: thisPeriodHourlyData },
            dailyData: { fields: [], data: thisPeriodDailyData },
            monthlyData: { fields: [], data: thisPeriodMonthlyData },
        },
        lastPeriod: {
            hourlyData: { fields: [], data: lastPeriodHourlyData },
            dailyData: { fields: [], data: lastPeriodDailyData },
            monthlyData: { fields: [], data: lastPeriodMonthlyData },
        },
    };
};

const generateHourlyData = (day, hour) => {
    return generateStatData(1e3, 2e3, { day, hour });
};

const generateDailyData = (day, dayOfQuarter?) => {
    return generateStatData(2e4, 3e4, { day, dayOfQuarter });
};

const generateMonthlyData = (month) => {
    return generateStatData(5e5, 7e5, { month });
};

const generateStatData = (min, max, dateData = {}) => ({
    playlistRequests: getRandomStatValue(min, max),
    podRequests: getRandomStatValue(min, max),
    totalPodSlots: getRandomStatValue(min, max),
    totalAdBreaks: getRandomStatValue(min, max),
    avgPodsPerPlaylist: getRandomStatValue(min, max),
    avgPodSlotsPerAdBreak: getRandomStatValue(min, max),
    avgPodSlotsPerPlaylist: getRandomStatValue(min, max),
    podFillRate: getRandomStatValue(min, max),
    playlistFillRate: getRandomStatValue(min, max),
    adBreaksInPlaylist: getRandomStatValue(min, max),
    standardRequests: getRandomStatValue(min, max),
    standardFillRate: getRandomStatValue(min, max),
    podImpressions: getRandomStatValue(min, max),
    playlistImpressions: getRandomStatValue(min, max),
    standardImpressions: getRandomStatValue(min, max),
    podUseRate: getRandomStatValue(min, max),
    playlistUseRate: getRandomStatValue(0.6, 0.99, 2),
    standardUseRate: getRandomStatValue(0.6, 0.99, 2),
    slotsPerPodRequest: getRandomStatValue(min, max),
    podsNetRevenue: getRandomStatValue(min, max),
    playlistNetRevenue: getRandomStatValue(min, max),
    standardNetRevenue: getRandomStatValue(min, max),
    standardAcceptedRequests: getRandomStatValue(min, max),
    podAcceptedRequests: getRandomStatValue(min, max),
    playlistAcceptedRequests: getRandomStatValue(min, max),
    podFills: getRandomStatValue(min, max),
    playlistFills: getRandomStatValue(min, max),
    standardFills: getRandomStatValue(min, max),
    podsGrossRevenue: getRandomStatValue(min, max),
    playlistGrossRevenue: getRandomStatValue(min, max),
    standardGrossRevenue: getRandomStatValue(min, max),
    slotsForAdPods: getRandomStatValue(min, max),
    slotsForPlaylists: getRandomStatValue(min, max),
    overallOpportunities: getRandomStatValue(min, max),
    impressions: getRandomStatValue(min, max),
    requests: getRandomStatValue(min, max),
    netRevenue: getRandomStatValue(min, max),
    grossRevenue: getRandomStatValue(min, max),
    useRate: getRandomStatValue(0.6, 0.99, 2),
    fillRate: getRandomStatValue(0.6, 0.99, 2),
    currency: "USD",
    currencyId: getRandomStatValue(min, max),
    acceptedRequestRate: Math.random(),
    acceptedRequests: getRandomStatValue(min, max),
    netRevenueCpm: getRandomStatValue(min, max),
    grossRevenueCpm: getRandomStatValue(min, max),
    fills: getRandomStatValue(min, max),
    ...dateData,
});

const getRandomStatValue = (min, max, decimals = 0) => +(Math.random() * (max - min) + min).toFixed(decimals);
