import { FC } from "react";
import { BlockedIndustriesSelect } from "@app/core/components";
import { CREATE_DEAL_FORM_ITEMS_NAME } from "@app/features/deals/constants";
import { useAdSourceBlockedIndustriesField } from "./useAdSourceBlockedIndustriesField";

export const AdSourceBlockedIndustriesField: FC = () => {
    const { value, advertiserDomainModeId, industriesModeId, onChange } = useAdSourceBlockedIndustriesField();

    return (
        <BlockedIndustriesSelect
            name={CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_BLOCKED_INDUSTRIES}
            advertiserDomainModeId={advertiserDomainModeId}
            industriesModeId={industriesModeId}
            value={value}
            onChange={onChange}
        />
    );
};
