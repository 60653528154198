import { useGetSeatFinanceDefaultsQuery } from "@app/core/services";
import { getDefaultCostModel } from "@app/features/seatAdSources/SeatAdSourcesForm/utils/getDefaultCostModel";
import { GUTTER, COL_SIZES } from "@app/features/deals/constants";
import { AD_SOURCE_FIELDS } from "@app/features/seatAdSources/constants";
import { Col, Collapse, Form, Row } from "antd";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { AdSourceFormsContext, AdSourcesForm } from "../../SeatAdSourcesForm";
import { CostModelSelect, CostInput, AllowAutoscaleRadio, RegionSelect } from "./Fields";
import { classLilstOptionsSection } from "@app/features/deals/DealForm/DealFormCreateAdSource/DealFormCreateAdSourceSections";
import { adminInternalSectionClassList } from "@app/features/deals/DealForm";
import { cx } from "@emotion/css";

export const AdminSettings = () => {
    const { seatId } = useParams<{ seatId: string }>();
    const adSourceType = Form.useWatch<AdSourcesForm["type"]>("type");
    const costModel = Form.useWatch<AdSourcesForm["costModel"]>("costModel");
    const { data } = useGetSeatFinanceDefaultsQuery(Number(seatId));
    const currentDefault = getDefaultCostModel(adSourceType?.id, data);
    const {
        forms: {
            adSourceFormMode,
            adSourceForm: { setFieldValue },
        },
    } = useContext(AdSourceFormsContext);
    const [defaultAssigned, setDefaultAssigned] = useState(false);

    useEffect(() => {
        // reset cost model as defaulted whenever the ad source type / cost model type changes so that the default can be reassigned
        setDefaultAssigned(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [adSourceType, costModel]);

    useEffect(() => {
        if (adSourceFormMode === "create" && currentDefault && !defaultAssigned) {
            setFieldValue(AD_SOURCE_FIELDS.COST_MODEL.name, currentDefault.costModel);
            setFieldValue(AD_SOURCE_FIELDS.COST_PERCENT.name, currentDefault.costValuePercent);
            setFieldValue(
                AD_SOURCE_FIELDS.COST_FIXED.name,
                currentDefault.costValueFixed ? currentDefault.costValueFixed / 1000 : 0
            );
            setDefaultAssigned(true);
        }
    }, [currentDefault, adSourceFormMode, defaultAssigned, setFieldValue]);

    return (
        <Collapse
            expandIconPosition="end"
            className={cx(classLilstOptionsSection, adminInternalSectionClassList)}
            ghost
        >
            <Collapse.Panel
                key="admin"
                header={<span style={{ fontSize: "14px", color: "#3042F9", fontWeight: "400" }}>Admin Options</span>}
                forceRender
            >
                <Row gutter={GUTTER}>
                    <Col {...COL_SIZES}>
                        <CostModelSelect currentDefaultName={currentDefault?.costModel.name} />
                    </Col>
                    <Col {...COL_SIZES}>
                        <CostInput
                            currentDefaultId={currentDefault?.costModel.id}
                            currentDefaultValue={currentDefault?.costValueFixed || currentDefault?.costValuePercent}
                        />
                    </Col>
                </Row>
                <Row gutter={GUTTER}>
                    <Col {...COL_SIZES}>
                        <AllowAutoscaleRadio />
                    </Col>
                    <Col {...COL_SIZES}>
                        <RegionSelect />
                    </Col>
                </Row>
            </Collapse.Panel>
        </Collapse>
    );
};
