import { Col, Form, Input, Row, Typography } from "antd";
import { FC } from "react";
import { WEEK_YEAR_MONTH_DAY_HOUR_MINUTE_AMPM_TIMEZONE } from "@app/core/components/constants";
import { AdvertiserDomainRaw } from "@app/core/services";
import { AdvertiserDomainDefinitionsButtonAndDrawer } from "./AdvertiserDomainDefinitionDrawer";
import { formatDateInUtc } from "@app/core/utils";

export const ADOMAIN_DETAILS_NAME_INPUT_SDET = "advertiser-domain-name-input";
export const ADOMAIN_DETAILS_UPDATED_INPUT_SDET = "advertiser-domain-updated-input";
export const ADOMAIN_DETAILS_CREATED_INPUT_SDET = "advertiser-domain-created-input";
export const ADOMAIN_DETAILS_TYPE_SDET = "advertiser-domain-type-input";
export const ADOMAIN_DETAILS_DOMAINS_SDET = "advertiser-domains-textarea";
export const ADOMAIN_DELETE_BTN_SDET = "advertiser-domains-delete-btn";

interface Props {
    aDomain?: AdvertiserDomainRaw;
    isDetailsPage?: boolean;
}
export const ADOMAIN_DETAILS_CONTENT_SDET = "advertiser-domain-details-content";

export const AdvertiserDomainDetailsContent: FC<Props> = ({ aDomain, isDetailsPage = false }) => {
    return aDomain ? (
        <Form size="small" layout="vertical" data-sdet={ADOMAIN_DETAILS_CONTENT_SDET} wrapperCol={{ xs: 24, md: 12 }}>
            <Row justify="space-between">
                <Col xs={{ span: 24, order: 1 }} md={{ span: isDetailsPage ? 12 : 24, order: 0 }}>
                    <Form.Item label="Name">
                        <Typography.Text data-sdet={ADOMAIN_DETAILS_NAME_INPUT_SDET}>{aDomain.name}</Typography.Text>
                    </Form.Item>
                </Col>
                {isDetailsPage && (
                    <Col xs={{ order: 0 }} md={{ order: 1 }}>
                        <AdvertiserDomainDefinitionsButtonAndDrawer />
                    </Col>
                )}
            </Row>
            <Form.Item label="Type">
                <Typography.Text data-sdet={ADOMAIN_DETAILS_TYPE_SDET}>
                    {aDomain.advertiserDomainListType.name}
                </Typography.Text>
            </Form.Item>
            <Form.Item label="Last Updated (UTC)">
                <Typography.Text data-sdet={ADOMAIN_DETAILS_UPDATED_INPUT_SDET}>
                    {formatDateInUtc(aDomain.updateDate, WEEK_YEAR_MONTH_DAY_HOUR_MINUTE_AMPM_TIMEZONE)}
                </Typography.Text>
            </Form.Item>
            <Form.Item label="Date Created (UTC)">
                <Typography.Text data-sdet={ADOMAIN_DETAILS_CREATED_INPUT_SDET}>
                    {formatDateInUtc(aDomain.creationDate, WEEK_YEAR_MONTH_DAY_HOUR_MINUTE_AMPM_TIMEZONE)}
                </Typography.Text>
            </Form.Item>
            <Form.Item label="Domains">
                <Input.TextArea
                    disabled
                    value={aDomain.advertiserDomains?.toString().split(",").join("\r\n")}
                    rows={6}
                    data-sdet={ADOMAIN_DETAILS_DOMAINS_SDET}
                    style={{ backgroundColor: "unset" }}
                />
            </Form.Item>
        </Form>
    ) : null;
};
