import { FC } from "react";
import { Form, Radio } from "antd";
import { FORM_FIELDS } from "../../constants";
import { useAtLeastOneOverrideRule } from "../../useAtLeastOneOverrideRule";
import { useIndustryOverrideModeRadio } from "./useIndustryOverrideModeRadio";

const { name, label } = FORM_FIELDS.INDUSTRY_OVERRIDE_MODE;

export const IndustryOverrideModeRadio: FC = () => {
    const { onChange } = useIndustryOverrideModeRadio();
    const atLeastOneOverrideRule = useAtLeastOneOverrideRule();

    return (
        <Form.Item name={name} label={label} rules={[atLeastOneOverrideRule]}>
            <Radio.Group onChange={onChange}>
                <Radio value={1}>Add New Industries</Radio>
                <Radio value={2}>Override Existing Industries</Radio>
                <Radio value={3}>None</Radio>
            </Radio.Group>
        </Form.Item>
    );
};
