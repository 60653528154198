import { GlobalIndustry } from "@app/core/components";
import { consoleApi, MAPPING_EXCEPTIONS_TAG } from "./console";
import { toQueryString } from "./utils";

export interface MappingException {
    creationDate: string;
    updateDate: string;
    id: number;
    network: {
        id: number;
        seat: {
            id: number;
            name: string;
            code: string;
            entityType: "Seat";
        };
        name: string;
        entityType: string;
    };
    adomain: string;
    crid: string;
    namespace: "g" | string; // "g" for global, "s:12345" for seat
    adomainOverride: string | null;
    industryOverrideMode: {
        creationDate: string;
        updateDate: string;
        id: number;
        name: string;
    };
    industryOverrideList: GlobalIndustry[];
    taxonomyOverrideMode: {
        creationDate: string;
        updateDate: string;
        id: number;
        name: string;
    };
    taxonomyOverrideList: {
        creationDate: string;
        updateDate: string;
        id: number;
        name: string;
    }[];
}

export interface GetSeatMappingExceptionsResponse {
    page: number;
    max: number;
    totalResults: number;
    mappingExceptions: MappingException[];
}

export interface GetMappingExceptionsParams {
    seatId: string;
    max?: number;
    page?: number;
    orderBy?: string | undefined; // "network" | "crid" | "adomain" | "dateCreated" | "lastUpdated";
    isAscending?: boolean;
    keyword?: string;
}

export interface CreateMappingExceptionParams {
    seatId: string;
    body: MappingExceptionPayload;
}

export interface UpdateMappingExceptionParams {
    mappingExceptionId: number;
    body: MappingExceptionPayload;
}

export interface MappingExceptionPayload {
    adomain: string;
    adomainOverride: string;
    crid: string;
    industryOverrideList: { id: number }[] | null;
    industryOverrideMode: { id: number };
    namespace: string; // "g" for global, "s:12345" for seat
    network: { id: number; name: string };
    taxonomyOverrideMode: { id: number };
    taxonomyOverrideList: { id: number }[] | null;
}

export const mappingExceptionsApi = consoleApi.injectEndpoints({
    endpoints: (builder) => ({
        getSeatMappingExceptions: builder.query<GetSeatMappingExceptionsResponse, GetMappingExceptionsParams>({
            query: ({ seatId, ...rest }) => `seats/${seatId}/mappingExceptions;${toQueryString(rest)}`,
            providesTags: [MAPPING_EXCEPTIONS_TAG],
        }),
        getMappingExceptionById: builder.query<MappingException, number>({
            query: (mappingExceptionId) => `mappingExceptions/${mappingExceptionId}`,
            providesTags: [MAPPING_EXCEPTIONS_TAG],
        }),
        createSeatMappingException: builder.mutation<MappingException, CreateMappingExceptionParams>({
            query: ({ seatId, body }) => ({
                url: `seats/${seatId}/mappingExceptions`,
                method: "POST",
                body,
            }),
            invalidatesTags: (_, err) => (err ? [] : [MAPPING_EXCEPTIONS_TAG]),
        }),
        updateMappingException: builder.mutation<MappingException, UpdateMappingExceptionParams>({
            query: ({ mappingExceptionId, body }) => ({
                url: `mappingExceptions/${mappingExceptionId}`,
                method: "PUT",
                body,
            }),
            invalidatesTags: (_, err) => (err ? [] : [MAPPING_EXCEPTIONS_TAG]),
        }),
        deleteMappingExceptionById: builder.mutation<void, number>({
            query: (mappingExceptionId) => ({
                url: `mappingExceptions/${mappingExceptionId}`,
                method: "DELETE",
            }),
            invalidatesTags: (_, err, mappingExceptionId) =>
                err ? [] : [MAPPING_EXCEPTIONS_TAG, { type: MAPPING_EXCEPTIONS_TAG, id: mappingExceptionId }],
        }),
    }),
    overrideExisting: false,
});

export const {
    useGetSeatMappingExceptionsQuery,
    useGetMappingExceptionByIdQuery,
    useCreateSeatMappingExceptionMutation,
    useUpdateMappingExceptionMutation,
    useDeleteMappingExceptionByIdMutation,
} = mappingExceptionsApi;
