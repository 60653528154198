import { useState } from "react";
import { useParams } from "react-router-dom";
import { message } from "antd";
import { LabeledValue } from "antd/lib/select";
import { BaseQueryFn, MutationDefinition } from "@reduxjs/toolkit/dist/query/react";
import { UseMutation } from "@reduxjs/toolkit/dist/query/react/buildHooks";
import { setInvalidBulkValues, trimBulkUploadValues } from "@app/features/targeting/helpers";

export const useBulkTargetingDrawerButton = <T,>(
    onChange: (value: LabeledValue[]) => void,
    values: LabeledValue[],
    useBulkValidationMutation: UseMutation<
        MutationDefinition<{ seatId: number; body: string[] }, BaseQueryFn, string, T[], string>
    >,
    transformToLabeledValues: (input: T[] | null) => LabeledValue[],
    validBulkValuesMapper: (input: T | null) => string
) => {
    const { seatId } = useParams<{ seatId: string }>();
    const [isOpen, setIsOpen] = useState(false);
    const [value, setValue] = useState("");
    const [invalidValues, setInvalidValues] = useState<string[]>([]);
    const [isLoadingAdd, setIsLoadingAdd] = useState(false);
    const [isLoadingReplace, setIsLoadingReplace] = useState(false);

    const [validateBulkInputs] = useBulkValidationMutation();

    const handleClose = () => {
        setInvalidValues([]);
        setValue("");
        setIsOpen(false);
    };
    const handleOpen = () => setIsOpen(true);
    const handleChangeValue = (v) => setValue(v);

    const handleReplace = async () => {
        setIsLoadingReplace(true);
        const allBulkValues = trimBulkUploadValues(value);
        let validBulkInputs;

        try {
            validBulkInputs = await validateBulkInputs({
                seatId: Number(seatId),
                body: allBulkValues,
            }).unwrap();
            onChange(transformToLabeledValues(validBulkInputs));
        } catch (_) {
            // TODO: add more robust error handling, if possible
            setIsLoadingReplace(false);
            message.error("Validation failed");
            return;
        }

        const validValues = new Set(validBulkInputs.map((input) => validBulkValuesMapper(input).toLowerCase()));

        setInvalidBulkValues(allBulkValues, validValues, setInvalidValues, setValue, setIsOpen);
        setIsLoadingReplace(false);
    };

    const handleAdd = async () => {
        setIsLoadingAdd(true);
        const allBulkValues = trimBulkUploadValues(value);
        let validBulkInputs;

        try {
            validBulkInputs = await validateBulkInputs({
                seatId: Number(seatId),
                body: allBulkValues,
            }).unwrap();
        } catch (_) {
            setIsLoadingAdd(false);
            message.error("Validation failed");
            return;
        }

        const existingValuesByValue = values.reduce<{
            [value: string | number]: LabeledValue;
        }>((byValue, value) => {
            byValue[value.value] = value;
            return byValue;
        }, {});
        const combinedValueByValue = transformToLabeledValues(validBulkInputs).reduce((byValue, value) => {
            byValue[value.value] = value;
            return byValue;
        }, existingValuesByValue);
        const combinedValues = Object.values<LabeledValue>(combinedValueByValue);

        const validValues = new Set(validBulkInputs?.map((input) => validBulkValuesMapper(input).toLowerCase()));

        setInvalidBulkValues(
            allBulkValues,
            validValues,
            setInvalidValues,
            setValue,
            setIsOpen,
            combinedValues,
            onChange
        );

        setIsLoadingAdd(false);
    };

    return {
        handleAdd,
        handleChangeValue,
        handleClose,
        handleOpen,
        handleReplace,
        isLoadingAdd,
        isLoadingReplace,
        isOpen,
        value,
        invalidValues,
    };
};
