import { Col, Flex, Form, Row } from "antd";
import { FC } from "react";
import { AddFrequencyCappingItem } from "./AddFrequencyCapping/AddFrequencyCappingItem";
import { DealFreqCappingsMultiselect } from "./DealFreqCappingsMultiselect";
import { FrequencyCappingTypeSelect } from "./FrequencyCappingTypeSelect/FrequencyCappingTypeSelect";
import { DEAL_FORM_FIELDS } from "../../../../constants";
import { useMarketplaceInfoWidget } from "@app/features/seatAdSources/SeatAdSourcesForm/useMarketplaceInfoWidget";
import { COL_SIZES, GUTTER } from "@app/features/seatAdSources/constants";

export const FrequencyCapping: FC = () => {
    const { marketplaceInfoMode } = useMarketplaceInfoWidget();
    const freqCappingType = Form.useWatch<{ name: string }>(DEAL_FORM_FIELDS.FREQUENCY_CAPPING_TYPE.name);

    if (marketplaceInfoMode.name === "view") {
        return (
            <>
                <FrequencyCappingTypeSelect />
                <DealFreqCappingsMultiselect />
            </>
        );
    }

    if (freqCappingType?.name === "Off") {
        return (
            <Row gutter={GUTTER}>
                <Col {...COL_SIZES}>
                    <FrequencyCappingTypeSelect />
                </Col>
            </Row>
        );
    }

    return (
        <>
            <Flex align="end" gap={8}>
                <FrequencyCappingTypeSelect style={{ marginBottom: 0 }} />
                <AddFrequencyCappingItem />
            </Flex>
            <DealFreqCappingsMultiselect style={{ marginTop: "0.5rem" }} />
        </>
    );
};
