import { Form } from "antd";
import { FORM_FIELDS } from "../../constants";
import { OverrideMode } from "../../types";
import { getOverrideModeLabel } from "../../utils";

const { ADD_OVERRIDE_INDUSTRIES, INDUSTRY_OVERRIDE_MODE } = FORM_FIELDS;
const { ADD, OVERRIDE, NONE } = OverrideMode;

interface UseAddOverrideIndustriesSelect {
    isDisabled: boolean;
    isRequired: boolean;
    label: string;
}

export const useAddOverrideIndustriesSelect = (): UseAddOverrideIndustriesSelect => {
    const form = Form.useFormInstance();
    const industryOverrideMode = Form.useWatch(INDUSTRY_OVERRIDE_MODE.name, form);

    const isDisabled = industryOverrideMode === NONE;

    const isRequired = [ADD, OVERRIDE].includes(industryOverrideMode);

    const label = getOverrideModeLabel(ADD_OVERRIDE_INDUSTRIES.label, industryOverrideMode);

    return {
        isDisabled,
        isRequired,
        label,
    };
};
