import { Form } from "antd";
import { FORM_FIELDS } from "../../constants";

const { INDUSTRY_OVERRIDE_MODE, TAXONOMY_OVERRIDE_MODE } = FORM_FIELDS;

interface UseAdvertiserDomainOverrideInput {
    onChange: () => void;
}

export const useAdvertiserDomainOverrideInput = (): UseAdvertiserDomainOverrideInput => {
    const form = Form.useFormInstance();

    const onChange = () => {
        form.validateFields([INDUSTRY_OVERRIDE_MODE.name, TAXONOMY_OVERRIDE_MODE.name]);
    };

    return {
        onChange,
    };
};
