import { FC } from "react";
import { Radio } from "antd";
import { OVERRIDE_TRANSPARENCY_OPTIONS } from "@app/features/deals/constants";
import { useAdSourceBundleIdField } from "./useAdSourceBundleIdField";
import { TransparencyFormItem } from "@app/features/deals/DealForm/DealFormCreateAdSource/DealFormCreateAdSourceSections/AdSourceTransparencySection/Fields";
import { AD_SOURCE_FIELDS } from "@app/features/seatAdSources/constants";

export const AdSourceBundleIdField: FC = () => {
    const { onChange, value, helpText } = useAdSourceBundleIdField();
    return (
        <TransparencyFormItem
            label={AD_SOURCE_FIELDS.BUNDLE_ID.label}
            help={helpText}
            data-sdet="ad-source-bundle-id-field"
        >
            <Radio.Group
                onChange={(e) => onChange(e?.target?.value)}
                value={value}
                options={OVERRIDE_TRANSPARENCY_OPTIONS}
            />
        </TransparencyFormItem>
    );
};
