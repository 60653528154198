import { FC } from "react";
import { Form } from "antd";
import { BlockedIndustriesSelect as CoreBlockedIndustriesSelect } from "@app/core/components/BlockedIndustriesSelect";
import { IndustryBlockMode, SeatAdSourcesType } from "@app/core/services";
import { AD_SOURCE_FIELDS, VERTICAL_LAYOUT_FIX } from "@app/features/seatAdSources/constants";

const { BLOCKED_INDUSTRIES, INDUSTRIES_MODE, MODE } = AD_SOURCE_FIELDS;

export const BlockedIndustriesSelect: FC = () => {
    const advertiserDomainModeId = Form.useWatch<SeatAdSourcesType>(MODE.name)?.id;
    const industriesModeId = Form.useWatch<IndustryBlockMode>(INDUSTRIES_MODE.name)?.id;

    return (
        <CoreBlockedIndustriesSelect
            name={BLOCKED_INDUSTRIES.name}
            advertiserDomainModeId={advertiserDomainModeId}
            industriesModeId={industriesModeId}
            {...VERTICAL_LAYOUT_FIX}
        />
    );
};
