import { FC } from "react";
import { Form, Select } from "antd";
import { LabeledValue } from "antd/es/select";
import { TagRender } from "@app/core/components";
import { SelectAllButtonDropdownRender } from "@app/core/components/SelectAllButtonDropdownRender";
import { FORM_FIELDS } from "../../constants";
import { useAddOverrideTaxonomiesSelect } from "./useAddOverrideTaxonomiesSelect";

export const AddOverrideTaxonomiesSelect: FC = () => {
    const {
        value,
        isDisabled,
        isRequired,
        label,
        options,
        isFetching,
        search,
        onChangeSearch,
        onBlur,
        onChange,
        onChangeSelectAll,
        getPrimaryKey,
    } = useAddOverrideTaxonomiesSelect();

    return (
        <Form.Item
            name={FORM_FIELDS.ADD_OVERRIDE_TAXONOMIES.name}
            label={label}
            rules={[{ required: isRequired, message: `${label} is required` }]}
        >
            <Select
                allowClear
                labelInValue
                showSearch
                mode="multiple"
                placeholder="Select Taxonomies"
                disabled={isDisabled}
                filterOption={false}
                loading={isFetching}
                options={options}
                searchValue={search}
                onBlur={onBlur}
                onSearch={onChangeSearch}
                tagRender={TagRender}
                onChange={onChange}
                dropdownRender={(menu) => (
                    <SelectAllButtonDropdownRender<LabeledValue>
                        menu={menu}
                        value={value ?? undefined}
                        search={search}
                        options={options}
                        getPrimaryKey={getPrimaryKey}
                        onChange={onChangeSelectAll}
                    />
                )}
            />
        </Form.Item>
    );
};
