import { useParams } from "react-router-dom";
import { PacingTypeOption } from "./types";
import { useGetAdSourcePacingTypesQuery } from "@app/core/services";

interface UsePacingTypeOptions {
    options: PacingTypeOption[];
}

export const usePacingTypeOptions = (): UsePacingTypeOptions => {
    const { seatId } = useParams<{ seatId: string }>();
    const { data } = useGetAdSourcePacingTypesQuery(Number(seatId));
    const options =
        data?.map((item) => ({
            id: item.id,
            name: item.name,
        })) ?? [];

    return {
        options,
    };
};
