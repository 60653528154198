export const IFRAMED_APP_ID = "streaming-integrations";

export const INTEGRATIONS_ROUTES = {
    CONTENT: "content",
    SSP_CONNECT: "ssp-connect",
    DMP: "dmp",
    ADVANCED_DELIVERY: "advanced-delivery",
} as const;

type IntegrationRouteKey = keyof typeof INTEGRATIONS_ROUTES;

export type IntegrationStreamingRoute = (typeof INTEGRATIONS_ROUTES)[IntegrationRouteKey];
