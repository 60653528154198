import { SeatAdSourcesType, useGetAvailableNetworksQuery } from "@app/core/services";
import { AD_SOURCE_FIELDS } from "@app/features/seatAdSources/constants";
import { AdSourceFormsContext } from "@app/features/seatAdSources/SeatAdSourcesForm/SeatAdSourcesForm";
import { useMarketplaceInfoWidget } from "@app/features/seatAdSources/SeatAdSourcesForm/useMarketplaceInfoWidget";
import { Form } from "antd";
import { useContext } from "react";
import { useParams } from "react-router-dom";
import { DEAL_FORM_FIELDS } from "../../../../constants";

export const useDemandSelect = () => {
    const { marketplaceInfoMode, dealObjectMode } = useMarketplaceInfoWidget();
    const { forms } = useContext(AdSourceFormsContext);
    const isReadonly = marketplaceInfoMode.name === "view";
    const { seatId } = useParams<{ seatId: string }>();
    const adSourceType = Form.useWatch<SeatAdSourcesType>(AD_SOURCE_FIELDS.TYPE.name, forms.adSourceForm);
    const adSourceTypeId = adSourceType?.id;
    const { data: availableNetworks = [], isFetching: isLoading } = useGetAvailableNetworksQuery(
        {
            adSourceTypeId: adSourceTypeId,
            seatId: Number(seatId),
        },
        { skip: !adSourceTypeId || isReadonly }
    );
    const isDisabled = dealObjectMode === "edit";
    const selectedDspNetworkId = Form.useWatch(DEAL_FORM_FIELDS.DEMAND.name)?.id;

    const onChange = () => {
        forms.demandConfigurationForm.setFieldsValue({
            buyerSeats: undefined,
        });
    };

    return {
        availableNetworks,
        dealObjectMode,
        isLoading,
        isDisabled,
        isReadonly,
        selectedDspNetworkId,
        onChange,
    };
};
