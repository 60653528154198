import { FC } from "react";
import { Form } from "antd";
import { Seat } from "@app/core/services";
import { NONE } from "@app/core/components/constants";
import {
    MAX_FIXED_CPM_VALUE,
    MAX_REVSHARE_PERCENTAGE_COST_VALUE,
} from "@app/features/inventory/HierarchyForms/constants";
import { LimitInput } from "@app/features/inventory/components/Fields";
import { REVSHARE_PERCENTAGE_COST_MODEL } from "@app/features/inventory/InventorySeat/constants";
import { useCostValue } from "@app/features/inventory/InventorySeat/InventorySeatEditPage/SeatDetailsForm/SeatDetailsFormFields/FinancialInternalOnly/Fields/CostValue/useCostValue";

interface CostValueProps {
    seat: Seat;
    edit?: boolean;
}

const getDisplayValue = (seat: Seat): number | string => {
    if (
        seat?.affiliateCostModel?.id === REVSHARE_PERCENTAGE_COST_MODEL.value &&
        typeof seat?.affiliateCostValuePercent === "number"
    )
        return `${seat?.affiliateCostValuePercent} %`;

    if (typeof seat?.affiliateCostValueFixed === "number") return `${seat?.affiliateCostValueFixed / 1000} CPM`;

    return NONE;
};

export const CostValue: FC<CostValueProps> = ({ seat, edit = false, ...rest }) => {
    const { prefix, suffix, toFixed, name, label, isRevsharePercentageCostModel, message } = useCostValue();

    const displayValue: number | string = getDisplayValue(seat);

    return (
        <Form.Item
            name={name}
            label={label}
            rules={[
                { required: edit, message: message },
                {
                    validator(_, value) {
                        if (!value) return Promise.resolve();
                        if (isRevsharePercentageCostModel && value > MAX_REVSHARE_PERCENTAGE_COST_VALUE)
                            return Promise.reject(
                                new Error(
                                    `Revshare Percentage Affiliate Cost Value can not be more than ${MAX_REVSHARE_PERCENTAGE_COST_VALUE} %`
                                )
                            );
                        if (!isRevsharePercentageCostModel && BigInt(value) > MAX_FIXED_CPM_VALUE)
                            return Promise.reject(
                                new Error(`Fixed CPM Affiliate Cost Value can not be more than ${MAX_FIXED_CPM_VALUE}`)
                            );
                        return Promise.resolve();
                    },
                },
            ]}
            wrapperCol={{ xs: 24, sm: 24, md: 24, lg: 20, xl: 24, xxl: 20 }}
            {...rest}
        >
            {edit ? (
                <LimitInput suffix={suffix} prefix={prefix} toFixed={toFixed} style={{ width: "70%" }} />
            ) : (
                displayValue
            )}
        </Form.Item>
    );
};
