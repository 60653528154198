import { FC } from "react";
import { Radio } from "antd";
import { SPECIFIED_TRANSPARENCY_OPTIONS } from "@app/features/deals/constants";
import { useAdSourceIncludedExtendedIdsField } from "./useAdSourceIncludedExtendedIdsField";
import { TransparencyFormItem } from "@app/features/deals/DealForm/DealFormCreateAdSource/DealFormCreateAdSourceSections/AdSourceTransparencySection/Fields";
import { AD_SOURCE_FIELDS } from "@app/features/seatAdSources/constants";

export const AdSourceIncludedExtendedIdsField: FC = () => {
    const { onChange, value } = useAdSourceIncludedExtendedIdsField();
    return (
        <TransparencyFormItem label={AD_SOURCE_FIELDS.INCLUDED_EXTENDED_IDS.label} data-sdet="included-extended-ids">
            <Radio.Group
                onChange={(e) => onChange(e?.target?.value)}
                value={value}
                options={SPECIFIED_TRANSPARENCY_OPTIONS}
            />
        </TransparencyFormItem>
    );
};
