import { useGetAvailableNetworksQuery } from "@app/core/services";
import { css } from "@emotion/css";
import { Select } from "antd";
import { LabeledValue } from "antd/es/select";
import { FC, useMemo } from "react";
import { useParams } from "react-router-dom";
import { SourceOptions } from "../SelectSource";

export const ALL_ID = -1;

const classNameSelect = css`
    &.ant-select.ant-select-in-form-item {
        width: 189px;
    }
`;

interface Props {
    value: LabeledValue | null;
    handleChange: (value: LabeledValue | null, allNetworks?: { id: number; name: string }[]) => void;
    sourceValue: SourceOptions | null;
}
export const SelectNetwork: FC<Props> = ({ value, handleChange, sourceValue }) => {
    const { seatId } = useParams<{ seatId: string }>();

    const params = useMemo(() => {
        const prop = {
            seatId: Number(seatId),
        };
        if (sourceValue?.code) {
            Object.assign(prop, {
                extendedId: sourceValue.code,
            });
        }
        return prop;
    }, [sourceValue, seatId]);

    const { isFetching: isLoadingNetwork, data: networksData } = useGetAvailableNetworksQuery(params, {
        skip: !Boolean(sourceValue?.code),
    });
    const networkOptions = useMemo(() => {
        const options = (networksData || []).map(({ id, name }) => ({ label: name, value: id }));

        if (options.length && options.length > 1) {
            options.unshift({ label: "SELECT ALL", value: ALL_ID });
        }

        return options;
    }, [networksData]);

    const onChange = (value: LabeledValue | null) => {
        if (value?.value === ALL_ID) {
            handleChange(
                value,
                networksData?.map(({ id, name }) => ({ id, name }))
            );
        } else {
            handleChange(value);
        }
    };

    return (
        <Select
            allowClear
            placeholder="Select Network"
            options={networkOptions}
            loading={isLoadingNetwork}
            labelInValue
            disabled={!Boolean(sourceValue) || isLoadingNetwork}
            onChange={onChange}
            className={classNameSelect}
            data-sdet="select-network"
            value={value}
        />
    );
};
