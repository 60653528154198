import { AdSourceStat } from "../../data";
import moment from "moment-timezone";
import { useMemo } from "react";
import { Metrics } from "@app/core/components/charts/DualAxesChart";
import { MixChartProps } from "@app/core/components/charts/DualAxesChart/MixChartWithDualAxesCountAreaCurrencyLineChart";
import { Y_AXIS_TITLE_TEXT_EVENTS_PER_SECOND, Y_AXIS_TITLE_TEXT_REVENUE_PER_SECOND } from "../charts";

export const useSeatAdSources24HourPerformanceChart = (
    adSourceHistoryStats: AdSourceStat[]
): Omit<MixChartProps, "chartId"> => {
    const { metricOne, metricTwo } = useMemo(() => {
        const metrics: Metrics = { metricOne: [], metricTwo: [] };
        const latestNTime = adSourceHistoryStats.slice(-1)[0].nTime;

        const firstMomentWithin24Hours = moment(latestNTime).subtract(23, "hours").subtract(59, "minutes");
        adSourceHistoryStats
            .filter((stat) => moment(stat.nTime).isAfter(firstMomentWithin24Hours))
            .forEach((stat) => {
                const time = moment(stat.nTime).format("HH:mm");
                metrics.metricOne.push({
                    time,
                    value1: stat.fills,
                    name1: "Fills",
                });
                metrics.metricOne.push({
                    time,
                    value1: stat.tries,
                    name1: "Tries",
                });
                metrics.metricOne.push({
                    time,
                    value1: stat.impressions,
                    name1: "Impressions",
                });
                metrics.metricOne.push({
                    time,
                    value1: stat.fallbacks,
                    name1: "Fall Backs",
                });
                metrics.metricOne.push({
                    time,
                    value1: stat.errors,
                    name1: "Errors",
                });
                metrics.metricOne.push({
                    time,
                    value1: stat.skips,
                    name1: "Skips",
                });
                metrics.metricTwo.push({
                    time,
                    value2: stat.netRevenue,
                    name2: "Net Revenue",
                });
            });
        return metrics;
    }, [adSourceHistoryStats]);

    return {
        metricOne,
        metricTwo,
        metricOneYAxisTitle: Y_AXIS_TITLE_TEXT_EVENTS_PER_SECOND,
        metricTwoYAxisTitle: Y_AXIS_TITLE_TEXT_REVENUE_PER_SECOND,
        disablePoints: true,
    };
};
