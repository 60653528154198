import { Store } from "rc-field-form/lib/interface";
import {
    AUDIENCE_LOCK_OFF_ENTIRE_SEAT_TYPE,
    INVENTORY_FORM_FIELDS,
    INVENTORY_SEAT_DEFAULT_CURRENCY,
    INVENTORY_SEAT_DEFAULT_UDE_CPM_FEE,
    PREBID_AD_SOURCE_EXTERNAL_AD_UNIT_CODE_NAMESPACE,
} from "@app/features/inventory/InventorySeat/constants";
import { Seat, SeatFlags, UpdateSeatPayload } from "@app/core/services";
import { getAuthorizedExportStreamLabel, getDmpTypeLabel } from "@app/features/inventory/helpers";
import { AdvancedFeaturesForm } from "@app/features/inventory/InventorySeat/InventorySeatEditPage/AdvancedFeaturesForm/useAdvancedFeaturesForm";

export const getAdvancedFeaturesFormInitialValues = (seat: Seat | null | undefined): Store | undefined => {
    if (!seat) return undefined;

    return {
        // Inventory Management
        [INVENTORY_FORM_FIELDS.SSP_CONNECT_ENABLED.name]: Boolean(seat.externalAdUnitCodeNamespaces.length),
        [INVENTORY_FORM_FIELDS.EXTERNAL_AD_UNIT_CODE_NAMESPACES.name]: seat.externalAdUnitCodeNamespaces.map(
            (namespace) => ({ label: namespace.name, value: namespace.id })
        ),
        [INVENTORY_FORM_FIELDS.AUTHORIZED_PBS_SERVERS.name]: seat.authorizedPbsServers.map((server) => ({
            label: server.name,
            value: server.id,
        })),
        [INVENTORY_FORM_FIELDS.CHANNELS_ENABLED.name]: seat.channelsEnabled,
        [INVENTORY_FORM_FIELDS.INVENTORY_DISTRIBUTION_GROUPS.name]: seat.distributionGroupsEnabled,
        // "CTV Tools
        [INVENTORY_FORM_FIELDS.CONTENT_METADATA_ENABLED.name]: seat.contentMetadataEnabled,
        [INVENTORY_FORM_FIELDS.SEAT_CONTENT_METADATA_KEYS.name]: seat.seatContentMetadataKeys.map(
            (contentMetadataKey) => ({ label: contentMetadataKey.contentKey, value: contentMetadataKey.id })
        ),
        [INVENTORY_FORM_FIELDS.AD_POD_ENABLED.name]: seat.adPodEnabled,
        [INVENTORY_FORM_FIELDS.LINEAR_ENABLED.name]: seat.linearEnabled,
        [INVENTORY_FORM_FIELDS.LSA_ENABLED.name]: seat.lsaEnabled,
        // Identity and Regulations
        [INVENTORY_FORM_FIELDS.DMP_CREATION_ENABLED.name]: seat.dmpCreationEnabled,
        [INVENTORY_FORM_FIELDS.DMPS.name]: seat.dmps.map((dmp) => ({
            label: getDmpTypeLabel(dmp),
            value: dmp.id,
        })),
        [INVENTORY_FORM_FIELDS.IDL_ENABLED.name]: seat.idlEnabled,
        [INVENTORY_FORM_FIELDS.OZTAM_ENABLED.name]: seat.oztamEnabled,
        [INVENTORY_FORM_FIELDS.MASK_DEVICE_ID.name]: seat.maskDeviceId,
        [INVENTORY_FORM_FIELDS.GDPR_GOOGLE_CONSENT_ALL.name]: seat.gdprGoogleConsentAll,
        [INVENTORY_FORM_FIELDS.ENABLE_AUDIENCE_TARGETING.name]: seat.enableAudienceTargeting,
        [INVENTORY_FORM_FIELDS.ENABLE_MAGNITE_MATCH.name]: seat.enableMagniteMatch,
        [INVENTORY_FORM_FIELDS.ENABLE_LIVERAMP_ATS.name]: seat.liverampAtsEnabled,
        // Creative Control
        [INVENTORY_FORM_FIELDS.ENABLE_ADVERTISER_WHITELIST.name]: seat.enableAdvertisersWhitelist,
        [INVENTORY_FORM_FIELDS.CREATIVE_BLOCK_MODE.name]: seat.creativeBlockingMode.id,
        [INVENTORY_FORM_FIELDS.CALCULON_USE_RATE_OVERRIDE.name]: seat.calculonUseRateOverride,
        [INVENTORY_FORM_FIELDS.UPPER_CALCULON_USE_RATE_OVERRIDE.name]: seat.upperCalculonUseRateOverride,
        [INVENTORY_FORM_FIELDS.IGNORE_BCAT_BADV_ENABLED.name]: Boolean(seat.seatFlags?.ignoreBcatBadvEnabled),
        // Ad Responses
        [INVENTORY_FORM_FIELDS.EMIT_PRICEING_IN_VAST.name]: seat.emitPricingInVast,
        [INVENTORY_FORM_FIELDS.ALLOW_VAST_EXTENSION_DSP.name]: seat.allowVastExtensionDSP,
        [INVENTORY_FORM_FIELDS.ALLOW_VAST_EXTENSION_CRID.name]: seat.allowVastExtensionCRID,
        [INVENTORY_FORM_FIELDS.ALLOW_VAST_EXTENSION_ADOMAIN.name]: seat.allowVastExtensionAdomain,
        [INVENTORY_FORM_FIELDS.ALLOW_VAST_EXTENSION_BRAND.name]: seat.allowVastExtensionBrand,
        [INVENTORY_FORM_FIELDS.ALLOW_VAST_EXTENSION_BID_PRICE.name]: seat.allowVastExtensionBidPrice,
        [INVENTORY_FORM_FIELDS.ALLOW_VAST_EXTENSION_AD_SOURCE_TYPE.name]: seat.allowVastExtensionAdSourceType,
        [INVENTORY_FORM_FIELDS.ALLOW_VAST_EXTENSION_IAB_CATEGORY.name]: seat.allowVastExtensionIabCategory,
        [INVENTORY_FORM_FIELDS.ALLOW_AD_SOURCE_NAME_VAST_EXTENSION.name]: seat.allowVastExtensionAdSourceName,
        [INVENTORY_FORM_FIELDS.ALLOWED_FMT_JSON_OPTIONS.name]: seat.allowedFmtJsonOptions.map((jsonOption) => ({
            label: jsonOption.name,
            value: jsonOption.id,
        })),
        [INVENTORY_FORM_FIELDS.ALLOW_AD_RESPONSE_PRICE_OVERRIDE.name]: Boolean(
            seat.seatFlags?.adResponsePriceOverrideTypeEnabled
        ),
        [INVENTORY_FORM_FIELDS.TELARIA_CHAIN_DISABLED.name]: Boolean(seat.seatFlags?.telariaChainDisabled),
        [INVENTORY_FORM_FIELDS.SEAT_FORMAT_OVERRIDE_ENABLED.name]: Boolean(seat.seatFlags?.seatFormatOverrideEnabled),
        // Demand Management
        [INVENTORY_FORM_FIELDS.AUTHORIZED_MARKETPLACES.name]: seat.authorizedMarketplaces.map((marketplace) => ({
            label: marketplace.name,
            value: marketplace.id,
        })),
        [INVENTORY_FORM_FIELDS.ALLOW_FORWARD_MARKET_DEAL_BLOCKS.name]: seat.allowForwardMarketDealBlocks,
        [INVENTORY_FORM_FIELDS.ALLOW_HIGH_CPM.name]: seat.doohEnabled,
        [INVENTORY_FORM_FIELDS.ALLOW_TAKE_RATE_TRANSPARENCY.name]: seat.allowTakeRateTransparency,
        [INVENTORY_FORM_FIELDS.EXTERNAL_DATA_STREAMS.name]: seat.authorizedExportStreams.map((stream) => ({
            label: getAuthorizedExportStreamLabel(stream),
            value: stream.id,
        })),
        [INVENTORY_FORM_FIELDS.ENABLE_DEMAND_ACQUISITION_COST.name]: seat.dacEnabled,
        [INVENTORY_FORM_FIELDS.ASAP_FEATHERING.name]: seat.asapFeatheringEnabled,
        //Unified Decisioning
        [INVENTORY_FORM_FIELDS.UD_ENABLED.name]: seat.udeEnabled,
        [INVENTORY_FORM_FIELDS.UD_FEE.name]: seat.udeCpmFee
            ? seat.udeCpmFee / 1000
            : INVENTORY_SEAT_DEFAULT_UDE_CPM_FEE,
        [INVENTORY_FORM_FIELDS.UDE_CPM_FEE_CURRENCY.name]: seat.udeCpmFeeCurrency
            ? {
                  value: seat.udeCpmFeeCurrency.id,
                  label: seat.udeCpmFeeCurrency.code,
              }
            : INVENTORY_SEAT_DEFAULT_CURRENCY,
        [INVENTORY_FORM_FIELDS.UD_LID_DISCOVERY_MODE.name]: seat.udeLidDiscoveryMode
            ? {
                  value: seat.udeLidDiscoveryMode.id,
                  label: seat.udeLidDiscoveryMode.name,
              }
            : null,
        [INVENTORY_FORM_FIELDS.ALLOW_BLIND_COMPETITION.name]: seat.allowBlindCompetition,
        [INVENTORY_FORM_FIELDS.MEDIATION_ENABLED.name]: seat.mediationEnabled,
        //Admin Tools
        [INVENTORY_FORM_FIELDS.ALERTS_ENABLED.name]: seat.alertsEnabled,
        [INVENTORY_FORM_FIELDS.TEST_CREATIVES_ENABLED.name]: seat.enableTestCreativeUpload,
        [INVENTORY_FORM_FIELDS.TEST_CREATIVE_ID.name]: seat.testCreativeId,
        [INVENTORY_FORM_FIELDS.CLICK_TRACKING.name]: seat.clickTrackingEnabled,
        [INVENTORY_FORM_FIELDS.ALLOW_VAST_UNWRAPPING.name]: seat.allowVastUnwrapping,
        [INVENTORY_FORM_FIELDS.ALLOW_OR_TARGETING.name]: seat.orTargetingAllowed,
        [INVENTORY_FORM_FIELDS.AUDIENCE_LOCK_TYPE.name]: seat.audienceLockType?.id ?? null,
        [INVENTORY_FORM_FIELDS.AUDIENCE_LOCK_ALLOWED_DOMAIN.name]: seat.audienceLockAllowedDomain?.join("\n"),
    };
};
export const parseAdvancedFeaturesFormValuesToApi = (
    seat: Seat,
    values: AdvancedFeaturesForm,
    isSysAdmin: boolean,
    isPubAcctMgr: boolean,
    isTremorUser: boolean
): UpdateSeatPayload => {
    const {
        // Inventory Management
        sspConnectEnabled,
        externalAdUnitCodeNamespaces,
        authorizedPbsServers,
        channelsEnabled,
        distributionGroupsEnabled,
        // "CTV Tools
        contentMetadataEnabled,
        seatContentMetadataKeys,
        adPodEnabled,
        linearEnabled,
        lsaEnabled,
        // Identity and Regulations
        dmpCreationEnabled,
        dmps,
        idlEnabled,
        oztamEnabled,
        maskDeviceId,
        gdprGoogleConsentAll,
        enableAudienceTargeting,
        enableMagniteMatch,
        liverampAtsEnabled,
        // Creative Control
        enableAdvertisersWhitelist,
        creativeBlockingMode,
        calculonUseRateOverride,
        upperCalculonUseRateOverride,
        ignoreBcatBadvEnabled,
        // Ad Responses
        emitPricingInVast,
        allowVastExtensionDSP,
        allowVastExtensionCRID,
        allowVastExtensionAdomain,
        allowVastExtensionBrand,
        allowVastExtensionBidPrice,
        allowVastExtensionAdSourceType,
        allowVastExtensionIabCategory,
        allowedFmtJsonOptions,
        allowVastExtensionAdSourceName,
        telariaChainDisabled,
        seatFormatOverrideEnabled,
        // Demand Management
        authorizedMarketplaces,
        allowForwardMarketDealBlocks,
        doohEnabled,
        allowTakeRateTransparency,
        authorizedExportStreams,
        dacEnabled,
        asapFeatheringEnabled,
        //Unified Decisioning
        udeEnabled,
        udeCpmFee,
        udeCpmFeeCurrency,
        udeLidDiscoveryMode,
        allowBlindCompetition,
        mediationEnabled,
        //Admin Tools
        alertsEnabled,
        enableTestCreativeUpload,
        testCreativeId,
        orTargetingAllowed,
        clickTrackingEnabled,
        allowVastUnwrapping,
        audienceLockType,
        audienceLockAllowedDomain,
        adResponsePriceOverrideTypeEnabled,
    } = values;

    const hasPrebidAdSource: boolean = (externalAdUnitCodeNamespaces || []).some(
        ({ value }) => value === PREBID_AD_SOURCE_EXTERNAL_AD_UNIT_CODE_NAMESPACE
    );
    const payload = {
        id: seat.id,
        seatFlags: seat.seatFlags || ({} as SeatFlags),
    };

    if (isSysAdmin || isPubAcctMgr) {
        Object.assign(payload, {
            ...seat,
            // Inventory Management
            externalAdUnitCodeNamespaces: sspConnectEnabled
                ? externalAdUnitCodeNamespaces.map(({ value, label }) => ({
                      id: value,
                      name: label,
                  }))
                : [],
            authorizedPbsServers: hasPrebidAdSource
                ? authorizedPbsServers.map(({ value, label }) => ({
                      id: value,
                      name: label,
                  }))
                : [],
            distributionGroupsEnabled,
            channelsEnabled,
            // "CTV Tools
            contentMetadataEnabled,
            seatContentMetadataKeys: (seatContentMetadataKeys || []).map(({ label }) => ({
                contentKey: label,
            })),
            adPodEnabled,
            linearEnabled,
            lsaEnabled,
            // Identity and Regulations
            dmpCreationEnabled,
            dmps: (dmps || []).map(({ value, label }) => ({ id: value, name: label })),
            idlEnabled,
            oztamEnabled,
            maskDeviceId,
            gdprGoogleConsentAll,
            liverampAtsEnabled,
            // Creative Control
            enableAdvertisersWhitelist,
            creativeBlockingMode: { id: creativeBlockingMode },
            calculonUseRateOverride,
            upperCalculonUseRateOverride,
            // Ad Responses
            allowedFmtJsonOptions: (allowedFmtJsonOptions || []).map(({ value, label }) => ({
                id: value,
                name: label,
            })),
            emitPricingInVast,
            // Demand Management
            authorizedMarketplaces: (authorizedMarketplaces || []).map(({ value, label }) => ({
                id: value,
                name: label,
            })),
            allowForwardMarketDealBlocks,
            allowTakeRateTransparency,
            doohEnabled,
            //Unified Decisioning
            udeEnabled,
            udeCpmFee: udeCpmFee ? udeCpmFee * 1000 : INVENTORY_SEAT_DEFAULT_UDE_CPM_FEE,
            udeCpmFeeCurrency: udeCpmFeeCurrency
                ? { id: udeCpmFeeCurrency.value, code: udeCpmFeeCurrency.label }
                : null,
            udeLidDiscoveryMode: udeLidDiscoveryMode
                ? {
                      id: udeLidDiscoveryMode.value,
                      name: udeLidDiscoveryMode.label,
                  }
                : null,
            allowBlindCompetition,
            mediationEnabled,
            //Admin Tools
            testCreativeId: testCreativeId ? testCreativeId : null,
            audienceLockAllowedDomain:
                audienceLockType !== AUDIENCE_LOCK_OFF_ENTIRE_SEAT_TYPE.value &&
                audienceLockAllowedDomain &&
                audienceLockAllowedDomain.trim()
                    ? audienceLockAllowedDomain.trim().split("\n")
                    : null,
            audienceLockType: { id: audienceLockType },
            alertsEnabled,
            enableTestCreativeUpload,
            orTargetingAllowed,
            allowVastUnwrapping,
        });
    }
    if (isSysAdmin) {
        Object.assign(payload, {
            // Ad Responses isSysAdmin Only
            allowVastExtensionDSP,
            allowVastExtensionCRID,
            allowVastExtensionAdomain,
            allowVastExtensionBrand,
            allowVastExtensionBidPrice,
            allowVastExtensionAdSourceType,
            allowVastExtensionIabCategory,
            allowVastExtensionAdSourceName,
            seatFlags: {
                ...payload.seatFlags,
                adResponsePriceOverrideTypeEnabled,
                telariaChainDisabled,
                ignoreBcatBadvEnabled,
                seatFormatOverrideEnabled,
            },
            // Demand Management isSysAdmin Only
            asapFeatheringEnabled,
            authorizedExportStreams: (authorizedExportStreams || []).map(({ value, label }) => ({
                id: value,
                name: label,
            })),
            //Admin Tools isSysAdmin Only
            clickTrackingEnabled,
        });
    }
    if (isTremorUser) {
        Object.assign(payload, {
            // Demand Management isTremorUser Only
            dacEnabled,
            // Identity and Regulations isTremorUser Only
            enableAudienceTargeting,
            enableMagniteMatch,
        });
    }
    return payload;
};
