import { FC } from "react";
import { Card, Col, Row } from "antd";
import { useUserAccess } from "@app/core/auth";
import { BlockedIndustriesSelect } from "@app/core/components";
import { TWO_COL_SIZES } from "@app/features/inventory/constants";
import {
    BlockBuyerSeaList,
    BlockedCategories,
    BrandSafetyInternalOnly,
    BlockedAdvertiserDomains,
    BlockAdvertiserDomainLists,
} from "@app/features/inventory/HierarchyForms/BrandForm/BrandFormSections/BrandSafetySection/Fields";

export const BrandSafetySection: FC = () => {
    const { isSysAdmin } = useUserAccess();
    return (
        <Card bordered={false}>
            <Row>
                <Col {...TWO_COL_SIZES}>
                    <BlockAdvertiserDomainLists />
                    <BlockedAdvertiserDomains />
                    <BlockBuyerSeaList />
                    <BlockedCategories />
                    <BlockedIndustriesSelect />
                </Col>

                <Col hidden={!isSysAdmin} {...TWO_COL_SIZES}>
                    <BrandSafetyInternalOnly />
                </Col>
            </Row>
        </Card>
    );
};
