import { useHistory, useLocation, useParams } from "react-router-dom";
import { SortOrder } from "antd/es/table/interface";
import { uri } from "@rubicon/utils";
import { PAGE, SIZE, UsePagination, usePagination, UseTableChanges, useTableChanges } from "@app/core/components/hooks";
import { ROUTE_FORMATTERS } from "@app/core/routing";
import {
    IdNameEntity,
    MappingException,
    useDeleteMappingExceptionByIdMutation,
    useGetSeatMappingExceptionsQuery,
} from "@app/core/services";
import { BrandSafetyDeleteModalProps } from "../../BrandSafetyDeleteModal";
import { useBrandSafetyDeleteModal } from "../../BrandSafetyDeleteModal/useBrandSafetyDeleteModal";

interface UseMappingExceptionsListPage {
    seatId: string;
    dataSource: MappingException[] | undefined;
    loading: boolean;
    sortBy: string;
    sortOrder: SortOrder;
    deleteModalProps: BrandSafetyDeleteModalProps;
    paginationConfig: UsePagination["paginationConfig"];
    onChangeTable: UseTableChanges["onChange"];
    onClickEdit: (mappingExceptionId: number) => void;
    onClickDelete: (mappingException: IdNameEntity) => void;
}

export const SORT_PARAM_MAP = {
    creationDate: "dateCreated",
    updateDate: "lastUpdated",
} as const;

export const useMappingExceptionsListPage = (): UseMappingExceptionsListPage => {
    const history = useHistory();
    const { search: queryString } = useLocation();

    const { seatId } = useParams<{ seatId: string }>();

    const [deleteTrigger, { isLoading: isDeleting }] = useDeleteMappingExceptionByIdMutation();
    const { handleOpen: handleOpenDeleteModal, ...restModalProps } = useBrandSafetyDeleteModal(deleteTrigger);

    const params = uri.getParamsFromSearch(queryString, [PAGE, SIZE, { key: "sort" }, { key: "search" }]);
    const unsignedOrderByParam = params.sort?.at(0) === "-" ? params.sort?.substring(1) : params.sort;
    const orderByParam = SORT_PARAM_MAP[unsignedOrderByParam] ?? unsignedOrderByParam;
    const getSeatMappingExceptionParams = {
        seatId,
        max: Number(params.size),
        page: Number(params.page),
        orderBy: orderByParam,
        isAscending: orderByParam ? params.sort?.at(0) === "-" : undefined,
        keyword: params.search,
    };

    const { data, isFetching } = useGetSeatMappingExceptionsQuery(getSeatMappingExceptionParams);

    const { handleChange, paginationConfig } = usePagination({
        totalCount: data?.totalResults ?? 0,
    });

    const { onChange: onChangeTable, sortOrder } = useTableChanges({
        handlePaginateChange: handleChange,
    });

    const deleteModalProps = {
        isDeleting,
        ...restModalProps,
    };

    const onClickEdit = (mappingExceptionId: number) =>
        history.push(
            ROUTE_FORMATTERS.SEAT_CONTROLS_BRAND_SAFETY_MAPPING_EXCEPTIONS_EDIT_PAGE(seatId, mappingExceptionId)
        );

    const onClickDelete = (mappingException: IdNameEntity) => handleOpenDeleteModal(mappingException);

    return {
        seatId,
        dataSource: data?.mappingExceptions,
        loading: isFetching,
        sortBy: unsignedOrderByParam,
        sortOrder,
        deleteModalProps,
        paginationConfig,
        onChangeTable,
        onClickEdit,
        onClickDelete,
    };
};
