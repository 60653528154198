import { MarketplaceInfo } from "@app/core/services";
import { ReadonlyControl, SelectWithValue } from "@app/features/seatAdSources/SeatAdSourcesForm/components";
import { Col, Form } from "antd";
import { FC } from "react";
import { getTimeZoneLabel } from "@app/core/services";
import { useTimeZoneSelect } from "./useTimeZoneSelect";

export const TimeZoneSelect: FC = () => {
    const { timeZones, isLoading, isVisible, isReadonly, onChange, disabled } = useTimeZoneSelect();

    if (!isVisible) {
        return null;
    }
    return (
        <Col sm={12}>
            <Form.Item label="Time Zone" name={["dealUnderEdit", "timeZone"]}>
                {isReadonly ? (
                    <ReadonlyControl<MarketplaceInfo["deal"]["timeZone"]> getLabel={getTimeZoneLabel} />
                ) : (
                    <SelectWithValue
                        disabled={disabled}
                        data-sdet="deal-timezone-field"
                        loading={isLoading}
                        showSearch
                        placeholder="Select Time Zone"
                        options={timeZones}
                        optionFilterProp="label"
                        fieldAsLabel={getTimeZoneLabel}
                        fieldAsValue={["id"]}
                        onChange={onChange}
                    />
                )}
            </Form.Item>
        </Col>
    );
};
