import { useCallback, useState } from "react";
import { Breadcrumb, Button, Drawer, Space, Typography } from "antd";
import { css } from "@emotion/css";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { gray8 } from "@rubicon/antd-components";

import { useGetAdSourceStatsSnapshotsQuery } from "@app/core/services/adStats/statsSnapshots";
import { useSeatAuthContext } from "@app/core/auth";
import { Snapshots } from "../Snapshots";

const drawerClasses = css`
    button.ant-drawer-close {
        order: 1;
    }
    .ant-drawer-body {
        padding: 0;
    }
`;

export const SnapshotsDrawer = ({ adSourceId, adSourceName, adSourceHistoryStats, isOpen, onClose }) => {
    const { session } = useSeatAuthContext();
    const [onlyMySnapshots, setOnlyMySnapshots] = useState(false);
    const [adStatHistory] = useState(adSourceHistoryStats);
    const { data = [] } = useGetAdSourceStatsSnapshotsQuery(
        { adSourceId, email: onlyMySnapshots ? session?.user.emailAddress : undefined },
        { skip: !session }
    );

    const breadcrumbItems = [{ title: adSourceName }, { title: <Typography.Text strong>Snapshots</Typography.Text> }];

    const onlyMySnapshotsChangeHandler = useCallback((v: boolean) => {
        setOnlyMySnapshots(v);
    }, []);

    return (
        <Drawer
            width="80%"
            open={isOpen}
            className={drawerClasses}
            destroyOnClose={true}
            onClose={onClose}
            title={
                <Space>
                    <Button
                        type="link"
                        size="small"
                        icon={<ArrowLeftOutlined style={{ justifyContent: "left" }} />}
                        onClick={onClose}
                        style={{ color: gray8 }}
                    />
                    <Breadcrumb items={breadcrumbItems} />
                </Space>
            }
        >
            <Snapshots
                data={data}
                adSourceId={adSourceId}
                adSourceName={adSourceName}
                adStatHistory={adStatHistory}
                onlyMySnapshots={onlyMySnapshots}
                onlyMySnapshotsChangeHandler={onlyMySnapshotsChangeHandler}
            />
        </Drawer>
    );
};
