import { brandCobalt, brandSlate } from "@rubicon/antd-components";
import { SearchOutlined, EditOutlined, HistoryOutlined } from "@ant-design/icons";
import { Button, Col, Input, Row, Table, Tooltip, Typography } from "antd";
import { TargetingDataSource, useTargetingTable } from "./useTargetingTable";
import { ROUTES, ROUTE_FORMATTERS } from "@app/core/routing";
import { TargetingDetailsDrawer } from "./TargetingDetailsDrawer";
import { useTargetingChangeHistory } from "../../TargetingDrawer/useTargetingChangeHistory";
import { useState } from "react";
import { TargetingDrawerChangeHistory } from "../../TargetingDrawer";
import { Link, Route, useHistory } from "react-router-dom";
import { useUserAccess } from "@app/core/auth";
import { ColumnsType } from "antd/es/table";
import { PAGE_SIZE_OPTIONS } from "@app/core/components/constants";

const TABLE_COLUMNS_NAMES = {
    NAME: { key: "name", dataIndex: "name", title: "Name" },
    PARENT: { key: "parent", dataIndex: "parent", title: "Parent" },
    ACTIONS: { key: "actions", dataIndex: "", fixed: "right", title: "Actions" },
};

export const TargetingTable = () => {
    const [selectedTargetindId, setSelectedTargetingId] = useState<number>();
    const history = useHistory();
    const { canEditSeat } = useUserAccess();
    const { targeting, isLoading, handleChange, handleSearch, searchKeyword, seatId, sort } = useTargetingTable();
    const { showChangeHistory, handleShowChangeHistory, handleCloseChangeHistory } = useTargetingChangeHistory();

    const handleCHangeHistory = (id: number) => {
        setSelectedTargetingId(id);
        handleShowChangeHistory();
    };

    const { sortBy, sortOrder } = sort;

    const handleEdit = (id: string | number) => {
        history.push(ROUTE_FORMATTERS.SEAT_CONTROLS_TARGETING_EDIT(seatId, id));
    };

    return (
        <>
            <Row gutter={[16, 16]}>
                <Col span={6}>
                    <Input
                        allowClear
                        data-sdet="targeting-table-search-input"
                        onChange={handleSearch}
                        placeholder="Search"
                        suffix={!searchKeyword && <SearchOutlined style={{ color: brandSlate }} />}
                        value={searchKeyword}
                    />
                </Col>
                <Col span={24}>
                    <Table<TargetingDataSource>
                        loading={isLoading}
                        size="small"
                        dataSource={targeting}
                        onChange={handleChange}
                        columns={
                            [
                                {
                                    key: TABLE_COLUMNS_NAMES.NAME.key,
                                    dataIndex: TABLE_COLUMNS_NAMES.NAME.key,
                                    title: TABLE_COLUMNS_NAMES.NAME.title,
                                    render: (name: string, { id }) => (
                                        <Link
                                            replace
                                            component={Typography.Link}
                                            style={{ color: brandCobalt }}
                                            to={{
                                                pathname: ROUTE_FORMATTERS.SEAT_CONTROLS_TARGETING_DETAILS_DRAWER(
                                                    seatId,
                                                    id
                                                ),
                                            }}
                                        >
                                            {name}
                                        </Link>
                                    ),
                                    sorter: (a: TargetingDataSource, b: TargetingDataSource) =>
                                        a.name.localeCompare(b.name),
                                    sortOrder: sortBy === TABLE_COLUMNS_NAMES.NAME.key ? sortOrder : null,
                                },
                                {
                                    key: TABLE_COLUMNS_NAMES.PARENT.key,
                                    dataIndex: TABLE_COLUMNS_NAMES.PARENT.key,
                                    title: TABLE_COLUMNS_NAMES.PARENT.title,
                                    sorter: (a: TargetingDataSource, b: TargetingDataSource) =>
                                        a.parent.localeCompare(b.parent),
                                    sortOrder: sortBy === TABLE_COLUMNS_NAMES.PARENT.key ? sortOrder : null,
                                },
                                {
                                    key: "actions",
                                    dataIndex: "",
                                    fixed: "right",
                                    title: "Actions",
                                    width: 100,
                                    render: (_: unknown, { id }) => (
                                        <Button.Group>
                                            {canEditSeat && (
                                                <Tooltip title="Edit">
                                                    <Button
                                                        data-sdet={`targeting-table-edit-button-${id}`}
                                                        onClick={() => handleEdit(id)}
                                                        icon={<EditOutlined />}
                                                    />
                                                </Tooltip>
                                            )}
                                            <Tooltip title="Change History">
                                                <Button
                                                    data-sdet={`targeting-table-change-history-button-${id}`}
                                                    onClick={() => handleCHangeHistory(id)}
                                                    icon={<HistoryOutlined />}
                                                />
                                            </Tooltip>
                                        </Button.Group>
                                    ),
                                    sorter: false,
                                },
                            ].filter(Boolean) as ColumnsType<TargetingDataSource>
                        }
                        showSorterTooltip={false}
                        rowKey="id"
                        pagination={{
                            pageSizeOptions: PAGE_SIZE_OPTIONS,
                        }}
                    />
                </Col>
            </Row>
            <TargetingDrawerChangeHistory
                seatId={Number(seatId)}
                open={showChangeHistory}
                handleClose={handleCloseChangeHistory}
                targetingId={selectedTargetindId as number}
            />
            <Route path={ROUTES.SEAT_CONTROLS_TARGETING_DETAILS_DRAWER}>
                <TargetingDetailsDrawer />
            </Route>
        </>
    );
};
