import moment from "moment-timezone";

// Label Constants
export const NONE = "- -";
export const NA = "N/A";
export const NONE_TEXT = "None";
export const ON = "ON";
export const OFF = "OFF";
export const YES = "Yes";
export const NO = "No";
export const NO_END_DATE = "No End Date";
export const NOT_ALLOWED = "Not Allowed";

// Input Constants
export const FILTER_INPUT_DEBOUNCE_TIME = 400;

// Date Constants
// TODO: These should be moved to a date constants file
export const YEAR_MONTH_DAY = "YYYY-MM-DD";
export const MONTH_DAY_YEAR = "MMM DD, YYYY";
export const MONTH_DAY_YEAR_DASHED = "MM-DD-YYYY";
export const MONTH_DAY_YEAR_HOUR_MINUTE_AMPM = "MMM DD, YYYY hh:mm A";
export const YEAR_MONTH_DAY_HOUR_MINUTE_AMPM_TIMEZONE = "YYYY-MM-DD, hh:mm A z";
export const YEAR_MONTH_DAY_HOUR_MINUTE_AMPM = "YYYY-MM-DD, hh:mm A";
export const YEAR_MONTH_DAY_HOUR_MINUTE_AMPM_NO_COMMA = "YYYY-MM-DD hh:mm A";
export const WEEK_YEAR_MONTH_DAY_HOUR_MINUTE_AMPM_TIMEZONE = "ddd YYYY-MM-DD, hh:mm A z";
export const YEAR_MONTH_DAY_HOUR_MINUTE_SECONDS_TIMEZONE = "YYYY-MM-DD, HH:mm:ss z";
export const YEAR_MONTH_DAY_HOUR_MINUTE_TIMEZONE = "YYYY-MM-DD, HH:mm z";
export const UTC_OFFSET_FREE_FORMAT = "YYYY-MM-DDTHH:mm:ss";

export const ISO_8601_FORMAT = "YYYY-MM-DDTHH:mm:ssZ";
export const MONTH_DAY_YEAR_HOUR_MINUTE_AMPM_DASHED = "MM-DD-YYYY hh:mm A";
export const MONTH_DAY_YEAR_HOUR_MINUTE_ZONE_SLASHED = "MM/DD/YY HH:mm z";
export const DAY_MONTH = "DD MMM";
export const DAY_NAME_MONTH_DAY_YEAR = "dddd, MMM DD, YYYY";
export const END_OF_DAY = moment().set("h", 23).set("m", 59).set("s", 59);
export const HOUR_MINUTE_AMPM = "hh:mm A";

// TimeZone Constants
export const DEFAULT_TIME_ZONE = {
    creationDate: "2015-04-08T16:50:03.000+0000",
    updateDate: "2015-04-08T16:50:03.000+0000",
    id: 614,
    code: "UTC",
    name: "Coordinated Universal Time",
    dayLightTime: false,
    dayLightSavingsOffset: 0,
    utcOffset: 0,
    entityType: "TimeZone",
};

// SideNav Constants
// TODO: Colocate this with the SideNav component
export const SIDE_NAV_WIDTH = "5.625rem";

export const DEFAULT_PAGE_SIZE = 25;
export const PAGE_SIZE_OPTIONS = [10, 25, 50, 100];
export const DEFAULT_TOTAL_RESULTS = 0;

export const SEARCH_SEATS_PREFIX = "seat:";
export const SEARCH_BUYERS_PREFIX = "buyer:";

export const SEARCH_PREFIX_OPTIONS = [
    {
        value: SEARCH_SEATS_PREFIX,
        label: "Seat",
    },
    {
        value: SEARCH_BUYERS_PREFIX,
        label: "Buyer",
    },
];

export enum ChartColors {
    Aqua = "#2F9999",
    Blue = "#6395F9",
    DarkGreen = "#4F9798",
    Grey = "#657899",
    LightBlue = "#74CBED",
    LightGreen = "#63DAAB",
    Orange = "#FF9D4F",
    Pink = "#FF9FC6",
    Violet = "#7666F9",
    Purple = "#926CB8",
    Red = "#FF4D4F",
    Viola = "#9A67BD",
    Yellow = "#F6C02D",
    TextHeader = "#262626",
    Default = "#5b8ff9",
}

export const REUSABLE_ADVERTISER_DOMAIN_ID = {
    1: 1,
    2: 1,
    3: 2,
};

export const MINUTES_IN = {
    MINUTE: 1,
    HOUR: 60,
    DAY: 1440,
    WEEK: 10080,
    MONTH: 43200,
};
