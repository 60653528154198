import { css } from "@emotion/css";
import { BLOCKED_INDUSTRIES_LABEL } from "@app/core/components";
import { InventoryDetailsDrawerType } from "../DetailsDrawer/reducer";
import {
    SeatHelpKeys,
    SEAT_BRAND_SAFETY_HELP_FIELDS,
    SEAT_CUSTOM_PIXELS_HELP_FIELDS,
    SEAT_LABELS_HELP_FIELDS,
    SEAT_PERFORMANCE_HELP_FIELDS,
    SUPPLY_SITE_DETAILS_HELP_FIELDS,
    BRAND_CHANNEL_PLAYLIST_CONFIGURATION_HELP_FIELDS,
} from "../InventorySeat/constants";

export const APPCTVID = 1;
export const POD_ID = 2;
export const PLAYLIST_ID = 3;
export const PLATFORM_ID = 1;

export const COL_SIZES = { xs: 24, sm: 24, md: 8, lg: 6, xl: 6, xxl: 4 };
export const classNameCardBody = css`
    box-shadow: none !important;

    > .ant-card-body {
        padding-top: 0;
        padding-bottom: 0;
    }
`;

export const FORM_ITEM_OFFSET = css`
    margin-bottom: 1rem;
`;

export enum SECTIONS_NAMES {
    //BASE
    PERFORMANCE = "performance",
    GENERAL = "general",
    AFFILIATE = "affiliate",
    BRAND_SAFETY = "brandSafety",
    PIXELS = "pixels",
    LABELS = "labels",
    FLOORS = "floors",
    SSP_CONNECT = "sspConnect",
    //PUBLISHER
    INTERNAL = "internal",
    ADVANCED_FEATURES = "advancedFeatures",
    //BRAND
    SUPPLY_DETAILS = "supplyDetails",
    SITE_DETAILS = "siteDetails",
    //SUPPLY
    EXCHANGE_SAFETY = "exchangeSafety",
    APP_DETAILS = "appDetails",
    WATERFALL_PREFILTERING = "waterfallPrefiltering",
    //AD UNIT
    DEMAND_REQUIREMENTS = "demandRequirements",
    PLAYLIST_CONFIGURATION = "playlistConfiguration",
    AD_POD = "adPod",
    AD_BREAK_RULES = "adBreakRules",
    //CHANNEL
    DETAILED_CONFIGURATION = "detailedConfiguration",
    DEFAULTS = "defaults",
}

export enum DetailsHelpKeys {
    Name = "name",
    Code = "code",
    PublisherBusinessDomain = "publisher.businessdomain",
    Description = "description",
    PublisherExternalComment = "publisher.externalcomment",
    PublisherEmitPricing = "emitpricinginvast",
    PublisherAllwVastExtensionAdSourceName = "publisher.allowVastExtensionAdSourceName",
    PublisherDSPVast = "seat.allowvastextensiondsp",
    PublisherCRID = "seat.allowvastextenstioncrid",
    PublisherADomainVast = "seat.allowvastextensionadomain",
    PublisherBrandVast = "seat.allowvastextensionbrand",
    PubilsherAdSourceType = "seat.allowvastextensionadsourcetype",
    PublisherIABCategory = "seat.allowvastextensioniabcat",
    PublisherAffiliateCostModel = "publisher.affiliatecostmodel",
    PublisherAffiliateCostValue = "publisher.affiliatecostvalue",
    BrandAffiliateCostModel = "brand.affiliatecostmodel",
    BrandAffiliateCostValue = "brand.affiliatecostvalue",
    SupplyAffiliateCostModel = "supply.affiliatecostmodel",
    SupplyAffiliateCostValue = "supply.affiliatecostvalue",
    AdUnitAffiliateCostModel = "adunit.affiliatecostmodel",
    AdUnitAffiliateCostValue = "adunit.affiliatecostvalue",
    PublisherAccountType = "publisher.accounttype",
    PublisherBillingCode = "publisher.billingcode",
    PublisherCreativeBlockMode = "publisher.creativeblockmode",
    UpdatedDate = "updatedate",
    LastIndexed = "lastindexed",
    CreationDate = "creationdate",
    Labels = "labels",
    BrandBlockedCategories = "brand.blockediabcategories",
    BrandCategories = "brand.iab",
    BrandAdvAsAllowList = "brand.enableadvertisersaswhitelist",
    FloorInheritance = "floor.inheritance",
    FloorCountry = "adstats-publisher.country",
    FloorPrice = "floor.price",
    SupplyMode = "supply.isdynamic",
    SupplyType = "supply.type",
    SupplyAppStore = "supply.appstore",
    SupplyAppStoreName = "supply.appstorename",
    SupplyBundleId = "supply.bundleid",
    SupplyStoreUrl = "supply.storeurl",
    SupplyPaidType = "supply.paidtype",
    SupplyDomain = "supply.domain",
    SupplyPartner = "supply.inventorypartnerdomain",
    SupplyVideoQuality = "supply.videoquality",
    SupplySourceRlationship = "supply.sourcerelationship",
    SupplyEnableNoAdCookieSync = "supply.enablenoadcookiesync",
    SupplyResellType = "supply.reselltype",
    SupplyDomainLists = "supply.supplydomainlists",
    SupplyBundleIdLists = "supply.appbundleidlists",
    IfaMode = "supply.ifamode",
    SupplyAllowVastWrappers = "supply.allowvastwrappers",
    SupplyCoppa = "supply.iscoppa",
    PrivacyPolicy = "supply.privacy",
    ViewabilityVendors = "supply.viewabilityvendors",
    Keywords = "supply.keywords",
    SupplyMaskIFA = "supply.maskifafordemand",
    WaterfallPrefiltering = "labels.prefilter",
    AdUnitStatus = "adsource.status",
    AdUnitFrequencyCapping = "adunit.enableadomainfreqcapping",
    AdUnitSupportedProtocols = "adunit.protocols",
    AdUnitMimes = "adunit.mimes",
    AdUnitSupportedApis = "adunit.apis",
    AdUnitPlacement = "adunit.placement",
    AdUnitMaxDuration = "adunit.maxduration",
    AdUnitMinDuration = "adunit.minduration",
    AdUnitMaxExtended = "adunit.maxextended",
    AdUnitBlockedAttr = "adunit.blockedattributes",
    AdUnitMinBitrate = "adunit.minbitrate",
    AdUnitMaxBitrate = "adunit.maxbitrate",
    AdUnitLinearity = "adunit.linearity",
    AdUnitType = "adunit.type",
    AdUnitMultiplicity = "adunit.multiplicity",
    AdUnitVidePlaback = "vaststats.versions",
    AdUnitDeliverMethod = "adunit.videodeliveries",
    AdUnitSkippable = "adunit.skip",
    AdUnitSkippableMin = "adunit.skippableminimum",
    AdUnitSkippableAfter = "adunit.skippableafter",
    AdUnitInterstitial = "adunit.interstitial",
    AdUnitBoxing = "adunit.boxing",
    AdUnitOverrideDynamicFloor = "adunit.overrideDynamicFloor",
    AdUnitSSAI = "adunit.ssaitype",
    AdUnitExtendedImp = "adunit.extendedimpressionwaittime",
    AdUnitCreativeSamples = "adunit.creativesamplesminute",
    AdUnitUnwrapVast = "adunit.unwrapvast",
    AdUnitContentChannel = "adunit.contentchannel",
    AdUnitContentNetwork = "adunit.contentnetwork",
    AdUnitUDOPT = "adunit.udoptout",
    AdUnitAcceleration = "adunit.lsa",
    AdUnitPublisherReauction = "adunit.publisherReauction",
    LiveStream = "supply.islivestream",
    AdUnitFillMode = "adunit.adpodfillmode",
    AdUnitPodEnforcement = "adunit.podenforcement",
    AdUnitCompetitiveSeparation = "adunit.competitiveseparation",
    AdUnitMaxAdsPerAdv = "adunit.maxadsperadvertiser",
    AdUnitPlaylistDefMode = "adunit.playlistdefinitionmode",
    AdUnitIsAdPod = "adunit.isadpod",
    AdUnitMaxPodSeconds = "adunit.maxpodseconds",
    AdUnitMaxAdsPerPod = "adunit.maxadsperpod",
}

export const BASE_INTERNAL_ONLY_LABELS = {
    publisherReauction: "Publisher Re-Auction",
    loverCalculonUseRate: "Lower Calculon Use Rate Override",
    upperCalculonUseRate: "Upper Calculon Use Rate Override",
};

//TODO use it for publisher. brand and supply labels
const BASE_GENERAL_DETAILS_LABELS = {
    name: "Name",
    description: "Description",
    lastUpdated: "Last Updated",
    affiliateCostModel: "Affiliate Cost Model",
    affiliateCostValue: "Affiliate Cost Value",
    lastIndexed: "Last Indexed",
    created: "Date Created",
    publisherName: "Publisher Name",
};

export const PUBLISHER_GENERAL_LABELS = {
    ...BASE_GENERAL_DETAILS_LABELS,
    code: "Code",
    accountType: "Account Type",
    businessDomain: "Business Domain",
    externalComment: "External Comment",
};

export const PUBLISHER_INTERNAL_LABELS = {
    billingCode: "Billing Code",
    ...BASE_INTERNAL_ONLY_LABELS,
};

export const BASE_BRAND_SAFETY_LABELS = {
    aDomainLists: "Blocked Advertiser Domain Lists",
    aDomains: "Blocked Advertiser Domains",
    seatList: "Blocked Buyer Seat List",
    adFreqCapping: "Advertiser Frequency Capping",
    adFreqCaps: "Advertiser Frequency Caps",
    blockedCategories: "Blocked Categories",
    blockedIndustries: BLOCKED_INDUSTRIES_LABEL,
    creativeBlockMode: "Creative Block Mode",
};
export const AFFILIATE_LABELS = {
    affiliateCostModel: "Affiliate Cost Model",
    affiliateCostValue: "Affiliate Cost Value",
};

export const BRAND_SUPPLY_DETAILS_LABELS = {
    categories: "Categories",
    advAsAllowList: "Advertisers as Allow List",
    publisherReauction: "Publisher Re-Auction",
    loverCalculonUseRate: "Lower Calculon Use Rate Override",
    upperCalculonUseRate: "Upper Calculon Use Rate Override",
};

export const SUPPLY_APP_DETAILS_LABELS = {
    appStore: "App Store",
    bundleId: "Bundle ID",
    appName: "App Name",
    storeUrl: "Store URL",
    paidType: "Paid Type",
    domain: "Domain",
    partnerDomain: "Inventory Partner Domain",
};

export const SUPPLY_INTERNAL_ONLY_LABELS = {
    resellType: "Resell Type",
    noAdCookieSync: "No-Ad Cookie Sync",
};

export const SUPPLY_EXCHANGE_SAFETY_LABELS = {
    supplyDomainLists: "Supply Domain Lists",
    appBundleIdLists: "App Bundle ID Lists",
};

export const SUPPLY_SUPPLY_DETAILS_LABELS = {
    video: "Video Quality",
    srcRelationship: "Source Relationship",
    ifa: "IFA Mode",
    wrapper: "Allow VAST Wrappers",
    coppa: "COPPA",
    privacyPolicy: "Privacy Policy",
    viewability: "Viewability Vendors",
    keywords: "Keywords",
    mask: "Mask IFA for Demands",
};

export const SUPPLY_WTAERFALL_DETAILS_LABELS = {
    prefiltering: "Seat Prefilter Labels",
};

export const AD_UNIT_DETAILS_LABELS = {
    ...BASE_GENERAL_DETAILS_LABELS,
    code: "Code",
    status: "Status",
};

export const AD_UNIT_BRAND_SAFETY_LABELS = {
    ...BASE_BRAND_SAFETY_LABELS,
};

export const AD_UNIT_DEMAND_REQUIREMENTS_LABELS = {
    supportedProtocols: "Supported Protocols",
    mimes: "MIMEs",
    supportedApis: "Supported APIs",
    placement: "Placement",
    maxDuration: "Max Duration",
    minDuration: "Min Duration",
    maxExtended: "Max Extended",
    blockedAttributes: "Blocked Attributes",
    minBitrate: "Min Bitrate",
    maxBitrate: "Max Bitrate",
};

export const AD_UNIT_SUPPLY_DETAILS_LABELS = {
    linearity: "Linearity",
    multiplicity: "Multiplicity",
    type: "Type",
    videoPlaybacks: "Video Playbacks",
    deliveryMethods: "Delivery Methods",
    skippable: "Skippable",
    skippableMin: "Skippable Minimum",
    skippableAfter: "Skippable After",
    interstitial: "Interstitial",
    boxing: "Boxing",
    allowDemandUnderInboundFloor: "Allow Demand Under Inbound Floor",
    ssaiType: "SSAI Type",
    extendedImpWaitTime: "Extended Imp Wait Time",
    creativeSamples: "Creative Samples / Minute",
    unwrapVast: "Unwrap VAST",
    liveStream: "Live Stream",
    contentChannel: "Content Channel",
    contentNetwork: "Content Network",
    udOptUout: "UD Opt-Out",
    liveStreamAcceleration: "Live Stream Acceleration",
};

export const AD_UNIT_PLAYLIST_CONFIGURATION_LABELS = {
    fillMode: "Fill Mode",
    podEnforcement: "Pod Enforcement",
    competSeparationG: "Competitive Separation Groups",
    maxAd: "Max Ads per Advertiser",
    playlistDef: "Playlist Definition Mode",
    isAdPod: "Is Ad Pod",
    maxPodSeconds: "Max Pod Seconds",
    maxAdsPerPod: "Max Ads Per Pod",
};

export const AD_UNIT_AD_BREAK_RULES_LABELS = {
    preRoll: "Pre-roll",
    midRoll: "Mid-roll",
    postRoll: "Post-roll",
};

export const CHANNEL_GENERAL_LABELS = {
    name: "Name",
    description: "Description",
    accountType: "Account Type",
    publisherCode: "Publisher Code",
    brandCode: "Brand Code",
    adUnitCode: "Ad Unit Code",
    status: "Status",
    billingCode: "Billing Code",
};

export const CHANNEL_DETAILED_CONFIGURATION_LABELS = {
    noAdCookieSync: "No-Ad Cookie Sync",
    advAsAllowList: "Advertisers as Allow List",
    publisherReAuction: "Publisher Re-Auction",
};

const GENERAL_HELP_FIELD = [
    {
        label: "Name",
        helpPath: DetailsHelpKeys.Name,
        key: "NAME",
    },
    {
        label: "Description",
        helpPath: DetailsHelpKeys.Description,
        key: "DESCRIPTION",
    },
    {
        label: "Code",
        helpPath: DetailsHelpKeys.Code,
        key: "CODE",
    },
    {
        label: "Date Created",
        helpPath: DetailsHelpKeys.CreationDate,
        key: "DATE_CREATED",
    },
    {
        label: "Last Updated",
        helpPath: DetailsHelpKeys.UpdatedDate,
        key: "LAST_UPDATED",
    },
    {
        label: "Last Indexed",
        helpPath: DetailsHelpKeys.LastIndexed,
        key: "LAST_INDEXED",
    },
];

const GENERAL_INTERNAL_ONLY_HELP_FIELDS = [
    {
        label: BASE_INTERNAL_ONLY_LABELS.publisherReauction,
        helpPath: DetailsHelpKeys.AdUnitPublisherReauction,
        key: BASE_INTERNAL_ONLY_LABELS.publisherReauction.toUpperCase(),
    },
    {
        label: BASE_INTERNAL_ONLY_LABELS.loverCalculonUseRate,
        helpPath: SeatHelpKeys.SeatCalculonUseRateOverride,
        key: BASE_INTERNAL_ONLY_LABELS.loverCalculonUseRate.toUpperCase(),
    },
    {
        label: BASE_INTERNAL_ONLY_LABELS.upperCalculonUseRate,
        helpPath: SeatHelpKeys.SeatUpperCalculonUserateOverride,
        key: BASE_INTERNAL_ONLY_LABELS.upperCalculonUseRate.toUpperCase(),
    },
];

const LABELS_HELP_FIELDS = (isInternalOnly?: boolean) =>
    [
        {
            label: "Seat Labels",
            helpPath: SeatHelpKeys.Labels,
            key: "SEAT_LABELS",
        },
        isInternalOnly && {
            label: "Global Labels",
            helpPath: SeatHelpKeys.GlobalLabels,
            key: "GLOBAL_LABELS",
        },
    ].filter(Boolean);

const PUBLISHER_GENERAL_HELP_FIELDS = [
    ...GENERAL_HELP_FIELD,
    {
        label: PUBLISHER_GENERAL_LABELS.businessDomain,
        helpPath: DetailsHelpKeys.PublisherBusinessDomain,
        key: PUBLISHER_GENERAL_LABELS.businessDomain.toUpperCase(),
    },
    {
        label: PUBLISHER_GENERAL_LABELS.externalComment,
        helpPath: DetailsHelpKeys.PublisherExternalComment,
        key: PUBLISHER_GENERAL_LABELS.externalComment.toUpperCase(),
    },
];

const PUBLISHER_AFFILIATE_HELP_FIELDS = [
    {
        label: BASE_GENERAL_DETAILS_LABELS.affiliateCostModel,
        helpPath: DetailsHelpKeys.PublisherAffiliateCostModel,
        key: BASE_GENERAL_DETAILS_LABELS.affiliateCostModel.toUpperCase(),
    },
    {
        label: BASE_GENERAL_DETAILS_LABELS.affiliateCostValue,
        helpPath: DetailsHelpKeys.PublisherAffiliateCostValue,
        key: BASE_GENERAL_DETAILS_LABELS.affiliateCostValue.toUpperCase(),
    },
];

const PUBLISHER_BRAND_SAFETY_HELP_FIELDS = (isInternalOnly = false) =>
    [
        ...SEAT_BRAND_SAFETY_HELP_FIELDS,
        isInternalOnly && {
            label: BASE_BRAND_SAFETY_LABELS.creativeBlockMode,
            helpPath: DetailsHelpKeys.PublisherCreativeBlockMode,
            key: BASE_BRAND_SAFETY_LABELS.creativeBlockMode.toUpperCase(),
        },
    ].filter(Boolean);

const PUBLISHER_INTERNAL_HELP_FIELDS = [
    {
        label: "Billing Code",
        helpPath: DetailsHelpKeys.PublisherBillingCode,
        key: "BILLING_CODE",
    },
    ...GENERAL_INTERNAL_ONLY_HELP_FIELDS,
];
const PUBLISHER_ADVANCED_FEATURES_HELP_FIELDS = [
    {
        label: "Emit Pricing in VAST",
        helpPath: DetailsHelpKeys.PublisherEmitPricing,
        key: "EMIT_PRICING_IN_VAST",
    },
    {
        label: "DSP VAST Extension",
        helpPath: DetailsHelpKeys.PublisherDSPVast,
        key: "DSP_VAST_EXTENSION",
    },
    {
        label: "CRID VAST Extension",
        helpPath: DetailsHelpKeys.PublisherCRID,
        key: "CRID_VAST_EXTENSION",
    },
    {
        label: "Adomain VAST Extension",
        helpPath: DetailsHelpKeys.PublisherADomainVast,
        key: "ADOMAIN_VAST_EXTENSION",
    },
    {
        label: "Brand VAST Extension",
        helpPath: DetailsHelpKeys.PublisherBrandVast,
        key: "BRAND_VAST_EXTENSION",
    },
    {
        label: "Bid Price VAST Extension",
        helpPath: DetailsHelpKeys.PublisherADomainVast,
        key: "BID_PRICE_VAST_EXTENSION",
    },
    {
        label: "Ad Source Type VAST Extension",
        helpPath: DetailsHelpKeys.PubilsherAdSourceType,
        key: "AD_SOURCE_TYPE_VAST_EXTENSION",
    },
    {
        label: "IAB Category VAST Extension",
        helpPath: DetailsHelpKeys.PublisherEmitPricing,
        key: "IAB_CATEGORY_VAST_EXTENSION",
    },
    {
        label: "Ad Source Name VAST Extension",
        helpPath: DetailsHelpKeys.PublisherAllwVastExtensionAdSourceName,
        key: "ADSOURCE_NAME_VAST_EXTENSION",
    },
];
const FLOORS_HELP_FIELDS = [
    {
        label: "Floors",
        helpPath: DetailsHelpKeys.FloorInheritance,
        key: "FLOORS",
    },
];

const BRAND_BRAND_SAFETY_HELP_FILEDS = (isInternalOnly?: boolean) =>
    [
        ...SEAT_BRAND_SAFETY_HELP_FIELDS,
        {
            label: "Blocked Categories",
            helpPath: DetailsHelpKeys.BrandBlockedCategories,
            key: "BLOCKED_CATEGORIES",
        },

        isInternalOnly && {
            label: BASE_BRAND_SAFETY_LABELS.creativeBlockMode,
            helpPath: DetailsHelpKeys.PublisherCreativeBlockMode,
            key: BASE_BRAND_SAFETY_LABELS.creativeBlockMode.toUpperCase(),
        },
    ].filter(Boolean);

const BRAND_SUPPLY_DETAILS = [
    {
        label: BRAND_SUPPLY_DETAILS_LABELS.categories,
        helpPath: DetailsHelpKeys.BrandCategories,
        key: BRAND_SUPPLY_DETAILS_LABELS.categories.toUpperCase(),
    },
];

const SUPPLY_GENERAL_HELP_FIELDS = [
    ...GENERAL_HELP_FIELD,
    {
        label: "Type",
        helpPath: DetailsHelpKeys.SupplyType,
        key: "TYPE",
    },
    {
        label: "Mode",
        helpPath: DetailsHelpKeys.SupplyMode,
        key: "MODE",
    },
];

const SUPPLY_APP_DETAILS_HELP_FIELDS = [
    {
        label: SUPPLY_APP_DETAILS_LABELS.appStore,
        helpPath: DetailsHelpKeys.SupplyAppStore,
        key: "APP_STORE",
    },
    {
        label: SUPPLY_APP_DETAILS_LABELS.bundleId,
        helpPath: DetailsHelpKeys.SupplyBundleId,
        key: "BUNDLE_ID",
    },
    {
        label: SUPPLY_APP_DETAILS_LABELS.appName,
        helpPath: DetailsHelpKeys.SupplyAppStoreName,
        key: "APP_NAME",
    },
    {
        label: SUPPLY_APP_DETAILS_LABELS.storeUrl,
        helpPath: DetailsHelpKeys.SupplyStoreUrl,
        key: "STORE_URL",
    },
    {
        label: SUPPLY_APP_DETAILS_LABELS.paidType,
        helpPath: DetailsHelpKeys.SupplyPaidType,
        key: "PAID_TYPE",
    },
    {
        label: SUPPLY_APP_DETAILS_LABELS.domain,
        helpPath: DetailsHelpKeys.SupplyDomain,
        key: "DOMAIN",
    },
    {
        label: SUPPLY_APP_DETAILS_LABELS.partnerDomain,
        helpPath: DetailsHelpKeys.SupplyPartner,
        key: "PARTNER_DOMAIN",
    },
];

const SUPPLY_INTERNAL_ONLY_HELP_FIELDS = [
    {
        label: SUPPLY_INTERNAL_ONLY_LABELS.resellType,
        helpPath: DetailsHelpKeys.SupplyResellType,
        key: SUPPLY_INTERNAL_ONLY_LABELS.resellType.toUpperCase(),
    },
    {
        label: SUPPLY_INTERNAL_ONLY_LABELS.noAdCookieSync,
        helpPath: DetailsHelpKeys.SupplyEnableNoAdCookieSync,
        key: SUPPLY_INTERNAL_ONLY_LABELS.noAdCookieSync.toUpperCase(),
    },
    {
        label: BASE_INTERNAL_ONLY_LABELS.loverCalculonUseRate,
        helpPath: SeatHelpKeys.SeatCalculonUseRateOverride,
        key: BASE_INTERNAL_ONLY_LABELS.loverCalculonUseRate.toUpperCase(),
    },
    {
        label: BASE_INTERNAL_ONLY_LABELS.upperCalculonUseRate,
        helpPath: SeatHelpKeys.SeatUpperCalculonUserateOverride,
        key: BASE_INTERNAL_ONLY_LABELS.upperCalculonUseRate.toUpperCase(),
    },
    {
        label: BASE_INTERNAL_ONLY_LABELS.publisherReauction,
        helpPath: DetailsHelpKeys.AdUnitPublisherReauction,
        key: BASE_INTERNAL_ONLY_LABELS.publisherReauction.toUpperCase(),
    },
];

const SUPPLY_EXCHANGE_SAFETY_HELP_FIELDS = [
    {
        label: SUPPLY_EXCHANGE_SAFETY_LABELS.supplyDomainLists,
        helpPath: DetailsHelpKeys.SupplyDomainLists,
        key: SUPPLY_EXCHANGE_SAFETY_LABELS.supplyDomainLists.toUpperCase(),
    },
    {
        label: SUPPLY_EXCHANGE_SAFETY_LABELS.appBundleIdLists,
        helpPath: DetailsHelpKeys.SupplyBundleIdLists,
        key: SUPPLY_EXCHANGE_SAFETY_LABELS.appBundleIdLists.toUpperCase(),
    },
];

const SUPPLY_SUPPLY_DETAILS_HELP_FILEDS = (isInternalOnly?: boolean) =>
    [
        {
            label: SUPPLY_SUPPLY_DETAILS_LABELS.video,
            helpPath: DetailsHelpKeys.SupplyVideoQuality,
            key: "VIDEO_QUOLITY",
        },
        {
            label: SUPPLY_SUPPLY_DETAILS_LABELS.srcRelationship,
            helpPath: DetailsHelpKeys.SupplySourceRlationship,
            key: "SOURCE_RLATIONSHIP",
        },
        {
            label: SUPPLY_SUPPLY_DETAILS_LABELS.ifa,
            helpPath: DetailsHelpKeys.IfaMode,
            key: "IFA_MODE",
        },
        {
            label: SUPPLY_SUPPLY_DETAILS_LABELS.wrapper,
            helpPath: DetailsHelpKeys.SupplyAllowVastWrappers,
            key: "VAST_WRAPPER",
        },
        {
            label: SUPPLY_SUPPLY_DETAILS_LABELS.coppa,
            helpPath: DetailsHelpKeys.SupplyCoppa,
            key: "COPPA",
        },
        {
            label: SUPPLY_SUPPLY_DETAILS_LABELS.privacyPolicy,
            helpPath: DetailsHelpKeys.PrivacyPolicy,
            key: "PRIVACY_POLICY",
        },
        isInternalOnly && {
            label: SUPPLY_SUPPLY_DETAILS_LABELS.viewability,
            helpPath: DetailsHelpKeys.ViewabilityVendors,
            key: "VENDORS",
        },
        {
            label: SUPPLY_SUPPLY_DETAILS_LABELS.keywords,
            helpPath: DetailsHelpKeys.Keywords,
            key: "KEYWORDS",
        },
        {
            label: SUPPLY_SUPPLY_DETAILS_LABELS.mask,
            helpPath: DetailsHelpKeys.SupplyMaskIFA,
            key: "MASK_IFA",
        },
    ].filter(Boolean);

const SUPPLY_WTAERFALL_HELP_FILEDS = [
    {
        label: SUPPLY_WTAERFALL_DETAILS_LABELS.prefiltering,
        helpPath: DetailsHelpKeys.WaterfallPrefiltering,
        key: "PREFILTERING",
    },
];

const AD_UNIT_GENERAL_HELP_FILEDS = [
    ...GENERAL_HELP_FIELD,
    {
        label: AD_UNIT_DETAILS_LABELS.status,
        helpPath: DetailsHelpKeys.AdUnitStatus,
        key: "STATUS",
    },
];

const AD_UNIT_AFFILIATE_HELP_FIELDS = [
    {
        label: BASE_GENERAL_DETAILS_LABELS.affiliateCostModel,
        helpPath: DetailsHelpKeys.AdUnitAffiliateCostModel,
        key: BASE_GENERAL_DETAILS_LABELS.affiliateCostModel.toUpperCase(),
    },
    {
        label: BASE_GENERAL_DETAILS_LABELS.affiliateCostValue,
        helpPath: DetailsHelpKeys.AdUnitAffiliateCostValue,
        key: BASE_GENERAL_DETAILS_LABELS.affiliateCostValue.toUpperCase(),
    },
];

const BRAND_AFFILIATE_HELP_FIELDS = [
    {
        label: BASE_GENERAL_DETAILS_LABELS.affiliateCostModel,
        helpPath: DetailsHelpKeys.BrandAffiliateCostModel,
        key: BASE_GENERAL_DETAILS_LABELS.affiliateCostModel.toUpperCase(),
    },
    {
        label: BASE_GENERAL_DETAILS_LABELS.affiliateCostValue,
        helpPath: DetailsHelpKeys.BrandAffiliateCostValue,
        key: BASE_GENERAL_DETAILS_LABELS.affiliateCostValue.toUpperCase(),
    },
];

const SUPPLY_AFFILIATE_HELP_FIELDS = [
    {
        label: BASE_GENERAL_DETAILS_LABELS.affiliateCostModel,
        helpPath: DetailsHelpKeys.SupplyAffiliateCostModel,
        key: BASE_GENERAL_DETAILS_LABELS.affiliateCostModel.toUpperCase(),
    },
    {
        label: BASE_GENERAL_DETAILS_LABELS.affiliateCostValue,
        helpPath: DetailsHelpKeys.SupplyAffiliateCostValue,
        key: BASE_GENERAL_DETAILS_LABELS.affiliateCostValue.toUpperCase(),
    },
];

const AD_UNIT_BRAND_SAFETY_HELP_FILEDS = [
    ...SEAT_BRAND_SAFETY_HELP_FIELDS,
    {
        label: AD_UNIT_BRAND_SAFETY_LABELS.adFreqCaps,
        helpPath: DetailsHelpKeys.AdUnitFrequencyCapping,
        key: AD_UNIT_BRAND_SAFETY_LABELS.adFreqCaps.toUpperCase(),
    },
];

const AD_UNIT_INTERNAL_ONLY_HELP_FILEDS = [
    {
        label: BASE_INTERNAL_ONLY_LABELS.publisherReauction,
        helpPath: DetailsHelpKeys.AdUnitPublisherReauction,
        key: BASE_INTERNAL_ONLY_LABELS.publisherReauction.toUpperCase(),
    },
    {
        label: BASE_INTERNAL_ONLY_LABELS.loverCalculonUseRate,
        helpPath: SeatHelpKeys.SeatCalculonUseRateOverride,
        key: BASE_INTERNAL_ONLY_LABELS.loverCalculonUseRate.toUpperCase(),
    },
    {
        label: BASE_INTERNAL_ONLY_LABELS.upperCalculonUseRate,
        helpPath: SeatHelpKeys.SeatUpperCalculonUserateOverride,
        key: BASE_INTERNAL_ONLY_LABELS.upperCalculonUseRate.toUpperCase(),
    },
];

const AD_UNIT_DEMAND_REQUIREMENTS_HELP_FIELDS = [
    {
        label: AD_UNIT_DEMAND_REQUIREMENTS_LABELS.supportedProtocols,
        helpPath: DetailsHelpKeys.AdUnitSupportedProtocols,
        key: AD_UNIT_DEMAND_REQUIREMENTS_LABELS.supportedProtocols.toUpperCase(),
    },
    {
        label: AD_UNIT_DEMAND_REQUIREMENTS_LABELS.mimes,
        helpPath: DetailsHelpKeys.AdUnitMimes,
        key: AD_UNIT_DEMAND_REQUIREMENTS_LABELS.mimes.toUpperCase(),
    },
    {
        label: AD_UNIT_DEMAND_REQUIREMENTS_LABELS.supportedApis,
        helpPath: DetailsHelpKeys.AdUnitSupportedApis,
        key: AD_UNIT_DEMAND_REQUIREMENTS_LABELS.supportedApis,
    },
    {
        label: AD_UNIT_DEMAND_REQUIREMENTS_LABELS.placement,
        helpPath: DetailsHelpKeys.AdUnitPlacement,
        key: AD_UNIT_DEMAND_REQUIREMENTS_LABELS.placement.toUpperCase(),
    },
    {
        label: AD_UNIT_DEMAND_REQUIREMENTS_LABELS.maxDuration,
        helpPath: DetailsHelpKeys.AdUnitMaxDuration,
        key: AD_UNIT_DEMAND_REQUIREMENTS_LABELS.maxDuration.toUpperCase(),
    },
    {
        label: AD_UNIT_DEMAND_REQUIREMENTS_LABELS.minDuration,
        helpPath: DetailsHelpKeys.AdUnitMinDuration,
        key: AD_UNIT_DEMAND_REQUIREMENTS_LABELS.minDuration,
    },
    {
        label: AD_UNIT_DEMAND_REQUIREMENTS_LABELS.maxExtended,
        helpPath: DetailsHelpKeys.AdUnitMaxExtended,
        key: AD_UNIT_DEMAND_REQUIREMENTS_LABELS.maxExtended,
    },
    {
        label: AD_UNIT_DEMAND_REQUIREMENTS_LABELS.blockedAttributes,
        helpPath: DetailsHelpKeys.AdUnitBlockedAttr,
        key: AD_UNIT_DEMAND_REQUIREMENTS_LABELS.blockedAttributes,
    },
    {
        label: AD_UNIT_DEMAND_REQUIREMENTS_LABELS.minBitrate,
        helpPath: DetailsHelpKeys.AdUnitMinBitrate,
        key: AD_UNIT_DEMAND_REQUIREMENTS_LABELS.minBitrate,
    },
    {
        label: AD_UNIT_DEMAND_REQUIREMENTS_LABELS.maxBitrate,
        helpPath: DetailsHelpKeys.AdUnitMaxBitrate,
        key: AD_UNIT_DEMAND_REQUIREMENTS_LABELS.maxBitrate,
    },
];

const AD_UNIT_SUPPLY_DETAILS_HELP_FILEDS = (isInternalOnly?: boolean) =>
    [
        {
            label: AD_UNIT_SUPPLY_DETAILS_LABELS.linearity,
            helpPath: DetailsHelpKeys.AdUnitLinearity,
            key: AD_UNIT_SUPPLY_DETAILS_LABELS.linearity.toUpperCase(),
        },
        {
            label: AD_UNIT_SUPPLY_DETAILS_LABELS.multiplicity,
            helpPath: DetailsHelpKeys.AdUnitMultiplicity,
            key: AD_UNIT_SUPPLY_DETAILS_LABELS.multiplicity.toUpperCase(),
        },
        {
            label: AD_UNIT_SUPPLY_DETAILS_LABELS.type,
            helpPath: DetailsHelpKeys.AdUnitType,
            key: AD_UNIT_SUPPLY_DETAILS_LABELS.type.toUpperCase(),
        },
        {
            label: AD_UNIT_SUPPLY_DETAILS_LABELS.videoPlaybacks,
            helpPath: DetailsHelpKeys.AdUnitVidePlaback,
            key: AD_UNIT_SUPPLY_DETAILS_LABELS.videoPlaybacks.toUpperCase(),
        },
        {
            label: AD_UNIT_SUPPLY_DETAILS_LABELS.deliveryMethods,
            helpPath: DetailsHelpKeys.AdUnitDeliverMethod,
            key: AD_UNIT_SUPPLY_DETAILS_LABELS.deliveryMethods.toUpperCase(),
        },
        {
            label: AD_UNIT_SUPPLY_DETAILS_LABELS.skippable,
            helpPath: DetailsHelpKeys.AdUnitSkippable,
            key: AD_UNIT_SUPPLY_DETAILS_LABELS.skippable.toUpperCase(),
        },
        {
            label: AD_UNIT_SUPPLY_DETAILS_LABELS.skippableMin,
            helpPath: DetailsHelpKeys.AdUnitSkippableMin,
            key: AD_UNIT_SUPPLY_DETAILS_LABELS.skippableMin.toUpperCase(),
        },
        {
            label: AD_UNIT_SUPPLY_DETAILS_LABELS.skippableAfter,
            helpPath: DetailsHelpKeys.AdUnitSkippableAfter,
            key: AD_UNIT_SUPPLY_DETAILS_LABELS.skippableAfter.toUpperCase(),
        },
        {
            label: AD_UNIT_SUPPLY_DETAILS_LABELS.interstitial,
            helpPath: DetailsHelpKeys.AdUnitInterstitial,
            key: AD_UNIT_SUPPLY_DETAILS_LABELS.interstitial.toUpperCase(),
        },
        {
            label: AD_UNIT_SUPPLY_DETAILS_LABELS.boxing,
            helpPath: DetailsHelpKeys.AdUnitBoxing,
            key: AD_UNIT_SUPPLY_DETAILS_LABELS.boxing.toUpperCase(),
        },

        {
            label: AD_UNIT_SUPPLY_DETAILS_LABELS.allowDemandUnderInboundFloor,
            helpPath: DetailsHelpKeys.AdUnitOverrideDynamicFloor,
            key: AD_UNIT_SUPPLY_DETAILS_LABELS.allowDemandUnderInboundFloor.toUpperCase(),
        },

        {
            label: AD_UNIT_SUPPLY_DETAILS_LABELS.ssaiType,
            helpPath: DetailsHelpKeys.AdUnitSSAI,
            key: AD_UNIT_SUPPLY_DETAILS_LABELS.ssaiType.toUpperCase(),
        },
        isInternalOnly && {
            label: AD_UNIT_SUPPLY_DETAILS_LABELS.extendedImpWaitTime,
            helpPath: DetailsHelpKeys.AdUnitExtendedImp,
            key: AD_UNIT_SUPPLY_DETAILS_LABELS.extendedImpWaitTime.toUpperCase(),
        },
        {
            label: AD_UNIT_SUPPLY_DETAILS_LABELS.creativeSamples,
            helpPath: DetailsHelpKeys.AdUnitCreativeSamples,
            key: AD_UNIT_SUPPLY_DETAILS_LABELS.creativeSamples.toUpperCase(),
        },
        {
            label: AD_UNIT_SUPPLY_DETAILS_LABELS.unwrapVast,
            helpPath: DetailsHelpKeys.AdUnitUnwrapVast,
            key: AD_UNIT_SUPPLY_DETAILS_LABELS.unwrapVast.toUpperCase(),
        },
        {
            label: AD_UNIT_SUPPLY_DETAILS_LABELS.liveStream,
            helpPath: DetailsHelpKeys.LiveStream,
            key: AD_UNIT_SUPPLY_DETAILS_LABELS.liveStream.toUpperCase(),
        },
        {
            label: AD_UNIT_SUPPLY_DETAILS_LABELS.contentChannel,
            helpPath: DetailsHelpKeys.AdUnitContentChannel,
            key: AD_UNIT_SUPPLY_DETAILS_LABELS.contentChannel.toUpperCase(),
        },
        {
            label: AD_UNIT_SUPPLY_DETAILS_LABELS.udOptUout,
            helpPath: DetailsHelpKeys.AdUnitUDOPT,
            key: AD_UNIT_SUPPLY_DETAILS_LABELS.udOptUout.toUpperCase(),
        },
        {
            label: AD_UNIT_SUPPLY_DETAILS_LABELS.liveStreamAcceleration,
            helpPath: DetailsHelpKeys.AdUnitAcceleration,
            key: AD_UNIT_SUPPLY_DETAILS_LABELS.liveStreamAcceleration.toUpperCase(),
        },
    ].filter(Boolean);

const AD_UNIT_PLAYLIST_CONFIGURATION_HELP_FIELDS = [
    {
        label: AD_UNIT_PLAYLIST_CONFIGURATION_LABELS.fillMode,
        helpKeys: DetailsHelpKeys.AdUnitFillMode,
        key: AD_UNIT_PLAYLIST_CONFIGURATION_LABELS.fillMode.toUpperCase(),
    },
    {
        label: AD_UNIT_PLAYLIST_CONFIGURATION_LABELS.podEnforcement,
        helpKeys: DetailsHelpKeys.AdUnitPodEnforcement,
        key: AD_UNIT_PLAYLIST_CONFIGURATION_LABELS.podEnforcement.toUpperCase(),
    },
    {
        label: AD_UNIT_PLAYLIST_CONFIGURATION_LABELS.competSeparationG,
        helpKeys: DetailsHelpKeys.AdUnitCompetitiveSeparation,
        key: AD_UNIT_PLAYLIST_CONFIGURATION_LABELS.competSeparationG.toUpperCase(),
    },
    {
        label: AD_UNIT_PLAYLIST_CONFIGURATION_LABELS.maxAd,
        helpKeys: DetailsHelpKeys.AdUnitMaxAdsPerAdv,
        key: AD_UNIT_PLAYLIST_CONFIGURATION_LABELS.maxAd.toUpperCase(),
    },
    {
        label: AD_UNIT_PLAYLIST_CONFIGURATION_LABELS.playlistDef,
        helpKeys: DetailsHelpKeys.AdUnitPlaylistDefMode,
        key: AD_UNIT_PLAYLIST_CONFIGURATION_LABELS.playlistDef.toUpperCase(),
    },
];

const AD_UNIT_AD_POD_HELP_FIELDS = [
    {
        label: AD_UNIT_PLAYLIST_CONFIGURATION_LABELS.isAdPod,
        helpKeys: DetailsHelpKeys.AdUnitIsAdPod,
        key: AD_UNIT_PLAYLIST_CONFIGURATION_LABELS.isAdPod.toUpperCase(),
    },
    {
        label: AD_UNIT_PLAYLIST_CONFIGURATION_LABELS.maxPodSeconds,
        helpKeys: DetailsHelpKeys.AdUnitMaxPodSeconds,
        key: AD_UNIT_PLAYLIST_CONFIGURATION_LABELS.maxPodSeconds.toUpperCase(),
    },
    {
        label: AD_UNIT_PLAYLIST_CONFIGURATION_LABELS.maxAdsPerPod,
        helpKeys: DetailsHelpKeys.AdUnitMaxAdsPerPod,
        key: AD_UNIT_PLAYLIST_CONFIGURATION_LABELS.maxAdsPerPod.toUpperCase(),
    },
    {
        label: AD_UNIT_PLAYLIST_CONFIGURATION_LABELS.fillMode,
        helpKeys: DetailsHelpKeys.AdUnitFillMode,
        key: AD_UNIT_PLAYLIST_CONFIGURATION_LABELS.fillMode.toUpperCase(),
    },
    {
        label: AD_UNIT_PLAYLIST_CONFIGURATION_LABELS.podEnforcement,
        helpKeys: DetailsHelpKeys.AdUnitPodEnforcement,
        key: AD_UNIT_PLAYLIST_CONFIGURATION_LABELS.podEnforcement.toUpperCase(),
    },
    {
        label: AD_UNIT_PLAYLIST_CONFIGURATION_LABELS.competSeparationG,
        helpKeys: DetailsHelpKeys.AdUnitCompetitiveSeparation,
        key: AD_UNIT_PLAYLIST_CONFIGURATION_LABELS.competSeparationG.toUpperCase(),
    },
];

const CHANNEL_GENERAL_HELP_FIELDS = (isInternalOnly?: boolean) =>
    [
        {
            label: CHANNEL_GENERAL_LABELS.name,
            helpKeys: DetailsHelpKeys.Name,
            key: CHANNEL_GENERAL_LABELS.name,
        },
        {
            label: CHANNEL_GENERAL_LABELS.description,
            helpPath: DetailsHelpKeys.Description,
            key: CHANNEL_GENERAL_LABELS.description,
        },
        {
            label: CHANNEL_GENERAL_LABELS.publisherCode,
            helpPath: DetailsHelpKeys.Code,
            key: CHANNEL_GENERAL_LABELS.publisherCode,
        },
        {
            label: CHANNEL_GENERAL_LABELS.adUnitCode,
            helpPath: DetailsHelpKeys.Code,
            key: CHANNEL_GENERAL_LABELS.adUnitCode,
        },
        isInternalOnly && {
            label: CHANNEL_GENERAL_LABELS.accountType,
            helpPath: DetailsHelpKeys.PublisherAccountType,
            key: CHANNEL_GENERAL_LABELS.accountType,
        },
        {
            label: CHANNEL_GENERAL_LABELS.billingCode,
            helpPath: DetailsHelpKeys.PublisherBillingCode,
            key: CHANNEL_GENERAL_LABELS.billingCode,
        },
    ].filter(Boolean);

const CHANNEL_DETAILED_CONFIGURATION_HELP_FILEDS = (isInternalOnly?: boolean) =>
    [
        {
            label: PUBLISHER_GENERAL_LABELS.businessDomain,
            helpPath: DetailsHelpKeys.PublisherBusinessDomain,
            key: PUBLISHER_GENERAL_LABELS.businessDomain.toUpperCase(),
        },
        {
            label: AD_UNIT_SUPPLY_DETAILS_LABELS.unwrapVast,
            helpPath: DetailsHelpKeys.AdUnitUnwrapVast,
            key: AD_UNIT_SUPPLY_DETAILS_LABELS.unwrapVast.toUpperCase(),
        },
        {
            label: SUPPLY_SUPPLY_DETAILS_LABELS.wrapper,
            helpPath: DetailsHelpKeys.SupplyAllowVastWrappers,
            key: "VAST_WRAPPER",
        },
        {
            label: AD_UNIT_SUPPLY_DETAILS_LABELS.extendedImpWaitTime,
            helpPath: DetailsHelpKeys.AdUnitExtendedImp,
            key: AD_UNIT_SUPPLY_DETAILS_LABELS.extendedImpWaitTime.toUpperCase(),
        },
        isInternalOnly && {
            label: CHANNEL_DETAILED_CONFIGURATION_LABELS.noAdCookieSync,
            helpPath: DetailsHelpKeys.SupplyEnableNoAdCookieSync,
            key: CHANNEL_DETAILED_CONFIGURATION_LABELS.noAdCookieSync.toUpperCase(),
        },
        isInternalOnly && {
            label: CHANNEL_DETAILED_CONFIGURATION_LABELS.advAsAllowList,
            helpPath: DetailsHelpKeys.BrandAdvAsAllowList,
            key: CHANNEL_DETAILED_CONFIGURATION_LABELS.advAsAllowList.toUpperCase(),
        },
        isInternalOnly && {
            label: CHANNEL_DETAILED_CONFIGURATION_LABELS.publisherReAuction,
            helpPath: DetailsHelpKeys.AdUnitPublisherReauction,
            key: CHANNEL_DETAILED_CONFIGURATION_LABELS.publisherReAuction.toUpperCase(),
        },
        ...SEAT_CUSTOM_PIXELS_HELP_FIELDS,
        ...SEAT_LABELS_HELP_FIELDS,
        {
            label: AD_UNIT_BRAND_SAFETY_LABELS.adFreqCaps,
            helpPath: DetailsHelpKeys.AdUnitFrequencyCapping,
            key: AD_UNIT_BRAND_SAFETY_LABELS.adFreqCaps.toUpperCase(),
        },
    ].filter(Boolean);

const CHANNEL_DEFAULTS_HELP_FILEDS = [
    {
        label: AD_UNIT_DEMAND_REQUIREMENTS_LABELS.mimes,
        helpPath: DetailsHelpKeys.AdUnitMimes,
        key: AD_UNIT_DEMAND_REQUIREMENTS_LABELS.mimes.toUpperCase(),
    },
    {
        label: AD_UNIT_DEMAND_REQUIREMENTS_LABELS.supportedApis,
        helpPath: DetailsHelpKeys.AdUnitSupportedApis,
        key: AD_UNIT_DEMAND_REQUIREMENTS_LABELS.supportedApis,
    },
    {
        label: AD_UNIT_SUPPLY_DETAILS_LABELS.linearity,
        helpPath: DetailsHelpKeys.AdUnitLinearity,
        key: AD_UNIT_SUPPLY_DETAILS_LABELS.linearity.toUpperCase(),
    },
    {
        label: AD_UNIT_SUPPLY_DETAILS_LABELS.multiplicity,
        helpPath: DetailsHelpKeys.AdUnitMultiplicity,
        key: AD_UNIT_SUPPLY_DETAILS_LABELS.multiplicity.toUpperCase(),
    },
    {
        label: AD_UNIT_SUPPLY_DETAILS_LABELS.type,
        helpPath: DetailsHelpKeys.AdUnitType,
        key: AD_UNIT_SUPPLY_DETAILS_LABELS.type.toUpperCase(),
    },
    {
        label: AD_UNIT_SUPPLY_DETAILS_LABELS.interstitial,
        helpPath: DetailsHelpKeys.AdUnitInterstitial,
        key: AD_UNIT_SUPPLY_DETAILS_LABELS.interstitial.toUpperCase(),
    },
    {
        label: AD_UNIT_DEMAND_REQUIREMENTS_LABELS.placement,
        helpPath: DetailsHelpKeys.AdUnitPlacement,
        key: AD_UNIT_DEMAND_REQUIREMENTS_LABELS.placement.toUpperCase(),
    },
    {
        label: SUPPLY_SUPPLY_DETAILS_LABELS.coppa,
        helpPath: DetailsHelpKeys.SupplyCoppa,
        key: "COPPA",
    },
    {
        label: SUPPLY_SUPPLY_DETAILS_LABELS.privacyPolicy,
        helpPath: DetailsHelpKeys.PrivacyPolicy,
        key: "PRIVACY_POLICY",
    },
    {
        label: SUPPLY_APP_DETAILS_LABELS.partnerDomain,
        helpPath: DetailsHelpKeys.SupplyPartner,
        key: "PARTNER_DOMAIN",
    },
    {
        label: AD_UNIT_DEMAND_REQUIREMENTS_LABELS.supportedProtocols,
        helpPath: DetailsHelpKeys.AdUnitSupportedProtocols,
        key: AD_UNIT_DEMAND_REQUIREMENTS_LABELS.supportedProtocols.toUpperCase(),
    },
    {
        label: BRAND_SUPPLY_DETAILS_LABELS.categories,
        helpPath: DetailsHelpKeys.BrandCategories,
        key: BRAND_SUPPLY_DETAILS_LABELS.categories.toUpperCase(),
    },
    {
        label: AD_UNIT_DEMAND_REQUIREMENTS_LABELS.minDuration,
        helpPath: DetailsHelpKeys.AdUnitMinDuration,
        key: AD_UNIT_DEMAND_REQUIREMENTS_LABELS.minDuration,
    },
    {
        label: AD_UNIT_DEMAND_REQUIREMENTS_LABELS.maxDuration,
        helpPath: DetailsHelpKeys.AdUnitMaxDuration,
        key: AD_UNIT_DEMAND_REQUIREMENTS_LABELS.maxDuration.toUpperCase(),
    },
    {
        label: AD_UNIT_DEMAND_REQUIREMENTS_LABELS.maxExtended,
        helpPath: DetailsHelpKeys.AdUnitMaxExtended,
        key: AD_UNIT_DEMAND_REQUIREMENTS_LABELS.maxExtended,
    },
    {
        label: AD_UNIT_DEMAND_REQUIREMENTS_LABELS.minBitrate,
        helpPath: DetailsHelpKeys.AdUnitMinBitrate,
        key: AD_UNIT_DEMAND_REQUIREMENTS_LABELS.minBitrate,
    },
    {
        label: AD_UNIT_DEMAND_REQUIREMENTS_LABELS.maxBitrate,
        helpPath: DetailsHelpKeys.AdUnitMaxBitrate,
        key: AD_UNIT_DEMAND_REQUIREMENTS_LABELS.maxBitrate,
    },
    {
        label: AD_UNIT_SUPPLY_DETAILS_LABELS.extendedImpWaitTime,
        helpPath: DetailsHelpKeys.AdUnitExtendedImp,
        key: AD_UNIT_SUPPLY_DETAILS_LABELS.extendedImpWaitTime.toUpperCase(),
    },
    {
        label: AD_UNIT_SUPPLY_DETAILS_LABELS.liveStream,
        helpPath: DetailsHelpKeys.LiveStream,
        key: AD_UNIT_SUPPLY_DETAILS_LABELS.liveStream.toUpperCase(),
    },
];

const BRAND_CHANNEL_GENERAL_HELP_FILEDS = [
    (CHANNEL_GENERAL_HELP_FIELDS(false) as { key: string }[]).filter(
        ({ key }) => key !== CHANNEL_GENERAL_LABELS.billingCode || key !== CHANNEL_GENERAL_LABELS.accountType
    ),
    {
        label: CHANNEL_GENERAL_LABELS.brandCode,
        helpPath: DetailsHelpKeys.Code,
        key: CHANNEL_GENERAL_LABELS.brandCode,
    },
];

const LABELS_SECTIONS_NAME = (isInternalOnly?: boolean) => ({
    [SECTIONS_NAMES.LABELS]: {
        label: "Labels",
        helpKeys: LABELS_HELP_FIELDS(isInternalOnly),
    },
});

const PIXELS_SECTIONS_NAME = {
    [SECTIONS_NAMES.PIXELS]: {
        label: "Custom Pixels",
        helpKeys: SEAT_CUSTOM_PIXELS_HELP_FIELDS,
    },
};

const BASE_DETAILS_SECIONS_NAME = {
    [SECTIONS_NAMES.PERFORMANCE]: { label: "Performance", helpKeys: SEAT_PERFORMANCE_HELP_FIELDS },
    [SECTIONS_NAMES.FLOORS]: {
        label: "Floors",
        helpKeys: [],
    },
};

const WATERFALL_PREFILTERING_SECTION = {
    [SECTIONS_NAMES.WATERFALL_PREFILTERING]: {
        label: "Waterfall Prefiltering",
        helpKeys: SUPPLY_WTAERFALL_HELP_FILEDS,
    },
};

export const PUBLISHER_DETAILS_SECTIONS_NAME = (isInternalOnly?: boolean) => ({
    ...BASE_DETAILS_SECIONS_NAME,
    [SECTIONS_NAMES.GENERAL]: { label: "General", helpKeys: PUBLISHER_GENERAL_HELP_FIELDS },
    [SECTIONS_NAMES.AFFILIATE]: {
        label: "Affiliate (Internal only)",
        helpKeys: PUBLISHER_AFFILIATE_HELP_FIELDS,
        helpLabel: "Affiliate",
    },
    [SECTIONS_NAMES.BRAND_SAFETY]: {
        label: "Brand Safety",
        helpKeys: PUBLISHER_BRAND_SAFETY_HELP_FIELDS(isInternalOnly),
    },
    [SECTIONS_NAMES.INTERNAL]: {
        label: "Internal (Internal only)",
        helpKeys: PUBLISHER_INTERNAL_HELP_FIELDS,
        helpLabel: "Internal",
    },
    [SECTIONS_NAMES.ADVANCED_FEATURES]: {
        label: "Advanced Features (Internal only)",
        helpKeys: PUBLISHER_ADVANCED_FEATURES_HELP_FIELDS,
        helpLabel: "Advanced Features",
    },
    ...PIXELS_SECTIONS_NAME,
    ...LABELS_SECTIONS_NAME(isInternalOnly),
});

export const BRAND_DETAILS_SECTIONS_NAME = (isInternalOnly?: boolean) => ({
    ...BASE_DETAILS_SECIONS_NAME,
    [SECTIONS_NAMES.GENERAL]: { label: "General", helpKeys: GENERAL_HELP_FIELD },
    [SECTIONS_NAMES.AFFILIATE]: {
        label: "Affiliate (Internal only)",
        helpKeys: BRAND_AFFILIATE_HELP_FIELDS,
        helpLabel: "Affiliate",
    },
    [SECTIONS_NAMES.BRAND_SAFETY]: { label: "Brand Safety", helpKeys: BRAND_BRAND_SAFETY_HELP_FILEDS(isInternalOnly) },
    [SECTIONS_NAMES.INTERNAL]: {
        label: "Internal (Internal Only)",
        helpKeys: GENERAL_INTERNAL_ONLY_HELP_FIELDS,
        helpLabel: "Internal",
    },
    [SECTIONS_NAMES.SUPPLY_DETAILS]: {
        label: "Supply Details",
        helpKeys: BRAND_SUPPLY_DETAILS,
    },
    ...PIXELS_SECTIONS_NAME,
    ...LABELS_SECTIONS_NAME(isInternalOnly),
});

export const SUPPLY_DETAILS_SECTIONS_NAME = (isInternalOnly?: boolean) => ({
    ...BASE_DETAILS_SECIONS_NAME,
    [SECTIONS_NAMES.GENERAL]: { label: "General", helpKeys: SUPPLY_GENERAL_HELP_FIELDS },
    [SECTIONS_NAMES.AFFILIATE]: {
        label: "Affiliate (Internal only)",
        helpKeys: SUPPLY_AFFILIATE_HELP_FIELDS,
        helpLabel: "Affiliate",
    },
    [SECTIONS_NAMES.BRAND_SAFETY]: { label: "Brand Safety", helpKeys: SEAT_BRAND_SAFETY_HELP_FIELDS },
    [SECTIONS_NAMES.SITE_DETAILS]: { label: "Site Details", helpKeys: SUPPLY_SITE_DETAILS_HELP_FIELDS },
    [SECTIONS_NAMES.APP_DETAILS]: {
        label: "App Details",
        helpKeys: SUPPLY_APP_DETAILS_HELP_FIELDS,
    },
    [SECTIONS_NAMES.EXCHANGE_SAFETY]: {
        label: "Exchange Safety (Internal Only)",
        helpKeys: SUPPLY_EXCHANGE_SAFETY_HELP_FIELDS,
        helpLabel: "Exchange Safety",
    },
    [SECTIONS_NAMES.INTERNAL]: {
        label: "Internal (Internal Only)",
        helpKeys: SUPPLY_INTERNAL_ONLY_HELP_FIELDS,
        helpLabel: "Internal",
    },
    [SECTIONS_NAMES.SUPPLY_DETAILS]: {
        label: "Supply Details",
        helpKeys: SUPPLY_SUPPLY_DETAILS_HELP_FILEDS(isInternalOnly),
    },
    ...PIXELS_SECTIONS_NAME,
    ...LABELS_SECTIONS_NAME(isInternalOnly),
    ...WATERFALL_PREFILTERING_SECTION,
});

export const AD_UNIT_DETAILS_SECTIONS_NAME = (isInternalOnly?: boolean) => ({
    ...BASE_DETAILS_SECIONS_NAME,
    [SECTIONS_NAMES.GENERAL]: { label: "General", helpKeys: AD_UNIT_GENERAL_HELP_FILEDS },
    [SECTIONS_NAMES.AFFILIATE]: {
        label: "Affiliate (Internal only)",
        helpKeys: AD_UNIT_AFFILIATE_HELP_FIELDS,
        helpLabel: "Affiliate",
    },
    [SECTIONS_NAMES.BRAND_SAFETY]: { label: "Brand Safety", helpKeys: AD_UNIT_BRAND_SAFETY_HELP_FILEDS },
    [SECTIONS_NAMES.INTERNAL]: {
        label: "Internal (Internal only)",
        helpKeys: AD_UNIT_INTERNAL_ONLY_HELP_FILEDS,
    },
    [SECTIONS_NAMES.DEMAND_REQUIREMENTS]: {
        label: "Demand Requirements",
        helpKeys: AD_UNIT_DEMAND_REQUIREMENTS_HELP_FIELDS,
    },
    [SECTIONS_NAMES.SSP_CONNECT]: {
        label: "SSP Connect - External Codes",
        helpKeys: [],
    },
    [SECTIONS_NAMES.SUPPLY_DETAILS]: {
        label: "Supply Details",
        helpKeys: AD_UNIT_SUPPLY_DETAILS_HELP_FILEDS(isInternalOnly),
    },
    [SECTIONS_NAMES.PLAYLIST_CONFIGURATION]: {
        label: "Playlist Configuration",
        helpKeys: AD_UNIT_PLAYLIST_CONFIGURATION_HELP_FIELDS,
    },
    [SECTIONS_NAMES.AD_POD]: {
        label: "Ad Pod",
        helpKeys: AD_UNIT_AD_POD_HELP_FIELDS,
    },
    [SECTIONS_NAMES.AD_BREAK_RULES]: {
        label: "Ad Break Rules",
        helpKeys: [],
    },
    ...PIXELS_SECTIONS_NAME,
    ...LABELS_SECTIONS_NAME(isInternalOnly),
    ...WATERFALL_PREFILTERING_SECTION,
});

export const CHANNEL_DETAILS_SECTIONS_NAME = (isInternalOnly?: boolean) => ({
    [SECTIONS_NAMES.PERFORMANCE]: { label: "Performance", helpKeys: SEAT_PERFORMANCE_HELP_FIELDS },
    [SECTIONS_NAMES.GENERAL]: { label: "General", helpKeys: CHANNEL_GENERAL_HELP_FIELDS(isInternalOnly) },
    [SECTIONS_NAMES.ADVANCED_FEATURES]: { label: "Advanced Features" },
    [SECTIONS_NAMES.DEFAULTS]: { label: "Defaults", helpKeys: CHANNEL_DEFAULTS_HELP_FILEDS },
    [SECTIONS_NAMES.BRAND_SAFETY]: { label: "Brand Safety", helpKeys: BRAND_BRAND_SAFETY_HELP_FILEDS(false) },
    [SECTIONS_NAMES.SSP_CONNECT]: { label: "SSP Connect - External Codes", helpKeys: [] },
    [SECTIONS_NAMES.DETAILED_CONFIGURATION]: {
        label: "Detailed Configuration",
        helpKeys: CHANNEL_DETAILED_CONFIGURATION_HELP_FILEDS(isInternalOnly),
    },
    ...PIXELS_SECTIONS_NAME,
    ...LABELS_SECTIONS_NAME(isInternalOnly),
    [SECTIONS_NAMES.FLOORS]: {
        label: "Floors",
        helpKeys: FLOORS_HELP_FIELDS,
    },
    [SECTIONS_NAMES.PLAYLIST_CONFIGURATION]: {
        label: "Playlist Configuration",
        helpKeys: BRAND_CHANNEL_PLAYLIST_CONFIGURATION_HELP_FIELDS,
    },
    [SECTIONS_NAMES.AD_POD]: {
        label: "Ad Pod",
        helpKeys: AD_UNIT_AD_POD_HELP_FIELDS,
    },
});

const BRAND_CHANNEL_DETAILS_SECTIONS_NAME = {
    ...CHANNEL_DETAILS_SECTIONS_NAME(false),
    // ...LABELS_SECTIONS_NAME(isInternalOnly),
    [SECTIONS_NAMES.GENERAL]: { label: "General", helpKeys: BRAND_CHANNEL_GENERAL_HELP_FILEDS },
    [SECTIONS_NAMES.PLAYLIST_CONFIGURATION]: {
        label: "Playlist Configuration",
        helpKeys: BRAND_CHANNEL_PLAYLIST_CONFIGURATION_HELP_FIELDS,
    },
};

export const DETAILS_UNITS = (isInternalOnly?: boolean) => ({
    [InventoryDetailsDrawerType.PUBLISHER]: PUBLISHER_DETAILS_SECTIONS_NAME(isInternalOnly),
    [InventoryDetailsDrawerType.BRAND]: BRAND_DETAILS_SECTIONS_NAME(isInternalOnly),
    [InventoryDetailsDrawerType.SUPPLY]: SUPPLY_DETAILS_SECTIONS_NAME(isInternalOnly),
    [InventoryDetailsDrawerType.AD_UNIT]: AD_UNIT_DETAILS_SECTIONS_NAME(isInternalOnly),
    [InventoryDetailsDrawerType.CHANNEL]: CHANNEL_DETAILS_SECTIONS_NAME(isInternalOnly),
    [InventoryDetailsDrawerType.BRAND_CHANNEL]: BRAND_CHANNEL_DETAILS_SECTIONS_NAME,
});
