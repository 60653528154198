import { Form } from "antd";
import { SeatAdSourcesType, useGetAdvertiserBlockingModesQuery } from "@app/core/services";
import { AD_SOURCE_FIELDS, AdSourceAdvertiserDomainModeIds } from "@app/features/seatAdSources/constants";

const { IAB_BLOCKED_CATEGORIES, BLOCKED_INDUSTRIES } = AD_SOURCE_FIELDS;

export const useModeSelect = () => {
    const form = Form.useFormInstance();
    const { data: blockingModes = [], isLoading } = useGetAdvertiserBlockingModesQuery();

    const handleChange = (value: SeatAdSourcesType): void => {
        const isOverrideInventoryBlockedDomains: boolean =
            value?.id === AdSourceAdvertiserDomainModeIds.ALLOW_OVERRIDE_INVENTORY;
        if (isOverrideInventoryBlockedDomains)
            form.setFieldsValue({
                [IAB_BLOCKED_CATEGORIES.name]: [],
                [BLOCKED_INDUSTRIES.name]: [],
            });
    };

    return { blockingModes, isLoading, handleChange };
};
