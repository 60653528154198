import { MONTH_DAY_YEAR_HOUR_MINUTE_AMPM, NONE } from "@app/core/components/constants";
import { GUTTER } from "@app/features/inventory/constants";
import { Col, Form, Row, Typography } from "antd";
import { FORM_ITEM_OFFSET, COL_SIZES } from "../../../constants";
import { useGeneral } from "./useGeneral";
import moment from "moment-timezone";
import { TextWithCopy } from "@app/features/inventory/components/TextWithCopy";

const { Item } = Form;
const COL_SIZES_DESC = { xs: 12, sm: 10, md: 12 };

export const General = () => {
    const { code, name, updateDate, description, creationDate, lastIndexed } = useGeneral();
    return (
        <div data-sdet="brand-general-section">
            <Row gutter={GUTTER}>
                <Col {...COL_SIZES}>
                    <Item label="Name" className={FORM_ITEM_OFFSET}>
                        <TextWithCopy text={name} notificationEntity="Name" />
                    </Item>
                </Col>
            </Row>
            <Row gutter={GUTTER}>
                <Col {...COL_SIZES}>
                    <Item label="Code" className={FORM_ITEM_OFFSET}>
                        <TextWithCopy text={code} notificationEntity="Code" />
                    </Item>
                </Col>
            </Row>
            <Row gutter={GUTTER}>
                <Col {...COL_SIZES_DESC}>
                    <Item label="Description" className={FORM_ITEM_OFFSET}>
                        <Typography.Paragraph>{description ? description : NONE}</Typography.Paragraph>
                    </Item>
                </Col>
            </Row>
            <Row gutter={GUTTER}>
                <Col {...COL_SIZES}>
                    <Item label="Last Updated" className={FORM_ITEM_OFFSET}>
                        {updateDate ? moment(updateDate).format(MONTH_DAY_YEAR_HOUR_MINUTE_AMPM) : NONE}
                    </Item>
                </Col>
                <Col {...COL_SIZES}>
                    <Item label="Last Indexed" className={FORM_ITEM_OFFSET}>
                        {lastIndexed ? moment(lastIndexed).format(MONTH_DAY_YEAR_HOUR_MINUTE_AMPM) : NONE}
                    </Item>
                </Col>
                <Col {...COL_SIZES}>
                    <Item label="Date Created" className={FORM_ITEM_OFFSET}>
                        {creationDate ? moment(creationDate).format(MONTH_DAY_YEAR_HOUR_MINUTE_AMPM) : NONE}
                    </Item>
                </Col>
            </Row>
        </div>
    );
};
