import { Button, Flex, InputNumber, Select, Typography } from "antd";
import { FCAP_MODES_OPTIONS, FCAP_TIME_OPTIONS } from "../constants";
import { PlusOutlined } from "@ant-design/icons";
import { useAddFrequencyCappingItem } from "./useAddFrequencyCappingItem";
import { useMarketplaceInfoWidget } from "@app/features/seatAdSources/SeatAdSourcesForm/useMarketplaceInfoWidget";

export const AddFrequencyCappingItem = () => {
    const { total, handleSetTotal, setMode, mode, minutes, handleSetMinutes, timing, setTiming, handleAddItem } =
        useAddFrequencyCappingItem();
    const { dealObjectMode } = useMarketplaceInfoWidget();
    const isDisabled = dealObjectMode === "edit";
    return (
        <Flex align="center" flex="1 0 auto" gap={8}>
            <Flex align="center" flex="0 0 auto">
                <InputNumber
                    placeholder="Total..."
                    value={total}
                    style={{ width: "100%" }}
                    onChange={handleSetTotal}
                    disabled={isDisabled}
                />
                <Select
                    disabled={isDisabled}
                    onChange={setMode}
                    value={mode}
                    style={{ width: "100%" }}
                    options={FCAP_MODES_OPTIONS}
                />
            </Flex>
            <Typography.Text style={{ flexShrink: 0 }}>Per</Typography.Text>
            <Flex align="center" flex="0 0 auto">
                <InputNumber
                    placeholder="Time Duration..."
                    style={{ width: "100%" }}
                    value={minutes}
                    onChange={handleSetMinutes}
                    disabled={isDisabled}
                />
                <Select
                    disabled={isDisabled}
                    value={timing}
                    onChange={setTiming}
                    style={{ width: "100%" }}
                    options={FCAP_TIME_OPTIONS}
                />
            </Flex>
            <Button
                disabled={isDisabled}
                type="link"
                onClick={handleAddItem}
                icon={<PlusOutlined />}
                style={{ paddingLeft: 0 }}
            >
                Add
            </Button>
        </Flex>
    );
};
