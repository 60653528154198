import { FC } from "react";
import { Form, Select } from "antd";
import { CREATE_DEAL_FORM_ITEMS_NAME } from "@app/features/deals/constants";
import { DealFormMode } from "@app/features/deals/DealForm/types";
import { useAdSourcePacingTypeField } from "./useAdSourcePacingTypeField";
import { AD_SOURCE_FIELDS } from "@app/features/seatAdSources/constants";

const { label } = AD_SOURCE_FIELDS.PACING_TYPE;

interface Props {
    mode: DealFormMode;
}

export const AdSourcePacingTypeField: FC<Props> = ({ mode }) => {
    const { value, options, isReadonly, helpText, onChange } = useAdSourcePacingTypeField(mode);

    return (
        <Form.Item
            required
            name={CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_PACING_TYPE}
            rules={[{ required: true, message: `${label} is required` }]}
            label={label}
            data-sdet="ad-source-pacing-type-field"
            help={helpText}
        >
            <Select
                onSelect={onChange}
                options={options}
                value={value}
                disabled={isReadonly}
                placeholder={`Select ${label}`}
            />
        </Form.Item>
    );
};
