import React, { useMemo } from "react";
import { Button, Card, Flex, Typography } from "antd";
import moment from "moment";
import { CloseOutlined } from "@ant-design/icons";
import { YEAR_MONTH_DAY_HOUR_MINUTE_AMPM } from "@app/core/components/constants";
import { useGetAdSourceStatsSnapshotQuery } from "@app/core/services/adStats/statsSnapshots";
import { SeatAdSources24HourPerformanceChart } from "@app/features/seatAdSources/seatAdSourcesCharts";
import { useMapAdSourceAdStats } from "@app/features/seatAdSources/data/useMapAdSourceAdStats";

export const StatsSnapshotCard = ({ adSourceId, snapshotTimestamp, onClose }) => {
    const { mapMultipleAdSourceAdStats } = useMapAdSourceAdStats();
    const { data, isLoading } = useGetAdSourceStatsSnapshotQuery(
        { adSourceId, timestamp: snapshotTimestamp as string },
        { skip: !snapshotTimestamp }
    );

    const handleClose = () => {
        onClose(snapshotTimestamp);
    };

    const graph = useMemo(() => {
        if (!data || isLoading) {
            return null;
        }
        const historyAdSourceStats = mapMultipleAdSourceAdStats(data);
        return (
            <SeatAdSources24HourPerformanceChart
                adSourceHistoryStats={historyAdSourceStats}
                chartId={`seat-ad-sources-snapshots-24-hour-performance-chart-snapshot-${snapshotTimestamp}`}
            />
        );
    }, [data, isLoading, snapshotTimestamp, mapMultipleAdSourceAdStats]);

    return (
        <Card
            size="small"
            styles={{
                header: {
                    height: "40px",
                },
            }}
            title={
                <Flex align="center" justify="center" gap="small">
                    <Typography.Title level={5} style={{ flex: 1, height: "40px" }}>
                        {moment
                            .unix(+snapshotTimestamp / 1000)
                            .subtract(1, "day")
                            .format(YEAR_MONTH_DAY_HOUR_MINUTE_AMPM)}
                        - {moment.unix(+snapshotTimestamp / 1000).format(YEAR_MONTH_DAY_HOUR_MINUTE_AMPM)}
                    </Typography.Title>
                    {/* <Button size="small">Download</Button> */}
                    <Button size="small" onClick={handleClose} icon={<CloseOutlined />} />
                </Flex>
            }
        >
            {graph}
        </Card>
    );
};
