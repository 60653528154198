import { FC } from "react";
import { Form } from "antd";
import {
    AFFILIATE_COST_MODEL,
    AFFILIATE_COST_VALUE_FIXED,
    AFFILIATE_COST_VALUE_PERCENT,
} from "@app/features/inventory/constants";
import { FormItemProps } from "antd/es/form";
import { LabeledValue } from "antd/lib/select";
import { useSeatAuthContext } from "@app/core/auth";
import {
    MAX_FIXED_CPM_VALUE,
    MAX_REVSHARE_PERCENTAGE_COST_VALUE,
} from "@app/features/inventory/HierarchyForms/constants";
import { currency as currencyUtils } from "@rubicon/utils";
import { LimitInput } from "@app/features/inventory/components/Fields";
import { REVSHARE_PERCENTAGE_COST_MODEL } from "@app/features/inventory/InventorySeat/constants";

const { useWatch, useFormInstance } = Form;

export const AffiliateCostValue: FC<FormItemProps> = (props) => {
    const form = useFormInstance();
    const { context } = useSeatAuthContext();
    const affiliateCostModel: LabeledValue = useWatch(AFFILIATE_COST_MODEL);
    const isRevsharePercentageCostModel: boolean = affiliateCostModel?.value === REVSHARE_PERCENTAGE_COST_MODEL.value;

    const onChange = (): Promise<void> => form.validateFields([AFFILIATE_COST_MODEL]);

    const currencyInfo = currencyUtils.getCurrencyInfo((context?.floorCurrency?.code as string) || "USD");

    const suffix: string = isRevsharePercentageCostModel ? "%" : (context?.floorCurrency?.code as string);
    const prefix: string | undefined = isRevsharePercentageCostModel ? undefined : currencyInfo?.symbol;

    const toFixed: number = isRevsharePercentageCostModel ? 2 : 3;

    const name: string = isRevsharePercentageCostModel ? AFFILIATE_COST_VALUE_PERCENT : AFFILIATE_COST_VALUE_FIXED;

    const placeholder: string = isRevsharePercentageCostModel
        ? "Enter Revshare Percentage value"
        : "Enter Fixed CPM value";

    const label: string = "Affiliate Cost Value";

    return (
        <Form.Item
            rules={[
                {
                    validator(_, value) {
                        if (!value) return Promise.resolve();
                        if (isRevsharePercentageCostModel && value > MAX_REVSHARE_PERCENTAGE_COST_VALUE)
                            return Promise.reject(
                                new Error(
                                    `Revshare Percentage Affiliate Cost Value can not be more than ${MAX_REVSHARE_PERCENTAGE_COST_VALUE} %`
                                )
                            );
                        if (!isRevsharePercentageCostModel && BigInt(value) > MAX_FIXED_CPM_VALUE)
                            return Promise.reject(
                                new Error(`Fixed CPM Affiliate Cost Value can not be more than ${MAX_FIXED_CPM_VALUE}`)
                            );
                        return Promise.resolve();
                    },
                },
            ]}
            name={name}
            label={label}
            {...props}
        >
            <LimitInput
                prefix={prefix}
                suffix={suffix}
                toFixed={toFixed}
                onChange={onChange}
                style={{ width: "100%" }}
                placeholder={placeholder}
            />
        </Form.Item>
    );
};
