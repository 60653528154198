import { LabeledValue } from "antd/es/select";
import { useAppDispatch, useAppSelector } from "@app/core/store";
import { CREATE_DEAL_FORM_ITEMS_NAME } from "@app/features/deals/constants";
import { dealFormSelectFieldChange } from "@app/features/deals/DealForm/reducer";

const { AD_SOURCE_BLOCKED_INDUSTRIES, AD_SOURCE_INDUSTRIES_MODE, AD_SOURCE_MODE } = CREATE_DEAL_FORM_ITEMS_NAME;

interface UseAdSourceBlockedIndustriesField {
    value: LabeledValue[] | null;
    advertiserDomainModeId: number | null;
    industriesModeId: number | null;
    onChange: (blockedIndustries: LabeledValue[]) => void;
}

export const useAdSourceBlockedIndustriesField = (): UseAdSourceBlockedIndustriesField => {
    const dispatch = useAppDispatch();

    const value = useAppSelector((state) => state.dealForm.values[AD_SOURCE_BLOCKED_INDUSTRIES]);
    const advertiserDomainModeId = useAppSelector((state) => state.dealForm.values[AD_SOURCE_MODE]);
    const industriesModeId = useAppSelector((state) => state.dealForm.values[AD_SOURCE_INDUSTRIES_MODE]);

    const onChange = (blockedIndustries: LabeledValue[]) => {
        dispatch(dealFormSelectFieldChange({ field: AD_SOURCE_BLOCKED_INDUSTRIES, value: blockedIndustries }));
    };

    return {
        value,
        advertiserDomainModeId,
        industriesModeId,
        onChange,
    };
};
