import { useCallback } from "react";
import cloneDeep from "lodash.clonedeep";
import { Currency, TimeZone, useGetCurrenciesConversionsQuery } from "@app/core/services";
import { convertLocalDateToLocalDate, parseDateStringFromApi } from "@app/core/utils";
import {
    SyncedFields,
    convertCurrencyRoundedDownToNearestCent,
    filterUndefinedSyncedFields,
    hasDefinedSyncedFields,
    notifySyncedFieldsSuccess,
    shouldSyncCpmRate,
    shouldSyncCurrency,
    shouldSyncEndDate,
    shouldSyncGroupCpmRate,
    shouldSyncGroupEndDate,
    shouldSyncGroupStartDate,
    shouldSyncImpressions,
    shouldSyncStartDate,
    shouldSyncStatus,
    shouldSyncTimeZone,
    useIsAdSourceOutOfSync,
    useMapStatuses,
    useSyncedFieldsConsumer,
} from "@app/features/syncedFields";
import { TargetingFormKeys } from "@app/features/targeting/constants";
import { useTargetingForm } from "@app/features/targeting/useTargetingForm";
import { DealInForm, DemandConfigurationForm } from "..";
import { AD_SOURCE_FIELDS, AdSourceTypeIds } from "../../constants";
import { MarketplaceInfoDrawerMode } from "../useMarketplaceInfoWidget";
import { useSyncRelatedFormData } from "./useSyncRelatedFormData";
import { useIsDealUsedInOtherAdSources } from "./useIsDealUsedInOtherAdSources";

interface UseSyncDealToAdSource {
    syncDealFieldsToAdSource: (
        deal: DealInForm | null, // only needed for group sync on create or copy
        dealFormMode: MarketplaceInfoDrawerMode,
        postDeleteSyncedFields?: SyncedFields
    ) => void;
    onSaveUpdateMarketplaceInfo: (deal: DealInForm) => void;
    onSubmitUpdateMarketplaceInfo: (deal: DealInForm, demand: DemandConfigurationForm) => void;
    consumeAllSyncedFields: () => void;
}

export const useAdSourceSyncDealToAdSource = (dealId: number | undefined): UseSyncDealToAdSource => {
    const { adSourceForm, adSourceId, isAdSourceFormInEditMode, readOnlyAdSourceFields } = useSyncRelatedFormData();
    const { consumeAllSyncedFields } = useSyncedFieldsConsumer();
    const { setIsAdSourceOutOfSync } = useIsAdSourceOutOfSync();
    const { mapDealStatusToAdSourceStatus } = useMapStatuses();
    const { targetingPayloadsByFormKey, targetingModePayloadsByFormKey, loadValidationTargeting } = useTargetingForm();
    const { data: currencyConversions } = useGetCurrenciesConversionsQuery();
    const isDealUsedInOtherAdSources = useIsDealUsedInOtherAdSources(adSourceId, dealId);

    const syncDealFieldsToAdSource = useCallback(
        (deal: DealInForm | null, dealFormMode: MarketplaceInfoDrawerMode, postDeleteSyncedFields?: SyncedFields) => {
            const adSource = adSourceForm.getFieldsValue();
            const adSourceTypeId = adSource[AD_SOURCE_FIELDS.TYPE.name]?.id;
            const marketplaceInfoList = adSource[AD_SOURCE_FIELDS.DEMAND.name];
            const dealCount = marketplaceInfoList?.length || 0;
            const isCreate = dealFormMode.name === "create";
            const isEdit = dealFormMode.name === "edit";
            const isDelete = Boolean(postDeleteSyncedFields);

            const isAttachingFirstDeal = dealCount === 0 && isCreate;
            const isEditingOnlyDeal = dealCount === 1 && isEdit;
            const isDeleteToSingleDeal = dealCount === 1 && isDelete;
            const isOneToOne = isAttachingFirstDeal || isEditingOnlyDeal || isDeleteToSingleDeal;

            const isAttachingMultiDeal = dealCount >= 1 && isCreate;
            const isEditingMultiDeal = dealCount > 1 && isEdit;
            const isOneToMany = isAttachingMultiDeal || isEditingMultiDeal;

            const isMultiAdSourceAuctionPriceDeal =
                isDealUsedInOtherAdSources && adSourceTypeId === AdSourceTypeIds.AUCTION_PRICE;

            if (!isOneToOne && !isOneToMany) {
                return;
            }

            const consumedSyncedFields = consumeAllSyncedFields();
            const syncedFields =
                isDeleteToSingleDeal && postDeleteSyncedFields ? postDeleteSyncedFields : consumedSyncedFields;

            const adSourceStartDate = adSource[AD_SOURCE_FIELDS.START_DATE.name];
            const adSourceEndDate = adSource[AD_SOURCE_FIELDS.END_DATE.name];
            const adSourceTimeZone = adSource[AD_SOURCE_FIELDS.TIME_ZONE.name];
            const adSourceFields: SyncedFields = {
                startDate: parseDateStringFromApi(adSourceStartDate, adSourceTimeZone?.code),
                endDate: adSourceEndDate ? parseDateStringFromApi(adSourceEndDate, adSourceTimeZone?.code) : null,
                timeZone: adSource[AD_SOURCE_FIELDS.TIME_ZONE.name] as TimeZone,
                cpmRate: adSource[AD_SOURCE_FIELDS.FIXED_CPM.name]
                    ? String(adSource[AD_SOURCE_FIELDS.FIXED_CPM.name])
                    : null,
                status: adSource[AD_SOURCE_FIELDS.STATUS.name],
                currency: adSource[AD_SOURCE_FIELDS.CURRENCY.name] as Currency,
                impressions: adSource[AD_SOURCE_FIELDS.TOTAL_IMPRESSIONS.name],
            };
            const adSourceFloorType = adSource[AD_SOURCE_FIELDS.FLOOR_TYPE.name];

            let validSyncedFields: SyncedFields = {};

            if (isOneToOne && !isMultiAdSourceAuctionPriceDeal) {
                validSyncedFields = {
                    startDate: shouldSyncStartDate(
                        isOneToOne,
                        isAdSourceFormInEditMode,
                        syncedFields.startDate,
                        adSourceTypeId,
                        readOnlyAdSourceFields,
                        adSourceFields.startDate
                    )
                        ? syncedFields.startDate
                        : undefined,
                    endDate: shouldSyncEndDate(isOneToOne, syncedFields.endDate, adSourceTypeId, adSourceFields.endDate)
                        ? syncedFields.endDate
                        : undefined,
                    timeZone: shouldSyncTimeZone(
                        isOneToOne,
                        isAdSourceFormInEditMode,
                        syncedFields.timeZone,
                        adSourceTypeId,
                        readOnlyAdSourceFields,
                        adSourceFields.timeZone
                    )
                        ? syncedFields.timeZone
                        : undefined,
                    cpmRate: shouldSyncCpmRate(
                        isOneToOne,
                        syncedFields.cpmRate,
                        adSourceTypeId,
                        adSourceFloorType?.id,
                        adSourceFields.cpmRate
                    )
                        ? syncedFields.cpmRate
                        : undefined,
                    status:
                        syncedFields.status && shouldSyncStatus(isOneToOne, syncedFields.status, adSourceFields.status)
                            ? mapDealStatusToAdSourceStatus(syncedFields.status.id)
                            : undefined,
                    currency: shouldSyncCurrency(
                        isOneToOne,
                        syncedFields.currency,
                        adSourceTypeId,
                        adSourceFields.currency
                    )
                        ? syncedFields.currency
                        : undefined,
                    impressions: shouldSyncImpressions(
                        isOneToOne,
                        syncedFields.impressions,
                        adSourceTypeId,
                        adSourceFields.impressions
                    )
                        ? syncedFields.impressions
                        : undefined,
                };
            }

            if (isOneToMany && deal && !isDelete) {
                validSyncedFields = {
                    startDate: shouldSyncGroupStartDate(
                        isOneToMany,
                        adSourceTypeId,
                        syncedFields.startDate,
                        syncedFields.timeZone?.code,
                        deal.startTime,
                        deal.timeZone?.code,
                        adSourceFields.startDate,
                        adSourceFields.timeZone?.code
                    )
                        ? convertLocalDateToLocalDate(
                              syncedFields.startDate || deal.startTime,
                              syncedFields.timeZone?.code || deal.timeZone?.code,
                              adSourceFields.timeZone?.code
                          )
                        : undefined,
                    endDate: shouldSyncGroupEndDate(
                        isOneToMany,
                        adSourceTypeId,
                        syncedFields.endDate,
                        syncedFields.timeZone?.code,
                        deal.endTime,
                        deal.timeZone?.code,
                        adSourceFields.endDate,
                        adSourceFields.timeZone?.code
                    )
                        ? convertLocalDateToLocalDate(
                              syncedFields.endDate || deal.endTime,
                              syncedFields.timeZone?.code || deal.timeZone?.code,
                              adSourceFields.timeZone?.code
                          )
                        : undefined,
                    cpmRate: shouldSyncGroupCpmRate(
                        isOneToMany,
                        adSourceTypeId,
                        deal.priceModel?.id,
                        syncedFields.cpmRate,
                        syncedFields.currency?.id,
                        deal.rate,
                        deal.currencyType?.id,
                        adSourceFields.cpmRate,
                        adSourceFields.currency?.id,
                        currencyConversions
                    )
                        ? convertCurrencyRoundedDownToNearestCent(
                              syncedFields.cpmRate || deal.rate,
                              syncedFields.currency?.id || deal.currencyType?.id,
                              adSourceFields.currency?.id,
                              currencyConversions
                          )
                        : undefined,
                };
            }

            validSyncedFields = filterUndefinedSyncedFields(validSyncedFields);
            if (!hasDefinedSyncedFields(validSyncedFields)) {
                return;
            }

            const updatedAdSourceFormFields = Object.entries({
                [AD_SOURCE_FIELDS.START_DATE.name]: validSyncedFields.startDate,
                [AD_SOURCE_FIELDS.END_DATE.name]: validSyncedFields.endDate,
                [AD_SOURCE_FIELDS.TIME_ZONE.name]: validSyncedFields.timeZone,
                [AD_SOURCE_FIELDS.STATUS.name]: validSyncedFields.status,
                [AD_SOURCE_FIELDS.TOTAL_IMPRESSIONS.name]: validSyncedFields.impressions,
                [AD_SOURCE_FIELDS.FIXED_CPM.name]: validSyncedFields.cpmRate,
                [AD_SOURCE_FIELDS.CURRENCY.name]: validSyncedFields.currency,
            })
                .filter((adSourceFormFieldEntry) => adSourceFormFieldEntry[1] !== undefined)
                .reduce(
                    (adSourceFormFields, updateEntry) => ({
                        ...adSourceFormFields,
                        [updateEntry[0]]: updateEntry[1],
                    }),
                    {}
                );

            const hasFormUpdates = Boolean(Object.keys(updatedAdSourceFormFields).length);
            if (hasFormUpdates) {
                adSourceForm.setFieldsValue({
                    ...updatedAdSourceFormFields,
                });
                const didShowSyncNotifications = notifySyncedFieldsSuccess(
                    validSyncedFields,
                    adSourceTypeId,
                    adSourceFloorType?.id
                );
                if (didShowSyncNotifications) {
                    setIsAdSourceOutOfSync(true);
                }
            }
        },
        [
            adSourceForm,
            currencyConversions,
            isAdSourceFormInEditMode,
            isDealUsedInOtherAdSources,
            readOnlyAdSourceFields,
            consumeAllSyncedFields,
            mapDealStatusToAdSourceStatus,
            setIsAdSourceOutOfSync,
        ]
    );

    const onSaveUpdateMarketplaceInfo = useCallback(
        (deal: DealInForm) => {
            const adSource = adSourceForm.getFieldsValue();
            const marketplaceInfoList = adSource[AD_SOURCE_FIELDS.DEMAND.name] || [];
            const dealIndex = marketplaceInfoList.findIndex((mpi) => mpi.deal.id === deal.id);

            if (dealIndex === -1) {
                // only update marketplace on save if editing a deal that is already attached to the ad source
                return;
            }

            const updatedMarketplaceInfoList = cloneDeep(marketplaceInfoList);
            const marketplaceInfo = updatedMarketplaceInfoList[dealIndex];

            updatedMarketplaceInfoList[dealIndex] = {
                ...marketplaceInfo,
                deal: {
                    ...marketplaceInfo.deal,
                    ...deal,
                },
            };
            adSourceForm.setFieldValue(AD_SOURCE_FIELDS.DEMAND.name, updatedMarketplaceInfoList);
        },
        [adSourceForm]
    );

    const onSubmitUpdateMarketplaceInfo = useCallback(
        (deal: DealInForm, demand: DemandConfigurationForm) => {
            const adSource = adSourceForm.getFieldsValue();
            const marketplaceInfoList = adSource[AD_SOURCE_FIELDS.DEMAND.name] || [];
            const dealIndex = marketplaceInfoList.findIndex((mpi) => mpi.deal.id === deal.id);
            const isLegacyDeal = deal.entityType === "Deal";
            const newMarketplaceInfo = {
                ...demand,
                deal: {
                    ...deal,
                    startTime: isLegacyDeal ? undefined : parseDateStringFromApi(deal.startTime, deal.timeZone?.code),
                    endTime: isLegacyDeal ? undefined : parseDateStringFromApi(deal.endTime, deal.timeZone?.code),
                },
                enforcement: demand.enforcement,
                currencyType: demand.currencyType?.id ? demand.currencyType : null,
                freqCappingType: demand.freqCappingType,
                network: demand.network,
                buyerSeats: demand.buyerSeats,
                dealFreqCappings: demand.dealFreqCappings?.map((cappings) => {
                    const { id: _, ...copyCappingsNoId } = { ...cappings };
                    return copyCappingsNoId;
                }),
                targeting: targetingPayloadsByFormKey[TargetingFormKeys.AdSourceAdditionalTargeting],
                targetingMode: targetingModePayloadsByFormKey[TargetingFormKeys.AdSourceAdditionalTargeting],
                "@class":
                    deal.entityType === "BuyerDeal"
                        ? "com.tremorvideo.ssp.platform.model.MarketPlaceInfoBuyerDeal"
                        : deal.entityType === "DemandDeal"
                        ? "com.tremorvideo.ssp.platform.model.MarketPlaceInfoDemandDeal"
                        : undefined,
                startDate: isLegacyDeal ? demand.startDate : undefined,
                endDate: isLegacyDeal ? demand.endDate || null : undefined,
            };

            const updatedMarketplaceInfoList = cloneDeep(marketplaceInfoList);
            if (dealIndex === -1) {
                updatedMarketplaceInfoList.push(newMarketplaceInfo);
            } else {
                updatedMarketplaceInfoList[dealIndex] = newMarketplaceInfo;
            }

            adSourceForm.setFieldValue(AD_SOURCE_FIELDS.DEMAND.name, updatedMarketplaceInfoList);

            loadValidationTargeting(
                TargetingFormKeys.AdSource,
                updatedMarketplaceInfoList.map((mpInfo) => mpInfo.targeting).flat()
            );
        },
        [adSourceForm, targetingPayloadsByFormKey, targetingModePayloadsByFormKey, loadValidationTargeting]
    );

    return {
        syncDealFieldsToAdSource,
        onSaveUpdateMarketplaceInfo,
        onSubmitUpdateMarketplaceInfo,
        consumeAllSyncedFields,
    };
};
