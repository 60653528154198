import { FC } from "react";
import { SelectWithValue } from "@app/features/seatAdSources/SeatAdSourcesForm/components";
import { TagRender } from "@app/core/components/TagRender";
import { Audience, useGetSeatAudiencesQuery } from "@app/core/services/console";
import { TargetingDimensionTypeLabels } from "../../constants";
import { isAddDisabled } from "../../helpers";
import { AudiencesTargetingDimension, UsedDimensions } from "../../types";
import { MoreMenu } from "../MoreMenu";
import { DimensionRow } from "./DimensionRow";
import { useParams } from "react-router-dom";
import { DefaultOptionType } from "antd/es/select";

interface Props {
    dimension: AudiencesTargetingDimension;
    onChangeDimensionValues: (value: Audience | null) => void;
    onClickDelete: () => void;
    onClickDownload: () => void;
    onClickDuplicate: () => void;
    usedDimensions: UsedDimensions;
    usedDimensionsAll: UsedDimensions[];
}

const isCaseInsensitiveMatch = (input: string, value: React.ReactNode | undefined) => {
    return Boolean(value) && typeof value === "string" && value.toLowerCase().includes(input.toLowerCase());
};

const filterOption = (input: string, option: DefaultOptionType | undefined) => {
    return isCaseInsensitiveMatch(input, option?.label) || isCaseInsensitiveMatch(input, option?.value);
};

export const AudiencesDimensionRow: FC<Props> = ({
    dimension,
    onChangeDimensionValues,
    onClickDelete,
    onClickDownload,
    onClickDuplicate,
    usedDimensions,
    usedDimensionsAll,
}) => {
    const { seatId } = useParams<{ seatId: string }>();
    const { data, isFetching } = useGetSeatAudiencesQuery(Number(seatId));
    return (
        <DimensionRow
            label={TargetingDimensionTypeLabels.Audiences}
            condition="Include"
            value={
                <SelectWithValue<Audience>
                    fieldAsLabel={(v) => `${v.name} ($${v.minPriceUSD} - $${v.maxPriceUSD})`}
                    fieldAsValue={["id"]}
                    labelInValue
                    loading={isFetching}
                    options={data || []}
                    onClear={() => onChangeDimensionValues(null)}
                    onChange={(v) => {
                        if (v && Array.isArray(v) && v.length) {
                            onChangeDimensionValues(v[0]);
                        } else if (v && !Array.isArray(v)) {
                            onChangeDimensionValues(v);
                        } else {
                            onChangeDimensionValues(null);
                        }
                    }}
                    placeholder="Select Audience"
                    style={{ width: "100%" }}
                    tagRender={TagRender}
                    value={dimension.values}
                    showSearch
                    filterOption={filterOption}
                />
            }
            menu={
                <MoreMenu
                    onClickDelete={onClickDelete}
                    onClickDownload={onClickDownload}
                    onClickDuplicate={onClickDuplicate}
                    isDuplicateDisabled={isAddDisabled(dimension.type, usedDimensions, usedDimensionsAll)}
                />
            }
        />
    );
};
