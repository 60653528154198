import { Currency, MarketplaceInfo, useGetCurrenciesQuery } from "@app/core/services";
import { IS_GOOGLE_DEAL } from "@app/features/deals/DealForm/useDealForm";
import { useMarketplaceInfoWidget } from "@app/features/seatAdSources/SeatAdSourcesForm/useMarketplaceInfoWidget";
import { useSyncedFieldsProducer } from "@app/features/syncedFields";
import { Form } from "antd";
import { useCallback } from "react";

export const useCurrencySelect = () => {
    const { dealObjectMode } = useMarketplaceInfoWidget();
    const isReadonly = dealObjectMode === "view";
    const { data: currencies, isFetching: isLoading } = useGetCurrenciesQuery(undefined, { skip: isReadonly });
    const { updateSyncedFields } = useSyncedFieldsProducer();
    const dsp = Form.useWatch<MarketplaceInfo["deal"]["network"]>(["dealUnderEdit", "network"])?.id;
    const disabled = dsp === IS_GOOGLE_DEAL && dealObjectMode === "edit";

    const onChange = useCallback(
        (value: Currency) => {
            updateSyncedFields({ currency: value });
        },
        [updateSyncedFields]
    );

    return { currencies, isLoading, isReadonly, onChange, disabled };
};
