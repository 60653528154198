import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { notification } from "antd";
import { ROUTES } from "@app/core/routing";
import { skipToken } from "@reduxjs/toolkit/query";
import { Seat, useGetSeatSelfQuery } from "@app/core/services/console/seats";

const defaultErrorRouteFormatter = () => ROUTES.LOGIN_FORM_CONTEXT_STEP;

export const useEntityRedirect = <T, K>(
    primaryKey: K | undefined,
    useGetQuery: (primaryKey: K | typeof skipToken) => { data?: T; error?: unknown; isSuccess: boolean },
    entityLabel: string,
    routeFormatter: (data: T, context: Seat | null | undefined) => string,
    getSeatId: (data: T) => string | number | undefined
) => {
    const { data, error, isSuccess } = useGetQuery(primaryKey || skipToken);
    const history = useHistory();
    const seatId = data ? getSeatId(data) : null;
    const { data: seatSelf, error: seatSelfError } = useGetSeatSelfQuery(seatId || 0, { skip: !seatId });

    useEffect(() => {
        if (seatSelfError) {
            notification.error({ message: "Error Fetching Seat" });
            history.push(defaultErrorRouteFormatter());
        }
        if (error || (isSuccess && !data)) {
            notification.error({ message: `Error Fetching ${entityLabel}` });
            history.push(defaultErrorRouteFormatter());
        }
        if (data) {
            history.push(routeFormatter(data, seatSelf?.seat));
        }
    }, [history, data, error, isSuccess, entityLabel, routeFormatter, seatSelf, seatSelfError]);
};
