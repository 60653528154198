import React, { useCallback, useMemo, useState } from "react";
import { Flex, DatePicker, Checkbox, Card, Tag, Typography, Button, CheckboxProps, DatePickerProps } from "antd";
import { gray8, gray7 } from "@rubicon/antd-components";
import { CreateSnapshotModal } from "./CreateSnapshotModal";
import { SnapshotsTimeline } from "./SnapshotsDrawer/SnapshotsTimeline";
import { StatsSnapshotCard } from "./StatsSnapshotViewer/StatsSnapshotCard";
import { useCreateSnapshotModal } from "./CreateSnapshotModal/useCreateSnapshotModal";
import { AdSourceStatsSnapshot } from "@app/core/services/adStats/statsSnapshots";
import { SeatAdSources24HourPerformanceChart } from "@app/features/seatAdSources/seatAdSourcesCharts";
import { AdSourceStat } from "@app/features/seatAdSources/data";

const SELECTED_SNAPSHOTS_LIMIT = 5;

interface Props {
    data: AdSourceStatsSnapshot[];
    adSourceId: number;
    adSourceName: string;
    adStatHistory: AdSourceStat[];
    onlyMySnapshots: boolean;
    onlyMySnapshotsChangeHandler: (v: boolean) => void;
}

export const Snapshots = ({
    data,
    adSourceId,
    adSourceName,
    adStatHistory,
    onlyMySnapshots,
    onlyMySnapshotsChangeHandler,
}: Props) => {
    const [selectedSnapshots, setSelectedSnapshots] = useState<number[]>([]);
    const [selectedYear, setSelectedYear] = useState<number | null>(null);
    const { open, handleOpenModal, handleCloseModal, onSubmit, isLoading, form } = useCreateSnapshotModal(adSourceId);

    const handleClick = useCallback(
        (id: number) => {
            if (selectedSnapshots.includes(id)) {
                setSelectedSnapshots((prev) => prev.filter((v) => v !== id));
            } else if (SELECTED_SNAPSHOTS_LIMIT > selectedSnapshots.length) {
                setSelectedSnapshots((prev) => [...prev, id]);
            }
        },
        [selectedSnapshots]
    );

    const handleCloseSnapshot = (snapshotTimestamp) => {
        setSelectedSnapshots((prev) => [...prev].filter((snapshot) => snapshot !== snapshotTimestamp));
    };

    const onChange: CheckboxProps["onChange"] = (e) => {
        setSelectedSnapshots([]);
        onlyMySnapshotsChangeHandler(e.target.checked);
    };

    const onDatePickerChange: DatePickerProps["onChange"] = (date) => {
        setSelectedYear(date ? date.year() : null);
    };

    const graph = useMemo(() => {
        if (!adStatHistory) {
            return null;
        }
        return (
            <SeatAdSources24HourPerformanceChart
                adSourceHistoryStats={adStatHistory}
                chartId="seat-ad-sources-snapshots-24-hour-performance-chart"
            />
        );
    }, [adStatHistory]);

    const statsSnapshotCards = useMemo(
        () =>
            selectedSnapshots.map((snapshotTimestamp) => (
                <StatsSnapshotCard
                    key={snapshotTimestamp}
                    adSourceId={adSourceId}
                    snapshotTimestamp={snapshotTimestamp}
                    onClose={handleCloseSnapshot}
                />
            )),
        [selectedSnapshots, adSourceId]
    );

    return (
        <Flex gap="large" style={{ height: "100%" }}>
            <Flex vertical gap="large" style={{ padding: "8px 16px", width: "200px" }}>
                <DatePicker placeholder="All Time" onChange={onDatePickerChange} picker="year" />
                <Checkbox checked={onlyMySnapshots} onChange={onChange}>
                    Only my snapshots
                </Checkbox>
                <SnapshotsTimeline
                    data={data}
                    selectedYear={selectedYear}
                    selectedSnapshots={selectedSnapshots}
                    handleClick={handleClick}
                    selectedSnapshotsLimit={SELECTED_SNAPSHOTS_LIMIT}
                />
            </Flex>
            <Card style={{ borderTop: 0, borderRight: 0, flex: 1, borderBottom: 0 }}>
                <Flex vertical style={{ width: "100%" }}>
                    <Card
                        size="small"
                        styles={{
                            header: {
                                height: "40px",
                                background: gray8,
                            },
                        }}
                        title={
                            <Flex align="center" gap="small" style={{ height: "100%" }}>
                                <Tag color={gray7} style={{ borderRadius: "10px" }}>
                                    Ad Source
                                </Tag>
                                <Typography.Text style={{ flex: 1, color: "#fff" }} strong>
                                    {adSourceName}: Current Last 24 Hrs Performance
                                </Typography.Text>
                                {/* <Button size="small">Download</Button> */}
                                <CreateSnapshotModal
                                    open={open}
                                    handleCloseModal={handleCloseModal}
                                    onSubmit={onSubmit}
                                    isLoading={isLoading}
                                    form={form}
                                />
                                <Button size="small" onClick={handleOpenModal}>
                                    Take Snapshot
                                </Button>
                            </Flex>
                        }
                    >
                        {graph}
                    </Card>
                    <Typography.Title level={4}>
                        Snapshots{" "}
                        <Typography.Text>You can display up to {SELECTED_SNAPSHOTS_LIMIT} snapshots</Typography.Text>
                    </Typography.Title>
                    <Flex vertical gap="large">
                        {!selectedSnapshots.length && (
                            <Card size="small">
                                <Typography.Text>Select a snapshot to display</Typography.Text>
                            </Card>
                        )}
                        {statsSnapshotCards}
                    </Flex>
                </Flex>
            </Card>
        </Flex>
    );
};
