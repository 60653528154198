import { FC } from "react";
import { cardBodyStyle } from "@app/features/deals/DealForm";
import { Card, Col, Row } from "antd";
import { GUTTER } from "@app/features/deals/constants";
import {
    AdSourceBundleIdField,
    AdSourceBundleIdOverrideField,
    AdSourceContentField,
    AdSourceDomainNameField,
    AdSourceDomainNameOverrideField,
    AdSourceFullIpAddressField,
    AdSourceIncludedExtendedIdsField,
    AdSourceReferUrlField,
    AdSourceStoreUrlField,
    AdSourceSupplyNameAppNameField,
    AdSourceSupplyPageContentUrlField,
    AdSourceUserIdIfaField,
    AdSourceExtendedUserIdList,
    AdSourceAllowAudienceLockField,
    AdSourceOztamCoViewingField,
} from "@app/features/deals/DealForm/DealFormCreateAdSource/DealFormCreateAdSourceSections/AdSourceTransparencySection/Fields";

interface AdSourceTransparencySectionProps {
    hasDomainNameOverride: boolean;
    hasBundleIdOverride: boolean;
    hasExtendedUserId: boolean;
    hasAdSourceAudienceLockEnabled: boolean;
    hasOztamEnabled: boolean;
}

export const AdSourceTransparencySection: FC<AdSourceTransparencySectionProps> = ({
    hasBundleIdOverride,
    hasDomainNameOverride,
    hasExtendedUserId,
    hasAdSourceAudienceLockEnabled,
    hasOztamEnabled,
}) => {
    return (
        <Card bordered={false} styles={{ body: cardBodyStyle }}>
            <Row gutter={GUTTER}>
                <Col span={24}>
                    <AdSourceSupplyNameAppNameField />
                </Col>
                <Col span={24}>
                    <AdSourceDomainNameField />
                </Col>
                {hasDomainNameOverride && (
                    <Col span={24}>
                        <AdSourceDomainNameOverrideField />
                    </Col>
                )}
                <Col span={24}>
                    <AdSourceSupplyPageContentUrlField />
                </Col>
                <Col span={24}>
                    <AdSourceReferUrlField />
                </Col>
                <Col span={24}>
                    <AdSourceFullIpAddressField />
                </Col>
                <Col span={24}>
                    <AdSourceBundleIdField />
                </Col>
                {hasBundleIdOverride && (
                    <Col span={24}>
                        <AdSourceBundleIdOverrideField />
                    </Col>
                )}
                <Col span={24}>
                    <AdSourceStoreUrlField />
                </Col>
                <Col span={24}>
                    <AdSourceUserIdIfaField />
                </Col>
                <Col span={24}>
                    <AdSourceContentField />
                </Col>
                <Col span={24}>
                    <AdSourceIncludedExtendedIdsField />
                </Col>
                {hasExtendedUserId && (
                    <Col span={24}>
                        <AdSourceExtendedUserIdList />
                    </Col>
                )}
                {hasOztamEnabled && (
                    <Col span={24}>
                        <AdSourceOztamCoViewingField />
                    </Col>
                )}
                {hasAdSourceAudienceLockEnabled && (
                    <Col span={24}>
                        <AdSourceAllowAudienceLockField />
                    </Col>
                )}
            </Row>
        </Card>
    );
};
