import { Form } from "antd";
import { FORM_FIELDS } from "./constants";
import { OverrideMode } from "./types";

interface UseAtLeastOneOverrideRule {
    validator: () => Promise<void>;
}

export const useAtLeastOneOverrideRule = (): UseAtLeastOneOverrideRule => {
    const form = Form.useFormInstance();
    const advertiserDomainOverride = Form.useWatch<string | null>(FORM_FIELDS.ADVERTISER_DOMAIN_OVERRIDE.name, form);
    const industryOverrideMode = Form.useWatch<number>(FORM_FIELDS.INDUSTRY_OVERRIDE_MODE.name, form);
    const taxonomyOverrideMode = Form.useWatch<number>(FORM_FIELDS.TAXONOMY_OVERRIDE_MODE.name, form);

    const atLeastOneOverrideEnabled =
        advertiserDomainOverride ||
        [OverrideMode.ADD, OverrideMode.OVERRIDE].includes(industryOverrideMode) ||
        [OverrideMode.ADD, OverrideMode.OVERRIDE].includes(taxonomyOverrideMode);

    return {
        validator: () =>
            atLeastOneOverrideEnabled ? Promise.resolve() : Promise.reject("At least one override must be enabled"),
    };
};
