import { useHistory, useParams } from "react-router-dom";
import { ROUTE_FORMATTERS } from "@app/core/routing";

interface UseMappingExceptionsCreatePage {
    onBack: () => void;
}

export const useMappingExceptionsCreatePage = (): UseMappingExceptionsCreatePage => {
    const history = useHistory();
    const { seatId } = useParams<{ seatId: string }>();

    const onBack = () => history.push(ROUTE_FORMATTERS.SEAT_CONTROLS_BRAND_SAFETY_MAPPING_EXCEPTIONS_LIST_PAGE(seatId));

    return {
        onBack,
    };
};
