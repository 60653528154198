import React, { FC } from "react";
import { useParams } from "react-router-dom";
import { useUserAccess } from "@app/core/auth";
import { ROUTE_FORMATTERS } from "@app/core/routing";
import { StyledReactLink } from "@app/core/components";
import { Alert, Dropdown, Typography, MenuProps, Skeleton } from "antd";
import { SourceSelf } from "@app/features/inventory/components/FloorsTable";
import { StructuredSeatNukesStatus, useGetStructuredNukesQuery } from "@app/core/services";
import { InventoryDetailsDrawerType } from "@app/features/inventory/DetailsDrawer/reducer";

interface SupplyTrafficAlertBannerProps {
    sourceSelf: SourceSelf;
}

interface AlertBannerMessageProps {
    data: StructuredSeatNukesStatus[];
    sourceSelf: SourceSelf;
    seatId: number;
}

const AlertBannerMessage: FC<AlertBannerMessageProps> = ({ data, sourceSelf, seatId }) => {
    const items: MenuProps["items"] = data.map(
        ({ publisher_id, brand_id, supply_id, supply_name, publisher_name, brand_name }, index) => ({
            key: index,
            label: (
                <StyledReactLink
                    to={
                        ROUTE_FORMATTERS.SEAT_INVENTORY_HEALTH_DETAILS_UNIT(
                            seatId,
                            InventoryDetailsDrawerType.SUPPLY,
                            publisher_id,
                            brand_id,
                            supply_id
                        ) as string
                    }
                >
                    {`${publisher_name} > ${brand_name} > ${supply_name}`}
                </StyledReactLink>
            ),
        })
    );

    switch (sourceSelf) {
        case "Publisher":
            if (data.length === 1) {
                return (
                    <>
                        <Typography.Text>A </Typography.Text>
                        <StyledReactLink
                            to={
                                ROUTE_FORMATTERS.SEAT_INVENTORY_HEALTH_DETAILS_UNIT(
                                    seatId,
                                    InventoryDetailsDrawerType.SUPPLY,
                                    data[0].publisher_id,
                                    data[0].brand_id,
                                    data[0].supply_id
                                ) as string
                            }
                        >
                            supply entity
                        </StyledReactLink>
                        <Typography.Text> on this publisher has been blocked by Magnite Admin</Typography.Text>
                    </>
                );
            }
            return (
                <>
                    <Typography.Text>A </Typography.Text>
                    <Dropdown menu={{ items }}>
                        <a onClick={(e) => e.preventDefault()}>supply entities</a>
                    </Dropdown>
                    <Typography.Text> on this publisher has been blocked by Magnite Admin</Typography.Text>
                </>
            );
        case "Brand":
            if (data.length === 1) {
                return (
                    <>
                        <Typography.Text>A </Typography.Text>
                        <StyledReactLink
                            to={
                                ROUTE_FORMATTERS.SEAT_INVENTORY_HEALTH_DETAILS_UNIT(
                                    seatId,
                                    InventoryDetailsDrawerType.SUPPLY,
                                    data[0].publisher_id,
                                    data[0].brand_id,
                                    data[0].supply_id
                                ) as string
                            }
                        >
                            supply entity
                        </StyledReactLink>
                        <Typography.Text> on this brand has been blocked by Magnite Admin</Typography.Text>
                    </>
                );
            }
            return (
                <>
                    <Typography.Text>A </Typography.Text>
                    <Dropdown menu={{ items }}>
                        <a onClick={(e) => e.preventDefault()}>supply entities</a>
                    </Dropdown>
                    <Typography.Text> on this brand has been blocked by Magnite Admin</Typography.Text>
                </>
            );
        case "Supply":
            return <Typography.Text>This supply entity has been blocked by Magnite Admin</Typography.Text>;
        case "Ad Unit":
            return (
                <>
                    <Typography.Text>The </Typography.Text>
                    <StyledReactLink
                        to={
                            ROUTE_FORMATTERS.SEAT_INVENTORY_HEALTH_DETAILS_UNIT(
                                seatId,
                                InventoryDetailsDrawerType.SUPPLY,
                                data[0].publisher_id,
                                data[0].brand_id,
                                data[0].supply_id
                            ) as string
                        }
                    >
                        supply entity
                    </StyledReactLink>
                    <Typography.Text> for this ad unit has been blocked by Magnite Admin</Typography.Text>
                </>
            );
        default:
            if (data.length === 1) {
                return (
                    <>
                        <Typography.Text>A </Typography.Text>
                        <StyledReactLink
                            to={
                                ROUTE_FORMATTERS.SEAT_INVENTORY_HEALTH_DETAILS_UNIT(
                                    seatId,
                                    InventoryDetailsDrawerType.SUPPLY,
                                    data[0].publisher_id,
                                    data[0].brand_id,
                                    data[0].supply_id
                                ) as string
                            }
                        >
                            supply entity
                        </StyledReactLink>
                        <Typography.Text> on this seat has been blocked by Magnite Admin</Typography.Text>
                    </>
                );
            }
            return (
                <>
                    <Typography.Text>A </Typography.Text>
                    <Dropdown menu={{ items }}>
                        <a onClick={(e) => e.preventDefault()}>supply entities</a>
                    </Dropdown>
                    <Typography.Text> on this seat has been blocked by Magnite Admin</Typography.Text>
                </>
            );
    }
};

const getNukedSupplyStatus = (
    sourceSelf: SourceSelf,
    nukeStatus: StructuredSeatNukesStatus[],
    publisherId: string,
    brandId: string,
    supplyId: string,
    adUnitId: string
): boolean => {
    if (sourceSelf === "Publisher") return nukeStatus.some((item) => item.publisher_id === Number(publisherId));
    if (sourceSelf === "Brand") return nukeStatus.some((item) => item.brand_id === Number(brandId));
    if (sourceSelf === "Supply") return nukeStatus.some((item) => item.supply_id === Number(supplyId));
    if (sourceSelf === "Ad Unit") return nukeStatus.some((item) => item.ad_unit_id === Number(adUnitId));
    return !!nukeStatus.length;
};

export const SupplyTrafficAlertBanner: FC<SupplyTrafficAlertBannerProps> = ({ sourceSelf }) => {
    const { hasTremorRole, hasSeatWriteAccess } = useUserAccess();
    const { seatId, publisherId, brandId, supplyId, adUnitId } = useParams<{
        seatId: string;
        publisherId: string;
        brandId: string;
        supplyId: string;
        adUnitId: string;
    }>();

    const { data = [], isFetching } = useGetStructuredNukesQuery(seatId, {
        skip: !seatId || !hasTremorRole,
    });

    const hasNukedSupply: boolean = getNukedSupplyStatus(sourceSelf, data, publisherId, brandId, supplyId, adUnitId);

    if (!hasTremorRole || !hasSeatWriteAccess || !hasNukedSupply || !data.length) return null;

    const dataSource: StructuredSeatNukesStatus[] = data.filter((item): boolean => {
        if (sourceSelf === "Publisher") return item.publisher_id === Number(publisherId);
        if (sourceSelf === "Brand") return item.brand_id === Number(brandId);
        if (sourceSelf === "Supply") return item.supply_id === Number(supplyId);
        if (sourceSelf === "Ad Unit") return item.ad_unit_id === Number(adUnitId);
        return true;
    });

    return (
        <Alert
            showIcon
            type="warning"
            style={{ width: "100%" }}
            message={
                isFetching ? (
                    <Skeleton.Button block active size="small" style={{ height: "1.375rem" }} />
                ) : (
                    <AlertBannerMessage data={dataSource} seatId={Number(seatId)} sourceSelf={sourceSelf} />
                )
            }
        />
    );
};
