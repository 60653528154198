import { NONE } from "@app/core/components/constants";
import { AdUnit, Brand, BrandSupply, Publisher } from "@app/core/services";
import { InternalOnly } from "@app/features/inventory/components/InternalOnly";
import { GUTTER, INNER_COL_SIZES, TWO_COL_SIZES } from "@app/features/inventory/constants";
import { getDimension } from "@app/features/inventory/helpers";
import { SeeMoreUnorderedList } from "@app/features/inventory/SeeMoreUnorderedList";
import { Col, Form, Row, Tag } from "antd";
import { FC } from "react";
import { BASE_BRAND_SAFETY_LABELS, FORM_ITEM_OFFSET } from "../../constants";
import { Industries } from "@app/core/components";

const { Item } = Form;

interface Props {
    unit: Publisher | Brand | BrandSupply | AdUnit;
    hasFreqCapping?: boolean;
    hasFreqCaps?: boolean;
    hasBlockedCategories?: boolean;
    hasBlockedIndustries?: boolean;
    hasCreativeBlockingMode?: boolean;
}

export const BrandSafety: FC<Props> = ({
    unit,
    hasFreqCaps,
    hasFreqCapping,
    hasBlockedCategories,
    hasBlockedIndustries,
    hasCreativeBlockingMode,
}) => {
    return (
        <div data-sdet="unit-brand-safety">
            <Row>
                <Col {...TWO_COL_SIZES}>
                    <Row gutter={GUTTER}>
                        <Col {...INNER_COL_SIZES}>
                            <Item label={BASE_BRAND_SAFETY_LABELS.aDomainLists} className={FORM_ITEM_OFFSET}>
                                <SeeMoreUnorderedList
                                    values={unit?.seatAdvertiserDomainFilterListDefs?.map((def) => def?.name)}
                                />
                            </Item>
                        </Col>
                    </Row>
                    <Row gutter={GUTTER}>
                        <Col {...INNER_COL_SIZES}>
                            <Item label={BASE_BRAND_SAFETY_LABELS.aDomains} className={FORM_ITEM_OFFSET}>
                                <SeeMoreUnorderedList values={unit?.advertiserBlockDomains} />
                            </Item>
                        </Col>
                    </Row>
                    <Row gutter={GUTTER}>
                        <Col {...INNER_COL_SIZES}>
                            <Item label={BASE_BRAND_SAFETY_LABELS.seatList} className={FORM_ITEM_OFFSET}>
                                {unit?.buyerSeatList?.name || NONE}
                            </Item>
                        </Col>
                    </Row>
                    <Row hidden={!hasFreqCapping} gutter={GUTTER}>
                        <Col {...INNER_COL_SIZES}>
                            <Item label={BASE_BRAND_SAFETY_LABELS.adFreqCapping} className={FORM_ITEM_OFFSET}>
                                {(unit as AdUnit)?.adomainFreqCappingType?.name || NONE}
                            </Item>
                        </Col>
                    </Row>
                    <Row hidden={!hasFreqCaps} gutter={GUTTER}>
                        <Col {...INNER_COL_SIZES}>
                            <Item label={BASE_BRAND_SAFETY_LABELS.adFreqCaps} className={FORM_ITEM_OFFSET}>
                                {(unit as AdUnit)?.adomainFreqCappings?.length
                                    ? (unit as AdUnit).adomainFreqCappings.map((capp) => (
                                          <Tag key={capp.id}>{`${capp.total} ${capp?.mode?.name} in ${getDimension(
                                              capp.timeDurationMin
                                          )}`}</Tag>
                                      ))
                                    : (unit as AdUnit)?.adomainFreqCappingType?.name || NONE}
                            </Item>
                        </Col>
                    </Row>
                    <Row hidden={!hasBlockedCategories} gutter={GUTTER}>
                        <Col {...INNER_COL_SIZES}>
                            <Item label={BASE_BRAND_SAFETY_LABELS.blockedCategories} className={FORM_ITEM_OFFSET}>
                                <SeeMoreUnorderedList
                                    values={(unit as Brand)?.blockedIabCategories?.map(
                                        (value) => `${value.code}: ${value.name}`
                                    )}
                                />
                            </Item>
                        </Col>
                    </Row>
                    <Row hidden={!hasBlockedIndustries} gutter={GUTTER}>
                        <Col {...INNER_COL_SIZES}>
                            <Item label={BASE_BRAND_SAFETY_LABELS.blockedIndustries} className={FORM_ITEM_OFFSET}>
                                <Industries industries={(unit as Brand)?.blockedIndustries} />
                            </Item>
                        </Col>
                    </Row>
                </Col>
                <Col hidden={!hasCreativeBlockingMode} {...TWO_COL_SIZES}>
                    <Row gutter={GUTTER}>
                        <Col span={24}>
                            <InternalOnly>
                                <Item label={BASE_BRAND_SAFETY_LABELS.creativeBlockMode} className={FORM_ITEM_OFFSET}>
                                    {(unit as Publisher)?.creativeBlockingMode?.name || NONE}
                                </Item>
                            </InternalOnly>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>
    );
};
