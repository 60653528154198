import { consoleApi, EXTERNAL_TAXONOMIES_TAG } from "./console";
import { toQueryString } from "./utils";

export interface ExternalTaxonomy {
    creationDate: string;
    updateDate: string;
    id: number;
    name: string;
    code: string;
    parentId: number;
    parentName: string;
    type: {
        creationDate: string;
        updateDate: string;
        id: number;
        name: string;
        sort: number;
        entityType: string;
    };
    entityType: "ExternalTaxonomy";
}

export const externalTaxonomiesApi = consoleApi.injectEndpoints({
    endpoints: (builder) => ({
        getExternalTaxonomies: builder.query<ExternalTaxonomy[], { keyword?: string }>({
            query: (params = {}) => {
                return `externalTaxonomies;${toQueryString({
                    keyword: params.keyword ?? "",
                })}`;
            },
            providesTags: [EXTERNAL_TAXONOMIES_TAG],
        }),
    }),
    overrideExisting: false,
});

export const { useGetExternalTaxonomiesQuery } = externalTaxonomiesApi;
