import { MarketplaceInfo } from "@app/core/services";
import { ReadonlyControl, SelectWithValue } from "@app/features/seatAdSources/SeatAdSourcesForm/components";
import { Form } from "antd";
import { CSSProperties, FC } from "react";
import { DEAL_FORM_FIELDS } from "../../../../../constants";
import { useFrequencyCappingSelect } from "./useFrequencyCappingSelect";

export const FrequencyCappingTypeSelect: FC<{ style?: CSSProperties }> = ({ style }) => {
    const { freqCappingTypes, isLoading, isDisabled, isReadonly, onChange } = useFrequencyCappingSelect();

    return (
        <Form.Item
            name={DEAL_FORM_FIELDS.FREQUENCY_CAPPING_TYPE.name}
            label={DEAL_FORM_FIELDS.FREQUENCY_CAPPING_TYPE.label}
            style={
                isReadonly
                    ? {
                          marginBottom: 0,
                      }
                    : style
            }
        >
            {isReadonly ? (
                <ReadonlyControl<MarketplaceInfo["freqCappingType"]>
                    getLabel={(cappingType) => cappingType?.name || ""}
                />
            ) : (
                <SelectWithValue
                    loading={isLoading}
                    options={freqCappingTypes}
                    fieldAsLabel={["name"]}
                    fieldAsValue={["id"]}
                    placeholder="Select…"
                    disabled={isDisabled}
                    onChange={onChange}
                />
            )}
        </Form.Item>
    );
};
