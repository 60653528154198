import { useCallback, useEffect, useMemo } from "react";
import moment from "moment-timezone";
import { TimeZone, SeatAdSourcesStatus, Currency } from "@app/core/services";
import {
    shouldSyncCpmRate,
    shouldSyncCurrency,
    shouldSyncEndDate,
    shouldSyncStartDate,
    shouldSyncStatus,
    shouldSyncTimeZone,
    useIsAdSourceOutOfSync,
    useSyncedFieldsConsumer,
} from "@app/features/syncedFields";
import { useSyncRelatedFormData } from "./useSyncRelatedFormData";
import { useUpdateDeal } from "./useUpdateDeal";

export const useAdSourceSyncAdSourceToDeal = () => {
    const {
        adSourceForm,
        adSourceFields,
        isAdSourceFormInEditMode,
        isDealDrawerOpen,
        singleAttachedDealSyncData,
        readOnlyAdSourceFields,
    } = useSyncRelatedFormData();
    const { marketplaceInfo, isOneToOne } = singleAttachedDealSyncData;
    const { type, floorType } = adSourceFields;
    const { updateDeal } = useUpdateDeal(marketplaceInfo, adSourceForm, type?.id, floorType?.id);
    const { setIsAdSourceOutOfSync } = useIsAdSourceOutOfSync();

    const onConsumeStartDateUpdate = useCallback(
        (updatedStartDate: moment.Moment | null) => {
            if (
                !shouldSyncStartDate(
                    isOneToOne,
                    isAdSourceFormInEditMode,
                    updatedStartDate,
                    type?.id,
                    readOnlyAdSourceFields
                )
            ) {
                return;
            }

            updateDeal({ startDate: updatedStartDate });
        },
        [isOneToOne, isAdSourceFormInEditMode, type, readOnlyAdSourceFields, updateDeal]
    );

    const onConsumeEndDateUpdate = useCallback(
        (updatedEndDate: moment.Moment | null) => {
            if (!shouldSyncEndDate(isOneToOne, updatedEndDate, type?.id)) {
                return;
            }

            updateDeal({ endDate: updatedEndDate });
        },
        [isOneToOne, type, updateDeal]
    );

    const onConsumeTimeZoneUpdate = useCallback(
        (updatedTimeZone: TimeZone | null) => {
            if (
                !shouldSyncTimeZone(
                    isOneToOne,
                    isAdSourceFormInEditMode,
                    updatedTimeZone,
                    type?.id,
                    readOnlyAdSourceFields
                )
            ) {
                return;
            }

            updateDeal({ timeZone: updatedTimeZone });
        },
        [isOneToOne, isAdSourceFormInEditMode, type, readOnlyAdSourceFields, updateDeal]
    );

    const onConsumeCpmRateUpdate = useCallback(
        (updatedCpmRate: string | null) => {
            if (!shouldSyncCpmRate(isOneToOne, updatedCpmRate, type?.id, floorType?.id)) {
                return;
            }

            updateDeal({ cpmRate: updatedCpmRate });
        },
        [isOneToOne, type, floorType, updateDeal]
    );

    const onConsumeStatusUpdate = useCallback(
        (updatedStatus: SeatAdSourcesStatus | null) => {
            if (!shouldSyncStatus(isOneToOne, updatedStatus)) {
                return;
            }

            updateDeal({ status: updatedStatus });
        },
        [isOneToOne, updateDeal]
    );

    const onConsumeCurrencyUpdate = useCallback(
        (updatedCurrency: Currency | null) => {
            if (!shouldSyncCurrency(isOneToOne, updatedCurrency, type?.id)) {
                return;
            }

            updateDeal({ currency: updatedCurrency });
        },
        [isOneToOne, type, updateDeal]
    );

    // Note - impressions are synced by inheritence, no need to update the deal here because the field is readonly in edit mode

    const updateConsumerCallbacks = useMemo(() => {
        if (isDealDrawerOpen || !isOneToOne || !marketplaceInfo) {
            return;
        }

        return {
            onConsumeStartDateUpdate,
            onConsumeEndDateUpdate,
            onConsumeTimeZoneUpdate,
            onConsumeCpmRateUpdate,
            onConsumeStatusUpdate,
            onConsumeCurrencyUpdate,
        };
    }, [
        isDealDrawerOpen,
        isOneToOne,
        marketplaceInfo,
        onConsumeStartDateUpdate,
        onConsumeEndDateUpdate,
        onConsumeTimeZoneUpdate,
        onConsumeCpmRateUpdate,
        onConsumeStatusUpdate,
        onConsumeCurrencyUpdate,
    ]);

    const { consumeAllSyncedFields } = useSyncedFieldsConsumer(updateConsumerCallbacks);

    useEffect(() => {
        // Ensure we start with fresh synced field state when the ad source form is first opened.
        consumeAllSyncedFields();
        setIsAdSourceOutOfSync(false);

        return () => {
            // Clean up any remaining synced field state when we leave the ad source form.
            consumeAllSyncedFields();
            setIsAdSourceOutOfSync(false);
        };
        // Empty dependency array is necessary to ensure that we only reset state once per initialiation / cleanup.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
};
