import {
    Deal,
    MarketplaceInfo,
    DealListItem,
    BuyerDeal,
    DemandDeal,
    TargetingCreatePayload,
    TargetingMode,
    AdSourcePayload,
    Seat,
} from "@app/core/services/console";
import { DealForm } from "./types";
import { DEAL_TYPES } from "@app/features/deals/constants";
import {
    AD_SOURCE_COST_MODEL_FIXED,
    DEFAULT_AD_SOURCE_COST_MODEL_ID,
    getDemandFees,
    getDemandAcquisitionCost,
    DEFAULT_AD_SOURCE_PACING_PERIOD,
} from "@app/features/deals/DealForm/useDealForm";
import { getDealFreqCappingsItems, getTransparencyToBoolean } from "@app/features/deals/helpers";
import { TargetingFormKeys } from "@app/features/targeting/constants";
import { AD_SOURCE_FIELDS } from "@app/features/seatAdSources/constants";
import { parseCostPercent } from "@app/features/seatAdSources/SeatAdSourcesForm/utils";
import { parseCostFixedToApi } from "@app/features/seatAdSources/SeatAdSourcesForm/utils/parseCostFixedToApi";
import { parsePriceModelFieldsToApi } from "@app/features/seatAdSources/SeatAdSourcesForm/utils/parsePriceModelFieldsToApi";

export const parseValueToApi = (value: number | null | undefined): null | { id: number } => {
    if (value) {
        return {
            id: value,
        };
    }
    return null;
};
export const parseFixedCpmToApi = (
    isProgrammaticGuaranteeDeal: boolean,
    isFixedPriceDeal: boolean,
    adSourceBookingPrice: number | null,
    dealRate: string | number | null | undefined
): number => {
    if (isProgrammaticGuaranteeDeal || isFixedPriceDeal || !adSourceBookingPrice) {
        return Number(dealRate) * 1000;
    }
    return adSourceBookingPrice * 1000;
};

export const parseAdditionalAdvertiserDomainsToApi = (
    adSourceAdditionalAdvertiserDomain: string | undefined
): string[] | [] => {
    if (!adSourceAdditionalAdvertiserDomain) {
        return [];
    }
    if (adSourceAdditionalAdvertiserDomain.includes("\n")) {
        return adSourceAdditionalAdvertiserDomain.split("\n").filter((domain) => domain);
    }
    return [adSourceAdditionalAdvertiserDomain];
};
export const parseAdvertiserDomainsToApi = (
    adSourceMode: number | null | undefined,
    adSourceReusableAdvertiserDomain: number[] | undefined
):
    | {
          advertiserDomainListType: {
              id: number;
          };
          id: number;
      }[]
    | null => {
    if (adSourceMode && adSourceReusableAdvertiserDomain?.length) {
        return adSourceReusableAdvertiserDomain?.map((id) => ({
            advertiserDomainListType: {
                id: adSourceMode,
            },
            id,
        }));
    }
    return null;
};

export const getApiDurationValueFromFormValue = (amountInput: string | number | null | undefined): number | null => {
    if (amountInput !== 0 && !amountInput) {
        return null;
    }

    return Number(amountInput);
};

export const getApiOverrideValueFromFormValue = (override: string | number): string | null => {
    if (override !== 0 && !override) {
        return null;
    }

    return String(override);
};

export const getDealMarketplaceInfo = (
    marketplaceInfoList: MarketplaceInfo[] | undefined,
    deal: Deal | DealListItem
): MarketplaceInfo | undefined =>
    marketplaceInfoList?.find((marketplaceInfo) => marketplaceInfo.deal.code === deal.code);

export const parseAdSourceCreatePayloadToApi = ({
    values,
    deal,
    seatId,
    targetingPayloadsByFormKey,
    targetingModePayloadsByFormKey,
    seat,
}: {
    values: DealForm;
    seatId: number;
    deal: BuyerDeal | DemandDeal;
    targetingPayloadsByFormKey: {
        adSource: TargetingCreatePayload[];
        adSourceAdditionalTargeting: TargetingCreatePayload[];
        deal: TargetingCreatePayload[];
        dealAdditionalTargeting: TargetingCreatePayload[];
    };
    targetingModePayloadsByFormKey: {
        adSource: TargetingMode;
        adSourceAdditionalTargeting: TargetingMode;
        deal: TargetingMode;
        dealAdditionalTargeting: TargetingMode;
    };
    seat: Seat | null;
}): AdSourcePayload => {
    const isProgrammaticGuaranteeDeal = values?.dealType === DEAL_TYPES.GUARANTEE;
    const isFixedPriceDeal = values?.dealType === DEAL_TYPES.FIXED;
    const isAuctionPriceDeal = values?.dealType === DEAL_TYPES.AUCTION;
    const { adSourceLevelDemandFee, dealLevelDemandFee } = getDemandFees(targetingPayloadsByFormKey, values?.dealType);
    const adSourceLevelDemandAcquisitionCost = getDemandAcquisitionCost(
        Boolean(seat?.dacEnabled),
        values?.adSourceDemandAcquisitionCost
    );

    const marketplaceInfoList = [
        {
            currencyType: parseValueToApi(values?.transactionCurrency),
            dealFreqCappings: getDealFreqCappingsItems(values?.dealFreqCappings),
            enforcement: values?.enforcement && !isAuctionPriceDeal ? parseValueToApi(values?.enforcement) : null,
            freqCappingType: parseValueToApi(values?.frequencyCapping),
            targeting: [...targetingPayloadsByFormKey[TargetingFormKeys.DealAdditionalTargeting]],
            demandFee: dealLevelDemandFee,
            deal: deal,
            "@class": `com.tremorvideo.ssp.platform.model.MarketPlaceInfo${deal.entityType}`,
        },
    ];
    const body = {
        [AD_SOURCE_FIELDS.TYPE.name]: parseValueToApi(values?.dealType),
        [AD_SOURCE_FIELDS.STATUS.name]: deal?.status,
        [AD_SOURCE_FIELDS.START_DATE.name]: deal?.startTime,
        [AD_SOURCE_FIELDS.END_DATE.name]: deal?.endTime,
        [AD_SOURCE_FIELDS.CURRENCY.name]: deal?.currencyType,
        [AD_SOURCE_FIELDS.TIME_ZONE.name]: deal?.timeZone,
        [AD_SOURCE_FIELDS.TOTAL_IMPRESSIONS.name]: values?.dealImpression,
        [AD_SOURCE_FIELDS.NAME.name]: values?.adSourceName,
        [AD_SOURCE_FIELDS.DESCRIPTION.name]: values?.adSourceDescription,
        [AD_SOURCE_FIELDS.PRIORITY.name]: parseValueToApi(values?.adSourcePriority),
        [AD_SOURCE_FIELDS.EXTEND_TIMEOUT_VALUE.name]: values?.adSourceExtendTimeout,
        [AD_SOURCE_FIELDS.COST_MODEL.name]: parseValueToApi(values?.adSourceCostModel),
        [AD_SOURCE_FIELDS.COST_PERCENT.name]: parseCostPercent(values?.adSourceCost),
        [AD_SOURCE_FIELDS.ALLOW_AUTOSCALE.name]: values?.adSourceAutoscale,
        [AD_SOURCE_FIELDS.REGION.name]: parseValueToApi(values?.adSourceRegion),
        [AD_SOURCE_FIELDS.SALES_CONTACT.name]: parseValueToApi(values?.adSourceSalesContact),
        [AD_SOURCE_FIELDS.EXTERNAL_CONTRACT_NUMBER.name]: getApiOverrideValueFromFormValue(
            values?.adSourceExternalContactNumber
        ),
        [AD_SOURCE_FIELDS.MODE.name]: parseValueToApi(values?.adSourceMode),
        [AD_SOURCE_FIELDS.REUSABLE_ADVERTISER_DOMAINS.name]: parseAdvertiserDomainsToApi(
            values?.adSourceMode,
            values?.adSourceReusableAdvertiserDomain
        ),
        [AD_SOURCE_FIELDS.ADDITIONAL_ADVERTISER_DOMAINS.name]: parseAdditionalAdvertiserDomainsToApi(
            values?.adSourceAdditionalAdvertiserDomain
        ),
        [AD_SOURCE_FIELDS.FIXED_CPM.name]: parseFixedCpmToApi(
            isProgrammaticGuaranteeDeal,
            isFixedPriceDeal,
            values?.adSourceBookingPrice,
            values?.dealRate
        ),
        ...parsePriceModelFieldsToApi(values?.dealType),
        [AD_SOURCE_FIELDS.PACING_PERIOD.name]: values?.adSourcePacingType
            ? parseValueToApi(DEFAULT_AD_SOURCE_PACING_PERIOD.id)
            : null,
        [AD_SOURCE_FIELDS.PACING_TYPE.name]: parseValueToApi(values?.adSourcePacingType),
        [AD_SOURCE_FIELDS.ALWAYS_SEND_FLOORS.name]: values?.adSourceAlwaysSendFloor,
        [AD_SOURCE_FIELDS.DAILY_IMPRESSION_CAP.name]: values?.adSourcePacingImpressionCap,
        [AD_SOURCE_FIELDS.PACING_REDISTRIBUTION.name]: values?.adSourcePacingRedistribute,
        [AD_SOURCE_FIELDS.TARGET_DATE.name]: values?.adSourcePacingCustomCurveStartDate,
        [AD_SOURCE_FIELDS.DELIVERY_PERCENTAGE.name]: values?.adSourcePacingCustomCurveImps,
        [AD_SOURCE_FIELDS.PIXELS.name]: values?.adSourcePixels?.map((id) => ({ id })),

        [AD_SOURCE_FIELDS.SUPPLY_NAME.name]: getTransparencyToBoolean(values?.adSourceAllowSiteName),
        [AD_SOURCE_FIELDS.DOMAIN_NAME.name]: getTransparencyToBoolean(values?.adSourceAllowDomainName),
        [AD_SOURCE_FIELDS.DOMAIN_NAME_OVERRIDE.name]: getApiOverrideValueFromFormValue(
            values?.adSourceDomainNameOverride
        ),
        [AD_SOURCE_FIELDS.ASSIGNEE.name]: parseValueToApi(values?.adSourceAssignee),
        [AD_SOURCE_FIELDS.MIN_DURATION.name]: getApiDurationValueFromFormValue(values?.adSourceMinDuration),
        [AD_SOURCE_FIELDS.MAX_DURATION.name]: getApiDurationValueFromFormValue(values?.adSourceMaxDuration),
        [AD_SOURCE_FIELDS.SUPPLY_PAGE.name]: getTransparencyToBoolean(values?.adSourceAllowSitePage),
        [AD_SOURCE_FIELDS.REFER_URL.name]: getTransparencyToBoolean(values?.adSourceAllowRef),
        [AD_SOURCE_FIELDS.FULL_IP_ADDRESS.name]: getTransparencyToBoolean(values?.adSourceAllowIp),
        [AD_SOURCE_FIELDS.BUNDLE_ID.name]: getTransparencyToBoolean(values?.adSourceAllowBundleId),
        [AD_SOURCE_FIELDS.BUNDLE_ID_OVERRIDE.name]: getApiOverrideValueFromFormValue(values?.adSourceBundleIdOverride),
        [AD_SOURCE_FIELDS.STORE_URL.name]: getTransparencyToBoolean(values?.adSourceAllowStoreUrl),
        [AD_SOURCE_FIELDS.USER_ID.name]: getTransparencyToBoolean(values?.adSourceAllowUserId),
        [AD_SOURCE_FIELDS.CONTENT.name]: getTransparencyToBoolean(values?.adSourceAllowContent),
        [AD_SOURCE_FIELDS.INCLUDED_EXTENDED_IDS.name]: getTransparencyToBoolean(values?.adSourceAllowAllExtendedId),
        [AD_SOURCE_FIELDS.EXTENDED_USER_ID.name]: values?.adSourceAllowedExtendedIdTransparencies,
        [AD_SOURCE_FIELDS.WATERFALL_PRE_FILTER_MODE.name]: values?.adSourceStrictMode,
        [AD_SOURCE_FIELDS.FALLBACK_OPPORTUNITY.name]: values?.adSourcePacingFallback,
        [AD_SOURCE_FIELDS.LABEL.name]: values?.adSourceLabels,
        [AD_SOURCE_FIELDS.DEMAND.name]: marketplaceInfoList,
        [AD_SOURCE_FIELDS.RUN_UNDER_INBOUND_FLOOR.name]: values?.adSourceRunUnderInboundFloor,
        maskUserId: values?.adSourceAllowUserId === "masked",
        seat: parseValueToApi(Number(seatId)),
        demandFee: adSourceLevelDemandFee,
        demandAcquisitionCost: adSourceLevelDemandAcquisitionCost,
        targeting: targetingPayloadsByFormKey[TargetingFormKeys.Deal],
        targetingMode: targetingModePayloadsByFormKey[TargetingFormKeys.Deal],
    };
    if (values?.adSourceCostModel === DEFAULT_AD_SOURCE_COST_MODEL_ID) {
        Object.assign(body, {
            costValuePercent: values?.adSourceCost,
        });
    }

    if (values?.adSourceCostModel === AD_SOURCE_COST_MODEL_FIXED) {
        Object.assign(body, {
            costValueFixed: parseCostFixedToApi(values?.adSourceCost),
        });
    }

    if (isAuctionPriceDeal) {
        Object.assign(body, {
            auctionType: {
                id: values?.adSourceAuctionType,
            },
            adSourceFloorType: {
                id: values?.adSourceFloorType,
            },
        });
    }

    return body as unknown as AdSourcePayload;
};
