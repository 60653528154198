import { FC } from "react";
import { Pagination } from "antd";
import { useDealHealthTablePagination } from "@app/features/deals/DealHealthPage/DealHealthTable/DealHealthTablePagination/useDealHealthTablePagination";
import { formatNumber } from "@rubicon/utils";

interface Props {
    totalResults: number;
}

const DealHealthPagination: FC<Props> = ({ totalResults }) => {
    const { page, maxResults, pageSizeOptions, handleChange } = useDealHealthTablePagination(totalResults);

    return (
        <Pagination
            current={page}
            onChange={handleChange}
            pageSize={maxResults}
            pageSizeOptions={pageSizeOptions}
            showTotal={(total) => (total ? `${formatNumber.asNumber(total)} Total` : "")}
            showSizeChanger
            total={totalResults}
        />
    );
};

export default DealHealthPagination;
