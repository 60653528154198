import { Form, Switch, Tag, Select } from "antd";
import {
    ContentTransparencyRule,
    ControlUserIdIfaValueType,
    ExtendedId,
    SeatAvailableNetworks,
    TransparencyDefaultForm,
    TransparencyDefaultRaw,
} from "@app/core/services";
import { FormListFieldData } from "antd/lib";
import { NONE } from "@app/core/components/constants";
import { BaseOptionType } from "antd/es/select";
import { ControlTableExtendedUserIdSelect } from "./ControlTableExtendedUserIdSelect";
import {
    EXTENDED_USER_ID_OPTIONS,
    ExtendedUserSelectValueType,
    LV,
    SPECIFIC,
    STATIC_OPTIONS,
    USER_ID_IFA_OPTIONS,
    notExtendableTypes,
} from "./constants";
import { AD_SOURCE_TYPES_NEXT_UI_NAMES } from "@app/features/seatAdSources/constants";

export type ShareMaskedTableView = "Share" | "Masked";
export interface TableRows {
    className?: string;
    id: number;
    adSourceType: string;
    allowUserId: string | boolean;
    maskUserId?: string;
    allowSiteName: ShareMaskedTableView;
    allowDomainName: ShareMaskedTableView;
    allowContent: string;
    allowSitePage: ShareMaskedTableView;
    allowRef: ShareMaskedTableView;
    allowIp: ShareMaskedTableView;
    allowBundleId: string;
    allowStoreUrl: ShareMaskedTableView;
    allowAllExtendedId: string | JSX.Element[];
}
export interface TableEditModeFormRows {
    className?: string;
    controlUserIdIfa: JSX.Element | undefined;
    controlContent: JSX.Element | undefined;
    id: number;
    adSourceType: string;
    allowSiteName: JSX.Element;
    allowDomainName: JSX.Element;
    allowSitePage: JSX.Element;
    allowRef: JSX.Element;
    allowIp: JSX.Element;
    allowBundleId: JSX.Element;
    allowStoreUrl: JSX.Element;
    controlExtendedUserId: JSX.Element | string;
}

export const getFormContentOptions = (contentTransparencyRules: ContentTransparencyRule[]) => {
    return [
        ...contentTransparencyRules.map(({ id, name }) => ({
            label: name,
            value: id,
        })),
        ...STATIC_OPTIONS,
    ];
};

export const mapContentRuleApiToForm = (
    allowContent: boolean,
    contentTransparencyRule: ContentTransparencyRule | undefined | null
) => {
    if (allowContent && contentTransparencyRule) {
        // custom
        return { label: contentTransparencyRule.name, value: contentTransparencyRule.id };
    }
    if (allowContent && !contentTransparencyRule) {
        // Shared
        return STATIC_OPTIONS[0];
    }
    return STATIC_OPTIONS[1]; // Hidden
};

export const mapContentRuleFormToApi = (controlContent: LV) => {
    if (controlContent.value === "hidden") {
        return {
            allowContent: false,
            contentTransparencyRule: null,
        };
    }
    if (controlContent.value === "shared") {
        return {
            allowContent: true,
            contentTransparencyRule: null,
        };
    }

    return {
        allowContent: true,
        contentTransparencyRule: { name: controlContent.label, id: controlContent.value } as ContentTransparencyRule,
    };
};

export const mapUserIdMaskApiToForm = (allowUserId: boolean, maskUserId: boolean) => {
    if (allowUserId && maskUserId) {
        return "masked";
    }
    if (allowUserId && !maskUserId) {
        return "shared";
    }
    return "hidden";
};
export const nameIdToLabelValueMapper = ({ id, name }) => ({ label: name, value: id });
export const labelValueToNameIdMapper = ({ value, label }) => ({ name: label, id: value });

export const mapUserIdMaskFormToApi = (controlUserIdIfa: ControlUserIdIfaValueType) => {
    if (controlUserIdIfa === "masked") {
        return {
            allowUserId: true,
            maskUserId: true,
        };
    }
    if (controlUserIdIfa === "shared") {
        return {
            allowUserId: true,
            maskUserId: false,
        };
    }
    return {
        allowUserId: false,
        maskUserId: false,
    };
};

export const mapExtendedUserIdApiToForm = (allowAllExtendedId: boolean, allowedExtendedIdTransparencies: unknown[]) => {
    if (allowAllExtendedId && !Boolean(allowedExtendedIdTransparencies.length)) {
        return EXTENDED_USER_ID_OPTIONS[0].value;
    }
    if (!allowAllExtendedId && Boolean(allowedExtendedIdTransparencies.length)) {
        return EXTENDED_USER_ID_OPTIONS[1].value;
    }
    return EXTENDED_USER_ID_OPTIONS[2].value;
};

interface ControlExtendedUserIdMetadata {
    extendedUserRaw: ExtendedId[] | undefined;
    availableNetworkRaw: SeatAvailableNetworks[] | undefined;
    extendedIds: Record<string, LV[]>;
}
export const mapExtendedUserIdFormToApi = (
    controlExtendedUserId: ExtendedUserSelectValueType,
    { extendedUserRaw, availableNetworkRaw, extendedIds }: ControlExtendedUserIdMetadata,
    adSourceTypeId: number
) => {
    if (controlExtendedUserId === "all") {
        return {
            allowedExtendedIdTransparencies: [],
            allowAllExtendedId: true,
        };
    }
    if (controlExtendedUserId === SPECIFIC) {
        const selectedUserIds = extendedIds[adSourceTypeId]?.map(({ value }) => String(value).split("/")[0]);
        const selectedNetworkIds = extendedIds[adSourceTypeId]?.map(({ value }) => String(value).split("/")[1]);
        const uIds = extendedUserRaw?.filter(({ id }) => selectedUserIds?.includes(String(id)));
        const nIds = availableNetworkRaw?.filter(({ id }) => selectedNetworkIds?.includes(String(id)));
        if (!uIds || !nIds)
            return {
                allowedExtendedIdTransparencies: [],
                allowAllExtendedId: false,
            };
        return {
            allowedExtendedIdTransparencies: selectedUserIds?.map((_, index) => ({
                extendedId: uIds[index],
                network: nIds[index],
            })),
            allowAllExtendedId: false,
        };
    }
    return {
        allowedExtendedIdTransparencies: [],
        allowAllExtendedId: false,
    };
};

export const BUNDLE_ID_SDET = "allow-bundle-ids-select";
export const CONTENT_SDET = "content-select";
export const EXTENDED_USER_ID_SDET = "extented-user-id-select";
export const EXTENDED_USER_ID_BUTTON_SDET = "extented-user-id-button";
export const USER_ID_IFA_SDET = "user-id-ifa-select";

export const getFormColumns = () =>
    [
        {
            dataIndex: "adSourceType",
            key: "adSourceType",
            sorter: false,
            title: "Ad Source Type",
            width: 350,
        },
        {
            dataIndex: "allowSiteName",
            key: "allowSiteName",
            sorter: false,
            title: "Supply Name / App Name",
            width: 200,
        },
        {
            dataIndex: "allowDomainName",
            key: "allowDomainName",
            sorter: false,
            title: "Domain Name",
            width: 150,
        },
        {
            dataIndex: "allowSitePage",
            key: "allowSitePage",
            sorter: false,
            title: "Supply Page / Content URL",
            width: 200,
        },
        {
            dataIndex: "allowRef",
            key: "allowRef",
            sorter: false,
            title: "Refer URL",
            width: 100,
        },
        {
            dataIndex: "allowIp",
            key: "allowIp",
            sorter: false,
            title: "Full IP Address",
            width: 100,
        },
        {
            dataIndex: "allowBundleId",
            key: "allowBundleId",
            sorter: false,
            title: "Bundle ID",
            width: 100,
        },
        {
            dataIndex: "allowStoreUrl",
            key: "allowStoreUrl",
            sorter: false,
            title: "Store URL",
            width: 100,
        },
        {
            dataIndex: "controlUserIdIfa",
            key: "controlUserIdIfa",
            sorter: false,
            title: "User ID / IFA",
            width: 100,
        },
        {
            dataIndex: "controlContent",
            key: "controlContent",
            sorter: false,
            title: "Content",
            width: 200,
        },
        {
            dataIndex: "controlExtendedUserId",
            key: "controlExtendedUserId",
            sorter: false,
            title: "Extended User ID",
            width: 200,
        },
    ].filter(Boolean);

export const getReadOnlyColumns = () =>
    [
        {
            dataIndex: "adSourceType",
            key: "adSourceType",
            sorter: false,
            title: "Ad Source Type",
            width: 350,
        },
        {
            dataIndex: "allowSiteName",
            key: "allowSiteName",
            sorter: false,
            title: "Supply Name / App Name",
            width: 200,
        },
        {
            dataIndex: "allowDomainName",
            key: "allowDomainName",
            sorter: false,
            title: "Domain Name",
            width: 150,
        },
        {
            dataIndex: "allowSitePage",
            key: "allowSitePage",
            sorter: false,
            title: "Supply Page / Content URL",
            width: 200,
        },
        {
            dataIndex: "allowRef",
            key: "allowRef",
            sorter: false,
            title: "Refer URL",
            width: 100,
        },
        {
            dataIndex: "allowIp",
            key: "allowIp",
            sorter: false,
            title: "Full IP Address",
            width: 100,
        },
        {
            dataIndex: "allowBundleId",
            key: "allowBundleId",
            sorter: false,
            title: "Bundle ID",
            width: 200,
        },
        {
            dataIndex: "allowStoreUrl",
            key: "allowStoreUrl",
            sorter: false,
            title: "Store URL",
            width: 100,
        },
        {
            dataIndex: "allowUserId",
            key: "allowUserId",
            sorter: false,
            title: "User ID / IFA",
            width: 100,
        },
        {
            dataIndex: "allowContent",
            key: "allowContent",
            sorter: false,
            title: "Content",
            width: 200,
        },
        {
            dataIndex: "allowAllExtendedId",
            key: "allowAllExtendedId",
            sorter: false,
            title: "Extended User ID",
            width: 200,
        },
    ].filter(Boolean);

export const shareMaskMap = (value: boolean): "Share" | "Masked" => (value ? "Share" : "Masked");

export const getTableFormRows = (
    data: TransparencyDefaultForm[] | undefined,
    ctrlMetadata: {
        contentOptions: BaseOptionType[];
        handleExtendedUserSelect: (value: ExtendedUserSelectValueType) => (id: number) => void;
        handleRemove: (val: string, adSourceTypeId: number) => void;
        extendedIds: Record<string, LV[]>;
    },
    fields: FormListFieldData[]
) => {
    return data
        ? data.map<TableEditModeFormRows>(
              (
                  {
                      id,
                      adSourceType,
                      allowSiteName,
                      allowDomainName,
                      controlContent,
                      allowSitePage,
                      allowRef,
                      allowIp,
                      allowBundleId,
                      allowStoreUrl,
                      controlUserIdIfa,
                  },
                  i
              ) => {
                  return {
                      className: "",
                      id,
                      adSourceType: AD_SOURCE_TYPES_NEXT_UI_NAMES[adSourceType?.name] || NONE,
                      allowSiteName: (
                          <Form.Item name={[fields[i].name, "allowSiteName"]}>
                              <Switch checkedChildren="Share" unCheckedChildren="Hide" defaultChecked={allowSiteName} />
                          </Form.Item>
                      ),
                      allowBundleId: (
                          <Form.Item name={[fields[i].name, "allowBundleId"]}>
                              <Switch
                                  data-sdet={BUNDLE_ID_SDET}
                                  checkedChildren="Share"
                                  unCheckedChildren="Hide"
                                  defaultChecked={allowBundleId}
                              />
                          </Form.Item>
                      ),
                      controlContent: (
                          <Form.Item name={[fields[i].name, "controlContent"]}>
                              <Select
                                  labelInValue
                                  data-sdet={CONTENT_SDET}
                                  options={ctrlMetadata.contentOptions}
                                  value={controlContent}
                              />
                          </Form.Item>
                      ),
                      allowDomainName: (
                          <Form.Item name={[fields[i].name, "allowDomainName"]}>
                              <Switch
                                  checkedChildren="Share"
                                  unCheckedChildren="Hide"
                                  defaultChecked={allowDomainName}
                              />
                          </Form.Item>
                      ),
                      allowRef: (
                          <Form.Item name={[fields[i].name, "allowRef"]}>
                              <Switch checkedChildren="Share" unCheckedChildren="Hide" defaultChecked={allowRef} />
                          </Form.Item>
                      ),
                      allowIp: (
                          <Form.Item name={[fields[i].name, "allowIp"]}>
                              <Switch checkedChildren="Share" unCheckedChildren="Hide" defaultChecked={allowIp} />
                          </Form.Item>
                      ),
                      allowSitePage: (
                          <Form.Item name={[fields[i].name, "allowSitePage"]}>
                              <Switch checkedChildren="Share" unCheckedChildren="Hide" defaultChecked={allowSitePage} />
                          </Form.Item>
                      ),
                      allowStoreUrl: (
                          <Form.Item name={[fields[i].name, "allowStoreUrl"]}>
                              <Switch checkedChildren="Share" unCheckedChildren="Hide" defaultChecked={allowStoreUrl} />
                          </Form.Item>
                      ),
                      controlUserIdIfa: (
                          <Form.Item name={[fields[i].name, "controlUserIdIfa"]}>
                              <Select
                                  data-sdet={USER_ID_IFA_SDET}
                                  options={USER_ID_IFA_OPTIONS}
                                  value={controlUserIdIfa}
                              />
                          </Form.Item>
                      ),
                      key: id,
                      controlExtendedUserId: !notExtendableTypes.includes(adSourceType.id) ? (
                          <ControlTableExtendedUserIdSelect
                              extendedIds={ctrlMetadata.extendedIds}
                              fields={fields}
                              i={i}
                              handleExtendedUserSelect={ctrlMetadata.handleExtendedUserSelect}
                              handleRemove={ctrlMetadata.handleRemove}
                              id={id}
                          />
                      ) : (
                          NONE
                      ),
                  };
              }
          )
        : [];
};

export const getTableReadOnlyRows = (data: TransparencyDefaultRaw[] | undefined) => {
    return data
        ? data.map<TableRows>(
              ({
                  id,
                  adSourceType,
                  allowUserId,
                  maskUserId,
                  allowSiteName,
                  allowDomainName,
                  allowContent,
                  contentTransparencyRule,
                  allowSitePage,
                  allowRef,
                  allowIp,
                  allowBundleId,
                  allowStoreUrl,
                  allowAllExtendedId,
                  allowedExtendedIdTransparencies,
              }) => {
                  return {
                      className: "",
                      id,
                      adSourceType: AD_SOURCE_TYPES_NEXT_UI_NAMES[adSourceType?.name] || NONE,
                      allowSiteName: shareMaskMap(allowSiteName),
                      allowBundleId: shareMaskMap(allowBundleId),
                      allowContent: mapContentRuleApiToForm(allowContent, contentTransparencyRule).label || "Share",
                      allowDomainName: shareMaskMap(allowDomainName),
                      allowIp: shareMaskMap(allowIp),
                      allowRef: shareMaskMap(allowRef),
                      allowSitePage: shareMaskMap(allowSitePage),
                      allowStoreUrl: shareMaskMap(allowStoreUrl),
                      allowUserId: mapUserIdMaskApiToForm(allowUserId, maskUserId),
                      key: id,
                      allowAllExtendedId: Boolean(allowAllExtendedId)
                          ? "All"
                          : (allowedExtendedIdTransparencies.length > 0 &&
                                allowedExtendedIdTransparencies.map(({ extendedId, network }) => (
                                    <Tag color="default" key={String(extendedId.id).concat(String(network.id))}>
                                        {extendedId.name.concat(":").concat(network.name)}
                                    </Tag>
                                ))) ||
                            (notExtendableTypes.includes(adSourceType.id) && NONE) ||
                            "Hide",
                  };
              }
          )
        : [];
};
