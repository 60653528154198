import { css } from "@emotion/css";

export const CONTROLS_ROUTES = {
    TAG_PARAMETERS: "tagparameters",
    SEAT_CONTACTS: "seat-contacts",
    ENCRYPTION_KEYS: "encryption-keys",
    CUSTOM_PIXELS: "custom-pixels",
    ADVANCED_AD_BREAKS: "advanced-rules",
    BUYER_SEAT_LIST: "buyer-seat-list",
    BRAND_SAFETY: "brand-safety",
    ADVERTISER_DOMAINS: "advertiser-domains",
    SEQUESTERED_DOMAINS: "sequestered-domains",
    TRANSPARENCY_DEFAULT: "transparency-default",
    CONTENT_TRANSPARENCY_RULES: "transparency-rules",
    COMPETITIVE_SEPARATION_GROUPS: "separation-groups",
    ADVANCED_BRAND_SAFETY: "advanced-brand-safety",
    REUSABLE_TARGETING: "reusable-targeting",
    ADVERTISERS: "classifications_advertisers",
    INDUSTRIES: "classifications_industries",
    BULK_OPRATIONS: "bulkoperations",
    ADVANCED_FLOORS: "advancedfloors",
    MAPPING_EXCEPTIONS: "mapping-exceptions",
} as const;

type ControlRouteKey = keyof typeof CONTROLS_ROUTES;

export const CUSTOM_PIXELS_DEFINITION_FIELDS = [
    { label: "Name", key: "name", helpPath: "pixel.name" },
    { label: "Type", key: "type", helpPath: "pixel.type" },
    {
        label: "Add to Ad Source By Default",
        key: "addtoadsourcebydefault",
        helpPath: "pixel.addtoadsourcebydefault",
    },
    { label: "Pixel URL", key: "value", helpPath: "pixel.value" },
];
export type ControlRoute = (typeof CONTROLS_ROUTES)[ControlRouteKey];

export const sectionHeaderClassname = css`
    .ant-collapse-header-text {
        align-self: center;
    }
    .ant-collapse-expand-icon {
        align-self: center;
        .ant-collapse-arrow {
            font-size: 16px !important;
        }
    }
`;

export const DEFAULT_LABEL_OFF = "Off";
export const DEFAULT_LABEL_BOTH_ALLOWED = "Both Allowed";
export const URL_REGEXP_PATTERN =
    /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/;

export const drawerRightClosePosition = css`
    .ant-drawer-close {
        position: absolute;
        top: 20px;
        right: 0;
    }
`;

export const tableCellFormItemStyle = css`
    .ant-table-cell .ant-form-item {
        margin-bottom: 0;
        padding-bottom: 0;
    }
`;
