import { AdSource, BuyerDeal, DealListItem, DemandDeal, FinanceDefault } from "@app/core/services";
import {
    CREATE_DEAL_FORM_ITEMS_NAME,
    DEAL_TYPES,
    TRANSPARENCY_ALL_VALUE,
    TRANSPARENCY_HIDDEN_VALUE,
    TRANSPARENCY_MASKED_VALUE,
    TRANSPARENCY_NONE_VALUE,
    TRANSPARENCY_OVERRIDE_VALUE,
    TRANSPARENCY_SHARED_VALUE,
    TRANSPARENCY_SPECIFIC_VALUE,
} from "@app/features/deals/constants";
import {
    DealAdSourceTransparencey,
    DealAdSourceTransparenceyMasked,
    DealAdSourceTransparenceyOverride,
    DealAdSourceTransparencyExtendedUserId,
    DealAdSourceTransparencySpecific,
    DealFormMode,
    DealFormValues,
} from "@app/features/deals/DealForm/types";
import {
    DEAL_DEFAULT_ENFORCEMENT_ID,
    DEAL_DEFAULT_FREQUENCY_CAPPING_ID,
    DEAL_PRICING_TYPE_AUCTION_PRICE,
    DEAL_TRANSACTION_TYPE_GUARANTEED_ID,
    DEAL_TRANSACTION_TYPE_NON_RESERVED_ID,
    DEFAULT_CONFIGURE_AD_SOURCE,
    DEFAULT_CONSENT,
    DEFAULT_FIXED_PRICING_MODEL_ID,
    DEFAULT_USD_CURRENCY_ID,
} from "@app/features/deals/DealForm/useDealForm";
import { getValuesFromDealAdSource } from "./dealAdSourceFormReducerUtils";
import { LabeledValue } from "antd/lib/select";
import { getDealMarketplaceInfo } from "./utils";
import { parseDateStringFromApiAsUtcFreeString } from "@app/core/utils";

export const getDealType = (deal: BuyerDeal | DemandDeal | DealListItem) => {
    if (deal.dealTransactionType?.id === DEAL_TRANSACTION_TYPE_GUARANTEED_ID) {
        return DEAL_TYPES.GUARANTEE;
    }
    if (
        deal.dealTransactionType?.id === DEAL_TRANSACTION_TYPE_NON_RESERVED_ID &&
        deal.dealPricingType?.id === DEAL_PRICING_TYPE_AUCTION_PRICE
    ) {
        return DEAL_TYPES.AUCTION;
    }
    return DEAL_TYPES.FIXED;
};

export const getValidDurationValue = (amountInput: string | number | null): string | null => {
    if (amountInput === null || amountInput === "") {
        return null;
    }

    const parsedSeconds = Number(parseFloat(amountInput as string));
    const isInvalidNumber = Number.isNaN(parsedSeconds);

    if (isInvalidNumber) {
        return null;
    }

    return Math.abs(parsedSeconds).toFixed();
};

export const getSharedHiddenValue = (value: boolean | undefined): DealAdSourceTransparencey =>
    value ? TRANSPARENCY_SHARED_VALUE : TRANSPARENCY_HIDDEN_VALUE;
export const getMaskedSharedHiddenValue = (
    maskedUserId: boolean | undefined,
    value: boolean | undefined
): DealAdSourceTransparenceyMasked => (maskedUserId ? TRANSPARENCY_MASKED_VALUE : getSharedHiddenValue(value));
export const getOverrideValue = (
    value: boolean | undefined,
    specific: boolean | undefined
): DealAdSourceTransparenceyOverride => (specific ? TRANSPARENCY_OVERRIDE_VALUE : getSharedHiddenValue(value));

export const getSpecificValue = (
    value: boolean | undefined,
    specific: DealAdSourceTransparencyExtendedUserId[] | undefined
): DealAdSourceTransparencySpecific =>
    specific?.length ? TRANSPARENCY_SPECIFIC_VALUE : value ? TRANSPARENCY_ALL_VALUE : TRANSPARENCY_NONE_VALUE;

export const getTransparenciesValues = (
    transparencies: DealAdSourceTransparencyExtendedUserId[]
): DealAdSourceTransparencyExtendedUserId[] =>
    transparencies.map(({ extendedId, network }) => ({
        extendedId: {
            id: extendedId?.id,
            name: extendedId?.name,
            code: extendedId?.code,
        },
        network: {
            id: network?.id,
            name: network?.name,
        },
    }));

export const getBuyerSeats = (buyerSeats: DealListItem["buyerSeats"]): LabeledValue[] =>
    (buyerSeats || []).map((buyer) => ({
        label: `${buyer.code}${buyer.name ? ` [${buyer.name}]` : ""}`,
        value: JSON.stringify(buyer),
    }));

export const getValuesFromDeal = (
    deal: DealListItem,
    mode: DealFormMode,
    adSource: AdSource | undefined,
    seatFinanceDefaults: FinanceDefault[] | undefined
): DealFormValues => {
    const marketplaceInfo = getDealMarketplaceInfo(adSource?.marketplaceInfoList, deal);

    return {
        //Deal Terms Section
        [CREATE_DEAL_FORM_ITEMS_NAME.TYPE]: getDealType(deal),
        [CREATE_DEAL_FORM_ITEMS_NAME.TRANSACTION_TYPE]: deal?.dealTransactionType?.id || null,
        [CREATE_DEAL_FORM_ITEMS_NAME.ID]: mode === "copy" ? "" : deal?.code,
        [CREATE_DEAL_FORM_ITEMS_NAME.NAME]: mode === "copy" ? `Copy of ${deal?.name}` : deal?.name,
        [CREATE_DEAL_FORM_ITEMS_NAME.DSP]: deal?.network?.id,
        [CREATE_DEAL_FORM_ITEMS_NAME.BUYER]: getBuyerSeats(deal.buyerSeats),
        [CREATE_DEAL_FORM_ITEMS_NAME.START_DATE]: parseDateStringFromApiAsUtcFreeString(
            deal?.startTime,
            deal?.timeZone?.code
        ),
        [CREATE_DEAL_FORM_ITEMS_NAME.END_DATE]: parseDateStringFromApiAsUtcFreeString(
            deal?.endTime,
            deal?.timeZone?.code
        ),
        [CREATE_DEAL_FORM_ITEMS_NAME.TIME_ZONE]: deal?.timeZone?.id,
        [CREATE_DEAL_FORM_ITEMS_NAME.STATUS]: deal?.status?.id,
        [CREATE_DEAL_FORM_ITEMS_NAME.ORIGIN]: deal?.dealOrigin?.id,
        [CREATE_DEAL_FORM_ITEMS_NAME.PUBLISHER_NAME]: deal?.publisherName ?? "",
        [CREATE_DEAL_FORM_ITEMS_NAME.PUBLISHER_EMAIL]: deal?.publisherEmail ?? "",
        [CREATE_DEAL_FORM_ITEMS_NAME.SHARED_SEATS]: (deal?.sharedSeats || []).map((item) => item?.id),
        [CREATE_DEAL_FORM_ITEMS_NAME.SHARED_MARKEPLACES]: (deal?.sharedMarketplaces || []).map((item) => item?.id),

        //Deal pricing section
        [CREATE_DEAL_FORM_ITEMS_NAME.RATE]: deal?.rate ?? null,
        [CREATE_DEAL_FORM_ITEMS_NAME.CURRENCY]: deal?.currencyType?.id ?? DEFAULT_USD_CURRENCY_ID,
        [CREATE_DEAL_FORM_ITEMS_NAME.IMPRESSION]: deal?.bookingVolume ?? null,
        [CREATE_DEAL_FORM_ITEMS_NAME.PRICE_MODEL]: deal.priceModel?.id ?? DEFAULT_FIXED_PRICING_MODEL_ID,

        //Demand configuration section
        [CREATE_DEAL_FORM_ITEMS_NAME.DEAL_FREQUENCY_CAPPINGS]: marketplaceInfo?.dealFreqCappings ?? [],
        [CREATE_DEAL_FORM_ITEMS_NAME.TRANSACTION_CURRENCY]: marketplaceInfo?.currencyType?.id ?? null,
        [CREATE_DEAL_FORM_ITEMS_NAME.ENFORCEMENT]: marketplaceInfo?.enforcement?.id ?? DEAL_DEFAULT_ENFORCEMENT_ID,
        [CREATE_DEAL_FORM_ITEMS_NAME.FREQUENCY_CAPPING]:
            marketplaceInfo?.freqCappingType?.id ?? DEAL_DEFAULT_FREQUENCY_CAPPING_ID,
        [CREATE_DEAL_FORM_ITEMS_NAME.PRICE_TYPE]: deal?.dealPricingType?.id || null,
        [CREATE_DEAL_FORM_ITEMS_NAME.DEMAND_TARGETING]: marketplaceInfo?.targeting ?? [],

        // Configure Ad Source Section
        [CREATE_DEAL_FORM_ITEMS_NAME.CONFIGURE_AD_SOURCE]: adSource ? DEFAULT_CONFIGURE_AD_SOURCE : true,
        [CREATE_DEAL_FORM_ITEMS_NAME.CONSENT]: adSource ? DEFAULT_CONSENT : true,

        //Ad Source Configuration section
        ...getValuesFromDealAdSource(adSource, seatFinanceDefaults, false, mode),
    };
};
