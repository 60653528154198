import { FC } from "react";
import { Layout } from "antd";
import { Loading } from "@app/core/components";
import { useSeatAuthContext } from "@app/core/auth";
import { DiagnosticsPage } from "./DiagnosticsPage";
import { useParams } from "react-router-dom";
import conf from "@app/core/conf";

export const DiagnosticsLadleResultsPage: FC = () => {
    const { context, session } = useSeatAuthContext();
    const { seatId, ladleName } = useParams<{ seatId: string; ladleName: string }>();

    if (!context || !session) {
        return (
            <Layout.Content>
                <Loading />
            </Layout.Content>
        );
    }

    return (
        <DiagnosticsPage
            src={() =>
                `${conf.mctvConsoleRoot}/ssp/seats/${seatId}/${
                    context.isDemandSeat ? "demand" : "supply"
                }-diagnostics/ladle-results/${ladleName}`
            }
        />
    );
};
