import { useEffect, useMemo, useState } from "react";
import { Form } from "antd";
import { LabeledValue } from "antd/es/select";
import debounce from "lodash.debounce";
import { FILTER_INPUT_DEBOUNCE_TIME } from "@app/core/components/constants";
import { useGetExternalTaxonomiesQuery } from "@app/core/services";
import { FORM_FIELDS } from "../../constants";
import { OverrideMode } from "../../types";
import { getOverrideModeLabel } from "../../utils";

const { ADD_OVERRIDE_TAXONOMIES, TAXONOMY_OVERRIDE_MODE } = FORM_FIELDS;
const { ADD, OVERRIDE, NONE } = OverrideMode;

interface UseAddOverrideTaxonomiesSelect {
    value: LabeledValue[] | undefined;
    isDisabled: boolean;
    isRequired: boolean;
    label: string;
    options: LabeledValue[];
    isFetching: boolean;
    search: string;
    areAllOptionsSelected: boolean;
    onChangeSearch: (search: string) => void;
    onBlur: () => void;
    onChange: (_, selectedOptions: LabeledValue[]) => void;
    onChangeSelectAll: (selectedOptions: LabeledValue[]) => void;
    getPrimaryKey: (taxonomy: LabeledValue) => number;
}

export const useAddOverrideTaxonomiesSelect = (): UseAddOverrideTaxonomiesSelect => {
    const form = Form.useFormInstance();
    const value = Form.useWatch(ADD_OVERRIDE_TAXONOMIES.name, form);
    const taxonomyOverrideMode = Form.useWatch(TAXONOMY_OVERRIDE_MODE.name, form);

    const isDisabled = taxonomyOverrideMode === NONE;
    const isRequired = [ADD, OVERRIDE].includes(taxonomyOverrideMode);

    const label = getOverrideModeLabel(ADD_OVERRIDE_TAXONOMIES.label, taxonomyOverrideMode);

    const [keyword, setKeyword] = useState("");

    const debouncedKeyword = useMemo(
        () => debounce((value: string) => setKeyword(value), FILTER_INPUT_DEBOUNCE_TIME),
        [setKeyword]
    );

    const [search, setSearch] = useState("");

    const { data, isFetching } = useGetExternalTaxonomiesQuery({ keyword });

    const options = useMemo<LabeledValue[]>(
        () =>
            [...(data ?? [])]
                .filter(({ name }) => name.toLowerCase().includes(search.toLowerCase()))
                .map(({ id, name }) => ({
                    label: name,
                    value: id,
                }))
                .sort((a, b) => a.label.localeCompare(b.label, "en", { sensitivity: "base" })),
        [data, search]
    );

    const onChangeSearch = (search: string) => {
        setSearch(search);
        debouncedKeyword(search);
    };

    const onBlur = () => onChangeSearch("");

    const areAllOptionsSelected = Boolean(options && value?.length === options.length);

    const _onChange = (selectedOptions: LabeledValue[]) => {
        form.setFieldValue(ADD_OVERRIDE_TAXONOMIES.name, selectedOptions);
        form.validateFields([ADD_OVERRIDE_TAXONOMIES.name]);
    };

    const onChange = (_, selectedOptions: LabeledValue[]) => _onChange(selectedOptions);

    const onChangeSelectAll = (selectedOptions: LabeledValue[]) => _onChange(selectedOptions);

    const getPrimaryKey = ({ value }) => value;

    useEffect(() => () => debouncedKeyword.cancel(), [debouncedKeyword]);

    return {
        value,
        isDisabled,
        isRequired,
        label,
        options,
        isFetching,
        search,
        areAllOptionsSelected,
        onChangeSearch,
        onBlur,
        onChange,
        onChangeSelectAll,
        getPrimaryKey,
    };
};
