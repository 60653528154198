import { FC, useEffect } from "react";
import { Card, Col, Collapse, Form, Row } from "antd";
import { format } from "@rubicon/utils";
import { DealList, useGetAdSourceByIdQuery } from "@app/core/services/console";
import {
    adSourcePanel,
    adSourceTablePanelCalssList,
    classNameDealDetailsCardBody,
    mainStepSectionClassList,
    PanelHeader,
} from "./BuyerDealDetailsForm";
import { DEAL_SECTIONS_NAME, DEAL_SECTION_KEYS } from "../constants";
import { AdSourceConfigurationSidePanel, DealObjectSidePanel } from "../DealForm/DefinitionSidePanels";
import { DealDetailsPageAdSource } from "./DealDetailsPageAdSourceConfiguration/DealDetailsPageAdSource";
import { DealDetailsPageTableAdSource } from "./DealDetailsPageAdSourceConfiguration/DealDetailsPageTableAdSource";
import { MONTH_DAY_YEAR_HOUR_MINUTE_AMPM_DASHED } from "@app/core/components/constants";
import { useUserAccess } from "@app/core/auth";
import { useAppDispatch, useAppSelector } from "@app/core/store";
import { collapseChange, selectCollapseKeys } from "../DealDetailsDrawer/reducer";
import { formatDateInUtc } from "@app/core/utils";

interface Props {
    dealAdSource: DealList;
}

export const LegacyDealDetailsForm: FC<Props> = ({ dealAdSource }) => {
    const { deal, dealListSources } = dealAdSource;
    const { name, status, dealOrigin, code, updateDate, creationDate, entityType } = deal;

    const adSourceLen = dealListSources?.length;
    const isSingleAdSource = adSourceLen === 1;
    const adSourceId = isSingleAdSource ? dealListSources[0].id : null;

    const { data } = useGetAdSourceByIdQuery(Number(adSourceId), { skip: !isSingleAdSource && !adSourceId });
    const { hasInternalAccess } = useUserAccess();
    const dispatch = useAppDispatch();
    const keys = useAppSelector(selectCollapseKeys);

    useEffect(() => {
        const visibleKeys: Set<string> = new Set(DEAL_SECTION_KEYS.DEAL_TERMS);

        if (Boolean(adSourceLen)) {
            visibleKeys.add(DEAL_SECTION_KEYS.AD_SOURCE_CONFIGURATION);
        }

        const uniqKeys = Array.from(visibleKeys);
        dispatch(collapseChange({ keys: uniqKeys, availableKeys: uniqKeys }));
        return () => {
            dispatch(collapseChange({ keys: [], availableKeys: [] }));
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [adSourceLen]);

    return (
        <Form layout="vertical">
            <Collapse
                activeKey={keys}
                onChange={(keys: string[]) => {
                    dispatch(collapseChange({ keys }));
                }}
                bordered
                ghost={false}
                collapsible="header"
            >
                <Collapse.Panel
                    key={DEAL_SECTION_KEYS.DEAL_TERMS}
                    header={<PanelHeader title={DEAL_SECTIONS_NAME.DEAL_TERMS} />}
                    extra={<DealObjectSidePanel label={DEAL_SECTIONS_NAME.DEAL_TERMS} />}
                    className={mainStepSectionClassList}
                >
                    <Card bordered={false} className={classNameDealDetailsCardBody}>
                        <Row>
                            <Col sm={8}>
                                <Form.Item label="Deal ID">{code}</Form.Item>
                            </Col>
                            <Col sm={8}>
                                <Form.Item label="Name">{name}</Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={8}>
                                <Form.Item label="Status">{status.name}</Form.Item>
                            </Col>
                            {hasInternalAccess && (
                                <Col sm={8}>
                                    <Form.Item label="Deal Origin">{format.asSelf(dealOrigin?.name)}</Form.Item>
                                </Col>
                            )}
                        </Row>
                        <Row>
                            <Col sm={8}>
                                <Form.Item label="Last Updated (UTC)">
                                    {formatDateInUtc(updateDate, MONTH_DAY_YEAR_HOUR_MINUTE_AMPM_DASHED)}
                                </Form.Item>
                            </Col>
                            <Col sm={8}>
                                <Form.Item label="Date Created (UTC)">
                                    {formatDateInUtc(creationDate, MONTH_DAY_YEAR_HOUR_MINUTE_AMPM_DASHED)}
                                </Form.Item>
                            </Col>
                        </Row>
                    </Card>
                </Collapse.Panel>
                {Boolean(adSourceLen) && (
                    <Collapse.Panel
                        key={DEAL_SECTION_KEYS.AD_SOURCE_CONFIGURATION}
                        header={<PanelHeader title={DEAL_SECTIONS_NAME.AD_SOURCE_CONFIGURATION} />}
                        extra={<AdSourceConfigurationSidePanel />}
                        className={isSingleAdSource ? adSourcePanel : adSourceTablePanelCalssList}
                    >
                        {isSingleAdSource && data && (
                            <DealDetailsPageAdSource adSource={data} entityType={entityType} />
                        )}
                        {adSourceLen > 1 && <DealDetailsPageTableAdSource adSources={dealListSources} />}
                    </Collapse.Panel>
                )}
            </Collapse>
        </Form>
    );
};
