import { FC, useState } from "react";
import { ROUTE_FORMATTERS } from "@app/core/routing";
import { ItemType } from "antd/lib/menu/interface";
import { Space, Button, Dropdown, Tooltip } from "antd";
import { useHistory, useParams } from "react-router-dom";
import { downloadTags } from "@app/features/inventory/helpers";
import { useSeatAuthContext, useUserAccess } from "@app/core/auth";
import { AdsTextDrawer } from "@app/features/inventory/AdsTextDrawer";
import { INVENTORY_SEAT_DETAILS } from "@app/features/inventory/InventorySeat/constants";
import { InventoryDetailsDrawerType } from "@app/features/inventory/DetailsDrawer/reducer";
import { MonitorOutlined, TagsOutlined, EditOutlined, PlusOutlined, MoreOutlined } from "@ant-design/icons";

export const InventorySeatTableActions: FC = () => {
    const { seatId } = useParams<{ seatId: string }>();
    const { hasSeatWriteAccess } = useUserAccess();
    const { context } = useSeatAuthContext();
    const history = useHistory();
    const [isAdsTextDrawerVisible, setIsAdsTextDrawerVisible] = useState(false);

    const handleChangelog = (): void => {
        if (!context) return;
        history.push(
            ROUTE_FORMATTERS.SEAT_INVENTORY_HEALTH_DETAILS_CHANGE_HISTORY(
                false,
                seatId,
                InventoryDetailsDrawerType.SEAT
            ) as string
        );
        return;
    };

    const handleOpenAdsTextDrawer = () => setIsAdsTextDrawerVisible(true);

    const handleTagParams = (): void =>
        history.push(
            ROUTE_FORMATTERS.SEAT_INVENTORY_HEALTH_DETAILS_TAG_PARAMS(false, seatId, InventoryDetailsDrawerType.SEAT)
        );
    const handleEditClick = (): void =>
        history.push(ROUTE_FORMATTERS.SEAT_INVENTORY_SEAT_EDIT_TABS(seatId, INVENTORY_SEAT_DETAILS));

    const handleCreativeReview = (): void =>
        history.push(
            ROUTE_FORMATTERS.SEAT_INVENTORY_HEALTH_DETAILS_CREATIVE_REVIEW(
                false,
                seatId,
                InventoryDetailsDrawerType.SEAT
            )
        );

    const handleDiagnostics = (): void =>
        history.push(
            ROUTE_FORMATTERS.SEAT_INVENTORY_HEALTH_DETAILS_DIAGNOSTICS(false, seatId, InventoryDetailsDrawerType.SEAT)
        );
    const floorAnalysis = (): void =>
        history.push(
            ROUTE_FORMATTERS.SEAT_INVENTORY_HEALTH_DETAILS_FLOOR_ANALYSIS(
                false,
                seatId,
                InventoryDetailsDrawerType.SEAT
            )
        );

    const handleAddChannel = (): void =>
        history.push(ROUTE_FORMATTERS.SEAT_INVENTORY_DETAILS_PUBLISHER_CHANNEL_CREATE(seatId));

    const handleAddPublisher = (): void =>
        history.push(ROUTE_FORMATTERS.SEAT_INVENTORY_DETAILS_PUBLISHER_CREATE(seatId));
    const handleManageSeat = (): void => history.push(ROUTE_FORMATTERS.SEAT_INVENTORY_SEAT_DETAILS(seatId));

    const handleAddFloor = (): void => history.push(ROUTE_FORMATTERS.SEAT_INVENTORY_FLOOR_HIERARCHICAL(seatId));
    const handleCloseAdsTextDrawer = (): void => setIsAdsTextDrawerVisible(false);

    const handleGetTags = (): void => downloadTags(seatId);

    const items: ItemType[] = [
        {
            label: "Creative Review",
            key: "creativeReview",
            onClick: (e) => {
                e.domEvent.stopPropagation();
                handleCreativeReview();
            },
        },
        {
            label: "Floor Analysis",
            key: "floorAnalysis",
            onClick: (e) => {
                e.domEvent.stopPropagation();
                floorAnalysis();
            },
        },
        {
            label: "Change History",
            key: "changeHistory",
            onClick: (e) => {
                e.domEvent.stopPropagation();
                handleChangelog();
            },
        },
        hasSeatWriteAccess && {
            label: "Manage Seat",
            key: "manageSeat",
            onClick: (e) => {
                e.domEvent.stopPropagation();
                handleManageSeat();
            },
        },
        {
            label: "Ads.txt Pub ID",
            key: "adsTxtPubId",
            onClick: (e) => {
                e.domEvent.stopPropagation();
                handleOpenAdsTextDrawer();
            },
        },
        {
            label: "Get Tags",
            key: "getTags",
            onClick: (e) => {
                e.domEvent.stopPropagation();
                handleGetTags();
            },
        },
    ].filter(Boolean) as ItemType[];

    const createActionsItems: ItemType[] = [
        hasSeatWriteAccess && {
            label: "Add a Channel for Seat",
            key: "addChannel",
            onClick: (e) => {
                e.domEvent.stopPropagation();
                handleAddChannel();
            },
        },
        hasSeatWriteAccess && {
            label: "Add a Publisher for Seat",
            key: "addPublisher",
            onClick: (e) => {
                e.domEvent.stopPropagation();
                handleAddPublisher();
            },
        },
        hasSeatWriteAccess && {
            label: "Add a Floor for Seat",
            key: "addFloor",
            onClick: (e) => {
                e.domEvent.stopPropagation();
                handleAddFloor();
            },
        },
    ].filter(Boolean) as ItemType[];

    return (
        <Space style={{ width: "100%" }}>
            <Button.Group>
                <Tooltip title="Inbound Request Params">
                    <Button
                        onClick={(e) => {
                            e.stopPropagation();
                            handleTagParams();
                        }}
                        icon={<TagsOutlined />}
                        size="small"
                    />
                </Tooltip>
                <Tooltip title="Diagnostic">
                    <Button
                        onClick={(e) => {
                            e.stopPropagation();
                            handleDiagnostics();
                        }}
                        icon={<MonitorOutlined />}
                        size="small"
                    />
                </Tooltip>
                {hasSeatWriteAccess && (
                    <Tooltip title="Edit">
                        <Button
                            icon={<EditOutlined />}
                            onClick={(e) => {
                                e.stopPropagation();
                                handleEditClick();
                            }}
                            size="small"
                        />
                    </Tooltip>
                )}
                {hasSeatWriteAccess && (
                    <Tooltip title="Create">
                        <Dropdown placement="bottomLeft" trigger={["click"]} menu={{ items: createActionsItems }}>
                            <Button
                                icon={<PlusOutlined rotate={90} />}
                                size="small"
                                onClick={(e) => e.stopPropagation()}
                            />
                        </Dropdown>
                    </Tooltip>
                )}
                <Dropdown menu={{ items }} placement="bottomLeft" trigger={["click"]}>
                    <Button icon={<MoreOutlined rotate={90} />} size="small" onClick={(e) => e.stopPropagation()} />
                </Dropdown>
            </Button.Group>
            <AdsTextDrawer visible={isAdsTextDrawerVisible} handleClose={handleCloseAdsTextDrawer} />
        </Space>
    );
};
