import { useUserAccess } from "@app/core/auth";
import { useEffect, useState } from "react";
import { getMenuItems } from "./helpers";

interface UseMenuItems {
    menuItems: {
        key: string;
        label: string;
        dataSdet: string;
        icon: JSX.Element;
        route: string;
    }[];
}

export const useMenuItems = (seatId: number): UseMenuItems => {
    const { seatAccessFlags } = useUserAccess();
    const { hasControlsNavItems } = useUserAccess();
    const [menuItems, setMenuItems] = useState<
        {
            key: string;
            label: string;
            icon: JSX.Element;
            dataSdet: string;
            route: string;
        }[]
    >(getMenuItems(seatAccessFlags, seatId, hasControlsNavItems));

    useEffect(() => {
        setMenuItems(getMenuItems(seatAccessFlags, seatId, hasControlsNavItems));
    }, [seatAccessFlags, seatId, hasControlsNavItems]);

    return {
        menuItems,
    };
};
