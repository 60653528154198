import { FC } from "react";
import { Layout } from "antd";
import { PageHeader } from "@ant-design/pro-layout";
import { Loading } from "@app/core/components";
import { MappingExceptionsForm } from "../MappingExceptionsForm";
import { useMappingExceptionsEditPage } from "./useMappingExceptionsEditPage";

export const MappingExceptionsEditPage: FC = () => {
    const { mappingException, isFetching, title, onBack } = useMappingExceptionsEditPage();

    if (!mappingException || isFetching) {
        return <Loading />;
    }

    return (
        <Layout data-sdet="mapping-exceptions-edit-page">
            <PageHeader title={title} onBack={onBack} />
            <MappingExceptionsForm mappingException={mappingException} />
        </Layout>
    );
};
