import { FC, useState } from "react";
import { useUserAccess } from "@app/core/auth";
import { downloadTags } from "../../helpers";
import { Button, Form, Row, Collapse, Space } from "antd";
import { Loading, Title } from "@app/core/components";
import { AdsTextDrawer } from "../../AdsTextDrawer";
import { ROUTE_FORMATTERS, ROUTES } from "@app/core/routing";
import {
    INVENTORY_SEAT_DETAILS,
    INVENTORY_HEADER_COLLAPSE_CLASS_LIST,
} from "@app/features/inventory/InventorySeat/constants";
import { Route, Switch, useHistory, useParams } from "react-router-dom";
import { HelpKeysDrawer } from "@app/core/components/HelpKeysDrawer";
import {
    LabelsSection,
    GeneralSection,
    FinancialSection,
    BrandSafetySection,
    PerformanceSection,
    CustomPixelsSection,
    SeatDefaultsSection,
} from "@app/features/inventory/InventorySeat/SeatDetailsPage/SeatDetailsSection";
import { ChangelogDrawer } from "@app/features/inventory/InventoryChangelog";
import { InventoryCurrencyMode } from "@app/features/inventory/InventoryCurrencyMode/InventoryCurrencyMode";
import { useGetSeatSelfQuery, useGetSupplySeatQuery } from "@app/core/services/console";
import { useSeatDetailsDefenitionsFields } from "@app/features/inventory/InventorySeat/SeatDetailsPage/useSeatDetailsDefenitionsFields";

export const SeatDetailsPage: FC = () => {
    const history = useHistory();
    const { hasSeatWriteAccess } = useUserAccess();
    const { seatId } = useParams<{ seatId: string }>();

    const { data, isFetching: isLoadingSeat } = useGetSeatSelfQuery(seatId);
    const { data: supplySeat, isFetching } = useGetSupplySeatQuery(Number(seatId));
    const [isAdsTextDrawerVisible, setIsAdsTextDrawerVisible] = useState(false);

    const {
        labelsDefinitionsFields,
        generalDefinitionsFields,
        financialDefinitionsFields,
        seatDefaultsDefinitionsFields,
        brandSafetyDefinitionsFields,
        performanceDefinitionsFields,
        customPixelsDefinitionsFields,
    } = useSeatDetailsDefenitionsFields();

    if (!data?.seat || isFetching || isLoadingSeat) return <Loading />;

    const handleEditClick = (): void =>
        history.push(ROUTE_FORMATTERS.SEAT_INVENTORY_SEAT_EDIT_TABS(seatId, INVENTORY_SEAT_DETAILS));
    const handleAddPublisher = (): void =>
        history.push(ROUTE_FORMATTERS.SEAT_INVENTORY_DETAILS_PUBLISHER_CREATE(seatId));
    const handleAddChannel = (): void =>
        history.push(ROUTE_FORMATTERS.SEAT_INVENTORY_DETAILS_PUBLISHER_CHANNEL_CREATE(seatId));
    const handleChangelog = (): void => {
        history.push(ROUTE_FORMATTERS.SEAT_INVENTORY_SEAT_DETAILS_PAGE_CHANGE_HISTORY(seatId));
    };

    const handleGetTags = (): void => downloadTags(seatId);

    const handleOpenAdsTextDrawer = (): void => setIsAdsTextDrawerVisible(true);

    const handleCloseAdsTextDrawer = (): void => setIsAdsTextDrawerVisible(false);

    return (
        <>
            <Form layout="vertical">
                <Row justify="end">
                    <Button.Group>
                        <Button onClick={handleOpenAdsTextDrawer}>Ads.txt Pub Ids</Button>
                        <Button onClick={handleGetTags}>Get Tags</Button>
                        <Button onClick={handleChangelog}>Change History</Button>
                        {hasSeatWriteAccess && <Button onClick={handleAddChannel}>Add Channel</Button>}
                        {hasSeatWriteAccess && <Button onClick={handleAddPublisher}>Add Publisher</Button>}
                        {hasSeatWriteAccess && (
                            <Button onClick={handleEditClick} type="primary">
                                Edit
                            </Button>
                        )}
                    </Button.Group>
                </Row>
                <Collapse
                    bordered
                    ghost={false}
                    className={INVENTORY_HEADER_COLLAPSE_CLASS_LIST}
                    defaultActiveKey={["0", "1", "2", "3", "4", "5", "6"]}
                    items={[
                        {
                            key: "0",
                            children: <PerformanceSection />,
                            collapsible: "header",
                            label: (
                                <Space>
                                    <Title level={5} title="Performance" />
                                    <InventoryCurrencyMode />
                                </Space>
                            ),
                            extra: (
                                <HelpKeysDrawer
                                    panelLabel="Performance"
                                    definitionsFields={performanceDefinitionsFields}
                                />
                            ),
                        },
                        {
                            key: "1",
                            collapsible: "header",
                            children: <GeneralSection seat={data?.seat} supplySeat={supplySeat} />,
                            label: <Title level={5} title="General" />,
                            extra: <HelpKeysDrawer panelLabel="General" definitionsFields={generalDefinitionsFields} />,
                        },
                        {
                            key: "2",
                            collapsible: "header",
                            children: <FinancialSection seat={data?.seat} />,
                            label: <Title level={5} title="Financial" />,
                            extra: (
                                <HelpKeysDrawer panelLabel="Financial" definitionsFields={financialDefinitionsFields} />
                            ),
                        },
                        {
                            key: "3",
                            collapsible: "header",
                            children: <SeatDefaultsSection seat={data?.seat} />,
                            label: <Title level={5} title="Seat Defaults" />,
                            extra: (
                                <HelpKeysDrawer
                                    panelLabel="Seat Defaults"
                                    definitionsFields={seatDefaultsDefinitionsFields}
                                />
                            ),
                        },
                        {
                            key: "4",
                            collapsible: "header",
                            children: <BrandSafetySection seat={data?.seat} />,
                            label: <Title level={5} title="Brand Safety" />,
                            extra: (
                                <HelpKeysDrawer
                                    panelLabel="Brand Safety"
                                    definitionsFields={brandSafetyDefinitionsFields}
                                />
                            ),
                        },
                        {
                            key: "5",
                            collapsible: "header",
                            children: <CustomPixelsSection seat={data?.seat} />,
                            label: <Title level={5} title="Custom Pixels" />,
                            extra: (
                                <HelpKeysDrawer
                                    panelLabel="Custom Pixels"
                                    definitionsFields={customPixelsDefinitionsFields}
                                />
                            ),
                        },
                        {
                            key: "6",
                            collapsible: "header",
                            children: <LabelsSection seat={data?.seat} />,
                            label: <Title level={5} title="Labels" />,
                            extra: <HelpKeysDrawer panelLabel="Labels" definitionsFields={labelsDefinitionsFields} />,
                        },
                    ]}
                />
            </Form>
            <Switch>
                <Route path={ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_SEAT_CHANGE_HISTORY}>
                    <ChangelogDrawer />
                </Route>
            </Switch>
            <AdsTextDrawer visible={isAdsTextDrawerVisible} handleClose={handleCloseAdsTextDrawer} />
        </>
    );
};
