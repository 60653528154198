import { FC } from "react";
import { AdSource } from "@app/core/services";
import { Card, Typography, Collapse, Flex } from "antd";
import {
    cardBodyStyle,
    sectionHeaderCollapseStyle,
    secondaryStepsSectionClassList,
} from "@app/features/deals/DealForm";
import { AdSourceDealsListTable } from "@app/features/deals/DealForm/DealFormCreateAdSource/DealFormCreateAdSourceSections/AdSourceDemandSection/AdSourceDealsListTable";
import { css, cx } from "@emotion/css";

const classList = css`
    border-bottom: 0;
`;

interface AdSourceDemandSectionProps {
    dealAdSourceData: AdSource | undefined;
}

export const AdSourceDemandSection: FC<AdSourceDemandSectionProps> = ({ dealAdSourceData }) => {
    return (
        <Collapse
            className={cx(secondaryStepsSectionClassList, classList)}
            defaultActiveKey={["0"]}
            collapsible="header"
            items={[
                {
                    key: "0",
                    style: sectionHeaderCollapseStyle,
                    children: (
                        <Card bordered={false} styles={{ body: cardBodyStyle }}>
                            <AdSourceDealsListTable dealAdSourceData={dealAdSourceData} />
                        </Card>
                    ),
                    label: (
                        <Flex
                            align="center"
                            gap="small"
                            style={{ fontSize: "1rem" }}
                            data-sdet="deal-form-ad-source-demand-section"
                        >
                            <Typography.Title style={{ marginBottom: 0 }} level={5}>
                                Demand Section
                            </Typography.Title>
                            <Typography.Text style={{ fontWeight: "normal" }}>
                                (Deals that use this Ad Source Container)
                            </Typography.Text>
                        </Flex>
                    ),
                },
            ]}
        />
    );
};
