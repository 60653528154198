import { useMemo } from "react";
import { LabeledValue } from "antd/lib/select";
import { useAppDispatch, useAppSelector } from "@app/core/store";
import { useGetAdvertiserBlockingModesQuery } from "@app/core/services";
import { CREATE_DEAL_FORM_ITEMS_NAME } from "@app/features/deals/constants";
import { dealFormSelectFieldChange } from "@app/features/deals/DealForm/reducer";
import { AdSourceAdvertiserDomainModeIds, DefaultIndustriesMode } from "@app/features/seatAdSources/constants";

interface UseAdSourceModeField {
    options: LabeledValue[];
    isFetching: boolean;
    onChange: (value: number) => void;
    value?: number | null;
}

export const useAdSourceModelField = (): UseAdSourceModeField => {
    const dispatch = useAppDispatch();
    const { data, isFetching } = useGetAdvertiserBlockingModesQuery();

    const value: number | null = useAppSelector(
        (state) => state.dealForm.values[CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_MODE]
    );
    const options: LabeledValue[] = useMemo(
        () => (data || []).map((item) => ({ label: item.name, value: item.id })),
        [data]
    );

    const onChange = (value: number): void => {
        if (value === AdSourceAdvertiserDomainModeIds.ALLOW_OVERRIDE_INVENTORY) {
            dispatch(
                dealFormSelectFieldChange({
                    field: CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_IAB_CATEGORIES,
                    value: [],
                })
            );
            dispatch(
                dealFormSelectFieldChange({
                    field: CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_INDUSTRIES_MODE,
                    value: DefaultIndustriesMode.id,
                })
            );
            dispatch(
                dealFormSelectFieldChange({
                    field: CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_BLOCKED_INDUSTRIES,
                    value: null,
                })
            );
        }
        dispatch(
            dealFormSelectFieldChange({
                field: CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_REUSABLE_ADVERTISER_DOMAIN,
                value: [],
            })
        );
        dispatch(dealFormSelectFieldChange({ field: CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_MODE, value }));
    };
    return {
        value,
        options,
        onChange,
        isFetching,
    };
};
