import {
    AdUnit,
    Brand,
    BrandSupply,
    BulkOperationSourceEntity,
    useGetAdUnitQuery,
    useGetBrandByIdQuery,
    useGetPublisherByIdQuery,
    useGetSupplyQuery,
} from "@app/core/services";
import { LabeledValue } from "antd/es/select";

const getBrandLabel = (brand: Brand) => {
    return `${brand?.publisher?.seat?.name} > ${brand?.publisher?.name} > ${brand?.name}`;
};

const getSupplyLabel = (supply: BrandSupply) => {
    return `${supply?.brand?.publisher?.seat?.name} > ${supply?.brand?.publisher?.name} > ${supply?.brand?.name} > ${supply?.name}`;
};

export const useGetEntityLabeledValue = (
    entityType: BulkOperationSourceEntity | null,
    id: number | null
): LabeledValue => {
    let label: string | undefined;

    const { data: publisher } = useGetPublisherByIdQuery(id as number, {
        skip: entityType !== BulkOperationSourceEntity.Publisher || !id,
    });

    const { data: brand } = useGetBrandByIdQuery(id as number, {
        skip: entityType !== BulkOperationSourceEntity.Brand || !id,
    });

    const { data: supply } = useGetSupplyQuery(id as number, {
        skip: entityType !== BulkOperationSourceEntity.Supply || !id,
    });

    const { data: adUnit } = useGetAdUnitQuery(id as number, {
        skip: entityType !== BulkOperationSourceEntity.AdUnit || !id,
    });

    switch (entityType) {
        case BulkOperationSourceEntity.Publisher:
            label = `${publisher?.seat?.name} > ${publisher?.name}`;
            break;
        case BulkOperationSourceEntity.Brand: {
            label = getBrandLabel(brand as Brand);
            break;
        }
        case BulkOperationSourceEntity.Supply: {
            label = getSupplyLabel(supply as BrandSupply);
            break;
        }
        case BulkOperationSourceEntity.AdUnit: {
            const { name, supply } = adUnit as AdUnit;
            const { name: supplyName, brand } = supply;
            const { name: brandName, publisher } = brand;
            const { name: publisherName, seat } = publisher;

            label = `${seat?.name} > ${publisherName} > ${brandName} > ${supplyName} > ${name}`;
            break;
        }
    }

    return { value: id, label } as LabeledValue;
};
