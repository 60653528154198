import { ControlRoute, CONTROLS_ROUTES } from "@app/features/controls/constants";
import {
    IntegrationConsoleRoute,
    INTEGRATIONS_ROUTES_CONSOLE,
} from "@app/features/integrations/integrationsConsole/constant";
import { BrandSafetySectionKey } from "@app/features/inventory/BrandSafetyDetailsDrawer/helpKeysButton";
import { INVENTORY_LIST_PAGES } from "@app/features/inventory/constants";
import { InventoryDetailsDrawerType } from "@app/features/inventory/DetailsDrawer/reducer";
import {
    BRAND_SAFETY_ADVANCEDL_KEY,
    BRAND_SAFETY_HIERARCHICAL_KEY,
} from "@app/features/inventory/InventoryBrandSafety/InventoryBrandSafetyPage";
import { INVENTORY_FLOORS_ADVANCED, INVENTORY_FLOORS_HIERARCHICAL } from "@app/features/inventory/InventoryFloors";
import { ADVANCED_FLOOR_SECTION_NAMES } from "@app/features/inventory/InventoryFloors/SeatFloorsAdvancedSwtich/SeatFloorsAdvancedDetails/constants";
import { INVENTORY_HEALTH_MODELS } from "@app/features/inventory/InventoryHealth/constants";
import { SECTIONS_NAMES } from "@app/features/inventory/InventoryHealthDetailsPages/constants";
import {
    INVENTORY_SEAT_ADVANCED,
    INVENTORY_SEAT_DEFAULT_PRICES,
    INVENTORY_SEAT_DETAILS,
    INVENTORY_SEAT_FLOORS,
    INVENTORY_SEAT_INTERNAL,
    INVENTORY_SEAT_INTERNAL_DEFINITION,
    INVENTORY_SEAT_PROTECTIONS,
    INVENTORY_SEAT_TABS_TYPES,
} from "@app/features/inventory/InventorySeat/constants";
import { ReportRoute, REPORTS_ROUTES } from "@app/features/reports/constants";
import { CUSTOM_REPORTS_ROUTES, CustomReportTabRoute } from "@app/features/reports/CustomReports/constants";

const replaceWith = (path: string, map: { [key: string]: string | number }) =>
    Object.keys(map).reduce((acc, key) => acc.replace(key, String(map[key])), path);

export const ROUTE_FORMATTERS = {
    ADMIN_SEAT: (seatId: number | string) => replaceWith(ROUTES.ADMIN, { ":seatId": seatId }),
    BUYER_DASHBOARD: (buyerId: number | string) => replaceWith(ROUTES.BUYER_DASHBOARD, { ":buyerId": buyerId }),
    BUYER_DEAL_DASHBOARD: (buyerId: number | string) =>
        replaceWith(ROUTES.BUYER_DEAL_DASHBOARD, { ":buyerId": buyerId }),
    BUYER_DEAL_LIBRARY: (buyerId: number | string) => replaceWith(ROUTES.BUYER_DEAL_LIBRARY, { ":buyerId": buyerId }),
    BUYER_DEAL_AD_CAMPAIGNS: (buyerId: number | string) =>
        replaceWith(ROUTES.BUYER_DEAL_AD_CAMPAIGNS, { ":buyerId": buyerId }),
    BUYER_DEAL_TRANSPARENCY: (buyerId: number | string) =>
        replaceWith(ROUTES.BUYER_DEAL_TRANSPARENCY, { ":buyerId": buyerId }),
    BUYER_DIAGNOSTICS: (buyerId: number | string) => replaceWith(ROUTES.BUYER_DIAGNOSTICS, { ":buyerId": buyerId }),
    BUYER_REPORTS: (buyerId: number | string) => replaceWith(ROUTES.BUYER_REPORTS, { ":buyerId": buyerId }),
    BUYER_DISCOUNTS: (buyerId: number | string) => replaceWith(ROUTES.BUYER_DISCOUNTS, { ":buyerId": buyerId }),
    DEAL_REDIRECT: (dealId: number | string) => replaceWith(ROUTES.DEAL_REDIRECT, { ":dealId": dealId }),
    LOGIN_FORM: (step: "context" | "email" | "password") => replaceWith(ROUTES.LOGIN_FORM, { ":step": step }),
    SEAT_AD_SERVING: (seatId: number | string) => replaceWith(ROUTES.SEAT_AD_SERVING, { ":seatId": seatId }),
    SEAT_AD_SOURCES: (seatId: number | string) => replaceWith(ROUTES.SEAT_AD_SOURCES, { ":seatId": seatId }),
    SEAT_AD_SOURCES_COPY: (seatId: number | string, adSourceId: number | string) =>
        replaceWith(ROUTES.SEAT_AD_SOURCES_COPY, { ":seatId": seatId, ":adSourceId": adSourceId }),
    SEAT_AD_SOURCES_CREATE: (seatId: number | string) =>
        replaceWith(ROUTES.SEAT_AD_SOURCES_CREATE, { ":seatId": seatId }),
    SEAT_AD_SOURCES_DETAILS: (seatId: number | string, adSourceId: number | string) =>
        replaceWith(ROUTES.SEAT_AD_SOURCES_DETAILS, { ":seatId": seatId, ":adSourceId": adSourceId }),
    SEAT_AD_SOURCES_DETAILS_DRAWER: (seatId: number | string, adSourceId: number | string) =>
        replaceWith(ROUTES.SEAT_AD_SOURCES_DETAILS_DRAWER, { ":seatId": seatId, ":adSourceId": adSourceId }),
    SEAT_AD_SOURCES_DETAILS_DRAWER_SECONDARY_VIEW: (
        seatId: number | string,
        adSourceId: number | string,
        secondaryView: string
    ) =>
        replaceWith(ROUTES.SEAT_AD_SOURCES_DETAILS_DRAWER_SECONDARY_VIEW, {
            ":seatId": seatId,
            ":adSourceId": adSourceId,
            ":secondaryView": secondaryView,
        }),
    SEAT_AD_SOURCES_DETAILS_PAGE_SECONDARY_VIEW: (
        seatId: number | string,
        adSourceId: number | string,
        secondaryView: string
    ) =>
        replaceWith(ROUTES.SEAT_AD_SOURCES_DETAILS_PAGE_SECONDARY_VIEW, {
            ":seatId": seatId,
            ":adSourceId": adSourceId,
            ":secondaryView": secondaryView,
        }),
    SEAT_AD_SOURCES_SECONDARY_VIEW_DRAWER: (
        seatId: number | string,
        adSourceId: number | string,
        secondaryView: string
    ) =>
        replaceWith(ROUTES.SEAT_AD_SOURCES_SECONDARY_VIEW_DRAWER, {
            ":seatId": seatId,
            ":adSourceId": adSourceId,
            ":secondaryView": secondaryView,
        }),
    SEAT_AD_SOURCES_EDIT: (seatId: number | string, adSourceId: number | string) =>
        replaceWith(ROUTES.SEAT_AD_SOURCES_EDIT, { ":seatId": seatId, ":adSourceId": adSourceId }),
    SEAT_AD_SOURCES_LIST: (seatId: number | string) => replaceWith(ROUTES.SEAT_AD_SOURCES, { ":seatId": seatId }),
    SEAT_AD_SOURCES_PACING: (seatId: number | string) =>
        replaceWith(ROUTES.SEAT_AD_SOURCES_PACING, { ":seatId": seatId }),
    SEAT_AD_SOURCES_PACING_DETAILS: (seatId: number | string, adSourceId: number | string) =>
        replaceWith(ROUTES.SEAT_AD_SOURCES_PACING_DETAILS, { ":seatId": seatId, ":adSourceId": adSourceId }),
    SEAT_AD_SOURCES_PACING_DETAILS_SECONDARY_VIEW: (
        seatId: number | string,
        adSourceId: number | string,
        secondaryView: string
    ) =>
        replaceWith(ROUTES.SEAT_AD_SOURCES_PACING_DETAILS_SECONDARY_VIEW, {
            ":seatId": seatId,
            ":adSourceId": adSourceId,
            ":secondaryView": secondaryView,
        }),
    SEAT_AD_SOURCES_PACING_SECONDARY_VIEW_DRAWER: (
        seatId: number | string,
        adSourceId: number | string,
        secondaryView: string
    ) =>
        replaceWith(ROUTES.SEAT_AD_SOURCES_PACING_SECONDARY_VIEW_DRAWER, {
            ":seatId": seatId,
            ":adSourceId": adSourceId,
            ":secondaryView": secondaryView,
        }),
    SEAT_CONTROLS: (seatId: number | string) => replaceWith(ROUTES.SEAT_CONTROLS, { ":seatId": seatId }),
    SEAT_CONTROLS_TABS: (
        seatId: number | string,
        tabs: ControlRoute | ControlRoute[] = [CONTROLS_ROUTES.TAG_PARAMETERS]
    ) =>
        replaceWith(ROUTES.SEAT_CONTROLS_TABS, {
            ":seatId": seatId,
            ":tab": Array.isArray(tabs) ? tabs.join("/") : tabs,
        }),
    SEAT_CONTROLS_BRAND_SAFETY_ADVERTISER_DOMAIN_DETAILS: (seatId: number | string, id: number | string) =>
        replaceWith(ROUTES.SEAT_CONTROLS_BRAND_SAFETY_ADVERTISER_DOMAIN_DETAILS, {
            ":seatId": seatId,
            ":id": id,
        }),
    SEAT_CONTROLS_BRAND_SAFETY_ADVERTISER_DOMAIN_CREATE: (seatId: number | string) =>
        replaceWith(ROUTES.SEAT_CONTROLS_BRAND_SAFETY_ADVERTISER_DOMAIN_CREATE, {
            ":seatId": seatId,
        }),
    SEAT_CONTROLS_BRAND_SAFETY_ADVERTISER_DOMAIN_EDIT: (seatId: number | string, id: number | string) =>
        replaceWith(ROUTES.SEAT_CONTROLS_BRAND_SAFETY_ADVERTISER_DOMAIN_EDIT, {
            ":seatId": seatId,
            ":id": id,
        }),
    SEAT_CONTROLS_BRAND_SAFETY_SEQUESTERED_DOMAIN_EDIT: (seatId: number | string, domain: number | string) =>
        replaceWith(ROUTES.SEAT_CONTROLS_BRAND_SAFETY_SEQUESTERED_DOMAIN_EDIT, {
            ":seatId": seatId,
            ":domain": domain,
        }),
    SEAT_CONTROLS_BRAND_SAFETY_TRANSPARENCY_RULES_CREATE: (seatId: number | string) =>
        replaceWith(ROUTES.SEAT_CONTROLS_BRAND_SAFETY_TRANSPARENCY_RULES_CREATE, {
            ":seatId": seatId,
        }),
    SEAT_CONTROLS_BRAND_SAFETY_TRANSPARENCY_RULES_EDIT: (seatId: number | string, id: number | string) =>
        replaceWith(ROUTES.SEAT_CONTROLS_BRAND_SAFETY_TRANSPARENCY_RULES_EDIT, {
            ":seatId": seatId,
            ":id": id,
        }),
    SEAT_CONTROLS_BULK_OPERATIONS: (seatId: number | string) =>
        replaceWith(ROUTES.SEAT_CONTROLS_BULK_OPERATIONS, { ":seatId": seatId }),
    SEAT_CONTROLS_BULK_OPERATIONS_CREATE: (seatId: number | string) =>
        replaceWith(ROUTES.SEAT_CONTROLS_BULK_OPERATIONS_CREATE, {
            ":seatId": seatId,
        }),
    SEAT_CONTROLS_CLASSIFICATIONS_TABS: (seatId: number | string, tab: string) =>
        replaceWith(ROUTES.SEAT_CONTROLS_CLASSIFICATIONS_TABS, { ":seatId": seatId, ":classificationsTab": tab }),
    SEAT_CONTROLS_CLASSIFICATIONS_ADVERTISER_DETAILS_PAGE: (seatId: number | string, advertiserId: number | string) =>
        replaceWith(ROUTES.SEAT_CONTROLS_CLASSIFICATIONS_ADVERTISER_DETAILS_PAGE, {
            ":seatId": seatId,
            ":advertiserId": advertiserId,
        }),
    SEAT_CONTROLS_CLASSIFICATIONS_ADVERTISER_CREATE_PAGE: (seatId: number | string) =>
        replaceWith(ROUTES.SEAT_CONTROLS_CLASSIFICATIONS_ADVERTISER_CREATE_PAGE, {
            ":seatId": seatId,
        }),
    SEAT_CONTROLS_CLASSIFICATIONS_ADVERTISER_EDIT_PAGE: (seatId: number | string, advertiserId: number | string) =>
        replaceWith(ROUTES.SEAT_CONTROLS_CLASSIFICATIONS_ADVERTISER_EDIT_PAGE, {
            ":seatId": seatId,
            ":advertiserId": advertiserId,
        }),
    SEAT_CONTROLS_CLASSIFICATIONS_INDUSTRY_CREATE_PAGE: (seatId: number | string) =>
        replaceWith(ROUTES.SEAT_CONTROLS_CLASSIFICATIONS_INDUSTRY_CREATE_PAGE, {
            ":seatId": seatId,
        }),
    SEAT_CONTROLS_CLASSIFICATIONS_INDUSTRY_DETAILS_PAGE: (seatId: number | string, industryId: number | string) =>
        replaceWith(ROUTES.SEAT_CONTROLS_CLASSIFICATIONS_INDUSTRY_DETAILS_PAGE, {
            ":seatId": seatId,
            ":industryId": industryId,
        }),
    SEAT_CONTROLS_CLASSIFICATIONS_INDUSTRY_EDIT_PAGE: (seatId: number | string, industryId: number | string) =>
        replaceWith(ROUTES.SEAT_CONTROLS_CLASSIFICATIONS_INDUSTRY_EDIT_PAGE, {
            ":seatId": seatId,
            ":industryId": industryId,
        }),
    SEAT_CONTROLS_CLASSIFICATIONS_LABEL_CREATE_PAGE: (seatId: number | string) =>
        replaceWith(ROUTES.SEAT_CONTROLS_CLASSIFICATIONS_LABEL_CREATE_PAGE, {
            ":seatId": seatId,
        }),
    SEAT_CONTROLS_CLASSIFICATIONS_LABEL_DETAILS_PAGE: (seatId: number | string, labelId: number | string) =>
        replaceWith(ROUTES.SEAT_CONTROLS_CLASSIFICATIONS_LABEL_DETAILS_PAGE, {
            ":seatId": seatId,
            ":labelId": labelId,
        }),
    SEAT_CONTROLS_CLASSIFICATIONS_LABEL_EDIT_PAGE: (seatId: number | string, labelId: number | string) =>
        replaceWith(ROUTES.SEAT_CONTROLS_CLASSIFICATIONS_LABEL_EDIT_PAGE, {
            ":seatId": seatId,
            ":labelId": labelId,
        }),
    SEAT_CONTROLS_CUSTOM_PIXELS: (seatId: number | string) =>
        replaceWith(ROUTES.SEAT_CONTROLS_CUSTOM_PIXELS, { ":seatId": seatId }),
    SEAT_CONTROLS_CUSTOM_PIXELS_CREATE: (seatId: number | string) =>
        replaceWith(ROUTES.SEAT_CONTROLS_CUSTOM_PIXELS_CREATE, { ":seatId": seatId }),
    SEAT_CONTROLS_CUSTOM_PIXELS_DETAILS: (seatId: number | string, pixelId: number | string) =>
        replaceWith(ROUTES.SEAT_CONTROLS_CUSTOM_PIXELS_DETAILS, { ":seatId": seatId, ":pixelId": pixelId }),
    SEAT_CONTROLS_CUSTOM_PIXELS_DETAILS_DRAWER: (seatId: number | string, pixelId: number | string) =>
        replaceWith(ROUTES.SEAT_CONTROLS_CUSTOM_PIXELS_DETAILS_DRAWER, {
            ":seatId": seatId,
            ":pixelId": pixelId,
        }),
    SEAT_CONTROLS_CUSTOM_PIXELS_EDIT: (seatId: number | string, pixelId: number | string) =>
        replaceWith(ROUTES.SEAT_CONTROLS_CUSTOM_PIXELS_EDIT, { ":seatId": seatId, ":pixelId": pixelId }),
    SEAT_CONTROLS_PRICE_OVERRIDES: (seatId: number | string) =>
        replaceWith(ROUTES.SEAT_CONTROLS_PRICE_OVERRIDES, { ":seatId": seatId }),
    SEAT_CONTROLS_TARGETING: (seatId: number | string) =>
        replaceWith(ROUTES.SEAT_CONTROLS_TARGETING, { ":seatId": seatId }),
    SEAT_CONTROLS_TARGETING_CREATE: (seatId: number | string) =>
        replaceWith(ROUTES.SEAT_CONTROLS_TARGETING_CREATE, { ":seatId": seatId }),
    SEAT_CONTROLS_TARGETING_DETAILS: (seatId: number | string, targetingId: number | string) =>
        replaceWith(ROUTES.SEAT_CONTROLS_TARGETING_DETAILS, { ":seatId": seatId, ":targetingId": targetingId }),
    SEAT_CONTROLS_TARGETING_EDIT: (seatId: number | string, targetingId: string | number) =>
        replaceWith(ROUTES.SEAT_CONTROLS_TARGETING_EDIT, { ":seatId": seatId, ":targetingId": targetingId }),
    SEAT_CONTROLS_TARGETING_DETAILS_DRAWER: (seatId: number | string, targetingId: number | string) =>
        replaceWith(ROUTES.SEAT_CONTROLS_TARGETING_DETAILS_DRAWER, {
            ":seatId": seatId,
            ":targetingId": targetingId,
        }),
    SEAT_CONTROLS_TARGETING_CHANGE_HISTORY: (seatId: number | string, targetingId: number | string) =>
        replaceWith(ROUTES.SEAT_CONTROLS_TARGETING_CHANGE_HISTORY, {
            ":seatId": seatId,
            ":targetingId": targetingId,
        }),
    SEAT_CONTROLS_PRICE_OVERRIDE_CREATE_PAGE: (seatId: number | string) =>
        replaceWith(ROUTES.SEAT_CONTROLS_PRICE_OVERRIDE_CREATE_PAGE, {
            ":seatId": seatId,
        }),
    SEAT_CONTROLS_PRICE_OVERRIDE_DETAILS_PAGE: (seatId: number | string, priceOverrideId: number | string) =>
        replaceWith(ROUTES.SEAT_CONTROLS_PRICE_OVERRIDE_DETAILS_PAGE, {
            ":seatId": seatId,
            ":priceOverrideId": priceOverrideId,
        }),
    SEAT_CONTROLS_PRICE_OVERRIDE_EDIT_PAGE: (seatId: number | string, priceOverrideId: number | string) =>
        replaceWith(ROUTES.SEAT_CONTROLS_PRICE_OVERRIDE_EDIT_PAGE, {
            ":seatId": seatId,
            ":priceOverrideId": priceOverrideId,
        }),
    SEAT_CONTROLS_TABS_CREATE: (
        seatId: number | string,
        tab: ControlRoute = CONTROLS_ROUTES.TAG_PARAMETERS,
        id: number
    ) => replaceWith(ROUTES.SEAT_CONTROLS_TABS_CREATE, { ":seatId": seatId, ":tab": tab, ":id": id }),
    SEAT_CONTROLS_BRAND_SAFETY_TABS: (
        seatId: number | string,
        tab: ControlRoute = CONTROLS_ROUTES.ADVERTISER_DOMAINS
    ) => replaceWith(ROUTES.SEAT_CONTROLS_BRAND_SAFETY, { ":seatId": seatId, ":tab": tab }),
    SEAT_CONTROLS_BRAND_SAFETY_ADVANCED_BRAND_SAFETY_DETAILS_PAGE: (seatId: number | string, id: string | number) =>
        replaceWith(ROUTES.SEAT_CONTROLS_BRAND_SAFETY_ADVANCED_BRAND_SAFETY_DETAILS_PAGE, {
            ":seatId": seatId,
            ":id": id,
        }),
    SEAT_CONTROLS_BRAND_SAFETY_ADVANCED_BRAND_SAFETY_DETAILS_DRAWER: (seatId: number | string, id: string | number) =>
        replaceWith(ROUTES.SEAT_CONTROLS_BRAND_SAFETY_ADVANCED_BRAND_SAFETY_DETAILS_DRAWER, {
            ":seatId": seatId,
            ":id": id,
        }),
    SEAT_CONTROLS_BRAND_SAFETY_ADVANCED_BRAND_SAFETY_EDIT_PAGE: (seatId: number | string, id: string | number) =>
        replaceWith(ROUTES.SEAT_CONTROLS_BRAND_SAFETY_ADVANCED_BRAND_SAFETY_EDIT_PAGE, {
            ":seatId": seatId,
            ":id": id,
        }),
    SEAT_CONTROLS_BRAND_SAFETY_ADVANCED_BRAND_SAFETY_CREATE_PAGE: (seatId: number | string) =>
        replaceWith(ROUTES.SEAT_CONTROLS_BRAND_SAFETY_ADVANCED_BRAND_SAFETY_CREATE_PAGE, {
            ":seatId": seatId,
        }),
    SEAT_CONTROLS_BRAND_SAFETY_SEPARATION_GROUPS: (seatId: number | string) =>
        replaceWith(ROUTES.SEAT_CONTROLS_BRAND_SAFETY_SEPARATION_GROUPS, {
            ":seatId": seatId,
        }),
    SEAT_CONTROLS_BRAND_SAFETY_SEPARATION_GROUPS_DETAILS_DRAWER: (seatId: number | string, id: string | number) =>
        replaceWith(ROUTES.SEAT_CONTROLS_BRAND_SAFETY_SEPARATION_GROUPS_DETAILS_DRAWER, {
            ":seatId": seatId,
            ":id": id,
        }),
    SEAT_CONTROLS_BRAND_SAFETY_SEPARATION_GROUPS_DETAILS_PAGE: (seatId: number | string, id: string | number) =>
        replaceWith(ROUTES.SEAT_CONTROLS_BRAND_SAFETY_SEPARATION_GROUPS_DETAILS_PAGE, {
            ":seatId": seatId,
            ":id": id,
        }),
    SEAT_CONTROLS_BRAND_SAFETY_SEPARATION_GROUPS_CREATE_PAGE: (seatId: number | string) =>
        replaceWith(ROUTES.SEAT_CONTROLS_BRAND_SAFETY_SEPARATION_GROUPS_CREATE_PAGE, {
            ":seatId": seatId,
        }),

    SEAT_CONTROLS_BRAND_SAFETY_SEPARATION_GROUPS_EDIT_PAGE: (seatId: number | string, id: number | string) =>
        replaceWith(ROUTES.SEAT_CONTROLS_BRAND_SAFETY_SEPARATION_GROUPS_EDIT_PAGE, {
            ":seatId": seatId,
            ":id": id,
        }),
    SEAT_CONTROLS_BRAND_SAFETY_BUYER_SEAT_LIST: (seatId: number | string) =>
        replaceWith(ROUTES.SEAT_CONTROLS_BRAND_SAFETY_BUYER_SEAT_LIST, { ":seatId": seatId }),
    SEAT_CONTROLS_BRAND_SAFETY_BUYER_SEAT_LIST_DETAILS_DRAWER: (seatId: number | string, id: string | number) =>
        replaceWith(ROUTES.SEAT_CONTROLS_BRAND_SAFETY_BUYER_SEAT_LIST_DETAILS_DRAWER, {
            ":seatId": seatId,
            ":buyerSeatListId": id,
        }),
    SEAT_CONTROLS_BRAND_SAFETY_BUYER_SEAT_LIST_DETAILS_PAGE: (seatId: number | string, id: string | number) =>
        replaceWith(ROUTES.SEAT_CONTROLS_BRAND_SAFETY_BUYER_SEAT_LIST_DETAILS_PAGE, {
            ":seatId": seatId,
            ":buyerSeatListId": id,
        }),
    SEAT_CONTROLS_BRAND_SAFETY_BUYER_SEAT_LIST_CREATE_PAGE: (seatId: number | string) =>
        replaceWith(ROUTES.SEAT_CONTROLS_BRAND_SAFETY_BUYER_SEAT_LIST_CREATE_PAGE, {
            ":seatId": seatId,
        }),
    SEAT_CONTROLS_BRAND_SAFETY_BUYER_SEAT_LIST_EDIT_PAGE: (seatId: number | string, id: string | number) =>
        replaceWith(ROUTES.SEAT_CONTROLS_BRAND_SAFETY_BUYER_SEAT_LIST_EDIT_PAGE, {
            ":seatId": seatId,
            ":buyerSeatListId": id,
        }),
    SEAT_CONTROLS_BRAND_SAFETY_MAPPING_EXCEPTIONS_CREATE_PAGE: (seatId: number | string) =>
        replaceWith(ROUTES.SEAT_CONTROLS_BRAND_SAFETY_MAPPING_EXCEPTIONS_CREATE_PAGE, {
            ":seatId": seatId,
        }),
    SEAT_CONTROLS_BRAND_SAFETY_MAPPING_EXCEPTIONS_DETAILS_PAGE: (
        seatId: number | string,
        mappingExceptionId: string | number
    ) =>
        replaceWith(ROUTES.SEAT_CONTROLS_BRAND_SAFETY_MAPPING_EXCEPTIONS_DETAILS_PAGE, {
            ":seatId": seatId,
            ":mappingExceptionId": mappingExceptionId,
        }),
    SEAT_CONTROLS_BRAND_SAFETY_MAPPING_EXCEPTIONS_EDIT_PAGE: (
        seatId: number | string,
        mappingExceptionId: string | number
    ) =>
        replaceWith(ROUTES.SEAT_CONTROLS_BRAND_SAFETY_MAPPING_EXCEPTIONS_EDIT_PAGE, {
            ":seatId": seatId,
            ":mappingExceptionId": mappingExceptionId,
        }),
    SEAT_CONTROLS_BRAND_SAFETY_MAPPING_EXCEPTIONS_LIST_PAGE: (seatId: number | string) =>
        replaceWith(ROUTES.SEAT_CONTROLS_BRAND_SAFETY_MAPPING_EXCEPTIONS_LIST_PAGE, {
            ":seatId": seatId,
        }),
    SEAT_CONTROLS_BRAND_SAFETY_MAPPING_EXCEPTIONS_LIST_PAGE_DETAILS_DRAWER: (
        seatId: number | string,
        mappingExceptionId: string | number
    ) =>
        replaceWith(ROUTES.SEAT_CONTROLS_BRAND_SAFETY_MAPPING_EXCEPTIONS_LIST_PAGE_DETAILS_DRAWER, {
            ":seatId": seatId,
            ":mappingExceptionId": mappingExceptionId,
        }),
    SEAT_CREATIVE_REVIEW: (seatId: number | string) => replaceWith(ROUTES.SEAT_CREATIVE_REVIEW, { ":seatId": seatId }),
    SEAT_DASHBOARD: (seatId: number | string) => replaceWith(ROUTES.SEAT_DASHBOARD, { ":seatId": seatId }),
    SEAT_DEAL_COPY: (seatId: number | string, dealId: number | string) =>
        replaceWith(ROUTES.SEAT_DEAL_COPY, { ":seatId": seatId, ":dealId": dealId }),
    SEAT_DEAL_COPY_AD_SOURCES_DRAWER: (seatId: number | string, dealId: number | string, adSourceId: number | string) =>
        replaceWith(ROUTES.SEAT_DEAL_COPY_AD_SOURCES_DRAWER, {
            ":seatId": seatId,
            ":dealId": dealId,
            ":adSourceId": adSourceId,
        }),
    SEAT_DEAL_COPY_AD_SOURCES_DRAWER_SECONDARY_VIEW: (
        seatId: number | string,
        dealId: number | string,
        adSourceId: number | string,
        secondaryView: string
    ) =>
        replaceWith(ROUTES.SEAT_DEAL_COPY_AD_SOURCES_DRAWER_SECONDARY_VIEW, {
            ":seatId": seatId,
            ":dealId": dealId,
            ":adSourceId": adSourceId,
            ":secondaryView": secondaryView,
        }),

    SEAT_DEAL_CREATE_TYPE: (seatId: number | string, dealType: "demand" | "inventory" | "legacy") =>
        replaceWith(ROUTES.SEAT_DEAL_CREATE_TYPE, { ":seatId": seatId, ":dealType": dealType }),
    SEAT_DEAL_CREATE: (seatId: number | string) => replaceWith(ROUTES.SEAT_DEAL_CREATE, { ":seatId": seatId }),
    SEAT_DEAL_CREATE_TYPE_AD_SOURCES_DRAWER: (
        seatId: number | string,
        adSourceId: number | string,
        dealType: "demand" | "inventory" | "legacy"
    ) =>
        replaceWith(ROUTES.SEAT_DEAL_CREATE_TYPE_AD_SOURCES_DRAWER, {
            ":seatId": seatId,
            ":adSourceId": adSourceId,
            ":dealType": dealType,
        }),
    SEAT_DEAL_CREATE_TYPE_AD_SOURCES_DRAWER_SECONDARY_VIEW: (
        seatId: number | string,
        adSourceId: number | string,
        dealType: "demand" | "inventory" | "legacy",
        secondaryView: string
    ) =>
        replaceWith(ROUTES.SEAT_DEAL_CREATE_TYPE_AD_SOURCES_DRAWER_SECONDARY_VIEW, {
            ":seatId": seatId,
            ":adSourceId": adSourceId,
            ":secondaryView": secondaryView,
            ":dealType": dealType,
        }),

    SEAT_DEAL_DETAILS: (seatId: number | string, dealId: number | string) =>
        replaceWith(ROUTES.SEAT_DEAL_DETAILS, { ":seatId": seatId, ":dealId": dealId }),
    SEAT_DEAL_DETAILS_AD_SOURCES_DRAWER: (
        seatId: number | string,
        dealId: number | string,
        adSourceId: number | string
    ) =>
        replaceWith(ROUTES.SEAT_DEAL_DETAILS_AD_SOURCES_DRAWER, {
            ":seatId": seatId,
            ":dealId": dealId,
            ":adSourceId": adSourceId,
        }),
    SEAT_DEAL_DETAILS_AD_SOURCES_DRAWER_SECONDARY_VIEW: (
        seatId: number | string,
        dealId: number | string,
        adSourceId: number | string,
        secondaryView: string
    ) =>
        replaceWith(ROUTES.SEAT_DEAL_DETAILS_AD_SOURCES_DRAWER_SECONDARY_VIEW, {
            ":seatId": seatId,
            ":dealId": dealId,
            ":adSourceId": adSourceId,
            ":secondaryView": secondaryView,
        }),
    SEAT_DEAL_EDIT: (seatId: number | string, dealId: number | string) =>
        replaceWith(ROUTES.SEAT_DEAL_EDIT, { ":seatId": seatId, ":dealId": dealId }),
    SEAT_DEAL_EDIT_AD_SOURCES_DRAWER: (seatId: number | string, dealId: number | string, adSourceId: number | string) =>
        replaceWith(ROUTES.SEAT_DEAL_EDIT_AD_SOURCES_DRAWER, {
            ":seatId": seatId,
            ":dealId": dealId,
            ":adSourceId": adSourceId,
        }),
    SEAT_DEAL_EDIT_AD_SOURCES_DRAWER_SECONDARY_VIEW: (
        seatId: number | string,
        dealId: number | string,
        adSourceId: number | string,
        secondaryView: string
    ) =>
        replaceWith(ROUTES.SEAT_DEAL_EDIT_AD_SOURCES_DRAWER_SECONDARY_VIEW, {
            ":seatId": seatId,
            ":dealId": dealId,
            ":adSourceId": adSourceId,
            ":secondaryView": secondaryView,
        }),
    SEAT_DEAL_HEALTH: (seatId: number | string) => replaceWith(ROUTES.SEAT_DEAL_HEALTH, { ":seatId": seatId }),
    SEAT_DEAL_HEALTH_DEAL_DETAILS_DRAWER: (seatId: number | string, dealId: number | string) =>
        replaceWith(ROUTES.SEAT_DEAL_HEALTH_DEAL_DETAILS_DRAWER, { ":seatId": seatId, ":dealId": dealId }),
    SEAT_DEAL_HEALTH_DEAL_DETAILS_DRAWER_SECONDARY_VIEW: (
        seatId: number | string,
        dealId: number | string,
        secondaryView: string
    ) =>
        replaceWith(ROUTES.SEAT_DEAL_HEALTH_DEAL_DETAILS_DRAWER_SECONDARY_VIEW, {
            ":seatId": seatId,
            ":dealId": dealId,
            ":secondaryView": secondaryView,
        }),
    SEAT_DEAL_HEALTH_DEAL_SECONDARY_VIEW: (seatId: number | string, dealId: number | string, secondaryView: string) =>
        replaceWith(ROUTES.SEAT_DEAL_HEALTH_DEAL_SECONDARY_VIEW, {
            ":seatId": seatId,
            ":dealId": dealId,
            ":secondaryView": secondaryView,
        }),
    SEAT_DEALS_HEALTH_AD_SOURCES_DRAWER: (seatId: number | string, adSourceId: number | string) =>
        replaceWith(ROUTES.SEAT_DEALS_HEALTH_AD_SOURCES_DRAWER, { ":seatId": seatId, ":adSourceId": adSourceId }),
    SEAT_DEALS_HEALTH_AD_SOURCES_DRAWER_SECONDARY_VIEW: (
        seatId: number | string,
        adSourceId: number | string,
        secondaryView: string
    ) =>
        replaceWith(ROUTES.SEAT_DEALS_HEALTH_AD_SOURCES_DRAWER_SECONDARY_VIEW, {
            ":seatId": seatId,
            ":adSourceId": adSourceId,
            ":secondaryView": secondaryView,
        }),
    SEAT_DEAL_LIST: (seatId: number | string) => replaceWith(ROUTES.SEAT_DEAL_LIST, { ":seatId": seatId }),
    SEAT_DEALS: (seatId: number | string) => replaceWith(ROUTES.SEAT_DEALS, { ":seatId": seatId }),
    SEAT_DEALS_DEAL_DETAILS_DRAWER: (seatId: number | string, dealId: number | string) =>
        replaceWith(ROUTES.SEAT_DEALS_DEAL_DETAILS_DRAWER, { ":seatId": seatId, ":dealId": dealId }),
    SEAT_DEALS_DEAL_DETAILS_DRAWER_SECONDARY_VIEW: (
        seatId: number | string,
        dealId: number | string,
        secondaryView: string
    ) =>
        replaceWith(ROUTES.SEAT_DEALS_DEAL_DETAILS_DRAWER_SECONDARY_VIEW, {
            ":seatId": seatId,
            ":dealId": dealId,
            ":secondaryView": secondaryView,
        }),
    SEAT_DEALS_SECONDARY_VIEW: (seatId: number | string, dealId: number | string, secondaryView: string) =>
        replaceWith(ROUTES.SEAT_DEALS_SECONDARY_VIEW, {
            ":seatId": seatId,
            ":dealId": dealId,
            ":secondaryView": secondaryView,
        }),
    SEAT_DEALS_AD_SOURCES_DRAWER: (seatId: number | string, adSourceId: number | string) =>
        replaceWith(ROUTES.SEAT_DEALS_AD_SOURCES_DRAWER, { ":seatId": seatId, ":adSourceId": adSourceId }),
    SEAT_DEALS_AD_SOURCES_DRAWER_SECONDARY_VIEW: (
        seatId: number | string,
        adSourceId: number | string,
        secondaryView: string
    ) =>
        replaceWith(ROUTES.SEAT_DEALS_AD_SOURCES_DRAWER_SECONDARY_VIEW, {
            ":seatId": seatId,
            ":adSourceId": adSourceId,
            ":secondaryView": secondaryView,
        }),

    SEAT_DEMAND: (seatId: number | string) => replaceWith(ROUTES.SEAT_DEMAND, { ":seatId": seatId }),
    SEAT_DIAGNOSTICS: (seatId: number | string) => replaceWith(ROUTES.SEAT_DIAGNOSTICS, { ":seatId": seatId }),
    SEAT_LADLE_DIAGNOSTICS: (seatId: number | string) =>
        replaceWith(ROUTES.SEAT_LADLE_DIAGNOSTICS, { ":seatId": seatId }),
    SEAT_DIAGNOSTICS_LADLE_RESULTS: (seatId: number | string, ladleName: string) =>
        replaceWith(ROUTES.SEAT_DIAGNOSTICS_LADLE_RESULTS, { ":seatId": seatId, ":ladleName": ladleName }),
    SEAT_INTEGRATIONS_CONSOLE: (seatId: number | string) =>
        replaceWith(ROUTES.SEAT_INTEGRATIONS_CONSOLE, { ":seatId": seatId }),
    SEAT_INTEGRATIONS_TABS_CONSOLE: (
        seatId: number | string,
        tab: IntegrationConsoleRoute = INTEGRATIONS_ROUTES_CONSOLE.SSP_CONNECT
    ) => replaceWith(ROUTES.SEAT_INTEGRATIONS_TABS_CONSOLE, { ":seatId": seatId, ":tab": tab }),
    SEAT_INTEGRATIONS: (seatId: number | string) => replaceWith(ROUTES.SEAT_INTEGRATIONS, { ":seatId": seatId }),
    SEAT_DIAGNOSTICS_DEAL_DASHBOARD: (seatId: number | string) =>
        replaceWith(ROUTES.SEAT_DIAGNOSTICS_DEAL_DASHBOARD, { ":seatId": seatId }),
    SEAT_DIAGNOSTICS_ADVERTISER_DASHBOARD: (seatId: number | string) =>
        replaceWith(ROUTES.SEAT_DIAGNOSTICS_ADVERTISER_DASHBOARD, { ":seatId": seatId }),
    SEAT_DIAGNOSTICS_BIDDER_DIANOSTICS: (seatId: number | string) =>
        replaceWith(ROUTES.SEAT_DIAGNOSTICS_BIDDER_DIAGNOSTICS, { ":seatId": seatId }),
    SEAT_DIAGNOSTICS_LADLE_RESULTS_ALL: (seatId: number | string) =>
        replaceWith(ROUTES.SEAT_DIAGNOSTICS_LADLE_RESULTS_ALL, { ":seatId": seatId }),
    SEAT_INVENTORY_HEALTH: (seatId: number | string) =>
        replaceWith(ROUTES.SEAT_INVENTORY_HEALTH, { ":seatId": seatId }),
    SEAT_INVENTORY_HEALTH_MODEL: (seatId: number | string, model: INVENTORY_HEALTH_MODELS, id: string | number) =>
        replaceWith(ROUTES.SEAT_INVENTORY_HEALTH_MODEL, { ":seatId": seatId, ":model": model, ":id": id }),
    SEAT_INVENTORY_HEALTH_MODEL_DEFINITION: (
        seatId: number | string,
        model: INVENTORY_HEALTH_MODELS,
        definition: string
    ) =>
        replaceWith(ROUTES.SEAT_INVENTORY_HEALTH_MODEL_DEFINITION, {
            ":seatId": seatId,
            ":model": model,
            ":definition": definition,
        }),
    SEAT_INVENTORY_HEALTH_DETAILS: (seatId: number | string, drawerType: InventoryDetailsDrawerType, id: number) =>
        replaceWith(ROUTES.SEAT_INVENTORY_HEALTH_DETAILS, { ":seatId": seatId, ":drawerType": drawerType, ":id": id }),
    SEAT_INVENTORY_HEALTH_DETAILS_DIAGNOSTICS: (
        isPage = false,
        seatId: number | string,
        drawerType: InventoryDetailsDrawerType,
        publisherId?: number,
        brandId?: number,
        supplyId?: number,
        adUnitId?: number
    ) => {
        switch (drawerType) {
            case InventoryDetailsDrawerType.SEAT:
                return replaceWith(ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_SEAT_DIAGNOSTICS, {
                    ":seatId": seatId,
                    ":drawerType": drawerType,
                });
            case InventoryDetailsDrawerType.PUBLISHER:
                return replaceWith(
                    isPage
                        ? ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_PUBLISHER_DIAGNOSTICS
                        : ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PUBLISHER_DIAGNOSTICS,
                    {
                        ":seatId": seatId,
                        ":drawerType": drawerType,
                        ":publisherId": publisherId as number,
                    }
                );
            case InventoryDetailsDrawerType.CHANNEL:
                return replaceWith(
                    isPage
                        ? ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_CHANNEL_DIAGNOSTICS
                        : ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PUBLISHER_DIAGNOSTICS,
                    {
                        ":seatId": seatId,
                        ":drawerType": drawerType,
                        ":publisherId": publisherId as number,
                    }
                );
            case InventoryDetailsDrawerType.BRAND:
                return replaceWith(
                    isPage
                        ? ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_BRAND_DIAGNOSTICS
                        : ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_BRAND_DIAGNOSTICS,
                    {
                        ":seatId": seatId,
                        ":drawerType": drawerType,
                        ":publisherId": publisherId as number,
                        ":brandId": brandId as number,
                    }
                );

            case InventoryDetailsDrawerType.BRAND_CHANNEL:
                return replaceWith(
                    isPage
                        ? ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_BRAND_CHANNEL_DIAGNOSTICS
                        : ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_BRAND_DIAGNOSTICS,
                    {
                        ":seatId": seatId,
                        ":drawerType": drawerType,
                        ":publisherId": publisherId as number,
                        ":brandId": brandId as number,
                    }
                );
            case InventoryDetailsDrawerType.SUPPLY:
                return replaceWith(
                    isPage
                        ? ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_SUPPLY_DIAGNOSTICS
                        : ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_SUPPLY_DIAGNOSTICS,
                    {
                        ":seatId": seatId,
                        ":drawerType": drawerType,
                        ":publisherId": publisherId as number,
                        ":brandId": brandId as number,
                        ":supplyId": supplyId as number,
                    }
                );
            case InventoryDetailsDrawerType.AD_UNIT:
                return replaceWith(
                    isPage
                        ? ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_AD_UNIT_DIAGNOSTICS
                        : ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_AD_UNIT_DIAGNOSTICS,
                    {
                        ":seatId": seatId,
                        ":drawerType": drawerType,
                        ":publisherId": publisherId as number,
                        ":brandId": brandId as number,
                        ":supplyId": supplyId as number,
                        ":adUnitId": adUnitId as number,
                    }
                );
        }
    },
    SEAT_INVENTORY_HEALTH_DETAILS_PERMISSIONS: (
        isPage = false,
        seatId: number | string,
        drawerType: InventoryDetailsDrawerType,
        publisherId?: number
    ) => {
        switch (drawerType) {
            case InventoryDetailsDrawerType.PUBLISHER:
            case InventoryDetailsDrawerType.CHANNEL:
                return replaceWith(
                    isPage
                        ? ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_PUBLISHER_PERMISSIONS
                        : ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PUBLISHER_PERMISSIONS,
                    {
                        ":seatId": seatId,
                        ":drawerType": drawerType,
                        ":publisherId": publisherId as number,
                    }
                );
        }
    },
    SEAT_INVENTORY_HEALTH_DETAILS_DIAGNOSTICS_DEFINITION: (
        isPage = false,
        seatId: number | string,
        drawerType: InventoryDetailsDrawerType,
        publisherId?: number,
        brandId?: number,
        supplyId?: number,
        adUnitId?: number
    ) => {
        switch (drawerType) {
            case InventoryDetailsDrawerType.SEAT:
                return replaceWith(ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_SEAT_DIAGNOSTICS_DEFINITION, {
                    ":seatId": seatId,
                    ":drawerType": drawerType,
                });
            case InventoryDetailsDrawerType.PUBLISHER:
                return replaceWith(
                    isPage
                        ? ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_PUBLISHER_DIAGNOSTICS_DEFINITION
                        : ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PUBLISHER_DIAGNOSTICS_DEFINITION,
                    {
                        ":seatId": seatId,
                        ":drawerType": drawerType,
                        ":publisherId": publisherId as number,
                    }
                );
            case InventoryDetailsDrawerType.CHANNEL:
                return replaceWith(
                    isPage
                        ? ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_CHANNEL_DIAGNOSTICS_DEFINITION
                        : ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PUBLISHER_DIAGNOSTICS_DEFINITION,
                    {
                        ":seatId": seatId,
                        ":drawerType": drawerType,
                        ":publisherId": publisherId as number,
                    }
                );
            case InventoryDetailsDrawerType.BRAND:
                return replaceWith(
                    isPage
                        ? ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_BRAND_DIAGNOSTICS_DEFINITION
                        : ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_BRAND_DIAGNOSTICS_DEFINITION,
                    {
                        ":seatId": seatId,
                        ":drawerType": drawerType,
                        ":publisherId": publisherId as number,
                        ":brandId": brandId as number,
                    }
                );

            case InventoryDetailsDrawerType.BRAND_CHANNEL:
                return replaceWith(
                    isPage
                        ? ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_BRAND_CHANNEL_DIAGNOSTICS_DEFINITION
                        : ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_BRAND_DIAGNOSTICS_DEFINITION,
                    {
                        ":seatId": seatId,
                        ":drawerType": drawerType,
                        ":publisherId": publisherId as number,
                        ":brandId": brandId as number,
                    }
                );
            case InventoryDetailsDrawerType.SUPPLY:
                return replaceWith(
                    isPage
                        ? ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_SUPPLY_DIAGNOSTICS_DEFINITION
                        : ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_SUPPLY_DIAGNOSTICS_DEFINITION,
                    {
                        ":seatId": seatId,
                        ":drawerType": drawerType,
                        ":publisherId": publisherId as number,
                        ":brandId": brandId as number,
                        ":supplyId": supplyId as number,
                    }
                );
            case InventoryDetailsDrawerType.AD_UNIT:
                return replaceWith(
                    isPage
                        ? ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_AD_UNIT_DIAGNOSTICS_DEFINITION
                        : ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_AD_UNIT_DIAGNOSTICS_DEFINITION,
                    {
                        ":seatId": seatId,
                        ":drawerType": drawerType,
                        ":publisherId": publisherId as number,
                        ":brandId": brandId as number,
                        ":supplyId": supplyId as number,
                        ":adUnitId": adUnitId as number,
                    }
                );
        }
    },
    SEAT_INVENTORY_HEALTH_DETAILS_ADS_TXT: (
        isPage = false,
        seatId: number | string,
        drawerType: InventoryDetailsDrawerType,
        publisherId?: number,
        brandId?: number
    ) => {
        switch (drawerType) {
            case InventoryDetailsDrawerType.PUBLISHER:
                return replaceWith(
                    isPage
                        ? ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_PUBLISHER_ADS_TXT
                        : ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PUBLISHER_ADS_TXT,
                    {
                        ":seatId": seatId,
                        ":drawerType": drawerType,
                        ":publisherId": publisherId as number,
                    }
                );
            case InventoryDetailsDrawerType.BRAND:
                return replaceWith(
                    isPage
                        ? ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_BRAND_ADS_TXT
                        : ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_BRAND_ADS_TXT,
                    {
                        ":seatId": seatId,
                        ":drawerType": drawerType,
                        ":publisherId": publisherId as number,
                        ":brandId": brandId as number,
                    }
                );
            case InventoryDetailsDrawerType.CHANNEL:
                return replaceWith(
                    isPage
                        ? ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_CHANNEL_ADS_TXT
                        : ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_CHANNEL_ADS_TXT,
                    {
                        ":seatId": seatId,
                        ":drawerType": drawerType,
                        ":publisherId": publisherId as number,
                    }
                );
        }
    },
    SEAT_INVENTORY_HEALTH_DETAILS_FLOOR_ANALYSIS: (
        isPage = false,
        seatId: number | string,
        drawerType: InventoryDetailsDrawerType,
        publisherId?: number,
        brandId?: number,
        supplyId?: number,
        adUnitId?: number
    ) => {
        switch (drawerType) {
            case InventoryDetailsDrawerType.SEAT:
                return replaceWith(ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_SEAT_FLOOR_ANALYSIS, {
                    ":seatId": seatId,
                    ":drawerType": drawerType,
                });
            case InventoryDetailsDrawerType.PUBLISHER:
                return replaceWith(
                    isPage
                        ? ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_PUBLISHER_FLOOR_ANALYSIS
                        : ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PUBLISHER_FLOOR_ANALYSIS,
                    {
                        ":seatId": seatId,
                        ":drawerType": drawerType,
                        ":publisherId": publisherId as number,
                    }
                );
            case InventoryDetailsDrawerType.CHANNEL:
                return replaceWith(
                    isPage
                        ? ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_CHANNEL_FLOOR_ANALYSIS
                        : ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PUBLISHER_FLOOR_ANALYSIS,
                    {
                        ":seatId": seatId,
                        ":drawerType": drawerType,
                        ":publisherId": publisherId as number,
                    }
                );
            case InventoryDetailsDrawerType.BRAND:
                return replaceWith(
                    isPage
                        ? ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_BRAND_FLOOR_ANALYSIS
                        : ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_BRAND_FLOOR_ANALYSIS,
                    {
                        ":seatId": seatId,
                        ":drawerType": drawerType,
                        ":publisherId": publisherId as number,
                        ":brandId": brandId as number,
                    }
                );

            case InventoryDetailsDrawerType.BRAND_CHANNEL:
                return replaceWith(
                    isPage
                        ? ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_BRAND_CHANNEL_FLOOR_ANALYSIS
                        : ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_BRAND_FLOOR_ANALYSIS,
                    {
                        ":seatId": seatId,
                        ":drawerType": drawerType,
                        ":publisherId": publisherId as number,
                        ":brandId": brandId as number,
                    }
                );
            case InventoryDetailsDrawerType.SUPPLY:
                return replaceWith(
                    isPage
                        ? ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_SUPPLY_FLOOR_ANALYSIS
                        : ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_SUPPLY_FLOOR_ANALYSIS,
                    {
                        ":seatId": seatId,
                        ":drawerType": drawerType,
                        ":publisherId": publisherId as number,
                        ":brandId": brandId as number,
                        ":supplyId": supplyId as number,
                    }
                );
            case InventoryDetailsDrawerType.AD_UNIT:
                return replaceWith(
                    isPage
                        ? ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_AD_UNIT_FLOOR_ANALYSIS
                        : ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_AD_UNIT_FLOOR_ANALYSIS,
                    {
                        ":seatId": seatId,
                        ":drawerType": drawerType,
                        ":publisherId": publisherId as number,
                        ":brandId": brandId as number,
                        ":supplyId": supplyId as number,
                        ":adUnitId": adUnitId as number,
                    }
                );
        }
    },
    SEAT_INVENTORY_HEALTH_DETAILS_TAG_PARAMS: (
        isPage = false,
        seatId: number | string,
        drawerType: InventoryDetailsDrawerType,
        publisherId?: number,
        brandId?: number,
        supplyId?: number,
        adUnitId?: number
    ) => {
        switch (drawerType) {
            case InventoryDetailsDrawerType.SEAT:
                return replaceWith(ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_SEAT_TAG_PARAMS, {
                    ":seatId": seatId,
                    ":drawerType": drawerType,
                });
            case InventoryDetailsDrawerType.PUBLISHER:
                return replaceWith(
                    isPage
                        ? ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_PUBLISHER_TAG_PARAMS
                        : ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PUBLISHER_TAG_PARAMS,
                    {
                        ":seatId": seatId,
                        ":drawerType": drawerType,
                        ":publisherId": publisherId as number,
                    }
                );
            case InventoryDetailsDrawerType.CHANNEL:
                return replaceWith(
                    isPage
                        ? ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_CHANNEL_TAG_PARAMS
                        : ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PUBLISHER_TAG_PARAMS,
                    {
                        ":seatId": seatId,
                        ":drawerType": drawerType,
                        ":publisherId": publisherId as number,
                    }
                );

            case InventoryDetailsDrawerType.BRAND:
                return replaceWith(
                    isPage
                        ? ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_BRAND_TAG_PARAMS
                        : ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_BRAND_TAG_PARAMS,
                    {
                        ":seatId": seatId,
                        ":drawerType": drawerType,
                        ":publisherId": publisherId as number,
                        ":brandId": brandId as number,
                    }
                );

            case InventoryDetailsDrawerType.BRAND_CHANNEL:
                return replaceWith(
                    isPage
                        ? ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_BRAND_CHANNEL_TAG_PARAMS
                        : ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_BRAND_TAG_PARAMS,
                    {
                        ":seatId": seatId,
                        ":drawerType": drawerType,
                        ":publisherId": publisherId as number,
                        ":brandId": brandId as number,
                    }
                );
            case InventoryDetailsDrawerType.SUPPLY:
                return replaceWith(
                    isPage
                        ? ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_SUPPLY_TAG_PARAMS
                        : ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_SUPPLY_TAG_PARAMS,
                    {
                        ":seatId": seatId,
                        ":drawerType": drawerType,
                        ":publisherId": publisherId as number,
                        ":brandId": brandId as number,
                        ":supplyId": supplyId as number,
                    }
                );
            case InventoryDetailsDrawerType.AD_UNIT:
                return replaceWith(
                    isPage
                        ? ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_AD_UNIT_TAG_PARAMS
                        : ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_AD_UNIT_TAG_PARAMS,
                    {
                        ":seatId": seatId,
                        ":drawerType": drawerType,
                        ":publisherId": publisherId as number,
                        ":brandId": brandId as number,
                        ":supplyId": supplyId as number,
                        ":adUnitId": adUnitId as number,
                    }
                );
        }
    },
    SEAT_INVENTORY_SEAT_DETAILS_PAGE_CHANGE_HISTORY: (seatId: number | string) =>
        replaceWith(ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_SEAT_CHANGE_HISTORY, { ":seatId": seatId }),
    SEAT_INVENTORY_HEALTH_DETAILS_CHANGE_HISTORY: (
        isPage = false,
        seatId: number | string,
        drawerType: InventoryDetailsDrawerType,
        publisherId?: number,
        brandId?: number,
        supplyId?: number,
        adUnitId?: number
    ) => {
        switch (drawerType) {
            case InventoryDetailsDrawerType.SEAT:
                return replaceWith(
                    isPage
                        ? ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_SEAT_CHANGE_HISTORY
                        : ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_SEAT_CHANGE_HISTORY,
                    {
                        ":seatId": seatId,
                        ":drawerType": drawerType,
                    }
                );

            case InventoryDetailsDrawerType.PUBLISHER:
                return replaceWith(
                    isPage
                        ? ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_PUBLISHER_CHANGE_HISTORY
                        : ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PUBLISHER_CHANGE_HISTORY,
                    {
                        ":seatId": seatId,
                        ":drawerType": drawerType,
                        ":publisherId": publisherId as number,
                    }
                );
            case InventoryDetailsDrawerType.BRAND:
                return replaceWith(
                    isPage
                        ? ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_BRAND_CHANGE_HISTORY
                        : ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_BRAND_CHANGE_HISTORY,
                    {
                        ":seatId": seatId,
                        ":drawerType": drawerType,
                        ":publisherId": publisherId as number,
                        ":brandId": brandId as number,
                    }
                );
            case InventoryDetailsDrawerType.CHANNEL:
                return replaceWith(
                    isPage
                        ? ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_CHANNEL_CHANGE_HISTORY
                        : ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_CHANNEL_CHANGE_HISTORY,
                    {
                        ":seatId": seatId,
                        ":drawerType": drawerType,
                        ":publisherId": publisherId as number,
                    }
                );
            case InventoryDetailsDrawerType.BRAND_CHANNEL:
                return replaceWith(
                    isPage
                        ? ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_BRAND_CHANNEL_CHANGE_HISTORY
                        : ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_BRAND_CHANNEL_CHANGE_HISTORY,
                    {
                        ":seatId": seatId,
                        ":drawerType": drawerType,
                        ":publisherId": publisherId as number,
                        ":brandId": brandId as number,
                    }
                );
            case InventoryDetailsDrawerType.SUPPLY:
                return replaceWith(
                    isPage
                        ? ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_SUPPLY_CHANGE_HISTORY
                        : ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_SUPPLY_CHANGE_HISTORY,
                    {
                        ":seatId": seatId,
                        ":drawerType": drawerType,
                        ":publisherId": publisherId as number,
                        ":brandId": brandId as number,
                        ":supplyId": supplyId as number,
                    }
                );
            case InventoryDetailsDrawerType.AD_UNIT:
                return replaceWith(
                    isPage
                        ? ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_AD_UNIT_CHANGE_HISTORY
                        : ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_AD_UNIT_CHANGE_HISTORY,
                    {
                        ":seatId": seatId,
                        ":drawerType": drawerType,
                        ":publisherId": publisherId as number,
                        ":brandId": brandId as number,
                        ":supplyId": supplyId as number,
                        ":adUnitId": adUnitId as number,
                    }
                );
        }
    },
    SEAT_INVENTORY_HEALTH_DETAILS_CREATIVE_REVIEW: (
        isPage = false,
        seatId: number | string,
        drawerType: InventoryDetailsDrawerType,
        publisherId?: number,
        brandId?: number,
        supplyId?: number,
        adUnitId?: number
    ) => {
        switch (drawerType) {
            case InventoryDetailsDrawerType.SEAT:
                return replaceWith(ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_SEAT_CREATIVE_REVIEW, {
                    ":seatId": seatId,
                    ":drawerType": drawerType,
                });
            case InventoryDetailsDrawerType.PUBLISHER:
                return replaceWith(
                    isPage
                        ? ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_PUBLISHER_CREATIVE_REVIEW
                        : ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PUBLISHER_CREATIVE_REVIEW,
                    {
                        ":seatId": seatId,
                        ":drawerType": drawerType,
                        ":publisherId": publisherId as number,
                    }
                );
            case InventoryDetailsDrawerType.CHANNEL:
                return replaceWith(
                    isPage
                        ? ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_CHANNEL_CREATIVE_REVIEW
                        : ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PUBLISHER_CREATIVE_REVIEW,
                    {
                        ":seatId": seatId,
                        ":drawerType": drawerType,
                        ":publisherId": publisherId as number,
                    }
                );

            case InventoryDetailsDrawerType.BRAND:
                return replaceWith(
                    isPage
                        ? ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_BRAND_CREATIVE_REVIEW
                        : ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_BRAND_CREATIVE_REVIEW,
                    {
                        ":seatId": seatId,
                        ":drawerType": drawerType,
                        ":publisherId": publisherId as number,
                        ":brandId": brandId as number,
                    }
                );
            case InventoryDetailsDrawerType.BRAND_CHANNEL:
                return replaceWith(
                    isPage
                        ? ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_BRAND_CHANNEL_CREATIVE_REVIEW
                        : ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_BRAND_CREATIVE_REVIEW,
                    {
                        ":seatId": seatId,
                        ":drawerType": drawerType,
                        ":publisherId": publisherId as number,
                        ":brandId": brandId as number,
                    }
                );
            case InventoryDetailsDrawerType.SUPPLY:
                return replaceWith(
                    isPage
                        ? ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_SUPPLY_CREATIVE_REVIEW
                        : ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_SUPPLY_CREATIVE_REVIEW,
                    {
                        ":seatId": seatId,
                        ":drawerType": drawerType,
                        ":publisherId": publisherId as number,
                        ":brandId": brandId as number,
                        ":supplyId": supplyId as number,
                    }
                );
            case InventoryDetailsDrawerType.AD_UNIT:
                return replaceWith(
                    isPage
                        ? ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_AD_UNIT_CREATIVE_REVIEW
                        : ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_AD_UNIT_CREATIVE_REVIEW,
                    {
                        ":seatId": seatId,
                        ":drawerType": drawerType,
                        ":publisherId": publisherId as number,
                        ":brandId": brandId as number,
                        ":supplyId": supplyId as number,
                        ":adUnitId": adUnitId as number,
                    }
                );
        }
    },
    INVENTORY_HEALTH_DETAILS_CREATIVE_REVIEW_DEFINITION: (
        isPage = false,
        seatId: number | string,
        drawerType: InventoryDetailsDrawerType,
        publisherId?: number,
        brandId?: number,
        supplyId?: number,
        adUnitId?: number
    ) => {
        switch (drawerType) {
            case InventoryDetailsDrawerType.SEAT:
                return replaceWith(ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_SEAT_CREATIVE_REVIEW_DEFINITION, {
                    ":seatId": seatId,
                    ":drawerType": drawerType,
                });
            case InventoryDetailsDrawerType.PUBLISHER:
                return replaceWith(
                    isPage
                        ? ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_PUBLISHER_CREATIVE_REVIEW_DEFINITION
                        : ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PUBLISHER_CREATIVE_REVIEW_DEFINITION,
                    {
                        ":seatId": seatId,
                        ":drawerType": drawerType,
                        ":publisherId": publisherId as number,
                    }
                );
            case InventoryDetailsDrawerType.CHANNEL:
                return replaceWith(
                    isPage
                        ? ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_CHANNEL_CREATIVE_REVIEW_DEFINITION
                        : ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PUBLISHER_CREATIVE_REVIEW_DEFINITION,
                    {
                        ":seatId": seatId,
                        ":drawerType": drawerType,
                        ":publisherId": publisherId as number,
                    }
                );
            case InventoryDetailsDrawerType.BRAND:
                return replaceWith(
                    isPage
                        ? ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_BRAND_CREATIVE_REVIEW_DEFINITION
                        : ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_BRAND_CREATIVE_REVIEW_DEFINITION,
                    {
                        ":seatId": seatId,
                        ":drawerType": drawerType,
                        ":publisherId": publisherId as number,
                        ":brandId": brandId as number,
                    }
                );
            case InventoryDetailsDrawerType.BRAND_CHANNEL:
                return replaceWith(
                    isPage
                        ? ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_BRAND_CHANNEL_CREATIVE_REVIEW_DEFINITION
                        : ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_BRAND_CREATIVE_REVIEW_DEFINITION,
                    {
                        ":seatId": seatId,
                        ":drawerType": drawerType,
                        ":publisherId": publisherId as number,
                        ":brandId": brandId as number,
                    }
                );
            case InventoryDetailsDrawerType.SUPPLY:
                return replaceWith(
                    isPage
                        ? ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_SUPPLY_CREATIVE_REVIEW_DEFINITION
                        : ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_SUPPLY_CREATIVE_REVIEW_DEFINITION,
                    {
                        ":seatId": seatId,
                        ":drawerType": drawerType,
                        ":publisherId": publisherId as number,
                        ":brandId": brandId as number,
                        ":supplyId": supplyId as number,
                    }
                );
            case InventoryDetailsDrawerType.AD_UNIT:
                return replaceWith(
                    isPage
                        ? ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_AD_UNIT_CREATIVE_REVIEW_DEFINITION
                        : ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_AD_UNIT_CREATIVE_REVIEW_DEFINITION,
                    {
                        ":seatId": seatId,
                        ":drawerType": drawerType,
                        ":publisherId": publisherId as number,
                        ":brandId": brandId as number,
                        ":supplyId": supplyId as number,
                        ":adUnitId": adUnitId as number,
                    }
                );
        }
    },
    SEAT_INVENTORY_HEALTH_DETAILS_LADLE: (
        isPage = false,
        seatId: number | string,
        drawerType: InventoryDetailsDrawerType,
        publisherId: number,
        brandId?: number,
        supplyId?: number,
        adUnitId?: number
    ) => {
        switch (drawerType) {
            case InventoryDetailsDrawerType.CHANNEL:
                return replaceWith(
                    isPage
                        ? ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_CHANNEL_LADLE
                        : ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_CHANNEL_LADLE,
                    {
                        ":seatId": seatId,
                        ":drawerType": drawerType,
                        ":publisherId": publisherId,
                    }
                );
            case InventoryDetailsDrawerType.BRAND_CHANNEL:
                return replaceWith(
                    isPage
                        ? ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_BRAND_CHANNEL_LADLE
                        : ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_BRAND_CHANNEL_LADLE,
                    {
                        ":seatId": seatId,
                        ":drawerType": drawerType,
                        ":publisherId": publisherId,
                        ":brandId": brandId as number,
                    }
                );
            case InventoryDetailsDrawerType.AD_UNIT:
                return replaceWith(
                    isPage
                        ? ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_AD_UNIT_LADLE
                        : ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_AD_UNIT_LADLE,
                    {
                        ":seatId": seatId,
                        ":drawerType": drawerType,
                        ":publisherId": publisherId,
                        ":brandId": brandId as number,
                        ":supplyId": supplyId as number,
                        ":adUnitId": adUnitId as number,
                    }
                );
        }
    },
    SEAT_INVENTORY_HEALTH_DETAILS_LADLE_DEFINITION: (
        isPage = false,
        seatId: number | string,
        drawerType: InventoryDetailsDrawerType,
        publisherId: number,
        brandId?: number,
        supplyId?: number,
        adUnitId?: number
    ) => {
        switch (drawerType) {
            case InventoryDetailsDrawerType.CHANNEL:
                return replaceWith(
                    isPage
                        ? ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_CHANNEL_LADLE_DEFINITION
                        : ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_CHANNEL_LADLE_DEFINITION,
                    {
                        ":seatId": seatId,
                        ":drawerType": drawerType,
                        ":publisherId": publisherId,
                    }
                );
            case InventoryDetailsDrawerType.BRAND_CHANNEL:
                return replaceWith(
                    isPage
                        ? ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_BRAND_CHANNEL_LADLE_DEFINITION
                        : ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_BRAND_CHANNEL_LADLE_DEFINITION,
                    {
                        ":seatId": seatId,
                        ":drawerType": drawerType,
                        ":publisherId": publisherId,
                        ":brandId": brandId as number,
                    }
                );
            case InventoryDetailsDrawerType.AD_UNIT:
                return replaceWith(
                    isPage
                        ? ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_AD_UNIT_LADLE_DEFINITION
                        : ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_AD_UNIT_LADLE_DEFINITION,
                    {
                        ":seatId": seatId,
                        ":drawerType": drawerType,
                        ":publisherId": publisherId,
                        ":brandId": brandId as number,
                        ":supplyId": supplyId as number,
                        ":adUnitId": adUnitId as number,
                    }
                );
        }
    },
    SEAT_INVENTORY_HEALTH_DETAILS_UNIT_SNAPSHOT: (
        isPage = false,
        seatId: number | string,
        drawerType: InventoryDetailsDrawerType,
        publisherId: number,
        brandId?: number,
        supplyId?: number,
        adUnitId?: number
    ) => {
        switch (drawerType) {
            case InventoryDetailsDrawerType.PUBLISHER:
                return replaceWith(
                    isPage
                        ? ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_PUBLISHER_SNAPSHOT
                        : ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PUBLISHER_SNAPSHOT,
                    {
                        ":seatId": seatId,
                        ":drawerType": drawerType,
                        ":publisherId": publisherId,
                    }
                );
            case InventoryDetailsDrawerType.CHANNEL:
                return replaceWith(
                    isPage
                        ? ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_CHANNEL_SNAPSHOT
                        : ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PUBLISHER_SNAPSHOT,
                    {
                        ":seatId": seatId,
                        ":drawerType": drawerType,
                        ":publisherId": publisherId,
                    }
                );
            case InventoryDetailsDrawerType.BRAND:
                return replaceWith(
                    isPage
                        ? ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_BRAND_SNAPSHOT
                        : ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_BRAND_SNAPSHOT,
                    {
                        ":seatId": seatId,
                        ":drawerType": drawerType,
                        ":publisherId": publisherId,
                        ":brandId": brandId as number,
                    }
                );
            case InventoryDetailsDrawerType.BRAND_CHANNEL:
                return replaceWith(
                    isPage
                        ? ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_BRAND_CHANNEL_SNAPSHOT
                        : ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_BRAND_SNAPSHOT,
                    {
                        ":seatId": seatId,
                        ":drawerType": drawerType,
                        ":publisherId": publisherId,
                        ":brandId": brandId as number,
                    }
                );
            case InventoryDetailsDrawerType.SUPPLY:
                return replaceWith(
                    isPage
                        ? ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_SUPPLY_SNAPSHOT
                        : ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_SUPPLY_SNAPSHOT,
                    {
                        ":seatId": seatId,
                        ":drawerType": drawerType,
                        ":publisherId": publisherId,
                        ":brandId": brandId as number,
                        ":supplyId": supplyId as number,
                    }
                );
            case InventoryDetailsDrawerType.AD_UNIT:
                return replaceWith(
                    isPage
                        ? ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_AD_UNIT_SNAPSHOT
                        : ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_AD_UNIT_SNAPSHOT,
                    {
                        ":seatId": seatId,
                        ":drawerType": drawerType,
                        ":publisherId": publisherId,
                        ":brandId": brandId as number,
                        ":supplyId": supplyId as number,
                        ":adUnitId": adUnitId as number,
                    }
                );
        }
    },
    SEAT_INVENTORY_HEALTH_DETAILS_UNIT: (
        seatId: number | string,
        drawerType: InventoryDetailsDrawerType,
        publisherId: number,
        brandId?: number,
        supplyId?: number,
        adUnitId?: number
    ) => {
        switch (drawerType) {
            case InventoryDetailsDrawerType.PUBLISHER:
            case InventoryDetailsDrawerType.CHANNEL:
                return replaceWith(ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PUBLISHER, {
                    ":seatId": seatId,
                    ":drawerType": drawerType,
                    ":publisherId": publisherId,
                });
            case InventoryDetailsDrawerType.BRAND:
            case InventoryDetailsDrawerType.BRAND_CHANNEL:
                return replaceWith(ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_BRAND, {
                    ":seatId": seatId,
                    ":drawerType": drawerType,
                    ":publisherId": publisherId,
                    ":brandId": brandId as number,
                });
            case InventoryDetailsDrawerType.SUPPLY:
                return replaceWith(ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_SUPPLY, {
                    ":seatId": seatId,
                    ":drawerType": drawerType,
                    ":publisherId": publisherId,
                    ":brandId": brandId as number,
                    ":supplyId": supplyId as number,
                });
            case InventoryDetailsDrawerType.AD_UNIT:
                return replaceWith(ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_AD_UNIT, {
                    ":seatId": seatId,
                    ":drawerType": drawerType,
                    ":publisherId": publisherId,
                    ":brandId": brandId as number,
                    ":supplyId": supplyId as number,
                    ":adUnitId": adUnitId as number,
                });
        }
    },
    SEAT_INVENTORY_HEALTH_DETAILS_UNIT_DEFINITION: (
        isPage = false,
        seatId: number | string,
        drawerType: InventoryDetailsDrawerType,
        key: SECTIONS_NAMES,
        publisherId: number | string,
        brandId?: number,
        supplyId?: number,
        adUnitId?: number
    ) => {
        switch (drawerType) {
            case InventoryDetailsDrawerType.PUBLISHER:
                return replaceWith(
                    isPage
                        ? ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_PUBLISHER_DEFINITION
                        : ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PUBLISHER_DEFINITION,
                    {
                        ":seatId": seatId,
                        ":drawerType": drawerType,
                        ":publisherId": publisherId,
                        ":key": key,
                    }
                );

            case InventoryDetailsDrawerType.CHANNEL:
                return replaceWith(
                    isPage
                        ? ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_CHANNEL_DEFINITION
                        : ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_CHANNEL_DEFINITION,
                    {
                        ":seatId": seatId,
                        ":drawerType": drawerType,
                        ":publisherId": publisherId,
                        ":key": key,
                    }
                );

            case InventoryDetailsDrawerType.BRAND:
                return replaceWith(
                    isPage
                        ? ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_BRAND_DEFINITION
                        : ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_BRAND_DEFINITION,
                    {
                        ":seatId": seatId,
                        ":drawerType": drawerType,
                        ":publisherId": publisherId,
                        ":brandId": brandId as number,
                        ":key": key,
                    }
                );

            case InventoryDetailsDrawerType.BRAND_CHANNEL:
                return replaceWith(
                    isPage
                        ? ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_BRAND_CHANNEL_DEFINITION
                        : ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_BRAND_DEFINITION,
                    {
                        ":seatId": seatId,
                        ":drawerType": drawerType,
                        ":publisherId": publisherId,
                        ":brandId": brandId as number,
                        ":key": key,
                    }
                );
            case InventoryDetailsDrawerType.SUPPLY:
                return replaceWith(
                    isPage
                        ? ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_SUPPLY_DEFINITION
                        : ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_SUPPLY_DEFINITION,
                    {
                        ":seatId": seatId,
                        ":publisherId": publisherId,
                        ":brandId": brandId as number,
                        ":drawerType": drawerType,
                        ":supplyId": supplyId as number,
                        ":key": key,
                    }
                );
            case InventoryDetailsDrawerType.AD_UNIT:
                return replaceWith(
                    isPage
                        ? ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_AD_UNIT_DEFINITION
                        : ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_AD_UNIT_DEFINITION,
                    {
                        ":seatId": seatId,
                        ":publisherId": publisherId,
                        ":brandId": brandId as number,
                        ":supplyId": supplyId as number,
                        ":drawerType": drawerType,
                        ":adUnitId": adUnitId as number,
                        ":key": key,
                    }
                );
        }
    },
    SEAT_INVENTORY_HEALTH_DETAILS_PAGE_UNIT: (
        type: InventoryDetailsDrawerType,
        seatId: string | number,
        publisherId?: string | number,
        brandId?: number | string,
        supplyId?: number | string,
        adUnitId?: number | string
    ) => {
        switch (type) {
            case InventoryDetailsDrawerType.PUBLISHER:
                return replaceWith(ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_PUBLISHER, {
                    ":seatId": seatId,
                    ":drawerType": type,
                    ":publisherId": publisherId as number,
                });
            case InventoryDetailsDrawerType.CHANNEL:
                return replaceWith(ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_CHANNEL, {
                    ":seatId": seatId,
                    ":drawerType": type,
                    ":publisherId": publisherId as number,
                });
            case InventoryDetailsDrawerType.BRAND_CHANNEL:
                return replaceWith(ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_BRAND_CHANNEL, {
                    ":seatId": seatId,
                    ":drawerType": type,
                    ":publisherId": publisherId as number,
                    ":brandId": brandId as number,
                });
            case InventoryDetailsDrawerType.BRAND:
                return replaceWith(ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_BRAND, {
                    ":seatId": seatId,
                    ":drawerType": type,
                    ":publisherId": publisherId as number,
                    ":brandId": brandId as number,
                });
            case InventoryDetailsDrawerType.SUPPLY:
                return replaceWith(ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_SUPPLY, {
                    ":seatId": seatId,
                    ":drawerType": type,
                    ":publisherId": publisherId as number,
                    ":brandId": brandId as number,
                    ":supplyId": supplyId as number,
                });
            case InventoryDetailsDrawerType.AD_UNIT:
                return replaceWith(ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_AD_UNIT, {
                    ":seatId": seatId,
                    ":publisherId": publisherId as number,
                    ":brandId": brandId as number,
                    ":supplyId": supplyId as number,
                    ":adUnitId": adUnitId as number,
                });
            default:
                return "";
        }
    },
    SEAT_INVENTORY: (seatId: number | string, key: string) => {
        switch (key) {
            case INVENTORY_LIST_PAGES.HEALTH:
                return replaceWith(ROUTES.SEAT_INVENTORY_HEALTH, { ":seatId": seatId });
            case INVENTORY_LIST_PAGES.SEAT:
                return replaceWith(ROUTES.SEAT_INVENTORY_SEAT_DETAILS, { ":seatId": seatId });
            case INVENTORY_LIST_PAGES.PERMISSIONS:
                return replaceWith(ROUTES.SEAT_INVENTORY_PERMISSIONS, { ":seatId": seatId });
            case INVENTORY_LIST_PAGES.FLOORS:
                return replaceWith(ROUTES.SEAT_INVENTORY_FLOOR_HIERARCHICAL, { ":seatId": seatId });
            case INVENTORY_LIST_PAGES.BRAND_SAFETY:
                return replaceWith(ROUTES.SEAT_INVENTORY_BRAND_SAFETY_HIERARCHICAL, { ":seatId": seatId });
            default:
                return replaceWith(ROUTES.SEAT_INVENTORY_DETAILS, { ":seatId": seatId });
        }
    },
    SEAT_INVENTORY_DETAILS: (seatId: number | string) =>
        replaceWith(ROUTES.SEAT_INVENTORY_DETAILS, { ":seatId": seatId }),
    SEAT_INVENTORY_DETAILS_PUBLISHER_BRANDS: (seatId: number | string, publisherId: number | string) =>
        replaceWith(ROUTES.SEAT_INVENTORY_DETAILS_PUBLISHER_BRANDS, { ":seatId": seatId, ":publisherId": publisherId }),
    SEAT_INVENTORY_DETAILS_PUBLISHER_CHANNEL_BRANDS: (seatId: number | string, channelId: number | string) =>
        replaceWith(ROUTES.SEAT_INVENTORY_DETAILS_PUBLISHER_CHANNEL_BRANDS, {
            ":seatId": seatId,
            ":channelId": channelId,
        }),
    SEAT_INVENTORY_DETAILS_PUBLISHER_BRAND_CHANNEL_CREATE: (seatId: number | string, publisherId: number | string) =>
        replaceWith(ROUTES.SEAT_INVENTORY_DETAILS_PUBLISHER_BRAND_CHANNEL_CREATE, {
            ":seatId": seatId,
            ":publisherId": publisherId,
        }),
    SEAT_INVENTORY_DETAILS_PUBLISHER_BRAND_CHANNEL_EDIT: (
        seatId: number | string,
        publisherId: number | string,
        brandId: number | string
    ) =>
        replaceWith(ROUTES.SEAT_INVENTORY_DETAILS_PUBLISHER_BRAND_CHANNEL_EDIT, {
            ":seatId": seatId,
            ":publisherId": publisherId,
            ":brandId": brandId,
        }),
    SEAT_INVENTORY_DETAILS_PUBLISHER_BRAND_CREATE: (seatId: number | string, publisherId: number | string) =>
        replaceWith(ROUTES.SEAT_INVENTORY_DETAILS_PUBLISHER_BRAND_CREATE, {
            ":seatId": seatId,
            ":publisherId": publisherId,
        }),
    SEAT_INVENTORY_DETAILS_PUBLISHER_BRAND_EDIT: (
        seatId: number | string,
        publisherId: number | string,
        brandId: number | string
    ) =>
        replaceWith(ROUTES.SEAT_INVENTORY_DETAILS_PUBLISHER_BRAND_EDIT, {
            ":seatId": seatId,
            ":publisherId": publisherId,
            ":brandId": brandId,
        }),
    SEAT_INVENTORY_DETAILS_PUBLISHER_BRAND_COPY: (
        seatId: number | string,
        publisherId: number | string,
        brandId: number | string
    ) =>
        replaceWith(ROUTES.SEAT_INVENTORY_DETAILS_PUBLISHER_BRAND_COPY, {
            ":seatId": seatId,
            ":publisherId": publisherId,
            ":brandId": brandId,
        }),

    SEAT_INVENTORY_DETAILS_PUBLISHER_BRAND_SUPPLY_CREATE: (
        seatId: number | string,
        publisherId: number | string,
        brandId: number | string
    ) =>
        replaceWith(ROUTES.SEAT_INVENTORY_DETAILS_PUBLISHER_BRAND_SUPPLY_CREATE, {
            ":seatId": seatId,
            ":publisherId": publisherId,
            ":brandId": brandId,
        }),
    SEAT_INVENTORY_DETAILS_PUBLISHER_BRAND_SUPPLY_EDIT: (
        seatId: number | string,
        publisherId: number | string,
        brandId: number | string,
        supplyId: number | string
    ) =>
        replaceWith(ROUTES.SEAT_INVENTORY_DETAILS_PUBLISHER_BRAND_SUPPLY_EDIT, {
            ":seatId": seatId,
            ":publisherId": publisherId,
            ":brandId": brandId,
            ":supplyId": supplyId,
        }),
    SEAT_INVENTORY_DETAILS_PUBLISHER_BRAND_SUPPLY_COPY: (
        seatId: number | string,
        publisherId: number | string,
        brandId: number | string,
        supplyId: number | string
    ) =>
        replaceWith(ROUTES.SEAT_INVENTORY_DETAILS_PUBLISHER_BRAND_SUPPLY_COPY, {
            ":seatId": seatId,
            ":publisherId": publisherId,
            ":brandId": brandId,
            ":supplyId": supplyId,
        }),
    SEAT_INVENTORY_DETAILS_PUBLISHER_BRAND_SUPPLY_AD_UNIT_CREATE: (
        seatId: number | string,
        publisherId: number | string,
        brandId: number | string,
        supplyId: number | string
    ) =>
        replaceWith(ROUTES.SEAT_INVENTORY_DETAILS_PUBLISHER_BRAND_SUPPLY_AD_UNIT_CREATE, {
            ":seatId": seatId,
            ":publisherId": publisherId,
            ":brandId": brandId,
            ":supplyId": supplyId,
        }),
    SEAT_INVENTORY_DETAILS_PUBLISHER_BRAND_SUPPLY_AD_UNIT_COPY: (
        seatId: number | string,
        publisherId: number | string,
        brandId: number | string,
        supplyId: number | string,
        adUnitId: number | string
    ) =>
        replaceWith(ROUTES.SEAT_INVENTORY_DETAILS_PUBLISHER_BRAND_SUPPLY_AD_UNIT_COPY, {
            ":seatId": seatId,
            ":publisherId": publisherId,
            ":brandId": brandId,
            ":supplyId": supplyId,
            ":adUnitId": adUnitId,
        }),
    SEAT_INVENTORY_DETAILS_PUBLISHER_BRAND_SUPPLY_AD_UNIT_EDIT: (
        seatId: number | string,
        publisherId: number | string,
        brandId: number | string,
        supplyId: number | string,
        adUnitId: number | string
    ) =>
        replaceWith(ROUTES.SEAT_INVENTORY_DETAILS_PUBLISHER_BRAND_SUPPLY_AD_UNIT_EDIT, {
            ":seatId": seatId,
            ":publisherId": publisherId,
            ":brandId": brandId,
            ":supplyId": supplyId,
            ":adUnitId": adUnitId,
        }),
    SEAT_INVENTORY_DETAILS_PUBLISHER_CREATE: (seatId: number | string) =>
        replaceWith(ROUTES.SEAT_INVENTORY_DETAILS_PUBLISHER_CREATE, { ":seatId": seatId }),
    SEAT_INVENTORY_DETAILS_PUBLISHER_EDIT: (seatId: number | string, publisherId: number | string) =>
        replaceWith(ROUTES.SEAT_INVENTORY_DETAILS_PUBLISHER_EDIT, { ":seatId": seatId, ":publisherId": publisherId }),
    SEAT_INVENTORY_DETAILS_PUBLISHER_COPY: (seatId: number | string, publisherId: number | string) =>
        replaceWith(ROUTES.SEAT_INVENTORY_DETAILS_PUBLISHER_COPY, { ":seatId": seatId, ":publisherId": publisherId }),
    SEAT_INVENTORY_DETAILS_PUBLISHER_CHANNEL_CREATE: (seatId: number | string) =>
        replaceWith(ROUTES.SEAT_INVENTORY_DETAILS_PUBLISHER_CHANNEL_CREATE, { ":seatId": seatId }),
    SEAT_INVENTORY_DETAILS_PUBLISHER_CHANNEL_EDIT: (seatId: number | string, channelId: number | string) =>
        replaceWith(ROUTES.SEAT_INVENTORY_DETAILS_PUBLISHER_CHANNEL_EDIT, {
            ":seatId": seatId,
            ":channelId": channelId,
        }),
    SEAT_INVENTORY_SEAT_DETAILS: (seatId: number | string) =>
        replaceWith(ROUTES.SEAT_INVENTORY_SEAT_DETAILS, { ":seatId": seatId }),
    SEAT_INVENTORY_PROTECTIONS_DETAILS: (seatId: number | string) =>
        replaceWith(ROUTES.SEAT_INVENTORY_SEAT_PROTECTIONS, { ":seatId": seatId }),
    SEAT_INVENTORY_ADVANCED_DETAILS: (seatId: number | string) =>
        replaceWith(ROUTES.SEAT_INVENTORY_SEAT_ADVANCED, { ":seatId": seatId }),
    SEAT_INVENTORY_SEAT_EDIT_DETAILS: (seatId: number | string) =>
        replaceWith(ROUTES.SEAT_INVENTORY_SEAT_EDIT_DETAILS, { ":seatId": seatId }),
    SEAT_INVENTORY_SEAT_TABS: (seatId: number | string, tab: INVENTORY_SEAT_TABS_TYPES) => {
        switch (tab) {
            case INVENTORY_SEAT_DETAILS:
                return replaceWith(ROUTES.SEAT_INVENTORY_SEAT_DETAILS, { ":seatId": seatId });
            case INVENTORY_SEAT_FLOORS:
                return replaceWith(ROUTES.SEAT_INVENTORY_SEAT_FLOORS, { ":seatId": seatId });
            case INVENTORY_SEAT_DEFAULT_PRICES:
                return replaceWith(ROUTES.SEAT_INVENTORY_SEAT_PRICES, { ":seatId": seatId });
            case INVENTORY_SEAT_PROTECTIONS:
                return replaceWith(ROUTES.SEAT_INVENTORY_SEAT_PROTECTIONS, { ":seatId": seatId });
            case INVENTORY_SEAT_ADVANCED:
                return replaceWith(ROUTES.SEAT_INVENTORY_SEAT_ADVANCED, { ":seatId": seatId });
            case INVENTORY_SEAT_INTERNAL:
                return replaceWith(ROUTES.SEAT_INVENTORY_SEAT_INTERNAL, { ":seatId": seatId });
            case INVENTORY_SEAT_INTERNAL_DEFINITION:
                return replaceWith(ROUTES.SEAT_INVENTORY_SEAT_INTERNAL_DEFINITION, { ":seatId": seatId });

            default:
                return replaceWith(ROUTES.SEAT_INVENTORY_SEAT_DETAILS, { ":seatId": seatId });
        }
    },
    SEAT_INVENTORY_SEAT_EDIT_TABS: (seatId: number | string, tab: INVENTORY_SEAT_TABS_TYPES) => {
        switch (tab) {
            case INVENTORY_SEAT_DETAILS:
                return replaceWith(ROUTES.SEAT_INVENTORY_SEAT_EDIT_DETAILS, { ":seatId": seatId });
            case INVENTORY_SEAT_FLOORS:
                return replaceWith(ROUTES.SEAT_INVENTORY_SEAT_EDIT_FLOORS, { ":seatId": seatId });
            case INVENTORY_SEAT_PROTECTIONS:
                return replaceWith(ROUTES.SEAT_INVENTORY_SEAT_EDIT_PROTECTIONS, { ":seatId": seatId });
            case INVENTORY_SEAT_DEFAULT_PRICES:
                return replaceWith(ROUTES.SEAT_INVENTORY_SEAT_EDIT_PRICES, { ":seatId": seatId });
            case INVENTORY_SEAT_ADVANCED:
                return replaceWith(ROUTES.SEAT_INVENTORY_SEAT_EDIT_ADVANCED, { ":seatId": seatId });
            case INVENTORY_SEAT_INTERNAL:
                return replaceWith(ROUTES.SEAT_INVENTORY_SEAT_EDIT_INTERNAL, { ":seatId": seatId });

            default:
                return replaceWith(ROUTES.SEAT_INVENTORY_SEAT_EDIT_DETAILS, { ":seatId": seatId });
        }
    },
    SEAT_INVENTORY_SEAT_FLOORS: (seatId: number | string) =>
        replaceWith(ROUTES.SEAT_INVENTORY_SEAT_FLOORS, { ":seatId": seatId }),
    SEAT_INVENTORY_SEAT_EDIT_FLOORS: (seatId: number | string) =>
        replaceWith(ROUTES.SEAT_INVENTORY_SEAT_EDIT_FLOORS, { ":seatId": seatId }),
    SEAT_INVENTORY_FLOOR_HIERARCHICAL_EDIT: (seatId: number | string, floorId: number | string) =>
        replaceWith(ROUTES.SEAT_INVENTORY_FLOOR_HIERARCHICAL_EDIT, { ":seatId": seatId, ":floorId": floorId }),
    SEAT_INVENTORY_FLOOR_HIERARCHICAL_PUBLISHER_FLOOR_CREATE: (seatId: number | string, publisherId: number | string) =>
        replaceWith(ROUTES.SEAT_INVENTORY_FLOOR_HIERARCHICAL_PUBLISHER_FLOOR_CREATE, {
            ":seatId": seatId,
            ":publisherId": publisherId,
        }),
    SEAT_INVENTORY_DETAILS_PUBLISHER_BRAND_SUPPLY: (
        seatId: number | string,
        publisherId: number | string,
        brandId: number | string
    ) =>
        replaceWith(ROUTES.SEAT_INVENTORY_DETAILS_PUBLISHER_BRAND_SUPPLY, {
            ":seatId": seatId,
            ":publisherId": publisherId,
            ":brandId": brandId,
        }),
    SEAT_INVENTORY_DETAILS_PUBLISHER_BRAND_SUPPLY_DETAILS: (
        seatId: number | string,
        publisherId: number | string,
        brandId: number | string,
        supplyId: number | string
    ) =>
        replaceWith(ROUTES.SEAT_INVENTORY_DETAILS_PUBLISHER_BRAND_SUPPLY_DETAILS, {
            ":seatId": seatId,
            ":publisherId": publisherId,
            ":brandId": brandId,
            ":supplyId": supplyId,
        }),
    SEAT_INVENTORY_DETAILS_PUBLISHER_BRAND_SUPPLY_AD_UNIT_DETAILS: (
        seatId: number | string,
        publisherId: number | string,
        brandId: number | string,
        supplyId: number | string,
        adUnit: number | string
    ) =>
        replaceWith(ROUTES.SEAT_INVENTORY_DETAILS_PUBLISHER_BRAND_SUPPLY_AD_UNIT_DETAILS, {
            ":seatId": seatId,
            ":publisherId": publisherId,
            ":brandId": brandId,
            ":supplyId": supplyId,
            ":adUnit": adUnit,
        }),
    SEAT_INVENTORY_FLOOR_TABS: (
        seatId: number | string,
        tab: typeof INVENTORY_FLOORS_HIERARCHICAL | typeof INVENTORY_FLOORS_ADVANCED
    ) => {
        switch (tab) {
            case INVENTORY_FLOORS_HIERARCHICAL:
                return replaceWith(ROUTES.SEAT_INVENTORY_FLOOR_HIERARCHICAL, { ":seatId": seatId });
            case INVENTORY_FLOORS_ADVANCED:
                return replaceWith(ROUTES.SEAT_INVENTORY_FLOOR_ADVANCED, { ":seatId": seatId });
            default:
                return replaceWith(ROUTES.SEAT_INVENTORY_FLOOR_HIERARCHICAL, { ":seatId": seatId });
        }
    },
    SEAT_INVENTORY_FLOOR_HIERARCHICAL: (seatId: number | string) =>
        replaceWith(ROUTES.SEAT_INVENTORY_FLOOR_HIERARCHICAL, { ":seatId": seatId }),
    SEAT_INVENTORY_FLOOR_HIERARCHICAL_CREATE: (seatId: number | string) =>
        replaceWith(ROUTES.SEAT_INVENTORY_FLOOR_HIERARCHICAL_CREATE, { ":seatId": seatId }),
    SEAT_INVENTORY_FLOOR_ADVANCED_DETAILS: (seatId: number | string, id: number | string) =>
        replaceWith(ROUTES.SEAT_INVENTORY_FLOOR_ADVANCED_DETAILS, { ":seatId": seatId, ":id": id }),
    SEAT_INVENTORY_FLOOR_ADVANCED_DEFINITION: (seatId: number | string, sectionKey: ADVANCED_FLOOR_SECTION_NAMES) =>
        replaceWith(ROUTES.SEAT_INVENTORY_FLOOR_ADVANCED_DEFINITION, { ":seatId": seatId, ":key": sectionKey }),
    SEAT_INVENTORY_FLOOR_ADVANCED_EDIT_DEFINITION: (
        seatId: number | string,
        id: number | string,
        sectionKey: ADVANCED_FLOOR_SECTION_NAMES
    ) =>
        replaceWith(ROUTES.SEAT_INVENTORY_FLOOR_ADVANCED_EDIT_DEFINITION, {
            ":seatId": seatId,
            ":id": id,
            ":key": sectionKey,
        }),
    SEAT_INVENTORY_FLOOR_ADVANCED_CREATE: (seatId: number | string) =>
        replaceWith(ROUTES.SEAT_INVENTORY_FLOOR_ADVANCED_CREATE, { ":seatId": seatId }),
    SEAT_INVENTORY_FLOOR_ADVANCED_EDIT: (seatId: number | string, id: number | string) =>
        replaceWith(ROUTES.SEAT_INVENTORY_FLOOR_ADVANCED_EDIT, { ":seatId": seatId, ":id": id }),
    SEAT_INVENTORY_FLOOR_PUBLISHER: (seatId: number | string, publisherId: number | string) =>
        replaceWith(ROUTES.SEAT_INVENTORY_FLOOR_PUBLISHER, { ":seatId": seatId, ":publisherId": publisherId }),
    SEAT_INVENTORY_FLOOR_HIERARCHICAL_PUBLISHER_FLOOR_EDIT: (
        seatId: number | string,
        publisherId: number | string,
        floorId: number | string
    ) =>
        replaceWith(ROUTES.SEAT_INVENTORY_FLOOR_HIERARCHICAL_PUBLISHER_FLOOR_EDIT, {
            ":seatId": seatId,
            ":publisherId": publisherId,
            ":floorId": floorId,
        }),
    SEAT_INVENTORY_FLOOR_HIERARCHICAL_BRAND_FLOOR_EDIT: (
        seatId: number | string,
        publisherId: number | string,
        brandId: number | string,
        floorId: number | string
    ) =>
        replaceWith(ROUTES.SEAT_INVENTORY_FLOOR_HIERARCHICAL_BRAND_FLOOR_EDIT, {
            ":seatId": seatId,
            ":publisherId": publisherId,
            ":brandId": brandId,
            ":floorId": floorId,
        }),
    SEAT_INVENTORY_FLOOR_PUBLISHER_BRAND: (seatId: number | string, brandId: number | string) =>
        replaceWith(ROUTES.SEAT_INVENTORY_FLOOR_PUBLISHER_BRAND, {
            ":seatId": seatId,
            ":brandId": brandId,
        }),
    SEAT_INVENTORY_SUPPLY_FLOORS: (seatId: number | string, supplyId: number | string) =>
        replaceWith(ROUTES.SEAT_INVENTORY_SUPPLY_FLOORS, {
            ":seatId": seatId,
            ":supplyId": supplyId,
        }),
    SEAT_INVENTORY_AD_UNITS_FLOORS: (seatId: number | string, adUnitId: number | string) =>
        replaceWith(ROUTES.SEAT_INVENTORY_AD_UNITS_FLOORS, {
            ":seatId": seatId,
            ":adUnitId": adUnitId,
        }),
    SEAT_INVENTORY_FLOOR_HIERARCHICAL_BRAND_FLOOR_CREATE: (seatId: number | string, brandId: number | string) =>
        replaceWith(ROUTES.SEAT_INVENTORY_FLOOR_HIERARCHICAL_BRAND_FLOOR_CREATE, {
            ":seatId": seatId,
            ":brandId": brandId,
        }),
    SEAT_INVENTORY_FLOOR_HIERARCHICAL_SUPPLY_FLOOR_CREATE: (seatId: number | string, supplyId: number | string) =>
        replaceWith(ROUTES.SEAT_INVENTORY_FLOOR_HIERARCHICAL_SUPPLY_FLOOR_CREATE, {
            ":seatId": seatId,
            ":supplyId": supplyId,
        }),
    SEAT_INVENTORY_FLOOR_HIERARCHICAL_AD_UNIT_FLOOR_CREATE: (seatId: number | string, adUnitId: number | string) =>
        replaceWith(ROUTES.SEAT_INVENTORY_FLOOR_HIERARCHICAL_AD_UNIT_FLOOR_CREATE, {
            ":seatId": seatId,
            ":adUnitId": adUnitId,
        }),
    SEAT_INVENTORY_BRAND_SAFETY_TABS: (
        seatId: number | string,
        tab: typeof BRAND_SAFETY_HIERARCHICAL_KEY | typeof BRAND_SAFETY_ADVANCEDL_KEY
    ) => {
        switch (tab) {
            case BRAND_SAFETY_HIERARCHICAL_KEY:
                return replaceWith(ROUTES.SEAT_INVENTORY_BRAND_SAFETY_HIERARCHICAL, { ":seatId": seatId });
            case BRAND_SAFETY_ADVANCEDL_KEY:
                return replaceWith(ROUTES.SEAT_INVENTORY_BRAND_SAFETY_ADVANCED, { ":seatId": seatId });
            default:
                return replaceWith(ROUTES.SEAT_INVENTORY_BRAND_SAFETY_HIERARCHICAL, { ":seatId": seatId });
        }
    },
    SEAT_INVENTORY_BRAND_SAFETY_ADVANCED_DETAILS: (seatId: string | number, id: number | string) =>
        replaceWith(ROUTES.SEAT_INVENTORY_BRAND_SAFETY_ADVANCED_DETAILS, { ":seatId": seatId, ":id": id }),
    SEAT_INVENTORY_BRAND_SAFETY_ADVANCED_DEFINITION: (seatId: string | number, key: BrandSafetySectionKey) =>
        replaceWith(ROUTES.SEAT_INVENTORY_BRAND_SAFETY_ADVANCED_DEFINITION, { ":seatId": seatId, ":key": key }),
    SEAT_INVENTORY_BRAND_SAFETY_ADVANCED_CREATE: (seatId: string | number) =>
        replaceWith(ROUTES.SEAT_INVENTORY_BRAND_SAFETY_ADVANCED_CREATE, { ":seatId": seatId }),
    SEAT_INVENTORY_BRAND_SAFETY_ADVANCED_EDIT: (seatId: string | number, id: number) =>
        replaceWith(ROUTES.SEAT_INVENTORY_BRAND_SAFETY_ADVANCED_EDIT, { ":seatId": seatId, ":id": id }),
    SEAT_INVENTORY_FLOOR_ADVANCED: (seatId: number | string) =>
        replaceWith(ROUTES.SEAT_INVENTORY_FLOOR_ADVANCED, { ":seatId": seatId }),
    SEAT_INVENTORY_BRAND_SAFETY_HIERARCHICAL_PUBLISHER: (seatId: number | string, publisherId: number | string) =>
        replaceWith(ROUTES.SEAT_INVENTORY_BRAND_SAFETY_HIERARCHICAL_PUBLISHER, {
            ":seatId": seatId,
            ":publisherId": publisherId,
        }),
    SEAT_INVENTORY_BRAND_SAFETY_HIERARCHICAL_BRAND: (seatId: number | string, brandId: number | string) =>
        replaceWith(ROUTES.SEAT_INVENTORY_BRAND_SAFETY_HIERARCHICAL_BRAND, {
            ":seatId": seatId,
            ":brandId": brandId,
        }),
    SEAT_INVENTORY_BRAND_SAFETY_HIERARCHICAL_SUPPLY: (seatId: number | string, supplyId: number | string) =>
        replaceWith(ROUTES.SEAT_INVENTORY_BRAND_SAFETY_HIERARCHICAL_SUPPLY, {
            ":seatId": seatId,
            ":supplyId": supplyId,
        }),
    SEAT_INVENTORY_BRAND_SAFETY_HIERARCHICAL_AD_UNIT: (seatId: number | string, adUnitId: number | string) =>
        replaceWith(ROUTES.SEAT_INVENTORY_BRAND_SAFETY_HIERARCHICAL_AD_UNIT, {
            ":seatId": seatId,
            ":adUnitId": adUnitId,
        }),
    SEAT_REPORTS: (seatId: number | string) => replaceWith(ROUTES.SEAT_REPORTS, { ":seatId": seatId }),
    SEAT_REPORTS_ADHOC_RESULTS: (seatId: number | string, executionCode: string) =>
        replaceWith(ROUTES.SEAT_REPORTS_ADHOC_RESULTS, {
            ":seatId": seatId,
            ":executionCode": executionCode,
        }),
    SEAT_REPORTS_SAVED_RESULTS: (seatId: number | string, reportId: number | string, executionCode: string) =>
        replaceWith(ROUTES.SEAT_REPORTS_SAVED_RESULTS, {
            ":seatId": seatId,
            ":reportId": reportId,
            ":executionCode": executionCode,
        }),
    SEAT_REPORTS_CONSOLE: (seatId: number | string, tab: ReportRoute = REPORTS_ROUTES.CUSTOM_REPORTS) =>
        replaceWith(ROUTES.SEAT_REPORTS_CONSOLE, { ":seatId": seatId, ":tab": tab }),
    SEAT_REPORTS_CONSOLE_TABS: (seatId: number | string, tab: ReportRoute = REPORTS_ROUTES.CUSTOM_REPORTS) =>
        replaceWith(ROUTES.SEAT_REPORTS_CONSOLE_TABS, { ":seatId": seatId, ":tab": tab }),
    SEAT_REPORTS_CONSOLE_CUSTOM_REPORTS_TABS: (
        seatId: number | string,
        tab: CustomReportTabRoute = CUSTOM_REPORTS_ROUTES.RECENT_ACTIVITIES
    ) => replaceWith(ROUTES.SEAT_REPORTS_CONSOLE_CUSTOM_REPORTS_TABS, { ":seatId": seatId, ":tab": tab }),
    SEAT_REPORTS_CONSOLE_CUSTOM_REPORTS_NEW_REPORT: (seatId: number | string) =>
        replaceWith(ROUTES.SEAT_REPORTS_CONSOLE_CUSTOM_REPORTS_NEW_REPORT, { ":seatId": seatId }),
    SEAT_REPORTS_CONSOLE_RESULTS: (seatId: number | string, executionCode: string) =>
        replaceWith(ROUTES.SEAT_REPORTS_CONSOLE_RESULTS, {
            ":seatId": seatId,
            ":executionCode": executionCode,
        }),
    SEAT_USER_PREFERENCES_LEGACY: (seatId: number | string) =>
        replaceWith(ROUTES.SEAT_USER_PREFERENCES_LEGACY, { ":seatId": seatId }),
    SEAT_USER_PREFERENCES: (seatId: number | string) =>
        replaceWith(ROUTES.SEAT_USER_PREFERENCES, { ":seatId": seatId }),
    BUYER_USER_PREFERENCES_LEGACY: (buyerId: number | string) =>
        replaceWith(ROUTES.BUYER_USER_PREFERENCES_LEGACY, { ":buyerId": buyerId }),
    BUYER_USER_PREFERENCES: (buyerId: number | string) =>
        replaceWith(ROUTES.BUYER_USER_PREFERENCES, { ":buyerId": buyerId }),
    SEAT_USER_ADMIN: (seatId: number | string) => replaceWith(ROUTES.SEAT_USER_ADMIN, { ":seatId": seatId }),
    SEAT_DEAL_LIBRARY: (seatId: number | string) => replaceWith(ROUTES.SEAT_DEAL_LIBRARY, { ":seatId": seatId }),
    BUYER_USER_ADMIN: (buyerId: number | string) => replaceWith(ROUTES.BUYER_USER_ADMIN, { ":buyerId": buyerId }),
    SEAT_CONTROLS_SEAT_CONTACTS_CREATE: (seatId: number | string) =>
        replaceWith(ROUTES.SEAT_CONTROLS_SEAT_CONTACTS_CREATE, {
            ":seatId": seatId,
        }),
    SEAT_CONTROLS_SEAT_CONTACTS_EDIT: (seatId: number | string, contactId: number | string) =>
        replaceWith(ROUTES.SEAT_CONTROLS_SEAT_CONTACTS_EDIT, {
            ":seatId": seatId,
            ":contactId": contactId,
        }),
    SEAT_CONTROLS_SEAT_CONTACT_DETAILS_PAGE: (seatId: number | string, contactId: number | string) =>
        replaceWith(ROUTES.SEAT_CONTROLS_SEAT_CONTACTS_DETAILS, {
            ":seatId": seatId,
            ":contactId": contactId,
        }),
};

export const ROUTES = {
    ADMIN: "/admin/:seatId",
    AD_UNIT_REDIRECT: "/ad-units/:adUnitCode",
    BUYER_DASHBOARD: "/buyers/:buyerId/dashboard",
    BUYER_DEAL_DASHBOARD: "/buyers/:buyerId/deal-dashboard",
    BUYER_DEAL_AD_CAMPAIGNS: "/buyers/:buyerId/ad-campaigns",
    BUYER_DEAL_TRANSPARENCY: "/buyers/:buyerId/transparency",
    BUYER_DIAGNOSTICS: "/buyers/:buyerId/diagnostics",
    BUYER_REPORTS: "/buyers/:buyerId/reports",
    BUYER_DISCOUNTS: "/buyers/:buyerId/discounts",
    BUYER_USER_PREFERENCES_LEGACY: "/buyers/:buyerId/user-preferences-legacy",
    BUYER_USER_PREFERENCES: "/buyers/:buyerId/user-preferences",
    BUYER_USER_ADMIN: "/buyers/:buyerId/admin",
    BUYER_DEAL_LIBRARY: "/buyers/:buyerId/deal-library",
    DEAL_REDIRECT: "/deals/:dealId",
    LOGIN_MAGNITE: "/login/magnite",
    LOGIN_TOKEN_EXCHANGE: "/login/token",
    LOGIN_FORM_CONTEXT_STEP: "/login/context",
    LOGIN_FORM_NO_VALID_CONTEXTS_STEP: "/login/no-contexts",
    LOGIN_FORM_EMAIL_STEP: "/login/email",
    LOGIN_FORM_PASSWORD_STEP: "/login/password",
    LOGIN_FORM_FORGOT_PASSWORD_STEP: "/login/forgot-password",
    LOGIN_FORM: "/login/:step",
    LOGIN: "/login",
    ROOT: "/",
    SEAT_AD_SERVING: "/seats/:seatId/ad-serving",
    SEAT_AD_SOURCES: "/seats/:seatId/ad-sources",
    SEAT_AD_SOURCES_COPY: "/seats/:seatId/ad-sources/:adSourceId/copy",
    SEAT_AD_SOURCES_CREATE: "/seats/:seatId/ad-sources/create",
    SEAT_AD_SOURCES_EDIT: "/seats/:seatId/ad-sources/:adSourceId/edit",
    SEAT_AD_SOURCES_DETAILS: "/seats/:seatId/ad-sources/details-page/:adSourceId",
    SEAT_AD_SOURCES_DETAILS_PAGE_DETAILS_DRAWER: "/seats/:seatId/ad-sources/details-page/details/:adSourceId",
    SEAT_AD_SOURCES_DETAILS_PAGE_SECONDARY_VIEW: "/seats/:seatId/ad-sources/details-page/:adSourceId/:secondaryView",
    SEAT_AD_SOURCES_LIST: "/seats/:seatId/ad-sources",
    SEAT_AD_SOURCES_DETAILS_DRAWER: "/seats/:seatId/ad-sources/details-drawer/:adSourceId",
    SEAT_AD_SOURCES_DETAILS_DRAWER_SECONDARY_VIEW:
        "/seats/:seatId/ad-sources/details-drawer/:adSourceId/:secondaryView",
    SEAT_AD_SOURCES_SECONDARY_VIEW_DRAWER: "/seats/:seatId/ad-sources/:adSourceId/:secondaryView",
    SEAT_AD_SOURCES_PACING: "/seats/:seatId/ad-sources/pacing",
    SEAT_AD_SOURCES_PACING_DETAILS: "/seats/:seatId/ad-sources/pacing/details-drawer/:adSourceId",
    SEAT_AD_SOURCES_PACING_DETAILS_SECONDARY_VIEW:
        "/seats/:seatId/ad-sources/pacing/details-drawer/:adSourceId/:secondaryView",
    SEAT_AD_SOURCES_PACING_SECONDARY_VIEW_DRAWER: "/seats/:seatId/ad-sources/pacing/:adSourceId/:secondaryView",
    SEAT_CONTROLS: "/seats/:seatId/controls",
    SEAT_CONTROLS_TAG_PARAMETERS: "/seats/:seatId/controls/tag-parameters",
    SEAT_CONTROLS_SEAT_CONTACTS: "/seats/:seatId/controls/seat-contacts",
    SEAT_CONTROLS_SEAT_CONTACTS_CREATE: "/seats/:seatId/controls/seat-contacts/create",
    SEAT_CONTROLS_SEAT_CONTACTS_EDIT: "/seats/:seatId/controls/seat-contacts/:contactId/edit",
    SEAT_CONTROLS_SEAT_CONTACTS_DETAILS: "/seats/:seatId/controls/seat-contacts/:contactId/details",
    SEAT_CONTROLS_ENCRYPTION_KEYS: "/seats/:seatId/controls/encryption-keys",

    SEAT_CONTROLS_CUSTOM_PIXELS: "/seats/:seatId/controls/custom-pixels",
    SEAT_CONTROLS_CUSTOM_PIXELS_CREATE: "/seats/:seatId/controls/custom-pixels/create",
    SEAT_CONTROLS_CUSTOM_PIXELS_DETAILS: "/seats/:seatId/controls/custom-pixels/:pixelId",
    SEAT_CONTROLS_CUSTOM_PIXELS_DETAILS_DRAWER: "/seats/:seatId/controls/custom-pixels/drawer/details/:pixelId",
    SEAT_CONTROLS_CUSTOM_PIXELS_EDIT: "/seats/:seatId/controls/custom-pixels/:pixelId/edit",

    SEAT_CONTROLS_ADVANCED_FLOORS: "/seats/:seatId/controls/advanced-floors",
    SEAT_CONTROLS_TARGETING: "/seats/:seatId/controls/targeting",
    SEAT_CONTROLS_TARGETING_CREATE: "/seats/:seatId/controls/targeting/create",
    SEAT_CONTROLS_TARGETING_DETAILS: "/seats/:seatId/controls/targeting/:targetingId",
    SEAT_CONTROLS_TARGETING_EDIT: "/seats/:seatId/controls/targeting/:targetingId/edit",
    SEAT_CONTROLS_TARGETING_DETAILS_DRAWER: "/seats/:seatId/controls/targeting/drawer/details/:targetingId",
    SEAT_CONTROLS_TARGETING_CHANGE_HISTORY: "/seats/:seatId/controls/targeting/change-history/:targetingId",

    SEAT_CONTROLS_BRAND_SAFETY_NO_TAB: "/seats/:seatId/controls/brand-safety",
    SEAT_CONTROLS_BRAND_SAFETY: "/seats/:seatId/controls/brand-safety/:tab?",
    SEAT_CONTROLS_BRAND_SAFETY_TAB_DETAILS_DRAWER: "/seats/:seatId/controls/brand-safety/:tab/details/:id",

    SEAT_CONTROLS_BRAND_SAFETY_ADVERTISER_DOMAINS: "/seats/:seatId/controls/brand-safety/advertiser-domains",
    SEAT_CONTROLS_BRAND_SAFETY_ADVERTISER_DOMAIN_DETAILS:
        "/seats/:seatId/controls/brand-safety/advertiser-domains/:id/details",
    SEAT_CONTROLS_BRAND_SAFETY_ADVERTISER_DOMAIN_CREATE:
        "/seats/:seatId/controls/brand-safety/advertiser-domains/create",
    SEAT_CONTROLS_BRAND_SAFETY_ADVERTISER_DOMAIN_EDIT:
        "/seats/:seatId/controls/brand-safety/advertiser-domains/edit/:id",

    SEAT_CONTROLS_BRAND_SAFETY_SEQUESTERED_DOMAINS: "/seats/:seatId/controls/brand-safety/sequestered-domains",
    SEAT_CONTROLS_BRAND_SAFETY_SEQUESTERED_DOMAIN_EDIT:
        "/seats/:seatId/controls/brand-safety/sequestered-domains/details/:domain",

    SEAT_CONTROLS_BRAND_SAFETY_TRANSPARENCY_DEFAULT: "/seats/:seatId/controls/brand-safety/transparency-default",
    SEAT_CONTROLS_BRAND_SAFETY_TRANSPARENCY_RULES: "/seats/:seatId/controls/brand-safety/transparency-rules",
    SEAT_CONTROLS_BRAND_SAFETY_TRANSPARENCY_RULES_CREATE:
        "/seats/:seatId/controls/brand-safety/transparency-rules/create",
    SEAT_CONTROLS_BRAND_SAFETY_TRANSPARENCY_RULES_EDIT:
        "/seats/:seatId/controls/brand-safety/transparency-rules/edit/:id",

    SEAT_CONTROLS_BRAND_SAFETY_SEPARATION_GROUPS: "/seats/:seatId/controls/brand-safety/separation-groups",
    SEAT_CONTROLS_BRAND_SAFETY_SEPARATION_GROUPS_DETAILS_DRAWER:
        "/seats/:seatId/controls/brand-safety/separation-groups/details/:id",
    SEAT_CONTROLS_BRAND_SAFETY_SEPARATION_GROUPS_DETAILS_PAGE:
        "/seats/:seatId/controls/brand-safety/separation-groups/details-page/:id",
    SEAT_CONTROLS_BRAND_SAFETY_SEPARATION_GROUPS_EDIT_PAGE:
        "/seats/:seatId/controls/brand-safety/separation-groups/edit/:id",
    SEAT_CONTROLS_BRAND_SAFETY_SEPARATION_GROUPS_CREATE_PAGE:
        "/seats/:seatId/controls/brand-safety/separation-groups/create",

    SEAT_CONTROLS_BRAND_SAFETY_ADVANCED_BRAND_SAFETY: "/seats/:seatId/controls/brand-safety/advanced-brand-safety",
    SEAT_CONTROLS_BRAND_SAFETY_ADVANCED_BRAND_SAFETY_EDIT_PAGE:
        "/seats/:seatId/controls/brand-safety/advanced-brand-safety/edit/:id",
    SEAT_CONTROLS_BRAND_SAFETY_ADVANCED_BRAND_SAFETY_CREATE_PAGE:
        "/seats/:seatId/controls/brand-safety/advanced-brand-safety/create",
    SEAT_CONTROLS_BRAND_SAFETY_ADVANCED_BRAND_SAFETY_DETAILS_DRAWER:
        "/seats/:seatId/controls/brand-safety/advanced-brand-safety/details/:id",
    SEAT_CONTROLS_BRAND_SAFETY_ADVANCED_BRAND_SAFETY_DETAILS_PAGE:
        "/seats/:seatId/controls/brand-safety/advanced-brand-safety/details-page/:id",

    SEAT_CONTROLS_BRAND_SAFETY_BUYER_SEAT_LIST: "/seats/:seatId/controls/brand-safety/buyer-seat-list",
    SEAT_CONTROLS_BRAND_SAFETY_BUYER_SEAT_LIST_DETAILS_DRAWER:
        "/seats/:seatId/controls/brand-safety/buyer-seat-list/details/:buyerSeatListId",
    SEAT_CONTROLS_BRAND_SAFETY_BUYER_SEAT_LIST_EDIT_PAGE:
        "/seats/:seatId/controls/brand-safety/buyer-seat-list/edit/:buyerSeatListId",
    SEAT_CONTROLS_BRAND_SAFETY_BUYER_SEAT_LIST_CREATE_PAGE:
        "/seats/:seatId/controls/brand-safety/buyer-seat-list/create",
    SEAT_CONTROLS_BRAND_SAFETY_BUYER_SEAT_LIST_DETAILS_PAGE:
        "/seats/:seatId/controls/brand-safety/buyer-seat-list/details-page/:buyerSeatListId",

    SEAT_CONTROLS_BRAND_SAFETY_MAPPING_EXCEPTIONS_CREATE_PAGE:
        "/seats/:seatId/controls/brand-safety/mapping-exceptions/create",
    SEAT_CONTROLS_BRAND_SAFETY_MAPPING_EXCEPTIONS_DETAILS_PAGE:
        "/seats/:seatId/controls/brand-safety/mapping-exceptions/details-page/:mappingExceptionId",
    SEAT_CONTROLS_BRAND_SAFETY_MAPPING_EXCEPTIONS_EDIT_PAGE:
        "/seats/:seatId/controls/brand-safety/mapping-exceptions/edit/:mappingExceptionId",
    SEAT_CONTROLS_BRAND_SAFETY_MAPPING_EXCEPTIONS_LIST_PAGE: "/seats/:seatId/controls/brand-safety/mapping-exceptions",
    SEAT_CONTROLS_BRAND_SAFETY_MAPPING_EXCEPTIONS_LIST_PAGE_DETAILS_DRAWER:
        "/seats/:seatId/controls/brand-safety/mapping-exceptions/details/:mappingExceptionId",

    SEAT_CONTROLS_FILTERS: "/seats/:seatId/controls/filters",
    SEAT_CONTROLS_BULK_OPERATIONS: "/seats/:seatId/controls/bulk-operations",
    SEAT_CONTROLS_BULK_OPERATIONS_CREATE: "/seats/:seatId/controls/bulk-operations/create",
    SEAT_CONTROLS_CLASSIFICATIONS: "/seats/:seatId/controls/classifications",
    SEAT_CONTROLS_CLASSIFICATIONS_TABS: "/seats/:seatId/controls/classifications/:classificationsTab",
    SEAT_CONTROLS_CLASSIFICATIONS_ADVERTISERS: "/seats/:seatId/controls/classifications/advertisers",
    SEAT_CONTROLS_CLASSIFICATIONS_ADVERTISER_DETAILS_PAGE:
        "/seats/:seatId/controls/classifications/advertisers/:advertiserId/details",
    SEAT_CONTROLS_CLASSIFICATIONS_ADVERTISER_CREATE_PAGE: "/seats/:seatId/controls/classifications/advertisers/create",
    SEAT_CONTROLS_CLASSIFICATIONS_ADVERTISER_EDIT_PAGE:
        "/seats/:seatId/controls/classifications/advertisers/:advertiserId/edit",
    SEAT_CONTROLS_CLASSIFICATIONS_INDUSTRIES: "/seats/:seatId/controls/classifications/industries",
    SEAT_CONTROLS_CLASSIFICATIONS_LABELS: "/seats/:seatId/controls/classifications/labels",
    SEAT_CONTROLS_CLASSIFICATIONS_INDUSTRY_DETAILS_PAGE:
        "/seats/:seatId/controls/classifications/industries/:industryId/details",
    SEAT_CONTROLS_CLASSIFICATIONS_INDUSTRY_CREATE_PAGE: "/seats/:seatId/controls/classifications/industries/create",
    SEAT_CONTROLS_CLASSIFICATIONS_INDUSTRY_EDIT_PAGE:
        "/seats/:seatId/controls/classifications/industries/:industryId/edit",
    SEAT_CONTROLS_CLASSIFICATIONS_LABEL_DETAILS_PAGE: "/seats/:seatId/controls/classifications/labels/:labelId/details",
    SEAT_CONTROLS_CLASSIFICATIONS_LABEL_CREATE_PAGE: "/seats/:seatId/controls/classifications/labels/create",
    SEAT_CONTROLS_CLASSIFICATIONS_LABEL_EDIT_PAGE: "/seats/:seatId/controls/classifications/labels/:labelId/edit",
    SEAT_CONTROLS_AUDIT_LOGS: "/seats/:seatId/controls/audit-logs",
    SEAT_CONTROLS_RECYCLE_BIN: "/seats/:seatId/controls/recycle-bin",
    SEAT_CONTROLS_PRICE_OVERRIDES: "/seats/:seatId/controls/price-overrides",
    SEAT_CONTROLS_PRICE_OVERRIDE_DETAILS_PAGE: "/seats/:seatId/controls/price-overrides/details/:priceOverrideId",
    SEAT_CONTROLS_PRICE_OVERRIDE_CREATE_PAGE: "/seats/:seatId/controls/price-overrides/create",
    SEAT_CONTROLS_PRICE_OVERRIDE_EDIT_PAGE: "/seats/:seatId/controls/price-overrides/:priceOverrideId/edit",
    SEAT_CONTROLS_TABS: "/seats/:seatId/controls/:tab?",
    SEAT_CONTROLS_TABS_CREATE: "/seats/:seatId/controls/:tab?code=:id", // TODO remove query parameter once controls be not iframe
    SEAT_CREATIVE_REVIEW: "/seats/:seatId/creative-review",
    SEAT_DASHBOARD: "/seats/:seatId/seat-dashboard",
    SEAT_DEAL_COPY: "/seats/:seatId/deals/:dealId/copy",
    SEAT_DEAL_COPY_AD_SOURCES_DRAWER: "/seats/:seatId/deals/:dealId/copy/ad-source-details/:adSourceId",
    SEAT_DEAL_COPY_AD_SOURCES_DRAWER_SECONDARY_VIEW:
        "/seats/:seatId/deals/:dealId/copy/ad-source-details/:adSourceId/:secondaryView",
    SEAT_DEAL_CREATE_TYPE: "/seats/:seatId/deals/create/:dealType",
    SEAT_DEAL_CREATE: "/seats/:seatId/deals/create",
    SEAT_DEAL_CREATE_TYPE_AD_SOURCES_DRAWER: "/seats/:seatId/deals/create/:dealType/ad-source-details/:adSourceId",
    SEAT_DEAL_CREATE_TYPE_AD_SOURCES_DRAWER_SECONDARY_VIEW:
        "/seats/:seatId/deals/create/:dealType/ad-source-details/:adSourceId/:secondaryView",
    SEAT_DEAL_DETAILS: "/seats/:seatId/deals/details/:dealId",
    SEAT_DEAL_DETAILS_AD_SOURCES_DRAWER: "/seats/:seatId/deals/:dealId/ad-source-details/:adSourceId",
    SEAT_DEAL_DETAILS_AD_SOURCES_DRAWER_SECONDARY_VIEW:
        "/seats/:seatId/deals/:dealId/ad-source-details/:adSourceId/:secondaryView",
    SEAT_DEAL_EDIT: "/seats/:seatId/deals/:dealId/edit",
    SEAT_DEAL_EDIT_AD_SOURCES_DRAWER: "/seats/:seatId/deals/:dealId/edit/ad-source-details/:adSourceId",
    SEAT_DEAL_EDIT_AD_SOURCES_DRAWER_SECONDARY_VIEW:
        "/seats/:seatId/deals/:dealId/edit/ad-source-details/:adSourceId/:secondaryView",
    SEAT_DEAL_HEALTH: "/seats/:seatId/deals/health",
    SEAT_DEAL_HEALTH_DEAL_DETAILS_DRAWER: "/seats/:seatId/deals/health/deal-details-drawer/:dealId",
    SEAT_DEAL_HEALTH_DEAL_DETAILS_DRAWER_SECONDARY_VIEW:
        "/seats/:seatId/deals/health/deal-details-drawer/:dealId/:secondaryView",
    SEAT_DEAL_HEALTH_DEAL_SECONDARY_VIEW: "/seats/:seatId/deals/health/:dealId/:secondaryView",
    SEAT_DEALS_HEALTH_AD_SOURCES_DRAWER: "/seats/:seatId/deals/health/ad-source-details/:adSourceId",
    SEAT_DEALS_HEALTH_AD_SOURCES_DRAWER_SECONDARY_VIEW:
        "/seats/:seatId/deals/health/ad-source-details/:adSourceId/:secondaryView",
    SEAT_DEAL_LIST: "/seats/:seatId/deals",
    SEAT_DEALS: "/seats/:seatId/deals",
    SEAT_DEALS_DEAL_DETAILS_DRAWER: "/seats/:seatId/deals/deal-details-drawer/:dealId",
    SEAT_DEALS_DEAL_DETAILS_DRAWER_SECONDARY_VIEW: "/seats/:seatId/deals/deal-details-drawer/:dealId/:secondaryView",
    SEAT_DEALS_SECONDARY_VIEW: "/seats/:seatId/deals/:dealId/:secondaryView",
    SEAT_DEALS_AD_SOURCES_DRAWER: "/seats/:seatId/deals/ad-source-details/:adSourceId",
    SEAT_DEALS_AD_SOURCES_DRAWER_SECONDARY_VIEW: "/seats/:seatId/deals/ad-source-details/:adSourceId/:secondaryView",
    SEAT_DEMAND: "/seats/:seatId/demand",
    SEAT_DIAGNOSTICS: "/seats/:seatId/diagnostics",
    SEAT_DIAGNOSTICS_LADLE_RESULTS: "/seats/:seatId/diagnostics/ladle-results/:ladleName",
    SEAT_DIAGNOSTICS_DEAL_DASHBOARD: "/seats/:seatId/diagnostics/deal-dashboard",
    SEAT_DIAGNOSTICS_ADVERTISER_DASHBOARD: "/seats/:seatId/diagnostics/advertiser-dashboard",
    SEAT_DIAGNOSTICS_BIDDER_DIAGNOSTICS: "/seats/:seatId/diagnostics/bidder-diagnostics",
    SEAT_DIAGNOSTICS_LADLE_RESULTS_ALL: "/seats/:seatId/diagnostics/ladle-results",
    SEAT_INTEGRATIONS: "/seats/:seatId/integrations",
    SEAT_INTEGRATIONS_CONSOLE: "/seats/:seatId/integrations-console",
    SEAT_INTEGRATIONS_TABS_CONSOLE: "/seats/:seatId/integrations-console/:tab?",
    SEAT_INVENTORY: "/seats/:seatId/inventory",
    SEAT_INVENTORY_HEALTH: "/seats/:seatId/inventory/health",
    SEAT_INVENTORY_HEALTH_MODEL: "/seats/:seatId/inventory/health/:model/:id",
    SEAT_INVENTORY_HEALTH_MODEL_DEFINITION: "/seats/:seatId/inventory/health/:model/:definition",

    SEAT_INVENTORY_HEALTH_DETAILS: "/seats/:seatId/inventory/health/details/:drawerType/:id", // TODO remove ?

    //Unit details page
    SEAT_INVENTORY_HEALTH_DETAILS_PAGE_PUBLISHER: "/seats/:seatId/inventory/health/details/page/publisher/:publisherId",
    SEAT_INVENTORY_HEALTH_DETAILS_PAGE_CHANNEL: "/seats/:seatId/inventory/health/details/page/channel/:publisherId",
    SEAT_INVENTORY_HEALTH_DETAILS_PAGE_BRAND:
        "/seats/:seatId/inventory/health/details/page/publisher/:publisherId/brand/:brandId",
    SEAT_INVENTORY_HEALTH_DETAILS_PAGE_BRAND_CHANNEL:
        "/seats/:seatId/inventory/health/details/page/publisher/:publisherId/brand-channel/:brandId",
    SEAT_INVENTORY_HEALTH_DETAILS_PAGE_SUPPLY:
        "/seats/:seatId/inventory/health/details/page/publisher/:publisherId/brand/:brandId/supply/:supplyId",
    SEAT_INVENTORY_HEALTH_DETAILS_PAGE_AD_UNIT:
        "/seats/:seatId/inventory/health/details/page/publisher/:publisherId/brand/:brandId/supply/:supplyId/ad-unit/:adUnitId",

    //Unit details
    SEAT_INVENTORY_HEALTH_DETAILS_PUBLISHER: "/seats/:seatId/inventory/health/details/:drawerType/:publisherId",
    SEAT_INVENTORY_HEALTH_DETAILS_BRAND:
        "/seats/:seatId/inventory/health/details/publisher/:publisherId/:drawerType/:brandId",
    SEAT_INVENTORY_HEALTH_DETAILS_SUPPLY:
        "/seats/:seatId/inventory/health/details/publisher/:publisherId/brand/:brandId/:drawerType/:supplyId",
    SEAT_INVENTORY_HEALTH_DETAILS_AD_UNIT:
        "/seats/:seatId/inventory/health/details/publisher/:publisherId/brand/:brandId/supply/:supplyId/:drawerType/:adUnitId",

    //Unit definition
    SEAT_INVENTORY_HEALTH_DETAILS_PAGE_PUBLISHER_DEFINITION:
        "/seats/:seatId/inventory/health/details/page/:drawerType/:publisherId/definition/:key",
    SEAT_INVENTORY_HEALTH_DETAILS_PUBLISHER_DEFINITION:
        "/seats/:seatId/inventory/health/details/:drawerType/:publisherId/definition/:key",
    SEAT_INVENTORY_HEALTH_DETAILS_PAGE_CHANNEL_DEFINITION:
        "/seats/:seatId/inventory/health/details/page/modal/:drawerType/:publisherId/definition/:key",
    SEAT_INVENTORY_HEALTH_DETAILS_CHANNEL_DEFINITION:
        "/seats/:seatId/inventory/health/details/:drawerType/:publisherId/definition/:key",
    SEAT_INVENTORY_HEALTH_DETAILS_PAGE_BRAND_DEFINITION:
        "/seats/:seatId/inventory/health/details/page/publisher/:publisherId/:drawerType/:brandId/definition/:key",
    SEAT_INVENTORY_HEALTH_DETAILS_BRAND_DEFINITION:
        "/seats/:seatId/inventory/health/details/publisher/:publisherId/:drawerType/:brandId/definition/:key",
    SEAT_INVENTORY_HEALTH_DETAILS_PAGE_SUPPLY_DEFINITION:
        "/seats/:seatId/inventory/health/details/page/publisher/:publisherId/brand/:brandId/:drawerType/:supplyId/definition/:key",
    SEAT_INVENTORY_HEALTH_DETAILS_SUPPLY_DEFINITION:
        "/seats/:seatId/inventory/health/details/publisher/:publisherId/brand/:brandId/:drawerType/:supplyId/definition/:key",
    SEAT_INVENTORY_HEALTH_DETAILS_PAGE_BRAND_CHANNEL_DEFINITION:
        "/seats/:seatId/inventory/health/details/page/modal/publisher/:publisherId/:drawerType/:brandId/definition/:key",
    SEAT_INVENTORY_HEALTH_DETAILS_PAGE_AD_UNIT_DEFINITION:
        "/seats/:seatId/inventory/health/details/page/publisher/:publisherId/brand/:brandId/supply/:supplyId/:drawerType/:adUnitId/definition/:key",
    SEAT_INVENTORY_HEALTH_DETAILS_AD_UNIT_DEFINITION:
        "/seats/:seatId/inventory/health/details/publisher/:publisherId/brand/:brandId/supply/:supplyId/:drawerType/:adUnitId/definition/:key",

    //Unit Diagnostics
    SEAT_INVENTORY_HEALTH_DETAILS_SEAT_DIAGNOSTICS: "/seats/:seatId/inventory/health/details/:drawerType/diagnostics",
    SEAT_INVENTORY_HEALTH_DETAILS_PAGE_PUBLISHER_DIAGNOSTICS:
        "/seats/:seatId/inventory/health/details/page/:drawerType/:publisherId/diagnostics",
    SEAT_INVENTORY_HEALTH_DETAILS_PUBLISHER_DIAGNOSTICS:
        "/seats/:seatId/inventory/health/details/:drawerType/:publisherId/diagnostics",
    SEAT_INVENTORY_HEALTH_DETAILS_PAGE_CHANNEL_DIAGNOSTICS:
        "/seats/:seatId/inventory/health/details/page/modal/:drawerType/:publisherId/diagnostics",
    SEAT_INVENTORY_HEALTH_DETAILS_PAGE_BRAND_CHANNEL_DIAGNOSTICS:
        "/seats/:seatId/inventory/health/details/page/modal/publisher/:publisherId/:drawerType/:brandId/diagnostics",
    SEAT_INVENTORY_HEALTH_DETAILS_PAGE_BRAND_DIAGNOSTICS:
        "/seats/:seatId/inventory/health/details/page/publisher/:publisherId/:drawerType/:brandId/diagnostics",
    SEAT_INVENTORY_HEALTH_DETAILS_BRAND_DIAGNOSTICS:
        "/seats/:seatId/inventory/health/details/publisher/:publisherId/:drawerType/:brandId/diagnostics",
    SEAT_INVENTORY_HEALTH_DETAILS_PAGE_SUPPLY_DIAGNOSTICS:
        "/seats/:seatId/inventory/health/details/page/publisher/:publisherId/brand/:brandId/:drawerType/:supplyId/diagnostics",
    SEAT_INVENTORY_HEALTH_DETAILS_SUPPLY_DIAGNOSTICS:
        "/seats/:seatId/inventory/health/details/publisher/:publisherId/brand/:brandId/:drawerType/:supplyId/diagnostics",
    SEAT_INVENTORY_HEALTH_DETAILS_PAGE_AD_UNIT_DIAGNOSTICS:
        "/seats/:seatId/inventory/health/details/page/publisher/:publisherId/brand/:brandId/supply/:supplyId/:drawerType/:adUnitId/diagnostics",
    SEAT_INVENTORY_HEALTH_DETAILS_AD_UNIT_DIAGNOSTICS:
        "/seats/:seatId/inventory/health/details/publisher/:publisherId/brand/:brandId/supply/:supplyId/:drawerType/:adUnitId/diagnostics",

    //Unit Diagnostics definition
    SEAT_INVENTORY_HEALTH_DETAILS_SEAT_DIAGNOSTICS_DEFINITION:
        "/seats/:seatId/inventory/health/details/:drawerType/diagnostics/definition",
    SEAT_INVENTORY_HEALTH_DETAILS_PAGE_PUBLISHER_DIAGNOSTICS_DEFINITION:
        "/seats/:seatId/inventory/health/details/page/:drawerType/:publisherId/diagnostics/definition",
    SEAT_INVENTORY_HEALTH_DETAILS_PUBLISHER_DIAGNOSTICS_DEFINITION:
        "/seats/:seatId/inventory/health/details/:drawerType/:publisherId/diagnostics/definition",
    SEAT_INVENTORY_HEALTH_DETAILS_PAGE_CHANNEL_DIAGNOSTICS_DEFINITION:
        "/seats/:seatId/inventory/health/details/page/modal/:drawerType/:publisherId/diagnostics/definition",
    SEAT_INVENTORY_HEALTH_DETAILS_PAGE_BRAND_CHANNEL_DIAGNOSTICS_DEFINITION:
        "/seats/:seatId/inventory/health/details/page/modal/publisher/:publisherId/:drawerType/:brandId/diagnostics/definition",
    SEAT_INVENTORY_HEALTH_DETAILS_PAGE_BRAND_DIAGNOSTICS_DEFINITION:
        "/seats/:seatId/inventory/health/details/page/publisher/:publisherId/:drawerType/:brandId/diagnostics/definition",
    SEAT_INVENTORY_HEALTH_DETAILS_BRAND_DIAGNOSTICS_DEFINITION:
        "/seats/:seatId/inventory/health/details/publisher/:publisherId/:drawerType/:brandId/diagnostics/definition",
    SEAT_INVENTORY_HEALTH_DETAILS_PAGE_SUPPLY_DIAGNOSTICS_DEFINITION:
        "/seats/:seatId/inventory/health/details/page/publisher/:publisherId/brand/:brandId/:drawerType/:supplyId/diagnostics/definition",
    SEAT_INVENTORY_HEALTH_DETAILS_SUPPLY_DIAGNOSTICS_DEFINITION:
        "/seats/:seatId/inventory/health/details/publisher/:publisherId/brand/:brandId/:drawerType/:supplyId/diagnostics/definition",
    SEAT_INVENTORY_HEALTH_DETAILS_PAGE_AD_UNIT_DIAGNOSTICS_DEFINITION:
        "/seats/:seatId/inventory/health/details/page/publisher/:publisherId/brand/:brandId/supply/:supplyId/:drawerType/:adUnitId/diagnostics/definition",
    SEAT_INVENTORY_HEALTH_DETAILS_AD_UNIT_DIAGNOSTICS_DEFINITION:
        "/seats/:seatId/inventory/health/details/publisher/:publisherId/brand/:brandId/supply/:supplyId/:drawerType/:adUnitId/diagnostics/definition",

    //Unit Diagnostics
    SEAT_INVENTORY_HEALTH_DETAILS_PAGE_PUBLISHER_PERMISSIONS:
        "/seats/:seatId/inventory/health/details/page/:drawerType/:publisherId/permissions",
    SEAT_INVENTORY_HEALTH_DETAILS_PUBLISHER_PERMISSIONS:
        "/seats/:seatId/inventory/health/details/:drawerType/:publisherId/permissions",

    //Unit Tag Params
    SEAT_INVENTORY_HEALTH_DETAILS_SEAT_TAG_PARAMS: "/seats/:seatId/inventory/health/details/:drawerType/tag-params",
    SEAT_INVENTORY_HEALTH_DETAILS_PAGE_PUBLISHER_TAG_PARAMS:
        "/seats/:seatId/inventory/health/details/page/:drawerType/:publisherId/tag-params",
    SEAT_INVENTORY_HEALTH_DETAILS_PUBLISHER_TAG_PARAMS:
        "/seats/:seatId/inventory/health/details/:drawerType/:publisherId/tag-params",
    SEAT_INVENTORY_HEALTH_DETAILS_PAGE_CHANNEL_TAG_PARAMS:
        "/seats/:seatId/inventory/health/details/page/modal/:drawerType/:publisherId/tag-params",
    SEAT_INVENTORY_HEALTH_DETAILS_PAGE_BRAND_TAG_PARAMS:
        "/seats/:seatId/inventory/health/details/publisher/publisher/:publisherId/:drawerType/:brandId/tag-params",
    SEAT_INVENTORY_HEALTH_DETAILS_BRAND_TAG_PARAMS:
        "/seats/:seatId/inventory/health/details/publisher/:publisherId/:drawerType/:brandId/tag-params",
    SEAT_INVENTORY_HEALTH_DETAILS_PAGE_BRAND_CHANNEL_TAG_PARAMS:
        "/seats/:seatId/inventory/health/details/page/modal/publisher/:publisherId/:drawerType/:brandId/tag-params",
    SEAT_INVENTORY_HEALTH_DETAILS_PAGE_SUPPLY_TAG_PARAMS:
        "/seats/:seatId/inventory/health/details/page/publisher/:publisherId/brand/:brandId/:drawerType/:supplyId/tag-params",
    SEAT_INVENTORY_HEALTH_DETAILS_SUPPLY_TAG_PARAMS:
        "/seats/:seatId/inventory/health/details/publisher/:publisherId/brand/:brandId/:drawerType/:supplyId/tag-params",
    SEAT_INVENTORY_HEALTH_DETAILS_PAGE_AD_UNIT_TAG_PARAMS:
        "/seats/:seatId/inventory/health/details/page/publisher/:publisherId/brand/:brandId/supply/:supplyId/:drawerType/:adUnitId/tag-params",
    SEAT_INVENTORY_HEALTH_DETAILS_AD_UNIT_TAG_PARAMS:
        "/seats/:seatId/inventory/health/details/publisher/:publisherId/brand/:brandId/supply/:supplyId/:drawerType/:adUnitId/tag-params",

    //Unit Floor Analysis
    SEAT_INVENTORY_HEALTH_DETAILS_SEAT_FLOOR_ANALYSIS:
        "/seats/:seatId/inventory/health/details/:drawerType/floorAnalysis",
    SEAT_INVENTORY_HEALTH_DETAILS_PAGE_PUBLISHER_FLOOR_ANALYSIS:
        "/seats/:seatId/inventory/health/details/page/:drawerType/:publisherId/floorAnalysis",
    SEAT_INVENTORY_HEALTH_DETAILS_PUBLISHER_FLOOR_ANALYSIS:
        "/seats/:seatId/inventory/health/details/:drawerType/:publisherId/floorAnalysis",
    SEAT_INVENTORY_HEALTH_DETAILS_PAGE_CHANNEL_FLOOR_ANALYSIS:
        "/seats/:seatId/inventory/health/details/page/modal/:drawerType/:publisherId/floorAnalysis",
    SEAT_INVENTORY_HEALTH_DETAILS_PAGE_BRAND_CHANNEL_FLOOR_ANALYSIS:
        "/seats/:seatId/inventory/health/details/page/modal/publisher/:publisherId/:drawerType/:brandId/floorAnalysis",
    SEAT_INVENTORY_HEALTH_DETAILS_PAGE_BRAND_FLOOR_ANALYSIS:
        "/seats/:seatId/inventory/health/details/page/publisher/:publisherId/:drawerType/:brandId/floorAnalysis",
    SEAT_INVENTORY_HEALTH_DETAILS_BRAND_FLOOR_ANALYSIS:
        "/seats/:seatId/inventory/health/details/publisher/:publisherId/:drawerType/:brandId/floorAnalysis",
    SEAT_INVENTORY_HEALTH_DETAILS_PAGE_SUPPLY_FLOOR_ANALYSIS:
        "/seats/:seatId/inventory/health/details/page/publisher/:publisherId/brand/:brandId/:drawerType/:supplyId/floorAnalysis",
    SEAT_INVENTORY_HEALTH_DETAILS_SUPPLY_FLOOR_ANALYSIS:
        "/seats/:seatId/inventory/health/details/publisher/:publisherId/brand/:brandId/:drawerType/:supplyId/floorAnalysis",
    SEAT_INVENTORY_HEALTH_DETAILS_PAGE_AD_UNIT_FLOOR_ANALYSIS:
        "/seats/:seatId/inventory/health/details/page/publisher/:publisherId/brand/:brandId/supply/:supplyId/:drawerType/:adUnitId/floorAnalysis",
    SEAT_INVENTORY_HEALTH_DETAILS_AD_UNIT_FLOOR_ANALYSIS:
        "/seats/:seatId/inventory/health/details/publisher/:publisherId/brand/:brandId/supply/:supplyId/:drawerType/:adUnitId/floorAnalysis",

    //Unit AdsTxt
    SEAT_INVENTORY_HEALTH_DETAILS_SEAT_ADS_TXT: "/seats/:seatId/inventory/health/details/:drawerType/adsTxt",
    SEAT_INVENTORY_HEALTH_DETAILS_PAGE_PUBLISHER_ADS_TXT:
        "/seats/:seatId/inventory/health/details/page/:drawerType/:publisherId/adsTxt",
    SEAT_INVENTORY_HEALTH_DETAILS_PUBLISHER_ADS_TXT:
        "/seats/:seatId/inventory/health/details/:drawerType/:publisherId/adsTxt",
    SEAT_INVENTORY_HEALTH_DETAILS_BRAND_ADS_TXT:
        "/seats/:seatId/inventory/health/details/publisher/:publisherId/:drawerType/:brandId/adsTxt",
    SEAT_INVENTORY_HEALTH_DETAILS_PAGE_BRAND_ADS_TXT:
        "/seats/:seatId/inventory/health/details/page/publisher/:publisherId/:drawerType/:brandId/adsTxt",
    SEAT_INVENTORY_HEALTH_DETAILS_PAGE_CHANNEL_ADS_TXT:
        "/seats/:seatId/inventory/health/details/page/modal/:drawerType/:publisherId/adsTxt",
    SEAT_INVENTORY_HEALTH_DETAILS_CHANNEL_ADS_TXT:
        "/seats/:seatId/inventory/health/details/:drawerType/:publisherId/adsTxt",
    //Unit Creative Review
    SEAT_INVENTORY_HEALTH_DETAILS_SEAT_CREATIVE_REVIEW:
        "/seats/:seatId/inventory/health/details/:drawerType/creativeReview",
    SEAT_INVENTORY_HEALTH_DETAILS_PAGE_PUBLISHER_CREATIVE_REVIEW:
        "/seats/:seatId/inventory/health/details/page/:drawerType/:publisherId/creativeReview",
    SEAT_INVENTORY_HEALTH_DETAILS_PUBLISHER_CREATIVE_REVIEW:
        "/seats/:seatId/inventory/health/details/:drawerType/:publisherId/creativeReview",
    SEAT_INVENTORY_HEALTH_DETAILS_PAGE_CHANNEL_CREATIVE_REVIEW:
        "/seats/:seatId/inventory/health/details/page/modal/:drawerType/:publisherId/creativeReview",
    SEAT_INVENTORY_HEALTH_DETAILS_PAGE_BRAND_CHANNEL_CREATIVE_REVIEW:
        "/seats/:seatId/inventory/health/details/page/modal/publisher/:publisherId/:drawerType/:brandId/creativeReview",
    SEAT_INVENTORY_HEALTH_DETAILS_PAGE_BRAND_CREATIVE_REVIEW:
        "/seats/:seatId/inventory/health/details/page/publisher/:publisherId/:drawerType/:brandId/creativeReview",
    SEAT_INVENTORY_HEALTH_DETAILS_BRAND_CREATIVE_REVIEW:
        "/seats/:seatId/inventory/health/details/publisher/:publisherId/:drawerType/:brandId/creativeReview",
    SEAT_INVENTORY_HEALTH_DETAILS_PAGE_SUPPLY_CREATIVE_REVIEW:
        "/seats/:seatId/inventory/health/details/page/publisher/:publisherId/brand/:brandId/:drawerType/:supplyId/creativeReview",
    SEAT_INVENTORY_HEALTH_DETAILS_SUPPLY_CREATIVE_REVIEW:
        "/seats/:seatId/inventory/health/details/publisher/:publisherId/brand/:brandId/:drawerType/:supplyId/creativeReview",
    SEAT_INVENTORY_HEALTH_DETAILS_PAGE_AD_UNIT_CREATIVE_REVIEW:
        "/seats/:seatId/inventory/health/details/page/publisher/:publisherId/brand/:brandId/supply/:supplyId/:drawerType/:adUnitId/creativeReview",
    SEAT_INVENTORY_HEALTH_DETAILS_AD_UNIT_CREATIVE_REVIEW:
        "/seats/:seatId/inventory/health/details/publisher/:publisherId/brand/:brandId/supply/:supplyId/:drawerType/:adUnitId/creativeReview",

    //Unit Change History
    SEAT_INVENTORY_HEALTH_DETAILS_PAGE_SEAT_CHANGE_HISTORY: "/seats/:seatId/inventory/seat/details/changeHistory",
    SEAT_INVENTORY_HEALTH_DETAILS_SEAT_CHANGE_HISTORY:
        "/seats/:seatId/inventory/health/details/:drawerType/changeHistory",
    SEAT_INVENTORY_HEALTH_DETAILS_PAGE_PUBLISHER_CHANGE_HISTORY:
        "/seats/:seatId/inventory/health/details/page/:drawerType/:publisherId/changeHistory",
    SEAT_INVENTORY_HEALTH_DETAILS_PUBLISHER_CHANGE_HISTORY:
        "/seats/:seatId/inventory/health/details/:drawerType/:publisherId/changeHistory",
    SEAT_INVENTORY_HEALTH_DETAILS_PAGE_CHANNEL_CHANGE_HISTORY:
        "/seats/:seatId/inventory/health/details/page/modal/:drawerType/:publisherId/changeHistory",
    SEAT_INVENTORY_HEALTH_DETAILS_CHANNEL_CHANGE_HISTORY:
        "/seats/:seatId/inventory/health/details/modal/:drawerType/:publisherId/changeHistory",
    SEAT_INVENTORY_HEALTH_DETAILS_PAGE_BRAND_CHANGE_HISTORY:
        "/seats/:seatId/inventory/health/details/page/publisher/:publisherId/:drawerType/:brandId/changeHistory",
    SEAT_INVENTORY_HEALTH_DETAILS_BRAND_CHANGE_HISTORY:
        "/seats/:seatId/inventory/health/details/publisher/:publisherId/:drawerType/:brandId/changeHistory",
    SEAT_INVENTORY_HEALTH_DETAILS_PAGE_SUPPLY_CHANGE_HISTORY:
        "/seats/:seatId/inventory/health/details/page/publisher/:publisherId/brand/:brandId/:drawerType/:supplyId/changeHistory",
    SEAT_INVENTORY_HEALTH_DETAILS_SUPPLY_CHANGE_HISTORY:
        "/seats/:seatId/inventory/health/details/publisher/:publisherId/brand/:brandId/:drawerType/:supplyId/changeHistory",
    SEAT_INVENTORY_HEALTH_DETAILS_PAGE_AD_UNIT_CHANGE_HISTORY:
        "/seats/:seatId/inventory/health/details/page/publisher/:publisherId/brand/:brandId/supply/:supplyId/:drawerType/:adUnitId/changeHistory",
    SEAT_INVENTORY_HEALTH_DETAILS_AD_UNIT_CHANGE_HISTORY:
        "/seats/:seatId/inventory/health/details/publisher/:publisherId/brand/:brandId/supply/:supplyId/:drawerType/:adUnitId/changeHistory",
    SEAT_INVENTORY_HEALTH_DETAILS_PAGE_BRAND_CHANNEL_CHANGE_HISTORY:
        "/seats/:seatId/inventory/health/details/page/modal/publisher/:publisherId/:drawerType/:brandId/changeHistory",
    SEAT_INVENTORY_HEALTH_DETAILS_BRAND_CHANNEL_CHANGE_HISTORY:
        "/seats/:seatId/inventory/health/details/publisher/:publisherId/:drawerType/:brandId/changeHistory",

    //Unit Creative Review Definition
    SEAT_INVENTORY_HEALTH_DETAILS_SEAT_CREATIVE_REVIEW_DEFINITION:
        "/seats/:seatId/inventory/health/details/:drawerType/creativeReview/definition",
    SEAT_INVENTORY_HEALTH_DETAILS_PAGE_PUBLISHER_CREATIVE_REVIEW_DEFINITION:
        "/seats/:seatId/inventory/health/details/page/:drawerType/:publisherId/creativeReview/definition",
    SEAT_INVENTORY_HEALTH_DETAILS_PUBLISHER_CREATIVE_REVIEW_DEFINITION:
        "/seats/:seatId/inventory/health/details/:drawerType/:publisherId/creativeReview/definition",
    SEAT_INVENTORY_HEALTH_DETAILS_PAGE_CHANNEL_CREATIVE_REVIEW_DEFINITION:
        "/seats/:seatId/inventory/health/details/page/modal/:drawerType/:publisherId/creativeReview/definition",
    SEAT_INVENTORY_HEALTH_DETAILS_PAGE_BRAND_CHANNEL_CREATIVE_REVIEW_DEFINITION:
        "/seats/:seatId/inventory/health/details/page/modal/publisher/:publisherId/:drawerType/:brandId/creativeReview/definition",
    SEAT_INVENTORY_HEALTH_DETAILS_PAGE_BRAND_CREATIVE_REVIEW_DEFINITION:
        "/seats/:seatId/inventory/health/details/page/publisher/:publisherId/:drawerType/:brandId/creativeReview/definition",
    SEAT_INVENTORY_HEALTH_DETAILS_BRAND_CREATIVE_REVIEW_DEFINITION:
        "/seats/:seatId/inventory/health/details/publisher/:publisherId/:drawerType/:brandId/creativeReview/definition",
    SEAT_INVENTORY_HEALTH_DETAILS_PAGE_SUPPLY_CREATIVE_REVIEW_DEFINITION:
        "/seats/:seatId/inventory/health/details/page/publisher/:publisherId/brand/:brandId/:drawerType/:supplyId/creativeReview/definition",
    SEAT_INVENTORY_HEALTH_DETAILS_SUPPLY_CREATIVE_REVIEW_DEFINITION:
        "/seats/:seatId/inventory/health/details/publisher/:publisherId/brand/:brandId/:drawerType/:supplyId/creativeReview/definition",
    SEAT_INVENTORY_HEALTH_DETAILS_PAGE_AD_UNIT_CREATIVE_REVIEW_DEFINITION:
        "/seats/:seatId/inventory/health/details/page/publisher/:publisherId/brand/:brandId/supply/:supplyId/:drawerType/:adUnitId/creativeReview/definition",
    SEAT_INVENTORY_HEALTH_DETAILS_AD_UNIT_CREATIVE_REVIEW_DEFINITION:
        "/seats/:seatId/inventory/health/details/publisher/:publisherId/brand/:brandId/supply/:supplyId/:drawerType/:adUnitId/creativeReview/definition",

    //Unit snapshot
    SEAT_INVENTORY_HEALTH_DETAILS_PAGE_PUBLISHER_SNAPSHOT:
        "/seats/:seatId/inventory/health/details/page/:drawerType/:publisherId/snapshot",
    SEAT_INVENTORY_HEALTH_DETAILS_PUBLISHER_SNAPSHOT:
        "/seats/:seatId/inventory/health/details/:drawerType/:publisherId/snapshot",
    SEAT_INVENTORY_HEALTH_DETAILS_PAGE_CHANNEL_SNAPSHOT:
        "/seats/:seatId/inventory/health/details/page/modal/:drawerType/:publisherId/snapshot",
    SEAT_INVENTORY_HEALTH_DETAILS_PAGE_BRAND_CHANNEL_SNAPSHOT:
        "/seats/:seatId/inventory/health/details/page/modal/publisher/:publisherId/:drawerType/:brandId/snapshot",
    SEAT_INVENTORY_HEALTH_DETAILS_PAGE_BRAND_SNAPSHOT:
        "/seats/:seatId/inventory/health/details/page/publisher/:publisherId/:drawerType/:brandId/snapshot",
    SEAT_INVENTORY_HEALTH_DETAILS_BRAND_SNAPSHOT:
        "/seats/:seatId/inventory/health/details/publisher/:publisherId/:drawerType/:brandId/snapshot",
    SEAT_INVENTORY_HEALTH_DETAILS_PAGE_SUPPLY_SNAPSHOT:
        "/seats/:seatId/inventory/health/details/page/publisher/:publisherId/brand/:brandId/:drawerType/:supplyId/snapshot",
    SEAT_INVENTORY_HEALTH_DETAILS_SUPPLY_SNAPSHOT:
        "/seats/:seatId/inventory/health/details/publisher/:publisherId/brand/:brandId/:drawerType/:supplyId/snapshot",
    SEAT_INVENTORY_HEALTH_DETAILS_PAGE_AD_UNIT_SNAPSHOT:
        "/seats/:seatId/inventory/health/details/page/publisher/:publisherId/brand/:brandId/supply/:supplyId/:drawerType/:adUnitId/snapshot",
    SEAT_INVENTORY_HEALTH_DETAILS_AD_UNIT_SNAPSHOT:
        "/seats/:seatId/inventory/health/details/publisher/:publisherId/brand/:brandId/supply/:supplyId/:drawerType/:adUnitId/snapshot",

    //Unit ladle
    SEAT_INVENTORY_HEALTH_DETAILS_PAGE_CHANNEL_LADLE:
        "/seats/:seatId/inventory/health/details/page/:drawerType/:publisherId/ladle",
    SEAT_INVENTORY_HEALTH_DETAILS_CHANNEL_LADLE:
        "/seats/:seatId/inventory/health/details/:drawerType/:publisherId/ladle",
    SEAT_INVENTORY_HEALTH_DETAILS_PAGE_BRAND_CHANNEL_LADLE:
        "/seats/:seatId/inventory/health/details/page/modal/publisher/:publisherId/:drawerType/:brandId/ladle",
    SEAT_INVENTORY_HEALTH_DETAILS_BRAND_CHANNEL_LADLE:
        "/seats/:seatId/inventory/health/details/publisher/:publisherId/:drawerType/:brandId/ladle",
    SEAT_INVENTORY_HEALTH_DETAILS_PAGE_AD_UNIT_LADLE:
        "/seats/:seatId/inventory/health/details/page/publisher/:publisherId/brand/:brandId/supply/:supplyId/:drawerType/:adUnitId/ladle",
    SEAT_INVENTORY_HEALTH_DETAILS_AD_UNIT_LADLE:
        "/seats/:seatId/inventory/health/details/publisher/:publisherId/brand/:brandId/supply/:supplyId/:drawerType/:adUnitId/ladle",

    //Unit ladle definition
    SEAT_INVENTORY_HEALTH_DETAILS_PAGE_CHANNEL_LADLE_DEFINITION:
        "/seats/:seatId/inventory/health/details/page/:drawerType/:publisherId/ladle/definition",
    SEAT_INVENTORY_HEALTH_DETAILS_CHANNEL_LADLE_DEFINITION:
        "/seats/:seatId/inventory/health/details/:drawerType/:publisherId/ladle/definition",
    SEAT_INVENTORY_HEALTH_DETAILS_PAGE_BRAND_CHANNEL_LADLE_DEFINITION:
        "/seats/:seatId/inventory/health/details/page/publisher/:publisherId/:drawerType/:brandId/ladle/definition",
    SEAT_INVENTORY_HEALTH_DETAILS_BRAND_CHANNEL_LADLE_DEFINITION:
        "/seats/:seatId/inventory/health/details/publisher/:publisherId/:drawerType/:brandId/ladle/definition",
    SEAT_INVENTORY_HEALTH_DETAILS_PAGE_AD_UNIT_LADLE_DEFINITION:
        "/seats/:seatId/inventory/health/details/page/publisher/:publisherId/brand/:brandId/supply/:supplyId/:drawerType/:adUnitId/ladle/definition",
    SEAT_INVENTORY_HEALTH_DETAILS_AD_UNIT_LADLE_DEFINITION:
        "/seats/:seatId/inventory/health/details/publisher/:publisherId/brand/:brandId/supply/:supplyId/:drawerType/:adUnitId/ladle/definition",

    SEAT_INVENTORY_DETAILS: "/seats/:seatId/inventory/details",
    SEAT_INVENTORY_DETAILS_PUBLISHER_CREATE: "/seats/:seatId/inventory/health/details/publisher/create",
    SEAT_INVENTORY_DETAILS_PUBLISHER_COPY: "/seats/:seatId/inventory/health/details/publisher/:publisherId/copy",
    SEAT_INVENTORY_DETAILS_PUBLISHER_EDIT: "/seats/:seatId/inventory/health/details/publisher/:publisherId/edit",
    SEAT_INVENTORY_DETAILS_PUBLISHER_CHANNEL_CREATE: "/seats/:seatId/inventory/health/details/channel/create",
    SEAT_INVENTORY_DETAILS_PUBLISHER_CHANNEL_EDIT: "/seats/:seatId/inventory/health/details/channel/:channelId/edit",
    SEAT_INVENTORY_DETAILS_PUBLISHER_BRANDS: "/seats/:seatId/inventory/details/publishers/:publisherId/brands",
    SEAT_INVENTORY_DETAILS_PUBLISHER_CHANNEL_BRANDS: "/seats/:seatId/inventory/details/channels/:channelId/brands",
    SEAT_INVENTORY_DETAILS_PUBLISHER_BRAND_CHANNEL_CREATE:
        "/seats/:seatId/inventory/health/details/publisher/:publisherId/brand-channel/create",
    SEAT_INVENTORY_DETAILS_PUBLISHER_BRAND_CHANNEL_EDIT:
        "/seats/:seatId/inventory/health/details/publisher/:publisherId/brand-channel/:brandId/edit",
    SEAT_INVENTORY_DETAILS_PUBLISHER_BRAND_CREATE:
        "/seats/:seatId/inventory/health/details/publisher/:publisherId/brand/create",
    SEAT_INVENTORY_DETAILS_PUBLISHER_BRAND_COPY:
        "/seats/:seatId/inventory/health/details/publisher/:publisherId/brand/:brandId/copy",
    SEAT_INVENTORY_DETAILS_PUBLISHER_BRAND_EDIT:
        "/seats/:seatId/inventory/health/details/publisher/:publisherId/brand/:brandId/edit",
    SEAT_INVENTORY_DETAILS_PUBLISHER_BRAND_SUPPLY_CREATE:
        "/seats/:seatId/inventory/health/details/publisher/:publisherId/brand/:brandId/supply/create",
    SEAT_INVENTORY_DETAILS_PUBLISHER_BRAND_SUPPLY_COPY:
        "/seats/:seatId/inventory/health/details/publisher/:publisherId/brand/:brandId/supply/:supplyId/copy",
    SEAT_INVENTORY_DETAILS_PUBLISHER_BRAND_SUPPLY_EDIT:
        "/seats/:seatId/inventory/health/details/publisher/:publisherId/brand/:brandId/supply/:supplyId/edit",
    SEAT_INVENTORY_DETAILS_PUBLISHER_BRAND_SUPPLY:
        "/seats/:seatId/inventory/details/publishers/:publisherId/brands/:brandId/supply",
    SEAT_INVENTORY_DETAILS_PUBLISHER_BRAND_SUPPLY_DETAILS:
        "/seats/:seatId/inventory/details/publishers/:publisherId/brands/:brandId/supply/:supplyId",
    SEAT_INVENTORY_DETAILS_PUBLISHER_BRAND_SUPPLY_AD_UNIT_DETAILS:
        "/seats/:seatId/inventory/details/publishers/:publisherId/brands/:brandId/supply/:supplyId/adUnit/:adUnit",
    SEAT_INVENTORY_DETAILS_PUBLISHER_BRAND_SUPPLY_AD_UNIT_CREATE:
        "/seats/:seatId/inventory/health/publishers/:publisherId/brands/:brandId/supply/:supplyId/adUnit/create",
    SEAT_INVENTORY_DETAILS_PUBLISHER_BRAND_SUPPLY_AD_UNIT_COPY:
        "/seats/:seatId/inventory/health/publishers/:publisherId/brands/:brandId/supply/:supplyId/adUnit/:adUnitId/copy",
    SEAT_INVENTORY_DETAILS_PUBLISHER_BRAND_SUPPLY_AD_UNIT_EDIT:
        "/seats/:seatId/inventory/health/publishers/:publisherId/brands/:brandId/supply/:supplyId/adUnit/:adUnitId/edit",
    SEAT_INVENTORY_SEAT: "/seats/:seatId/inventory/seat/:tab",
    SEAT_INVENTORY_SEAT_DETAILS: "/seats/:seatId/inventory/seat/details",
    SEAT_INVENTORY_SEAT_FLOORS: "/seats/:seatId/inventory/seat/floors",
    SEAT_INVENTORY_SEAT_PRICES: "/seats/:seatId/inventory/seat/prices",
    SEAT_INVENTORY_SEAT_PROTECTIONS: "/seats/:seatId/inventory/seat/protections",
    SEAT_INVENTORY_SEAT_ADVANCED: "/seats/:seatId/inventory/seat/advanced",
    SEAT_INVENTORY_SEAT_INTERNAL: "/seats/:seatId/inventory/seat/internal",
    SEAT_INVENTORY_SEAT_INTERNAL_DEFINITION: "/seats/:seatId/inventory/seat/internal/definition",
    SEAT_INVENTORY_SEAT_EDIT: "/seats/:seatId/inventory/seat/:tab/edit",
    SEAT_INVENTORY_SEAT_EDIT_DETAILS: "/seats/:seatId/inventory/seat/details/edit",
    SEAT_INVENTORY_SEAT_EDIT_FLOORS: "/seats/:seatId/inventory/seat/floors/edit",
    SEAT_INVENTORY_SEAT_EDIT_PRICES: "/seats/:seatId/inventory/seat/prices/edit",
    SEAT_INVENTORY_SEAT_EDIT_PROTECTIONS: "/seats/:seatId/inventory/seat/protections/edit",
    SEAT_INVENTORY_SEAT_EDIT_ADVANCED: "/seats/:seatId/inventory/seat/advanced/edit",
    SEAT_INVENTORY_SEAT_EDIT_INTERNAL: "/seats/:seatId/inventory/seat/internal/edit",
    SEAT_INVENTORY_PERMISSIONS: "/seats/:seatId/inventory/permissions",
    SEAT_INVENTORY_FLOOR: "/seats/:seatId/inventory/floors/:tab",
    SEAT_INVENTORY_FLOOR_HIERARCHICAL: "/seats/:seatId/inventory/floors/hierarchical",
    SEAT_INVENTORY_FLOOR_HIERARCHICAL_CREATE: "/seats/:seatId/inventory/floors/hierarchical/create",
    SEAT_INVENTORY_FLOOR_HIERARCHICAL_PUBLISHER_FLOOR_CREATE:
        "/seats/:seatId/inventory/floors/hierarchical/publishers/:publisherId/floors/create",
    SEAT_INVENTORY_FLOOR_HIERARCHICAL_BRAND_FLOOR_CREATE:
        "/seats/:seatId/inventory/floors/hierarchical/brands/:brandId/floors/create",
    SEAT_INVENTORY_FLOOR_HIERARCHICAL_SUPPLY_FLOOR_CREATE:
        "/seats/:seatId/inventory/floors/hierarchical/supply/:supplyId/floors/create",
    SEAT_INVENTORY_FLOOR_HIERARCHICAL_AD_UNIT_FLOOR_CREATE:
        "/seats/:seatId/inventory/floors/hierarchical/adUnit/:adUnitId/floors/create",
    SEAT_INVENTORY_FLOOR_HIERARCHICAL_EDIT: "/seats/:seatId/inventory/floors/hierarchical/:floorId/edit",
    SEAT_INVENTORY_FLOOR_HIERARCHICAL_PUBLISHER_FLOOR_EDIT:
        "/seats/:seatId/inventory/floors/hierarchical/publishers/:publisherId/floors/:floorId/edit",
    SEAT_INVENTORY_FLOOR_HIERARCHICAL_BRAND_FLOOR_EDIT:
        "/seats/:seatId/inventory/floors/hierarchical/publishers/:publisherId/brands/:brandId/floors/:floorId/edit",
    SEAT_INVENTORY_FLOOR_ADVANCED: "/seats/:seatId/inventory/floors/advanced",
    SEAT_INVENTORY_FLOOR_ADVANCED_DETAILS: "/seats/:seatId/inventory/floors/advanced/details/:id",
    SEAT_INVENTORY_FLOOR_ADVANCED_DEFINITION: "/seats/:seatId/inventory/floors/advanced/definition/:key",
    SEAT_INVENTORY_FLOOR_ADVANCED_CREATE: "/seats/:seatId/inventory/floors/advanced/create",
    SEAT_INVENTORY_FLOOR_ADVANCED_EDIT: "/seats/:seatId/inventory/floors/advanced/:id/edit",
    SEAT_INVENTORY_FLOOR_ADVANCED_EDIT_DEFINITION: "/seats/:seatId/inventory/floors/advanced/:id/edit/definition/:key",
    SEAT_INVENTORY_FLOOR_PUBLISHER: "/seats/:seatId/inventory/floors/hierarchical/publishers/:publisherId/floors",
    SEAT_INVENTORY_FLOOR_PUBLISHER_BRAND: "/seats/:seatId/inventory/floors/hierarchical/brands/:brandId/floors",
    SEAT_INVENTORY_SUPPLY_FLOORS: "/seats/:seatId/inventory/floors/hierarchical/supply/:supplyId/floors",
    SEAT_INVENTORY_AD_UNITS_FLOORS: "/seats/:seatId/inventory/floors/hierarchical/adUnit/:adUnitId/floors",
    SEAT_INVENTORY_BRAND_SAFETY: "/seats/:seatId/inventory/brand-safety/:tab",
    SEAT_INVENTORY_BRAND_SAFETY_HIERARCHICAL: "/seats/:seatId/inventory/brand-safety/hierarchical",
    SEAT_INVENTORY_BRAND_SAFETY_HIERARCHICAL_PUBLISHER:
        "/seats/:seatId/inventory/brand-safety/hierarchical/publisher/:publisherId",
    SEAT_INVENTORY_BRAND_SAFETY_HIERARCHICAL_BRAND: "/seats/:seatId/inventory/brand-safety/hierarchical/brand/:brandId",
    SEAT_INVENTORY_BRAND_SAFETY_HIERARCHICAL_SUPPLY:
        "/seats/:seatId/inventory/brand-safety/hierarchical/supply/:supplyId",
    SEAT_INVENTORY_BRAND_SAFETY_HIERARCHICAL_AD_UNIT:
        "/seats/:seatId/inventory/brand-safety/hierarchical/adUnit/:adUnitId",
    SEAT_INVENTORY_BRAND_SAFETY_ADVANCED: "/seats/:seatId/inventory/brand-safety/advanced",
    SEAT_INVENTORY_BRAND_SAFETY_ADVANCED_DETAILS: "/seats/:seatId/inventory/brand-safety/advanced/details/:id",
    SEAT_INVENTORY_BRAND_SAFETY_ADVANCED_DEFINITION: "/seats/:seatId/inventory/brand-safety/advanced/definition/:key",
    SEAT_INVENTORY_BRAND_SAFETY_ADVANCED_CREATE: "/seats/:seatId/inventory/brand-safety/advanced/create",
    SEAT_INVENTORY_BRAND_SAFETY_ADVANCED_EDIT: "/seats/:seatId/inventory/brand-safety/advanced/edit/:id",
    SEAT_LADLE_DIAGNOSTICS: "/seats/:seatId/ladle-diagnostics",
    SEAT_REPORTS: "/seats/:seatId/reports",
    SEAT_REPORTS_SAVED_RESULTS: "/seats/:seatId/reports/report/:reportId/results/code/:executionCode",
    SEAT_REPORTS_ADHOC_RESULTS: "/seats/:seatId/reports/results/code/:executionCode",
    SEAT_REPORTS_CONSOLE: "/seats/:seatId/reports-console",
    SEAT_REPORTS_CONSOLE_RESULTS: "/seats/:seatId/reports-console/custom/results/code/:executionCode",
    SEAT_REPORTS_CONSOLE_TABS: "/seats/:seatId/reports-console/:tab",
    SEAT_REPORTS_CONSOLE_CUSTOM_REPORTS: "/seats/:seatId/reports-console/custom",
    SEAT_REPORTS_CONSOLE_CUSTOM_REPORTS_TABS: "/seats/:seatId/reports-console/custom/:tab",
    SEAT_REPORTS_CONSOLE_CUSTOM_REPORTS_RECENT_ACTIVITIES: "/seats/:seatId/reports-console/custom/recent-activities",
    SEAT_REPORTS_CONSOLE_CUSTOM_REPORTS_MY_REPORTS: "/seats/:seatId/reports-console/custom/my-reports",
    SEAT_REPORTS_CONSOLE_CUSTOM_REPORTS_SHARED_WITH_YOU: "/seats/:seatId/reports-console/custom/shared-with-you",
    SEAT_REPORTS_CONSOLE_CUSTOM_REPORTS_NEW_REPORT: "/seats/:seatId/reports-console/custom/new-report",
    SEAT_REPORTS_CONSOLE_FORECASTING: "/seats/:seatId/reports-console/forecasting",
    SEAT_REPORTS_CONSOLE_ADVERTISER_REPORT: "/seats/:seatId/reports-console/advertiser-report",
    SEAT_USER_PREFERENCES_LEGACY: "/seats/:seatId/user-preferences-legacy",
    SEAT_USER_PREFERENCES: "/seats/:seatId/user-preferences",
    SEAT_USER_ADMIN: "/seats/:seatId/admin",
    SEAT_DEAL_LIBRARY: "/seats/:seatId/deal-library",
    WILDCARD: "*",
};
