import { BLOCKED_INDUSTRIES_LABEL, BLOCKED_INDUSTRIES_NAME } from "@app/core/components";
import {
    LABEL_VALUES,
    INTERNAL_LABEL_VALUES,
    PREFILTER_LABEL_VALUES,
    DISTRIBUTION_GROUP_LABEL_VALUES,
    FREQUENCY_CAPPS,
    AD_BREAK_RULES,
    AD_BREAK_RULES_MID,
    AD_BREAK_RULES_PRE,
    AD_BREAK_RULES_POST,
    AFFILIATE_COST_MODEL,
    AFFILIATE_COST_VALUE_PERCENT,
    AFFILIATE_COST_VALUE_FIXED,
} from "@app/features/inventory/constants";

export const CODE_TYPE = "codeType";
export const SUPPORTED_APIS = "supportedApis";
export const MIMES = "mimes";
export const AD_UNIT_TYPE = "type";
export const ADOMAIN_FREQ_CAPPING_TYPE = "adomainFreqCappingType";
export const SSAI_TYPE = "ssaiType";
export const PLACEMENT = "placement";
export const MAX_DURATION = "maxDuration";
export const MAX_POD_SECONDS = "maxPodSeconds";
export const MAX_ADS_PER_POD = "maxAdsPerPod";
export const LSA_WINDOW_SIZE_SECONDS = "lsaWindowSizeSeconds";
export const IMPRESSION_WAIT_TIME = "impressionWaitTime";
export const MIN_DURATION = "minDuration";
export const MIN_BITRATE = "minBitrate";
export const MAX_BITRATE = "maxBitrate";
export const MAX_EXTENDED = "maxExtended";
export const ADDITIONAL_TIME = "additionalTime";
export const AD_POD_FILL_MODE = "adPodFillMode";
export const POD_ENFORCEMENT = "podEnforcement";
export const COMPETITIVE_SEPARATION_MODE = "competitiveSeparationMode";
export const SEPARATION_GROUPS = "separationGroups";
export const MAX_ADS_PER_ADV = "maxAdsPerAdvertiser";
export const PLAYLIST_DEFINITION_MODE = "playlistDefinitionMode";
export const SUPPORTED_PROTOCOLS = "supportedProtocols";
export const MULTIPLICITY = "multiplicity";
export const LINEARITY = "linearity";
export const CUSTOM_PIXELS_EMPTY_NOTIFICATION = "There are no custom pixels at Parent levels";
export const BLOCKED_DOMAINS_EMPTY_NOTIFICATION = "There are no domains blocked at Parent levels";
export const MAX_NAME_LENGTH = 200;
export const MAX_DESCRIPTION_LENGTH = 1024;

export const MAX_REVSHARE_PERCENTAGE_COST_VALUE = 999;
// to prevent mysql error based on the column type
export const MAX_FIXED_CPM_VALUE = 9223372036854774n;

export const PUBLISHER_FORM_FIELDS = {
    // General Section
    NAME: {
        name: "name",
        label: "Name",
        rulesMessage: {
            required: "Name is required",
            maxLength: `Name cannot be longer than ${MAX_NAME_LENGTH} characters`,
        },
        placeholder: "Enter Name",
    },
    CODE_TYPE: {
        name: CODE_TYPE,
        label: "Code Type",
    },
    CODE: {
        name: "code",
        label: "Code",
        rulesMessage: "Code is required",
        placeholder: "Enter Code",
    },
    DESCRIPTION: {
        name: "description",
        label: "Description",
        placeholder: "Enter Description",
        rulesMessage: {
            maxLength: `Description cannot be longer than ${MAX_DESCRIPTION_LENGTH} characters`,
        },
    },
    EXTERNAL_COMMENT: {
        name: "externalComment",
        label: "External Comment",
        placeholder: "Enter External Comment",
    },
    BUSINESS_DOMAIN: {
        name: "businessDomain",
        label: "Business Domain",
        placeholder: "Enter Business Domain",
    },
    ACCOUNT_TYPE: {
        name: "accountType",
        label: "Account Type",
        placeholder: "Not Specified",
    },
    PUBLISHER_NAME: {
        name: "publisherNameOverride",
        label: "Publisher Name",
        placeholder: "Enter Publisher Name",
    },
    OVERRIDE: {
        name: "override",
        label: "Override",
    },

    // Affiliate Section
    AFFILIATE_COST_MODEL: {
        name: AFFILIATE_COST_MODEL,
        label: "Affiliate Cost Model",
        placeholder: "Select Affiliate Cost Model…",
    },
    AFFILIATE_COST_VALUE_PERCENT: {
        name: AFFILIATE_COST_VALUE_PERCENT,
        label: "Affiliate Cost Value",
    },
    AFFILIATE_COST_VALUE_FIXED: {
        name: AFFILIATE_COST_VALUE_FIXED,
        label: "Affiliate Cost Value",
    },

    // Brand Safety Section
    BLOCK_ADVERTISER_DOMAIN_LISTS: {
        name: "seatAdvertiserDomainFilterListDefs",
        label: "Block Advertiser Domain Lists",
        placeholder: "Select Block Advertiser Domains…",
    },
    BLOCKED_ADVERTISER_DOMAINS: {
        name: "advertiserBlockDomains",
        label: "Blocked Advertiser Domains",
        placeholder: "Enter Blocked Advertiser Domains one per line",
    },
    BLOCK_BUYER_SEAT_LIST: {
        name: "buyerSeatList",
        label: "Block Buyer Seat List",
        placeholder: "Select Block Buyer Seat List…",
    },
    CREATIVE_BLOCK_MODE: {
        name: "creativeBlockingMode",
        label: "Creative Block Mode",
    },
    // Internal Section
    BILLING_CODE: {
        name: "billingCode",
        label: "Billing Code",
        placeholder: "Enter Billing Code",
    },
    LOWER_CALCULON_USE_RATE_OVERRIDE: {
        name: "calculonUseRateOverride",
        label: "Lower Calculon Use Rate Override",
        placeholder: "Enter a Value between 0.001 and 0.999",
        rulesMessage: {
            range: "Lower Calculon Use Rate Override value should be between .001 - .999",
            less: "Lower Calculon Use Rate Override value should be less than Upper Calculon Use Rate Override",
        },
    },
    UPPER_CALCULON_USE_RATE_OVERRIDE: {
        name: "upperCalculonUseRateOverride",
        label: "Upper Calculon Use Rate Override",
        placeholder: "Enter a Value between 0.001 and 0.999",
        rulesMessage: {
            range: "Upper Calculon Use Rate Override value should be between .001 - .999",
            greater: "Upper Calculon Use Rate Override value should be greater than Lower Calculon Use Rate Override",
        },
    },
    PUBLISHER_RE_AUCTION: {
        name: "publisherReauction",
        label: "Publisher Re-Auction",
    },
    // Advanced Features
    EMIT_PRICEING_IN_VAST: {
        name: "emitPricingInVast",
        label: "Emit Pricing in VAST",
    },
    ALLOW_VAST_EXTENSION_DSP: {
        name: "allowVastExtensionDSP",
        label: "Allow DSP VAST Extension",
    },
    ALLOW_VAST_EXTENSION_CRID: {
        name: "allowVastExtensionCRID",
        label: "Allow CRID VAST Extension",
    },
    ALLOW_VAST_EXTENSION_ADOMAIN: {
        name: "allowVastExtensionAdomain",
        label: "Allow Adomain VAST Extension",
    },
    ALLOW_VAST_EXTENSION_BRAND: {
        name: "allowVastExtensionBrand",
        label: "Allow Brand VAST Extension",
    },
    ALLOW_VAST_EXTENSION_BID_PRICE: {
        name: "allowVastExtensionBidPrice",
        label: "Allow Bid Price VAST Extension",
    },
    ALLOW_AD_SOURCE_VAST_EXTENSION: {
        name: "allowVastExtensionAdSourceType",
        label: "Allow Ad Source Type VAST Extension",
    },
    ALLOW_VAST_EXTENSION_IAB_CATEGORY: {
        name: "allowVastExtensionIabCategory",
        label: "Allow IAB Category VAST Extension",
    },
    ALLOW_AD_SOURCE_NAME_VAST_EXTENSION: {
        name: "allowVastExtensionAdSourceName",
        label: "Allow Ad Source Name VAST Extension",
    },
    // Custom Pixels
    CUSTOM_PIXELS: {
        name: "thirdPartyPixels",
        label: "Custom Pixels",
        placeholder: "Select Custom Pixels…",
    },
    // Labels
    LABELS: {
        name: LABEL_VALUES,
        label: "Seat Label",
    },
    INTERNAL_LABELS: {
        name: INTERNAL_LABEL_VALUES,
        label: "Global Label",
    },
} as const;

export const BRAND_FORM_FIELDS = {
    // General Section
    NAME: {
        name: "name",
        label: "Name",
        rulesMessage: {
            required: "Name is required",
            maxLength: `Name cannot be longer than ${MAX_NAME_LENGTH} characters`,
        },
        placeholder: "Enter Name",
    },
    CODE_TYPE: {
        name: CODE_TYPE,
        label: "Code Type",
    },
    CODE: {
        name: "code",
        label: "Code",
        rulesMessage: "Code is required",
        placeholder: "Enter Code",
    },
    DESCRIPTION: {
        name: "description",
        label: "Description",
        placeholder: "Enter Description",
        rulesMessage: {
            maxLength: `Description cannot be longer than ${MAX_DESCRIPTION_LENGTH} characters`,
        },
    },
    // Affiliate Section
    AFFILIATE_COST_MODEL: {
        name: AFFILIATE_COST_MODEL,
        label: "Affiliate Cost Model",
        placeholder: "Select Affiliate Cost Model…",
    },
    AFFILIATE_COST_VALUE_PERCENT: {
        name: AFFILIATE_COST_VALUE_PERCENT,
        label: "Affiliate Cost Value",
    },
    AFFILIATE_COST_VALUE_FIXED: {
        name: AFFILIATE_COST_VALUE_FIXED,
        label: "Affiliate Cost Value",
    },

    // Brand Safety Section
    BLOCK_ADVERTISER_DOMAIN_LISTS: {
        name: "seatAdvertiserDomainFilterListDefs",
        label: "Block Advertiser Domain Lists",
        placeholder: "Select Block Advertiser Domains…",
    },
    BLOCKED_ADVERTISER_DOMAINS: {
        name: "advertiserBlockDomains",
        label: "Blocked Advertiser Domains",
        placeholder: "Enter Blocked Advertiser Domains one per line",
    },
    BLOCK_BUYER_SEAT_LIST: {
        name: "buyerSeatList",
        label: "Block Buyer Seat List",
        placeholder: "Select Block Buyer Seat List…",
    },
    BLOCKED_CATEGORIES: {
        name: "blockedIabCategories",
        label: "Blocked Categories",
        placeholder: "Select Blocked Categories…",
    },
    BLOCKED_INDUSTRIES: {
        name: BLOCKED_INDUSTRIES_NAME,
        label: BLOCKED_INDUSTRIES_LABEL,
    },
    CREATIVE_BLOCK_MODE: {
        name: "creativeBlockingMode",
        label: "Creative Block Mode",
    },
    // Internal Section
    LOWER_CALCULON_USE_RATE_OVERRIDE: {
        name: "calculonUseRateOverride",
        label: "Lower Calculon Use Rate Override",
        placeholder: "Enter a Value between .001 and .999",
        rulesMessage: {
            range: "Lower Calculon Use Rate Override value should be between .001 - .999",
            less: "Lower Calculon Use Rate Override value should be less than Upper Calculon Use Rate Override",
        },
    },
    UPPER_CALCULON_USE_RATE_OVERRIDE: {
        name: "upperCalculonUseRateOverride",
        label: "Upper Calculon Use Rate Override",
        placeholder: "Enter a Value between .001 and .999",
        rulesMessage: {
            range: "Upper Calculon Use Rate Override value should be between .001 - .999",
            greater: "Upper Calculon Use Rate Override value should be greater than Lower Calculon Use Rate Override",
        },
    },
    PUBLISHER_RE_AUCTION: {
        name: "publisherReauction",
        label: "Publisher Re-Auction",
    },
    // Supply Details Section
    CATEGORIES: {
        name: "iabCategories",
        label: "Categories",
        placeholder: "Select Categories…",
    },
    ADVERTISERS_AS_WHITELIST: {
        name: "enableAdvertisersAsWhitelist",
        label: "Advertisers as Allow List",
        trueLabel: "Enabled",
        falseLabel: "Disabled",
    },
    // Custom Pixels
    CUSTOM_PIXELS: {
        name: "thirdPartyPixels",
        label: "Custom Pixels",
        placeholder: "Select Custom Pixels…",
    },
    // Labels
    LABELS: {
        name: LABEL_VALUES,
        label: "Seat Label",
    },
    INTERNAL_LABELS: {
        name: INTERNAL_LABEL_VALUES,
        label: "Global Label",
    },
    DISTRIBUTION_GROUP_LABELS: {
        name: DISTRIBUTION_GROUP_LABEL_VALUES,
        label: "Distribution Group Labels",
    },
} as const;

export const SUPPLY_FORM_FIELDS = {
    // General Section
    NAME: {
        name: "name",
        label: "Name",
        rulesMessage: {
            required: "Name is required",
            maxLength: `Name cannot be longer than ${MAX_NAME_LENGTH} characters`,
        },
        placeholder: "Enter Name",
    },
    CODE_TYPE: {
        name: CODE_TYPE,
        label: "Code Type",
    },
    CODE: {
        name: "code",
        label: "Code",
        rulesMessage: "Code is required",
        placeholder: "Enter Code",
    },
    DESCRIPTION: {
        name: "description",
        label: "Description",
        placeholder: "Enter Description",
        rulesMessage: {
            maxLength: `Description cannot be longer than ${MAX_DESCRIPTION_LENGTH} characters`,
        },
    },
    TYPE: {
        name: "type",
        label: "Type",
    },
    MODE: {
        name: "isDynamic",
        label: "Mode",
    },
    // Affiliate Section
    AFFILIATE_COST_MODEL: {
        name: AFFILIATE_COST_MODEL,
        label: "Affiliate Cost Model",
        placeholder: "Select Affiliate Cost Model…",
    },
    AFFILIATE_COST_VALUE_PERCENT: {
        name: AFFILIATE_COST_VALUE_PERCENT,
        label: "Affiliate Cost Value",
    },
    AFFILIATE_COST_VALUE_FIXED: {
        name: AFFILIATE_COST_VALUE_FIXED,
        label: "Affiliate Cost Value",
    },
    // Brand Safety Section
    BLOCK_ADVERTISER_DOMAIN_LISTS: {
        name: "seatAdvertiserDomainFilterListDefs",
        label: "Block Advertiser Domain Lists",
        placeholder: "Select Block Advertiser Domains…",
    },
    BLOCKED_ADVERTISER_DOMAINS: {
        name: "advertiserBlockDomains",
        label: "Blocked Advertiser Domains",
        placeholder: "Enter Blocked Advertiser Domains one per line",
    },
    BLOCK_BUYER_SEAT_LIST: {
        name: "buyerSeatList",
        label: "Block Buyer Seat List",
        placeholder: "Select Block Buyer Seat List…",
    },
    // Site Details Section
    EXTERNAL_NAME: {
        name: "supplyNameOverride",
        label: "External Name",
        placeholder: "Enter External Name",
        rulesMessage: "External Name is required",
    },
    OVERRIDE: {
        name: "override",
        label: "Override Supply Name",
    },
    // Used in App Details Section
    DOMAIN: {
        name: "domain",
        label: "Domain",
        placeholder: "Supply's domain or website",
    },
    INVENTORY_PARTNER_DOMAIN: {
        name: "inventoryPartnerDomain",
        label: "Inventory Partner Domain",
        placeholder: "Supply's inventory partner domain",
    },
    // App Details Section
    APP_STORE: {
        name: "appStore",
        label: "App Store",
        placeholder: "Select App Store…",
        rulesMessage: "App Store is required",
    },
    BUNDLE_ID: {
        name: "bundleId",
        label: "Bundle ID",
        placeholder: "Enter the iOS App Store Bundle ID; e.g. com.apple.myiOSapp",
        rulesMessage: "Bundle ID is required",
    },
    BUNDLE_ID_IS_OVERRIDE: {
        name: "bundleIdIsOverride",
        label: "Override Bundle ID",
    },
    APP_NAME: {
        name: "supplyNameOverride",
        label: "App Name",
        rulesMessage: "App Name is required",
        placeholder: "Enter App Name",
    },
    STORE_URL: {
        name: "storeUrl",
        label: "Store Url",
        rulesMessage: "Store Url is required",
        placeholder: "Enter Store Url",
    },
    PAID_TYPE: {
        name: "paidType",
        label: "Paid Type",
    },
    // Exchange Safety Section
    SUPPLY_DOMAIN_LISTS: {
        name: "supplySupplyDomainFilterLists",
        label: "Supply Domain Lists",
        placeholder: "Select Supply Domains…",
        // rulesMessage: "Supply Domain Lists is required",
    },
    APP_BUNDLE_ID_LISTS: {
        name: "supplyBundleIdFilterLists",
        label: "App Bundle ID Lists",
        placeholder: "Select App Bundle IDs…",
        // rulesMessage: "App Bundle ID Lists is required",
    },
    // Internal Section
    RESELL_TYPE: {
        name: "resellType",
        label: "Resell Type",
    },
    ENABLE_NO_AD_COOKIE_SYNC: {
        name: "enableNoAdCookieSync",
        label: "No-Ad Cookie Sync",
    },
    LOWER_CALCULON_USE_RATE_OVERRIDE: {
        name: "calculonUseRateOverride",
        label: "Lower Calculon Use Rate Override",
        placeholder: "Enter a Value between .001 and .999",
        rulesMessage: {
            range: "Lower Calculon Use Rate Override value should be between .001 - .999",
            less: "Lower Calculon Use Rate Override value should be less than Upper Calculon Use Rate Override",
        },
    },
    UPPER_CALCULON_USE_RATE_OVERRIDE: {
        name: "upperCalculonUseRateOverride",
        label: "Upper Calculon Use Rate Override",
        placeholder: "Enter a Value between .001 and .999",
        rulesMessage: {
            range: "Upper Calculon Use Rate Override value should be between .001 - .999",
            greater: "Upper Calculon Use Rate Override value should be greater than Lower Calculon Use Rate Override",
        },
    },
    PUBLISHER_RE_AUCTION: {
        name: "publisherReauction",
        label: "Publisher Re-Auction",
    },
    // Supply Details Section
    VIDEO_QUALITY: {
        name: "videoQuality",
        label: "Video Quality",
    },
    SOURCE_RELATIONSHIP: {
        name: "sourceRelationship",
        label: "Src Relationship",
    },
    IFA_MODE: {
        name: "ifaMode",
        label: "IFA Mode",
    },
    IFA_TYPE: {
        name: "ifaType",
        label: "IFA Type",
        placeholder: "Select IFA Type…",
    },
    ALLOW_VAST_WRAPPERS: {
        name: "allowVastWrappers",
        label: "Allow VAST Wrappers",
    },
    COPPA: {
        name: "isCoppa",
        label: "COPPA",
    },
    PRIVACY_POLICY: {
        name: "hasPrivacyPolicy",
        label: "Privacy Policy",
    },
    VIEWABILITY_VENDORS: {
        name: "viewabilityVendors",
        label: "Viewability Vendors",
        placeholder: "Select Viewability Vendors…",
    },
    KEYWORDS: {
        name: "keywords",
        label: "Keywords",
        placeholder: "Select Keywords",
    },
    MASK_IFA_FOR_DEMANDS: {
        name: "maskIFAForNetworks",
        label: "Mask IFA for Demands",
        placeholder: "Select Mask IFA for Demands…",
    },
    // Custom Pixels
    CUSTOM_PIXELS: {
        name: "thirdPartyPixels",
        label: "Custom Pixels",
        placeholder: "Select Custom Pixels…",
    },
    // Labels
    LABELS: {
        name: LABEL_VALUES,
        label: "Seat Label",
    },
    INTERNAL_LABELS: {
        name: INTERNAL_LABEL_VALUES,
        label: "Global Label",
    },
    // Waterfall Prefiltering
    PREFILTER_LABEL_VALUES: {
        name: PREFILTER_LABEL_VALUES,
        label: "Seat Prefilter Labels",
    },
} as const;

export const AD_UNIT_FORM_FIELDS = {
    //General section fields
    NAME: {
        name: "name",
        label: "Name",
        rulesMessage: {
            required: "Name is required",
            maxLength: `Name cannot be longer than ${MAX_NAME_LENGTH} characters`,
        },
        placeholder: "Enter Name",
    },
    CODE_TYPE: {
        name: CODE_TYPE,
        label: "Code Type",
    },
    CODE: {
        name: "code",
        label: "Code",
        rulesMessage: "Code is required",
        placeholder: "Enter Code",
    },
    DESCRIPTION: {
        name: "description",
        label: "Description",
        placeholder: "Enter Description",
        rulesMessage: {
            maxLength: `Description cannot be longer than ${MAX_DESCRIPTION_LENGTH} characters`,
        },
    },
    STATUS: {
        name: "status",
        label: "Status",
    },
    // Affiliate Section
    AFFILIATE_COST_MODEL: {
        name: AFFILIATE_COST_MODEL,
        label: "Affiliate Cost Model",
        placeholder: "Select Affiliate Cost Model…",
    },
    AFFILIATE_COST_VALUE_PERCENT: {
        name: AFFILIATE_COST_VALUE_PERCENT,
        label: "Affiliate Cost Value",
    },
    AFFILIATE_COST_VALUE_FIXED: {
        name: AFFILIATE_COST_VALUE_FIXED,
        label: "Affiliate Cost Value",
    },
    // Brand Safety Section fields
    BLOCK_ADVERTISER_DOMAIN_LIST: {
        name: "seatAdvertiserDomainFilterListDefs",
        label: "Block Advertiser Domain List",
        placeholder: "Select Block Advertiser Domain Lists…",
    },
    BLOCK_ADVERTISER_DOMAINS: {
        name: "advertiserBlockDomains",
        label: "Block Advertiser Domains",
        placeholder: "Enter Blocked Advertiser Domains…",
        help: "Enter one domain per line",
    },
    BLOCK_BUYER_SEAT_LIST: {
        name: "buyerSeatList",
        label: "Block Buyer Seat List",
        placeholder: "Select Block Buyer Seat List",
    },
    ADVERTISER_FREQUENCY_CAPPING_TYPE: {
        name: ADOMAIN_FREQ_CAPPING_TYPE,
        label: "Advertiser Frequency Capping",
    },
    ADV_DOMAIN_FREQ_CAPPS: {
        name: FREQUENCY_CAPPS,
        label: "Advertiser Domain Frequency Caps",
        placehoderTota: "Total",
        placehoderDuration: "Time Duration",
    },
    // Internal
    LOWER_CALCULON_USE_RATE_OVERRIDE: {
        name: "calculonUseRateOverride",
        label: "Lower Calculon Use Rate Override",
        placeholder: "Enter a Value between .001 and .999",
        rulesMessage: {
            range: "Lower Calculon Use Rate Override value should be between .001 - .999",
            less: "Lower Calculon Use Rate Override value should be less than Upper Calculon Use Rate Override",
        },
    },
    UPPER_CALCULON_USE_RATE_OVERRIDE: {
        name: "upperCalculonUseRateOverride",
        label: "Upper Calculon Use Rate Override",
        placeholder: "Enter a Value between .001 and .999",
        rulesMessage: {
            range: "Upper Calculon Use Rate Override value should be between .001 - .999",
            greater: "Upper Calculon Use Rate Override value should be greater than Lower Calculon Use Rate Override",
        },
    },
    PUBLISHER_RE_AUCTION: {
        name: "publisherReauction",
        label: "Publisher Re-Auction",
    },

    // Demand Requirements
    SUPPORTED_PROTOCOLS: {
        name: SUPPORTED_PROTOCOLS,
        label: "Supported Protocols",
        placeholder: "Select Supported Protocols…",
    },
    MIMES: {
        name: MIMES,
        label: "MIMEs",
        placeholder: "Select MIMEs…",
    },
    SUPPORTED_APIS: {
        name: SUPPORTED_APIS,
        label: "Supported APIs",
        placeholder: "Select Supported APIs…",
    },
    PLACEMENT: {
        name: PLACEMENT,
        label: "Placement",
    },
    MIN_DURATION: {
        name: MIN_DURATION,
        label: "Min Duration",
        placeholder: "Enter Min Duration in Seconds",
    },
    MAX_DURATION: {
        name: MAX_DURATION,
        label: "Max Duration",
        placeholder: "Enter Max Duration in Seconds",
    },
    MAX_EXTENDED: {
        name: MAX_EXTENDED,
        label: "Max Extended",
    },
    ADDITIONAL_TIME: {
        name: ADDITIONAL_TIME,
        label: "Additional Time in Seconds",
        placeholder: "Enter Additional Time in Seconds",
    },
    MIN_BITRATE: {
        name: MIN_BITRATE,
        label: "Min Bitrate (Kbps)",
        placeholder: "Enter Min Bitrate (Kbps)",
    },
    MAX_BITRATE: {
        name: MAX_BITRATE,
        label: "Max Bitrate (Kbps)",
        placeholder: "Enter Max Bitrate (Kbps)",
    },
    BLOCKED_ATTRIBUTES: {
        name: "blockedCreativeAttributes",
        label: "Blocked Attributes",
        placeholder: "Select Blocked Attributes…",
    },

    //Supply Details section fields
    LINEARITY: {
        name: LINEARITY,
        label: "Linearity",
    },
    MULTIPLICITY: {
        name: MULTIPLICITY,
        label: "Multiplicity",
    },
    TYPE: {
        name: AD_UNIT_TYPE,
        label: "Type",
    },
    START_DELAY: {
        name: "startDelay",
        label: "Start Delay in Seconds",
        placeholder: "Enter Start Delay in Seconds",
    },
    VIDEO_PLAYBACKS: {
        name: "allowedPlaybackMethods",
        label: "Video Playbacks",
        placeholder: "Select…",
    },
    DELIVERY_METHODS: {
        name: "videoDeliveries",
        label: "Delivery Methods",
        placeholder: "Select…",
    },
    SKIPPABLE: {
        name: "skip",
        label: "Skippable",
    },
    SKIPPABLE_AFTER: {
        name: "skipafter",
        label: "Skippable After",
        placeholder: "Enter Skippable After in Seconds",
    },
    SKIPPABLE_MINIMUM: {
        name: "skipmin",
        label: "Skippable Minimum",
        placeholder: "Enter Skippable Minimum in Seconds",
    },
    INTERSTITIAL: {
        name: "isInterstitial",
        label: "Interstitial",
    },
    BOXING: {
        name: "isBoxingAllowed",
        label: "Boxing",
    },
    SSAI_TYPE: {
        name: SSAI_TYPE,
        label: "SSAI Type",
        placeholder: "Select SSAI Type…",
    },
    EXTENDED_IMP_WAIT_TIME: {
        name: "impressionWaitTime",
        label: "Extended Imp Wait Time",
        placeholder: "Select Extended Imp Wait Time",
    },
    CREATIVE_SAMPLES_MINUTE: {
        name: "creativeSamplesMinute",
        label: "Creative Samples / Minute",
        placeholder: "Creative Samples / Minute",
    },
    UNWRAP_VAST: {
        name: "unwrapVast",
        label: "Unwrap VAST",
    },
    LIVE_STREAM: {
        name: "isLiveStream",
        label: "Live Stream",
    },
    CONTENT_CHANNEL: {
        name: "contentChannel",
        label: "Content Channel",
        placeholder: "Content Channel",
    },
    CONTENT_NETWORK: {
        name: "contentNetwork",
        label: "Content Network",
        placeholder: "Content Network",
    },
    UDE_OPT_OUT: {
        name: "udeOptOut",
        label: "UD Opt-Out",
    },
    ALLOW_DEMAND_UNDER_INBOUND_FLOOR: {
        name: "overrideDynamicFloor",
        label: "Allow Demand Under Inbound Floor",
    },
    LIVE_STREAM_ACCELERATION: {
        name: "lsaEnabled",
        label: "Live Stream Acceleration",
    },
    // Ad Pod Section
    MAX_POD_SECONDS: {
        name: MAX_POD_SECONDS,
        label: "Max Pod Seconds",
        placeholder: "Enter Max Pod in Seconds",
    },
    MAX_ADS_PER_POD: {
        name: MAX_ADS_PER_POD,
        label: "Max Ads Per Pod",
        placeholder: "Enter Max Ads Per Pod",
    },
    FILL_MODE: {
        name: AD_POD_FILL_MODE,
        label: "Fill Mode",
    },
    POD_ENFORCEMENT: {
        name: POD_ENFORCEMENT,
        label: "Pod Enforcement",
    },
    COMPETITIVE_SEPARATION_MODE: {
        name: COMPETITIVE_SEPARATION_MODE,
        label: "Competitive Separation Mode",
    },
    COMPETITIVE_SEPARATIO_GROUPS: {
        name: SEPARATION_GROUPS,
        label: "Competitive Separation Groups",
        placeholder: "Select Competitive Separation Groups...",
    },
    MAX_ADS_PER_ADV: {
        name: MAX_ADS_PER_ADV,
        label: "Max Ads per Advertiser",
        placeholder: "Enter Max Ads per Advertiser",
    },
    // Playlist Configuration Section
    PLAYLIST_DEFINITION_MODE: {
        name: PLAYLIST_DEFINITION_MODE,
        label: "Playlist Definition Mode",
    },
    // Ad Break Rules Section
    AD_BREAK_RULES: {
        name: AD_BREAK_RULES,
        labelName: "Name",
        labelIndex: "Index",
        labelAds: "# of Ads",
        labelDuration: "Duration",
        rulesMessage: "Field is required",
    },
    AD_BREAK_RULES_PRE: {
        name: AD_BREAK_RULES_PRE,
    },
    AD_BREAK_RULES_MID: {
        name: AD_BREAK_RULES_MID,
    },
    AD_BREAK_RULES_POST: {
        name: AD_BREAK_RULES_POST,
    },
    CUE_POINTS: {
        name: "cuepoints",
        label: "Cue points",
    },
    // Custom Pixels
    CUSTOM_PIXELS: {
        name: "thirdPartyPixels",
        label: "Custom Pixels",
        placeholder: "Select Custom Pixels…",
    },
    // Labels
    LABELS: {
        name: LABEL_VALUES,
        label: "Seat Label",
    },
    INTERNAL_LABELS: {
        name: INTERNAL_LABEL_VALUES,
        label: "Global Label",
    },

    // Waterfall Prefiltering
    PREFILTER_LABEL_VALUES: {
        name: PREFILTER_LABEL_VALUES,
        label: "Seat Prefilter Labels",
    },
} as const;

export const CHANNEL_FORM_FIELDS = {
    // General Section
    NAME: {
        name: "name",
        label: "Name",
        rulesMessage: {
            required: "Name is required",
            maxLength: `Name cannot be longer than ${MAX_NAME_LENGTH} characters`,
        },
        placeholder: "Enter Name",
    },
    DESCRIPTION: {
        name: "description",
        label: "Description",
        placeholder: "Enter Description",
        rulesMessage: {
            maxLength: `Description cannot be longer than ${MAX_DESCRIPTION_LENGTH} characters`,
        },
    },
    STATUS: {
        name: "status",
        label: "Status",
    },
    CODE_TYPE: {
        name: CODE_TYPE,
        label: "Code Type",
    },
    CODE: {
        name: "code",
        label: "Ad Unit Code",
        rulesMessage: "Ad Unit Code is required",
        placeholder: "Enter Ad Unit Code",
    },
    PUBLISHER_CODE: {
        name: "publisherCode",
        label: "Publisher Code",
    },
    ACCOUNT_TYPE: {
        name: "accountType",
        label: "Account Type",
        placeholder: "Not Specified",
    },
    BILLING_CODE: {
        name: "billingCode",
        label: "Billing Code",
        rulesMessage: "Billing Code is required",
        rulesMaxCharsMessage: "Billing Code should be less than 37 character",
        placeholder: "Enter Billing Code",
    },
    //Defaults Sections
    MIMES: {
        name: MIMES,
        label: "MIMEs",
        placeholder: "Select MIMEs…",
    },
    SUPPORTED_APIS: {
        name: SUPPORTED_APIS,
        label: "Supported APIs",
        placeholder: "Select Supported APIs…",
    },
    LINEARITY: {
        name: LINEARITY,
        label: "Linearity",
    },
    TYPE: {
        name: AD_UNIT_TYPE,
        label: "Type",
    },
    START_DELAY: {
        name: "startDelay",
        label: "Start Delay in Seconds",
        placeholder: "Enter Start Delay in Seconds",
    },
    INTERSTITIAL: {
        name: "isInterstitial",
        label: "Interstitial",
    },
    PLACEMENT: {
        name: PLACEMENT,
        label: "Placement",
    },
    COPPA: {
        name: "isCoppa",
        label: "COPPA",
    },
    PRIVACY_POLICY: {
        name: "hasPrivacyPolicy",
        label: "Privacy Policy",
    },
    CATEGORIES: {
        name: "iabCategories",
        label: "Categories",
        placeholder: "Select Categories…",
    },
    SUPPORTED_PROTOCOLS: {
        name: SUPPORTED_PROTOCOLS,
        label: "Supported Protocols",
        placeholder: "Select Supported Protocols…",
    },
    MIN_DURATION: {
        name: MIN_DURATION,
        label: "Min Duration",
        placeholder: "Enter Min Duration in Seconds",
    },
    MAX_DURATION: {
        name: MAX_DURATION,
        label: "Max Duration",
        placeholder: "Enter Max Duration in Seconds",
    },
    MAX_EXTENDED: {
        name: MAX_EXTENDED,
        label: "Max Extended",
    },
    ADDITIONAL_TIME: {
        name: ADDITIONAL_TIME,
        label: "Additional Time in Seconds",
        placeholder: "Enter Additional Time in Seconds",
    },
    MIN_BITRATE: {
        name: MIN_BITRATE,
        label: "Min Bitrate (Kbps)",
        placeholder: "Enter Min Bitrate (Kbps)",
    },
    MAX_BITRATE: {
        name: MAX_BITRATE,
        label: "Max Bitrate (Kbps)",
        placeholder: "Enter Max Bitrate (Kbps)",
    },
    SSAI_TYPE: {
        name: SSAI_TYPE,
        label: "SSAI Type",
        placeholder: "Select SSAI Type…",
    },
    LIVE_STREAM: {
        name: "isLiveStream",
        label: "Live Stream",
    },
    SKIPPABLE: {
        name: "skip",
        label: "Skippable",
    },
    SKIPPABLE_AFTER: {
        name: "skipafter",
        label: "Skippable After",
        placeholder: "Enter Skippable After in Seconds",
    },
    SKIPPABLE_MINIMUM: {
        name: "skipmin",
        label: "Skippable Minimum",
        placeholder: "Enter Skippable Minimum in Seconds",
    },
    MULTIPLICITY: {
        name: MULTIPLICITY,
        label: "Multiplicity",
    },
    // Ad Pod Section
    MAX_POD_SECONDS: {
        name: MAX_POD_SECONDS,
        label: "Max Pod Seconds",
        placeholder: "Enter Max Pod in Seconds",
    },
    MAX_ADS_PER_POD: {
        name: MAX_ADS_PER_POD,
        label: "Max Ads Per Pod",
        placeholder: "Enter Max Ads Per Pod",
    },
    FILL_MODE: {
        name: AD_POD_FILL_MODE,
        label: "Fill Mode",
    },
    POD_ENFORCEMENT: {
        name: POD_ENFORCEMENT,
        label: "Pod Enforcement",
    },
    COMPETITIVE_SEPARATION_MODE: {
        name: COMPETITIVE_SEPARATION_MODE,
        label: "Competitive Separation Mode",
    },
    COMPETITIVE_SEPARATIO_GROUPS: {
        name: SEPARATION_GROUPS,
        label: "Competitive Separation Groups",
        placeholder: "Select Competitive Separation Groups…",
    },
    MAX_ADS_PER_ADV: {
        name: MAX_ADS_PER_ADV,
        label: "Max Ads per Advertiser",
        placeholder: "Enter Max Ads per Advertiser",
    },
    // Playlist Configuration Section
    PLAYLIST_DEFINITION_MODE: {
        name: PLAYLIST_DEFINITION_MODE,
        label: "Playlist Definition Mode",
    },
    // Ad Break Rules Section
    AD_BREAK_RULES: {
        name: AD_BREAK_RULES,
        labelName: "Name",
        labelIndex: "Index",
        labelAds: "# of Ads",
        labelDuration: "Duration",
        rulesMessage: "Field is required",
    },
    AD_BREAK_RULES_PRE: {
        name: AD_BREAK_RULES_PRE,
    },
    AD_BREAK_RULES_MID: {
        name: AD_BREAK_RULES_MID,
    },
    AD_BREAK_RULES_POST: {
        name: AD_BREAK_RULES_POST,
    },
    CUE_POINTS: {
        name: "cuepoints",
        label: "Cue points",
    },
    // Brand Safety Section
    BLOCK_ADVERTISER_DOMAIN_LISTS: {
        name: "seatAdvertiserDomainFilterListDefs",
        label: "Block Advertiser Domain Lists",
        placeholder: "Select Block Advertiser Domains…",
    },
    BLOCKED_ADVERTISER_DOMAINS: {
        name: "advertiserBlockDomains",
        label: "Blocked Advertiser Domains",
        placeholder: "Enter Blocked Advertiser Domains one per line",
    },
    BLOCK_BUYER_SEAT_LIST: {
        name: "buyerSeatList",
        label: "Block Buyer Seat List",
        placeholder: "Select Block Buyer Seat List…",
    },
    BLOCKED_CATEGORIES: {
        name: "blockedIabCategories",
        label: "Blocked Categories",
        placeholder: "Select Blocked Categories…",
    },
    BLOCKED_INDUSTRIES: {
        name: BLOCKED_INDUSTRIES_NAME,
        label: BLOCKED_INDUSTRIES_LABEL,
    },
    // Detailed Configuration Section
    PUBLISHER_NAME: {
        name: "publisherNameOverride",
        label: "Publisher Name",
        placeholder: "Enter Publisher Name",
    },
    SUPPLY_NAME: {
        name: "supplyNameOverride",
        label: "Supply Name",
        placeholder: "Enter Supply Name",
    },
    PUBLISHER_OVERRIDE: {
        name: "publisherOverride",
        label: "Override",
    },
    SUPPLY_OVERRIDE: {
        name: "supplyOverride",
        label: "Override",
    },
    NO_AD_SYNC: {
        name: "enableNoAdCookieSync",
        label: "No-Ad Cookie Sync",
    },
    BUSINESS_DOMAIN: {
        name: "businessDomain",
        label: "Business Domain",
        placeholder: "Enter Business Domain",
        rulesMessage: "Business Domain is required",
    },
    PARTNER_DOMAIN: {
        name: "inventoryPartnerDomain",
        label: "Inventory Partner Domain",
        placeholder: "Enter Inventory Partner Domain",
    },
    ALLOW_VAST_WRAPPERS: {
        name: "allowVastWrappers",
        label: "Allow VAST Wrappers",
    },
    UD_OPT_OUT: {
        name: "udeOptOut",
        label: "UD Opt-Out",
    },
    LIVE_STREAM_ACCELERATION: {
        name: "lsaEnabled",
        label: "Live Stream Acceleration",
    },
    LSA_WINDOW_SIZE_SECONDS: {
        name: LSA_WINDOW_SIZE_SECONDS,
        label: "LSA Window Size Seconds",
        placeholder: "Enter LSA Window Size in Seconds…",
    },
    UNWRAP_VAST: {
        name: "unwrapVast",
        label: "Unwrap VAST",
    },
    EXTENDED_IMP_WAIT_TIME: {
        name: IMPRESSION_WAIT_TIME,
        label: "Extended Imp Wait Time",
        placeholder: "Select Extended Imp Wait Time",
    },
    ADV_AS_WHITELIST: {
        name: "enableAdvertisersAsWhitelist",
        label: "Advertisers as Whitelist",
    },
    ADVERTISERS_AS_ALLOW_LIST: {
        name: "enableAdvertisersAsWhitelist",
        label: "Advertisers as Allow List",
        trueLabel: "Enabled",
        falseLabel: "Disabled",
    },
    ADV_FREQ_CAPP: {
        name: ADOMAIN_FREQ_CAPPING_TYPE,
        label: "Advertiser Frequency Capping",
    },
    ADV_DOMAIN_FREQ_CAPPS: {
        name: FREQUENCY_CAPPS,
        label: "Advertiser Domain Frequency Caps",
        placehoderTota: "Total",
        placehoderDuration: "Time Duration",
    },
    PUBLISHER_RE_AUCTION: {
        name: "publisherReauction",
        label: "Publisher Re-Auction",
    },
    // Custom Pixels
    CUSTOM_PIXELS: {
        name: "thirdPartyPixels",
        label: "Custom Pixels",
        placeholder: "Select Custom Pixels…",
    },
    // Labels
    LABELS: {
        name: LABEL_VALUES,
        label: "Seat Label",
    },
    INTERNAL_LABELS: {
        name: INTERNAL_LABEL_VALUES,
        label: "Global Label",
    },
    DISTRIBUTION_GROUP_LABELS: {
        name: DISTRIBUTION_GROUP_LABEL_VALUES,
        label: "Distribution Group Labels",
    },
} as const;

export const OTHER_APP_STORE_ID = 0;
export const DEFAULT_AD_UNIT_START_DELAY = 0;
export const DEFAULT_AD_UNIT_MIN_DURATION = 5;
export const DEFAULT_AD_UNIT_MAX_DURATION = 30;
export const IFA_SPECIFY_MODE = false;
export const DEFAULT_AD_UNIT_INTERSTITIAL = false;
export const DEFAULT_UDE_OPT_OUT = false;
export const DEFAULT_AD_UNIT_BOXING = false;
export const DEFAULT_AD_UNIT_LIVE_STREAM = false;
export const IFA_AUTOMATICALLY_DETECT_MODE = true;
export const DEFAULT_BUNDLE_ID_IS_OVERRIDE = false;
export const DEFAULT_ALLOW_VAST_WRAPPER = true;
export const DEFAULT_LIVE_STREAM_ACCELERATION = false;
export const DEFAULT_UNWRAP_VAST = false;
export const DEFAULT_EMIT_PRICING_IN_VAST = true;
export const DEFAULT_ENABLE_NO_AD_COOKIE_SYNC = true;
export const DEFAULT_ADVERTISERS_AS_ALLOW_LIST = false;
export const DEFAULT_ALLOW_VAST_EXTENSION_DSP = false;
export const DEFAULT_ALLOW_VAST_EXTENSION_CRID = false;
export const DEFAULT_ALLOW_VAST_EXTENSION_ADOMAIN = false;
export const DEFAULT_ALLOW_VAST_EXTENSION_BRAND = false;
export const DEFAULT_ALLOW_VAST_EXTENSION_BID_PRICE = false;
export const INDUSTRIES_COMPETITIVE_SEPARATION_MODE = false;
export const IAB_CATEGORIES_COMPETITIVE_SEPARATION_MODE = true;
export const DEFAULT_ALLOW_AD_SOURCE_VAST_EXTENSION = false;
export const DEFAULT_ALLOW_VAST_EXTENSION_IAB_CATEGORY = false;
export const DEFAULT_ALLOW_AD_SOURCE_NAME_VAST_EXTENSION = false;

export const SUPPLY_DYNAMIC_MODE = true;
export const SUPPLY_STATIC_MODE = false;

//Playlist Configuration
export const DEFAULT_PLAYLIST_DEFINITION_MODE = { value: 1, label: "Platform" };
export const PLAYLIST_DEFINITION_MODE_OPTIONS = [
    DEFAULT_PLAYLIST_DEFINITION_MODE,
    {
        value: 2,
        label: "Dynamic",
    },
];
//Ad Break Rules
export const BREAK_RULE_PRE_ROLL = { id: 1, name: "Pre-Roll" };
export const BREAK_RULE_MID_ROLL = { id: 2, name: "Mid-Roll" };
export const BREAK_RULE_POST_ROLL = { id: 3, name: "Post-Roll" };

// Detailed Configuration
export const DEFAULT_WAIT_TIME = { value: 3, label: "120 Min" };
export const OFF_WAIT_TIME = { value: -1, label: "Off" };
export const NONE_OPTION = { value: -1, label: "None" };
export const DEFAULT_ADVERTISER_FREQUENCY_CAPPING = { value: 1, label: "Off" };

export const DEFAULT_FILLS = { value: 1, label: "Fills" };
const MINUTES_IN_HOUR = 60;
const MINUTES_IN_DAY = MINUTES_IN_HOUR * 24;
const MINUTES_IN_WEEK = MINUTES_IN_DAY * 7;
export const MINUTES_IN_MONTH = MINUTES_IN_DAY * 30;
export const FREQ_CAPS_MINUTES = { value: 1, label: "Minutes" };
const FREQ_CAPS_HOURS = { value: MINUTES_IN_HOUR, label: "Hours" };
const FREQ_CAPS_DAYS = { value: MINUTES_IN_DAY, label: "Days" };
const FREQ_CAPS_WEEKS = { value: MINUTES_IN_WEEK, label: "Weeks" };
const FREQ_CAPS_MONTHS = { value: MINUTES_IN_MONTH, label: "Months" };
export const FREQ_CAPS_OPTIONS = [
    FREQ_CAPS_MINUTES,
    FREQ_CAPS_HOURS,
    FREQ_CAPS_DAYS,
    FREQ_CAPS_WEEKS,
    FREQ_CAPS_MONTHS,
];
export const SUPPLY_TYPES = {
    APP: 1,
    SITE: 2,
    CHANNEL: 3,
    LINEAR: 4,
};

// For Supply Domain and Bundle Id Filter Lists on Supply, only allow List Type 2(Allow Lists)
export const SUPPLY_DOMAIN_AND_BUNDLE_ID_FILTER_LISTS_TYPE = 2;
export const API_TYPES = {
    VPAID_1: { value: 1, label: "VPAID 1.0" },
    VPAID_2: { value: 2, label: "VPAID 2.0" },
    MRAID_1: { value: 3, label: "MRAID 1" },
    MRAID_2: { value: 4, label: "MRAID 2" },
    OMID_1: { value: 5, label: "OMID 1" },
} as const;
export const AD_UNITS_MULTIPLICITIES = {
    SINGLE: { value: 1, label: "Single" },
    POD: { value: 2, label: "Pod" },
    PLAYLIST: { value: 3, label: "Playlist" },
    UDE: { value: 4, label: "UDE" },
} as const;
export const AD_UNITS_PROTOCOLS = {
    VAST_2: { value: 1, label: "VAST 2" },
    VAST_3: { value: 2, label: "VAST 3" },
    VAST_4: { value: 3, label: "VAST 4" },
} as const;

export const EXTENDED_IMP_TIME_LINEAR_DEFAULT = { value: 3, label: "120 Min" };

export const MIME_TYPES = {
    MP4: { value: 1, label: "video/mp4" },
    FLASH: { value: 2, label: "application/x-shockwave-flash" },
    JAVASCRIPT: { value: 3, label: "application/javascript" },
    X_FLV: { value: 4, label: "video/x-flv" },
    APPLE: { value: 5, label: "application/vnd.apple.mpegurl" },
    DASH_XML: { value: 6, label: "application/dash+xml" },
    MS_SSTR_XML: { value: 7, label: "application/vnd.ms-sstr+xml" },
    QUICKTIME: { value: 8, label: "video/quicktime" },
} as const;
export const NOT_SPECIFIED_PAID_TYPE = { value: 1, label: "Not Specified" };
export const SUPPLY_SITE_TYPE = { value: 2, label: "Site" };
export const OPTIMIZE_FILL_MODE = { value: 1, label: "Optimize Fill" };
export const NONE_POD_ENFORCEMENT = { value: 0, label: "None" };
export const SUPPLY_CTV_TYPE = { value: 1, label: "App/CTV" };
export const SUPPLY_LINEAR_TYPE = { value: 4, label: "Linear" };
export const AD_UNIT_ACTIVE_STATUS = { value: 1, label: "Active" };
export const AD_UNIT_IN_STREAM_PLACEMENT_TYPE = { value: 1, label: "In-Stream" };
export const YES_OPTION = { value: true, label: "Yes" };
export const NO_OPTION = { value: false, label: "No" };
export const NOT_SPECIFIED_OPTION = { value: "NOT_SPECIFIED", label: "Not Specified" };
export const UNKNOWN_OPTION = { value: "UNKNOWN", label: "Unknown" };
export const LINEARITY_BOTH_ALLOWED = { value: "BOTH_ALLOWED", label: "Both Allowed" };
export const AD_UNIT_PRE_ROLL_TYPE = { value: 1, label: "Pre-roll" };
export const AD_UNIT_CUSTOM_TYPE = { value: 4, label: "Custom" };
export const LINEARITY_LINEAR = { value: 1, label: "Linear/In-stream" };

export const NOT_ALLOWED_MAX_EXTENDED = { value: 0, label: "Not Allowed" };
export const UNNLIMITED_MAX_EXTENDED = { value: -1, label: "Unlimited" };
export const CUSTOM_MAX_EXTENDED = { value: "CUSTOM", label: "Custom" };
export const AD_UNIT_MAX_EXTENDED_OPTIONS = [UNNLIMITED_MAX_EXTENDED, NOT_ALLOWED_MAX_EXTENDED, CUSTOM_MAX_EXTENDED];
export const INVENTORY_YES_NO_SPECIFIED_OPTIONS = [YES_OPTION, NO_OPTION, NOT_SPECIFIED_OPTION];
export const AD_UNIT_SKIPPABLE_OPTIONS = [YES_OPTION, NO_OPTION, UNKNOWN_OPTION];
export const INVENTORY_GENERATED_CODE_TYPE = { value: "generated", label: "Generated" };
export const INVENTORY_NOT_AVAILABLE_RESELL_TYPE = { value: 4, label: "Not Available" };
export const INVENTORY_INDIRECT_SUPPLY_SOURCE_RELATIONSHIP = { value: 0, label: "Indirect" };
export const INVENTORY_UNKNOWN_VIDEO_QUALITY_TYPE = { value: 0, label: "Unknown" };
export const INVENTORY_CUSTOM_CODE_TYPE = { value: "custom", label: "Custom" };
export const INVENTORY_CODE_TYPE_OPTIONS = [INVENTORY_GENERATED_CODE_TYPE, INVENTORY_CUSTOM_CODE_TYPE];
export const INVENTORY_YES_NO_OPTIONS = [YES_OPTION, NO_OPTION];

export type InventoryCodeType = typeof INVENTORY_GENERATED_CODE_TYPE.value | typeof INVENTORY_CUSTOM_CODE_TYPE.value;
export type HierarchyFormsMode = "create" | "copy" | "edit";
