import React, { FC } from "react";
import { useUserAccess } from "@app/core/auth";
import { ROUTE_FORMATTERS } from "@app/core/routing";
import { Button, Dropdown, Space, Tooltip } from "antd";
import { ItemType } from "antd/lib/menu/interface";
import { useHistory, useParams } from "react-router-dom";
import { downloadTags } from "@app/features/inventory/helpers";
import { AdUnit } from "@app/core/services";
import { InventoryDetailsDrawerType } from "@app/features/inventory/DetailsDrawer/reducer";
import { TagsOutlined, EditOutlined, MoreOutlined, MonitorOutlined, PlusOutlined } from "@ant-design/icons";

interface InventoryAdUnitsTableActionsProps {
    supplyId: string | number;
    publisherId: string | number;
    brandId: string | number;
    adUnit: AdUnit;
}

export const InventoryAdUnitsTableActions: FC<InventoryAdUnitsTableActionsProps> = ({
    adUnit,
    brandId,
    publisherId,
    supplyId,
}) => {
    const history = useHistory();
    const { hasSeatWriteAccess } = useUserAccess();
    const { seatId } = useParams<{ seatId: string }>();

    const handleTagParams = (): void => {
        history.push(
            ROUTE_FORMATTERS.SEAT_INVENTORY_HEALTH_DETAILS_TAG_PARAMS(
                false,
                seatId,
                InventoryDetailsDrawerType.AD_UNIT,
                publisherId as number,
                brandId as number,
                supplyId as number,
                adUnit.id
            )
        );
    };

    const handleChangelog = (): void => {
        history.push(
            ROUTE_FORMATTERS.SEAT_INVENTORY_HEALTH_DETAILS_CHANGE_HISTORY(
                false,
                seatId,
                InventoryDetailsDrawerType.AD_UNIT,
                Number(publisherId),
                Number(brandId),
                Number(supplyId),
                Number(adUnit.id)
            ) as string
        );
        return;
    };
    const handleCreativeReview = (): void => {
        history.push(
            ROUTE_FORMATTERS.SEAT_INVENTORY_HEALTH_DETAILS_CREATIVE_REVIEW(
                false,
                seatId,
                InventoryDetailsDrawerType.AD_UNIT,
                publisherId as number,
                brandId as number,
                supplyId as number,
                adUnit.id
            )
        );
    };

    const handleDiagnostics = () => {
        history.push(
            ROUTE_FORMATTERS.SEAT_INVENTORY_HEALTH_DETAILS_DIAGNOSTICS(
                false,
                seatId,
                InventoryDetailsDrawerType.AD_UNIT,
                publisherId as number,
                brandId as number,
                supplyId as number,
                adUnit.id
            )
        );
    };
    const floorAnalysis = (): void =>
        history.push(
            ROUTE_FORMATTERS.SEAT_INVENTORY_HEALTH_DETAILS_FLOOR_ANALYSIS(
                false,
                seatId,
                InventoryDetailsDrawerType.AD_UNIT,
                publisherId as number,
                brandId as number,
                supplyId as number,
                adUnit.id
            )
        );

    const handleEdit = () => {
        history.push(
            ROUTE_FORMATTERS.SEAT_INVENTORY_DETAILS_PUBLISHER_BRAND_SUPPLY_AD_UNIT_EDIT(
                seatId,
                publisherId,
                brandId,
                supplyId,
                adUnit.id
            )
        );
    };

    const handleCopy = () => {
        history.push(
            ROUTE_FORMATTERS.SEAT_INVENTORY_DETAILS_PUBLISHER_BRAND_SUPPLY_AD_UNIT_COPY(
                seatId,
                publisherId,
                brandId,
                supplyId,
                adUnit.id
            )
        );
    };
    const handleAddFloor = (): void => history.push(ROUTE_FORMATTERS.SEAT_INVENTORY_AD_UNITS_FLOORS(seatId, adUnit.id));

    const handleGetTags = () => {
        downloadTags(seatId);
    };

    const handleLadle = () => {
        history.push(
            ROUTE_FORMATTERS.SEAT_INVENTORY_HEALTH_DETAILS_LADLE(
                false,
                seatId,
                InventoryDetailsDrawerType.AD_UNIT,
                Number(publisherId),
                Number(brandId),
                Number(supplyId),
                Number(adUnit.id)
            ) as string
        );
    };

    const items = [
        {
            label: "Creative Review",
            key: "creativeReview",
            onClick: (e) => {
                e.domEvent.stopPropagation();
                handleCreativeReview();
            },
        },
        {
            label: "Floor Analysis",
            key: "floorAnalysis",
            onClick: (e) => {
                e.domEvent.stopPropagation();
                floorAnalysis();
            },
        },
        hasSeatWriteAccess && {
            label: "Change History",
            key: "changeHistory",
            onClick: (e) => {
                e.domEvent.stopPropagation();
                handleChangelog();
            },
        },
        hasSeatWriteAccess && {
            label: "Get Tag",
            key: "getTag",
            onClick: (e) => {
                e.domEvent.stopPropagation();
                handleGetTags();
            },
        },
        {
            label: "Ladle",
            key: "ladle",
            onClick: (e) => {
                e.domEvent.stopPropagation();
                handleLadle();
            },
        },
    ].filter(Boolean) as ItemType[];

    const createActionsItems: ItemType[] = [
        hasSeatWriteAccess && {
            label: "Copy Ad Unit",
            key: "copyAdUnit",
            onClick: (e) => {
                e.domEvent.stopPropagation();
                handleCopy();
            },
        },
        hasSeatWriteAccess && {
            label: "Add a Floor",
            key: "createFloor",
            onClick: (e) => {
                e.domEvent.stopPropagation();
                handleAddFloor();
            },
        },
    ].filter(Boolean) as ItemType[];

    return (
        <Space style={{ width: "100%" }}>
            <Button.Group>
                <Tooltip title="Inbound Request Params">
                    <Button
                        onClick={(e) => {
                            e.stopPropagation();
                            handleTagParams();
                        }}
                        icon={<TagsOutlined />}
                        size="small"
                    />
                </Tooltip>
                <Tooltip title="Diagnostic">
                    <Button
                        onClick={(e) => {
                            e.stopPropagation();
                            handleDiagnostics();
                        }}
                        icon={<MonitorOutlined />}
                        size="small"
                    />
                </Tooltip>
                {hasSeatWriteAccess && (
                    <Tooltip title="Edit">
                        <Button
                            onClick={(e) => {
                                e.stopPropagation();
                                handleEdit();
                            }}
                            icon={<EditOutlined />}
                            size="small"
                        />
                    </Tooltip>
                )}
                {hasSeatWriteAccess && (
                    <Tooltip title="Create">
                        <Dropdown placement="bottomLeft" trigger={["click"]} menu={{ items: createActionsItems }}>
                            <Button
                                icon={<PlusOutlined rotate={90} />}
                                size="small"
                                onClick={(e) => e.stopPropagation()}
                            />
                        </Dropdown>
                    </Tooltip>
                )}
                <Dropdown menu={{ items }} placement="bottomLeft" trigger={["click"]}>
                    <Button icon={<MoreOutlined rotate={90} />} size="small" onClick={(e) => e.stopPropagation()} />
                </Dropdown>
            </Button.Group>
        </Space>
    );
};
