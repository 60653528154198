import { useNestedData } from "../useNestedData";
import { useAppSelector } from "@app/core/store";
import { ChangeLogEntityType } from "@app/core/services";
import { InventoryDetailsDrawerType, selectInventoryDetailsDrawerType } from "../../reducer";

interface Props {
    unitId: string | number;
}

interface UseChangeHistory {
    breadcrumbs?: string;
    entityType: ChangeLogEntityType | null;
    isLoading: boolean;
}

export const useChangeHistory = ({ unitId }: Props): UseChangeHistory => {
    const { nestedBreadcrumbs, isLoading } = useNestedData(unitId);
    const drawerType = useAppSelector(selectInventoryDetailsDrawerType) as InventoryDetailsDrawerType;
    let entityType: ChangeLogEntityType;

    switch (drawerType) {
        case InventoryDetailsDrawerType.SEAT:
            entityType = ChangeLogEntityType.Seat;
            break;
        case InventoryDetailsDrawerType.PUBLISHER:
            entityType = ChangeLogEntityType.Publisher;
            break;
        case InventoryDetailsDrawerType.CHANNEL:
            entityType = ChangeLogEntityType.Channel;
            break;
        case InventoryDetailsDrawerType.BRAND:
            entityType = ChangeLogEntityType.Brand;
            break;
        case InventoryDetailsDrawerType.BRAND_CHANNEL:
            entityType = ChangeLogEntityType.BrandChannels;
            break;
        case InventoryDetailsDrawerType.SUPPLY:
            entityType = ChangeLogEntityType.Supply;
            break;
        case InventoryDetailsDrawerType.AD_UNIT:
            entityType = ChangeLogEntityType.AdUnit;
            break;
    }

    return {
        breadcrumbs: nestedBreadcrumbs,
        entityType,
        isLoading,
    };
};
