import { FC } from "react";
import { Radio } from "antd";
import { TRANSPARENCY_OPTIONS } from "@app/features/deals/constants";
import { useAdSourceStoreUrlField } from "./useAdSourceStoreUrlField";
import { TransparencyFormItem } from "@app/features/deals/DealForm/DealFormCreateAdSource/DealFormCreateAdSourceSections/AdSourceTransparencySection/Fields";
import { AD_SOURCE_FIELDS } from "@app/features/seatAdSources/constants";

export const AdSourceStoreUrlField: FC = () => {
    const { value, onChange } = useAdSourceStoreUrlField();
    return (
        <TransparencyFormItem label={AD_SOURCE_FIELDS.STORE_URL.label} data-sdet="store-url">
            <Radio.Group onChange={(e) => onChange(e?.target?.value)} value={value} options={TRANSPARENCY_OPTIONS} />
        </TransparencyFormItem>
    );
};
