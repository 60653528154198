import { Card, Col, Row, Collapse } from "antd";
import { FC } from "react";
import {
    mainStepSectionClassList,
    AD_SOURCE_SECTIONS,
    cardBodyStyle,
    AD_SOURCE_SECTIONS_NAME,
    COL_SIZES,
    GUTTER,
} from "../../../constants";
import { DefinitionSidePanel } from "../../DefinitionSidePanel";
import { useIsPbsDealType } from "../../useIsPbsDealType";
import { LabelMultiselect } from "./Fields/LabelMultiselect";
import { PreFilterModeRadio } from "./Fields/PreFilterModeRadio";

export const LabelsSection: FC = () => {
    const isPbsDealType = useIsPbsDealType();
    const isVisible = !isPbsDealType;
    if (!isVisible) {
        return null;
    }
    return (
        <Collapse
            bordered
            ghost={false}
            collapsible="header"
            className={mainStepSectionClassList}
            items={[
                {
                    key: "1",
                    label: AD_SOURCE_SECTIONS_NAME.LABELS,
                    extra: (
                        <DefinitionSidePanel
                            panelLabel={AD_SOURCE_SECTIONS_NAME[AD_SOURCE_SECTIONS.LABELS]}
                            section={AD_SOURCE_SECTIONS.LABELS}
                            sectionSelector="#adSourceForm"
                        />
                    ),
                    forceRender: true,
                    children: (
                        <Card bordered={false} styles={{ body: cardBodyStyle }}>
                            <Row gutter={GUTTER}>
                                <Col {...COL_SIZES} md={14} lg={14} xl={14} xxl={14}>
                                    <PreFilterModeRadio />
                                </Col>
                            </Row>
                            <Row gutter={GUTTER}>
                                <Col {...COL_SIZES} md={14} lg={14} xl={14} xxl={14}>
                                    <LabelMultiselect />
                                </Col>
                            </Row>
                        </Card>
                    ),
                },
            ]}
        />
    );
};
