import { FC } from "react";
import { Row, Typography } from "antd";
import { LabeledValue } from "antd/lib/select";
import { TargetingDimensionTypeLabels } from "@app/features/targeting/constants";
import { BulkUploadDrawerButton } from "../../BulkUploadDrawerButton";
import { useContentMetadataTvSeriesDrawerButton } from "./useBulkContentMetadataTvSeriesDrawer";

interface Props {
    onChange: (value: LabeledValue[]) => void;
    values: LabeledValue[];
}

export const BulkContentMetadataTvSeriesDrawerButton: FC<Props> = ({ onChange, values }) => {
    const {
        handleAdd,
        handleChangeValue,
        handleClose,
        handleOpen,
        handleReplace,
        isLoadingAdd,
        isLoadingReplace,
        isOpen,
        value,
        invalidValues,
    } = useContentMetadataTvSeriesDrawerButton(onChange, values);

    return (
        <BulkUploadDrawerButton
            handleAdd={handleAdd}
            handleChangeValue={handleChangeValue}
            handleClose={handleClose}
            handleOpen={handleOpen}
            handleReplace={handleReplace}
            isLoadingAdd={isLoadingAdd}
            isLoadingReplace={isLoadingReplace}
            isOpen={isOpen}
            value={value}
            title={`Bulk Load ${TargetingDimensionTypeLabels.ContentSeries}`}
            placeholder={`Enter ${TargetingDimensionTypeLabels.ContentSeries} one per row`}
            extra={
                <Row align={"middle"}>
                    <Typography.Paragraph>
                        {TargetingDimensionTypeLabels.ContentSeries} names are case sensitive.
                    </Typography.Paragraph>
                </Row>
            }
            invalidValues={invalidValues}
        />
    );
};
