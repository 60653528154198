import { Advertiser } from "./advertisers";
import { consoleApi, SEAT_INDUSTRIES_GLOBAL_TAG, SEAT_INDUSTRIES_TAG, SEAT_INDUSTRY_DETAILS_TAG } from "./console";
import { CommonPageParams } from "./seats";
import { toQueryString } from "./utils";

export type Industry = {
    code: string;
    creationDate: string;
    description: string | null;
    id: number;
    name: string;
    primaryIabCategory: null;
    seat: {
        id: number;
        name: string;
        code: string;
        entityType: "Seat";
    };
    updateDate: string;
};

export type IndustryDetails = Industry & {
    deleted?: boolean;
    advertisers: Advertiser[];
};

export type IndustryMutationPayload = {
    code?: string;
    description?: string;
    id: number | null;
    name: string;
    seat: {
        id: number;
    };
};

export interface GlobalIndustryOption {
    code: string;
    creationDate: string;
    description: string | null;
    id: number;
    name: string;
    primaryIabCategory: {
        code: string;
        creationDate: string;
        id: number;
        name: string;
        parentId: number | null;
        parentName: string | null;
        updateDate: string;
    } | null;
    externalTaxonomies: unknown;
    seat: null;
    updateDate: string;
}

export const industriesApi = consoleApi.injectEndpoints({
    endpoints: (builder) => ({
        getIndustries: builder.query<Industry[], { seatId: number; params?: CommonPageParams }>({
            query: ({ seatId, params }) => {
                return `seats/${seatId}/industries;${toQueryString(params || {})}`;
            },
            providesTags: [{ type: SEAT_INDUSTRIES_TAG }],
        }),
        getIndustriesGlobal: builder.query<GlobalIndustryOption[], CommonPageParams | void>({
            query: (args) => {
                return `industries/global;${toQueryString(args || {})}`;
            },
            providesTags: [{ type: SEAT_INDUSTRIES_GLOBAL_TAG }],
        }),
        getIndustryDetails: builder.query<IndustryDetails, number>({
            query: (industryId) => {
                return `industries/${industryId}`;
            },
            providesTags: (_, __, id) => [{ type: SEAT_INDUSTRY_DETAILS_TAG, id }],
        }),
        createIndustry: builder.mutation<IndustryDetails, { seatId: number; payload: IndustryMutationPayload }>({
            query: ({ seatId, payload }) => ({
                url: `seats/${seatId}/industries`,
                method: "POST",
                body: payload,
            }),
            invalidatesTags: () => [{ type: SEAT_INDUSTRIES_TAG }],
        }),
        updateIndustry: builder.mutation<IndustryDetails, { payload: IndustryMutationPayload & { id: number } }>({
            query: ({ payload }) => ({
                url: `industries/${payload.id}`,
                method: "PUT",
                body: payload,
            }),
            invalidatesTags: (_, __, { payload }) => [
                { type: SEAT_INDUSTRIES_TAG },
                { type: SEAT_INDUSTRY_DETAILS_TAG, id: payload.id },
            ],
        }),
        deleteIndustry: builder.mutation<void, number>({
            query: (industryId) => ({
                url: `industries/${industryId}`,
                method: "DELETE",
            }),
            invalidatesTags: (_, __, industryId) => [
                { type: SEAT_INDUSTRIES_TAG },
                { type: SEAT_INDUSTRY_DETAILS_TAG, id: industryId },
            ],
        }),
    }),
    overrideExisting: false,
});

export const {
    useGetIndustriesQuery,
    useGetIndustriesGlobalQuery,
    useLazyGetIndustryDetailsQuery,
    useGetIndustryDetailsQuery,
    useCreateIndustryMutation,
    useUpdateIndustryMutation,
    useDeleteIndustryMutation,
} = industriesApi;
