import { FC } from "react";
import { Form, Select } from "antd";
import { CREATE_DEAL_FORM_ITEMS_NAME } from "@app/features/deals/constants";
import { AD_SOURCE_FIELDS } from "@app/features/seatAdSources/constants";
import { useAdSourceIndustriesModeField } from "./useAdSourceIndustriesModeField";

interface Props {
    advertiserDomainModeId: number | null;
}

export const AdSourceIndustriesModeField: FC<Props> = ({ advertiserDomainModeId }) => {
    const { value, isLoading, isDisabled, options, onChange } = useAdSourceIndustriesModeField(advertiserDomainModeId);

    return (
        <Form.Item
            name={CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_INDUSTRIES_MODE}
            data-sdet="ad-source-industries-mode-field"
            label={AD_SOURCE_FIELDS.INDUSTRIES_MODE.label}
        >
            <Select
                disabled={isDisabled}
                showSearch
                options={options}
                loading={isLoading}
                optionFilterProp="label"
                placeholder={`Select ${AD_SOURCE_FIELDS.INDUSTRIES_MODE.label}`}
                onChange={onChange}
                value={value}
                allowClear
            />
        </Form.Item>
    );
};
