import React, { useMemo } from "react";
import { Flex, Timeline, TimelineItemProps, Typography } from "antd";
import { ClockCircleOutlined } from "@ant-design/icons";
import { css } from "@emotion/css";
import moment from "moment";
import { blue6 } from "@rubicon/antd-components";
import { AdSourceStatsSnapshot } from "@app/core/services/adStats/statsSnapshots";
import { YEAR_MONTH_DAY_HOUR_MINUTE_AMPM } from "@app/core/components/constants";

const selectedItemStyles = css`
    .ant-timeline-item-content {
        background-color: #f6f0fe;
    }
`;

const disabledItemStyles = css`
    .ant-timeline-item-content {
        background-color: #f5f5f5;
    }
`;

const itemStyles = css`
    .ant-timeline-item-content {
        cursor: pointer;
        width: 100%;
    }
`;

interface Props {
    data: AdSourceStatsSnapshot[];
    selectedYear: number | null;
    selectedSnapshots: number[];
    selectedSnapshotsLimit: number;
    handleClick: (id: number) => void;
}

export const SnapshotsTimeline = ({
    data,
    selectedYear,
    selectedSnapshots,
    handleClick,
    selectedSnapshotsLimit,
}: Props) => {
    const items: TimelineItemProps[] = useMemo(() => {
        const items: TimelineItemProps[] = [];
        const obj = {};

        for (const snapshot of [...data].reverse()) {
            const parsedTimestamp = snapshot.filename.match(/\/(\d+)\.json$/)?.[1];
            if (!parsedTimestamp) {
                continue;
            }
            const timestamp = moment.unix(+parsedTimestamp / 1000);
            const year = timestamp.year();

            if (selectedYear && selectedYear !== year) {
                continue;
            }

            if (!(year in obj)) {
                obj[year] = [snapshot];
            } else {
                obj[year].push(snapshot);
            }
        }

        for (const year of Object.keys(obj).sort((a, b) => +b - +a)) {
            items.push({
                dot: <ClockCircleOutlined style={{ fontSize: "12px" }} />,
                color: blue6,
                children: <Typography.Text>{year}</Typography.Text>,
            });

            for (const snapshot of obj[year]) {
                const parsedTimestamp = snapshot.filename.match(/\/(\d+)\.json$/)?.[1];
                if (!parsedTimestamp) {
                    continue;
                }
                const timestamp = moment.unix(+parsedTimestamp / 1000);
                items.push({
                    color: blue6,
                    children: (
                        <Flex vertical onClick={() => handleClick(parsedTimestamp)}>
                            <Typography.Text>{timestamp.format(YEAR_MONTH_DAY_HOUR_MINUTE_AMPM)}</Typography.Text>
                            <Typography.Text>{snapshot.emailAddress}</Typography.Text>
                        </Flex>
                    ),
                    className: selectedSnapshots.includes(parsedTimestamp)
                        ? selectedItemStyles
                        : selectedSnapshots.length < selectedSnapshotsLimit
                        ? ""
                        : disabledItemStyles,
                });
            }
        }

        return items;
    }, [handleClick, selectedSnapshots, data, selectedYear, selectedSnapshotsLimit]);

    if (!items.length) {
        return <Typography.Text>No snapshots</Typography.Text>;
    }

    return <Timeline className={itemStyles} mode="left" items={items} />;
};
