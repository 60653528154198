import {
    useGetStructuredNukesQuery,
    useRunSupplyTrafficMutation,
    useBlockSupplyTrafficMutation,
} from "@app/core/services";
import { FC, useState } from "react";
import { useParams } from "react-router-dom";
import { useUserAccess } from "@app/core/auth";
import { Space, Typography, Switch, notification } from "antd";
import { Confirmation } from "@app/features/inventory/components/SupplyTrafficSwitch/Confirmation";

export const SupplyTrafficSwitch: FC = () => {
    const { hasTremorRole, hasSeatWriteAccess } = useUserAccess();
    const { seatId, supplyId } = useParams<{ seatId: string; supplyId: string }>();
    const { data = [], isFetching } = useGetStructuredNukesQuery(seatId, {
        skip: !seatId || !hasTremorRole || !supplyId,
    });

    const [open, setIsOpen] = useState<boolean>(false);
    const [isTrafficRunning, setIsTrafficRunning] = useState<boolean>(
        !data.some((nuke): boolean => nuke?.supply_id === Number(supplyId))
    );

    const [blockSupplyTraffic, { isLoading: isBlocking }] = useBlockSupplyTrafficMutation();
    const [runSupplyTraffic, { isLoading: isRunning }] = useRunSupplyTrafficMutation();

    const onChange = async (value: boolean): Promise<void> => {
        if (!value) {
            setIsOpen(true);
            return;
        }
        try {
            await runSupplyTraffic({ id: supplyId }).unwrap();
            notification.success({ message: "Supply Traffic is Running" });
            setIsTrafficRunning(true);
        } catch (err) {
            notification.error({ message: err?.data?.errorDescription });
        }
    };

    const handleCancel = (): void => {
        setIsTrafficRunning(true);
        setIsOpen(false);
    };

    const handleBlock = async (): Promise<void> => {
        try {
            await blockSupplyTraffic({ body: { id: supplyId } }).unwrap();
            notification.success({ message: "Supply Traffic is Blocked" });
            setIsTrafficRunning(false);
            setIsOpen(false);
        } catch (err) {
            notification.error({ message: err?.data?.errorDescription });
        }
    };

    if (!hasTremorRole || !hasSeatWriteAccess) return null;

    return (
        <>
            <Space>
                <Typography.Title level={5} style={{ marginBottom: 0, marginTop: 0 }}>
                    Supply Traffic:
                </Typography.Title>
                <Switch
                    onChange={onChange}
                    checkedChildren="Running"
                    checked={isTrafficRunning}
                    unCheckedChildren="Blocked"
                    loading={isBlocking || isRunning || isFetching}
                />
            </Space>
            <Confirmation confirmLoading={isBlocking} onYes={handleBlock} open={open} onCancel={handleCancel} />
        </>
    );
};
