import { AdSourceFloorType, Audience, BaseOption, SeatSegment } from "@app/core/services";
import { useAppSelector } from "@app/core/store";
import {
    AD_SOURCE_FIELDS,
    AD_SOURCE_SECTIONS_NAME,
    DEFAULT_ENRICHMENT_COST_CURRENCY_CODE,
    VERTICAL_LAYOUT_FIX,
    cardBodyStyle as cardBodyStyleDefault,
    mainStepSectionClassList,
} from "@app/features/seatAdSources/constants";
import { targetingToTargetingBlock } from "@app/features/targeting";
import { css } from "@emotion/css";
import { Card, Col, Form, Row, Collapse } from "antd";
import { CSSProperties, FC, ReactNode } from "react";
import { AdSourcesForm } from "../..";
import { EnrichmentCostDetailsDrawer } from "./EnrichmentCostDetailsDrawer";
import { DealLevelValue, useAdSourceEnrichmentCost } from "./useAdSourceEnrichmentCost";
import {
    getSegmentsFromTargetingForm,
    EnrichmentCost,
    getAudiencesFromTargetingForm,
    getEnrichmentCostHelp,
    isEnrichmentCostEligible,
} from "./utils";
import { DemandAcquisitionCostField } from "./DemandAcquisitionCostField";
import { EnrichmentCostTable } from "./EnrichmentCostTable";
import { useDemandAcquisitionCostUtils } from "./useDemandAcquisitionCostUtils";

interface Props {
    adSourceCpm: number | null;
    adSourceCurrency: string;
    adSourceDemandAcquisitionCost: string | number | undefined | null;
    adSourceDemandAcquisitionCostModelId: number | null | undefined;
    adSourceEc: EnrichmentCost;
    adSourceFloorType: Partial<AdSourceFloorType> | undefined;
    adSourceLevelAudiences: Audience[];
    adSourceLevelSegments: SeatSegment[];
    adSourceName: string;
    adSourceType: BaseOption;
    cardBodyStyle?: CSSProperties | null;
    dealLevelValues: DealLevelValue[];
    defaultActiveKey?: string[];
    demandAcquisitionCostField: ReactNode;
    hasEnrichmentCostTargeting: boolean;
    isDemandAcquisitionCostEnabled: boolean;
    isDrawerOpen: boolean;
    isRangedCost: boolean;
    selectedDealCurrencyCode: string;
    selectedDealLevelAudiences: Audience[];
    selectedDealLevelSegments: SeatSegment[];
    selectedDealName: string;
    selectedDealRate: number;
    setIsDrawerOpen: (_isDrawerOpen: boolean) => void;
    setSelectedDeal: (dealId: string) => void;
    shouldUseAdSourceValues: boolean;
}

export const EnrichmentCostSectionInnerCard: FC<Omit<Props, "defaultActiveKey">> = ({
    adSourceFloorType,
    adSourceCpm,
    adSourceCurrency,
    adSourceDemandAcquisitionCost,
    adSourceDemandAcquisitionCostModelId,
    adSourceEc,
    adSourceLevelAudiences,
    adSourceLevelSegments,
    adSourceName,
    adSourceType,
    cardBodyStyle = cardBodyStyleDefault,
    dealLevelValues,
    demandAcquisitionCostField,
    hasEnrichmentCostTargeting,
    isDemandAcquisitionCostEnabled,
    isDrawerOpen,
    selectedDealCurrencyCode,
    selectedDealLevelAudiences,
    selectedDealLevelSegments,
    selectedDealName,
    selectedDealRate,
    setIsDrawerOpen,
    setSelectedDeal,
    shouldUseAdSourceValues,
}) => {
    const adSourceDemandAcquisitionCostNum = !adSourceDemandAcquisitionCost ? 0 : Number(adSourceDemandAcquisitionCost);

    return (
        <>
            <Card
                bordered={false}
                styles={{ body: cardBodyStyle || undefined }}
                className={css`
                    &.ant-card {
                        box-shadow: none;
                    }
                `}
                data-sdet="ad-source-enrichment-cost-section"
            >
                <Col span={24}>
                    {isDemandAcquisitionCostEnabled && (
                        <Row>
                            <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={8}>
                                {demandAcquisitionCostField}
                            </Col>
                        </Row>
                    )}
                    <Form.Item
                        label={`Audience Enrichment Cost (CPM)${
                            isDemandAcquisitionCostEnabled ? " & Demand Acquisition Cost (CPM)" : ""
                        }`}
                        help={getEnrichmentCostHelp(hasEnrichmentCostTargeting, Boolean(adSourceDemandAcquisitionCost))}
                        {...VERTICAL_LAYOUT_FIX}
                        className={css`
                            .ant-form-item-control-input {
                                min-height: 0;
                            }
                        `}
                    >
                        {Boolean(adSourceDemandAcquisitionCost || hasEnrichmentCostTargeting) && (
                            <EnrichmentCostTable
                                adSourceType={adSourceType}
                                adSourceCpm={adSourceCpm}
                                adSourceFloorType={adSourceFloorType}
                                adSourceDemandAcquisitionCost={adSourceDemandAcquisitionCostNum}
                                adSourceDemandAcquisitionCostModelId={adSourceDemandAcquisitionCostModelId}
                                adSourceEc={adSourceEc}
                                dealLevelValues={dealLevelValues}
                                isDemandAcquisitionCostEnabled={isDemandAcquisitionCostEnabled}
                                setSelectedDeal={(dealId: string) => setSelectedDeal(dealId)}
                                shouldUseAdSourceValues={shouldUseAdSourceValues}
                            />
                        )}
                    </Form.Item>
                </Col>
            </Card>
            <EnrichmentCostDetailsDrawer
                adSourceFloorType={adSourceFloorType}
                adSourceCpm={adSourceCpm}
                adSourceCurrencyCode={adSourceCurrency}
                adSourceDemandAcquisitionCost={adSourceDemandAcquisitionCostNum}
                adSourceDemandAcquisitionCostModelId={adSourceDemandAcquisitionCostModelId || null}
                adSourceLevelAudiences={adSourceLevelAudiences}
                adSourceLevelSegments={adSourceLevelSegments}
                adSourceName={adSourceName}
                adSourceType={adSourceType}
                dealCurrencyCode={selectedDealCurrencyCode}
                dealLevelAudiences={selectedDealLevelAudiences}
                dealLevelSegments={selectedDealLevelSegments}
                dealName={selectedDealName}
                dealRate={selectedDealRate}
                shouldUseAdSourceValues={shouldUseAdSourceValues}
                isDemandAcquisitionCostEnabled={isDemandAcquisitionCostEnabled}
                onClose={() => setIsDrawerOpen(false)}
                open={isDrawerOpen}
            />
        </>
    );
};

export const EnrichmentCostSectionInner: FC<Props> = ({ defaultActiveKey = ["1"], ...rest }) => {
    return (
        <Collapse
            bordered
            ghost={false}
            collapsible="header"
            className={mainStepSectionClassList}
            defaultActiveKey={defaultActiveKey}
            items={[
                {
                    key: "1",
                    label: AD_SOURCE_SECTIONS_NAME.ENRICHMENT_COST,
                    forceRender: true,
                    children: <EnrichmentCostSectionInnerCard {...rest} />,
                },
            ]}
        />
    );
};

export const EnrichmentCostSection: FC = () => {
    const { isDemandAcquisitionEnabled } = useDemandAcquisitionCostUtils();
    const adSourceCurrencyCode =
        Form.useWatch<AdSourcesForm["currencyType"]>(AD_SOURCE_FIELDS.CURRENCY.name)?.code || "USD";
    const adSourceType = Form.useWatch<AdSourcesForm["type"]>(AD_SOURCE_FIELDS.TYPE.name);
    const adSourceLevelTargeting = useAppSelector(
        (state) => state.targeting.targetingFormsByKey.adSource.targetingBlocks
    );
    const adSourceFloorType = Form.useWatch<AdSourcesForm["adSourceFloorType"]>(AD_SOURCE_FIELDS.FLOOR_TYPE.name);
    const marketplaceInfoList = Form.useWatch<AdSourcesForm["marketplaceInfoList"]>(AD_SOURCE_FIELDS.DEMAND.name);
    const adSourceDemandAcquisitionCost = Form.useWatch<AdSourcesForm["demandAcquisitionCost"]>(
        AD_SOURCE_FIELDS.DEMAND_ACQUISITION_COST.name
    );
    const adSourceDemandAcquisitionCostModelId = Form.useWatch<AdSourcesForm["demandAcquisitionCostModel"]>(
        AD_SOURCE_FIELDS.DEMAND_ACQUISITION_COST_MODEL.name
    )?.id;
    const adSourceDemandAcquisitionCostNum = !adSourceDemandAcquisitionCost ? 0 : Number(adSourceDemandAcquisitionCost);
    const adSourceCpm = Form.useWatch<AdSourcesForm["bookingPrice"]>(AD_SOURCE_FIELDS.OVERRIDE_FLOOR_CPM.name);
    const adSourceName = Form.useWatch<AdSourcesForm["name"]>(AD_SOURCE_FIELDS.NAME.name);
    const isVisible = isEnrichmentCostEligible(adSourceType?.id);

    const {
        adSourceEc,
        adSourceLevelAudiences,
        adSourceLevelSegments,
        dealLevelValues,
        hasEnrichmentCostTargeting,
        isDrawerOpen,
        isRangedCost,
        selectedMarketplace,
        setIsDrawerOpen,
        setSelectedMarketplace,
        shouldUseAdSourceValues,
    } = useAdSourceEnrichmentCost({
        adSourceDemandAcquisitionCost: adSourceDemandAcquisitionCostNum,
        adSourceDemandAcquisitionCostModelId,
        adSourceCpm,
        adSourceFloorTypeId: Number(adSourceFloorType?.id || 0),
        adSourceCurrencyCode,
        adSourceType,
        adSourceLevelTargeting,
        marketplaceInfoList,
    });

    if (!isVisible) {
        return null;
    }

    return (
        <EnrichmentCostSectionInner
            adSourceCpm={adSourceCpm}
            adSourceCurrency={adSourceCurrencyCode || DEFAULT_ENRICHMENT_COST_CURRENCY_CODE}
            adSourceDemandAcquisitionCost={adSourceDemandAcquisitionCost}
            adSourceEc={adSourceEc}
            adSourceFloorType={adSourceFloorType}
            adSourceLevelAudiences={adSourceLevelAudiences}
            adSourceLevelSegments={adSourceLevelSegments}
            adSourceName={adSourceName}
            adSourceType={adSourceType}
            dealLevelValues={dealLevelValues}
            demandAcquisitionCostField={<DemandAcquisitionCostField />}
            adSourceDemandAcquisitionCostModelId={adSourceDemandAcquisitionCostModelId}
            hasEnrichmentCostTargeting={hasEnrichmentCostTargeting}
            isDemandAcquisitionCostEnabled={isDemandAcquisitionEnabled}
            isDrawerOpen={isDrawerOpen}
            isRangedCost={isRangedCost}
            selectedDealLevelAudiences={getAudiencesFromTargetingForm(
                selectedMarketplace?.targeting.map(targetingToTargetingBlock) || []
            )}
            selectedDealLevelSegments={getSegmentsFromTargetingForm(
                selectedMarketplace?.targeting.map(targetingToTargetingBlock) || []
            )}
            selectedDealCurrencyCode={
                selectedMarketplace?.deal.currencyType?.code || DEFAULT_ENRICHMENT_COST_CURRENCY_CODE
            }
            selectedDealName={selectedMarketplace?.deal.name || ""}
            selectedDealRate={Number(selectedMarketplace?.deal.rate) || 0}
            setIsDrawerOpen={setIsDrawerOpen}
            setSelectedDeal={(dealId) => {
                setSelectedMarketplace(
                    marketplaceInfoList?.find((marketplace) => String(marketplace.deal.id) === dealId) || null
                );
                setIsDrawerOpen(true);
            }}
            shouldUseAdSourceValues={shouldUseAdSourceValues}
        />
    );
};
