import { FC, useMemo } from "react";
import { Col, Row, Space, Table } from "antd";
import { merge } from "lodash";
import { format, formatNumber } from "@rubicon/utils";
import { HelpTooltip, StyledLink, getPacingStatusAttributes } from "@app/core/components";
import { WaterfallPacing } from "@app/core/services/console/waterfallPacing";
import { useSeatAdSourcesPacingTable } from "@app/features/seatAdSources/SeatAdSourcesPacingPage/SeatAdSourcesPacingTable/useSeatAdSourcesPacingTable";
import { AdSourcesPacingHelpKeys } from "../../constants";
import { useSeatAdSourcesDetailsDrawer } from "../../SeatAdSourcesDetailsDrawer/useSeatAdSourcesDetailsDrawer";
import moment from "moment-timezone";
import { YEAR_MONTH_DAY } from "@app/core/components/constants";

const { DEFAULT_DASHES } = format.constants;

const COLUMN_KEY = {
    AD_SOURCE_NAME: "adSourceName",
    TIME_ZONE: "timeZone",
    START_DATE: "startDate",
    END_DATE: "endDate",
    BOOKED_IMPS: "bkVolume",
    DAYS_ELAPSED: "daysElapsed",
    IMPS_TO_DATE: "recordedTotalImpCount",
    AVG_IMPS_PER_DAY: "averageImpsPerDay",
    TRIES: "yesTries",
    FILLS: "yesFills",
    IMPS: "yesImpressions",
    PACING_DELTA: "pacingDelta",
    DAYS_REMAINING: "daysRemaining",
    IMPS_REMAINING: "impsRemaining",
    IMPS_PER_DAY_TO_FULFILL: "impsPerDayToFulfill",
    DAILY_CAPS: "dailyCapValue",
    IMPS_TODAY: "todayImpCount",
    DAILY_IMPS_REMAINING: "dailyImpsRemaining",
} as const;

const tintedBackground = "#F0E7E4";
const darkBorder = "1px solid #2C2C2C";

const cellStyles = {
    tinted: { background: tintedBackground } as const,
    borderTop: { borderTop: darkBorder } as const,
    borderLeft: { borderLeft: darkBorder } as const,
    borderRight: { borderRight: darkBorder } as const,
    borderBottom: { borderBottom: darkBorder } as const,
} as const;

const compileCellStyles = (styles: (keyof typeof cellStyles)[]) =>
    styles.reduce((acc, style) => merge(acc, { style: cellStyles[style] }), { style: {} });

const tintedBorderLeftRight = () => compileCellStyles(["tinted", "borderLeft", "borderRight"]);
const tintedBorderBottom = () => compileCellStyles(["tinted", "borderBottom"]);
const tintedBorderBottomLeft = () => compileCellStyles(["tinted", "borderBottom", "borderLeft"]);
const tintedBorderBottomRight = () => compileCellStyles(["tinted", "borderBottom", "borderRight"]);
const borderBottom = () => compileCellStyles(["borderBottom"]);
const borderLeft = () => compileCellStyles(["borderLeft"]);
const borderRight = () => compileCellStyles(["borderRight"]);
const formatDate = (date: string | undefined | null) =>
    moment(date).isValid() && typeof date === "string" ? moment(date).format(YEAR_MONTH_DAY) : date || DEFAULT_DASHES;

const getDefaultColumns = () => [
    {
        title: "Booking Details",
        children: [
            {
                dataIndex: COLUMN_KEY.AD_SOURCE_NAME,
                title: (
                    <HelpTooltip
                        helpKeyList={AdSourcesPacingHelpKeys}
                        helpKey={AdSourcesPacingHelpKeys.AdSourceName}
                        popover={true}
                    >
                        Ad Source Name
                    </HelpTooltip>
                ),
                onHeaderCell: borderBottom,
            },
            {
                dataIndex: COLUMN_KEY.TIME_ZONE,
                title: (
                    <HelpTooltip
                        helpKeyList={AdSourcesPacingHelpKeys}
                        helpKey={AdSourcesPacingHelpKeys.TimeZone}
                        popover={true}
                    >
                        Time Zone
                    </HelpTooltip>
                ),
                onHeaderCell: borderBottom,
                render: format.asSelf,
            },
            {
                dataIndex: COLUMN_KEY.START_DATE,
                title: (
                    <HelpTooltip
                        helpKeyList={AdSourcesPacingHelpKeys}
                        helpKey={AdSourcesPacingHelpKeys.StartDate}
                        popover={true}
                    >
                        Start Date
                    </HelpTooltip>
                ),
                onHeaderCell: borderBottom,
                render: formatDate,
                width: 88,
            },
            {
                dataIndex: COLUMN_KEY.END_DATE,
                title: (
                    <HelpTooltip
                        helpKeyList={AdSourcesPacingHelpKeys}
                        helpKey={AdSourcesPacingHelpKeys.EndDate}
                        popover={true}
                    >
                        End Date
                    </HelpTooltip>
                ),
                onHeaderCell: borderBottom,
                render: formatDate,
                width: 88,
            },
            {
                dataIndex: COLUMN_KEY.BOOKED_IMPS,
                title: (
                    <HelpTooltip
                        helpKeyList={AdSourcesPacingHelpKeys}
                        helpKey={AdSourcesPacingHelpKeys.BookedImps}
                        popover={true}
                    >
                        Booked Imps
                    </HelpTooltip>
                ),
                onHeaderCell: borderBottom,
                render: formatNumber.asWhole,
            },
        ],
    },
    {
        title: "Delivered",
        onHeaderCell: tintedBorderLeftRight,
        children: [
            {
                dataIndex: COLUMN_KEY.DAYS_ELAPSED,
                title: "Days Elapsed",
                onHeaderCell: tintedBorderBottomLeft,
                onCell: borderLeft,
                render: formatNumber.asWhole,
            },
            {
                dataIndex: COLUMN_KEY.IMPS_TO_DATE,
                title: "Imps to Date",
                onHeaderCell: tintedBorderBottom,
                render: formatNumber.asWhole,
            },
            {
                dataIndex: COLUMN_KEY.AVG_IMPS_PER_DAY,
                title: "Avg Imps per Day",
                onHeaderCell: tintedBorderBottomRight,
                onCell: borderRight,
                render: formatNumber.asWhole,
            },
        ],
    },
    {
        title: "Yesterday",
        children: [
            {
                dataIndex: COLUMN_KEY.TRIES,
                title: (
                    <HelpTooltip
                        helpKeyList={AdSourcesPacingHelpKeys}
                        helpKey={AdSourcesPacingHelpKeys.Tries}
                        popover={true}
                    >
                        Tries
                    </HelpTooltip>
                ),
                onHeaderCell: borderBottom,
                render: formatNumber.asWhole,
            },
            {
                dataIndex: COLUMN_KEY.FILLS,
                title: (
                    <HelpTooltip
                        helpKeyList={AdSourcesPacingHelpKeys}
                        helpKey={AdSourcesPacingHelpKeys.Fills}
                        popover={true}
                    >
                        Fills
                    </HelpTooltip>
                ),
                onHeaderCell: borderBottom,
                render: formatNumber.asWhole,
            },
            {
                dataIndex: COLUMN_KEY.IMPS,
                title: (
                    <HelpTooltip
                        helpKeyList={AdSourcesPacingHelpKeys}
                        helpKey={AdSourcesPacingHelpKeys.Imps}
                        popover={true}
                    >
                        Imps
                    </HelpTooltip>
                ),
                onHeaderCell: borderBottom,
                render: formatNumber.asWhole,
            },
        ],
    },
    {
        title: "Remaining",
        onHeaderCell: tintedBorderLeftRight,
        children: [
            {
                dataIndex: COLUMN_KEY.PACING_DELTA,
                title: "Pacing Delta",
                render: (pacingProgress) => {
                    return formatNumber.asPercent(pacingProgress?.pacingDeltaPercentage);
                },
                onHeaderCell: tintedBorderBottomLeft,
                onCell: ({ pacingDelta }) => {
                    const { cellColor, cellTextColor } = getPacingStatusAttributes(pacingDelta?.pacingStatus?.id);
                    return merge({}, borderLeft(), {
                        style: {
                            color: cellTextColor,
                            background: cellColor,
                        },
                    });
                },
            },
            {
                dataIndex: COLUMN_KEY.DAYS_REMAINING,
                title: "Days Remaining",
                onHeaderCell: tintedBorderBottom,
                render: formatNumber.asWhole,
            },
            {
                dataIndex: COLUMN_KEY.IMPS_REMAINING,
                title: "Imps Remaining",
                onHeaderCell: tintedBorderBottom,
                render: formatNumber.asWhole,
            },
            {
                dataIndex: COLUMN_KEY.IMPS_PER_DAY_TO_FULFILL,
                title: "Imps per Day To Fulfill",
                onHeaderCell: tintedBorderBottomRight,
                onCell: borderRight,
                render: formatNumber.asWhole,
            },
        ],
    },
    {
        title: "Daily Cap Pacing",
        children: [
            {
                dataIndex: COLUMN_KEY.DAILY_CAPS,
                title: "Daily Caps",
                onHeaderCell: borderBottom,
                render: formatNumber.asWhole,
            },
            {
                dataIndex: COLUMN_KEY.IMPS_TODAY,
                title: "Imps Today",
                onHeaderCell: borderBottom,
                render: formatNumber.asWhole,
            },
            {
                dataIndex: COLUMN_KEY.DAILY_IMPS_REMAINING,
                title: "Daily Imps Remaining",
                onHeaderCell: borderBottom,
                render: formatNumber.asWhole,
            },
        ],
    },
];

const getTableItems = (data: WaterfallPacing[], openDetailsDrawer: (adSourceId: number) => void) =>
    data
        ?.filter((adSourcePacing) => adSourcePacing)
        ?.map((adSourcePacing) => ({
            key: adSourcePacing.entityId,
            [COLUMN_KEY.AD_SOURCE_NAME]: (
                <StyledLink onClick={() => openDetailsDrawer(adSourcePacing.adSourceId)}>
                    {adSourcePacing.adSourceName}
                </StyledLink>
            ),
            [COLUMN_KEY.TIME_ZONE]: adSourcePacing.timeZone,
            [COLUMN_KEY.START_DATE]: adSourcePacing.startDate,
            [COLUMN_KEY.END_DATE]: adSourcePacing.endDate,
            [COLUMN_KEY.BOOKED_IMPS]: adSourcePacing.bkVolume,
            [COLUMN_KEY.DAYS_ELAPSED]: adSourcePacing.daysElapsed,
            [COLUMN_KEY.IMPS_TO_DATE]: adSourcePacing.recordedTotalImpCount,
            [COLUMN_KEY.AVG_IMPS_PER_DAY]: adSourcePacing.averageImpsPerDay,
            [COLUMN_KEY.TRIES]: adSourcePacing.yesTries,
            [COLUMN_KEY.FILLS]: adSourcePacing.yesFills,
            [COLUMN_KEY.IMPS]: adSourcePacing.yesImpressions,
            [COLUMN_KEY.PACING_DELTA]: adSourcePacing.pacingProgress,
            [COLUMN_KEY.DAYS_REMAINING]: adSourcePacing.daysRemaining,
            [COLUMN_KEY.IMPS_REMAINING]: adSourcePacing.impsRemaining,
            [COLUMN_KEY.IMPS_PER_DAY_TO_FULFILL]: adSourcePacing.impsPerDayToFulfill,
            [COLUMN_KEY.DAILY_CAPS]: adSourcePacing.dailyCapValue,
            [COLUMN_KEY.IMPS_TODAY]: adSourcePacing.todayImpCount,
            [COLUMN_KEY.DAILY_IMPS_REMAINING]: adSourcePacing.dailyImpsRemaining,
        }));

export const SeatAdSourcesPacingTable: FC = () => {
    const { data, isFetching, total, current, pageSize, pageSizeOptions, onPaginationChange } =
        useSeatAdSourcesPacingTable();
    const { openSeatAdSourceDrawerPacing } = useSeatAdSourcesDetailsDrawer();

    const openAdSourceDrawer = (adSourceId: number) => {
        openSeatAdSourceDrawerPacing(adSourceId);
    };

    const defaultColumns = useMemo(() => getDefaultColumns(), []);

    return (
        <Space
            data-sdet="seat-ad-sources-pacing-summary-table"
            direction="vertical"
            size="middle"
            style={{ width: "100%" }}
        >
            <Row>
                <Col span={24}>
                    <Table
                        size="small"
                        columns={defaultColumns}
                        dataSource={getTableItems(data, openAdSourceDrawer)}
                        loading={isFetching}
                        pagination={{
                            total,
                            current,
                            pageSize,
                            pageSizeOptions,
                            onChange: onPaginationChange,
                            showSizeChanger: true,
                        }}
                        showSorterTooltip={false}
                        scroll={{ x: 1750 }}
                        bordered
                    />
                </Col>
            </Row>
        </Space>
    );
};
