import { FC } from "react";
import { Button, Card, Col, Collapse, Form, Layout, Modal, Row, Typography } from "antd";
import { PageHeader } from "@ant-design/pro-layout";
import { useHistory, useParams } from "react-router-dom";
import { BlockedIndustriesSelect, Loading, SectionTitle } from "@app/core/components";
import { useInventoryAdvancedBrandSafetyPageForm } from "./useInventoryAdvancedBrandSafetyPageForm";
import {
    AdditionalAdvertiserDomainsField,
    BlockIABCategoriesField,
    BrandSafetyNameField,
    BrandSafetyTypeField,
    IABCategoryBlockExceptionsField,
    ReusableAdvertiserDomainsField,
} from "./BrandSafetyFormFields";
import { CreateTargeting } from "@app/features/targeting";
import { css } from "@emotion/css";
import { TargetingFormKeys } from "@app/features/targeting/constants";
import { AffixFormButtonsBar } from "@app/features/inventory/components/AffixFormButtonsBar";
import { advancedBrandSafetyHelpKeys } from "./helpkeys";
import { HelpKeysDrawer } from "@app/core/components/HelpKeysDrawer";
import { classNameCardBody } from "../../InventoryHealthDetailsPages/constants";
import { BRAND_SAFETY_BLOCK_TYPE } from "@app/features/inventory/InventoryBrandSafety/InventoryAdvancedBrandSafetyPage/constants";
import { BrandSafetyDeleteModal } from "@app/features/controls/brandSafety/BrandSafetyDeleteModal";

export enum InventoryAdvancedBrandSafetyPageFormMode {
    CREATE = "create",
    EDIT = "edit",
}

export enum InventoryAdvancedBrandSafetyPageFormSource {
    INVENTORY = "inventory",
    CONTROLS = "controls",
}

interface Props {
    mode: InventoryAdvancedBrandSafetyPageFormMode;
    source?: InventoryAdvancedBrandSafetyPageFormSource;
}

export const InventoryAdvancedBrandSafetyPageForm: FC<Props> = ({
    mode,
    source = InventoryAdvancedBrandSafetyPageFormSource.INVENTORY,
}) => {
    const { id } = useParams<{ id?: string }>();
    const history = useHistory();
    const {
        title,
        form,
        type,
        isLoading,
        isDeleting,
        handleSubmit,
        confirmModal,
        isSubmitting,
        handleCancel,
        initialFormData,
        handleCloseModal,
        deleteModalProps,
        handleDeleteOpen,
        handleConfirmModal,
        handleChangeBrandSafetyType,
        handleChangeReusableAdvertiserDomains,
    } = useInventoryAdvancedBrandSafetyPageForm(mode, Number(id), source);
    const { ...deleteProps } = deleteModalProps;

    if (isLoading) return <Loading />;

    return (
        <Layout>
            <PageHeader title={title} onBack={history.goBack} />
            <Layout.Content
                className={css`
                    padding: 0;
                `}
            >
                <Form
                    form={form}
                    layout="vertical"
                    initialValues={initialFormData}
                    scrollToFirstError={{ behavior: "smooth", block: "center" }}
                >
                    <Collapse defaultActiveKey={[1, 2]} bordered collapsible="header">
                        <Collapse.Panel
                            key={1}
                            header={<SectionTitle title={advancedBrandSafetyHelpKeys.general.label} />}
                            extra={
                                <HelpKeysDrawer
                                    panelLabel={advancedBrandSafetyHelpKeys.general.label}
                                    definitionsFields={advancedBrandSafetyHelpKeys.general.helpKeys}
                                />
                            }
                        >
                            <Card bordered={false} className={classNameCardBody}>
                                <Row>
                                    <Col xs={24} sm={24} md={24} lg={6}>
                                        <BrandSafetyNameField isSubmitting={isSubmitting} />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={24} sm={24} md={24} lg={14}>
                                        <BrandSafetyTypeField
                                            isSubmitting={isSubmitting}
                                            onChange={handleChangeBrandSafetyType}
                                        />
                                    </Col>
                                </Row>
                                {type === BRAND_SAFETY_BLOCK_TYPE.value && (
                                    <Row>
                                        <Col xs={24} sm={24} md={24} lg={10}>
                                            <BlockIABCategoriesField isSubmitting={isSubmitting} />
                                        </Col>
                                    </Row>
                                )}
                                {type === BRAND_SAFETY_BLOCK_TYPE.value && (
                                    <Row>
                                        <Col xs={24} sm={24} md={24} lg={10}>
                                            <IABCategoryBlockExceptionsField isSubmitting={isSubmitting} />
                                        </Col>
                                    </Row>
                                )}
                                {type === BRAND_SAFETY_BLOCK_TYPE.value && (
                                    <Row>
                                        <Col xs={24} sm={24} md={24} lg={10}>
                                            <BlockedIndustriesSelect disabled={isSubmitting} />
                                        </Col>
                                    </Row>
                                )}
                                <Row>
                                    <Col xs={24} sm={24} md={24} lg={10}>
                                        <ReusableAdvertiserDomainsField
                                            type={type}
                                            isSubmitting={isSubmitting}
                                            onChange={handleChangeReusableAdvertiserDomains}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={24} sm={24} md={24} lg={10}>
                                        <AdditionalAdvertiserDomainsField isSubmitting={isSubmitting} />
                                    </Col>
                                </Row>
                            </Card>
                        </Collapse.Panel>
                        <Collapse.Panel
                            key={2}
                            header={<SectionTitle title={advancedBrandSafetyHelpKeys.targeting.label} />}
                            extra={
                                <HelpKeysDrawer
                                    panelLabel={advancedBrandSafetyHelpKeys.targeting.label}
                                    definitionsFields={advancedBrandSafetyHelpKeys.targeting.helpKeys}
                                />
                            }
                        >
                            <Card bordered={false} className={classNameCardBody}>
                                <Row>
                                    <Col span={24}>
                                        <CreateTargeting formKey={TargetingFormKeys.BrandSafety} />
                                    </Col>
                                </Row>
                            </Card>
                        </Collapse.Panel>
                    </Collapse>

                    <AffixFormButtonsBar
                        isDeleteButtonShown={mode === InventoryAdvancedBrandSafetyPageFormMode.EDIT}
                        onDelete={handleDeleteOpen}
                        loading={isSubmitting}
                        disabled={isSubmitting}
                        affixStyle={{ margin: "2rem 0 0" }}
                        onSubmit={handleConfirmModal}
                        onCancel={handleCancel}
                        submitButtonTitle="Save"
                    />
                </Form>
                <BrandSafetyDeleteModal {...deleteProps} isDeleting={isDeleting} />
                <Modal
                    open={confirmModal}
                    title="Warning"
                    onCancel={handleCloseModal}
                    onOk={handleSubmit}
                    footer={[
                        <Button key="cancel" onClick={handleCloseModal} disabled={isSubmitting}>
                            No
                        </Button>,
                        <Button key="confirm" type="primary" onClick={handleSubmit} loading={isSubmitting}>
                            Yes
                        </Button>,
                    ]}
                >
                    {mode === InventoryAdvancedBrandSafetyPageFormMode.CREATE ? (
                        <Typography.Text>
                            Are you sure you want to apply brand safety to <Typography.Text strong>ALL</Typography.Text>{" "}
                            inventory?
                        </Typography.Text>
                    ) : (
                        <Typography.Text>Are you sure you want to apply changes?</Typography.Text>
                    )}
                </Modal>
            </Layout.Content>
        </Layout>
    );
};
