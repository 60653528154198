import { Button, Dropdown, MenuProps, Space } from "antd";
import { DownOutlined, LoadingOutlined } from "@ant-design/icons";
import { SnapshotsDrawer } from "../SnapshotsDrawer";
import { useSnapshotsDrawer } from "../SnapshotsDrawer/useSnapshotsDrawers";
import { CreateSnapshotModal } from "../CreateSnapshotModal";
import { useCreateSnapshotModal } from "../CreateSnapshotModal/useCreateSnapshotModal";
import { AdSourceStat } from "@app/features/seatAdSources/data";

interface Props {
    adSourceId: number;
    adSourceName: string;
    adSourceHistoryStats: AdSourceStat[];
}

export const SnapshotsControls = ({ adSourceId, adSourceName, adSourceHistoryStats }: Props) => {
    const { isOpen, handleCloseDrawer, handleOpenDrawer } = useSnapshotsDrawer();
    const { open, handleOpenModal, handleCloseModal, onSubmit, isLoading, form } = useCreateSnapshotModal(adSourceId);
    const items: MenuProps["items"] = [
        {
            label: "Take Snapshot",
            key: "takeSnapshot",
            onClick: handleOpenModal,
        },
        {
            label: "View Snapshots",
            key: "viewSnapshots",
            onClick: handleOpenDrawer,
        },
    ];
    return (
        <Space size="middle">
            <CreateSnapshotModal
                open={open}
                handleCloseModal={handleCloseModal}
                onSubmit={onSubmit}
                isLoading={isLoading}
                form={form}
            />
            <Dropdown menu={{ items }} disabled={isLoading}>
                <Button>
                    <Space>
                        Snapshot
                        {isLoading ? <LoadingOutlined spin /> : <DownOutlined />}
                    </Space>
                </Button>
            </Dropdown>
            {/* TODO: Commented out until download functionality is implemented */}
            {/* <Button icon={<DownloadOutlined />} /> */}
            <SnapshotsDrawer
                isOpen={isOpen}
                onClose={handleCloseDrawer}
                adSourceId={adSourceId}
                adSourceName={adSourceName}
                adSourceHistoryStats={adSourceHistoryStats}
            />
        </Space>
    );
};
