import { PageHeader } from "@ant-design/pro-layout";
import { useSeatAuthContext } from "@app/core/auth";
import { HelpKeysDrawerContent } from "@app/core/components/HelpKeysDrawer";
import { ROUTE_FORMATTERS } from "@app/core/routing";
import {
    TargetingCreatePayload,
    TargetingIncludeExclude,
    useCreateSeatReusableTargetingMutation,
} from "@app/core/services";
import { targetingToTargetingBlocks } from "@app/features/targeting";
import { TargetingFormKeys } from "@app/features/targeting/constants";
import { CreateTargetingBlockContent } from "@app/features/targeting/CreateTargeting/CreateTargetingBlockContent";
import {
    getSelectedTargetingDimensionsCount,
    getSelectedTargetingDimensionsCountExcludes,
} from "@app/features/targeting/ReadOnlyTargeting/ReadOnlyTargetingBlocks";
import { useTargetingForm } from "@app/features/targeting/useTargetingForm";
import { Layout, notification } from "antd";
import { useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { TargetingDrawer } from "../TargetingDrawer";
import { useTargetingDetailsDefinitionDrawer } from "../TargetingDrawer/useTargetingDefinitionDrawer";
import { TargetingForm } from "../TargetingForm";
import { TargetingFormValues, TARGETING_DIMENSION_MESSAGE } from "../TargetingForm/constants";

export const TargetingCreatePage = () => {
    const { seatId } = useParams<{ seatId: string }>();
    const { context } = useSeatAuthContext();
    const history = useHistory();

    const [createTargetng, { isLoading }] = useCreateSeatReusableTargetingMutation();

    const { showDefinition, definitions, title, handleShowDefinition, handleCloseDefinition } =
        useTargetingDetailsDefinitionDrawer();

    const {
        addTargetingBlock,
        addTargetingBlockDimension,
        addTargetingBlockDimensionRule,
        addTargetingBlockDimensionRuleGroup,
        copyTargetingBlock,
        copyTargetingBlockDimension,
        deleteTargetingBlock,
        deleteTargetingBlockDimension,
        deleteTargetingBlockDimensionRule,
        downloadTargetingBlockDimension,
        setTargetingBlockDimensionCondition,
        setTargetingBlockDimensionMode,
        setTargetingBlockDimensionOperator,
        setTargetingBlockDimensionPmpCondition,
        setTargetingBlockDimensionRuleCondition,
        setTargetingBlockDimensionRuleGroup,
        setTargetingBlockDimensionRuleId,
        setTargetingBlockDimensionRuleOperator,
        setTargetingBlockDimensionRuleValue,
        setTargetingBlockDimensionTimeZoneMode,
        setTargetingBlockDimensionValues,
        setTargetingBlockIsReusable,
        targetingFormsByFormKey,
        targetingPayloadsByFormKey,
    } = useTargetingForm(TargetingFormKeys.Controls);
    const targetingForm = targetingFormsByFormKey[TargetingFormKeys.Controls];
    const targetingBlocks = targetingToTargetingBlocks(targetingForm.targetingBlocks);
    const usedDimensionsTargetingBlocks = targetingBlocks.map((block) => block.usedDimensions);
    const validationTargetingBlocks = targetingToTargetingBlocks(targetingForm.validationTargetingBlocks);
    const usedDimensionsValidationTargetingBlocks = validationTargetingBlocks.map((block) => block.usedDimensions);

    const handleErrorTargetingNotification = (message: string) => {
        notification.error({ message });
    };

    const handleValidationDimensionTargeting = (targetingBlock: TargetingCreatePayload | undefined): boolean => {
        if (!targetingBlock) {
            return false;
        }

        const targetingIncludes = targetingBlock.include;
        const targetingExcludes = targetingBlock.exclude;

        const includesDimensionCount = getSelectedTargetingDimensionsCount(
            targetingIncludes as TargetingIncludeExclude
        );

        const excludesDimensionCount = getSelectedTargetingDimensionsCountExcludes(
            targetingExcludes as TargetingIncludeExclude
        );

        if (!includesDimensionCount && !excludesDimensionCount) {
            return false;
        }

        return true;
    };

    useEffect(() => {
        addTargetingBlock(TargetingFormKeys.Controls);
    }, [addTargetingBlock]);

    const handleSubmit = async (values: TargetingFormValues) => {
        const [newTargetingBlock] = targetingPayloadsByFormKey[TargetingFormKeys.Controls];

        if (handleValidationDimensionTargeting(newTargetingBlock)) {
            try {
                const res = await createTargetng({
                    seatId: Number(seatId),
                    body: {
                        ...newTargetingBlock,
                        name: values.name?.trim() as string,
                    },
                }).unwrap();
                notification.success({ message: `Targeting ${res.name} created successfully` });
                history.push(ROUTE_FORMATTERS.SEAT_CONTROLS_TARGETING_DETAILS(seatId, res.id));
            } catch (error) {
                handleErrorTargetingNotification(`Targeting operation has failed: ${error?.data?.errorDescription}`);
            }
        } else {
            handleErrorTargetingNotification(TARGETING_DIMENSION_MESSAGE);
        }
    };

    return (
        <Layout data-sdet="targeting-create-page">
            <PageHeader
                title="Create Targeting"
                onBack={() => history.push(ROUTE_FORMATTERS.SEAT_CONTROLS_TARGETING(seatId))}
            />
            <TargetingForm
                initialValues={{
                    parent: context?.name,
                    name: null,
                }}
                isSubmitting={isLoading}
                handleDefinition={handleShowDefinition}
                onFinish={handleSubmit}
                onCancel={() => history.push(ROUTE_FORMATTERS.SEAT_CONTROLS_TARGETING(seatId))}
            >
                {targetingBlocks.length ? (
                    <CreateTargetingBlockContent
                        formKey={TargetingFormKeys.Controls}
                        index={0}
                        onChangeDimensionCondition={setTargetingBlockDimensionCondition}
                        onChangeDimensionMode={setTargetingBlockDimensionMode}
                        onChangeDimensionOperator={setTargetingBlockDimensionOperator}
                        onChangeDimensionPmpCondition={setTargetingBlockDimensionPmpCondition}
                        onChangeDimensionRuleCondition={setTargetingBlockDimensionRuleCondition}
                        onChangeDimensionRuleGroup={setTargetingBlockDimensionRuleGroup}
                        onChangeDimensionRuleId={setTargetingBlockDimensionRuleId}
                        onChangeDimensionRuleOperator={setTargetingBlockDimensionRuleOperator}
                        onChangeDimensionRuleValue={setTargetingBlockDimensionRuleValue}
                        onChangeDimensionTimeZoneMode={setTargetingBlockDimensionTimeZoneMode}
                        onChangeDimensionValues={setTargetingBlockDimensionValues}
                        onChangeIsReusable={setTargetingBlockIsReusable}
                        onClickAddDimension={addTargetingBlockDimension}
                        onClickAddDimensionRule={addTargetingBlockDimensionRule}
                        onClickAddDimensionRuleGroup={addTargetingBlockDimensionRuleGroup}
                        onClickCopy={copyTargetingBlock}
                        onClickDelete={deleteTargetingBlock}
                        onClickDeleteDimension={deleteTargetingBlockDimension}
                        onClickDeleteDimensionRule={deleteTargetingBlockDimensionRule}
                        onClickDownload={downloadTargetingBlockDimension}
                        onClickDuplicateDimension={copyTargetingBlockDimension}
                        targetingBlock={targetingBlocks[0]}
                        usedDimensionsTargetingBlocks={usedDimensionsTargetingBlocks}
                        usedDimensionsValidationTargetingBlocks={usedDimensionsValidationTargetingBlocks}
                    />
                ) : null}
            </TargetingForm>
            <TargetingDrawer open={showDefinition} title={title} closeDrawer={handleCloseDefinition}>
                <HelpKeysDrawerContent definitionsFields={definitions} />
            </TargetingDrawer>
        </Layout>
    );
};
