import { useEffect, useMemo, useState } from "react";
import { LabeledValue } from "antd/es/select";
import debounce from "lodash.debounce";
import { useLoadOnScroll } from "@app/core/components";
import { FILTER_INPUT_DEBOUNCE_TIME } from "@app/core/components/constants";
import { useGetGlobalNetworksQuery } from "@app/core/services";

const MAX_PAGE_RESULTS = 200;
const MAX_PAGE_RESULTS_WITH_SEARCH = MAX_PAGE_RESULTS * 10;

interface UseDemandSelect {
    options: LabeledValue[];
    isFetching: boolean;
    search: string;
    hasMore: boolean;
    loadMore: () => void;
    onChangeSearch: (search: string) => void;
    onBlur: () => void;
}

export const useDemandSelect = (): UseDemandSelect => {
    const [pageByKeyword, setPageByKeyword] = useState({
        "": 1,
    });
    const [keyword, setKeyword] = useState("");

    const debouncedKeyword = useMemo(
        () => debounce((value: string) => setKeyword(value), FILTER_INPUT_DEBOUNCE_TIME),
        [setKeyword]
    );

    const [search, setSearch] = useState("");

    const max = search ? MAX_PAGE_RESULTS_WITH_SEARCH : MAX_PAGE_RESULTS;

    const { data, isFetching, originalArgs } = useGetGlobalNetworksQuery({
        keyword,
        max,
        page: pageByKeyword[keyword] || 1,
    });

    const {
        options: rawOptions,
        hasMore,
        loadMore,
    } = useLoadOnScroll(
        data,
        isFetching,
        originalArgs?.keyword || "",
        originalArgs?.page || 1,
        (data?.length ?? 0) >= max,
        () =>
            setPageByKeyword((prev) => {
                const currentPage = prev[keyword] || 1;
                return {
                    ...prev,
                    [keyword]: currentPage + 1,
                };
            })
    );

    const options = useMemo<LabeledValue[]>(
        () =>
            [...(rawOptions || [])]
                .filter(({ name }) => name.toLowerCase().includes(search.toLowerCase()))
                .map(({ id, name }) => ({
                    label: name,
                    value: id,
                }))
                .sort((a, b) => a.label.localeCompare(b.label, "en", { sensitivity: "base" })),
        [rawOptions, search]
    );

    const onChangeSearch = (search: string) => {
        setSearch(search);
        debouncedKeyword(search);
    };

    const onBlur = () => setSearch("");

    useEffect(() => () => debouncedKeyword.cancel(), [debouncedKeyword]);

    return {
        options,
        isFetching,
        search,
        hasMore,
        loadMore,
        onChangeSearch,
        onBlur,
    };
};
