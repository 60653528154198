import { FC } from "react";
import { Col, Form, Input, Row, Space } from "antd";
import { WarningOutlined } from "@ant-design/icons";
import { CurrencyInput } from "@app/core/components";
import { gold6 } from "@rubicon/antd-components";
import { useGetEnrichmentCostModelsQuery } from "@app/core/services";
import {
    AD_SOURCE_FIELDS,
    DEFAULT_ENRICHMENT_COST_CURRENCY_CODE,
    DemandAcquisitionCostModelId,
    VERTICAL_LAYOUT_FIX,
} from "@app/features/seatAdSources/constants";
import { SelectWithValue } from "../../components";
import {
    getDemandAcquisitionCostFormValue,
    getDemandAcquisitionCostHelpText,
    getDemandAcquisitionCostLabel,
} from "./utils";
import { useDemandAcquisitionCostUtils } from "./useDemandAcquisitionCostUtils";

export const DemandAcquisitionCostField: FC = () => {
    const { isDemandAcquisitionEnabled, isDemandAcquisitionReadonly } = useDemandAcquisitionCostUtils();
    const { data: enrichmentCostModels } = useGetEnrichmentCostModelsQuery();

    const dac = Form.useWatch<string | null>(AD_SOURCE_FIELDS.DEMAND_ACQUISITION_COST.name);
    const dacModelId = Form.useWatch(AD_SOURCE_FIELDS.DEMAND_ACQUISITION_COST_MODEL.name)?.id;

    if (!isDemandAcquisitionEnabled) {
        return null;
    }

    const dacLabel = getDemandAcquisitionCostLabel(dacModelId);
    const dacHelpText = getDemandAcquisitionCostHelpText(dac, dacModelId);
    const isDacModelPercent = dacModelId === DemandAcquisitionCostModelId.PERCENTAGE;

    return (
        <div data-sdet="demand-acquisition-cost-fields">
            <Row>
                <Col>
                    <Form.Item
                        label={AD_SOURCE_FIELDS.DEMAND_ACQUISITION_COST.label}
                        tooltip={{
                            // remove this once percent EC is fixed
                            overlayInnerStyle: { width: "30rem" },
                            title: (
                                <p>
                                    Percent Demand Acquisition Cost is not available to edit at this time. Please change
                                    to Fixed or contact your Account Manager.
                                </p>
                            ),
                            icon: <WarningOutlined style={{ color: gold6 }} />,
                        }}
                        {...VERTICAL_LAYOUT_FIX}
                    >
                        <Space.Compact>
                            <Form.Item name={AD_SOURCE_FIELDS.DEMAND_ACQUISITION_COST_MODEL.name}>
                                <SelectWithValue
                                    style={{
                                        width: "120px",
                                    }}
                                    data-sdet="ad-source-demand-acquisition-cost-model"
                                    disabled={isDemandAcquisitionReadonly || !isDacModelPercent} // temporarily disabled (if percent) until percent EC is fixed
                                    options={enrichmentCostModels}
                                    placeholder="Select..."
                                    fieldAsLabel={["name"]}
                                    fieldAsValue={["id"]}
                                />
                            </Form.Item>
                            {isDacModelPercent ? (
                                <Form.Item
                                    name={AD_SOURCE_FIELDS.DEMAND_ACQUISITION_COST.name}
                                    data-sdet="ad-source-demand-acquisition-cost-field"
                                    help={dacHelpText}
                                >
                                    <Input
                                        style={{
                                            width: "280px",
                                        }}
                                        data-sdet="ad-source-demand-acquisition-cost-input"
                                        placeholder={`Enter ${dacLabel}`}
                                        suffix="%"
                                        disabled={true} // temporarily disabled until percent EC is fixed - replace true with isDemandAcquisitionReadonly when fixed
                                        value={getDemandAcquisitionCostFormValue(dac)}
                                    />
                                </Form.Item>
                            ) : (
                                <CurrencyInput
                                    style={{
                                        width: "320px",
                                    }}
                                    name={AD_SOURCE_FIELDS.DEMAND_ACQUISITION_COST.name}
                                    dataSdetPrefix="ad-source-demand-acquisition-cost"
                                    label={dacLabel}
                                    hideLabel
                                    disabled={isDemandAcquisitionReadonly}
                                    currencyCode={DEFAULT_ENRICHMENT_COST_CURRENCY_CODE}
                                    help={dacHelpText}
                                    value={getDemandAcquisitionCostFormValue(dac)}
                                />
                            )}
                        </Space.Compact>
                    </Form.Item>
                </Col>
            </Row>
        </div>
    );
};
