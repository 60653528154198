import { FC } from "react";
import { Radio } from "antd";
import { TRANSPARENCY_OPTIONS } from "@app/features/deals/constants";
import { useAdSourceSupplyPageContentUrlField } from "./useAdSourceSupplyPageContentUrlField";
import { TransparencyFormItem } from "@app/features/deals/DealForm/DealFormCreateAdSource/DealFormCreateAdSourceSections/AdSourceTransparencySection/Fields";
import { AD_SOURCE_FIELDS } from "@app/features/seatAdSources/constants";

export const AdSourceSupplyPageContentUrlField: FC = () => {
    const { value, onChange, isDisabled, overrideText } = useAdSourceSupplyPageContentUrlField();
    return (
        <TransparencyFormItem
            extra={overrideText}
            label={AD_SOURCE_FIELDS.SUPPLY_PAGE.label}
            data-sdet="supply-page-content-url"
        >
            <Radio.Group
                disabled={isDisabled}
                onChange={(e) => onChange(e?.target?.value)}
                value={value}
                options={TRANSPARENCY_OPTIONS}
            />
        </TransparencyFormItem>
    );
};
