import { FC } from "react";
import { Layout } from "antd";
import { PageHeader } from "@ant-design/pro-layout";
import { MappingExceptionsForm } from "../MappingExceptionsForm";
import { useMappingExceptionsCreatePage } from "./useMappingExceptionsCreatePage";

export const MappingExceptionsCreatePage: FC = () => {
    const { onBack } = useMappingExceptionsCreatePage();

    return (
        <Layout data-sdet="mapping-exceptions-create-page">
            <PageHeader title="Add Mapping Exceptions" onBack={onBack} />
            <MappingExceptionsForm />
        </Layout>
    );
};
