import { FC } from "react";
import { Button, Form, Row, Col, Space, Typography } from "antd";
import { ReloadOutlined } from "@ant-design/icons";
import { SelectWithValue } from "@app/features/seatAdSources/SeatAdSourcesForm/components";
import { useCreativeReview } from "./useCreativeReview";
import { CreativeReviewContent } from "./CreativeReviewContent";
import { LoadableComponent } from "../LoadableComponent";
import { CreativeSamplesEntityTypes } from "@app/core/services";

export type Creative = {
    uhash: string;
    reqId: string;
    sampleTime: number;
};

export interface CreativeItem extends Creative {
    crid: string;
    name: string;
    type: string;
    domain: string;
}

interface Props {
    id: number;
    entityType: CreativeSamplesEntityTypes;
}

export const CreativeReview: FC<Props> = ({ id, entityType }) => {
    const {
        options,
        selectedCreative,
        setSelectedCreative,
        onBlockDomain,
        onRefresh,
        loadingState,
        isBlockLoading,
        isDomainBlocked,
    } = useCreativeReview(id, entityType);
    return (
        <LoadableComponent loadingState={loadingState}>
            <Form layout="vertical">
                <Row align="bottom" style={{ paddingInline: "1rem" }} gutter={[8, 8]}>
                    <Col flex="1">
                        <Form.Item label="Creatives">
                            <SelectWithValue<CreativeItem | null>
                                fieldAsLabel={["label"]}
                                fieldAsValue={["name"]}
                                loading={loadingState === "loading"}
                                onChange={(item: CreativeItem) => setSelectedCreative(item as CreativeItem)}
                                optionFilterProp="value"
                                options={options.map((item) => ({
                                    ...item,
                                    label: (
                                        <Row justify="space-between">
                                            <Col>{item.name}</Col>
                                            <Col>
                                                <Typography.Text copyable={{ text: item.crid }} />
                                            </Col>
                                        </Row>
                                    ),
                                }))}
                                labelRender={(v) => v.value}
                                showSearch
                                value={selectedCreative}
                            />
                        </Form.Item>
                    </Col>
                    <Col>
                        <Form.Item>
                            <Space>
                                <Button
                                    danger
                                    type="primary"
                                    onClick={onBlockDomain}
                                    disabled={isDomainBlocked}
                                    loading={isBlockLoading}
                                >
                                    Block {selectedCreative?.domain || ""}
                                </Button>
                                <Button onClick={onRefresh}>
                                    <ReloadOutlined /> Refresh
                                </Button>
                            </Space>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>

            {selectedCreative && <CreativeReviewContent creative={selectedCreative} />}
        </LoadableComponent>
    );
};
