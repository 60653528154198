import { useState } from "react";
import { Form, notification } from "antd";
import { useCreateAdSourceStatsSnapshotMutation } from "@app/core/services/adStats/statsSnapshots";

export const useCreateSnapshotModal = (adSourceId: number) => {
    const [createSeatSnapshot, { isLoading }] = useCreateAdSourceStatsSnapshotMutation();
    const [form] = Form.useForm<{ description: string }>();
    const [open, setOpen] = useState(false);

    const handleOpenModal = () => {
        setOpen(true);
    };

    const handleCloseModal = () => {
        form.resetFields();
        setOpen(false);
    };

    const onSubmit = async () => {
        const message = form.getFieldValue("description");
        try {
            await createSeatSnapshot({ adSourceId, message }).unwrap();
            handleCloseModal();
        } catch (err) {
            notification.error({ message: err.data?.errorDescription || "Failed to create Snapshot!" });
        }
    };

    return {
        open,
        handleOpenModal,
        handleCloseModal,
        onSubmit,
        isLoading,
        form,
    };
};
