import { FC } from "react";
import { Col, Flex, Row, Space, Typography } from "antd";
import { formatNumber } from "@rubicon/utils";
import { useUserAccess } from "@app/core/auth";
import { AdSource } from "@app/core/services";
import { formatDateStringFromApi } from "@app/core/utils";
import { GUTTER } from "@app/features/deals/constants";
import { YEAR_MONTH_DAY } from "../constants";
import { NoDataAvailable } from "../LoadableComponent/NoDataAvailable";
import { getPacingStatusAttributes, PacingProgressBar } from "../PacingProgressBar";
import { PacingAdminDiagnostics } from "./PacingAdminDiagnostics";
import { PacingIndicatorStatusText } from "./PacingIndicatorStatusText";

const { Text, Paragraph } = Typography;

interface Props {
    adSource: AdSource;
}

export const PacingStatus: FC<Props> = ({ adSource }) => {
    const { showFallbackOpportunity } = useUserAccess();
    const { adsourcePacingData } = adSource;

    if (!adsourcePacingData?.pacingProgress) {
        return <NoDataAvailable />;
    }

    const { recordedTotalImpCount, totalImpCount, reqOpportunity, pacingProgress } = adsourcePacingData;
    const { expectedProgress, pacingDeltaPercentage, pacingStatus } = pacingProgress;
    const { textColor } = getPacingStatusAttributes(pacingStatus?.id);

    return (
        <Space data-sdet="pacing-status" size={"middle"} direction={"vertical"} style={{ width: "100%" }}>
            <Row gutter={GUTTER}>
                <Col style={{ width: "20%" }}>
                    <Flex vertical>
                        <PacingProgressBar pacingData={adsourcePacingData} showTooltip={false} />
                        <Typography.Paragraph
                            type="secondary"
                            italic
                            style={{ fontSize: "12px" }}
                        >{`Pacing data from ${formatDateStringFromApi(
                            adSource.bookingStartDate,
                            adSource.timeZone?.code,
                            YEAR_MONTH_DAY
                        )} up to an hour ago.`}</Typography.Paragraph>
                    </Flex>
                </Col>
                <Col style={{ width: "20%" }}>
                    <Text>Recorded Imp</Text>
                    <Paragraph strong>{formatNumber.asWhole(recordedTotalImpCount)}</Paragraph>
                </Col>
                <Col style={{ width: "20%" }}>
                    <Text>Projected</Text>
                    <Paragraph strong>{formatNumber.asWhole(totalImpCount)}</Paragraph>
                </Col>
                <Col style={{ width: "20%" }}>
                    <Text>On Pace</Text>
                    <Paragraph>
                        <Text strong>{formatNumber.asNumber(expectedProgress)}</Text>
                        <Text style={{ color: textColor, fontWeight: "bold" }}>
                            {" "}
                            ({formatNumber.asPercent(pacingDeltaPercentage)})
                        </Text>
                    </Paragraph>
                </Col>
                <Col style={{ width: "20%" }}>
                    <Text>Est. Daily Avails</Text>
                    <Paragraph strong>{formatNumber.asAbbreviated(reqOpportunity)}</Paragraph>
                </Col>
            </Row>
            <Row>
                <PacingIndicatorStatusText pacingProgress={pacingProgress} />
            </Row>
            <Row hidden={!showFallbackOpportunity} style={{ width: "100%" }}>
                <PacingAdminDiagnostics id={adSource.id} />
            </Row>
        </Space>
    );
};
