import { useHistory, useParams } from "react-router-dom";
import { ROUTE_FORMATTERS } from "@app/core/routing";
import { MappingException, useGetMappingExceptionByIdQuery } from "@app/core/services";

interface UseMappingExceptionsEditPage {
    mappingException: MappingException | undefined;
    isFetching: boolean;
    title: string | undefined;
    onBack: () => void;
}

export const useMappingExceptionsEditPage = (): UseMappingExceptionsEditPage => {
    const history = useHistory();
    const { seatId, mappingExceptionId } = useParams<{ seatId: string; mappingExceptionId: string }>();

    const { data: mappingException, isFetching } = useGetMappingExceptionByIdQuery(Number(mappingExceptionId), {
        skip: !mappingExceptionId,
    });

    const title = mappingException ? `Edit: ${mappingException.network.name}` : undefined;

    const onBack = () => history.push(ROUTE_FORMATTERS.SEAT_CONTROLS_BRAND_SAFETY_MAPPING_EXCEPTIONS_LIST_PAGE(seatId));

    return {
        mappingException,
        isFetching,
        title,
        onBack,
    };
};
