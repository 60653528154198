import { useMemo, useState } from "react";
import { DEFAULT_PAGE_SIZE, PAGE_SIZE_OPTIONS } from "@app/core/components/constants";
import { AdSource } from "@app/core/services";
import { ColumnKey, DemandTableRecord, getDataSource } from "../SeatAdSourcesForm/AdSourcesSections";
import { MONTH_DAY_YEAR_HOUR_MINUTE_AMPM_DASHED } from "@app/core/components/constants";
import { parseBookingPriceFromApi } from "../SeatAdSourcesForm/utils";
import { format } from "@rubicon/utils";
import moment from "moment-timezone";
import { TablePaginationConfig } from "antd";
import { AdSourceTypeIds } from "../constants";

const { DEFAULT_DASHES } = format.constants;

type CompareFunction = (a: DemandTableRecord, b: DemandTableRecord) => number;

interface SortField {
    columnKey: string;
    order: "ascend" | "descend";
}

const extractNumberIfExists = (value: string | number): number | string => {
    if (typeof value === "number") {
        return value;
    }

    const extractedNumber = Number(value.match(/\d/g)?.join(""));
    return isNaN(extractedNumber) ? value : extractedNumber;
};

const compareFunctionsByColumnKey: Record<Exclude<ColumnKey, "currency" | "actions" | "ec">, CompareFunction> = {
    name: (a, b) => a.name.localeCompare(b.name),
    network: (a, b) => a.network.localeCompare(b.network),
    buyerSeat: (a, b) => {
        const buyerSeatA = a.buyerSeat;
        const buyerSeatB = b.buyerSeat;

        if (buyerSeatA === DEFAULT_DASHES && buyerSeatB === DEFAULT_DASHES) return 0;
        if (buyerSeatA === DEFAULT_DASHES) return 1;
        if (buyerSeatB === DEFAULT_DASHES) return -1;

        return a.buyerSeat.localeCompare(b.buyerSeat);
    },
    rate: (a, b) => {
        const aRate = extractNumberIfExists(a.rate || 0);
        const bRate = extractNumberIfExists(b.rate || 0);

        const aRateIsNumber = typeof aRate === "number";
        const bRateIsNumber = typeof bRate === "number";

        if (aRateIsNumber && bRateIsNumber) return bRate - aRate;

        if (!aRateIsNumber && !bRateIsNumber) return aRate.localeCompare(bRate);
        if (!aRateIsNumber) return 1;
        if (!bRateIsNumber) return -1;

        return 0;
    },
    startDate: (a, b) => {
        const dateA = moment(a.startDate, MONTH_DAY_YEAR_HOUR_MINUTE_AMPM_DASHED);
        const dateB = moment(b.startDate, MONTH_DAY_YEAR_HOUR_MINUTE_AMPM_DASHED);

        if (dateA.isBefore(dateB)) return 1;
        if (dateA.isAfter(dateB)) return -1;

        return 0;
    },
    endDate: (a, b) => {
        const dateA = moment(a.endDate, MONTH_DAY_YEAR_HOUR_MINUTE_AMPM_DASHED);
        const dateB = moment(b.endDate, MONTH_DAY_YEAR_HOUR_MINUTE_AMPM_DASHED);

        const dateAValid = dateA.isValid();
        const dateBValid = dateB.isValid();

        if (!dateAValid && !dateBValid) return String(a.endDate).localeCompare(String(b.endDate));
        if (!dateAValid) return 1;
        if (!dateBValid) return -1;

        if (dateA.isBefore(dateB)) return 1;
        if (dateA.isAfter(dateB)) return -1;

        return 0;
    },
    freqCapping: (a, b) => a.freqCapping.localeCompare(b.freqCapping) * -1,
    targeting: (a, b) => a.targeting.localeCompare(b.targeting) * -1,
    internalStatus: (a, b) => a.internalStatus.localeCompare(b.internalStatus),
    buyerStatus: (a, b) => {
        const buyerStatusA = a.buyerStatus;
        const buyerStatusB = b.buyerStatus;

        if (buyerStatusA === DEFAULT_DASHES && buyerStatusB === DEFAULT_DASHES) return 0;
        if (buyerStatusA === DEFAULT_DASHES) return 1;
        if (buyerStatusB === DEFAULT_DASHES) return -1;

        return a.buyerStatus.localeCompare(b.buyerStatus);
    },
} as const;

interface UseSeatAdSourcesDealCountTable {
    deals: DemandTableRecord[];
    onSearchChange: (searchString: string) => void;
    onSortChange: (_a: unknown, _b: unknown, sortField: SortField) => void;
    isSingleInstanceOnly: boolean;
    totalPaginationProps: TablePaginationConfig;
}

export const useSeatAdSourcesDealCountTable = (adSource: AdSource): UseSeatAdSourcesDealCountTable => {
    const [search, setSearch] = useState<string>("");
    const [{ max, page }, setPagination] = useState<{ page: number; max: number }>({
        page: 1,
        max: DEFAULT_PAGE_SIZE,
    });
    const [sortField, setSortField] = useState<SortField>({ columnKey: "network", order: "ascend" });

    const isSingleInstanceOnly =
        adSource.type?.id === AdSourceTypeIds.AUTOMATED_GUARANTEED ||
        adSource.type?.id === AdSourceTypeIds.CONDITIONAL_AUTOMATED_GUARANTEED;

    const dealsDataSource = useMemo(() => {
        return getDataSource(adSource.marketplaceInfoList, {
            adSourceFloorType: adSource.adSourceFloorType,
            rate: adSource.bookingPrice ? parseBookingPriceFromApi(adSource.bookingPrice) : null,
            currency: adSource.currencyType,
            startDate: moment(adSource.bookingStartDate),
            endDate: adSource.bookingEndDate ? moment(adSource.bookingEndDate) : null,
            type: adSource.type,
            timeZone: adSource.timeZone,
        });
    }, [
        adSource.adSourceFloorType,
        adSource.marketplaceInfoList,
        adSource.bookingPrice,
        adSource.currencyType,
        adSource.bookingStartDate,
        adSource.bookingEndDate,
        adSource.type,
        adSource.timeZone,
    ]);

    const searchFilteredDeals = useMemo(() => {
        const searchTerm = search.toLowerCase().trim();
        return dealsDataSource.filter((deal) => deal.searchIndex.toLowerCase().includes(searchTerm));
    }, [dealsDataSource, search]);

    const sortedDeals = useMemo(() => {
        const sortOrderMultiplier = sortField.order === "ascend" ? 1 : -1;
        const compareFunction = compareFunctionsByColumnKey[sortField.columnKey];
        if (compareFunction) {
            return searchFilteredDeals.sort((a, b) => compareFunction(a, b) * sortOrderMultiplier);
        }
        return searchFilteredDeals;
    }, [searchFilteredDeals, sortField.columnKey, sortField.order]);

    const paginatedDeals = useMemo(() => {
        const startIndex = (page - 1) * max;
        const endIndex = startIndex + max;
        const paginatedDeals = sortedDeals.slice(startIndex, endIndex);
        return paginatedDeals;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sortedDeals, sortField, max, page]);

    const onSearchChange = (searchString: string) => {
        setPagination({ max, page: 1 });
        setSearch(searchString);
    };

    const onPaginationChange = (page: number, pMax: number) => {
        const updatedCurrentPage = pMax === max ? page : 1;
        setPagination({ max: pMax, page: updatedCurrentPage });
    };

    const onSortChange = (_a: unknown, _b: unknown, sortField: SortField) => {
        setSortField(sortField);
    };

    return {
        deals: paginatedDeals,
        onSearchChange,
        onSortChange,
        isSingleInstanceOnly,
        totalPaginationProps: {
            current: page,
            pageSize: max,
            total: sortedDeals.length,
            onChange: onPaginationChange,
            pageSizeOptions: PAGE_SIZE_OPTIONS,
        },
    };
};
