import { toQueryString } from "./utils";
import { ThirdPartyPixel } from "@app/core/services/console/pixels";
import { Label, LabelValue } from "@app/core/services/console/labels";
import { SeatAdvertiserDomainFilterListDef } from "./seatAdvertiserDomainFilterListDefs";
import {
    CHANGELOG_TAG,
    consoleApi,
    PUBLISHER_INHERITED_PIXELS_TAG,
    PUBLISHER_SEAT_PICKLIST_TAG,
    PUBLISHER_TAG,
    SEAT_PUBLISHERS_TAG,
} from "./console";
import { ChangeLogEntityType } from "./changeLogs";
import { BlockedIabCategory } from "./brands";
import { GlobalIndustry } from "@app/core/components";

export interface SeatPublisher {
    creationDate: string;
    updateDate: string;
    id: number;
    seat: {
        id: number;
        name: string;
        code: string;
        defaultBillingCode?: string | null;
        entityType: "Seat";
    };
    name: string;
    publisherNameOverride?: string | null;
    code: string;
    accountType?: null;
    billingCode: string;
    advertiserBlockDomains: string[];
    seatAdvertiserDomainFilterListDefs: SeatAdvertiserDomainFilterListDef[];
    labelValues: LabelValue[];
    internalLabelValues: LabelValue[];
    // This is not an omission - the API only returns the buyerSeatList's creationDate/updateDate
    buyerSeatList?: {
        creationDate: string;
        updateDate: string;
    } | null;
    type: {
        creationDate: string;
        updateDate: string;
        id: number;
        name: "Publisher" | "Channel";
    };
    publisherReauction?: null;
    emitPricingInVast?: null;
    externalCodes: unknown[];
    entityType: "Publisher" | "Channel";
}

interface BuyerSeatList {
    buyerSeats: {
        blocked: unknown | null;
        code: string;
        creationDate: string;
        demandSeat: unknown | null;
        entityType: string;
        externalName: string;
        hidden: boolean;
        id: number;
        name: string;
        updateDate: string;
    }[];
    creationDate: string;
    description: string;
    entityType: "BuyerSeatList";
    id: number;
    name: string;
    updateDate: string;
}

export interface AccountType {
    creationDate: string;
    id: number;
    name: string;
    updateDate: string;
}

export interface Publisher {
    creationDate?: string;
    updateDate?: string;
    id: number;
    seat: {
        id: number;
        name: string;
        code: string;
        defaultBillingCode?: null;
        entityType: "Seat";
    };
    name: string;
    publisherNameOverride: string | null;
    code: string;
    accountType: AccountType | null;
    billingCode: string;
    calculatedCalculonUseRateOverride: number | null;
    calculatedUpperCalculonUseRateOverride: number | null;
    calculonUseRateOverride: number | null;
    upperCalculonUseRateOverride: number | null;
    businessDomain?: string;
    description?: string;
    allowVastExtensionDSP?: boolean;
    allowVastExtensionCRID?: boolean;
    allowVastExtensionAdomain?: boolean;
    allowVastExtensionBrand?: boolean;
    allowVastExtensionBidPrice?: boolean;
    allowVastExtensionIabCategory?: boolean;
    allowVastExtensionAdSourceType?: boolean;
    allowVastExtensionAdSourceName?: boolean;
    externalComment?: null | string;
    advertiserBlockDomains: string[];
    thirdPartyPixels?: ThirdPartyPixel[];
    seatAdvertiserDomainFilterListDefs: SeatAdvertiserDomainFilterListDef[];
    labelValues?: LabelValue[];
    affiliateCostModel?: { id: number; name: string };
    affiliateCostValuePercent?: number | null;
    affiliateCostValueFixed?: number | null;
    internalLabelValues?: LabelValue[];
    buyerSeatList?: BuyerSeatList | null;
    publisherReauction?: boolean | null;
    emitPricingInVast: boolean;
    externalCodes?: unknown[];
    blockedIabCategories?: BlockedIabCategory[];
    blockedIndustries?: GlobalIndustry[];
    creativeBlockingMode?: { id: number; name: string } | null;
    type: {
        creationDate: string;
        updateDate: string;
        id: number;
        name: "Channel" | "Publisher";
    };
    entityType: "Publisher";
}

export interface GetSeatPublishersParams {
    seatId: number;
    max?: number;
    page?: number;
    keyword?: string;
    orderBy?: string;
    asc?: boolean;
    channels?: boolean;
}

export interface GetSeatPublishersPicklistParams {
    seatId: string;
    max?: number;
    page?: number;
    keyword?: string;
}

export interface PublisherPayload {
    // General
    code: string;
    description: string;
    name: string;
    publisherNameOverride: string;
    //is isPubAcctMgr || isSysAdmin only
    externalComment?: string;
    businessDomain?: string;
    accountType?: { id: number; name?: string } | null;

    // Brand Safety
    seatAdvertiserDomainFilterListDefs: { id: number; name?: string }[];
    advertiserBlockDomains: string[] | null;
    buyerSeatList: { id: number; name?: string } | null;
    // is isSysAdmin only
    creativeBlockingMode?: { id: number; name?: string };

    // Custom Pixels
    thirdPartyPixels: { id: number; name?: string }[];

    // Internal Section is isTremorUser only
    billingCode?: string;
    calculonUseRateOverride?: number | null;
    upperCalculonUseRateOverride?: number | null;
    // isTremorUser && (isPubAcctMgr || isSysAdmin) only
    publisherReauction?: boolean | null;

    // Advanced Features is isSysAdmin only
    emitPricingInVast?: boolean;
    allowVastExtensionDSP?: boolean;
    allowVastExtensionCRID?: boolean;
    allowVastExtensionAdomain?: boolean;
    allowVastExtensionBrand?: boolean;
    allowVastExtensionBidPrice?: boolean;
    allowVastExtensionAdSourceType?: boolean;
    allowVastExtensionIabCategory?: boolean;
    allowVastExtensionAdSourceName?: boolean;

    // Labels
    labelValues: { id: number; value: string; label: Label }[];
    // is hasInternalAccess only
    internalLabelValues?: { id: number; value: string; label: Label }[];
}

export interface CreatePublisherParams {
    seatId: number;
    body: PublisherPayload;
}

export interface UpdatePublisherPayload extends Partial<PublisherPayload> {
    seat: { id: number };
    id: number;
}

export interface UpdatePublisherBrandSafetyPayload {
    id: number;
    advertiserBlockDomains: string[];
    buyerSeatList: { id: number } | null;
    seatAdvertiserDomainFilterListDefs: { id: number }[];
}

export interface UpdatePublisherChannelBrandSafetyPayload {
    id: number;
    payload: {
        publisher: {
            advertiserBlockDomains: string[];
            buyerSeatList: { id: number } | null;
            seatAdvertiserDomainFilterListDefs: { id: number }[];
        };
    };
}

interface PublisherValidationPayloadParams {
    seatId: number;
    body: string[];
}

export const publishersApi = consoleApi.injectEndpoints({
    endpoints: (builder) => ({
        getSeatPublishers: builder.query<SeatPublisher[], GetSeatPublishersParams>({
            query: ({ seatId, ...rest }: GetSeatPublishersParams) => {
                return `seats/${seatId}/publishers;${toQueryString(rest)}`;
            },
            providesTags: (_, __, { seatId }) => [{ type: SEAT_PUBLISHERS_TAG, id: seatId }],
        }),
        getAllSeatPublishers: builder.query<SeatPublisher[], GetSeatPublishersParams>({
            query: ({ seatId }: GetSeatPublishersParams) => {
                return `seats/${seatId}/publishers`;
            },
            providesTags: (_, __, { seatId }) => [{ type: SEAT_PUBLISHERS_TAG, id: seatId }],
        }),
        getSeatPublishersPicklist: builder.query<Publisher[], GetSeatPublishersPicklistParams>({
            query: (params: GetSeatPublishersPicklistParams) => {
                const { seatId, ...rest } = params;
                return `seats/${seatId}/publishers/picklist;${toQueryString(rest)}`;
            },
            providesTags: [PUBLISHER_SEAT_PICKLIST_TAG],
        }),
        getPublisherById: builder.query<Publisher, number>({
            query: (id: number) => `publishers/${id}`,
            providesTags: (_, __, id) => [{ type: PUBLISHER_TAG, id }],
        }),
        createPublisher: builder.mutation<Publisher, CreatePublisherParams>({
            query: ({ seatId, body }: CreatePublisherParams) => {
                return {
                    url: `seats/${seatId}/publishers`,
                    method: "POST",
                    body,
                };
            },
            invalidatesTags: (_, err, { seatId }) => (err ? [] : [{ type: SEAT_PUBLISHERS_TAG, id: seatId }]),
        }),
        updatePublisher: builder.mutation<Publisher, UpdatePublisherPayload>({
            query: (payload: UpdatePublisherPayload) => ({
                url: `publishers/${payload.id}`,
                method: "PUT",
                body: payload,
            }),
            invalidatesTags: (_, err, payload) =>
                err
                    ? []
                    : [
                          { type: PUBLISHER_TAG, id: payload.id },
                          { type: SEAT_PUBLISHERS_TAG, id: payload.seat.id },
                          { type: PUBLISHER_INHERITED_PIXELS_TAG, id: payload.id },
                          { type: CHANGELOG_TAG, id: `${ChangeLogEntityType.Publisher}-${payload.id}` },
                      ],
        }),
        deletePublisher: builder.mutation<unknown, number>({
            query: (id: number) => ({
                url: `publishers/${id}`,
                method: "DELETE",
            }),
            invalidatesTags: (_, err) => (err ? [] : [SEAT_PUBLISHERS_TAG]),
        }),
        validatePublishers: builder.mutation<Publisher[], PublisherValidationPayloadParams>({
            query: ({ seatId, body }: PublisherValidationPayloadParams) => {
                return {
                    url: `validations/access;seatId=${seatId};type=publisher`,
                    method: "POST",
                    body,
                };
            },
        }),
        updatePublisherBrandSafety: builder.mutation<Publisher, UpdatePublisherBrandSafetyPayload>({
            query: (payload: UpdatePublisherBrandSafetyPayload) => ({
                url: `publishers/${payload.id}`,
                method: "PUT",
                body: payload,
            }),
            invalidatesTags: (_, err, payload) => (err ? [] : [{ type: PUBLISHER_TAG, id: payload.id }]),
        }),
        updatePublisherChannelBrandSafety: builder.mutation<Publisher, UpdatePublisherChannelBrandSafetyPayload>({
            query: ({ id, payload }: UpdatePublisherChannelBrandSafetyPayload) => ({
                url: `channels/${id}`,
                method: "PUT",
                body: payload,
            }),
            invalidatesTags: (_, err, payload) => (err ? [] : [{ type: PUBLISHER_TAG, id: payload.id }]),
        }),
    }),
    overrideExisting: false,
});

export const {
    useCreatePublisherMutation,
    useGetPublisherByIdQuery,
    useGetSeatPublishersPicklistQuery,
    useGetSeatPublishersQuery,
    useGetAllSeatPublishersQuery,
    useUpdatePublisherMutation,
    useDeletePublisherMutation,
    useValidatePublishersMutation,
    useUpdatePublisherBrandSafetyMutation,
    useUpdatePublisherChannelBrandSafetyMutation,
} = publishersApi;
