import { Card, Form, Row, Collapse } from "antd";
import { FC } from "react";
import {
    AD_SOURCE_FIELDS,
    AD_SOURCE_SECTIONS,
    AD_SOURCE_TYPES_NEXT_UI_NAMES,
    AdSourceTypes,
    cardBodyStyle,
    GUTTER,
    mainStepSectionClassList,
} from "../../../constants";
import { DefinitionSidePanel } from "../../DefinitionSidePanel";
import { useIsPbsDealType } from "../../useIsPbsDealType";
import {
    AlwaysSendFloorRadio,
    AuctionTypeSelect,
    BookingBudgetField,
    CurrencySelect,
    FixedCpmInput,
    FloorTypeSelect,
    OverrideFloorCpmInput,
    PriceModelSelect,
    VastDynamicPricingLocationRadio,
    VastDynamicPricingMultiplierInput,
    RunUnderInboundFloorRadio,
    AdResponsePriceOverrideSelect,
} from "./Fields";
import { usePricingSectionName } from "./usePricingSectionName";
import { SeatAdSourcesType } from "@app/core/services";
import { getPricingSectionName } from "../../utils/getPricingSectionName";

export const PricingSection: FC = () => {
    const adSourceType = Form.useWatch<SeatAdSourcesType>(AD_SOURCE_FIELDS.TYPE.name);
    const adSourceTypeName = AD_SOURCE_TYPES_NEXT_UI_NAMES[adSourceType?.name];

    const sectionName = usePricingSectionName();
    const isPbsDealType = useIsPbsDealType();

    const isVisible = !isPbsDealType && adSourceTypeName !== AdSourceTypes.FALLBACK_TAG;
    if (!isVisible) {
        return null;
    }
    return (
        <Collapse
            bordered
            ghost={false}
            collapsible="header"
            className={mainStepSectionClassList}
            defaultActiveKey={["1"]}
            items={[
                {
                    key: "1",
                    forceRender: true,
                    label: sectionName,
                    extra: (
                        <DefinitionSidePanel
                            panelLabel={getPricingSectionName(adSourceTypeName)}
                            section={AD_SOURCE_SECTIONS.PRICING}
                            sectionSelector="#adSourceForm"
                        />
                    ),
                    children: (
                        <Card bordered={false} styles={{ body: cardBodyStyle }}>
                            <Row gutter={GUTTER}>
                                <PriceModelSelect />
                            </Row>
                            <Row gutter={GUTTER}>
                                <FloorTypeSelect />
                            </Row>
                            <Row gutter={GUTTER}>
                                <VastDynamicPricingLocationRadio />
                            </Row>
                            <Row gutter={GUTTER}>
                                <VastDynamicPricingMultiplierInput />
                            </Row>
                            <Row gutter={GUTTER}>
                                <FixedCpmInput />
                                <OverrideFloorCpmInput />
                                <CurrencySelect />
                            </Row>
                            <Row gutter={GUTTER}>
                                <AuctionTypeSelect />
                                <AlwaysSendFloorRadio />
                            </Row>
                            <Row gutter={GUTTER}>
                                <BookingBudgetField />
                            </Row>
                            <Row gutter={GUTTER}>
                                <RunUnderInboundFloorRadio />
                            </Row>
                            <Row gutter={GUTTER}>
                                <AdResponsePriceOverrideSelect />
                            </Row>
                        </Card>
                    ),
                },
            ]}
        />
    );
};
