import { BLOCKED_INDUSTRIES_NAME } from "@app/core/components";

export const ID = "id";
export const NAME = "name";
export const TYPE = "type";
export const IAB_CATEGORIES = "blockedIabCategories";
export const REUSABLE_ADVERTISER_DOMAINS = "reusableAdvertiserDomains";
export const ADD_ADVERTISER_DOMAINS = "addAdvertiserDomains";
export const TARGETING = "targeting";
export const ACTIONS = "actions";

export const COLUMN_KEY = {
    NAME,
    ID,
    TYPE,
    IAB_CATEGORIES,
    BLOCKED_INDUSTRIES: BLOCKED_INDUSTRIES_NAME,
    REUSABLE_ADVERTISER_DOMAINS,
    ADD_ADVERTISER_DOMAINS,
    TARGETING,
    ACTIONS,
};

export const CREATE_BRAND_SAFETY_FORM_ITEMS_NAME = {
    NAME: "name",
    TYPE: "type",
    IABCATEGORIES: "blockedIabCategories",
    IAB_CATEGORY_BLOCK_EXCEPTIONS: "blockExceptionIabCategories",
    BLOCKED_INDUSTRIES: BLOCKED_INDUSTRIES_NAME,
    REUSABLE_ADVERTISER_DOMAINS: "seatAdvertiserDomainFilterListDefs",
    ADD_ADVERTISER_DOMAINS: "advertiserDomains",
    TARGETING: "targeting",
} as const;

export const BRAND_SAFETY_BLOCK_TYPE = {
    value: 1,
    label: "Block",
};
export const BRAND_SAFETY_ALLOW_TYPE = {
    value: 2,
    label: "Allow",
};
export const BRAND_SAFETY_TYPES_OPTIONS_LABEL_VALUE = [BRAND_SAFETY_BLOCK_TYPE, BRAND_SAFETY_ALLOW_TYPE];
