import { LabeledValue } from "antd/lib/select";
import { GlobalIndustry } from "./types";

export const parseBlockedIndustriesFromApi = (
    blockedIndustries: GlobalIndustry[] | null | undefined,
    formMode?: "create" | "copy" | "edit"
): LabeledValue[] | null => {
    if (formMode === "create" || !blockedIndustries) {
        return null;
    }
    return blockedIndustries
        .map(({ id, name }) => ({ label: name, value: id }))
        .sort((a, b) => a.label.localeCompare(b.label, "en", { sensitivity: "base" }));
};

export const parseBlockedIndustriesToApi = (blockedIndustries: LabeledValue[] | null): { id: number }[] | null => {
    return blockedIndustries?.map(({ value }) => ({ id: Number(value) })) ?? null;
};

export const sortGlobalIndustries = (a: GlobalIndustry[] | null, b: GlobalIndustry[] | null): number => {
    const aCount = a?.length ?? 0;
    const bCount = b?.length ?? 0;

    if (aCount === bCount) {
        if (aCount > 1) {
            const aString = a?.map(({ name }) => name).join("") ?? "";
            const bString = b?.map(({ name }) => name).join("") ?? "";

            return aString.localeCompare(bString, "en", { sensitivity: "base" });
        }

        return 0;
    }
    return aCount > bCount ? 1 : -1;
};
