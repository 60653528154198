import { useCallback } from "react";
import { useAppDispatch, useAppSelector } from "@app/core/store";
import {
    selectIsAdSourceOutOfSync,
    selectIsSyncingAdSourceChangesToDeal,
    updateIsAdSourceOutOfSyncState,
    updateIsSyncingAdSourceChangesToDeal,
} from "./reducer";

interface UseIsAdSourceOutOfSync {
    isAdSourceOutOfSync: boolean;
    setIsAdSourceOutOfSync: (isOutOfSync: boolean) => void;
    isSyncingAdSourceChangesToDeal: boolean;
    setIsSyncingAdSourceChangesToDeal: (isWaiting: boolean) => void;
}

// An ad source can only become out-of-sync in the ad source form workflow when:
//   a) A synced ad source field is updated, resulting in the deal being updated via a network request
//   b) A synced deal field change is saved in the ad source -> deal drawer
//   c) A deal is attached, resulting in synced field updates (deal -> ad source)
//   d) A deal is detached, resulting in synced field updates (deal -> ad source)
//
// This doesn't apply in the deal form because there are no individual updates to ad sources or deals... They are handled all at once on save.

export const useIsAdSourceOutOfSync = (): UseIsAdSourceOutOfSync => {
    const dispatch = useAppDispatch();

    const isAdSourceOutOfSync = useAppSelector(selectIsAdSourceOutOfSync);
    const setIsAdSourceOutOfSync = useCallback(
        (isOutOfSync: boolean) => {
            dispatch(updateIsAdSourceOutOfSyncState(isOutOfSync));
        },
        [dispatch]
    );

    const isSyncingAdSourceChangesToDeal = useAppSelector(selectIsSyncingAdSourceChangesToDeal);
    const setIsSyncingAdSourceChangesToDeal = useCallback(
        (isUpdatingDeal: boolean) => {
            dispatch(updateIsSyncingAdSourceChangesToDeal(isUpdatingDeal));
        },
        [dispatch]
    );

    return {
        isAdSourceOutOfSync,
        setIsAdSourceOutOfSync,
        isSyncingAdSourceChangesToDeal,
        setIsSyncingAdSourceChangesToDeal,
    };
};
