import { ROUTES } from "@app/core/routing";
import { Route, Switch } from "react-router-dom";
import { InventoryHealthUnitPage } from "./InventoryHealthUnitPage";
import { InventoryDetailsDrawerType } from "../../DetailsDrawer/reducer";

export const InventoryHealthUnitPageSwitch = () => {
    return (
        <Switch>
            <Route
                exact
                path={[
                    ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_PUBLISHER,
                    ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_PUBLISHER_DEFINITION,
                    ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_PUBLISHER_CHANGE_HISTORY,
                    ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_PUBLISHER_TAG_PARAMS,
                    ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_PUBLISHER_CREATIVE_REVIEW,
                    ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_PUBLISHER_CREATIVE_REVIEW_DEFINITION,
                    ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_PUBLISHER_SNAPSHOT,
                    ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_PUBLISHER_DIAGNOSTICS,
                    ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_PUBLISHER_PERMISSIONS,
                    ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_PUBLISHER_FLOOR_ANALYSIS,
                    ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_PUBLISHER_DIAGNOSTICS_DEFINITION,
                    ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_PUBLISHER_ADS_TXT,
                ]}
            >
                <InventoryHealthUnitPage type={InventoryDetailsDrawerType.PUBLISHER} />
            </Route>
            <Route
                exact
                path={[
                    ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_CHANNEL,
                    ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_CHANNEL_DEFINITION,
                    ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_CHANNEL_TAG_PARAMS,
                    ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_CHANNEL_CHANGE_HISTORY,
                    ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_CHANNEL_CREATIVE_REVIEW,
                    ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_CHANNEL_CREATIVE_REVIEW_DEFINITION,
                    ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_CHANNEL_SNAPSHOT,
                    ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_CHANNEL_DIAGNOSTICS,
                    ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_CHANNEL_FLOOR_ANALYSIS,
                    ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_CHANNEL_DIAGNOSTICS_DEFINITION,
                    ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_CHANNEL_LADLE,
                    ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_CHANNEL_LADLE_DEFINITION,
                    ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_CHANNEL_ADS_TXT,
                ]}
            >
                <InventoryHealthUnitPage type={InventoryDetailsDrawerType.CHANNEL} />
            </Route>
            <Route
                exact
                path={[
                    ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_BRAND_CHANNEL,
                    ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_BRAND_CHANNEL_DEFINITION,
                    ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_BRAND_CHANNEL_CHANGE_HISTORY,
                    ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_BRAND_CHANNEL_TAG_PARAMS,
                    ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_BRAND_CHANNEL_CREATIVE_REVIEW,
                    ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_BRAND_CHANNEL_CREATIVE_REVIEW_DEFINITION,
                    ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_BRAND_CHANNEL_DIAGNOSTICS,
                    ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_BRAND_CHANNEL_FLOOR_ANALYSIS,
                    ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_BRAND_CHANNEL_DIAGNOSTICS_DEFINITION,
                    ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_BRAND_CHANNEL_SNAPSHOT,
                    ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_BRAND_CHANNEL_LADLE,
                    ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_BRAND_CHANNEL_LADLE_DEFINITION,
                ]}
            >
                <InventoryHealthUnitPage type={InventoryDetailsDrawerType.BRAND_CHANNEL} />
            </Route>
            <Route
                exact
                path={[
                    ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_BRAND,
                    ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_BRAND_DEFINITION,
                    ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_BRAND_CHANGE_HISTORY,
                    ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_BRAND_TAG_PARAMS,
                    ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_BRAND_CREATIVE_REVIEW,
                    ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_BRAND_CREATIVE_REVIEW_DEFINITION,
                    ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_BRAND_DIAGNOSTICS,
                    ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_BRAND_FLOOR_ANALYSIS,
                    ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_BRAND_DIAGNOSTICS_DEFINITION,
                    ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_BRAND_SNAPSHOT,
                    ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_BRAND_ADS_TXT,
                ]}
            >
                <InventoryHealthUnitPage type={InventoryDetailsDrawerType.BRAND} />
            </Route>
            <Route
                exact
                path={[
                    ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_SUPPLY,
                    ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_SUPPLY_DEFINITION,
                    ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_SUPPLY_CHANGE_HISTORY,
                    ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_SUPPLY_TAG_PARAMS,
                    ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_SUPPLY_CREATIVE_REVIEW,
                    ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_SUPPLY_CREATIVE_REVIEW_DEFINITION,
                    ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_SUPPLY_DIAGNOSTICS,
                    ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_SUPPLY_FLOOR_ANALYSIS,
                    ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_SUPPLY_DIAGNOSTICS_DEFINITION,
                    ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_SUPPLY_SNAPSHOT,
                ]}
            >
                <InventoryHealthUnitPage type={InventoryDetailsDrawerType.SUPPLY} />
            </Route>
            <Route
                exact
                path={[
                    ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_AD_UNIT,
                    ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_AD_UNIT_DEFINITION,
                    ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_AD_UNIT_CHANGE_HISTORY,
                    ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_AD_UNIT_TAG_PARAMS,
                    ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_AD_UNIT_CREATIVE_REVIEW,
                    ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_AD_UNIT_CREATIVE_REVIEW_DEFINITION,
                    ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_AD_UNIT_DIAGNOSTICS,
                    ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_AD_UNIT_FLOOR_ANALYSIS,
                    ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_AD_UNIT_DIAGNOSTICS_DEFINITION,
                    ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_AD_UNIT_SNAPSHOT,
                    ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_AD_UNIT_LADLE,
                    ROUTES.SEAT_INVENTORY_HEALTH_DETAILS_PAGE_AD_UNIT_LADLE_DEFINITION,
                ]}
            >
                <InventoryHealthUnitPage type={InventoryDetailsDrawerType.AD_UNIT} />
            </Route>
        </Switch>
    );
};
