import { FC } from "react";
import { Form } from "antd";
import { LoadOnScrollSelect } from "@rubicon/antd-components";
import { FORM_FIELDS } from "../../constants";
import { useDemandSelect } from "./useDemandSelect";

const { name, label } = FORM_FIELDS.DEMAND;

export const DemandSelect: FC = () => {
    const { options, isFetching, search, hasMore, loadMore, onChangeSearch, onBlur } = useDemandSelect();

    return (
        <Form.Item
            name={name}
            label={label}
            rules={[{ required: true, message: `${label} is required` }]}
            wrapperCol={{ sm: 24, md: 8 }}
        >
            <LoadOnScrollSelect
                allowClear
                labelInValue
                showSearch
                placeholder="Select DSP"
                filterOption={false}
                loading={isFetching}
                options={options}
                searchValue={search}
                hasMore={hasMore}
                loadMore={loadMore}
                onBlur={onBlur}
                onSearch={onChangeSearch}
            />
        </Form.Item>
    );
};
