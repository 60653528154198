import { FC } from "react";
import { format } from "@rubicon/utils";
import { AdvancedBrandSafety } from "@app/core/services";
import { BLOCKED_INDUSTRIES_LABEL, Dashes, Industries, sortGlobalIndustries, StyledLink } from "@app/core/components";
import { Button, Space, Table, Tooltip } from "antd";
import { DeleteItem } from "../useInventoryAdvancedBrandSafetyPage";
import { DEFAULT_PAGE_SIZE, PAGE_SIZE_OPTIONS } from "@app/core/components/constants";
import { QuestionCircleOutlined, EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { COLUMN_KEY } from "@app/features/inventory/InventoryBrandSafety/InventoryAdvancedBrandSafetyPage/constants";
import { useInventoryAdvancedBrandSafetyTable } from "@app/features/inventory/InventoryBrandSafety/InventoryAdvancedBrandSafetyPage/InventoryAdvancedBrandSafetyTable/useInventoryAdvancedBrandSafetyTable";
import { brandCobalt } from "@rubicon/antd-components";

const { YES } = format.constants;

const columns = (
    handleEdit: (id: number) => void,
    handleDeleteAdvancedBrandSafety: (item: DeleteItem) => void,
    handleDetails: (id: number) => void
) => [
    {
        dataIndex: COLUMN_KEY.NAME,
        title: "Name",
        render: (text: string, record: { key: number; name: string }) => {
            return <StyledLink onClick={() => handleDetails(record.key)}>{format.asSelf(record?.name)}</StyledLink>;
        },
        sorter: (a, b) => {
            return String(a.name).localeCompare(String(b.name));
        },
    },
    {
        dataIndex: COLUMN_KEY.TYPE,
        title: "Type",
        render: (text: string) => <Dashes value={text} />,
        sorter: (a, b) => {
            return String(a.type).localeCompare(String(b.type));
        },
    },
    {
        dataIndex: COLUMN_KEY.IAB_CATEGORIES,
        title: (
            <Tooltip>
                <Space>
                    Blocked IAB Categories
                    <QuestionCircleOutlined style={{ color: brandCobalt }} />
                </Space>
            </Tooltip>
        ),
        sorter: (a, b) => {
            return String(a[COLUMN_KEY.IAB_CATEGORIES]).localeCompare(String(b[COLUMN_KEY.IAB_CATEGORIES]));
        },
    },
    {
        dataIndex: COLUMN_KEY.BLOCKED_INDUSTRIES,
        title: (
            <Tooltip>
                <Space>
                    {BLOCKED_INDUSTRIES_LABEL}
                    <QuestionCircleOutlined style={{ color: brandCobalt }} />
                </Space>
            </Tooltip>
        ),
        render: (blockedIndustries, { key }) => (
            <Industries industries={blockedIndustries} viewAll={{ onClick: () => handleDetails(key) }} />
        ),
        sorter: (a, b) => sortGlobalIndustries(a[COLUMN_KEY.BLOCKED_INDUSTRIES], b[COLUMN_KEY.BLOCKED_INDUSTRIES]),
    },
    {
        dataIndex: COLUMN_KEY.REUSABLE_ADVERTISER_DOMAINS,
        title: (
            <Tooltip>
                <Space>
                    Reusable Advertiser Domains
                    <QuestionCircleOutlined style={{ color: brandCobalt }} />
                </Space>
            </Tooltip>
        ),
        sorter: (a, b) => {
            return String(a[COLUMN_KEY.REUSABLE_ADVERTISER_DOMAINS]).localeCompare(
                String(b[COLUMN_KEY.REUSABLE_ADVERTISER_DOMAINS])
            );
        },
    },
    {
        dataIndex: COLUMN_KEY.ADD_ADVERTISER_DOMAINS,
        title: (
            <Tooltip>
                <Space>
                    Add. Advertiser Domains
                    <QuestionCircleOutlined style={{ color: brandCobalt }} />
                </Space>
            </Tooltip>
        ),
        sorter: (a, b) => {
            return String(a[COLUMN_KEY.ADD_ADVERTISER_DOMAINS]).localeCompare(
                String(b[COLUMN_KEY.ADD_ADVERTISER_DOMAINS])
            );
        },
    },
    {
        dataIndex: COLUMN_KEY.TARGETING,
        title: "Targeting",
        sorter: (a, b) => {
            return String(a[COLUMN_KEY.TARGETING]).localeCompare(String(b[COLUMN_KEY.TARGETING]));
        },
    },
    {
        dataIndex: COLUMN_KEY.ACTIONS,
        title: "Actions",
        render: (_, record) => {
            return (
                <Space>
                    <Tooltip title="Edit">
                        <Button onClick={() => handleEdit(record.key)} icon={<EditOutlined />} />
                    </Tooltip>
                    <Tooltip title="Remove">
                        <Button
                            onClick={() => handleDeleteAdvancedBrandSafety({ id: record.key, name: record.name })}
                            icon={<DeleteOutlined />}
                        />
                    </Tooltip>
                </Space>
            );
        },
    },
];

interface InventoryAdvancedBrandSafetyTableProps {
    handleDetails: (id: number) => void;
    handleEdit: (id: number) => void;
    handleDeleteAdvancedBrandSafety: (item: DeleteItem) => void;
    searchValue: string;
}

const InventoryAdvancedBrandSafetyTable: FC<InventoryAdvancedBrandSafetyTableProps> = ({
    handleDeleteAdvancedBrandSafety,
    handleDetails,
    handleEdit,
    searchValue,
}) => {
    const { data, isFetching } = useInventoryAdvancedBrandSafetyTable();
    const getTableItems = (data: Array<AdvancedBrandSafety> | undefined) =>
        data
            ?.filter((brand) =>
                searchValue ? brand.name.toLowerCase().includes(searchValue.toLowerCase().trim()) : true
            )
            ?.map((brand) => {
                return {
                    key: brand?.id,
                    [COLUMN_KEY.NAME]: brand?.name,
                    [COLUMN_KEY.TYPE]: brand?.type?.name,
                    [COLUMN_KEY.IAB_CATEGORIES]: format.asYesOrNo(brand?.blockedIabCategories?.length),
                    [COLUMN_KEY.BLOCKED_INDUSTRIES]: brand?.blockedIndustries,
                    [COLUMN_KEY.REUSABLE_ADVERTISER_DOMAINS]: brand?.seatAdvertiserDomainFilterListDefs?.length
                        ? YES
                        : "None",
                    [COLUMN_KEY.ADD_ADVERTISER_DOMAINS]: brand?.advertiserDomains?.length ? YES : "None",
                    [COLUMN_KEY.TARGETING]: format.asYesOrNo(brand?.targeting?.length),
                };
            });

    return (
        <Space direction="vertical" size="middle" style={{ width: "100%" }}>
            <Table
                size="small"
                loading={isFetching}
                showSorterTooltip={false}
                pagination={{
                    size: "default",
                    showLessItems: true,
                    showSizeChanger: true,
                    pageSizeOptions: PAGE_SIZE_OPTIONS,
                    defaultPageSize: DEFAULT_PAGE_SIZE,
                }}
                dataSource={getTableItems(data)}
                columns={columns(handleEdit, handleDeleteAdvancedBrandSafety, handleDetails)}
            />
        </Space>
    );
};

export default InventoryAdvancedBrandSafetyTable;
