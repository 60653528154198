import { Affix, Card, Space, Button, Typography, Modal } from "antd";
import { WarningOutlined } from "@ant-design/icons";
import { warningColor } from "@rubicon/antd-components";
import { FC, useContext } from "react";
import { AdSourceFormsContext } from "..";

interface Props {
    adSourceName: string;
    isSubmitting: boolean;
    isDeleting: boolean;
    openDeleteModal: boolean;
    handleSubmit: () => Promise<void>;
    handleCancel: () => void;
    handleDelete: () => void;
    handleOpenDeleteModal: () => void;
    handleCloseDeleteModal: () => void;
    openSyncCancelWarningModal: boolean;
    handleConfirmSyncCancelWarning: () => void;
    handleCancelSyncCancelWarning: () => void;
}

export const AdSourcesFooter: FC<Props> = ({
    adSourceName,
    isSubmitting,
    handleCancel,
    handleDelete,
    handleSubmit,
    isDeleting,
    openDeleteModal,
    handleOpenDeleteModal,
    handleCloseDeleteModal,
    openSyncCancelWarningModal,
    handleConfirmSyncCancelWarning,
    handleCancelSyncCancelWarning,
}) => {
    const { forms } = useContext(AdSourceFormsContext);
    const { adSourceFormMode } = forms;
    return (
        <Affix offsetBottom={46}>
            <div>
                <Card styles={{ body: { padding: "0.75rem 1rem" } }} style={{ marginTop: "3rem" }}>
                    <Space>
                        <Button type="primary" onClick={handleSubmit} loading={isSubmitting} data-sdet="submit">
                            Submit
                        </Button>
                        <Button onClick={handleCancel} disabled={isSubmitting} data-sdet="cancel">
                            Cancel
                        </Button>
                        {adSourceFormMode === "edit" && (
                            <Button
                                type="primary"
                                onClick={handleOpenDeleteModal}
                                disabled={isDeleting}
                                danger
                                loading={isDeleting}
                            >
                                Delete
                            </Button>
                        )}
                    </Space>
                </Card>
                <Modal
                    data-sdet="delete-modal"
                    open={openDeleteModal}
                    title="Delete Ad Source"
                    onOk={handleDelete}
                    onCancel={handleCloseDeleteModal}
                    confirmLoading={isDeleting}
                    styles={{ body: { height: "200px" } }}
                    footer={[
                        <Button key="no" onClick={handleCloseDeleteModal}>
                            Cancel
                        </Button>,
                        <Button
                            key="yes"
                            type="primary"
                            danger
                            loading={isDeleting}
                            disabled={isDeleting}
                            onClick={handleDelete}
                        >
                            Delete
                        </Button>,
                    ]}
                >
                    <Typography.Paragraph>
                        Are you sure you want to delete <Typography.Text strong>{adSourceName}</Typography.Text>?
                    </Typography.Paragraph>
                </Modal>
                <Modal
                    data-sdet="sync-cancel-warning-modal"
                    open={openSyncCancelWarningModal}
                    closable={false}
                    styles={{ body: { height: "200px" } }}
                    title={
                        <Typography.Text>
                            <WarningOutlined data-sdet="warning-icon" style={{ color: warningColor }} /> Warning
                        </Typography.Text>
                    }
                    footer={[
                        <Button key="no" onClick={handleCancelSyncCancelWarning}>
                            Cancel
                        </Button>,
                        <Button key="yes" type="primary" danger onClick={handleConfirmSyncCancelWarning}>
                            Leave it unsynced
                        </Button>,
                    ]}
                >
                    <Typography.Paragraph>
                        The Deal that&apos;s attached to this Ad Source has been updated with your changes and the Ad
                        Source has not. If you want to cancel your changes, you will need to change the Deal settings
                        back to the original setting and save the Deal. Otherwise the Deal and Ad Source will not be
                        synced.
                    </Typography.Paragraph>
                </Modal>
            </div>
        </Affix>
    );
};
