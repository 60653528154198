import { CSSProperties, FC, MouseEvent } from "react";
import { Tag, Typography, Form } from "antd";
import type { CustomTagProps } from "rc-select/lib/BaseSelect";
import { DEAL_FORM_FIELDS } from "../../../../constants";
import { ReadonlyControl, SelectWithValue } from "@app/features/seatAdSources/SeatAdSourcesForm/components";
import { MarketplaceInfo } from "@app/core/services";
import { useMarketplaceInfoWidget } from "@app/features/seatAdSources/SeatAdSourcesForm/useMarketplaceInfoWidget";
import { DemandConfigurationForm } from "@app/features/seatAdSources/SeatAdSourcesForm/SeatAdSourcesForm";
import { MINUTES_IN } from "@app/core/components/constants";

export const formatTimeDuration = (timeDurationMin) => {
    const fullMonth = Math.floor(timeDurationMin / MINUTES_IN.MONTH);
    const fullWeek = Math.floor((timeDurationMin - fullMonth * MINUTES_IN.MONTH) / MINUTES_IN.WEEK);
    const fullDay = Math.floor(
        (timeDurationMin - (fullMonth * MINUTES_IN.MONTH + fullWeek * MINUTES_IN.WEEK)) / MINUTES_IN.DAY
    );
    const fullHour = Math.floor(
        (timeDurationMin - (fullMonth * MINUTES_IN.MONTH + fullWeek * MINUTES_IN.WEEK + fullDay * MINUTES_IN.DAY)) /
            MINUTES_IN.HOUR
    );

    const mins =
        timeDurationMin -
        (fullHour * MINUTES_IN.HOUR +
            fullMonth * MINUTES_IN.MONTH +
            fullWeek * MINUTES_IN.WEEK +
            fullDay * MINUTES_IN.DAY);

    const month = fullMonth ? `${fullMonth} ${fullMonth === 1 ? "month" : "months"} ` : "";
    const week = fullWeek ? `${fullWeek} ${fullWeek === 1 ? "week" : "weeks"} ` : "";
    const day = fullDay ? `${fullDay} ${fullDay === 1 ? "day" : "days"} ` : "";
    const hour = fullHour ? `${fullHour} ${fullHour === 1 ? "hour" : "hours"} ` : "";
    const minute = mins ? `${mins} ${mins === 1 ? "minute" : "minutes"} ` : "";

    return month + week + day + hour + minute;
};

const CustomTag: FC<CustomTagProps> = (props) => {
    const { value, closable, label } = props;
    const form = Form.useFormInstance<DemandConfigurationForm>();
    const onPreventMouseDown = (event: MouseEvent<HTMLSpanElement>) => {
        event.preventDefault();
        event.stopPropagation();
    };
    const currentValue = value.find((item) => item?.id === label);
    return (
        <Tag
            color="blue"
            onMouseDown={onPreventMouseDown}
            closable={closable}
            onClose={() => {
                form.setFieldValue(
                    DEAL_FORM_FIELDS.DEAL_FREQ_CAPPINGS.name,
                    value.filter((item) => item?.id !== label)
                );
            }}
            style={{ marginRight: 3 }}
        >
            <Typography.Text>
                <Typography.Text strong>
                    {currentValue?.total} {currentValue?.mode.name} in
                </Typography.Text>{" "}
                {formatTimeDuration(currentValue?.timeDurationMin)}
            </Typography.Text>
        </Tag>
    );
};

const tagRender = (props: CustomTagProps) => {
    return <CustomTag {...props} />;
};

export const DealFreqCappingsMultiselect: FC<{ style?: CSSProperties }> = ({ style }) => {
    const { marketplaceInfoMode, dealObjectMode } = useMarketplaceInfoWidget();
    const freqCappings = Form.useWatch<string[]>(DEAL_FORM_FIELDS.DEAL_FREQ_CAPPINGS.name);
    const isHidden = !freqCappings?.length;
    const isDisabled = dealObjectMode === "edit";
    return (
        <Form.Item name={DEAL_FORM_FIELDS.DEAL_FREQ_CAPPINGS.name} hidden={isHidden} style={style}>
            {marketplaceInfoMode.name === "view" ? (
                <ReadonlyControl<MarketplaceInfo["dealFreqCappings"]>
                    getLabel={(cappings) =>
                        cappings.map((capping, index) => (
                            <Tag key={index}>
                                {capping.total} {capping.mode.name} in {capping.timeDurationMin} minutes
                            </Tag>
                        ))
                    }
                />
            ) : (
                <SelectWithValue
                    tagRender={tagRender}
                    mode="tags"
                    showSearch={false}
                    open={false}
                    fieldAsLabel={["id"]}
                    fieldAsValue={["id"]}
                    disabled={isDisabled}
                />
            )}
        </Form.Item>
    );
};
