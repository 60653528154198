import { LabeledValue } from "antd/es/select";

export interface MappingExceptionsForm {
    adomain: string | null;
    adomainOverride: string | null;
    crid: string | null;
    industryOverrideList: LabeledValue[] | null;
    industryOverrideMode: number;
    network: LabeledValue | null;
    taxonomyOverrideMode: number;
    taxonomyOverrideList: LabeledValue[] | null;
}

export enum OverrideMode {
    ADD = 1,
    OVERRIDE = 2,
    NONE = 3,
}
