import { BrandChannel } from "./brands";
import { ChangeLogEntityType } from "./changeLogs";
import {
    CHANGELOG_TAG,
    CHANNEL_ITEM_TAG,
    CHANNEL_TAG,
    consoleApi,
    PUBLISHER_TAG,
    SEAT_PUBLISHERS_TAG,
} from "./console";
import { IabCategory } from "./iabCategories";
import { Label } from "./labels";
import { AdomainFreqCapping, IndustriesCategory } from "./supply";
import { toQueryString } from "./utils";

export interface ChannelParams {
    seatId: string;
    keyword?: string;
    page?: number;
    max?: number;
}

export interface ChannelItem {
    code: string;
    entityType: "Channel";
    id: number;
    name: string;
    publisher?: {
        id: number;
        seat?: {
            id: number;
            name: string;
            code: string;
            isSupplySeat: boolean;
            enableAdServing: boolean;
        };
    };
    seat?: {
        name: string;
        entityType: string;
        id: number;
    };
    type: {
        id: number;
        name: string;
    };
}

interface ChannelValidationPayloadParams {
    seatId: number;
    body: string[];
}

export interface ChannelPayload {
    // General
    name: string;
    adUnit: {
        //General Section
        code: string;
        status: {
            id: number | string;
        };
        //DetailedC Configuration Section
        adomainFreqCappingType: {
            id: number | string;
        };
        lsaEnabled: boolean;
        lsaWindowSizeSeconds: number | null;
        adomainFreqCappings?: AdomainFreqCapping[];
        impressionWaitTime: { id: number } | null;
        unwrapVast: boolean;
        udeOptOut: boolean | null;
        //Defaults Section
        mimes: { id: number; name?: string }[];
        supportedApis: { id: number; name?: string }[];
        linearity: { id: number } | null;
        type: { id: number; name?: string };
        startDelay: number;
        isInterstitial: boolean;
        placement: { id: number; name?: string };
        supportedProtocols: { id: number; name?: string }[];
        minDuration: number;
        maxDuration: number;
        maxExtended: number;
        minBitrate: number | null;
        maxBitrate: number | null;
        ssaiType: { id: number; name?: string } | null;
        isLiveStream: boolean;
        skip: boolean | null;
        skipafter: number | null;
        skipmin: number | null;
        multiplicity: { id: number };
        // Ad Pod Section
        maxPodSeconds: number | null;
        maxAdsPerPod: number | null;
        //Palylist Configuration Section
        adPodFillMode: { id: number } | null;
        podEnforcement: { id: number } | null;
        separationGroups:
            | {
                  iabCategories: IabCategory[];
                  id: number;
                  industries: IndustriesCategory[];
                  name: string;
              }[]
            | null;
        playlistDefinitionMode: { id: number; name: string } | null;
        maxAdsPerAdvertiser: number | null;
        //Ad Break Rules Section
        adBreakRules: {
            adBreakLabel: string;
            maxAdsPerPod: number;
            maxPodLengthSeconds: number;
            midRollIndex: number | null;
        }[];
        cuepoints: string[] | null;
    };
    publisher: {
        //General Section
        name: string;
        accountType?: { id: number } | null;
        description: string | null;
        billingCode: string;
        //Detailed Configuration Section
        publisherNameOverride: string | null;
        businessDomain: string;
        //Brand Safety Section
        seatAdvertiserDomainFilterListDefs: { id: number; name?: string }[];
        advertiserBlockDomains: string[] | null;
        blockedIabCategories: { id: number; name?: string }[];
        blockedIndustries: { id: number }[] | null;
        buyerSeatList: { id: number; name?: string } | null;
        // Custom Pixels Section
        thirdPartyPixels: { id: number; name?: string }[];
        // Labels
        labelValues: { id: number; value: string; label: Label }[];
        internalLabelValues?: { id: number; value: string; label: Label }[];
    };
    brand: {
        //Defaults Section
        iabCategories: { id: number; name?: string }[] | null;
        //Detailed Configuration Section
        enableAdvertisersAsWhitelist: boolean;
        //Brand Safety Section
        blockedIabCategories: { id: number; name?: string }[];
        blockedIndustries: { id: number }[] | null;
    };
    supply: {
        //Defaults Section
        isCoppa: boolean | null;
        hasPrivacyPolicy: boolean | null;
        //Detailed Configuration Section
        allowVastWrappers: boolean;
        enableNoAdCookieSync?: boolean;
        supplyNameOverride: string | null;
        inventoryPartnerDomain: string | null;
    };
}

interface CreateChannelPayload {
    id: number;
    payload: ChannelPayload;
}

interface UpdateChannelPayload {
    id: number;
    payload: Partial<ChannelPayload>;
}

export const channelApi = consoleApi.injectEndpoints({
    endpoints: (builder) => ({
        getChannels: builder.query<ChannelItem[], ChannelParams>({
            query: (params: ChannelParams) => {
                const { seatId, ...rest } = params;
                return `/seats/${seatId}/channels/picklist;${toQueryString(rest)}`;
            },
            providesTags: [CHANNEL_TAG],
        }),
        getChannel: builder.query<BrandChannel, number>({
            query: (id: number) => `/channels/${id}`,
            providesTags: [CHANNEL_ITEM_TAG],
        }),
        createChannel: builder.mutation<BrandChannel, CreateChannelPayload>({
            query: ({ id, payload }) => ({
                url: `seats/${id}/channels`,
                method: "POST",
                body: payload,
            }),
            invalidatesTags: (_, err) => (err ? [] : [{ type: SEAT_PUBLISHERS_TAG }]),
        }),
        updateChannel: builder.mutation<BrandChannel, UpdateChannelPayload>({
            query: ({ id, payload }) => ({
                url: `channels/${id}`,
                method: "PUT",
                body: payload,
            }),
            invalidatesTags: (_, err, { id }) =>
                err
                    ? []
                    : [
                          { type: SEAT_PUBLISHERS_TAG },
                          { type: CHANNEL_ITEM_TAG },
                          { type: CHANGELOG_TAG, id: `${ChangeLogEntityType.Channel}-${id}` },
                          { type: PUBLISHER_TAG, id },
                      ],
        }),
        deleteChannel: builder.mutation<void, number>({
            query: (id: number) => ({
                url: `channels/${id}`,
                method: "DELETE",
            }),
            invalidatesTags: (_, err) => (err ? [] : [{ type: SEAT_PUBLISHERS_TAG }]),
        }),
        validateChannels: builder.mutation<ChannelItem[], ChannelValidationPayloadParams>({
            query: ({ seatId, body }: ChannelValidationPayloadParams) => {
                return {
                    url: `validations/access;seatId=${seatId};type=channel`,
                    method: "POST",
                    body,
                };
            },
        }),
    }),
});

export const {
    useGetChannelsQuery,
    useGetChannelQuery,
    useCreateChannelMutation,
    useUpdateChannelMutation,
    useDeleteChannelMutation,
    useValidateChannelsMutation,
} = channelApi;
