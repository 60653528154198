import { PacedProgressThemeColor, gray1, gray10 } from "@rubicon/antd-components";

const { DarkRed, Red, LightRed, Green, LightPurple, Purple, DarkPurple } = PacedProgressThemeColor;

export enum PacingStatusType {
    ReachedGoal = 1,
    SeverelyUnder = 2,
    ModeratelyUnder = 3,
    SlightlyUnder = 4,
    OnPace = 5,
    SlightlyOver = 6,
    ModeratelyOver = 7,
    SeverelyOver = 8,
}

interface PacingStatusAttributes {
    barColor: string;
    textColor: string;
    cellColor: string;
    cellTextColor: string;
    text: string;
    description: string;
}

const DefaultPacingStatusAttributes: PacingStatusAttributes = {
    barColor: Green,
    textColor: gray10,
    cellColor: gray1,
    cellTextColor: gray10,
    text: "Unknown pacing status",
    description: "has an unknown pacing status",
} as const;

const PacingStatusAttributesMap: Record<PacingStatusType, { color: string; text: string; description: string }> = {
    [PacingStatusType.ReachedGoal]: {
        color: Green,
        text: "Reached impression goal",
        description: "has reached impression goal",
    } as const,
    [PacingStatusType.SeverelyUnder]: {
        color: DarkRed,
        text: "Severely under pacing",
        description: "is severely under pacing",
    } as const,
    [PacingStatusType.ModeratelyUnder]: {
        color: Red,
        text: "Moderately under pacing",
        description: "is moderately under pacing",
    } as const,
    [PacingStatusType.SlightlyUnder]: {
        color: LightRed,
        text: "Slightly under pacing",
        description: "is slightly under pacing",
    } as const,
    [PacingStatusType.OnPace]: {
        color: Green,
        text: "Pacing within an acceptable range",
        description: "is on pace",
    } as const,
    [PacingStatusType.SlightlyOver]: {
        color: LightPurple,
        text: "Slightly over pacing",
        description: "is slightly over pacing",
    } as const,
    [PacingStatusType.ModeratelyOver]: {
        color: Purple,
        text: "Moderately over pacing",
        description: "is moderately over pacing",
    } as const,
    [PacingStatusType.SeverelyOver]: {
        color: DarkPurple,
        text: "Severely over pacing",
        description: "is severely over pacing",
    } as const,
} as const;

export const getPacingStatusAttributes = (pacingProgressStatusId: number | undefined): PacingStatusAttributes => {
    if (!pacingProgressStatusId || !PacingStatusAttributesMap[pacingProgressStatusId]) {
        return DefaultPacingStatusAttributes;
    }

    const { color, text, description } = PacingStatusAttributesMap[pacingProgressStatusId];
    return {
        barColor: color,
        textColor: color,
        cellColor: color,
        cellTextColor: gray1,
        text,
        description,
    };
};
