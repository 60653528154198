import { useParams } from "react-router-dom";
import { DealList, useGetDealAdSourceByIdQuery } from "@app/core/services/console/deals";
import { ROUTE_FORMATTERS } from "@app/core/routing";
import { useEntityRedirect } from "./useEntityRedirect";
import { notification } from "antd";

export const useDealRedirect = () => {
    const { dealId } = useParams<{ dealId: string }>();

    return useEntityRedirect<DealList, number>(
        Number(dealId),
        useGetDealAdSourceByIdQuery,
        "Deal",
        (data) => {
            if (!data.deal?.seat?.id) {
                notification.error({ message: "Marketplace Deals Not Currently Supported" });
                return ROUTE_FORMATTERS.LOGIN_FORM("context");
            }
            return ROUTE_FORMATTERS.SEAT_DEAL_DETAILS(data.deal.seat.id, data.deal.id);
        },
        (data: DealList | null) => data?.deal?.seat?.id
    );
};
