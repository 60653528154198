import { FC } from "react";
import { Button, Card, Flex, Form, Layout } from "antd";
import { FixedSaveButtons } from "@app/core/components";
import { MappingException } from "@app/core/services";
import { BrandSafetyDeleteModal } from "../../BrandSafetyDeleteModal";
import {
    AddOverrideIndustriesSelect,
    AddOverrideTaxonomiesSelect,
    AdomainInput,
    AdvertiserDomainOverrideInput,
    CridInput,
    DemandSelect,
    IndustryOverrideModeRadio,
    TaxonomyOverrideModeRadio,
} from "./Fields";
import { useMappingExceptionsForm } from "./useMappingExceptionsForm";

interface Props {
    mappingException?: MappingException;
}

export const MappingExceptionsForm: FC<Props> = ({ mappingException }) => {
    const { form, initialValues, deleteModalProps, fixedSaveButtonProps, onFinish, onClickDelete } =
        useMappingExceptionsForm(mappingException);

    return (
        <Form
            layout="vertical"
            style={{ width: "inherit" }}
            form={form}
            initialValues={initialValues}
            wrapperCol={{ xs: 24, md: 16 }}
            onFinish={onFinish}
        >
            <Layout.Content>
                <Card style={{ minHeight: "77vh" }} bordered={false}>
                    <Flex justify="space-between">
                        <Flex flex={1} vertical>
                            <DemandSelect />
                            <AdomainInput />
                            <CridInput />
                            <AdvertiserDomainOverrideInput />
                            <IndustryOverrideModeRadio />
                            <AddOverrideIndustriesSelect />
                            <TaxonomyOverrideModeRadio />
                            <AddOverrideTaxonomiesSelect />
                        </Flex>
                    </Flex>
                </Card>
            </Layout.Content>
            <FixedSaveButtons
                {...fixedSaveButtonProps}
                suffixAddOn={
                    mappingException ? (
                        <>
                            <Button danger data-sdet="delete-button" type="primary" onClick={onClickDelete}>
                                Delete
                            </Button>
                            <BrandSafetyDeleteModal {...deleteModalProps} />
                        </>
                    ) : undefined
                }
            />
        </Form>
    );
};
