import { Loading, SectionTitle } from "@app/core/components";
import { Publisher as IPublisher, useGetSeatSelfQuery } from "@app/core/services";
import { SnapshotsEntityTypes } from "@app/core/services/adStats/statsSnapshots";
import { EntityTypes } from "@app/features/adStats";
import { Card, Collapse, Form } from "antd";
import { FC } from "react";
import { classNameCardBody, PUBLISHER_DETAILS_SECTIONS_NAME, SECTIONS_NAMES } from "../constants";
import { HelpKeysButton } from "../HelpKeysButton";
import { Affiliate, BrandSafety, Labels, Performance, Pixels } from "../SharedSections";
import { AdvancedFeatures, General, Internal } from "./Sections";
import { FloorsTable } from "@app/features/inventory/components/FloorsTable";
import { useUserAccess } from "@app/core/auth";
import { SupplyTrafficAlertBanner } from "@app/features/inventory/components/SupplyTrafficAlertBanner";
import { useParams } from "react-router";

interface Props {
    publisher: IPublisher;
}

export const PublisherSections: FC<Props> = ({ publisher }) => {
    const { seatId } = useParams<{ seatId: string }>();
    const { data: seatSelfData } = useGetSeatSelfQuery(seatId);
    const seat = seatSelfData?.seat || null;
    const { isSysAdmin, isPubAcctMgr, isTremorUser, hasInternalAccess } = useUserAccess();
    const hasCreativeBlockingMode = publisher?.entityType === "Publisher" && isSysAdmin;
    const isAffiliateSectionShown: boolean = isTremorUser && !!seat?.affiliateCostEnabled;

    if (!publisher) return <Loading />;

    return (
        <>
            <SupplyTrafficAlertBanner sourceSelf="Publisher" />
            <Form layout="vertical" style={{ width: "100%" }} data-sdet="publisher-sections">
                <Collapse defaultActiveKey={[1, 2, 3, 4, 5, 6, 7, 8, 9]} bordered ghost={false} collapsible="header">
                    <Collapse.Panel
                        key={1}
                        header={<SectionTitle title={PUBLISHER_DETAILS_SECTIONS_NAME().performance.label} />}
                        extra={
                            <HelpKeysButton
                                label={PUBLISHER_DETAILS_SECTIONS_NAME().performance.label}
                                sectionKey={SECTIONS_NAMES.PERFORMANCE}
                            />
                        }
                    >
                        <Performance
                            id={publisher?.id}
                            adStatsEntity={EntityTypes.Publisher}
                            snapShotEntity={SnapshotsEntityTypes.Publisher}
                        />
                    </Collapse.Panel>
                    {isAffiliateSectionShown && (
                        <Collapse.Panel
                            key={2}
                            header={<SectionTitle title={PUBLISHER_DETAILS_SECTIONS_NAME().affiliate.label} />}
                            extra={
                                <HelpKeysButton
                                    label={PUBLISHER_DETAILS_SECTIONS_NAME().affiliate.helpLabel}
                                    sectionKey={SECTIONS_NAMES.AFFILIATE}
                                />
                            }
                        >
                            <Card bordered={false} className={classNameCardBody}>
                                <Affiliate unit={publisher} />
                            </Card>
                        </Collapse.Panel>
                    )}
                    <Collapse.Panel
                        key={3}
                        header={<SectionTitle title={PUBLISHER_DETAILS_SECTIONS_NAME().general.label} />}
                        extra={
                            <HelpKeysButton
                                label={PUBLISHER_DETAILS_SECTIONS_NAME().general.label}
                                sectionKey={SECTIONS_NAMES.GENERAL}
                            />
                        }
                    >
                        <Card bordered={false} className={classNameCardBody}>
                            <General />
                        </Card>
                    </Collapse.Panel>
                    <Collapse.Panel
                        key={4}
                        header={<SectionTitle title={PUBLISHER_DETAILS_SECTIONS_NAME().brandSafety.label} />}
                        extra={
                            <HelpKeysButton
                                label={PUBLISHER_DETAILS_SECTIONS_NAME().brandSafety.label}
                                sectionKey={SECTIONS_NAMES.BRAND_SAFETY}
                            />
                        }
                    >
                        <Card bordered={false} className={classNameCardBody}>
                            <BrandSafety unit={publisher} hasCreativeBlockingMode={hasCreativeBlockingMode} />
                        </Card>
                    </Collapse.Panel>
                    {isTremorUser && (
                        <Collapse.Panel
                            key={5}
                            header={<SectionTitle title={PUBLISHER_DETAILS_SECTIONS_NAME().internal.label} />}
                            extra={
                                <HelpKeysButton
                                    label={PUBLISHER_DETAILS_SECTIONS_NAME().internal.helpLabel}
                                    sectionKey={SECTIONS_NAMES.INTERNAL}
                                />
                            }
                        >
                            <Card bordered={false} className={classNameCardBody}>
                                <Internal publisher={publisher} />
                            </Card>
                        </Collapse.Panel>
                    )}
                    {(isSysAdmin || isPubAcctMgr) && (
                        <Collapse.Panel
                            key={6}
                            header={<SectionTitle title={PUBLISHER_DETAILS_SECTIONS_NAME().advancedFeatures.label} />}
                            extra={
                                <HelpKeysButton
                                    label={PUBLISHER_DETAILS_SECTIONS_NAME().advancedFeatures.helpLabel}
                                    sectionKey={SECTIONS_NAMES.ADVANCED_FEATURES}
                                />
                            }
                        >
                            <Card bordered={false} className={classNameCardBody}>
                                <AdvancedFeatures publisher={publisher} />
                            </Card>
                        </Collapse.Panel>
                    )}
                    <Collapse.Panel
                        key={7}
                        header={<SectionTitle title={PUBLISHER_DETAILS_SECTIONS_NAME().pixels.label} />}
                        extra={
                            <HelpKeysButton
                                label={PUBLISHER_DETAILS_SECTIONS_NAME().pixels.label}
                                sectionKey={SECTIONS_NAMES.PIXELS}
                            />
                        }
                    >
                        <Card bordered={false} className={classNameCardBody}>
                            <Pixels id={publisher?.id} />
                        </Card>
                    </Collapse.Panel>
                    <Collapse.Panel
                        key={8}
                        header={<SectionTitle title={PUBLISHER_DETAILS_SECTIONS_NAME().labels.label} />}
                        extra={
                            <HelpKeysButton
                                label={PUBLISHER_DETAILS_SECTIONS_NAME().labels.label}
                                sectionKey={SECTIONS_NAMES.LABELS}
                            />
                        }
                    >
                        <Card bordered={false} className={classNameCardBody}>
                            <Labels
                                labels={publisher?.labelValues}
                                internalLabelValues={publisher?.internalLabelValues}
                                isInternalOnly={hasInternalAccess}
                            />
                        </Card>
                    </Collapse.Panel>
                    <Collapse.Panel
                        key={9}
                        header={<SectionTitle title={PUBLISHER_DETAILS_SECTIONS_NAME().floors.label} />}
                    >
                        <Card bordered={false} className={classNameCardBody}>
                            <FloorsTable
                                readOnly
                                id={publisher?.id}
                                sourceSelf="Publisher"
                                hiddenColumns={["actualPrice"]}
                            />
                        </Card>
                    </Collapse.Panel>
                </Collapse>
            </Form>
        </>
    );
};
