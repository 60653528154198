import { BrandChannel, ChannelPayload, Publisher } from "@app/core/services";
import {
    AD_UNIT_ACTIVE_STATUS,
    DEFAULT_ADVERTISER_FREQUENCY_CAPPING,
    HierarchyFormsMode,
    INVENTORY_CUSTOM_CODE_TYPE,
    INVENTORY_GENERATED_CODE_TYPE,
    CHANNEL_FORM_FIELDS,
    LINEARITY_BOTH_ALLOWED,
    AD_UNIT_PRE_ROLL_TYPE,
    DEFAULT_AD_UNIT_START_DELAY,
    CUSTOM_MAX_EXTENDED,
    UNKNOWN_OPTION,
    YES_OPTION,
    NOT_SPECIFIED_OPTION,
    DEFAULT_LIVE_STREAM_ACCELERATION,
    DEFAULT_UDE_OPT_OUT,
    NONE_OPTION,
    OFF_WAIT_TIME,
} from "../constants";
import { Store } from "rc-field-form/lib/interface";
import { LabeledValue } from "antd/lib/select";
import {
    parseAdUnitAdditionalTime,
    parseAdUnitInterstitial,
    parseAdUnitLinearity,
    parseAdUnitLiveStream,
    parseAdUnitMaxBitrate,
    parseAdUnitMaxDuration,
    parseAdUnitMaxExtended,
    parseAdUnitMimes,
    parseAdUnitMinBitrate,
    parseAdUnitMinDuration,
    parseAdUnitMultiplicity,
    parseAdUnitPlacement,
    parseAdUnitSkip,
    parseAdUnitSkipafter,
    parseAdUnitSkipmin,
    parseAdUnitSsaiType,
    parseAdUnitStartDelay,
    parseAdUnitSupportedApis,
    parseAdUnitSupportedProtocols,
    parseAdUnitType,
    parseCodeValue,
    parseFreqCaps,
    parseFreqCapsToApi,
    parseHasPrivacyPolicy,
    parseIabCategories,
    parseIsCoppa,
    parseChannelPlayListDefinitionMode,
    parseAdUnitAdPodFillMode,
    parseAdUnitPodEnforcement,
    parseAdUnitSeparationMode,
    parseAdUnitSeparationGroups,
    parseBlockedAdvertiserDomains,
    parseAdvertiserBlockDomains,
    parseBuyerSeatList,
    parseBlockedIabCategories,
    parseThirdPartyPixels,
    parseLabelValues,
    parseInternalLabelValues,
    parseAdUnitMaxPodSeconds,
    parseAdUnitMaxAdsPerPod,
    parseImpressionWaitTime,
    parseAdomainFreqCappingType,
    parseLsaEnabled,
    parseLsaWindowSizeSeconds,
    parsePublisherReauction,
    parseAdBreakRules,
    parseAdBreakRulesToApi,
    parseChannelPlayListDefinitionModeToApi,
} from "../helpers";
import { PublisherChannelForm } from "./usePublisherChannelForm";
import { parseBlockedIndustriesFromApi, parseBlockedIndustriesToApi } from "@app/core/components";

interface PublisherChannelFormValuesToApi {
    isSysAdmin: boolean;
    isPubAcctMgr: boolean;
    isTremorUser: boolean;
    values: PublisherChannelForm;
    mode: Exclude<HierarchyFormsMode, "copy">;
    hasInternalAccess: boolean;
}

const parseAccountType = (mode: HierarchyFormsMode, publisher: Publisher | undefined): LabeledValue | null => {
    const isCreateMode = mode === "create";
    if (!publisher?.accountType || isCreateMode) return null;
    return { value: publisher.accountType.id, label: publisher.accountType.name };
};

interface getPublisherChannelFormInitialValues {
    mode: Exclude<HierarchyFormsMode, "copy">;
    channel?: BrandChannel;
}

export const getPublisherChannelFormInitialValue = ({ mode, channel }: getPublisherChannelFormInitialValues): Store => {
    const isCreateMode = mode === "create";
    const { preRoll, midRoll, postRoll } = parseAdBreakRules(mode, channel?.adUnit?.adBreakRules);

    return {
        // General Section
        [CHANNEL_FORM_FIELDS.NAME.name]: isCreateMode ? "" : channel?.name,
        [CHANNEL_FORM_FIELDS.CODE_TYPE.name]: isCreateMode
            ? INVENTORY_GENERATED_CODE_TYPE.value
            : INVENTORY_CUSTOM_CODE_TYPE.value,
        [CHANNEL_FORM_FIELDS.CODE.name]: isCreateMode ? "" : channel?.adUnit?.code,
        [CHANNEL_FORM_FIELDS.PUBLISHER_CODE.name]: isCreateMode ? "" : channel?.publisher?.code,
        [CHANNEL_FORM_FIELDS.DESCRIPTION.name]: isCreateMode ? "" : channel?.publisher?.description,
        [CHANNEL_FORM_FIELDS.STATUS.name]: isCreateMode ? AD_UNIT_ACTIVE_STATUS.value : channel?.adUnit?.status.id,
        [CHANNEL_FORM_FIELDS.ACCOUNT_TYPE.name]: parseAccountType(mode, channel?.publisher),
        [CHANNEL_FORM_FIELDS.BILLING_CODE.name]: isCreateMode ? "" : channel?.publisher?.billingCode,

        // Defaults Section
        [CHANNEL_FORM_FIELDS.MIMES.name]: parseAdUnitMimes(mode, channel?.adUnit),
        [CHANNEL_FORM_FIELDS.SUPPORTED_APIS.name]: parseAdUnitSupportedApis(mode, channel?.adUnit),
        [CHANNEL_FORM_FIELDS.LINEARITY.name]: parseAdUnitLinearity(mode, channel?.adUnit),
        [CHANNEL_FORM_FIELDS.TYPE.name]: parseAdUnitType(mode, channel?.adUnit),
        [CHANNEL_FORM_FIELDS.START_DELAY.name]: parseAdUnitStartDelay(mode, channel?.adUnit),
        [CHANNEL_FORM_FIELDS.INTERSTITIAL.name]: parseAdUnitInterstitial(mode, channel?.adUnit),
        [CHANNEL_FORM_FIELDS.PLACEMENT.name]: parseAdUnitPlacement(mode, channel?.adUnit),
        [CHANNEL_FORM_FIELDS.COPPA.name]: parseIsCoppa(mode, channel?.supply),
        [CHANNEL_FORM_FIELDS.PRIVACY_POLICY.name]: parseHasPrivacyPolicy(mode, channel?.supply),
        [CHANNEL_FORM_FIELDS.CATEGORIES.name]: parseIabCategories(mode, channel?.brand),
        [CHANNEL_FORM_FIELDS.SUPPORTED_PROTOCOLS.name]: parseAdUnitSupportedProtocols(mode, channel?.adUnit),
        [CHANNEL_FORM_FIELDS.MIN_DURATION.name]: parseAdUnitMinDuration(mode, channel?.adUnit),
        [CHANNEL_FORM_FIELDS.MAX_DURATION.name]: parseAdUnitMaxDuration(mode, channel?.adUnit),
        [CHANNEL_FORM_FIELDS.MAX_EXTENDED.name]: parseAdUnitMaxExtended(mode, channel?.adUnit),
        [CHANNEL_FORM_FIELDS.ADDITIONAL_TIME.name]: parseAdUnitAdditionalTime(mode, channel?.adUnit),
        [CHANNEL_FORM_FIELDS.MIN_BITRATE.name]: parseAdUnitMinBitrate(mode, channel?.adUnit),
        [CHANNEL_FORM_FIELDS.MAX_BITRATE.name]: parseAdUnitMaxBitrate(mode, channel?.adUnit),
        [CHANNEL_FORM_FIELDS.SSAI_TYPE.name]: parseAdUnitSsaiType(mode, channel?.adUnit),
        [CHANNEL_FORM_FIELDS.LIVE_STREAM.name]: parseAdUnitLiveStream(mode, channel?.adUnit),
        [CHANNEL_FORM_FIELDS.SKIPPABLE.name]: parseAdUnitSkip(mode, channel?.adUnit),
        [CHANNEL_FORM_FIELDS.SKIPPABLE_AFTER.name]: parseAdUnitSkipafter(mode, channel?.adUnit),
        [CHANNEL_FORM_FIELDS.SKIPPABLE_MINIMUM.name]: parseAdUnitSkipmin(mode, channel?.adUnit),
        [CHANNEL_FORM_FIELDS.MULTIPLICITY.name]: parseAdUnitMultiplicity(mode, channel?.adUnit),

        // Ad Pod Section
        [CHANNEL_FORM_FIELDS.MAX_POD_SECONDS.name]: parseAdUnitMaxPodSeconds(mode, channel?.adUnit),
        [CHANNEL_FORM_FIELDS.MAX_ADS_PER_POD.name]: parseAdUnitMaxAdsPerPod(mode, channel?.adUnit),

        //Playlist Configuration
        [CHANNEL_FORM_FIELDS.FILL_MODE.name]: parseAdUnitAdPodFillMode(mode, channel?.adUnit),
        [CHANNEL_FORM_FIELDS.POD_ENFORCEMENT.name]: parseAdUnitPodEnforcement(mode, channel?.adUnit),
        [CHANNEL_FORM_FIELDS.COMPETITIVE_SEPARATION_MODE.name]: parseAdUnitSeparationMode(mode, channel?.adUnit),
        [CHANNEL_FORM_FIELDS.COMPETITIVE_SEPARATIO_GROUPS.name]: parseAdUnitSeparationGroups(mode, channel?.adUnit),
        [CHANNEL_FORM_FIELDS.MAX_ADS_PER_ADV.name]: isCreateMode ? null : channel?.adUnit?.maxAdsPerAdvertiser,
        [CHANNEL_FORM_FIELDS.PLAYLIST_DEFINITION_MODE.name]: parseChannelPlayListDefinitionMode(
            mode,
            channel?.adUnit?.playlistDefinitionMode?.id
        ),

        //Ad Break Rules Section
        [CHANNEL_FORM_FIELDS.AD_BREAK_RULES_PRE.name]: preRoll,
        [CHANNEL_FORM_FIELDS.AD_BREAK_RULES_MID.name]: midRoll,
        [CHANNEL_FORM_FIELDS.AD_BREAK_RULES_POST.name]: postRoll,
        [CHANNEL_FORM_FIELDS.CUE_POINTS.name]: isCreateMode ? [] : channel?.adUnit?.cuepoints,

        // Brand Safety
        [CHANNEL_FORM_FIELDS.BLOCK_ADVERTISER_DOMAIN_LISTS.name]: parseBlockedAdvertiserDomains(
            mode,
            channel?.publisher
        ),
        [CHANNEL_FORM_FIELDS.BLOCKED_ADVERTISER_DOMAINS.name]: parseAdvertiserBlockDomains(mode, channel?.publisher),
        [CHANNEL_FORM_FIELDS.BLOCK_BUYER_SEAT_LIST.name]: parseBuyerSeatList(mode, channel?.publisher),
        [CHANNEL_FORM_FIELDS.BLOCKED_CATEGORIES.name]: parseBlockedIabCategories(mode, channel?.brand),
        [CHANNEL_FORM_FIELDS.BLOCKED_INDUSTRIES.name]: parseBlockedIndustriesFromApi(
            channel?.brand?.blockedIndustries,
            mode
        ),

        //Detailed Configuration
        [CHANNEL_FORM_FIELDS.NO_AD_SYNC.name]: isCreateMode ? true : channel?.supply.enableNoAdCookieSync,
        [CHANNEL_FORM_FIELDS.PUBLISHER_NAME.name]: isCreateMode ? "" : channel?.publisher?.publisherNameOverride,
        [CHANNEL_FORM_FIELDS.SUPPLY_NAME.name]: isCreateMode ? "" : channel?.supply?.name,
        [CHANNEL_FORM_FIELDS.BUSINESS_DOMAIN.name]: isCreateMode ? "" : channel?.publisher?.businessDomain,
        [CHANNEL_FORM_FIELDS.PARTNER_DOMAIN.name]: isCreateMode ? "" : channel?.supply?.inventoryPartnerDomain,
        [CHANNEL_FORM_FIELDS.ALLOW_VAST_WRAPPERS.name]: isCreateMode ? true : channel?.supply?.allowVastWrappers,
        [CHANNEL_FORM_FIELDS.LIVE_STREAM_ACCELERATION.name]: parseLsaEnabled(mode, channel?.adUnit),
        [CHANNEL_FORM_FIELDS.LSA_WINDOW_SIZE_SECONDS.name]: parseLsaWindowSizeSeconds(mode, channel?.adUnit),
        [CHANNEL_FORM_FIELDS.UNWRAP_VAST.name]: isCreateMode ? false : channel?.adUnit?.unwrapVast,
        [CHANNEL_FORM_FIELDS.EXTENDED_IMP_WAIT_TIME.name]: parseImpressionWaitTime(mode, channel?.adUnit),
        [CHANNEL_FORM_FIELDS.ADV_AS_WHITELIST.name]: channel?.brand.enableAdvertisersAsWhitelist || false,
        [CHANNEL_FORM_FIELDS.ADV_FREQ_CAPP.name]: parseAdomainFreqCappingType(mode, channel?.adUnit),
        [CHANNEL_FORM_FIELDS.ADV_DOMAIN_FREQ_CAPPS.name]: parseFreqCaps(mode, channel?.adUnit?.adomainFreqCappings),
        [CHANNEL_FORM_FIELDS.PUBLISHER_RE_AUCTION.name]: parsePublisherReauction(mode, channel?.adUnit),
        [CHANNEL_FORM_FIELDS.UD_OPT_OUT.name]: isCreateMode ? DEFAULT_UDE_OPT_OUT : channel?.adUnit?.udeOptOut,

        // Custom Pixels Section
        [CHANNEL_FORM_FIELDS.CUSTOM_PIXELS.name]: parseThirdPartyPixels(mode, channel?.publisher),
        //
        // Labels Section
        [CHANNEL_FORM_FIELDS.LABELS.name]: parseLabelValues(mode, channel?.publisher),
        [CHANNEL_FORM_FIELDS.INTERNAL_LABELS.name]: parseInternalLabelValues(mode, channel?.publisher),
    };
};

export const parsePublisherChannelFormValuesToApi = ({
    values,
    isSysAdmin,
    isPubAcctMgr,
    isTremorUser,
    hasInternalAccess,
}: PublisherChannelFormValuesToApi): ChannelPayload => {
    const {
        // Genral
        name,
        codeType,
        code,
        status,
        description,
        accountType,
        billingCode,
        //Defaults
        mimes,
        supportedApis,
        linearity,
        type,
        startDelay,
        isInterstitial,
        placement,
        isCoppa,
        hasPrivacyPolicy,
        iabCategories,
        supportedProtocols,
        minDuration,
        maxDuration,
        maxExtended,
        additionalTime,
        maxBitrate,
        minBitrate,
        ssaiType,
        isLiveStream,
        skip,
        skipafter,
        skipmin,
        multiplicity,
        // Ad Pod Section
        maxPodSeconds,
        maxAdsPerPod,
        // Playlist Configuration
        adPodFillMode,
        podEnforcement,
        separationGroups,
        maxAdsPerAdvertiser,
        playlistDefinitionMode,
        //Ad Break Rules Section
        adBreakRulesPre,
        adBreakRulesMid,
        adBreakRulesPost,
        cuepoints,
        // Brand Safety Section
        seatAdvertiserDomainFilterListDefs,
        advertiserBlockDomains,
        buyerSeatList,
        blockedIabCategories,
        blockedIndustries,
        // Detailed Configuration
        publisherNameOverride,
        supplyNameOverride,
        businessDomain,
        inventoryPartnerDomain,
        allowVastWrappers,
        unwrapVast,
        impressionWaitTime,
        enableAdvertisersAsWhitelist,
        adomainFreqCappingType,
        adomainFreqCappings,
        enableNoAdCookieSync,
        lsaWindowSizeSeconds,
        lsaEnabled,
        publisherReauction,
        udeOptOut,
        // Custom Pixels
        thirdPartyPixels,
        // Labels
        labelValues,
        internalLabelValues,
    } = values;

    const payload: ChannelPayload = {
        name,
        adUnit: {
            //General Section
            code: parseCodeValue(codeType, code),
            status: {
                id: status,
            },
            //Detailed Configuration Section
            impressionWaitTime:
                impressionWaitTime.value === OFF_WAIT_TIME.value ? null : { id: Number(impressionWaitTime.value) },
            adomainFreqCappingType: {
                id: adomainFreqCappingType.value,
            },
            unwrapVast: unwrapVast,
            lsaEnabled: lsaEnabled || DEFAULT_LIVE_STREAM_ACCELERATION,
            lsaWindowSizeSeconds: lsaWindowSizeSeconds || null,
            udeOptOut,
            // Defaults Section
            mimes: (mimes || []).map((option) => ({
                id: option.value as number,
                name: option.label as string,
            })),
            supportedApis: (supportedApis || []).map((option) => ({
                id: option.value as number,
                name: option.label as string,
            })),
            linearity: linearity === LINEARITY_BOTH_ALLOWED.value ? null : { id: linearity as number },
            type: type
                ? { id: type.value as number, name: type.label as string }
                : { id: AD_UNIT_PRE_ROLL_TYPE.value, name: AD_UNIT_PRE_ROLL_TYPE.label },
            startDelay: startDelay ?? DEFAULT_AD_UNIT_START_DELAY,
            isInterstitial,
            placement: { id: placement.value as number, name: placement.label as string },
            supportedProtocols: (supportedProtocols || []).map((option) => ({
                id: option.value as number,
                name: option.label as string,
            })),
            minDuration,
            maxDuration,
            minBitrate,
            maxBitrate,
            maxExtended: maxExtended === CUSTOM_MAX_EXTENDED.value ? additionalTime : (maxExtended as number),
            ssaiType:
                ssaiType.value !== NONE_OPTION.value
                    ? { id: ssaiType.value as number, name: ssaiType.label as string }
                    : null,
            isLiveStream,
            skip: skip === UNKNOWN_OPTION.value ? null : (skip as boolean),
            skipafter: skip === YES_OPTION.value ? skipafter : null,
            skipmin: skip === YES_OPTION.value ? skipmin : null,
            multiplicity: { id: multiplicity },
            //Pod
            maxPodSeconds: maxPodSeconds || null,
            maxAdsPerPod: maxAdsPerPod || null,
            //Playlist Configuration Section
            adPodFillMode: adPodFillMode ? { id: adPodFillMode } : null,
            //TODO figure out none value
            podEnforcement: typeof podEnforcement === "number" ? { id: podEnforcement } : null,
            separationGroups: (separationGroups || []).map(({ value, label, iabCategories, industries }) => ({
                id: value,
                name: label,
                iabCategories,
                industries,
            })),
            maxAdsPerAdvertiser: maxAdsPerAdvertiser ? maxAdsPerAdvertiser : null,
            playlistDefinitionMode: playlistDefinitionMode
                ? parseChannelPlayListDefinitionModeToApi(playlistDefinitionMode)
                : null,
            //Ad Break Rules Section
            adBreakRules: parseAdBreakRulesToApi(adBreakRulesPre, adBreakRulesMid, adBreakRulesPost),
            cuepoints: cuepoints ? cuepoints : null,
        },
        brand: {
            //Defaults Section
            iabCategories: iabCategories?.length
                ? (iabCategories || []).map((option) => ({
                      id: option.value as number,
                  }))
                : null,
            //Detailed Configuration Section
            enableAdvertisersAsWhitelist: Boolean(enableAdvertisersAsWhitelist),
            //Brand Safety Section
            blockedIabCategories: (blockedIabCategories || []).map((option) => ({
                id: option.value as number,
            })),
            blockedIndustries: parseBlockedIndustriesToApi(blockedIndustries),
        },
        publisher: {
            //General Section
            name,
            description: description,
            billingCode: billingCode,
            //Detailed Configuration Section
            businessDomain: businessDomain,
            publisherNameOverride: publisherNameOverride,
            // Brand Safety Section
            seatAdvertiserDomainFilterListDefs: (seatAdvertiserDomainFilterListDefs || []).map((option) => ({
                id: option.value as number,
                name: option.label as string,
            })),
            advertiserBlockDomains: advertiserBlockDomains.trim() ? advertiserBlockDomains.trim().split("\n") : null,
            buyerSeatList: buyerSeatList
                ? { id: buyerSeatList.value as number, name: buyerSeatList.label as string }
                : null,
            blockedIabCategories: (blockedIabCategories || []).map((option) => ({
                id: option.value as number,
            })),
            blockedIndustries: parseBlockedIndustriesToApi(blockedIndustries),
            // Custom Pixels
            thirdPartyPixels: (thirdPartyPixels || []).map((pixel) => ({
                id: pixel.value as number,
                name: pixel.label as string,
            })),
            // Labels
            labelValues: (labelValues || []).map(({ value, seatLabel, labelValue }) => ({
                id: value,
                value: labelValue,
                label: seatLabel,
            })),
        },

        supply: {
            //Defaults Section
            isCoppa: isCoppa === NOT_SPECIFIED_OPTION.value ? null : (isCoppa as boolean),
            hasPrivacyPolicy: hasPrivacyPolicy === NOT_SPECIFIED_OPTION.value ? null : (hasPrivacyPolicy as boolean),
            //Detailed Configuration Section
            allowVastWrappers: allowVastWrappers,
            supplyNameOverride: supplyNameOverride,
            inventoryPartnerDomain: inventoryPartnerDomain,
        },
    };
    if (isTremorUser) {
        Object.assign(payload.supply, {
            //Detailed Configuration Section Internal Only
            enableNoAdCookieSync,
        });
        if (isPubAcctMgr || isSysAdmin)
            //Detailed Configuration Section Internal Only
            Object.assign(payload.adUnit, {
                publisherReauction: publisherReauction === NOT_SPECIFIED_OPTION.value ? null : publisherReauction,
            });
    }

    if ((isPubAcctMgr || isSysAdmin) && accountType) {
        Object.assign(payload.adUnit, {
            //General Section
            accountType: accountType ? { id: accountType.value } : null,
        });
    }

    if (adomainFreqCappingType.value !== DEFAULT_ADVERTISER_FREQUENCY_CAPPING.value) {
        Object.assign(payload.adUnit, {
            //Detailed Configuration Section
            adomainFreqCappings: parseFreqCapsToApi(adomainFreqCappings),
        });
    }

    if (hasInternalAccess)
        Object.assign(payload.publisher, {
            // Labels
            internalLabelValues: (internalLabelValues || []).map(({ value, seatLabel, labelValue }) => ({
                id: value,
                value: labelValue,
                label: seatLabel,
            })),
        });

    return payload;
};
