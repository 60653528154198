import { useAppDispatch, useAppSelector } from "@app/core/store";
import { LabeledValue } from "antd/lib/select";
import { setFiltersSeatAdSourcesStatus } from "../../reducer";
import { PacingStatusType } from "@app/core/components/PacingProgressBar/utils";

interface UseSeatAdSourcesPacingStatusFilter {
    handleChangeValue: (value: string | number | undefined, option: LabeledValue | LabeledValue[]) => void;
    options: LabeledValue[];
    value: LabeledValue[] | string | null;
    isLoading: boolean;
}

const options = [
    { label: "Reached Goal", value: PacingStatusType.ReachedGoal },
    { label: "Severely Under Pace", value: PacingStatusType.SeverelyUnder },
    { label: "Moderately Under Pace", value: PacingStatusType.ModeratelyUnder },
    { label: "Slightly Under Pace", value: PacingStatusType.SlightlyUnder },
    { label: "On Pace", value: PacingStatusType.OnPace },
    { label: "Slightly Over Pace", value: PacingStatusType.SlightlyOver },
    { label: "Moderately Over Pace", value: PacingStatusType.ModeratelyOver },
    { label: "Severely Over Pace", value: PacingStatusType.SeverelyOver },
];

export const useSeatAdSourcesPacingStatusFilter = (): UseSeatAdSourcesPacingStatusFilter => {
    const dispatch = useAppDispatch();
    const seatAdSourcesPacingStatus = useAppSelector((state) => state.seatAdSourcesPacing.filters.seatAdSourcesStatus);

    const handleChangeValue = (_, option: LabeledValue[]) => {
        dispatch(setFiltersSeatAdSourcesStatus(option));
    };

    return {
        handleChangeValue,
        options,
        value: seatAdSourcesPacingStatus,
        isLoading: false,
    };
};
