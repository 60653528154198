import { Form } from "antd";
import { useMemo } from "react";
import { Seat } from "@app/core/services";
import { useUserAccess } from "@app/core/auth";
import { INVENTORY_FORM_FIELDS } from "@app/features/inventory/InventorySeat/constants";

const { useWatch } = Form;

interface UseFinancialInternalOnlyProps {
    edit: boolean;
    seat: Seat;
}

interface UseFinancialInternalOnly {
    affiliateCostEnabledShown: boolean;
    affiliateCostModelValueShown: boolean;
}

export const useFinancialInternalOnly = ({ seat, edit }: UseFinancialInternalOnlyProps): UseFinancialInternalOnly => {
    const { isTremorUser } = useUserAccess();
    const isAffiliateCostEnabled = useWatch(INVENTORY_FORM_FIELDS.AFFILIATE_COST_ENABLED.name);

    const affiliateCostModelValueShown: boolean = useMemo<boolean>(() => {
        if (!edit) return seat.affiliateCostEnabled && isTremorUser;
        return isAffiliateCostEnabled && isTremorUser;
    }, [edit, isAffiliateCostEnabled, isTremorUser, seat]);

    return {
        affiliateCostModelValueShown,
        affiliateCostEnabledShown: isTremorUser,
    };
};
