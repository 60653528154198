import { FC } from "react";
import { Card, Flex, Form, Tag } from "antd";
import { css } from "@emotion/css";
import moment from "moment-timezone";
import { format } from "@rubicon/utils";
import { Industries } from "@app/core/components";
import { YEAR_MONTH_DAY_HOUR_MINUTE_AMPM } from "@app/core/components/constants";
import { MappingException } from "@app/core/services";
import { FORM_FIELDS } from "./constants";
import { getOverrideModeLabel } from "./utils";

const { asSelf } = format;
const { DEFAULT_DASHES } = format.constants;
const {
    DEMAND,
    ADOMAIN,
    CRID,
    ADVERTISER_DOMAIN_OVERRIDE,
    INDUSTRY_OVERRIDE_MODE,
    ADD_OVERRIDE_INDUSTRIES,
    TAXONOMY_OVERRIDE_MODE,
    ADD_OVERRIDE_TAXONOMIES,
} = FORM_FIELDS;

interface Props {
    mappingException: MappingException;
}

export const MappingExceptionsReadOnlyForm: FC<Props> = ({ mappingException }) => {
    const {
        network,
        adomain,
        crid,
        adomainOverride,
        industryOverrideMode,
        industryOverrideList,
        taxonomyOverrideMode,
        taxonomyOverrideList,
        updateDate,
        creationDate,
    } = mappingException;

    return (
        <Card
            type="inner"
            bordered={false}
            className={css`
                &.ant-card {
                    box-shadow: none;
                }
            `}
        >
            <Flex justify="space-between">
                <Form layout="vertical">
                    <Form.Item label={DEMAND.label}>{asSelf(network.name)}</Form.Item>
                    <Form.Item label={ADOMAIN.label}>{asSelf(adomain)}</Form.Item>
                    <Form.Item label={CRID.label}>{asSelf(crid)}</Form.Item>
                    <Form.Item label={ADVERTISER_DOMAIN_OVERRIDE.label}>{asSelf(adomainOverride)}</Form.Item>
                    <Form.Item label={INDUSTRY_OVERRIDE_MODE.label}>{asSelf(industryOverrideMode.name)}</Form.Item>
                    <Form.Item label={getOverrideModeLabel(ADD_OVERRIDE_INDUSTRIES.label, industryOverrideMode.id)}>
                        <Industries industries={industryOverrideList} />
                    </Form.Item>
                    <Form.Item label={TAXONOMY_OVERRIDE_MODE.label}>{asSelf(taxonomyOverrideMode.name)}</Form.Item>
                    <Form.Item label={getOverrideModeLabel(ADD_OVERRIDE_TAXONOMIES.label, taxonomyOverrideMode.id)}>
                        {taxonomyOverrideList?.length
                            ? taxonomyOverrideList.map(({ id, name }) => <Tag key={id}>{name}</Tag>)
                            : DEFAULT_DASHES}
                    </Form.Item>
                    <Form.Item label="Last Updated (UTC)">
                        {moment.utc(updateDate).format(YEAR_MONTH_DAY_HOUR_MINUTE_AMPM)}
                    </Form.Item>
                    <Form.Item label="Date Created (UTC)">
                        {moment.utc(creationDate).format(YEAR_MONTH_DAY_HOUR_MINUTE_AMPM)}
                    </Form.Item>
                </Form>
            </Flex>
        </Card>
    );
};
