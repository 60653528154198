import { Industries, SectionTitle } from "@app/core/components";
import { Collapse, Form, Input, Tag } from "antd";
import { FORM_NAMES, HELP_KEYS, SECTION_NAMES } from "../constants";
import { AdvancedBrandSafetyItem } from "@app/core/services";
import { FC } from "react";
import { HelpKeysButton } from "@app/core/components/HelpKeysDrawer";
import { NONE } from "@app/core/components/constants";
import { css } from "@emotion/css";
import { ReadOnlyBrandSafetyTargeting } from "@app/features/targeting/ReadOnlyTargeting/ReadOnlyBrandSafetyTargeting";

export const advancedBrandSafetyCollapseClassList = css`
    &.ant-collapse .ant-collapse-content > .ant-collapse-content-box {
        padding-left: 1.5rem;
    }
`;

interface Props {
    brandSafety: AdvancedBrandSafetyItem;
    handleDefinition: (type: SECTION_NAMES) => void;
}
export const AdvancedBrandSafetyReadOnlyForm: FC<Props> = ({ brandSafety, handleDefinition }) => (
    <Collapse
        defaultActiveKey={["1", "2"]}
        collapsible="header"
        className={advancedBrandSafetyCollapseClassList}
        items={[
            {
                key: "1",
                label: <SectionTitle title={HELP_KEYS[SECTION_NAMES.GENERAL].label} />,
                extra: (
                    <HelpKeysButton
                        onClick={() => handleDefinition(SECTION_NAMES.GENERAL)}
                        buttonName={HELP_KEYS[SECTION_NAMES.GENERAL].label}
                    />
                ),
                children: (
                    <Form layout="vertical">
                        <Form.Item label={FORM_NAMES.NAME.label}>{brandSafety.name}</Form.Item>
                        <Form.Item label={FORM_NAMES.TYPE.label}>{brandSafety.type.name}</Form.Item>
                        <Form.Item label={FORM_NAMES.IAB_CATEGORIES.label}>
                            {brandSafety.blockedIabCategories.length
                                ? brandSafety.blockedIabCategories.map(({ name, id }) => <Tag key={id}>{name}</Tag>)
                                : NONE}
                        </Form.Item>
                        <Form.Item label={FORM_NAMES.IAB_EXCEPTIONS_CATEGORIES.label}>
                            {brandSafety.blockExceptionIabCategories.length
                                ? brandSafety.blockExceptionIabCategories.map(({ name, id }) => (
                                      <Tag key={id}>{name}</Tag>
                                  ))
                                : NONE}
                        </Form.Item>
                        <Form.Item label={FORM_NAMES.BLOCKED_INDUSTRIES.label}>
                            <Industries industries={brandSafety.blockedIndustries} />
                        </Form.Item>
                        <Form.Item label={FORM_NAMES.REUSABLE_ADV_DOMAINS.label}>
                            {brandSafety.seatAdvertiserDomainFilterListDefs.length
                                ? brandSafety.seatAdvertiserDomainFilterListDefs.map(({ name, id }) => (
                                      <Tag key={id}>{name}</Tag>
                                  ))
                                : NONE}
                        </Form.Item>

                        <Form.Item
                            label={`${FORM_NAMES.ADDT_ADV_DOMAINS.label} ${
                                brandSafety.advertiserDomains.length
                                    ? "(count: " + brandSafety.advertiserDomains.length + ")"
                                    : ""
                            }`}
                        >
                            {brandSafety.advertiserDomains.length ? (
                                <Input.TextArea
                                    value={brandSafety.advertiserDomains.map((name) => name).join("\n")}
                                    autoSize
                                    readOnly
                                />
                            ) : (
                                NONE
                            )}
                        </Form.Item>
                    </Form>
                ),
            },
            {
                key: "2",
                label: <SectionTitle title={HELP_KEYS[SECTION_NAMES.TARGETING].label} />,
                extra: (
                    <HelpKeysButton
                        onClick={() => handleDefinition(SECTION_NAMES.TARGETING)}
                        buttonName={HELP_KEYS[SECTION_NAMES.TARGETING].label}
                    />
                ),
                children: (
                    <ReadOnlyBrandSafetyTargeting
                        targeting={brandSafety.targeting}
                        targetingMode={brandSafety.targetingMode}
                    />
                ),
            },
        ]}
    />
);
