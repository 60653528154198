import { useUserAccess } from "@app/core/auth";
import { ROUTES } from "@app/core/routing";
import { Route, Switch } from "react-router-dom";
import { TargetingChangeHistoryPage } from "./TargetingChangeHistoryPage";
import { TargetingCreatePage } from "./TargetingCreatePage";
import { TargetingDetailsPage } from "./TargetingDetailsPage";
import { TargetingEditPage } from "./TargetingEditPage";
import { TargetingPage } from "./TargetingPage";

export const TargetingSwitch = () => {
    const { canEditSeat } = useUserAccess();
    return (
        <Switch>
            <Route exact path={[ROUTES.SEAT_CONTROLS_TARGETING, ROUTES.SEAT_CONTROLS_TARGETING_DETAILS_DRAWER]}>
                <TargetingPage />
            </Route>
            {canEditSeat && (
                <Route exact path={ROUTES.SEAT_CONTROLS_TARGETING_EDIT}>
                    <TargetingEditPage />
                </Route>
            )}
            {canEditSeat && (
                <Route exact path={ROUTES.SEAT_CONTROLS_TARGETING_CREATE}>
                    <TargetingCreatePage />
                </Route>
            )}
            <Route exact path={ROUTES.SEAT_CONTROLS_TARGETING_CHANGE_HISTORY}>
                <TargetingChangeHistoryPage />
            </Route>
            <Route exact path={ROUTES.SEAT_CONTROLS_TARGETING_DETAILS}>
                <TargetingDetailsPage />
            </Route>
        </Switch>
    );
};
