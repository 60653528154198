import { Form, FormInstance, notification, RadioChangeEvent } from "antd";
import {
    BRAND_SAFETY_BLOCK_TYPE,
    CREATE_BRAND_SAFETY_FORM_ITEMS_NAME,
} from "@app/features/inventory/InventoryBrandSafety/InventoryAdvancedBrandSafetyPage/constants";
import { useWatch } from "antd/lib/form/Form";
import {
    AdvancedBrandSafety,
    AdvancedBrandSafetyPayload,
    useCreateAdvancedBrandSafetyMutation,
    useDeleteAdvancedBrandSafetyMutation,
    useGetAdvancedBrandSafetyByIdQuery,
    useUpdateAdvancedBrandSafetyMutation,
} from "@app/core/services";
import { useHistory, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useTargetingForm } from "@app/features/targeting/useTargetingForm";
import { TargetingDimensionTypeLabels, TargetingFormKeys } from "@app/features/targeting/constants";
import { useAppDispatch } from "@app/core/store";
import { resetTargetingBlocksByFormKey } from "@app/features/targeting/reducer";
import {
    InventoryAdvancedBrandSafetyPageFormMode,
    InventoryAdvancedBrandSafetyPageFormSource,
} from "./InventoryAdvancedBrandSafetyPageForm";
import { ROUTE_FORMATTERS } from "@app/core/routing";
import { BRAND_SAFETY_ADVANCEDL_KEY } from "../InventoryBrandSafetyPage";
import { formatCategoryLabel } from "@app/features/targeting";
import { useBrandSafetyDeleteModal } from "@app/features/controls/brandSafety/BrandSafetyDeleteModal/useBrandSafetyDeleteModal";
import { CONTROLS_ROUTES } from "@app/features/controls/constants";
import { parseBlockedIndustriesFromApi, parseBlockedIndustriesToApi } from "@app/core/components";

const getPageTitle = (mode: InventoryAdvancedBrandSafetyPageFormMode, name?: string) => {
    switch (mode) {
        case InventoryAdvancedBrandSafetyPageFormMode.CREATE:
            return "Add Advanced Brand Safety";

        case InventoryAdvancedBrandSafetyPageFormMode.EDIT:
            return `Edit: ${name}`;

        default:
            return "No title";
    }
};

const getAdvertiserDomains = (advertiserDomains: string): Array<string> => {
    if (advertiserDomains?.trim() === "") {
        return [];
    }
    return advertiserDomains?.split("\n");
};

const initialFormData = {
    advertiserDomains: null,
    blockedIabCategories: [],
    id: null,
    name: null,
    seat: null,
    seatAdvertiserDomainFilterListDefs: [],
    targeting: null,
    targetingMode: null,
    type: BRAND_SAFETY_BLOCK_TYPE.value,
    blockedIndustries: null,
};

interface UseInventoryAdvancedBrandSafetyPageForm {
    title: string;
    isLoading: boolean;
    form: FormInstance;
    confirmModal: boolean;
    handleSubmit: () => void;
    handleCancel: () => void;
    handleConfirmModal: () => void;
    handleCloseModal: () => void;
    handleChangeBrandSafetyType: (e: RadioChangeEvent) => void;
    handleChangeReusableAdvertiserDomains: (value: Array<number>) => void;
    type: number;
    isSubmitting: boolean;
    initialFormData: typeof initialFormData;
    deleteModalProps: Omit<ReturnType<typeof useBrandSafetyDeleteModal>, "handleOpen">;
    handleDeleteOpen: () => void;
    isDeleting: boolean;
}

export const useInventoryAdvancedBrandSafetyPageForm = (
    mode: InventoryAdvancedBrandSafetyPageFormMode,
    id?: number,
    source?: InventoryAdvancedBrandSafetyPageFormSource
): UseInventoryAdvancedBrandSafetyPageForm => {
    const [form] = Form.useForm();
    const dispatch = useAppDispatch();
    const type = useWatch<number>(CREATE_BRAND_SAFETY_FORM_ITEMS_NAME.TYPE, form);
    const blockType: boolean = type === BRAND_SAFETY_BLOCK_TYPE.value;
    const history = useHistory();

    const [confirmModal, setConfirmModal] = useState<boolean>(false);
    const { data: brand, isFetching } = useGetAdvancedBrandSafetyByIdQuery(Number(id), { skip: !Boolean(id) });

    const [createBrandSafety, { isLoading: isBrandSafetyCreating }] = useCreateAdvancedBrandSafetyMutation();
    const [updateBrandSafety, { isLoading: isBrandSafetyUpdating }] = useUpdateAdvancedBrandSafetyMutation();
    const [deleteTrigger, { isLoading: isDeleting }] = useDeleteAdvancedBrandSafetyMutation();

    const { seatId } = useParams<{ seatId: string }>();
    const { targetingPayloadsByFormKey, targetingModePayloadsByFormKey, loadTargeting } = useTargetingForm(
        TargetingFormKeys.BrandSafety
    );

    const handleCancel = (): void => {
        if (source === InventoryAdvancedBrandSafetyPageFormSource.CONTROLS) {
            history.push(
                ROUTE_FORMATTERS.SEAT_CONTROLS_BRAND_SAFETY_TABS(seatId, CONTROLS_ROUTES.ADVANCED_BRAND_SAFETY)
            );
            return;
        }
        history.push(ROUTE_FORMATTERS.SEAT_INVENTORY_BRAND_SAFETY_TABS(seatId, BRAND_SAFETY_ADVANCEDL_KEY));
    };

    const { handleOpen, ...restModalProps } = useBrandSafetyDeleteModal(deleteTrigger, handleCancel);
    const handleDeleteOpen = (): void => handleOpen({ id: brand?.id as number, name: brand?.name as string });

    const handleAdvancedBrandSafetyPage = (id?: string | number): void => {
        if (source === InventoryAdvancedBrandSafetyPageFormSource.CONTROLS) {
            history.push(
                ROUTE_FORMATTERS.SEAT_CONTROLS_BRAND_SAFETY_ADVANCED_BRAND_SAFETY_DETAILS_PAGE(seatId, Number(id))
            );
            return;
        }
        history.push(ROUTE_FORMATTERS.SEAT_INVENTORY_BRAND_SAFETY_TABS(seatId, BRAND_SAFETY_ADVANCEDL_KEY));
    };
    const onCreateBrandSafety = async (seatId: number, body: AdvancedBrandSafetyPayload) => {
        await createBrandSafety({ id: seatId, body })
            .unwrap()
            .then((brand: AdvancedBrandSafety) => {
                dispatch(resetTargetingBlocksByFormKey({ formKey: TargetingFormKeys.BrandSafety }));
                form.setFieldsValue(initialFormData);
                notification.success({ message: `You created brand safety ${brand?.name}` });
                handleAdvancedBrandSafetyPage(brand.id);
            })
            .catch((error) => {
                notification.error({ message: error?.data?.errorDescription });
            });
    };

    const handleCreate = async (): Promise<void> => {
        await form.validateFields();

        const {
            name,
            type,
            advertiserDomains,
            blockedIabCategories,
            blockExceptionIabCategories,
            blockedIndustries,
            seatAdvertiserDomainFilterListDefs,
        } = form.getFieldsValue(Object.values(CREATE_BRAND_SAFETY_FORM_ITEMS_NAME));

        const body: AdvancedBrandSafetyPayload = {
            name: name.trim(),
            type: { id: type },
            id: initialFormData.id,
            seat: { id: Number(seatId) },
            advertiserDomains: getAdvertiserDomains(advertiserDomains),
            blockedIabCategories: blockType ? (blockedIabCategories || []).map(({ value }) => ({ id: value })) : [],
            blockExceptionIabCategories: blockType
                ? (blockExceptionIabCategories || []).map(({ value }) => ({ id: value }))
                : [],
            blockedIndustries: blockType ? parseBlockedIndustriesToApi(blockedIndustries) : null,
            seatAdvertiserDomainFilterListDefs: seatAdvertiserDomainFilterListDefs?.map((id: number) => ({ id })) ?? [],
            targeting: targetingPayloadsByFormKey[TargetingFormKeys.BrandSafety],
            targetingMode: targetingModePayloadsByFormKey[TargetingFormKeys.BrandSafety],
        };
        await onCreateBrandSafety(Number(seatId), body);
    };

    const handleUpdate = async (brand: AdvancedBrandSafety): Promise<void> => {
        await form.validateFields();

        const {
            name,
            type,
            advertiserDomains,
            blockedIabCategories,
            blockedIndustries,
            blockExceptionIabCategories,
            seatAdvertiserDomainFilterListDefs,
        } = form.getFieldsValue(Object.values(CREATE_BRAND_SAFETY_FORM_ITEMS_NAME));

        const body: AdvancedBrandSafetyPayload = {
            name,
            id: brand?.id,
            type: { id: type },
            seat: { id: Number(seatId) },
            advertiserDomains: getAdvertiserDomains(advertiserDomains),
            blockedIabCategories: blockType ? (blockedIabCategories || []).map(({ value }) => ({ id: value })) : [],
            blockExceptionIabCategories: blockType
                ? (blockExceptionIabCategories || []).map(({ value }) => ({ id: value }))
                : [],
            blockedIndustries: blockType ? parseBlockedIndustriesToApi(blockedIndustries) : null,
            seatAdvertiserDomainFilterListDefs: seatAdvertiserDomainFilterListDefs?.map((id: number) => ({ id })) ?? [],
            targeting: targetingPayloadsByFormKey[TargetingFormKeys.BrandSafety],
            targetingMode: targetingModePayloadsByFormKey[TargetingFormKeys.BrandSafety],
        };

        await updateBrandSafety({ id: brand?.id, body })
            .unwrap()
            .then((brand: AdvancedBrandSafety) => {
                form.setFieldsValue(initialFormData);
                notification.success({ message: `You updated brand safety ${brand?.name}` });
                handleAdvancedBrandSafetyPage(brand.id);
            })
            .catch((error) => {
                notification.error({ message: error?.data?.errorDescription });
            })
            .finally(handleCloseModal);
    };

    const handleSubmit = (): void => {
        if (mode === "create") {
            handleCreate();
            return;
        }
        handleUpdate(brand as AdvancedBrandSafety);
    };

    const handleConfirmModal = async (): Promise<void> => {
        try {
            await form.validateFields();
            //INFO: when we have targeting we don't need to show confirm modal
            if (targetingPayloadsByFormKey[TargetingFormKeys.BrandSafety].length) {
                handleSubmit();
                return;
            }
            setConfirmModal(true);
        } catch (error) {
            form.scrollToField(error.errorFields[0].name, { behavior: "smooth", block: "center" });
        }
    };
    const handleCloseModal = (): void => setConfirmModal(false);

    const handleChangeBrandSafetyType = (e: RadioChangeEvent): void => {
        form.setFieldsValue({
            [CREATE_BRAND_SAFETY_FORM_ITEMS_NAME.TYPE]: e.target.value,
            [CREATE_BRAND_SAFETY_FORM_ITEMS_NAME.REUSABLE_ADVERTISER_DOMAINS]: [],
        });
    };
    const handleChangeReusableAdvertiserDomains = (value: Array<number>): void => {
        form.setFieldsValue({
            [CREATE_BRAND_SAFETY_FORM_ITEMS_NAME.REUSABLE_ADVERTISER_DOMAINS]: value,
        });
    };

    useEffect((): void => {
        if (brand) {
            loadTargeting(TargetingFormKeys.BrandSafety, brand.targeting, brand.targetingMode);
        }
    }, [brand, loadTargeting]);

    useEffect((): void => {
        if (mode === "edit") {
            form.setFieldsValue({
                [CREATE_BRAND_SAFETY_FORM_ITEMS_NAME.NAME]: brand?.name,
                [CREATE_BRAND_SAFETY_FORM_ITEMS_NAME.TYPE]: brand?.type?.id,
                [CREATE_BRAND_SAFETY_FORM_ITEMS_NAME.IABCATEGORIES]:
                    brand?.blockedIabCategories?.map((category) => ({
                        value: category.id,
                        label: formatCategoryLabel(category, TargetingDimensionTypeLabels.ContentCategories),
                    })) ?? [],
                [CREATE_BRAND_SAFETY_FORM_ITEMS_NAME.IAB_CATEGORY_BLOCK_EXCEPTIONS]:
                    brand?.blockExceptionIabCategories?.map((category) => ({
                        value: category.id,
                        label: formatCategoryLabel(category, TargetingDimensionTypeLabels.ContentCategories),
                    })) ?? [],
                [CREATE_BRAND_SAFETY_FORM_ITEMS_NAME.BLOCKED_INDUSTRIES]: parseBlockedIndustriesFromApi(
                    brand?.blockedIndustries,
                    mode
                ),
                [CREATE_BRAND_SAFETY_FORM_ITEMS_NAME.REUSABLE_ADVERTISER_DOMAINS]:
                    brand?.seatAdvertiserDomainFilterListDefs?.map((domain) => domain?.id) ?? [],
                [CREATE_BRAND_SAFETY_FORM_ITEMS_NAME.ADD_ADVERTISER_DOMAINS]:
                    brand?.advertiserDomains?.join("\n") ?? null,
            });
            return;
        }
        form.setFieldsValue(initialFormData);
    }, [form, brand, mode]);

    return {
        form,
        type,
        handleCancel,
        handleSubmit,
        confirmModal,
        initialFormData,
        handleCloseModal,
        handleDeleteOpen,
        handleConfirmModal,
        isLoading: isFetching,
        handleChangeBrandSafetyType,
        title: getPageTitle(mode, brand?.name),
        handleChangeReusableAdvertiserDomains,
        isSubmitting: isBrandSafetyCreating || isBrandSafetyUpdating,
        deleteModalProps: { ...restModalProps },
        isDeleting,
    };
};
