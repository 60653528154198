import { FC } from "react";
import { ColProps } from "antd/lib/grid/col";
import { Select, Form, SelectProps } from "antd";
import { AFFILIATE_COST_MODEL } from "@app/features/inventory/constants";
import { useAffiliateCostModel } from "@app/features/inventory/components/FormItems/AffiliateCostModel/useAffiliateCostModel";

export interface AffiliateCostModel extends SelectProps {
    name: typeof AFFILIATE_COST_MODEL;
    label: string;
    extra?: string;
    wrapperCol?: ColProps;
}

export const AffiliateCostModel: FC<AffiliateCostModel> = ({ name, label, wrapperCol, extra, ...rest }) => {
    const { isFetching, options, costValue } = useAffiliateCostModel();

    return (
        <Form.Item
            extra={extra}
            name={name}
            label={label}
            wrapperCol={wrapperCol}
            rules={[
                {
                    validator(_, value) {
                        if (!value && costValue)
                            return Promise.reject(new Error("Cost Model is required when Affiliate Cost Value is set"));

                        return Promise.resolve();
                    },
                },
            ]}
        >
            <Select
                showSearch
                allowClear
                labelInValue
                options={options}
                loading={isFetching}
                optionFilterProp="label"
                style={{ width: "100%" }}
                autoClearSearchValue={false}
                {...rest}
            />
        </Form.Item>
    );
};
