import { BLOCKED_INDUSTRIES_LABEL, Dashes, GlobalIndustry, Industries } from "@app/core/components";
import { AdSourceProperties, BaseOption, IabCategory, IabCategoryBlockMode } from "@app/core/services";
import { AD_SOURCE_FIELDS } from "@app/features/seatAdSources/constants";
import { Col, Form, Input, Row, Tag } from "antd";
import { FC } from "react";

interface Props {
    iabCategoryBlockMode: IabCategoryBlockMode;
    blockedIabCategories: IabCategory[];
    blockExceptionIabCategories: IabCategory[];
    advertiserBlockingMode: AdSourceProperties | null;
    advertiserBlockDomains: string[] | null;
    seatAdvertiserDomainFilterListDefs: BaseOption[] | null;
    industriesMode: string | null;
    blockedIndustries: GlobalIndustry[] | null;
}
export const AdSourceBrandSafety: FC<Props> = ({
    iabCategoryBlockMode,
    blockedIabCategories,
    blockExceptionIabCategories,
    advertiserBlockDomains,
    seatAdvertiserDomainFilterListDefs,
    advertiserBlockingMode,
    industriesMode,
    blockedIndustries,
}) => {
    return (
        <>
            <Row>
                <Col sm={8}>
                    <Form.Item label="IAB Category Mode">
                        <Dashes value={iabCategoryBlockMode?.name} />
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                <Col sm={8}>
                    <Form.Item label="IAB Categories">
                        {blockedIabCategories?.length ? (
                            blockedIabCategories.map(({ name, id }) => <Tag key={id}>{name}</Tag>)
                        ) : (
                            <Dashes />
                        )}
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                <Col sm={8}>
                    <Form.Item label="IAB Category Block Exceptions">
                        {blockExceptionIabCategories?.length ? (
                            blockExceptionIabCategories.map(({ name, id }) => <Tag key={id}>{name}</Tag>)
                        ) : (
                            <Dashes />
                        )}
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                <Col sm={8}>
                    <Form.Item label="Advertiser Domain Mode">
                        <Dashes value={advertiserBlockingMode?.name} />
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                <Col sm={8}>
                    <Form.Item label="Reusable Advertiser Domains">
                        {seatAdvertiserDomainFilterListDefs?.length ? (
                            seatAdvertiserDomainFilterListDefs.map(({ name, id }) => <Tag key={id}>{name}</Tag>)
                        ) : (
                            <Dashes />
                        )}
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                <Col sm={8}>
                    <Form.Item label="Additional Advertiser Domains">
                        {advertiserBlockDomains?.length ? (
                            <Input.TextArea rows={3} value={advertiserBlockDomains.join("\n")} readOnly />
                        ) : (
                            <Dashes />
                        )}
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                <Col sm={8}>
                    <Form.Item label={AD_SOURCE_FIELDS.INDUSTRIES_MODE.label}>
                        <Dashes value={industriesMode} />
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                <Col sm={8}>
                    <Form.Item label={BLOCKED_INDUSTRIES_LABEL}>
                        <Industries industries={blockedIndustries} />
                    </Form.Item>
                </Col>
            </Row>
        </>
    );
};
