import { MappingException, MappingExceptionPayload } from "@app/core/services";
import { OVERRIDE_MODE_TEXT } from "./constants";
import { MappingExceptionsForm, OverrideMode } from "./types";

export const parseMappingExceptionToApi = (
    {
        adomain,
        adomainOverride,
        crid,
        industryOverrideList,
        industryOverrideMode,
        network,
        taxonomyOverrideMode,
        taxonomyOverrideList,
    }: MappingExceptionsForm,
    seatId?: string
): MappingExceptionPayload => ({
    adomain: adomain ?? "",
    adomainOverride: adomainOverride ?? "",
    crid: crid ?? "",
    industryOverrideList: industryOverrideList?.map(({ value }) => ({ id: Number(value) })) ?? null,
    industryOverrideMode: { id: Number(industryOverrideMode) },
    namespace: seatId ? `s:${seatId}` : "g",
    network: { id: Number(network?.value), name: String(network?.label) },
    taxonomyOverrideMode: { id: Number(taxonomyOverrideMode) },
    taxonomyOverrideList: taxonomyOverrideList?.map(({ value }) => ({ id: Number(value) })) ?? null,
});

export const parseMappingExceptionFromApi = ({
    adomain,
    adomainOverride,
    crid,
    industryOverrideList,
    industryOverrideMode,
    network,
    taxonomyOverrideMode,
    taxonomyOverrideList,
}: MappingException): MappingExceptionsForm => ({
    adomain,
    adomainOverride,
    crid,
    industryOverrideList: industryOverrideList?.map(({ name, id }) => ({ label: name, value: id })) ?? null,
    industryOverrideMode: industryOverrideMode?.id ?? 3,
    network: { label: network.name, value: network.id },
    taxonomyOverrideMode: taxonomyOverrideMode?.id ?? 3,
    taxonomyOverrideList: taxonomyOverrideList?.map(({ name, id }) => ({ label: name, value: id })) ?? null,
});

export const getOverrideModeLabel = (fieldLabel: string, mode: number): string =>
    `${OVERRIDE_MODE_TEXT[mode]} ${mode === OverrideMode.NONE ? fieldLabel : fieldLabel.split(" ").splice(-1)}`.trim();
