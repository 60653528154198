import { FC } from "react";
import { Form, Input } from "antd";
import { FORM_FIELDS } from "../../constants";
import { useAtLeastOneOverrideRule } from "../../useAtLeastOneOverrideRule";
import { useAdvertiserDomainOverrideInput } from "./useAdvertiserDomainOverrideInput";

const { name, label, max } = FORM_FIELDS.ADVERTISER_DOMAIN_OVERRIDE;

export const AdvertiserDomainOverrideInput: FC = () => {
    const { onChange } = useAdvertiserDomainOverrideInput();
    const atLeastOneOverrideRule = useAtLeastOneOverrideRule();

    return (
        <Form.Item
            name={name}
            label={label}
            rules={[{ max, message: `${label} cannot exceed ${max} characters` }, atLeastOneOverrideRule]}
        >
            <Input placeholder={`Enter ${label}`} onChange={onChange} />
        </Form.Item>
    );
};
