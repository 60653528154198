import { FC } from "react";
import {
    cardBodyStyle,
    sectionHeaderCollapseStyle,
    secondaryStepsSectionClassList,
} from "@app/features/deals/DealForm";
import { Title } from "@app/core/components";
import { DealFormMode } from "../../../types";
import { Card, Col, Row, Collapse } from "antd";
import { CREATE_DEAL_FORM_ITEMS_NAME, GUTTER } from "@app/features/deals/constants";
import { DealCurrencyField } from "@app/features/deals/DealForm/DealDemandFormSections/DealPricingSection/Fields";
import {
    AdSourceAdResponsePriceOverrideField,
    AdSourceAlwaysSendFloorField,
    AdSourceAuctionTypeField,
    AdSourceFloorTypeField,
    AdSourceOverrideFloorCpmField,
    AdSourceUnderInboundFloor,
} from "@app/features/deals/DealForm/DealFormCreateAdSource/DealFormCreateAdSourceSections/AdSourceFloorSettingsSection/Fields";
import { AdSourceFloorSettingsDefinitionSidePanel } from "@app/features/deals/DealForm/DealFormCreateAdSource/DealFormCreateAdSourceSidePanels";
import { css, cx } from "@emotion/css";

const classList = css`
    border-bottom: 0;
`;

interface Props {
    mode: DealFormMode;
    adSourceAdResponsePriceOverrideShown: boolean;
}

export const AdSourceFloorSettingsSection: FC<Props> = ({ mode, adSourceAdResponsePriceOverrideShown }) => {
    return (
        <Collapse
            defaultActiveKey={["0"]}
            className={cx(secondaryStepsSectionClassList, classList)}
            collapsible="header"
            items={[
                {
                    key: "0",
                    label: <Title level={5} title="Floor Settings" />,
                    style: sectionHeaderCollapseStyle,
                    extra: <AdSourceFloorSettingsDefinitionSidePanel />,
                    children: (
                        <Card
                            bordered={false}
                            styles={{ body: cardBodyStyle }}
                            data-sdet="deal-form-ad-source-floor-settings-section"
                        >
                            <Row gutter={GUTTER}>
                                <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={8}>
                                    <AdSourceFloorTypeField />
                                </Col>
                            </Row>
                            <Row gutter={GUTTER}>
                                <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={8}>
                                    <AdSourceOverrideFloorCpmField />
                                </Col>
                                <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={8}>
                                    <DealCurrencyField
                                        name={CREATE_DEAL_FORM_ITEMS_NAME.AD_SOURCE_CURRENCY}
                                        mode={mode}
                                    />
                                </Col>
                            </Row>
                            <Row gutter={GUTTER}>
                                <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={8}>
                                    <AdSourceAuctionTypeField />
                                </Col>
                                <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={8}>
                                    <AdSourceAlwaysSendFloorField />
                                </Col>
                            </Row>
                            <Row gutter={GUTTER}>
                                <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={8}>
                                    <AdSourceUnderInboundFloor />
                                </Col>
                                {adSourceAdResponsePriceOverrideShown && (
                                    <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={8}>
                                        <AdSourceAdResponsePriceOverrideField />
                                    </Col>
                                )}
                            </Row>
                        </Card>
                    ),
                },
            ]}
        />
    );
};
