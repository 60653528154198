import { FC, useMemo } from "react";
import { Tag } from "antd";
import { ArrowRightOutlined } from "@ant-design/icons";
import { Dashes } from "../Dashes";
import { StyledLink } from "../styled";
import { GlobalIndustry } from "./types";

const DEFAULT_MAX_ENTRIES_UNTIL_VIEW_ALL_DISPLAYED = 5;

interface Props {
    industries: GlobalIndustry[] | null | undefined;
    viewAll?: {
        onClick: () => void;
        maxEntries?: number;
    };
}

export const Industries: FC<Props> = ({ industries, viewAll }) => {
    const maxEntries = viewAll?.maxEntries ?? DEFAULT_MAX_ENTRIES_UNTIL_VIEW_ALL_DISPLAYED;

    const sortedIndustries = useMemo(
        () => industries?.slice().sort((a, b) => a.name.localeCompare(b.name, "en", { sensitivity: "base" })),
        [industries]
    );

    const cappedIndustries = useMemo(
        () => (viewAll ? sortedIndustries?.slice(0, maxEntries) : sortedIndustries),
        [viewAll, sortedIndustries, maxEntries]
    );

    if (!industries?.length || !cappedIndustries?.length) {
        return <Dashes />;
    }

    return (
        <div data-sdet="view-all-industry-tags">
            {cappedIndustries.map(({ id, name }) => (
                <Tag key={id}>{name}</Tag>
            ))}
            {viewAll && industries.length > maxEntries && (
                <StyledLink onClick={viewAll.onClick}>
                    <span style={{ whiteSpace: "nowrap" }}>
                        View All <ArrowRightOutlined />
                    </span>
                </StyledLink>
            )}
        </div>
    );
};
