import React from "react";
import { Button, Flex, Form, FormProps, Input, Modal } from "antd";

interface Props {
    open: boolean;
    handleCloseModal: React.MouseEventHandler<HTMLElement>;
    onSubmit: FormProps["onFinish"];
    isLoading: boolean;
    form: FormProps["form"];
}

export const CreateSnapshotModal = ({ open, handleCloseModal, onSubmit, isLoading, form }: Props) => {
    return (
        <Modal open={open} title="New Snapshot" onCancel={handleCloseModal} footer={null}>
            <Form form={form} layout="vertical" onFinish={onSubmit}>
                <Form.Item name="description" label="Description" rules={[{ required: true }]}>
                    <Input.TextArea rows={2} placeholder="Enter text here..." />
                </Form.Item>
                <Flex gap="small" justify="flex-end">
                    <Button htmlType="button" onClick={handleCloseModal}>
                        Cancel
                    </Button>
                    <Button loading={isLoading} type="primary" htmlType="submit">
                        Create Snapshot
                    </Button>
                </Flex>
            </Form>
        </Modal>
    );
};
