import moment from "moment-timezone";
import { format as formatter } from "@rubicon/utils";
import {
    MONTH_DAY_YEAR_HOUR_MINUTE_AMPM,
    UTC_OFFSET_FREE_FORMAT,
    ISO_8601_FORMAT,
    YEAR_MONTH_DAY_HOUR_MINUTE_AMPM_TIMEZONE,
} from "@app/core/components/constants";
import { TimeZone } from "@app/core/services/console";

const { DEFAULT_DASHES } = formatter.constants;

export const formatDateInUtc = (date?: string | null, format: string = YEAR_MONTH_DAY_HOUR_MINUTE_AMPM_TIMEZONE) =>
    date ? moment.utc(date).format(format) : DEFAULT_DASHES;

export const formatDateInTimeZone = (
    date: string | null | undefined,
    timeZone?: (Partial<TimeZone> & { code: string; utcOffset: number; dayLightSavingsOffset: number }) | null,
    format: string = MONTH_DAY_YEAR_HOUR_MINUTE_AMPM
) => {
    if (!date) {
        return DEFAULT_DASHES;
    }

    if (!timeZone) {
        return moment(date).format(format);
    }

    const isValidTimeZoneCode = moment().tz(timeZone.code).isValid();

    // If the timezone code is valid, default to using it
    if (isValidTimeZoneCode) {
        return moment(date).tz(timeZone.code).format(format);
    }

    // Otherwise, fallback to the UTC offest
    return moment(date)
        .utcOffset((timeZone.utcOffset + timeZone.dayLightSavingsOffset) / 1000 / 60, true)
        .format(format);
};

// Ignores current date's timezone and substitutes it with the passed timezone
// Does _not_ take into account any time difference - e.g. converting noon PST to UTC will yield noon UTC
export const convertDateToTimeZone = (
    dateString: string | null,
    timeZone: { utcOffset: number; code: string; dayLightSavingsOffset: number } | null | undefined
): string | null => {
    if (!dateString) {
        return null;
    }
    if (!timeZone) {
        return dateString;
    }
    const dateNoTimeZone = moment(dateString).format(UTC_OFFSET_FREE_FORMAT);
    const isValidTimeZoneCode = moment().tz(timeZone.code).isValid();

    // If the timezone code is valid, default to using it
    if (isValidTimeZoneCode) {
        return moment(dateString).tz(timeZone.code, true).format(ISO_8601_FORMAT);
    }

    // Otherwise, fallback to the UTC offest
    return moment(dateNoTimeZone)
        .utcOffset((timeZone.utcOffset + timeZone.dayLightSavingsOffset) / 1000 / 60, true)
        .format(ISO_8601_FORMAT);
};

export const isSameDate = (
    a: moment.Moment | string | null | undefined,
    b: moment.Moment | string | null | undefined
): boolean => {
    if (!a && !b) {
        return a === b;
    }
    if (typeof a === "string") {
        a = moment(a);
    }
    if (typeof b === "string") {
        b = moment(b);
    }
    if (moment.isMoment(a) && a.isValid() && moment.isMoment(b) && b.isValid()) {
        return a.isSame(b);
    }
    return false;
};

export const applyTimeZoneToDate = (date: moment.Moment, timeZoneCode: string) => {
    const dateStringNoTimeZone = moment(date).format(UTC_OFFSET_FREE_FORMAT);
    const timeZoneOffset = moment().tz(timeZoneCode).format("Z");
    const newString = dateStringNoTimeZone.concat(timeZoneOffset);
    const convertedDate = moment(newString).tz(timeZoneCode);
    return convertedDate;
};

export const convertLocalDateToLocalDate = (
    date: moment.Moment | null,
    sourceTimeZoneCode: string | undefined,
    targetTimeZoneCode: string | undefined
): moment.Moment | null => {
    if (!date) {
        return null;
    }
    const validatedSourceTimeZoneCode = validateTimeZoneCode(sourceTimeZoneCode);
    const validatedTargetTimeZoneCode = validateTimeZoneCode(targetTimeZoneCode);
    if (!validatedSourceTimeZoneCode || !validatedTargetTimeZoneCode) {
        return date;
    }
    const sourceDate = moment(date).tz(validatedSourceTimeZoneCode, true);
    const targetDate = moment(sourceDate).tz(validatedTargetTimeZoneCode).local(true);
    return targetDate;
};

export const convertLocalDateToUtcString = (
    dateString: string | null,
    sourceTimeZoneCode: string,
    targetTimeZoneCode: string
): string | null => {
    if (!dateString) {
        return null;
    }
    return moment(moment(dateString).tz(sourceTimeZoneCode, true)).tz(targetTimeZoneCode).utc().format(ISO_8601_FORMAT);
};

export const isLessThanOrOnlyDate = (
    aDate: moment.Moment | null | undefined,
    aTimeZoneCode: string | null | undefined,
    bDate: moment.Moment | null | undefined,
    bTimeZoneCode: string | null | undefined
): boolean => {
    if (moment.isMoment(aDate) && moment.isMoment(bDate)) {
        const aValidatedTimeZoneCode = validateTimeZoneCode(aTimeZoneCode);
        const bValidatedTimeZoneCode = validateTimeZoneCode(bTimeZoneCode);
        if (aValidatedTimeZoneCode && bValidatedTimeZoneCode) {
            const aDateTimeZone = applyTimeZoneToDate(aDate, aValidatedTimeZoneCode);
            const bDateTimeZone = applyTimeZoneToDate(bDate, bValidatedTimeZoneCode);

            return aDateTimeZone.isBefore(bDateTimeZone);
        }
        if (aValidatedTimeZoneCode) {
            const aDateTimeZone = applyTimeZoneToDate(aDate, aValidatedTimeZoneCode);
            return aDateTimeZone.isBefore(bDate);
        }
        if (bValidatedTimeZoneCode) {
            const bDateTimeZone = applyTimeZoneToDate(bDate, bValidatedTimeZoneCode);
            return aDate.isBefore(bDateTimeZone);
        }
        return aDate.isBefore(bDate);
    }
    if (moment.isMoment(aDate)) {
        return true;
    }
    return false;
};

export const isGreaterThanOrNullDate = (
    aDate: moment.Moment | null | undefined,
    aTimeZoneCode: string | null | undefined,
    bDate: moment.Moment | null | undefined,
    bTimeZoneCode: string | null | undefined
): boolean => {
    if (moment.isMoment(aDate) && moment.isMoment(bDate)) {
        const aValidatedTimeZoneCode = validateTimeZoneCode(aTimeZoneCode);
        const bValidatedTimeZoneCode = validateTimeZoneCode(bTimeZoneCode);
        if (aValidatedTimeZoneCode && bValidatedTimeZoneCode) {
            const aDateTimeZone = applyTimeZoneToDate(aDate, aValidatedTimeZoneCode);
            const bDateTimeZone = applyTimeZoneToDate(bDate, bValidatedTimeZoneCode);
            return aDateTimeZone.isAfter(bDateTimeZone);
        }
        return aDate.isAfter(bDate);
    }
    return aDate === null && Boolean(bDate);
};

export const validateTimeZoneCode = (timeZoneCode: string | null | undefined): string | null => {
    if (!timeZoneCode || typeof timeZoneCode !== "string" || !Boolean(moment.tz.zone(timeZoneCode))) {
        return null;
    }
    return timeZoneCode;
};

export const convertLocalDateToApi = (date: moment.Moment | string | null, timeZoneCode: string): string | null => {
    if (!date) {
        return null;
    }

    if (typeof date === "string") {
        // Not great, but here we make the assumption that if we recieve date as a string,
        // then it has already been converted to to the API format.
        return date;
    }

    return moment(date).tz(timeZoneCode, true).utc().format(ISO_8601_FORMAT);
};

export const parseDateStringFromApi = (
    date: moment.Moment | string | null | undefined,
    timeZoneCode: string | null | undefined
): moment.Moment | null => {
    if (!date) {
        return null;
    }

    if (moment.isMoment(date)) {
        // Not great, but here we make the assumption that if we recieve date as a moment object,
        // then it has already been parsed from the API and is in the correct timezone.
        return date;
    }

    const validatedTimeZoneCode = validateTimeZoneCode(timeZoneCode);
    const dateInTimeZone = validatedTimeZoneCode ? moment(date).tz(validatedTimeZoneCode) : moment(date);
    const dateNoTimeZone = dateInTimeZone.format(UTC_OFFSET_FREE_FORMAT);
    return moment(dateNoTimeZone);
};

export const parseDateStringFromApiAsUtcFreeString = (
    date: moment.Moment | string | null | undefined,
    timeZoneCode: string | null | undefined
): string | null => {
    const parsedDate = parseDateStringFromApi(date, timeZoneCode);
    return parsedDate ? parsedDate.format(UTC_OFFSET_FREE_FORMAT) : null;
};

export const formatDateStringFromApi = (
    date: moment.Moment | string | null | undefined,
    timeZoneCode: string | null | undefined,
    format: string
): string => {
    if (!date) {
        return DEFAULT_DASHES;
    }
    const parsedDate = parseDateStringFromApi(date, timeZoneCode);

    if (!moment.isMoment(parsedDate)) {
        return DEFAULT_DASHES;
    }
    return parsedDate.format(format);
};

export const formatDateAsUtcOffsetFreeString = (date: moment.Moment | null): string | null => {
    return date ? date.format(UTC_OFFSET_FREE_FORMAT) : null;
};

export const formatLocalDate = (date: moment.Moment | string | null, format: string): string => {
    if (!date) {
        return DEFAULT_DASHES;
    }
    return moment(date).format(format);
};

export const isDateMinimum = (
    date: moment.Moment,
    timeZoneCode: string,
    dates: { date: moment.Moment; timeZoneCode: string }[]
): boolean => {
    const dateTimeZone = applyTimeZoneToDate(date, timeZoneCode);
    return dates.every((d) => dateTimeZone.isSameOrBefore(applyTimeZoneToDate(d.date, d.timeZoneCode)));
};

export const isDateMaximum = (
    date: moment.Moment | null,
    timeZoneCode: string,
    dates: { date: moment.Moment | null; timeZoneCode: string }[]
): boolean => {
    if (!date) {
        return true;
    }

    if (dates.some((d) => !d.date)) {
        return false;
    }

    const dateTimeZone = applyTimeZoneToDate(date, timeZoneCode);
    return dates.every((d) => dateTimeZone.isSameOrAfter(applyTimeZoneToDate(d.date as moment.Moment, d.timeZoneCode)));
};

export const getConvertedMinDate = (
    dates: { date: moment.Moment; timeZoneCode: string }[],
    targetTimeZoneCode: string
): moment.Moment | null => {
    if (!dates.length) {
        return null;
    }

    return moment.min(
        dates
            .map((d) => convertLocalDateToLocalDate(d.date, d.timeZoneCode, targetTimeZoneCode))
            .filter((d) => d !== null) as moment.Moment[]
    );
};

export const getConvertedMaxDate = (
    dates: { date: moment.Moment | null; timeZoneCode: string }[],
    targetTimeZoneCode: string
): moment.Moment | null => {
    if (!dates.length || dates.some((d) => !d.date)) {
        return null;
    }

    return moment.max(
        dates
            .map((d) => convertLocalDateToLocalDate(d.date, d.timeZoneCode, targetTimeZoneCode))
            .filter((d) => d !== null) as moment.Moment[]
    );
};
