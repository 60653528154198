import { Loading } from "@app/core/components";
import { useSeatAdSourcesPerformanceLast24Hours } from "@app/features/seatAdSources/SeatAdSourcesDetailsDrawer/SeatAdSourcesDetailsDrawerContent/SeatAdSourcesDetailsDrawerView/secondaryViews/SeatAdSourcesDetailsPerformance/performanceViews/SeatAdSourcesPerformanceLast24Hours/useSeatAdSourcesPerformanceLast24Hours";
import { SeatAdSources24HourPerformanceChart } from "@app/features/seatAdSources/seatAdSourcesCharts";
import { Button, Col, Empty, Flex, Row, Space } from "antd";
import { FC, useState } from "react";
import { AdSourcesPerformanceMetrics } from "../AdSourcesPerformanceMetrics";
import { PerformanceStatsTypeButtons } from "@app/features/inventory/InventoryHealthDetailsPages/SharedSections/PerformanceStatsTypeButtons";
import { DownloadOutlined } from "@ant-design/icons";
import moment from "moment-timezone";
import { SnapshotsControls } from "./Snapshots";

interface Props {
    adSourceId: number;
    adSourceTypeId: number;
    handlePerformanceTypeSwitch: (isLiveActive: boolean) => void;
    adSourceName: string;
}

export const Last24Hours: FC<Props> = ({ adSourceId, adSourceTypeId, handlePerformanceTypeSwitch, adSourceName }) => {
    const { loadingState, adSourceHistoryStats, totalAdSourceStat } =
        useSeatAdSourcesPerformanceLast24Hours(adSourceId);
    const [isExtended, setIsExtended] = useState(false);

    const handleExtend = () => {
        setIsExtended(!isExtended);
    };

    const handleChartDownload = () => {
        const canvas = document.getElementsByTagName("canvas")[0];

        const canvasWithBg = document.createElement("canvas");
        canvasWithBg.width = canvas.width;
        canvasWithBg.height = canvas.height;

        const ctx = canvasWithBg.getContext("2d");

        if (ctx) {
            ctx.fillStyle = "#ffffff";
            ctx.fillRect(0, 0, canvasWithBg.width, canvasWithBg.height);
            ctx.drawImage(canvas, 0, 0);
            const downloadableData = canvasWithBg.toDataURL();
            const link = document.createElement("a");
            const date = moment(+Date.now()).format("YYYY-MM-DD-HH-mm");
            link.download = `${adSourceName}_${date}.png`;
            link.href = downloadableData;
            link.click();
            link.remove();
        }
    };

    return (
        <Space direction="vertical" size={50} style={{ display: "flex" }}>
            <Row gutter={[16, 16]}>
                <Col span={isExtended ? 12 : 6}>
                    <AdSourcesPerformanceMetrics
                        handleExtend={handleExtend}
                        isExtended={isExtended}
                        adSourceTypeId={adSourceTypeId}
                        adSourceStat={totalAdSourceStat}
                        hasLoadedAdStat={loadingState}
                    />
                </Col>
                <Col span={isExtended ? 12 : 18}>
                    <Row
                        justify={"space-between"}
                        style={{
                            margin: "0 0 16px 0",
                            padding: "0 16px",
                        }}
                    >
                        <PerformanceStatsTypeButtons handlePerformanceTypeSwitch={handlePerformanceTypeSwitch} />
                        <Col>
                            {adSourceHistoryStats && (
                                <Flex gap="small">
                                    <SnapshotsControls
                                        adSourceId={adSourceId}
                                        adSourceName={adSourceName}
                                        adSourceHistoryStats={adSourceHistoryStats}
                                    />
                                    <Button onClick={handleChartDownload}>
                                        <DownloadOutlined />
                                    </Button>
                                </Flex>
                            )}
                        </Col>
                    </Row>
                    {adSourceHistoryStats && (
                        <SeatAdSources24HourPerformanceChart adSourceHistoryStats={adSourceHistoryStats} />
                    )}
                    {loadingState === "loading" && <Loading position="relative" />}
                    {loadingState === "loadedNoData" && (
                        <Empty
                            style={{
                                height: "75%",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                            image={Empty.PRESENTED_IMAGE_SIMPLE}
                        />
                    )}
                </Col>
            </Row>
        </Space>
    );
};
