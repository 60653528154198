import { FC } from "react";
import { Radio } from "antd";
import { TRANSPARENCY_OPTIONS } from "@app/features/deals/constants";
import { useAdSourceSupplyNameAppNameField } from "./useAdSourceSupplyNameAppNameField";
import { TransparencyFormItem } from "@app/features/deals/DealForm/DealFormCreateAdSource/DealFormCreateAdSourceSections/AdSourceTransparencySection/Fields";
import { AD_SOURCE_FIELDS } from "@app/features/seatAdSources/constants";

export const AdSourceSupplyNameAppNameField: FC = () => {
    const { value, onChange } = useAdSourceSupplyNameAppNameField();

    return (
        <TransparencyFormItem label={AD_SOURCE_FIELDS.SUPPLY_NAME.label} data-sdet="supply-app-name">
            <Radio.Group onChange={(e) => onChange(e?.target?.value)} value={value} options={TRANSPARENCY_OPTIONS} />
        </TransparencyFormItem>
    );
};
