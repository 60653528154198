import { LabeledValue } from "antd/es/select";
import { useGetIndustryBlockingModesQuery } from "@app/core/services";
import { useAppDispatch, useAppSelector } from "@app/core/store";
import { CREATE_DEAL_FORM_ITEMS_NAME } from "@app/features/deals/constants";
import { dealFormSelectFieldChange } from "@app/features/deals/DealForm/reducer";
import { AdSourceAdvertiserDomainModeIds } from "@app/features/seatAdSources/constants";

const { AD_SOURCE_INDUSTRIES_MODE } = CREATE_DEAL_FORM_ITEMS_NAME;

interface UseAdSourceIndustriesModeField {
    value: number | null;
    isLoading: boolean;
    isDisabled: boolean;
    options: LabeledValue[];
    onChange: (industryBlockModeId: number) => void;
}

export const useAdSourceIndustriesModeField = (
    advertiserDomainModeId: number | null
): UseAdSourceIndustriesModeField => {
    const dispatch = useAppDispatch();
    const { data, isLoading } = useGetIndustryBlockingModesQuery();

    const isDisabled = advertiserDomainModeId === AdSourceAdvertiserDomainModeIds.ALLOW_OVERRIDE_INVENTORY;

    const value = useAppSelector((state) => state.dealForm.values[AD_SOURCE_INDUSTRIES_MODE]);

    const options = (data || []).map((item) => ({ label: item.name, value: item.id }));

    const onChange = (industryBlockModeId: number) => {
        dispatch(dealFormSelectFieldChange({ field: AD_SOURCE_INDUSTRIES_MODE, value: industryBlockModeId }));
    };

    return {
        value,
        isLoading,
        isDisabled,
        options,
        onChange,
    };
};
