import { useHistory, useParams } from "react-router-dom";
import { useUserAccess } from "@app/core/auth";
import { ROUTE_FORMATTERS } from "@app/core/routing";
import { MappingException, useDeleteMappingExceptionByIdMutation } from "@app/core/services";
import { BrandSafetyDeleteModalProps } from "../../BrandSafetyDeleteModal";
import { useBrandSafetyDeleteModal } from "../../BrandSafetyDeleteModal/useBrandSafetyDeleteModal";

interface UseMappingExceptionsActions {
    isVisible: boolean;
    deleteModalProps: BrandSafetyDeleteModalProps;
    onClickEdit: () => void;
    onClickDelete: () => void;
}

export const useMappingExceptionsActions = (mappingException: MappingException): UseMappingExceptionsActions => {
    const history = useHistory();
    const { seatId, mappingExceptionId } = useParams<{ seatId: string; mappingExceptionId: string }>();
    const [deleteTrigger, { isLoading: isDeleting }] = useDeleteMappingExceptionByIdMutation();
    const postDeleteEvent = () =>
        history.push(ROUTE_FORMATTERS.SEAT_CONTROLS_BRAND_SAFETY_MAPPING_EXCEPTIONS_LIST_PAGE(seatId));
    const { handleOpen: handleOpenDeleteModal, ...restModalProps } = useBrandSafetyDeleteModal(
        deleteTrigger,
        postDeleteEvent
    );

    const { canEditSeat: isVisible } = useUserAccess();

    const deleteModalProps = {
        isDeleting,
        ...restModalProps,
    };

    const onClickEdit = () =>
        history.push(
            ROUTE_FORMATTERS.SEAT_CONTROLS_BRAND_SAFETY_MAPPING_EXCEPTIONS_EDIT_PAGE(seatId, mappingExceptionId)
        );

    const onClickDelete = () => handleOpenDeleteModal({ id: mappingException.id, name: mappingException.network.name });

    return {
        isVisible,
        deleteModalProps,
        onClickEdit,
        onClickDelete,
    };
};
