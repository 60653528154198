import { intl } from "@rubicon/utils";

export interface BucketedColumnData {
    time: string; // number-number
    y: number; // ct
    totalTime: number; // tt
    avg: number; // tt / ct
    type: string;
    runningAvgCount: number;
}

export interface SplineData {
    time: string;
    name: string;
    count: number;
    cumulativeTotal?: number;
}

export interface TooltipFilledUnFilledData {
    color: string;
    data: BucketedColumnData;
}

export interface TooltipFilledUnFilledCumulativeData {
    color: string;
    data: SplineData;
}

export type TooltipCustomContentData = [
    TooltipFilledUnFilledData?,
    TooltipFilledUnFilledData?,
    TooltipFilledUnFilledCumulativeData?,
    TooltipFilledUnFilledCumulativeData?,
    TooltipFilledUnFilledCumulativeData?
];

export const enum TimingStatsEntity {
    FILLED = "filled",
    UNFILLED = "unFilled",
}

export const enum TimingStatsCumulativeEtity {
    FILLED_CUMULATIVE = "filledCumulative",
    UNFILLED_CUMULATIVE = "unFilledCumulative",
}

export const enum TimingStatsCombinedTotal {
    COMBINED_TOTAL = "combinedTotal",
}

interface TData {
    [key: string]: { ct: number; tt: number };
}

interface TotalTill {
    [x: string]: {
        data: number[];
        runningCount: number;
        indexes: string[];
    };
}
export const getColumnData = (
    tData: TData,
    type: TimingStatsEntity,
    allKeys: Set<string>
): { columnData: BucketedColumnData[]; totalTill: TotalTill } => {
    const totalTill: TotalTill = {
        [type]: {
            runningCount: 0,
            data: [],
            indexes: [],
        },
    };
    const columnData = [] as BucketedColumnData[];

    // TODO use reduce?
    for (const key of [...allKeys]) {
        if (tData?.hasOwnProperty(key)) {
            const { ct, tt } = tData[key];
            const avgTime = ct ? tt / ct : 0;

            columnData.push({ time: key, y: ct, totalTime: tt, avg: avgTime, type, runningAvgCount: ct });
        } else {
            columnData.push({ time: key, y: 0, totalTime: 0, avg: 0, type, runningAvgCount: 0 });
        }
    }

    columnData.sort((a, b) => {
        const [aValue] = a.time.split("-");
        const [bValue] = b.time.split("-");
        return parseInt(aValue) - parseInt(bValue);
    });

    for (let index = 1; index < columnData.length; index++) {
        columnData[index].runningAvgCount += columnData[index - 1].runningAvgCount;
    }

    columnData.forEach(({ y, time }) => {
        totalTill[type].runningCount += y;
        totalTill[type].data.push(totalTill[type].runningCount);
        totalTill[type].indexes.push(time);
    });

    return {
        columnData,
        totalTill,
    };
};

export const getCombinedData = (filledTotalTill: TotalTill, unfilledTotalTill: TotalTill) => {
    const combinedData: { data: number[]; runningCount: number } = {
        data: [],
        runningCount: 0,
    };
    const splineData: SplineData[] = [];

    const len = filledTotalTill[TimingStatsEntity.FILLED].data.length;
    const combined = { ...filledTotalTill, ...unfilledTotalTill };
    const keys = Object.keys(combined);

    for (let i = 0; i < len; i++) {
        let totalForSlot = 0;
        keys.forEach((dim) => {
            totalForSlot += combined[dim].data[i];
        });

        combinedData.data.push(totalForSlot);
    }

    keys.forEach((dim) => (combinedData.runningCount += combined[dim].runningCount));

    keys.forEach((dim) => {
        combined[dim].data.forEach((point, index) => {
            const time = combined[dim].indexes[index];
            splineData.push({
                time,
                name: `${dim}Cumulative`,
                count: getPercentage(point, combined[dim].runningCount),
            });
        });
    });

    keys.slice(0, 1).forEach((dim) => {
        combinedData.data.forEach((point, index) => {
            const time = combined[dim].indexes[index];
            splineData.push({
                time,
                name: TimingStatsCombinedTotal.COMBINED_TOTAL,
                count: getPercentage(point, combinedData.runningCount),
                cumulativeTotal: point,
            });
        });
    });

    // Uses when we turn off combined total line
    const combinedTotalData = splineData.filter(({ name }) => name === TimingStatsCombinedTotal.COMBINED_TOTAL);

    return {
        splineData,
        combinedTotalData,
    };
};

const getPercentage = (point: number, runningCount: number) => (point / runningCount) * 100;

export const labelFormatter = (v: string) => intl.shortFormatter(v);
