import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { useUserAccess } from "@app/core/auth";
import { BLOCKED_INDUSTRIES_LABEL, Industries, SEARCH, sortGlobalIndustries, StyledLink } from "@app/core/components";
import { NONE } from "@app/core/components/constants";
import { ROUTES, ROUTE_FORMATTERS } from "@app/core/routing";
import {
    AdvancedBrandSafety,
    useDeleteAdvancedBrandSafetyMutation,
    useGetAdvancedBrandSafetyQuery,
} from "@app/core/services";
import { Button, Table, Tag, Tooltip } from "antd";
import { ColumnsType } from "antd/es/table";
import { Link, Route, useHistory, useLocation, useParams } from "react-router-dom";
import { COLUMNS_NAME } from "./constants";
import { AdvancedBrandSafetyDetailsDrawer } from "./AdvancedBrandSafetyDetailsDrawer";
import { useMemo, useState } from "react";
import { AdvancedBrandSafetyAdvDomainsDrawer } from "./AdvancedBrandSafetyAdvDomainsDrawer";
import { BrandSafetyDeleteModal } from "../BrandSafetyDeleteModal";
import { useBrandSafetyDeleteModal } from "../BrandSafetyDeleteModal/useBrandSafetyDeleteModal";

interface AdvDomain {
    id: number;
    domains: string[];
}

export interface DeleteCandidate {
    id: number;
    name: string;
}
const getColumns = (
    seatId: string,
    queryString: string,
    canEdit: boolean,
    handleEdit: (id: number) => void,
    handleDeleteModal: (candidate: DeleteCandidate) => void,
    handleOpenAdvDomains: (v: AdvDomain) => void,
    handleClickViewAll: (id: number) => void
) =>
    [
        {
            title: "Name",
            dataIndex: COLUMNS_NAME.NAME,
            sorter: (a: AdvancedBrandSafety, b: AdvancedBrandSafety) => a.name.localeCompare(b.name),
            sortDirections: ["ascend", "descend"],
            width: "28%",
            render: (name: string, { id }: AdvancedBrandSafety) => (
                <Link
                    to={{
                        pathname: ROUTE_FORMATTERS.SEAT_CONTROLS_BRAND_SAFETY_ADVANCED_BRAND_SAFETY_DETAILS_DRAWER(
                            seatId,
                            id
                        ),
                        search: queryString,
                    }}
                >
                    {name}
                </Link>
            ),
        },
        {
            title: "Reusable Adv. Domains",
            dataIndex: COLUMNS_NAME.REUSABLE_ADV_DOMAINS,
            width: "20%",
            render: (_: unknown, { seatAdvertiserDomainFilterListDefs }: AdvancedBrandSafety) =>
                seatAdvertiserDomainFilterListDefs.length
                    ? seatAdvertiserDomainFilterListDefs.map(({ id, name }) => <Tag key={id}>{name}</Tag>)
                    : NONE,
        },
        {
            title: "Addt’l Adv. Domains",
            dataIndex: COLUMNS_NAME.ADDT_ADV_DOMAINS,
            width: "20%",
            render: (_: unknown, { id, advertiserDomains }: AdvancedBrandSafety) => {
                return advertiserDomains.length ? (
                    <StyledLink onClick={() => handleOpenAdvDomains({ id, domains: advertiserDomains })}>
                        {advertiserDomains.length} domains
                    </StyledLink>
                ) : (
                    NONE
                );
            },
        },
        {
            title: "IAB Categories",
            dataIndex: COLUMNS_NAME.IAB_CATEGORIES,
            render: (_: unknown, { blockedIabCategories }: AdvancedBrandSafety) =>
                blockedIabCategories.length
                    ? blockedIabCategories.map(({ name, id }) => <Tag key={id}>{name}</Tag>)
                    : NONE,
        },
        {
            title: BLOCKED_INDUSTRIES_LABEL,
            dataIndex: COLUMNS_NAME.BLOCKED_INDUSTRIES,
            render: (_: unknown, { blockedIndustries, id }: AdvancedBrandSafety) => (
                <Industries
                    industries={blockedIndustries}
                    viewAll={{ maxEntries: 3, onClick: () => handleClickViewAll(id) }}
                />
            ),
            sorter: (a: AdvancedBrandSafety, b: AdvancedBrandSafety) =>
                sortGlobalIndustries(a.blockedIndustries, b.blockedIndustries),
        },
        {
            title: "Type",
            dataIndex: COLUMNS_NAME.TYPE,
            width: "90px",
            render: (type: { name: string }) => type.name,
        },
        canEdit && {
            title: "Actions",
            dataIndex: COLUMNS_NAME.ACTIONS,
            width: "114px",
            render: (_: unknown, { id, name }: AdvancedBrandSafety) => {
                return (
                    <Button.Group>
                        <Tooltip title="Edit">
                            <Button icon={<EditOutlined />} onClick={() => handleEdit(id)} />
                        </Tooltip>
                        <Tooltip title="Delete">
                            <Button icon={<DeleteOutlined />} onClick={() => handleDeleteModal({ id, name })} />
                        </Tooltip>
                    </Button.Group>
                );
            },
        },
    ].filter(Boolean) as ColumnsType<AdvancedBrandSafety>;

export const AdvancedBrandSafetyPage = () => {
    const history = useHistory();
    const { search: queryString } = useLocation();
    const keyword = new URLSearchParams(queryString).get(SEARCH.key);

    const { seatId } = useParams<{ seatId: string }>();
    const { canEditSeat } = useUserAccess();
    const [openAdvDomains, setOpenAdvDomains] = useState<AdvDomain | null>(null);
    const { data, isFetching } = useGetAdvancedBrandSafetyQuery(seatId);
    const searchedItems = useMemo(
        () =>
            data?.filter(({ name }) =>
                keyword ? name.toLocaleLowerCase().includes(keyword.toLocaleLowerCase()) : true
            ),
        [data, keyword]
    );

    const [deleteTrigger, { isLoading: isDeleting }] = useDeleteAdvancedBrandSafetyMutation();
    const { handleOpen, ...restModalProps } = useBrandSafetyDeleteModal(deleteTrigger);

    const handleEdit = (id: number) => {
        history.push(ROUTE_FORMATTERS.SEAT_CONTROLS_BRAND_SAFETY_ADVANCED_BRAND_SAFETY_EDIT_PAGE(seatId, id));
    };

    const handleOpenAdvDomains = (v: AdvDomain | null) => {
        setOpenAdvDomains(v);
    };

    const handleClickViewAll = (id: number) => {
        history.push({
            pathname: ROUTE_FORMATTERS.SEAT_CONTROLS_BRAND_SAFETY_ADVANCED_BRAND_SAFETY_DETAILS_DRAWER(seatId, id),
            search: queryString,
        });
    };

    return (
        <>
            <Table
                columns={getColumns(
                    seatId,
                    queryString,
                    canEditSeat,
                    handleEdit,
                    handleOpen,
                    handleOpenAdvDomains,
                    handleClickViewAll
                )}
                dataSource={searchedItems}
                loading={isFetching}
                pagination={false}
                rowKey="id"
                showSorterTooltip={false}
                size="small"
            />

            <AdvancedBrandSafetyAdvDomainsDrawer
                open={Boolean(openAdvDomains?.id)}
                domains={openAdvDomains?.domains || []}
                onClose={() => handleOpenAdvDomains(null)}
                onEdit={() => handleEdit(openAdvDomains?.id as number)}
            />

            <BrandSafetyDeleteModal isDeleting={isDeleting} {...restModalProps} />

            <Route exact path={ROUTES.SEAT_CONTROLS_BRAND_SAFETY_ADVANCED_BRAND_SAFETY_DETAILS_DRAWER}>
                <AdvancedBrandSafetyDetailsDrawer />
            </Route>
        </>
    );
};
