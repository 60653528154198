import { PageLoader } from "@rubicon/antd-components";
import { Button, Flex } from "antd";
import { useHistory } from "react-router-dom";
import { TargetingDetailsActions } from "./TargetingDetailsActions";
import { TargetingFormReadOnly } from "./TargetingForm";
import { useGetTargetingAndReferences } from "./useTargeting";
import { PageHeader } from "@ant-design/pro-layout";
import { TargetingDrawer, TargetingDrawerChangeHistory } from "./TargetingDrawer";
import { useTargetingDetailsDefinitionDrawer } from "./TargetingDrawer/useTargetingDefinitionDrawer";
import { useTargetingChangeHistory } from "./TargetingDrawer/useTargetingChangeHistory";
import { HelpKeysDrawerContent } from "@app/core/components/HelpKeysDrawer";
import { useRedirectErrorTargeting } from "./useRedirectErrorTargeting";
import { ROUTE_FORMATTERS } from "@app/core/routing";
import { useUserAccess } from "@app/core/auth";

export const TargetingDetailsPage = () => {
    const history = useHistory();
    const { canEditSeat } = useUserAccess();
    const { showDefinition, definitions, title, handleShowDefinition, handleCloseDefinition } =
        useTargetingDetailsDefinitionDrawer();
    const { showChangeHistory, handleShowChangeHistory, handleCloseChangeHistory } = useTargetingChangeHistory();
    const { targeting, references, hasReferences, isLoading, seatId, targetingId, error } =
        useGetTargetingAndReferences();

    useRedirectErrorTargeting({ error, targetingId, seatId });

    if (isLoading) {
        return <PageLoader />;
    }

    return (
        <>
            <PageHeader
                title={targeting?.name}
                onBack={() => history.push(ROUTE_FORMATTERS.SEAT_CONTROLS_TARGETING(seatId))}
            >
                <Flex justify="space-between">
                    <TargetingDetailsActions
                        canEdit={canEditSeat}
                        hasDelete={!hasReferences && !isLoading}
                        targeting={{ id: targeting?.id as number, name: targeting?.name as string }}
                    />
                    <Button data-sdet="change-history" onClick={handleShowChangeHistory}>
                        Change History
                    </Button>
                </Flex>
            </PageHeader>
            <TargetingFormReadOnly
                targeting={targeting}
                references={references}
                handleDefinition={handleShowDefinition}
            />
            <TargetingDrawer open={showDefinition} title={title} closeDrawer={handleCloseDefinition}>
                <HelpKeysDrawerContent definitionsFields={definitions} />
            </TargetingDrawer>
            <TargetingDrawerChangeHistory
                seatId={Number(seatId)}
                open={showChangeHistory}
                handleClose={handleCloseChangeHistory}
                targetingId={targeting.id}
            />
        </>
    );
};
