import { FC } from "react";
import { Footer, Loading, NavBar } from "@app/core/components";
import { Layout } from "antd";
import { useSeatAuthContext } from "@app/core/auth";
import { ControlsNavMenu } from "./ControlsNavMenu";
import { ControlsSwitch } from "./ControlsSwitch";

export const Controls: FC = () => {
    const { context, session } = useSeatAuthContext();

    if (!context || !session) {
        return (
            <Layout.Content>
                <Loading />
            </Layout.Content>
        );
    }

    return (
        <>
            <NavBar label="Controls" />
            <ControlsNavMenu />
            <ControlsSwitch />
            <Layout.Footer>
                <Footer />
            </Layout.Footer>
        </>
    );
};
